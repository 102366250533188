import React from 'react'
import styles from './ConfirmationRequest.module.scss'

import { Button } from '../Button/Button'

export interface ConfirmationRequestProps {
  message: string
  hideCancel: boolean
  closeText: string
  handleClose: () => void
  handleProceed: () => void
  children: React.ReactNode
}

export const ConfirmationRequest: React.FC<ConfirmationRequestProps> = ({
  message,
  hideCancel,
  closeText,
  handleClose,
  handleProceed
}) => {
  return (
    <div className={styles.container}>
      <div>
        <h4>{message}</h4>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          type='submit'
          className='btn--primary'
          handleClick={handleProceed}
        >
          <h4>{hideCancel ? 'Ok' : 'Yes'}</h4>
        </Button>

        {!hideCancel && (
          <Button
            type='submit'
            className='btn--primary'
            handleClick={handleClose}
          >
            <h4>{closeText ? closeText : 'No'}</h4>
          </Button>
        )}
      </div>
    </div>
  )
}
