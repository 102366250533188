import React, { useState } from 'react'
import { containsNumber } from '../../../utils/helpers'
import { Button } from '../../../components/Button/Button'
import { ConfirmationRequest } from '../../../components/ConfirmationRequest/ConfirmationRequest'
import TextInput from '../../../components/Forms/Inputs/TextInput/TextInput'
import styles from '../styles.module.scss'

function Profile(props) {
  const {
    toggleModal,
    signoutUser,
    user,
    updateUser,
    redirectToChangePassword
  } = props
  const [firstName, setFirstName] = useState(user.data.firstName)
  const [lastName, setLastName] = useState(user.data.lastName)

  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)

  const saveHandler = (e) => {
    e.preventDefault()
    let data = {
      firstName,
      lastName
    }
    updateUser(user.data.id, data)
  }

  const openDeleteAccountModal = () => {
    toggleModal({
      active: true,
      type: 'confirm',
      handleProceed: () => {
        toggleModal({ active: false })
      },
      ChildComponent: ConfirmationRequest,
      message:
        'Please email Sportable at support@sportable.com, to delete your account.',
      className: 'modalSmall',
      hideCancel: true
    })
  }

  const handleFirstNameChange = (e) => {
    const value = e.target.value
    setFirstName(value)
    if (value === '') {
      setFirstNameError(true)
    } else if (containsNumber(value)) {
      setFirstNameError(true)
    } else {
      setFirstNameError(false)
    }
  }

  const handleLastNameChange = (e) => {
    const value = e.target.value
    setLastName(value)
    if (value === '') {
      setLastNameError(true)
    } else if (containsNumber(value)) {
      setLastNameError(true)
    } else {
      setLastNameError(false)
    }
  }

  return (
    <div className={styles.settingsCard}>
      <form onSubmit={saveHandler}>
        <div className={styles.row}>
          <h1 className={styles.cardTitle}>Profile</h1>
        </div>
        <div className={`${styles.row} flex-start`}>
          <div className={styles.contactContainer}>
            <h1>
              {user.data.firstName} {user.data.lastName}
            </h1>
            <h5>{user.data.email}</h5>
          </div>
        </div>
        <div className={`${styles.row} space-between`}>
          <div style={{ width: '45%' }}>
            <TextInput
              label='First name'
              input={{
                name: 'firstName',
                value: firstName,
                onChange: handleFirstNameChange
              }}
              meta={{
                initial: user.data.firstName,
                touched: true,
                error: firstNameError
              }}
            />
          </div>
          <div style={{ width: '45%' }}>
            <TextInput
              label='Last name'
              input={{
                name: 'lastName',
                value: lastName,
                onChange: handleLastNameChange
              }}
              meta={{
                initial: user.data.lastName,
                touched: true,
                error: lastNameError
              }}
            />
          </div>
        </div>
        <div className={styles.row}>
          <Button
            disabled={
              (firstName === user.data.firstName &&
                lastName === user.data.lastName) ||
              firstNameError ||
              lastNameError
            }
            type='submit'
            className={`button btn--primary`}
          >
            Save
          </Button>
        </div>
        <div className={styles.row}>
          <h5 className={styles.delete} onClick={openDeleteAccountModal}>
            Delete account
          </h5>
          <h5 className={styles.delete} onClick={redirectToChangePassword}>
            Change password
          </h5>
          <h5 className={styles.delete} onClick={signoutUser}>
            Sign out
          </h5>
        </div>
      </form>
    </div>
  )
}

export default Profile
