import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import animations from '../animations'
import styles from './Form.module.scss'

import * as routerActions from '../../../ui/router/actions'
import { FormController } from './FormController'

// Types
import { FormProps } from './Form.types'
import { RouterState } from '../../../ui/router/types'
import { PitchLinesBackground } from '../../PitchLinesBackground/PitchLinesBackground'

type SetRedirect = (pathname: string) => void

interface Location {
  pathname: string
}

export interface FormViewProps {
  router: RouterState
  side: string
  history: string[]
  location: Location
  setRedirect: SetRedirect
  view: FormProps
}

export const FormView: React.FC<FormViewProps> = (props) => {
  const { view, router, history } = props

  const animationContainer = useRef()
  const formContainer = useRef()

  useEffect(() => {
    animateComponentIn()
  }, [])

  useEffect(() => {
    const { redirect, action } = router
    if (redirect && action === 'PUSH') {
      animateComponentOut(() => {
        console.log('animating form out')
        history.push(redirect)
      })
    }
  }, [router.redirect, router.action])

  const animateComponentIn = () => {
    const animation =
      view.side === 1
        ? animations.slideInLeftBackground
        : animations.slideInRightBackground
    animation(animationContainer.current, () => {
      animations.showContent(formContainer.current, 0.5)
    })
  }

  const animateComponentOut = (cb) => {
    animations.hideContent(formContainer.current, 0.5, () => {
      const animation =
        view.side === 1
          ? animations.slideOutLeftBackground
          : animations.slideOutRightBackground
      animation(animationContainer.current, () => {
        cb()
      })
    })
  }

  return (
    <div className={styles.formViewContainer}>
      {view.side === 1 ? (
        <React.Fragment>
          {/* <div style={backgroundStyle} /> */}
          <PitchLinesBackground />
          <div style={{ position: 'relative' }}>
            <div
              className={styles.animationContainerLeft}
              ref={animationContainer}
            />
            <div
              className={`${styles.formContainer} ${styles.formLeft}`}
              ref={formContainer}
            >
              <FormController {...props} {...view} />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>
            <div
              className={styles.animationContainerRight}
              ref={animationContainer}
            />
            <div
              className={`${styles.formContainer} ${styles.formRight}`}
              ref={formContainer}
            >
              <FormController {...props} {...view} />
            </div>
          </div>
          {/* <div id='animation' style={backgroundStyle} /> */}
          <PitchLinesBackground id={'animation'} />
        </React.Fragment>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  router: state.router
})

export default connect(mapStateToProps, routerActions)(FormView)
