import { ViewType } from '../../components/Views/Views.types'
import { SessionList } from './SessionList/SessionList'
import { MainProps } from '../../components/Views/Main/Main.types'
import { Route } from '../../App/App.types'
import { useMatchTrackerNavbarProps } from '../../apps/MatchTracker/hooks'

export const path = '/activitylist'

export const activityListRoute: Route<MainProps> = {
  path,
  view: {
    type: ViewType.MAIN,
    name: 'Sessions',
    Content: SessionList,
    useNavbarProps: useMatchTrackerNavbarProps
  }
}
