import React from 'react'
import styles from './Player.module.scss'
import { PlayerData } from '../../metrics_server/players/types'
import { DefaultPlayer } from './DefaultPlayer/DefaultPlayer'
import { Sport } from '../../metrics_server/sports/data_types'

export interface PlayerProps {
  key: number | string
  editMode: boolean
  player: PlayerData
  size?: 's' | 'm' | 'l'
  draggable?: boolean
  id?: string
  className?: string
  addPlayer: () => void
  removePlayerSession?: (id: string, arg1?: any) => void
  addPlayerSession?: (arg0: any) => void
  editPlayer?: (player: PlayerData) => void
  removePlayer?: (player: PlayerData) => void
  selectPlayer?: (player: PlayerData) => void
  sport?: Sport
}

export const Player = (props: PlayerProps) => {
  const { size = 's' } = props
  const tileSize =
    size === 's'
      ? styles.tileSmall
      : size === 'm'
      ? styles.tileMedium
      : styles.tileLarge

  return <DefaultPlayer tileSize={tileSize} {...props} />
}
