import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button } from '../../../components/Button/Button'
import { CardList } from '../../../components/CardList/CardList'
import { ExpandableCard } from '../../../components/ExpandableCard/ExpandableCard'
import { ListInset } from '../../../components/ListInset/ListInset'
import { PlayerKeyItem } from '../../../components/PlayerKeyItem/PlayerKeyItem'
import {
  CanvasStyle,
  CanvasViewTypes,
  Strack
} from '../../../components/Strack/Strack.types'
import { Table } from '../../../components/Table/Table'
import { TargetCard } from '../../../components/TargetCard/TargetCard'
import { TargetDropZone } from '../../../components/TargetDropZone/TargetDropZone'
import styles from './Targets.module.scss'
import {
  useChallengeArray,
  useChallenge,
  useTargets,
  useSelectedTarget
} from '../../../metrics_server/targets/hooks'
import { useNewChallengeModal } from '../modals/new_challenge'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'
import { useEvents } from '../../../metrics_server/events/hooks'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { useAppDispatch } from '../../../store/hooks'
import {
  setSelectedChallenge,
  setSelectedTarget,
  updateChallenge
} from '../../../metrics_server/targets/actions'
import { setRedirect } from '../../../ui/router/actions'
import * as TargetSetupScreen from '../../TargetSetup/config'
import {
  getTargetSummaryMetricsTableRowData,
  getTargetSummaryPerformanceTableRowData
} from '../../../metrics_server/targets/functions'
import BasicSelect from '../../../components/Material/Select'
import { RawFlightEventData } from '../../../metrics_server/events/flight/types'
import { isEventTypeData } from '../../../metrics_server/events/functions'
import { StrackEvents } from '../../../components/Strack/StrackEvents/StrackEvents'
import { MetricTypeKeys } from '../../../metrics_server/metrics/data_types'

export interface TargetsProps {
  active: boolean
  strackReady: boolean
  strack: Strack
  canvasStyle: CanvasStyle
  canvasId: string
  toggleCanvasView: (view: string) => void
  toggleCanvasVisibility: (canvasId: string) => void
  canvasView: CanvasViewTypes
}

type TargetsHeaderType = {
  name: string
  key: MetricTypeKeys
  width: number
  type: string
}

export const Targets: React.FC<TargetsProps> = ({
  strackReady,
  active,
  canvasView,
  canvasStyle,
  toggleCanvasVisibility,
  strack,
  canvasId
}) => {
  // Modals //
  const { openNewChallengeModal } = useNewChallengeModal()
  // ====== //

  // Redux //
  const events = useEvents()
  const targets = useTargets()
  const dispatch = useAppDispatch()
  // ===== //

  // Session //
  const formattedSession = useSelectedFormattedSession()
  const { live, sport, playersSessions } = formattedSession
  // ====== //

  // Challenge //
  const { challenge } = useChallenge()
  const challengeArray = useChallengeArray()
  const { target, targetPlayers } = useSelectedTarget()
  // ====== //

  // TODO: This needs a refactor - hiding and showing canavases should be handled further up the tree - same goes for target setup component //
  const previousChallengeArrayLength = useRef(challengeArray.length)
  useEffect(() => {
    if (
      previousChallengeArrayLength.current === 0 &&
      challengeArray.length > 0
    ) {
      toggleCanvasVisibility(canvasId)
    }
    previousChallengeArrayLength.current = challengeArray.length
  }, [challengeArray.length])

  // Actions //
  const editChallenge = () => {
    dispatch(setRedirect(TargetSetupScreen.path))
  }

  const startChallenge = (challenge) => {
    const timeNow = new Date().getTime() / 1000
    const data = { ...challenge }
    data.startTime = timeNow
    dispatch(updateChallenge(data, null))
  }

  const stopChallenge = (challenge) => {
    const timeNow = new Date().getTime() / 1000
    const data = { ...challenge }
    data.timeEnd = timeNow
    dispatch(updateChallenge(data, null))
  }

  // ======= //

  // Component
  const [showPerformance, setShowPerformance] = useState(false)
  const [playersInDropZone, setPlayersInDropZone] = useState([])

  const unitSystem = useUnitsSystem(sport)

  useEffect(() => {
    if (active) {
      dispatch(setSelectedTarget(targets.selected.selectedTarget))
    }
  }, [active])

  const targetMetricTableData = useMemo(() => {
    const data = []
    if (target?.targetSummary) {
      for (const playerSessionId in target.targetSummary) {
        const playerSession = playersSessions.byId.map[playerSessionId]
        if (playerSession) {
          const row = getTargetSummaryMetricsTableRowData(target, playerSession)
          data.push(row)
        }
      }
    }
    return data
  }, [target])

  const targetMetricTableHeaders = useMemo(() => {
    const baseHeaders: TargetsHeaderType[] = [
      {
        name: `Dist (${unitSystem.units.distance.abbreviation})`,
        key: 'dist',
        width: 15,
        type: 'text'
      },
      {
        name: 'Hang Time (s)',
        key: 'hangTime',
        width: 15,
        type: 'text'
      },
      {
        name: 'Spiral (%)',
        key: 'efficiency',
        width: 15,
        type: 'text'
      },
      {
        name: 'EoE (%)',
        key: 'endOverEndEfficiency',
        width: 15,
        type: 'text'
      },
      {
        name: `Spin (${unitSystem.units.revs.abbreviation})`,
        key: 'spin',
        width: 15,
        type: 'text'
      }
    ]

    return baseHeaders.filter((header) => {
      if (sport.props.features.targetMetrics) {
        return sport.props.features.targetMetrics.includes(header.key)
      }
      return true
    })
  }, [sport, unitSystem])

  const targetPerformanceTableData = useMemo(() => {
    const data = []
    if (target?.targetSummary) {
      for (const playerSessionId in target.targetSummary) {
        const playerSession = playersSessions.byId.map[playerSessionId]
        if (playerSession) {
          const row = getTargetSummaryPerformanceTableRowData(
            target,
            playerSession
          )
          data.push(row)
        }
      }
    }
    return data
  }, [target])

  const targetFlights = useMemo(() => {
    const flights: RawFlightEventData[] = []
    if (target?.targetSummary) {
      for (const playerSessionId in target.targetSummary) {
        const flightStats = target.targetSummary[playerSessionId].flightStats
        if (flightStats) {
          flightStats.forEach((flightStat) => {
            const flight = events.rawData[flightStat.id]
            if (flight && isEventTypeData.flight(flight)) {
              flights.push(flight)
            }
          })
        }
      }
    }
    return flights
  }, [target, events.rawData])

  const challengeOptions = challengeArray.map((challenge) => {
    return { name: challenge.name, value: challenge.id }
  })

  const activeChallenge = Object.values(targets.items).find((challenge) => {
    return !challenge.timeEnd || !challenge.startTime
  })

  // set all players selected in target on mount
  useEffect(() => {
    if (targetPlayers.length < 1) return
    setPlayersInDropZone(targetPlayers.map((player) => player.id))
  }, [targetPlayers])

  return (
    <>
      <></>
      {challengeArray.length < 1 ? (
        <div className={styles.noChallengeContainer}>
          <div className={styles.placeHolderText}>
            No sub-sessions found for this session...
          </div>
          <Button
            type='submit'
            className='btn--border btn--long'
            handleClick={openNewChallengeModal}
          >
            <h4 id='create-player'>Create new sub-session</h4>
          </Button>
        </div>
      ) : (
        <>
          <></>
          {challenge && (
            <>
              <StrackEvents
                events={targetFlights}
                active={active}
                canvasView={canvasView}
                strack={strack}
                canvasStyle={canvasStyle}
                strackReady={strackReady}
                maxNumberOfEvents={live ? 10 : 200}
              />
              <div className={styles.challengeHeaderDesktop}>
                <div className={styles.challengeSelectContainer}>
                  <div className={styles.challengeSelect}>
                    <BasicSelect
                      variant='standard'
                      selected={targets.selected.id}
                      onChange={(value) => {
                        dispatch(
                          setSelectedChallenge(targets.items[value], sport)
                        )
                      }}
                      label='Select Sub Session'
                      options={challengeOptions}
                      size={'small'}
                      fontSize={12}
                      padding={0}
                      labelMarginTop={8}
                      labelShrink={true}
                    />
                  </div>
                  <Button
                    className={`button btn--primary btn--thin`}
                    handleClick={() => {
                      editChallenge()
                    }}
                  >
                    Edit sub-session
                  </Button>
                </div>
                <div style={{ display: 'flex' }}>
                  {live ? (
                    <>
                      <></>
                      {activeChallenge ? (
                        <>
                          <></>
                          {activeChallenge.id === challenge.id ? (
                            <>
                              <></>
                              {!activeChallenge.startTime ? (
                                <Button
                                  className={`button btn--primary btn--thin`}
                                  handleClick={() =>
                                    startChallenge(activeChallenge)
                                  }
                                >
                                  Start sub-session
                                </Button>
                              ) : (
                                <>
                                  <div className={styles.recordingContainer}>
                                    <div className={styles.rec}></div>
                                    <h5>Sub-session in progress</h5>
                                  </div>
                                  <Button
                                    className={`button btn--primary btn--thin`}
                                    handleClick={() =>
                                      stopChallenge(activeChallenge)
                                    }
                                  >
                                    Stop sub-session
                                  </Button>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div className={styles.recordingContainer}>
                                <div className={styles.rec}></div>
                                <h5>
                                  Sub-session in progress -{' '}
                                  {activeChallenge.name}
                                </h5>
                              </div>
                              <Button
                                className={`link sportable-red btn--xs`}
                                handleClick={() =>
                                  dispatch(
                                    setSelectedChallenge(activeChallenge, sport)
                                  )
                                }
                              >
                                view
                              </Button>
                            </>
                          )}
                        </>
                      ) : (
                        <Button
                          className={`button btn--primary btn--thin`}
                          handleClick={openNewChallengeModal}
                        >
                          New sub-session
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      className={`button btn--primary btn--thin`}
                      handleClick={openNewChallengeModal}
                    >
                      New sub-session
                    </Button>
                  )}
                </div>
              </div>
              {challenge?.Targets?.length < 1 ? (
                <div className={styles.noTargetContainer}>
                  <div className={styles.placeHolderText}>
                    No targets found...
                  </div>
                  <Button
                    type='submit'
                    className='btn--border btn--long'
                    handleClick={() => {
                      editChallenge()
                    }}
                  >
                    <h4 id='create-player'>Set targets</h4>
                  </Button>
                </div>
              ) : (
                <>
                  <div className={styles.targetDetailsContainerDesktop}>
                    {target && strackReady && (
                      <ExpandableCard
                        width={'340px'}
                        height={'140px'}
                        dropdown={true}
                      >
                        <TargetCard item={target} size='large' />
                      </ExpandableCard>
                    )}
                  </div>
                  <div className={styles.kickDropZoneContainerDesktop}>
                    <CardList
                      col={12}
                      items={[{}]}
                      scrollerId={`scroller-${1}`}
                      className='maxHeight'
                    >
                      <div className={styles.dropZoneContainer}>
                        <div className={styles.dropZone}>
                          {active && target && (
                            <TargetDropZone
                              playerIds={playersInDropZone}
                              target={target}
                            />
                          )}
                        </div>
                        <div className={styles.playerKey}>
                          <ListInset items={targetPlayers}>
                            <PlayerKeyItem
                              playerIds={playersInDropZone}
                              onUpdate={(name, checkedList, item) =>
                                setPlayersInDropZone(checkedList)
                              }
                            />
                          </ListInset>
                        </div>
                      </div>
                    </CardList>
                  </div>
                  <div className={styles.targetTableContainerDesktop}>
                    <CardList
                      col={12}
                      items={[{}]}
                      scrollerId={`scroller-${1}`}
                      className='maxHeight'
                    >
                      <div className='card-table-container'>
                        <div className={styles.switch}>
                          <Button
                            className={`link`}
                            handleClick={() =>
                              setShowPerformance(!showPerformance)
                            }
                          >
                            {showPerformance ? 'Show Metrics' : 'Show Accuracy'}
                          </Button>
                        </div>
                        <div className={styles.table}>
                          {showPerformance ? (
                            <Table
                              // Table props
                              options={{
                                initialOrder: 'dec',
                                initialSortBy: 'player',
                                sortActive: false
                              }}
                              id={'performance'}
                              headerFont={13}
                              tableClass={'minimalistBlack'}
                              className={'small-container'}
                              data={targetPerformanceTableData}
                              headers={[
                                {
                                  name: 'Player',
                                  key: 'player',
                                  width: 25,
                                  type: 'text'
                                },
                                {
                                  name: 'Good',
                                  key: 'good',
                                  width: 15,
                                  type: 'text',
                                  headerColor: 'rgba(244, 247, 99, 0.5)'
                                },
                                {
                                  name: 'Moderate',
                                  key: 'moderate',
                                  width: 15,
                                  type: 'text',
                                  headerColor: 'rgba(100,0,0,0.5)'
                                },
                                {
                                  name: 'Poor',
                                  key: 'poor',
                                  width: 15,
                                  type: 'text',
                                  headerColor: 'rgba(0,0,100,0.5)'
                                },
                                {
                                  name: `Avg distance to target (${unitSystem.units.distance.abbreviation})`,
                                  key: 'averageDist',
                                  width: 15,
                                  type: 'number'
                                },
                                {
                                  name: 'Count',
                                  key: 'count',
                                  width: 15,
                                  type: 'number'
                                }
                              ]}
                            />
                          ) : (
                            <Table
                              // Table props
                              options={{
                                initialOrder: 'dec',
                                initialSortBy: 'player',
                                sortActive: false
                              }}
                              headerFont={13}
                              tableClass={'minimalistBlack'}
                              className={'small-container'}
                              id={'metrics'}
                              data={targetMetricTableData}
                              headers={[
                                {
                                  name: 'Player',
                                  key: 'player',
                                  width: 25,
                                  type: 'text'
                                },
                                ...targetMetricTableHeaders
                              ]}
                            />
                          )}
                        </div>
                      </div>
                    </CardList>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
