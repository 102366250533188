import React from 'react'

export function CloudIcon({ color }: { color?: string }) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 298.834 298.834'
      xmlSpace='preserve'
    >
      <g>
        <g>
          <path
            d='M256.581,147.826c-2.885-22.698-21.553-40.538-44.624-42.136c-12.612-30.734-42.667-51.075-76.186-51.075
			c-43.248,0-78.817,33.506-82.109,75.923C23.788,132.214,0,157.048,0,187.333c0,31.367,25.519,56.886,56.886,56.886h193.558
			c26.682,0,48.39-21.707,48.39-48.39C298.834,171.223,280.378,150.85,256.581,147.826z M250.443,231.168H56.886
			c-24.171,0-43.836-19.664-43.836-43.835s19.665-43.836,43.836-43.836c0.798,0,1.668,0.029,2.66,0.089
			c3.77,0.224,6.919-2.773,6.919-6.513c0-0.063-0.001-0.125-0.003-0.188c0.045-38.178,31.12-69.221,69.308-69.221
			c29.502,0,55.817,18.727,65.482,46.601c0.884,2.548,3.175,4.477,6.743,4.373c16.236-0.571,32.036,11.354,35.307,28.8h-19.11
			c-3.604,0-6.525,2.921-6.525,6.525c0,3.604,2.921,6.525,6.525,6.525h26.249c19.486,0,35.34,15.854,35.34,35.34
			C285.784,215.315,269.931,231.168,250.443,231.168z'
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}
