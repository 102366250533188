import { useMemo } from 'react'
import { useAppSelector } from '../../store/hooks'
import { useFormattedSession } from '../sessions/hooks'

export const useTargets = () => {
  const targets = useAppSelector((state) => state.targets)
  return targets
}

export const useChallengeArray = () => {
  const targets = useTargets()
  const challengeArray = useMemo(() => {
    return Object.values(targets.items)
  }, [targets.items])
  return challengeArray
}

export const useChallenge = () => {
  const targets = useTargets()
  const challenge = useMemo(() => {
    return targets.items[targets.selected.id]
  }, [targets.items, targets.selected.id])

  return { challenge }
}

export const useSelectedTarget = () => {
  const targets = useTargets()
  const { challenge } = useChallenge()
  const formattedSession = useFormattedSession(challenge?.sessionId)

  const target = useMemo(() => {
    const { selectedTarget } = targets.selected
    return challenge?.Targets?.find((target) => target.id === selectedTarget)
  }, [challenge, targets.selected.selectedTarget])

  const targetPlayers = useMemo(() => {
    if (!formattedSession || !target?.targetPlayerSessions) return []
    return (
      target.targetPlayerSessions
        // Check if playersSessionsId exists in session
        .filter(
          (tps) =>
            tps.playersSessionsId &&
            formattedSession.playersSessions.byId.map[tps.playersSessionsId]
        )
        // Return player
        .map((tps) => {
          return formattedSession.playersSessions.byId.map[
            tps.playersSessionsId
          ].player
        })
    )
  }, [target])

  return {
    target,
    targetPlayers
  }
}
