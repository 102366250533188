import {
  // API actions
  SET_SESSION_CHALLENGES,
  UPDATE_CHALLENGE,
  CREATE_CHALLENGE,
  SET_SELECTED_CHALLENGE,
  SET_SELECTED_TARGET,
  UPDATE_TARGET,
  DELETE_TARGET,
  CREATE_TARGET,
  ADD_TARGET_PLAYER_SESSION,
  REMOVE_TARGET_PLAYER_SESSION
} from './types'

import { setError } from '../../ui/error/actions'
import { request } from '../../utils/request_handler'
import { updateSession } from '../sessions/actions'
import { API_ROOT_URL } from '../../const'
import { Challenge, Target } from './types'
import { Action, ActionCreator, Dispatch } from 'redux'
import { getUnitSystemFromSession } from '../units/functions'
import { Sport } from '../sports/data_types'

// Get teams of logged in user
export function getSessionChallenges(sessionId: string) {
  return (dispatch, getState) => {
    const { units } = getState()
    const session = getState().sessions.rawData[sessionId]
    const unitSystem = getUnitSystemFromSession(session, units)

    const success = (response: { data: any[] }) => {
      if (response.data) {
        // Filter out drills //

        const challenges = response.data.filter((subsession) => {
          return subsession.type === 0
        })

        const firstChallenge = challenges[0]
        let firstTarget

        if (firstChallenge) {
          firstTarget = challenges[0].Targets[0]
        }

        dispatch({
          type: SET_SESSION_CHALLENGES,
          payload: { data: challenges, firstTarget }
        })
        dispatch(
          updateSession({
            id: sessionId,
            challenges: challenges ? challenges.map((x) => x.id) : []
          })
        )

        // Get target summaries for all challenges
        challenges.forEach((challenge) => {
          if (challenge.Targets) {
            challenge.Targets.forEach((target) => {
              dispatch(getFullTarget(target.id, challenge.id))
            })
          }
        })
      } else {
        dispatch({
          type: SET_SESSION_CHALLENGES,
          payload: { sessionId, data: [] }
        })
        dispatch(
          updateSession({
            id: sessionId,
            challenges: []
          })
        )
      }
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get session challenges. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}/challenges?units=${unitSystem.key}`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export const clearSessionChallenges: ActionCreator<Action> = (
  sessionId: string
) => {
  return {
    type: SET_SESSION_CHALLENGES,
    payload: { sessionId, data: [] }
  }
}

// Create Challenge

export const createChallenge = (
  challenge: Challenge,
  cb: (arg: Challenge) => void
) => {
  return (dispatch: Dispatch, getState) => {
    const { units } = getState()
    const sessionId = getState().sessions.selectedId
    const session = getState().sessions.rawData[sessionId]
    const unitSystem = getUnitSystemFromSession(session, units)
    const success = (response) => {
      dispatch({
        type: CREATE_CHALLENGE,
        payload: { data: response.data }
      })
      cb(response.data)
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to create challenge. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `challenges?units=${unitSystem.key}`,
      method: 'post',
      data: challenge,
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Update challenge
export function updateChallenge(challenge: Challenge, callback?) {
  return (dispatch, getState) => {
    const { units } = getState()
    const sessionId = getState().sessions.selectedId
    const session = getState().sessions.rawData[sessionId]
    const unitSystem = getUnitSystemFromSession(session, units)
    const success = (response) => {
      dispatch({
        type: UPDATE_CHALLENGE,
        payload: response.data
      })
      if (callback) callback()
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to update challenge. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `challenges?units=${unitSystem.key}`,
      data: challenge,
      method: 'put',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Set status challenge
export const setSelectedChallenge = (challenge: Challenge, sport: Sport) => {
  return (dispatch) => {
    const firstTarget = challenge.Targets[0]
    if (firstTarget) {
      dispatch(getFullTarget(firstTarget.id, challenge.id))
    }
    dispatch({
      type: SET_SELECTED_CHALLENGE,
      payload: challenge
    })
  }
}

// Set selected target for challenge
export function setSelectedTarget(targetId) {
  return (dispatch, getState) => {
    const selectedChallengeId = getState().targets.selected.id
    if (targetId) {
      dispatch(getFullTarget(targetId, selectedChallengeId))
    }
    dispatch({
      type: SET_SELECTED_TARGET,
      payload: targetId
    })
  }
}

// Get Full Target Summary
export function getFullTarget(targetId: string, challengeId: string) {
  return (dispatch, getState) => {
    const { targets } = getState()
    const { units } = getState()
    const sessionId = getState().sessions.selectedId
    const session = getState().sessions.rawData[sessionId]
    const unitSystem = getUnitSystemFromSession(session, units)
    // Check if challenge is active in live session
    if (challengeId) {
      const challenge = targets.items[challengeId]
      if (challenge && !challenge.startTime) return
    }

    const success = (response) => {
      const data = response.data
      dispatch({
        type: UPDATE_TARGET,
        payload: data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get target summary. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `targets/${targetId}?units=${unitSystem.key}`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Create Target
export function createTarget(data) {
  return (dispatch, getState) => {
    const { units } = getState()
    const sessionId = getState().sessions.selectedId
    const session = getState().sessions.rawData[sessionId]
    const unitSystem = getUnitSystemFromSession(session, units)
    const success = (response) => {
      dispatch({
        type: CREATE_TARGET,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to create target. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `targets?units=${unitSystem.key}`,
      data,
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Update target
export function updateTarget(data: Target, targetId: string) {
  return (dispatch, getState) => {
    const { units } = getState()
    const sessionId = getState().sessions.selectedId
    const session = getState().sessions.rawData[sessionId]
    const unitSystem = getUnitSystemFromSession(session, units)
    const { subSessionId } = data
    const currentTargetState = getState().targets.items[
      subSessionId
    ].Targets.find((target) => target.id === targetId)
    dispatch({
      type: UPDATE_TARGET,
      payload: data
    })
    const success = (response) => {
      dispatch({
        type: UPDATE_TARGET,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch({
        type: UPDATE_TARGET,
        payload: currentTargetState
      })
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to update target. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `targets/${targetId}?units=${unitSystem.key}`,
      data,
      method: 'put',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Delete player
export function deleteTarget(targetId, subSessionId) {
  return (dispatch) => {
    const success = () => {
      dispatch({
        type: DELETE_TARGET,
        payload: { id: targetId, subSessionId }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to delete target. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `targets/${targetId}`,
      method: 'delete',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

export function updateTargetPlayerSessions(playerSessionId, target) {
  const { targetPlayerSessions, id, subSessionId } = target
  const data = {
    targetId: id,
    playersSessionsId: playerSessionId
  }
  if (targetPlayerSessions) {
    const targetPlayerSession = targetPlayerSessions.find(
      (tps) => tps.playersSessionsId === playerSessionId
    )
    if (targetPlayerSession) {
      return removeTargetPlayerSession(targetPlayerSession, subSessionId)
    } else {
      return addTargetPlayerSession(data, subSessionId)
    }
  } else {
    return addTargetPlayerSession(data, subSessionId)
  }
}

export function addTargetPlayerSession(data, challengeId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: ADD_TARGET_PLAYER_SESSION,
        payload: { data: response.data, challengeId }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to create target player session. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `targets_players_sessions`,
      data,
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function removeTargetPlayerSession(targetPlayerSession, challengeId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: REMOVE_TARGET_PLAYER_SESSION,
        payload: { targetPlayerSession, challengeId }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to delete target player session. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `targets_players_sessions/${targetPlayerSession.id}`,
      method: 'delete',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}
