import { useState } from 'react'

export const useImageInputLogic = (props) => {
  const [img, setImage] = useState(props.img)

  const getFiles = (file: any) => {
    if (file.type.slice(0, 5) === 'image') {
      props.onImageChange(file.base64)
      setImage(file.base64)
    } else {
      console.log('Error!: That file was not an image')
    }
  }
  return { getFiles, img }
}
