import { useMemo } from 'react'
import { useEvents } from '../hooks'
import { RawFlightEventData } from './types'
import { getLatestKick } from './functions'
import { eventTypes } from '../data_types'
import { RawEventData } from '../types'

export function useLatestKick() {
  const events = useEvents()
  return useMemo(() => {
    const eventsArray = Object.values(events.rawData)
    const flightsArray = eventsArray.filter(
      (event: RawEventData): event is RawFlightEventData => {
        return event.event?.type === eventTypes.items.flight.value
      }
    )

    return getLatestKick(flightsArray)
  }, [events.rawData])
}

export function useLatestFlight() {
  const events = useEvents()
  return useMemo(() => {
    const eventsArray = Object.values(events.rawData)
    const flightsArray = eventsArray.filter(
      (event: RawEventData): event is RawFlightEventData => {
        return event.event?.type === eventTypes.items.flight.value
      }
    )

    // Sort by start time bringing most recent to the top
    flightsArray.sort((a, b) => {
      return b.startTime - a.startTime
    })

    return flightsArray[0]
  }, [events.rawData])
}
