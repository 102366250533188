import React from 'react'

export interface HomeIconProps {
  scale?: number
  color?: string
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  style?: React.CSSProperties
}

export const HomeIcon = ({
  scale = 1,
  color = '#8CB09D',
  handleClick = undefined,
  style
}: HomeIconProps) => {
  return (
    <div onClick={handleClick} style={style}>
      <svg
        width={`${29 * scale}`}
        height={`${28 * scale}`}
        viewBox='0 0 28 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M25.8814 13.8085L15.3157 3.24838L14.6075 2.54018C14.4462 2.37988 14.228 2.28992 14.0005 2.28992C13.7731 2.28992 13.5548 2.37988 13.3935 2.54018L2.11964 13.8085C1.9543 13.9732 1.82363 14.1694 1.73533 14.3854C1.64704 14.6015 1.60292 14.833 1.60558 15.0664C1.61652 16.0289 2.41769 16.7972 3.38019 16.7972H4.5423V25.7031H23.4587V16.7972H24.6454C25.113 16.7972 25.5532 16.614 25.8841 16.2831C26.047 16.1208 26.1761 15.9277 26.2639 15.715C26.3516 15.5024 26.3963 15.2745 26.3954 15.0445C26.3954 14.5796 26.2122 14.1394 25.8814 13.8085V13.8085ZM15.5318 23.7343H12.4693V18.1562H15.5318V23.7343ZM21.49 14.8285V23.7343H17.2818V17.4999C17.2818 16.8956 16.7923 16.4062 16.188 16.4062H11.813C11.2087 16.4062 10.7193 16.8956 10.7193 17.4999V23.7343H6.51105V14.8285H3.88605L14.0032 4.71948L14.6349 5.35112L24.1177 14.8285H21.49Z'
          fill={color}
        />
      </svg>
    </div>
  )
}
