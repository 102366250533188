import { useAppSelector } from '../../store/hooks'
import Themes from './theme'

export const useTheme = () => {
  const config = useAppSelector((state) => state.config)

  let theme = Themes.matchTracker
  if (config) {
    theme = config.application.theme
  }

  return theme
}
