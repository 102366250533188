import { SET_UNIT_SYSTEM, SET_UNIT_SYSTEMS } from './action_types'

export const setUnitSystems = (unitSystems: string[]) => {
  return {
    type: SET_UNIT_SYSTEMS,
    payload: unitSystems
  }
}

export const setUnitSystem = (unitSystem) => {
  return {
    type: SET_UNIT_SYSTEM,
    payload: unitSystem
  }
}
