import React from 'react'
import { Team } from '../../metrics_server/teams/types'
import { AddNewButton } from '../AddNewButton/AddNewButton'
import ScrollerX from '../ScollerX/ScollerX'
import { TeamLogo } from '../TeamLogo/TeamLogo'
import styles from './TeamSelectBar.module.scss'
import { sortAlphabeticallyByKey } from '../../utils/helpers/index'

export interface TeamSelectBarProps {
  className?: any
  disabled?: boolean
  label?: string
  teams: Team[]
  selectedTeamId: string
  disabledTeam?: string
  selectTeamCallback: (teamId: string) => void
  disabledMessage?: string
  addTeamCallback: () => void
}

export const TeamSelectBar: React.FC<TeamSelectBarProps> = (props) => {
  const {
    className,
    disabled,
    label,
    teams,
    selectedTeamId,
    disabledTeam,
    selectTeamCallback,
    disabledMessage,
    addTeamCallback
  } = props

  const orderedTeams = sortAlphabeticallyByKey(teams, 'name')

  const getTeams = () => {
    return orderedTeams.map((team) => {
      let isCurrentTeam = team.id === selectedTeamId
      let isDisabledTeam = team.id === disabledTeam
      return (
        <div
          id='team'
          key={team.id}
          className={`${
            isCurrentTeam ? styles.teamContainer : styles.teamInactive
          } ${isDisabledTeam ? styles.teamDisabled : ''}`}
          onClick={() => {
            !disabled &&
              !isDisabledTeam &&
              !isCurrentTeam &&
              selectTeamCallback(team.id)
          }}
        >
          <div
            className={styles.logoContainer}
            style={{ marginLeft: '15px', marginRight: '15px' }}
          >
            <TeamLogo logo={team.logo} color={team.color} size={30} />
          </div>
          <h5 className={styles.teamName}>{team.name}</h5>
          {isDisabledTeam && (
            <div className={styles.disabledMessage}>{disabledMessage}</div>
          )}
        </div>
      )
    })
  }

  return (
    <React.Fragment>
      {label && <label className={styles.teamBarTitle}>{label}</label>}
      <div
        style={{ height: '100%' }}
        className={`${className} ${styles[className]}`}
      >
        <ScrollerX scrollerId='scroller' scrollerClass='form-scroller-x'>
          <div
            className={styles.teamBarContainer}
            style={{ display: 'inline-flex' }}
          >
            {addTeamCallback && (
              <AddNewButton
                addCallBack={addTeamCallback}
                disabled={disabled}
                size={45}
              />
            )}
            {getTeams()}
          </div>
        </ScrollerX>
      </div>
    </React.Fragment>
  )
}
