import React from 'react'
import styles from './CommentatorStatistic.module.scss'

export interface CommentatorStatisticProps {
  title: string
  icon?: string
  stat?: number | string
  tag?: number | string
  iconSrc?: string
  inline?: boolean
  statColor?: string
  centerAlign?: boolean
}

export const CommentatorStatistic = ({ title, stat, tag, statColor }: any) => {
  return (
    <div className={styles.commentatorStatContainer}>
      <div className={styles.commentatorStatWrapper}>
        <p className={styles.commentatorStatTitle}>{stat && title}</p>
      </div>
      <div className={styles.commentatorStatWrapper}>
        <h1 className={styles.commentatorStat}>
          {stat && stat}
          {tag && <span className={styles.commentatorStatTag}>{tag}</span>}
          {!stat && title}
        </h1>
      </div>
    </div>
  )
}
