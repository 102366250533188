import { useMemo } from 'react'
import { useSessionStrackProps } from '../../components/Strack/hooks'
import { ViewStrackProps } from '../../components/Views/Views.types'

export const useSessionSetupViewStrackProps = (view): ViewStrackProps => {
  const strackProps = useSessionStrackProps('setup')

  // Manage canvas visibility //
  const hiddenCanvases = useMemo(() => {
    return []
  }, [])
  // ============= //

  return useMemo(() => {
    return {
      enabled: true,
      ...strackProps,
      hiddenCanvases
    }
  }, [strackProps, hiddenCanvases])
}
