import React, { useEffect, useState } from 'react'
import { isPropEmpty } from '../../utils/helpers'
import { connect } from 'react-redux'
import * as teamActions from '../../metrics_server/teams/actions'
import * as userActions from '../../metrics_server/user/actions'
import * as organisationActions from '../../metrics_server/organisations/actions'
import { UserState } from '../../metrics_server/user/types'
import { RouterState } from '../../ui/router/types'
import { OrganisationsState } from '../../metrics_server/organisations/types'
import { OrganisationAndTeamForms } from './form_config'

const actions = {
  ...teamActions,
  ...userActions,
  ...organisationActions
}

type LoadingContainers = {
  createProfileButton: string
  createTeamButton: string
}

export interface CreateOrganisationContainerProps {
  user: UserState
  loadingContainers: LoadingContainers
  componentId: string
  createTeam: (values: any, cb: () => void) => void
  checkOrganisation: (values: any, cb: () => void) => void
  joinTeam: (team: any, cb: () => void) => void
  goBack: () => void
  updateNewOrgansationState: (values: any, cb: () => void) => void
  toggleIsNewOrg: (bool: boolean) => void
  isSetup: boolean
  isNewOrg: boolean
  signoutUser: () => void
  setRedirect: (path: string) => void
  createOrganisation: (values: any, cb: () => void) => void
  organisations: OrganisationsState
  router: RouterState
}

export const CreateOrganisationContainer: any = (ChildComponent) => {
  const ComposedComponent = (props) => {
    const [componentId, setComponentId] = useState('createProfile')
    const [loadingContainers, setLoadingContainers] = useState({
      createProfileButton: 'createProfile',
      createTeamButton: 'createTeam'
    })

    const [isSetup, setIsSetup] = useState(
      props.router.prevRoute === '/login' ||
        isPropEmpty(props.organisations.items)
    )
    const [isNewOrg, setIsNewOrg] = useState(
      props.router.prevRoute === '/login' ||
        isPropEmpty(props.organisations.items)
    )

    useEffect(() => {
      if (props.router.prevRoute === '/login') {
        setIsSetup(true)
        setIsNewOrg(true)
      } else if (isPropEmpty(props.organisations.items)) {
        setIsSetup(true)
        setIsNewOrg(true)
      }
    }, [props.router.prevRoute, props.organisations.items])

    const createTeam = (values, cb) => {
      if (isNewOrg) {
        props.createOrganisation(values, () => {
          cb()
          props.setRedirect('/teams')
        })
      } else {
        props.createTeam(values, () => {
          cb()
          props.setRedirect('/teams')
        })
      }
    }

    const updateNewOrgansationState = (values, cb) => {
      props.updateNewOrgansationState(values)
      cb()
    }

    const checkOrganisation = (values, cb) => {
      const threeWords = [values.firstWord, values.secondWord, values.thirdWord]
      props.checkOrganisation({ threeWords }, cb)
    }

    const joinTeam = (team, cb) => {
      const data = {
        ...team
      }
      props.joinTeam(data, () => {
        cb()
        props.setRedirect('/teams')
      })
    }

    const toggleIsNewOrg = (bool) => {
      setIsNewOrg(bool)
    }

    return (
      <ChildComponent
        {...props}
        user={props.user}
        initialFormKey={
          isSetup
            ? OrganisationAndTeamForms.joinOrganisation
            : OrganisationAndTeamForms.joinTeam
        }
        loadingContainers={loadingContainers}
        componentId={componentId}
        createTeam={createTeam}
        checkOrganisation={checkOrganisation}
        joinTeam={joinTeam}
        updateNewOrgansationState={updateNewOrgansationState}
        toggleIsNewOrg={toggleIsNewOrg}
        isSetup={isSetup}
        isNewOrg={isNewOrg}
        signoutUser={props.signoutUser}
      />
    )
  }

  function mapStateToProps(state) {
    return {
      authentication: state.authentication,
      auth: state.auth,
      user: state.user,
      organisations: state.organisations,
      router: state.router
    }
  }

  return connect(mapStateToProps, actions)(ComposedComponent)
}
