import React from 'react'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import styles from './CloudStatus.module.scss'
import Loader from '../Loader/Loader'

export namespace CloudStatusType {
  export const upload = 'upload'
  export const disconnected = 'disconnected'
  export const loading = 'loading'
  export const hidden = 'hidden'
  export const uploaded = 'uploaded'
}

export type CloudStatusTypes = keyof typeof CloudStatusType

export interface CloudStatusProps {
  status: CloudStatusTypes
  color?: string
  size?: string
  onClick?: (e: any) => void
}

export const CloudStatus = ({
  status,
  color,
  size,
  onClick
}: CloudStatusProps) => {
  const handleOnCloudClick = (e) => {
    e.stopPropagation()
    if (!onClick) return null
    onClick(e)
  }

  return (
    <div className={styles.cloudIconContainer} onClick={handleOnCloudClick}>
      {status === CloudStatusType.upload && (
        <CloudUploadOutlinedIcon
          sx={{ color: color, fontSize: size || '25px' }}
        />
      )}
      {status === CloudStatusType.disconnected && (
        <CloudOffOutlinedIcon sx={{ color: color, fontSize: size || '25px' }} />
      )}
      {status === CloudStatusType.uploaded && (
        <CloudDoneIcon sx={{ color: color, fontSize: size || '25px' }} />
      )}
      {status === CloudStatusType.loading && (
        <div
          style={{
            display: 'flex',
            height: size || '25px',
            width: size || '25px'
          }}
        >
          <Loader color={color} />
        </div>
      )}
    </div>
  )
}
