import React, { useState } from 'react'
import { Button } from '../../../components/Button/Button'
import { FormContainer } from '../../../components/Forms/Form/Form'
import { OrganisationsState } from '../../../metrics_server/organisations/types'
import { getTeamOptions } from '../../../metrics_server/teams/functions'
import { TeamsState } from '../../../metrics_server/teams/types'
import { isPropEmpty } from '../../../utils/helpers'
import { OrganisationAndTeamForms } from '../form_config'

const validate = (values) => {
  const errors = {} as any
  if (!values.teams) {
    errors.teams = 'Required'
  }
  return errors
}

export interface JoinTeamFormProps {
  organisations: OrganisationsState | undefined
  switchForm: (formKey: string) => void
  joinTeam: (team, switchToNextForm) => void
  teams: TeamsState
  goBack: () => void
}

export const JoinTeamForm = (props: JoinTeamFormProps) => {
  const { checkedOrganisation, items } = props.organisations
  const { teams } = props

  const teamsToJoin = checkedOrganisation.teams.filter((team) => {
    return !teams.items[team.id]
  })

  const [possibleTeams, setPossibleTeams] = useState(
    getTeamOptions(teamsToJoin)
  )

  const [selectedTeamId, setSelectedTeamId] = useState(
    teamsToJoin[0] ? teamsToJoin[0].id : null
  )

  const getTeamNameFromId = (selectedTeamId) => {
    const team = teamsToJoin.find((team) => selectedTeamId === team.id)
    return team
  }

  const handleSubmit = (values) => {
    const { checkedOrganisation } = props.organisations
    const team = checkedOrganisation.teams.find((team) => {
      return values.teams === team.id
    })
    props.joinTeam(team, switchToNextForm)
  }

  const joinAllTeams = () => {
    teamsToJoin.map((team) => {
      props.joinTeam(team, switchToNextForm)
    })
  }

  const switchToNextForm = () => {
    props.switchForm(OrganisationAndTeamForms.createTeam)
  }

  const { goBack } = props

  const org = checkedOrganisation

  const [fields, setFields] = useState([
    {
      name: 'teams',
      type: 'searchable',
      label: 'Select Teams',
      options: possibleTeams,
      initialValue: selectedTeamId
    }
  ])

  return (
    <div style={{ width: '80%' }}>
      <div className='section' style={{ height: '15px' }}>
        {isPropEmpty(items) ? (
          <Button
            className='link'
            handleClick={() =>
              props.switchForm(OrganisationAndTeamForms.joinOrganisation)
            }
          >
            &lt; Not your organisation
          </Button>
        ) : (
          <Button className='link' handleClick={() => goBack()}>
            &lt; Back
          </Button>
        )}
      </div>

      {possibleTeams.length > 0 ? (
        <>
          <FormContainer
            fields={fields}
            validate={validate}
            onSubmit={(values) => handleSubmit(values)}
            submitText={'Join'}
            title={`Join ${isPropEmpty(items) ? org.name : 'team'}`}
            secondaryButtonText={'Or add a new team'}
            secondaryButtonHandleClick={() =>
              props.switchForm(OrganisationAndTeamForms.createTeam)
            }
          />
          <div>
            <Button className='link-2' handleClick={() => joinAllTeams()}>
              Join All Teams
            </Button>
          </div>
        </>
      ) : (
        <>
          <div>
            <Button
              className='link'
              handleClick={() =>
                props.switchForm(OrganisationAndTeamForms.createTeam)
              }
            >
              Create Team
            </Button>
          </div>
          <span>No Teams Available</span>
        </>
      )}
    </div>
  )
}
