import { sportTypes } from '../sports/data_types'
import { Base64Result, CSVFile, JSONUploadState, PitchFile } from './types'

export function isPitchFile(
  file: CSVFile | PitchFile | JSONUploadState
): file is PitchFile {
  return (file as PitchFile).selectedPitchId !== undefined
}

export function isCSVFile(
  file: CSVFile | PitchFile | JSONUploadState
): file is CSVFile {
  return (file as CSVFile).name !== undefined
}

export function isJSONUploadState(
  file: CSVFile | PitchFile | JSONUploadState
): file is JSONUploadState {
  return !isPitchFile(file) && !isCSVFile(file)
}

export const toBase64 = (file: Blob, fileType: string): Promise<Base64Result> =>
  new Promise((resolve, reject) => {
    if (!file) resolve({ type: fileType, base64: null })
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve({ type: fileType, base64: reader.result })
    reader.onerror = (error) => reject(error)
  })

export const extractBase64 = (
  results: Base64Result[],
  fileType: string
): string | ArrayBuffer => {
  let base64 = results.find((x) => x.type === fileType).base64
  if (base64) {
    if (typeof base64 === 'string') {
      base64 = base64.split(',')[1]
    }
  }
  return base64
}

export const totalStationDataIsValid = (anchorJson, pitchJson, type) => {
  const getNumberOfPoints = (json) => {
    let numberOfPoints = 0
    json.forEach((x) => {
      if (x['4'] && x['4'].charAt(0) === 'P') numberOfPoints++
    })
    return numberOfPoints
  }

  const checkNumberOfPointsIsValidForPitchType = (
    numberOfPoints,
    type,
    sportTypesItems
  ) => {
    if (
      type === sportTypesItems.rugbyUnion.value &&
      numberOfPoints === sportTypesItems.rugbyUnion.numberOfPoints
    ) {
      return true
    } else if (
      type === sportTypesItems.boxing?.value &&
      numberOfPoints === sportTypesItems.boxing?.numberOfPoints
    ) {
      return true
    }
    return false
  }

  if (!pitchJson) {
    const numberOfPoints = getNumberOfPoints(anchorJson)
    checkNumberOfPointsIsValidForPitchType(
      numberOfPoints,
      type,
      sportTypes.items
    )
    const isValid = checkNumberOfPointsIsValidForPitchType(
      numberOfPoints,
      type,
      sportTypes.items
    )
    return isValid
  } else {
    const numberOfPointsInAnchorJson = getNumberOfPoints(anchorJson)
    const numberOfPointsInPitchJson = getNumberOfPoints(pitchJson)
    const isValid =
      checkNumberOfPointsIsValidForPitchType(
        numberOfPointsInPitchJson,
        type,
        sportTypes.items
      ) && numberOfPointsInAnchorJson === 0
    return isValid
  }
}
