import React, { CSSProperties } from 'react'
import styles from './Teams.module.scss'
import { StrackController } from '../../../../utils/strack/strack'

export function AflTeams({
  strack,
  teams,
  highlightedSide,
  leftLogoPosition,
  rightLogoPosition,
  getCanvasCoordinate,
  placeHolderLogo,
  scale,
  getOverlayStyle,
  field
}) {
  let goalSquareWidth, goalSquareHeight

  let leftGoalSquare = field.leftGoalSquare
  let rightGoalSquare = field.rightGoalSquare

  leftLogoPosition = getCanvasCoordinate(
    scale,
    leftGoalSquare.topLeft.x,
    leftGoalSquare.topLeft.y,
    true
  )
  rightLogoPosition = getCanvasCoordinate(
    scale,
    rightGoalSquare.bottomLeft.x,
    rightGoalSquare.bottomLeft.y,
    true
  )

  if (strack.pitchFlipped) {
    leftLogoPosition = getCanvasCoordinate(
      scale,
      rightGoalSquare.bottomLeft.x,
      rightGoalSquare.bottomLeft.y,
      true
    )
    rightLogoPosition = getCanvasCoordinate(
      scale,
      leftGoalSquare.topLeft.x,
      leftGoalSquare.topLeft.y,

      true
    )
  }

  goalSquareWidth = Math.abs(
    leftGoalSquare.topLeft.x - leftGoalSquare.topRight.x
  )
  goalSquareHeight = Math.abs(
    leftGoalSquare.topLeft.y - leftGoalSquare.bottomLeft.y
  )

  const leftStyles: CSSProperties = {
    position: 'absolute',
    top: leftLogoPosition.scaleY,
    left: leftLogoPosition.scaleX,
    height: `${goalSquareHeight * scale}px`,
    width: `${goalSquareWidth * scale}px`
  }

  const rightStyles: CSSProperties = {
    position: 'absolute',
    top: rightLogoPosition.scaleY,
    left: rightLogoPosition.scaleX,
    height: `${goalSquareHeight * scale}px`,
    width: `${goalSquareWidth * scale}px`
  }

  const leftNamePosition = getCanvasCoordinate(
    scale,
    field.centerLeftPitchEdge.x,
    field.centerLeftPitchEdge.y,
    true
  )
  const rightNamePosition = getCanvasCoordinate(
    scale,
    field.centerRightPitchEdge.x,
    field.centerRightPitchEdge.y,
    true
  )

  let leftNameStyles: CSSProperties = {
    position: 'absolute',
    top: leftNamePosition.scaleY,
    left: leftNamePosition.scaleX - 15,
    transform: 'translate(-50%, -50%) rotate(-90deg)',
    transformOrigin: 'center'
  }

  let rightNameStyles: CSSProperties = {
    position: 'absolute',
    top: rightNamePosition.scaleY,
    left: rightNamePosition.scaleX + 15,
    transform: 'translate(-50%, -50%) rotate(90deg)',
    transformOrigin: 'center'
  }

  if (strack.pitchFlipped) {
    leftNameStyles = {
      position: 'absolute',
      top: rightNamePosition.scaleY,
      left: rightNamePosition.scaleX + 15,
      transform: 'translate(-50%, -50%) rotate(90deg)',
      transformOrigin: 'center'
    }

    rightNameStyles = {
      position: 'absolute',
      top: leftNamePosition.scaleY,
      left: leftNamePosition.scaleX - 15,
      transform: 'translate(-50%, -50%) rotate(-90deg)',
      transformOrigin: 'center'
    }
  }

  if (!teams[1]) return null

  return (
    <>
      <div style={leftNameStyles} className={styles.teamName}>
        {teams[0].name}
      </div>

      <div className={styles.leftAFLTeamContainer} style={leftStyles}>
        <div
          className={styles.colorOverlay}
          style={getOverlayStyle(teams, 0, highlightedSide)}
        />
        {teams[0].logo ? (
          <img
            id='team-logo'
            height={'30px'}
            className={styles.aflTeamImg}
            src={teams[0].logo}
            style={{ opacity: 0.5 }}
            alt=''
          />
        ) : (
          placeHolderLogo(teams[0].color)
        )}
      </div>
      <div style={rightNameStyles} className={styles.teamName}>
        {teams[1].name}
      </div>

      <div className={styles.rightAFLTeamContainer} style={rightStyles}>
        <div
          className={styles.colorOverlay}
          style={getOverlayStyle(teams, 1, highlightedSide)}
        />
        {teams[1].logo ? (
          <img
            id='team-logo'
            height={'30px'}
            className={styles.aflTeamImg}
            src={teams[1].logo}
            style={{ opacity: 0.5 }}
            alt=''
          />
        ) : (
          placeHolderLogo(teams[1].color)
        )}
      </div>
    </>
  )
}
