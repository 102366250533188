import React from 'react'
import { CreateOrganisationForm } from './CreateOrganisationForm/CreateOrganisationForm'
import { JoinOrganisationForm } from './JoinOrganisationForm/JoinOrganisationForm'
import { JoinTeamForm } from './JoinTeamForm/JoinTeamForm'

import { TeamForm } from './TeamForm/TeamForm'
import { Form } from '../../components/Views/Form/Form.types'

export enum OrganisationAndTeamForms {
  createOrganisation = 'createOrganisation',
  joinOrganisation = 'joinOrganisation',
  createTeam = 'createTeam',
  joinTeam = 'joinTeam'
}

interface FormConfig {
  createOrganisation: Form
  joinOrganisation: Form
  createTeam: Form
  joinTeam: Form
}

export const formConfig: FormConfig = {
  [OrganisationAndTeamForms.createOrganisation]: {
    key: OrganisationAndTeamForms.createOrganisation,
    name: 'Create Organisation',
    Component: CreateOrganisationForm,
    title: () => {
      return <h1>Create Organisation</h1>
    }
  },
  [OrganisationAndTeamForms.joinOrganisation]: {
    key: OrganisationAndTeamForms.joinOrganisation,
    name: 'Join Organisation',
    Component: JoinOrganisationForm,
    title: () => {
      return (
        <h1>
          Join An <span>Organisation</span>
        </h1>
      )
    }
  },
  [OrganisationAndTeamForms.createTeam]: {
    key: OrganisationAndTeamForms.createTeam,
    name: 'Create Team',
    Component: TeamForm,
    title: () => {
      return <h1>Create New Team</h1>
    }
  },
  [OrganisationAndTeamForms.joinTeam]: {
    key: OrganisationAndTeamForms.joinTeam,
    name: 'Join Team',
    Component: JoinTeamForm,
    title: () => {
      return <h1>Join Team</h1>
    }
  }
}

export const formMatrix = [
  [formConfig.joinOrganisation, formConfig.joinTeam],
  [formConfig.createOrganisation, formConfig.createTeam]
]
