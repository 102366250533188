import {
  CREATE_PLAYER,
  EDIT_PLAYER,
  DELETE_PLAYER,
  GET_PLAYER_USERS,
  SET_PLAYER
} from './types'

import { setError } from '../../ui/error/actions'
import { request } from '../../utils/request_handler'
import { API_ROOT_URL } from '../../const'

// Create new player
export function createPlayer(data, callback?) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: CREATE_PLAYER,
        payload: { ...response.data, teamId: data.teamId }
      })
      setPlayer(response.data)
      if (callback) callback(response.data)
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to create player. API request failed. Check log for more detail.`
        })
      )
    }

    // Default weight and height to 0
    if (!data.weight) data.weight = 0
    if (!data.height) data.height = 0

    const options = {
      url: 'players',
      data,
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

// Edit player
export function editPlayer(data) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: EDIT_PLAYER,
        payload: { ...response.data, teamId: data.teamId }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to edit player. API request failed. Check log for more detail.`
        })
      )
    }

    // Default weight and height to 0
    if (!data.weight) data.weight = 0
    if (!data.height) data.height = 0

    const options = {
      url: `players/${data.id}`,
      data,
      method: 'put',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

// Delete player
export function deletePlayer(id, teamId) {
  return (dispatch) => {
    const success = () => {
      dispatch({
        type: DELETE_PLAYER,
        payload: { id, teamId }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to delete player. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `players/${id}`,
      method: 'delete',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

// Get users who are players in an organisation
export function getPlayersAsUsersInOrganisation() {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: GET_PLAYER_USERS,
        payload: response
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get player users in an organisation. API request failed. Check log for more detail.`
        })
      )
    }
    const org_id = 1
    const options = {
      url: `organisations/${org_id}/players`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

export function setPlayer(player) {
  return {
    type: SET_PLAYER,
    payload: player
  }
}
