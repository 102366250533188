export const sportTypeKey = {
  australianRules: 'AustralianRules',
  rugbyUnion: 'RugbyUnion',
  rugbyLeague: 'RugbyLeague',
  americanFootball: 'AmericanFootball',
  boxing: 'Boxing',
  demonstration: 'Demonstration',
  canadianFootball: 'CanadianFootball',
  soccer: 'Soccer'
} as const
