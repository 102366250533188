import { TOGGLE_MODAL } from './types'
import { ModalState } from './types'

const initialState: ModalState = {
  active: false,
  handleProceed: null,
  handleClose: null,
  ChildComponent: null,
  className: null
}

export function modalReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return action.payload
    default:
      return state
  }
}
