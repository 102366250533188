import {
  // API actions
  UPDATE_GRAPHIC_STATE,
  SET_GRAPHICS_TYPES,
  SET_TOURNAMENTS,
  SET_ASPECT_RATIOS
} from './types'

import { request } from '../../utils/request_handler'
import { BROADCAST_ROOT_URL } from '../../const'
import { getUnitSystemFromSession } from '../units/functions'

// Get graphic types
export function getGraphicsTypes(sportType?) {
  return async (dispatch) => {
    const options = {
      url: '/graphic/types',
      method: 'get',
      baseURL: BROADCAST_ROOT_URL,
      withCredentials: true
    }
    const success = (response) => {
      dispatch({
        type: SET_GRAPHICS_TYPES,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      console.error(error)
    }
    return request(options, success, error, dispatch)
  }
}

// Get Tournaments
export function getTournaments(sportType?) {
  return async (dispatch) => {
    const options = {
      url: '/graphic/tournaments',
      method: 'get',
      baseURL: BROADCAST_ROOT_URL,
      withCredentials: true
    }
    const success = (response) => {
      dispatch({
        type: SET_TOURNAMENTS,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      console.error(error)
    }
    return request(options, success, error, dispatch)
  }
}

// Get Aspect Ratios
export function getAspectRatios(sportType?) {
  return async (dispatch) => {
    const options = {
      url: '/graphic/aspectRatios',
      method: 'get',
      baseURL: BROADCAST_ROOT_URL,
      withCredentials: true
    }
    const success = (response) => {
      dispatch({
        type: SET_ASPECT_RATIOS,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      console.error(error)
    }
    return request(options, success, error, dispatch)
  }
}

// Get session graphic
export function getSessionGraphic(sessionId, data) {
  return async (dispatch, getState) => {
    const { units } = getState()
    const session = getState().sessions.selected
    const unitSystem = getUnitSystemFromSession(session, units)

    dispatch({
      type: UPDATE_GRAPHIC_STATE,
      payload: { data, generating: true }
    })

    const success = (response) => {
      dispatch({
        type: UPDATE_GRAPHIC_STATE,
        payload: {
          data,
          image: response.data,
          generating: false
        }
      })
    }

    const error = (error, errorType) => {
      dispatch({
        type: UPDATE_GRAPHIC_STATE,
        payload: { data, image: null, generating: false }
      })
    }

    const options = {
      url: `/sessions/${sessionId}/graphic?units=${unitSystem.key}`,
      method: 'post',
      data,
      baseURL: BROADCAST_ROOT_URL,
      withCredentials: true,
      responseType: 'text'
    }

    return request(options, success, error, dispatch)
  }
}
