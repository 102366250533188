import React, { useEffect } from 'react'
import styles from './Navbar.module.scss'
import { sportableColors, uiTypes } from '../../const'

// New icons
import { Home } from './icons/Home'
import { Settings } from './icons/Settings'
import { Team } from './icons/Team'
import { Activity } from './icons/Activity'
import { CenterButton } from './CenterButton'
import { useTheme } from '../../ui/config/hook'

type GoTo = (route: string) => any

export interface NavbarProps {
  active?: string
  goTo?: GoTo
  uiType?
  centerButton: {
    callback: () => void
    text: string
    icon: string
    disabled: boolean
    loading: boolean
    visible: boolean
  }
}

export const NavBar: React.FC<NavbarProps> = ({
  active,
  goTo,
  uiType,
  centerButton
}) => {
  const [selectedRoute, setSelectedRoute] = React.useState<string | null>(null)
  const theme = useTheme()

  useEffect(() => {
    if (active !== selectedRoute) {
      setSelectedRoute(null)
    }
  }, [active])

  const selectRoute = (item: Route) => {
    setSelectedRoute(item.goTo)
    goTo && goTo(item.goTo)
  }

  const getRoutes = (active: string) => {
    return routes.map((item) => {
      const isActive = selectedRoute
        ? selectedRoute === item.type
        : active === item.type

      if (
        uiType?.value === uiTypes.commentatorTool.value &&
        item.type === '/teams'
      ) {
        item.hidden = true
      }

      if (item.type === 'center') {
        return (
          <React.Fragment key={item.type}>
            <CenterButton item={item} {...centerButton} />
          </React.Fragment>
        )
      }

      return (
        <div key={item.type} className={styles.navItem}>
          <div
            className={`${styles.navItemInner} ${
              item.hidden ? styles.hidden : ''
            }`}
            onClick={() => !selectedRoute && !isActive && selectRoute(item)}
          >
            {item.SvgComponent && (
              <item.SvgComponent
                color={
                  isActive
                    ? theme.appColor
                    : sportableColors.colors.sportableGreyDark
                }
              />
            )}
            <p style={{ color: isActive && theme.appColor }}>{item.name}</p>
          </div>
        </div>
      )
    })
  }

  return <div className={styles.navBarContainer}>{getRoutes(active)}</div>
}

interface Route {
  type: string
  SvgComponent?: ({ color }: { color: any }) => JSX.Element
  name?: string
  goTo: string
  hidden?: boolean
}

let routes: Route[] = [
  {
    type: '/dashboard',
    SvgComponent: Home,
    name: 'Dashboard',
    goTo: '/dashboard',
    hidden: true
  },
  {
    type: '/teams',
    SvgComponent: Team,
    name: 'Teams',
    goTo: '/teams'
  },
  {
    type: 'center',
    goTo: null
    // hidden: process.env.MS_ENV === 'cloud'
  },
  {
    type: '/activitylist',
    SvgComponent: Activity,
    name: 'Sessions',
    goTo: '/activitylist'
  },
  {
    type: '/settings',
    SvgComponent: Settings,
    name: 'Settings',
    goTo: '/settings'
    // hidden: true,
  }
]
