import React from 'react'

import { Button } from '../../../Button/Button'

import { Form } from '../Form.types'

import styles from '../Form.module.scss'

interface FormHeaderProps extends Form {
  switchToPreviousForm: () => void
  getFormByFormKey: (formKey: string) => Form
}

export function Header(props: FormHeaderProps) {
  const {
    getFormByFormKey,
    previousFormKey,
    switchToPreviousForm,
    logoImg,
    title,
    message
  } = props

  const previousForm = getFormByFormKey(previousFormKey)
  let previousButtonText = null
  if (previousForm) previousButtonText = previousForm.name

  return (
    <React.Fragment>
      <div className='section' style={{ height: '15px' }}>
        {!!previousForm && (
          <Button className='link' handleClick={switchToPreviousForm}>
            &lt; {previousButtonText ? previousButtonText : 'Back'}
          </Button>
        )}
        {!!logoImg && (
          <img className={styles.headerLogo} src={logoImg} alt='' />
        )}
      </div>
      <div>
        <div className={styles.formTitle}>
          {!!title && (
            <>
              {typeof title === 'string' ? <h1>{title}</h1> : <props.title />}
            </>
          )}
          {message && <h5>{message}</h5>}
        </div>
      </div>
    </React.Fragment>
  )
}
