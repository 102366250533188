import React from 'react'
import downloadIcon from '../../../../assets/img/download-inverse.svg'
import styles from '../Notification.module.scss'

export interface DownloadNotificationType {
  text?: string
}
export interface DownloadNotificationProps {
  clear: () => void
  notification: DownloadNotificationType
}

export const DownloadNotification = (props: DownloadNotificationProps) => {
  const { notification } = props

  return (
    <div>
      <div
        style={{ backgroundImage: `url(${downloadIcon})` }}
        className={styles.icon}
      ></div>
      <div className={styles.messageContainer}>
        <div style={{ display: 'flex' }} className={styles.title}>
          <h5>{notification.text}!</h5>
          <h5 style={{ cursor: 'pointer' }} onClick={props.clear}>
            X
          </h5>
        </div>
      </div>
    </div>
  )
}
