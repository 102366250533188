import React, { useCallback } from 'react'
import { useInsightsFormLogic } from './InsightsCard.logic'

import { CommentatorButton } from '../../CommentatorButton/CommentatorButton'
import { ColumnForm } from '../ColumnForm/ColumnForm'
import { Toggle } from '../Fields/Toggle/Toggle'
import MultipleSelectChip from '../../Material/MultipleSelect'
import BasicSelect from '../../Material/Select'
import BasicTextField from '../../Material/Text'
import BasicTabs from '../../Material/Tabs'

import styles from './InsightsCardForm.module.scss'

import { InsightsCardConfig } from '../../../metrics_server/insights/types'
import {
  AggregationOperatorTypes,
  aggregationOperatorTypes
} from '../../../metrics_server/metrics/aggregation_operators/data_types'
import { sportTypes } from '../../../metrics_server/sports/data_types'
import { useMetricOptionsWithUnits } from '../../../metrics_server/metrics/hooks'
import { SessionData } from '../../../metrics_server/sessions/types'
import { UnitsState } from '../../../metrics_server/units/types'
import { getSport } from '../../../metrics_server/sports/functions'
import { useSession } from '../../../metrics_server/sessions/hooks'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'

export interface InsightsCardFormProps {
  configId: string
  handleProceed: (cardConfig: InsightsCardConfig) => void
  handleSecondaryButton: () => void
  session: SessionData
  units: UnitsState
}

export const InsightsCardForm = (props: InsightsCardFormProps) => {
  const { handleProceed, handleSecondaryButton, configId, units } = props

  const { flightMetrics, flightTypes, playerOptions, sport } = useSession()
  const unitSystem = useUnitsSystem(sport)

  const metricOptionsWithUnits = useMetricOptionsWithUnits(
    flightMetrics,
    unitSystem
  )

  const { state, dispatch, updateCardConfig, updateRequest } =
    useInsightsFormLogic(configId)

  const flightType = flightTypes.getTypeByValue(
    state.cardConfig.config.flightType
  )

  const renderFormButtons = useCallback(() => {
    return (
      <div className={styles.formButtons}>
        <CommentatorButton
          title='save'
          handleClick={() => handleProceed({ ...state.cardConfig })}
        />
        <CommentatorButton title='cancel' handleClick={handleSecondaryButton} />
      </div>
    )
  }, [state])

  return (
    <div className={styles.commentatorModalFormWrapper}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h4 className={styles.title}>Create Card</h4>
      </div>
      <div className={styles.formContainer}>
        <BasicTextField
          label='Name'
          value={state.cardConfig.name}
          onChange={(value) => dispatch(updateCardConfig({ name: value }))}
          mb={2}
          size='small'
        />
        <div>
          <BasicTabs tabs={['Group', 'Options']}>
            <React.Fragment>
              <></>
              <BasicSelect
                label='Type'
                options={[
                  { name: 'All', value: 'All' },
                  { name: 'Teams', value: 'Teams' },
                  { name: 'Players', value: 'Players' }
                ]}
                selected={state.cardConfig.config.groupBy.type}
                onChange={(value) => {
                  dispatch(
                    updateRequest({
                      groupBy: {
                        ...state.cardConfig.config.groupBy,
                        type: value
                      }
                    })
                  )
                }}
                mb={2}
                size='small'
              />
              {state.cardConfig.config.groupBy.type === 'Players' && (
                <MultipleSelectChip
                  label='Players'
                  options={playerOptions}
                  selected={state.cardConfig.config.groupBy.ids}
                  onChange={(selected) => {
                    dispatch(
                      updateRequest({
                        groupBy: {
                          ...state.cardConfig.config.groupBy,
                          ids: selected
                        }
                      })
                    )
                  }}
                  mb={2}
                  size='small'
                />
              )}
            </React.Fragment>
            <React.Fragment>
              <BasicSelect
                label='Event type'
                options={flightTypes.options}
                selected={state.cardConfig.config.flightType}
                onChange={(value) => {
                  if (value !== state.cardConfig.config.flightType) {
                    dispatch(updateRequest({ flightType: value }))
                  }
                }}
                mb={2}
                size='small'
              />
              {flightType.props.types?.options.length > 0 && (
                <MultipleSelectChip
                  label='Sub type'
                  options={flightType.props.types?.options}
                  selected={state.cardConfig.config.flightSubTypes}
                  onChange={(selected) => {
                    dispatch(updateRequest({ flightSubTypes: selected }))
                  }}
                  mb={2}
                  size='small'
                />
              )}
              <BasicSelect
                label='Aggregation'
                options={aggregationOperatorTypes.options}
                selected={state.cardConfig.config.aggregation}
                onChange={(value) => {
                  dispatch(updateRequest({ aggregation: value }))
                }}
                mb={2}
                size='small'
              />
              <ColumnForm
                title={'Select Metric:'}
                options={metricOptionsWithUnits}
                selected={
                  state.cardConfig.config.stat
                    ? [state.cardConfig.config.stat]
                    : []
                }
                maxSelections={1}
                handleChange={(selected) => {
                  dispatch(updateRequest({ stat: selected[0] }))
                }}
              />
            </React.Fragment>
          </BasicTabs>
        </div>
        <div>{renderFormButtons()}</div>
      </div>
    </div>
  )
}
