import React, { useState, useRef, useEffect } from 'react'
import styles from './SideBar.module.scss'
import viewAnimations from '../animations'
import { SideBar } from './Navbar/Navbar'
import { Content } from './Content/Content'
import { NavBar } from '../../Navbar/Navbar'
import { useNoNavbarProps } from '../Main/hooks'
import { useAppDispatch } from '../../../store/hooks'

export interface SideBarViewProps {
  children
  views
  view
  router
  history
  location
  setRedirect
  setRedirectPop
  version
  toggleModal
  pitches
}

export function SideBarView(props: SideBarViewProps) {
  const dispatch = useAppDispatch()

  const {
    view,
    views,
    router,
    history,
    location,
    setRedirect,
    setRedirectPop,
    version
  } = props

  const { useNavbarProps = useNoNavbarProps } = view

  const navbarProps = useNavbarProps(view)

  const [inView, setInView] = useState('profile')
  const [switchTo, setSwitchTo] = useState(null)

  const rightContainer = useRef(null)
  const leftContainer = useRef(null)
  const content = useRef(null)
  const contentInner = useRef(null)
  const footer = useRef(null)

  const checkForNewElementInView = (e, inView, handleScrollToElement) => {
    if (e.target.scrollTop === 0) return setInView(views[0].id)
    if (
      e.target.scrollTop + e.target.offsetHeight >
      contentInner.current.offsetHeight
    )
      return setInView(views[views.length - 1].id)

    const element = document.getElementById(inView)
    const contentContainerHeight = parseFloat(
      window.getComputedStyle(content.current).height
    )

    // lastScrollTop = e.target.scrollTop
    const elInViewIndex = views.findIndex((view) => {
      return inView === view.id
    })

    if (elInViewIndex < 0) {
      return
    } else {
      const offsetBottom = element.offsetTop + element.offsetHeight
      if (contentContainerHeight / 2 + e.target.scrollTop < element.offsetTop) {
        setInView(views[elInViewIndex - 1].id)
        content.current.removeEventListener('scroll', handleScrollToElement)
      } else if (
        contentContainerHeight / 2 + e.target.scrollTop >
        offsetBottom
      ) {
        setInView(views[elInViewIndex + 1].id)
        content.current.removeEventListener('scroll', handleScrollToElement)
      }
    }
  }

  /*======== Switch section ========*/

  const switchView = (viewId) => {
    setSwitchTo(viewId)

    setInView(viewId)

    // Remove scroll listener for sidebar options
    const view = document.getElementById(viewId)

    // Add scroll listener to check when scroll ends
    let scrollTimeout

    const scrollCheck = () => {
      clearTimeout(scrollTimeout)
      scrollTimeout = setTimeout(() => {
        setSwitchTo(null)

        // Add scroll listener for sidebar
      }, 100)
    }

    content.current.addEventListener('scroll', scrollCheck)

    view.scrollIntoView({
      behavior: 'smooth',
      block: view.offsetHeight > window.innerHeight ? 'start' : 'center'
    })
  }

  useEffect(() => {
    viewAnimations.slideInSettingsContainer(rightContainer.current, 0.3, () => {
      viewAnimations.showContent(leftContainer.current, 0.3)
      viewAnimations.showContent(content.current, 0.3)
    })

    // Listen for back button and change route
    if (location.pathname !== router.redirect) {
      setRedirectPop(location.pathname)
    }
  }, [])

  useEffect(() => {
    content.current.addEventListener(
      'scroll',
      function handleScrollToElement(e) {
        checkForNewElementInView(e, inView, handleScrollToElement)
      }
    )
  }, [inView])

  useEffect(() => {
    if (
      router.redirect &&
      router.redirect !== location.pathname &&
      router.action === 'PUSH'
    ) {
      animateComponentOut(router.redirect)
    }
  }, [router.redirect])

  const animateComponentOut = (nextRoute) => {
    viewAnimations.hideContent(content.current, 0.5, () => {
      viewAnimations.slideOutSettingsContainer(
        rightContainer.current,
        0.3,
        () => {
          history.push(nextRoute)
        }
      )

      // Slide out footer
      const nextFooter = router.footerRoutes.indexOf(nextRoute) >= 0
      const isFooter = router.footerRoutes.indexOf(location.pathname) >= 0
      if (!nextFooter && isFooter) {
        viewAnimations.slideOutFooter(footer.current, 0.5)
      }
    })
    viewAnimations.hideContent(leftContainer.current, 0.5)
  }

  return (
    <div className={styles.SideBarViewContainer}>
      <div ref={leftContainer} className={styles.leftContainer}>
        <div className={styles.menuContainer}>
          <SideBar
            cards={Object.values(props.view.cards)}
            switchCard={switchView}
            inView={inView}
            switchTo={switchTo}
          />
        </div>
      </div>
      <div ref={rightContainer} className={styles.rightContainer}>
        <div ref={content} className={styles.sidebarViewContent}>
          <div ref={contentInner}>
            <Content
              toggleModal={props.toggleModal}
              pitches={props.pitches}
              inView={inView}
              views={views}
              setRedirect={setRedirect}
              {...props}
            />
          </div>
        </div>
      </div>
      <div ref={footer} className='navbar-z-index'>
        <NavBar
          {...navbarProps}
          goTo={(route) => dispatch(setRedirect(route))}
          active={location.pathname}
        />
      </div>
    </div>
  )
}
