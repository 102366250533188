import React, { Component } from 'react'
import styles from './Select.module.scss'
import animation from '../../../../utils/animations'

import Scroller from '../../../Scroller/Scroller'
import { Options } from '../../../../metrics_server/data_types'
import { AdjustedFontSizeType } from '../../../EventDetailCard/FormattedEventDetailCard'

interface Option {
  name: string
  value: string | number
}

type SelectOption = (value: string | number) => void

interface OptionProps {
  option: Option
  selectOption: SelectOption
  adjustedFontSize?: AdjustedFontSizeType
}

const Option = (props: OptionProps) => {
  const { name, value } = props.option
  const { adjustedFontSize } = props
  return (
    <div
      onClick={() => {
        props.selectOption(value)
      }}
      className={'option'}
    >
      <p style={{ fontSize: adjustedFontSize?.p }}>{name}</p>
    </div>
  )
}

interface DropdownProps {
  selectedOption: Option
  expanded: boolean
  options: Readonly<Option[]>
  selectOption: SelectOption
  selectedOptions: Array<number | string>
  defaultSelection: Option
  dropHorizontal?: boolean
  adjustedFontSize?: AdjustedFontSizeType
}

interface DropdownState {
  scroller: boolean
  contentsId: string
  scrollerId: string
}

class Dropdown extends Component<DropdownProps, DropdownState> {
  private contents: React.RefObject<HTMLInputElement>
  private dropdown: React.RefObject<HTMLInputElement>

  constructor(props) {
    super(props)

    const id = function () {
      return '_' + Math.random().toString(36).substr(2, 9)
    }

    this.state = {
      scroller: false,
      contentsId: id(),
      scrollerId: id()
    }

    this.contents = React.createRef()
    this.dropdown = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { expanded } = this.props
    const { scrollerId, contentsId } = this.state

    const contents = document.getElementById(contentsId),
      dropdown = this.dropdown.current,
      scroller = document.getElementById(scrollerId)
    let sectionHeight = 0
    if (contents) {
      sectionHeight = contents.scrollHeight
    }

    if (sectionHeight > 300) {
      sectionHeight = 350
    } else if (sectionHeight > 120 && sectionHeight <= 300) {
      sectionHeight = 165
    } else if (scroller) {
      scroller.style.display = 'none'
    }
    if (!prevProps.expanded && expanded) {
      dropdown.style.border = '#95A5A6 1px solid'

      // Check if off page
      if (
        dropdown.getBoundingClientRect().y + sectionHeight >
        window.innerHeight
      ) {
        dropdown.style.bottom = '0'
        animation.expandDropdownUp(dropdown, sectionHeight)
      } else {
        dropdown.style.top = '0'
        animation.expandDropdownDown(dropdown, sectionHeight)
      }
      // animation.expandDropdownUp(dropdown, sectionHeight)
    } else if (prevProps.expanded && !expanded) {
      dropdown.style.border = 'none'
      animation.collapseDropdown(dropdown)
    }
  }

  render() {
    const { options, selectedOptions, defaultSelection, adjustedFontSize } =
      this.props
    const { contentsId, scrollerId } = this.state

    let filteredOptions: Option[]
    let finalOptions: Option[]

    if (selectedOptions) {
      filteredOptions = options.filter(
        (el) => !selectedOptions.includes(el.value)
      )

      finalOptions = [defaultSelection, ...filteredOptions]

      finalOptions = finalOptions.filter(
        (el, index, thisArray) =>
          index ===
          thisArray.findIndex((t) => t.name === el.name && t.value === el.value)
      )
    }

    return (
      <div
        className={`main-drop ${this.props.dropHorizontal ? 'horizontal' : ''}`}
      >
        <div ref={this.dropdown} className='dropdown-container'>
          <Scroller scrollerId={scrollerId} scrollerClass='select-scroller'>
            <div id={contentsId} className='select-dropdown'>
              {selectedOptions
                ? finalOptions.map((option, index) => (
                    <Option
                      {...this.props}
                      key={index}
                      option={option}
                      adjustedFontSize={adjustedFontSize}
                    />
                  ))
                : options.map((option, index) => (
                    <Option
                      {...this.props}
                      key={index}
                      option={option}
                      adjustedFontSize={adjustedFontSize}
                    />
                  ))}
            </div>
          </Scroller>
        </div>
      </div>
    )
  }
}

interface Input {
  name: string
  value: any
}

export type UpdateValue = (name: string, value: string | number) => void

interface SelectProps {
  defaultSelection?: Option
  selectedOptions?: Array<number | string>
  input: Input
  updateValue: UpdateValue
  options: Readonly<Options<any>>
  label?: string
  className?: string
  horizontal?: boolean
  adjustedFontSize?: AdjustedFontSizeType
}

interface SelectState {
  expanded: boolean
  selected?: boolean
}

class Select extends Component<SelectProps, SelectState> {
  private dropdown: React.RefObject<HTMLInputElement>

  constructor(props) {
    super(props)

    let selected = false
    if (props.input.value) selected = true

    this.state = {
      expanded: false,
      selected
    }

    this.dropdown = React.createRef()
  }

  selectOption = (value) => {
    this.props.updateValue(this.props.input.name, value)
    const newState = { expanded: false }
    this.setState(newState)
  }

  handleFocus = (e) => {
    this.setState({
      selected: true,
      expanded: true
    })
  }

  handleBlur = () => {
    const { value } = this.props.input
    const newState: SelectState = { expanded: false }
    if (!value || value !== 0) newState.selected = false
    this.setState(newState)
  }

  toggleDropdown = () => {
    const { expanded } = this.state,
      { value } = this.props.input
    const newState: SelectState = { expanded: !expanded }
    if ((!value || value !== 0) && expanded) {
      newState.selected = false
    } else {
      newState.selected = true
    }
    this.setState(newState)
  }

  render() {
    const {
        options,
        input,
        label,
        className,
        selectedOptions,
        defaultSelection,
        adjustedFontSize
      } = this.props,
      { expanded } = this.state
    const selectedOption = options.find((option) => {
      return option.value === input.value
    })
    return (
      <div>
        <div
          tabIndex={0}
          onBlur={this.handleBlur}
          className={`select-container ${className} ${styles[className]}`}
        >
          <div
            onClick={this.toggleDropdown}
            style={{ position: 'relative' }}
            className={
              input.value
                ? `value ${styles.newInputContainer}`
                : `value unselected ${styles.newInputContainer}`
            }
          >
            <div
              className={
                this.state.selected || input.value || input.value === 0
                  ? `${styles.dynamicLabel} ${styles.inputSelected}`
                  : styles.dynamicLabel
              }
            >
              <p style={{ fontSize: adjustedFontSize?.p }}>{label}</p>
            </div>
            {(input.value ||
              input.value === 0 ||
              input.value === null ||
              typeof input.value === 'boolean') && (
              <div className={`${styles.dynamicLabel} select-value`}>
                <p style={{ fontSize: adjustedFontSize?.p }}>
                  {selectedOption
                    ? selectedOption.name
                    : `${input.value} - no option`}
                </p>
              </div>
            )}
          </div>
          <div onClick={this.toggleDropdown} className='dropdown-symbol'>
            <svg
              width='6px'
              height='5px'
              viewBox='0 0 6 5'
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
            >
              <defs></defs>
              <g
                id='Symbols'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'
              >
                <g
                  id='📱/Dropdown/Simple-+-Label'
                  transform='translate(-185.000000, -36.000000)'
                >
                  <image
                    id='icon'
                    transform='translate(188.000000, 38.000000) scale(-1, 1) rotate(-90.000000) translate(-188.000000, -38.000000) '
                    x='184'
                    y='34'
                    width='8'
                    height='8'
                    xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABGdBTUEAA1teXP8meAAAAlZJREFUeAHt3TFOw1AQhOFAwXWgpUeIAgrug0TLmTgUDQVQwXPxpGjkvDib8YgoP5KVbJzdFd+QhMKIzYYvBBBAAAEEEEAAAQQQQAABBFYSeGhzX1aazdg9Avft/Fc7ftvxtue5nDYLbONPARCCGXg0bg6fEEZixnMjfEIwQs+Nmj5w+3t+x951ywfznOARjy35ye9h/LQ9j0fsolUEwBeQZAl+Ult2gS8gyRL8pLbsAl9AkiX4SW3ZBb6AJEvwk9qyC3wBSZbgJ7VlF/gCkizBT2rLLvAFJFmCn9SWXeALSLIEP6ktu8AXkGQJflJbdoEvIMkS/KS27AJfQJIl+Elt2QW+gCRL8JPasgt8AUmW4Ce1ZRf4ApIswU9qyy7wBSRZ3rVlSy8Rn543XVLOl0ngts35bEe/DHx0yyXiJvQ+5qbd+QC/c4xvL8enI2cvIlvObMmhb0FPZ+YT+Xb5EI4wj5cc+msor4SxZ+ksIZTYvE2E4PUsTSOEEpu3iRC8nqVphFBi8zYRgtezNI0QSmzeJkLwepamEUKJzdtECF7P0jRCKLF5mwjB61maRgglNm8TIXg9S9MIocTmbSIEr2dpGiGU2LxNhOD1LE0jhBKbt4kQvJ6laYRQYvM2EYLXszSNEEps3iZC8HqWphFCic3bRAhez9I0QiixeZsIwetZmnZSIfyHP9AoKQ+a3tu553Z8D57TT121O9e94NYrsOSV8OpdyTQVGIUAvmqtVM+FAP5K2LvGbocA/i6llR/nn3muDMx4BBBAAAEEEEAAAQQQQOAkBP4AJH0mEFWhNxMAAAAASUVORK5CYII='
                  ></image>
                </g>
              </g>
            </svg>
          </div>
          <Dropdown
            expanded={expanded}
            selectOption={this.selectOption}
            options={options}
            selectedOptions={selectedOptions}
            selectedOption={selectedOption}
            defaultSelection={defaultSelection}
            dropHorizontal={this.props.horizontal}
            adjustedFontSize={adjustedFontSize}
          />
        </div>
      </div>
    )
  }
}

export default Select
