import React from 'react'

export interface PlayerAvatarProps {
  scale?: number
  autoScale?: boolean
}

export const PlayerAvatar = ({
  scale = 1,
  autoScale = false
}: PlayerAvatarProps) => {
  return (
    <svg
      width={!autoScale ? `${scale * 90}px` : '100%'}
      height={!autoScale ? `${scale * 90}px` : '100%'}
      viewBox='0 0 90 90'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1908_1578)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M44.7856 1C38.7687 1 33.3641 7.43303 32.6837 10.4686C32.23 12.4924 32.4568 15.2915 33.3641 18.866C32.3079 17.8137 31.7798 18.7346 31.7798 21.6287C31.7798 24.5228 32.5318 26.282 34.0359 26.9064C34.4232 26.9064 34.7163 28.274 34.9151 31.0093C35.1141 33.7446 41.1465 37.4956 45.085 37.4956C49.0236 37.4956 55.056 33.7446 55.2549 31.0093C55.4538 28.274 55.7469 26.9064 56.1342 26.9064C57.6382 26.282 58.3903 24.5228 58.3903 21.6287C58.3903 18.7346 57.8622 17.8137 56.806 18.866C57.7132 15.2915 57.9401 12.4924 57.4864 10.4686C56.806 7.43303 51.4014 1 45.3844 1H44.7856Z'
          stroke='#7E7E7E'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.0628 31.9141V37.2142C35.2374 38.6801 32.6662 40.1643 27.3492 41.6668C19.3738 43.9206 16.151 44.3538 10.3824 51.2745C4.61373 58.1953 1.04639 80.9223 1.04639 86.0431C3.18282 87.4335 14.3324 88.3852 17.3304 87.9899C18.1823 85.7847 18.8284 80.503 19.2686 72.1449'
          stroke='#7E7E7E'
          strokeWidth='2'
        />
        <path
          d='M16.5879 59.9883C16.9642 70.6888 20.4355 76.6614 27.0016 77.906'
          stroke='#7E7E7E'
          strokeWidth='2'
        />
        <path
          d='M33.9097 39.2461C34.4947 44.888 38.3537 47.7089 45.4867 47.7089'
          stroke='#7E7E7E'
          strokeWidth='2'
        />
        <path
          d='M29.3813 41.3145C30.752 49.188 36.0368 53.1248 45.2358 53.1248'
          stroke='#7E7E7E'
          strokeWidth='2'
        />
        <path
          d='M23.9854 88.7547L21.0386 74.7227'
          stroke='#7E7E7E'
          strokeWidth='2'
        />
        <path
          d='M54.8164 31.9141V37.2142C54.6419 38.6801 57.213 40.1643 62.53 41.6668C70.5054 43.9206 73.7283 44.3538 79.4968 51.2745C85.2655 58.1953 88.8329 80.9223 88.8329 86.0431C86.6964 87.4335 75.5469 88.3852 72.5488 87.9899C71.6969 85.7847 71.0509 80.503 70.6107 72.1449'
          stroke='#7E7E7E'
          strokeWidth='2'
        />
        <path
          d='M73.2913 59.9883C72.9149 70.6888 69.4436 76.6614 62.8774 77.906'
          stroke='#7E7E7E'
          strokeWidth='2'
        />
        <path
          d='M55.969 39.2461C55.384 44.888 51.5251 47.7089 44.3921 47.7089'
          stroke='#7E7E7E'
          strokeWidth='2'
        />
        <path
          d='M60.498 41.3145C59.1274 49.188 53.8426 53.1248 44.6436 53.1248'
          stroke='#7E7E7E'
          strokeWidth='2'
        />
        <path
          d='M65.894 88.7547L68.8409 74.7227'
          stroke='#7E7E7E'
          strokeWidth='2'
        />
      </g>
      <defs>
        <clipPath id='clip0_1908_1578'>
          <rect width='90' height='90' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
