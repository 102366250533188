import { Route } from '../../App/App.types'
import { useMatchTrackerNavbarProps } from '../../apps/MatchTracker/hooks'
import { MainProps } from '../../components/Views/Main/Main.types'
import { SideBarProps } from '../../components/Views/SideBar/SideBar.types'
import { ViewType } from '../../components/Views/Views.types'
import { Contact } from './Contact/Contact'
import { Faq } from './Faq/Faq'
import { Network } from './Network/Network'
import { Organisation } from './Organisation/Organisation'
import { Privacy } from './Privacy/Privacy'
import Profile from './Profile/Profile'
import { useSettingsProps } from './useSettingsProps'

export const path = '/settings'

export enum SettingsCards {
  FAQ = 'faq',
  CONTACT = 'contact',
  NETWORK = 'network',
  ORGANISATION = 'organisation',
  PROFILE = 'profile',
  USAGE = 'usage',
  PRIVACY = 'privacy'
}

export const cardConfig = {
  [SettingsCards.PROFILE]: {
    key: SettingsCards.PROFILE,
    name: 'Profile',
    Content: Profile,
    index: 0,
    reqPermissionLevel: 0
  },
  [SettingsCards.ORGANISATION]: {
    key: SettingsCards.ORGANISATION,
    name: 'Organisation',
    Content: Organisation,
    index: 1,
    reqPermissionLevel: 0
  },
  [SettingsCards.NETWORK]: {
    key: SettingsCards.NETWORK,
    name: 'Network',
    Content: Network,
    index: 3,
    reqPermissionLevel: 0
  }
  //   [SettingsCards.FAQ]: {
  //     key: SettingsCards.FAQ,
  //     name: 'Faqs',
  //     Content: Faq,
  //     index: 4,
  //     reqPermissionLevel: 0
  //   },
  //   [SettingsCards.CONTACT]: {
  //     key: SettingsCards.CONTACT,
  //     name: 'Contact',
  //     Content: Contact,
  //     index: 5,
  //     reqPermissionLevel: 0
  //   },
  //   [SettingsCards.USAGE]: {
  //     key: SettingsCards.USAGE,
  //     name: 'Usage',
  //     Content: Usage,
  //     index: 5,
  //     reqPermissionLevel: 0
  //   },
  //   [SettingsCards.PRIVACY]: {
  //     key: SettingsCards.PRIVACY,
  //     name: 'Privacy',
  //     Content: Privacy,
  //     index: 6,
  //     reqPermissionLevel: 0
  //   }
}

export const settingsRoute: Route<SideBarProps> = {
  path,
  view: {
    type: ViewType.SIDEBAR,
    name: 'Settings',
    cards: cardConfig,
    useProps: useSettingsProps,
    useNavbarProps: useMatchTrackerNavbarProps
  }
}
