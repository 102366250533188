import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  DataTypeConfig
} from '../../data_types'

export type SessionModeTypeFeatures = {
  disableOption: boolean
}

export const defaultSessionModeTypeFeatures: SessionModeTypeFeatures = {
  disableOption: false
}

export const sessionModeTypesConfig = {
  unknown: {
    key: 'unknown',
    name: 'Unknown',
    value: 0,
    props: {
      features: { ...defaultSessionModeTypeFeatures, disableOption: true }
    }
  },
  live: {
    key: 'live',
    name: 'Live',
    value: 1,
    props: {
      features: { ...defaultSessionModeTypeFeatures }
    }
  },
  replay: {
    key: 'replay',
    name: 'Replay',
    value: 2,
    props: {
      features: { ...defaultSessionModeTypeFeatures, disableOption: false }
    }
  }
} as const

export type SessionModeTypeProps = {
  features: SessionModeTypeFeatures
}

export type SessionModeTypes = DataTypes<typeof sessionModeTypesConfig>

export type SessionModeTypeKey = DataTypeKey<SessionModeTypes>

export type SessionModeTypeKeys = DataTypeKeys<SessionModeTypeKey>

export type SessionModeTypeValues = DataTypeValues<SessionModeTypeKey>

export const getSessionModeTypeGroup = (items: {
  [key in SessionModeTypeKeys]?: SessionModeTypes[key]
}) => {
  return getDataTypeGroup<
    SessionModeTypeKeys,
    SessionModeTypeValues,
    SessionModeTypes,
    SessionModeTypeProps
  >(items)
}

export type SessionModeTypeGroup = ReturnType<typeof getSessionModeTypeGroup>

export type SessionModeTypeConfig = DataTypeConfig<
  SessionModeTypeKeys,
  SessionModeTypeValues,
  SessionModeTypeProps
>

export type SessionModeType = SessionModeTypeGroup['items'][SessionModeTypeKeys]

export const sessionModeTypes = getSessionModeTypeGroup(sessionModeTypesConfig)

export const isSessionModeType = generateTypeChecks<
  SessionModeTypeKeys,
  SessionModeTypeGroup
>(sessionModeTypes)
