import moment from '../../../../utils/moment'
import { NotificationType } from '../../../../metrics_server/notifications/types'

export const useNotificationsListItemLogic = (
  notification: NotificationType
) => {
  const { deviceType, timestamp, title, description, text, arrivalTime } =
    notification

  let isDownloadNotification: boolean
  let downloadNotificationTimestamp: string

  if (!!text && !!arrivalTime) {
    isDownloadNotification = true
    downloadNotificationTimestamp = moment.unix(arrivalTime).format('h:mm:ss')
  }

  const convertedTime = moment.unix(timestamp).format('h:mm:ss')

  const isLowBattery = description
    ? description.includes('Low Battery')
    : undefined

  return {
    isLowBattery,
    deviceType,
    timestamp,
    title,
    description,
    convertedTime,
    isDownloadNotification,
    text,
    downloadNotificationTimestamp
  }
}
