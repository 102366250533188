import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup
} from '../data_types'

export type PitchSetupFeatures = {
  test: boolean
}

export const defaultPitchSetupFeatures: PitchSetupFeatures = {
  test: false
}

export const pitchSetupTypesConfig = {
  auto: {
    name: 'Auto',
    key: 'auto',
    value: 0,
    props: {
      features: defaultPitchSetupFeatures
    }
  },
  pitch: {
    name: 'Pitch',
    key: 'pitch',
    value: 1,
    props: {
      features: defaultPitchSetupFeatures
    }
  },
  totalStation: {
    name: 'Total Station',
    key: 'totalStation',
    value: 2,
    props: {
      features: defaultPitchSetupFeatures
    }
  },
  uploadJSON: {
    name: 'Upload JSON',
    key: 'uploadJSON',
    value: 3,
    props: {
      features: defaultPitchSetupFeatures
    }
  }
} as const

export type PitchSetupTypeProps = {
  features: PitchSetupFeatures
}

export type PitchSetupTypes = DataTypes<typeof pitchSetupTypesConfig>

export type PitchSetupTypeKey = DataTypeKey<PitchSetupTypes>

export type PitchSetupTypeKeys = DataTypeKeys<PitchSetupTypeKey>

export type PitchSetupTypeValues = DataTypeValues<PitchSetupTypeKey>

export const getPitchSetupTypeGroup = (items: {
  [key in PitchSetupTypeKeys]?: PitchSetupTypes[key]
}) => {
  return getDataTypeGroup<
    PitchSetupTypeKeys,
    PitchSetupTypeValues,
    PitchSetupTypes,
    PitchSetupTypeProps
  >(items)
}

export type PitchSetupTypeGroup = ReturnType<typeof getPitchSetupTypeGroup>

export type PitchSetupTypeConfig = DataTypeConfig<
  PitchSetupTypeKeys,
  PitchSetupTypeValues,
  PitchSetupTypeProps
>

export const pitchSetupTypes = getPitchSetupTypeGroup(pitchSetupTypesConfig)

export type PitchSetupType = ReturnType<typeof pitchSetupTypes.getTypeByValue>
