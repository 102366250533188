import React from 'react'
import { useAppDispatch } from '../../store/hooks'

import { signoutUser } from '../../metrics_server/user/actions'
import { setError } from '../../ui/error/actions'

export function useOptions(props) {
  const dispatch = useAppDispatch()

  const options = [
    {
      name: 'Sign out',
      handle: () => {
        dispatch(signoutUser())
        // dispatch(setError({
        //     message: `Are you sure?`,
        //     type: 'warning',
        //     proceed: () => {
        //         dispatch(signoutUser())
        //     }
        // }))
      }
    }
  ]

  return {
    options
  }
}
