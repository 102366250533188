import React from 'react'
import { sportableColors } from '../../const'
import { useFlightNotesModal } from '../../views/Session/modals/flight_notes'
import Checkbox from '../Forms/Inputs/Checkbox/Checkbox'
import { NotesIcon } from '../Icons/NotesIcon/NotesIcon'
import { OperatorNotes } from '../../metrics_server/events/types'

export interface EventOperatorNotesProps {
  operatorNotes: OperatorNotes
  updateEventHighlight
}

export const EventOperatorNotes = ({
  operatorNotes,
  updateEventHighlight
}: EventOperatorNotesProps) => {
  const { openFlightNotesModal } = useFlightNotesModal(operatorNotes)

  const checkNotesIsEmtpy = (operatorNotes: OperatorNotes) => {
    if (operatorNotes) {
      // if notes is empty return true
      if (
        typeof operatorNotes.notes === 'string' &&
        operatorNotes.notes.trim().length === 0
      ) {
        return true
      } else {
        return false
      }
    }
    return true
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: '5px' }}>
        <Checkbox
          size='small'
          scale={1.2}
          onClicked={updateEventHighlight}
          checked={!!operatorNotes && operatorNotes.highlight}
          type='highlight'
        />
      </div>

      <NotesIcon
        handleClick={openFlightNotesModal}
        scale={0.8}
        color={
          checkNotesIsEmtpy(operatorNotes)
            ? sportableColors.colors.sportableGrey
            : sportableColors.colors.sportableBlue
        }
      />
    </div>
  )
}
