import React from 'react'
import styles from './ListInset.module.scss'

export interface ListInsetProps {
  items: any
  children: React.ReactNode
}

export const ListInset: React.FC<ListInsetProps> = ({ children, items }) => {
  const renderItem = (item, index) => {
    const childrenWithProps = React.Children.map(children, (child) => {
      return React.cloneElement(child as React.ReactElement, { item })
    })
    return (
      <div key={index} className={styles.listItem}>
        {childrenWithProps}
      </div>
    )
  }

  return (
    <div className={styles.insetListContainer}>
      <div className={styles.scroll}>
        <ul>{items.map(renderItem)}</ul>
      </div>
    </div>
  )
}
