import React from 'react'

export interface ProfileIconProps {
  color: string
  scale: number
}

export const ProfileIcon: React.FC<ProfileIconProps> = ({ color, scale }) => {
  return (
    <svg
      width={`${22 * scale}px`}
      height={`${27 * scale}px`}
      viewBox='0 0 22 27'
      version='1.1'
    >
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='profile' fill={color} fillRule='nonzero'>
          <path
            d='M11,14 C7.13400675,14 4,10.8659932 4,7 C4,3.13400675 7.13400675,0 11,0 C14.8659932,0 18,3.13400675 18,7 C18,10.8659932 14.8659932,14 11,14 Z M11,12.7272727 C14.1630854,12.7272727 16.7272727,10.1630854 16.7272727,7 C16.7272727,3.83691461 14.1630854,1.27272727 11,1.27272727 C7.83691461,1.27272727 5.27272727,3.83691461 5.27272727,7 C5.27272727,10.1630854 7.83691461,12.7272727 11,12.7272727 Z'
            id='Oval-3'
          ></path>
          <path
            d='M11,13.6363636 L11,14.2727273 C4.66265434,14.2727273 1.46491796,17.9985208 1.30078043,25.7272727 L20.6992196,25.7272727 C20.535082,17.9985209 17.3373456,14.2727273 11,14.2727273 L11,13.6363636 Z M11,13.6363636 L11,13 C18.2950489,13 22,17.5546403 22,26.3636364 L22,27 L0,27 L0,26.3636364 C0,17.5546403 3.7049511,13 11,13 L11,13.6363636 Z'
            id='Path-6'
          ></path>
        </g>
      </g>
    </svg>
  )
}
