import React, { useCallback, useEffect, useRef } from 'react'
import styles from './MSErrors.module.scss'
import ErrorIcon from '@mui/icons-material/Error'
import { useVersion } from '../../metrics_server/version/hooks'
import { useAppDispatch } from '../../store/hooks'
import { toggleModal } from '../../ui/modal/actions'
import { JSONEditor } from 'vanilla-jsoneditor'
import { sportableColors } from '../../const'

export const ErrorJson = ({ json }) => {
  const refContainer = useRef(null)
  const refEditor = useRef(null)
  useEffect(() => {
    refEditor.current = new JSONEditor({
      target: refContainer?.current,
      props: {
        content: {
          json: json ?? {}
        },
        readOnly: true
      }
    })

    return () => {
      if (refEditor.current) {
        refEditor.current?.destroy()
        refEditor.current = null
      }
    }
  }, [])
  return (
    <div>
      <div className={styles.editorContainer} ref={refContainer}></div>
    </div>
  )
}

export const MSErrors = () => {
  const dispatch = useAppDispatch()
  const { errors } = useVersion()

  let color = 'white'

  if (errors?.length > 0) color = sportableColors.colors.colorError

  const openMSErrorModal = useCallback(() => {
    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        handleProceed: (values) => {
          dispatch(toggleModal({}))
        },
        wrapper: true,
        ChildComponent: () => <ErrorJson json={errors} />,
        className: 'modalSmall',
        title: 'Error details'
      })
    )
  }, [errors])

  return (
    <ErrorIcon
      style={{ color }}
      onClick={() => openMSErrorModal()}
      sx={{
        '& :hover': {
          color: sportableColors.colors.sportableBlue,
          cursor: 'pointer'
        }
      }}
    />
  )
}
