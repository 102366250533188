import React, {
  JSXElementConstructor,
  useEffect,
  useRef,
  useState
} from 'react'

import styles from './StrackContainer.module.scss'

interface StrackStandardProps {
  setSelectedCanvas?: (canvasId: string) => void
  children?: React.ReactElement<any>

  // Component
  active: boolean
  selected: boolean
  setOptions?: () => void
}

export function StrackStandard({
  // Strack
  setSelectedCanvas,
  children,

  // Component
  active,
  selected,
  setOptions
}: StrackStandardProps) {
  const viewContainer = useRef(null)

  // Switch view based on tab selected
  useEffect(() => {
    if (active) {
      viewContainer.current.style.display = 'block'
      viewContainer.current.style.pointerEvents = 'auto'

      // Set correct canvas
      setSelectedCanvas(null)
    }
  }, [active])

  useEffect(() => {
    if (!selected) {
      viewContainer.current.style.display = 'none'
      viewContainer.current.style.pointerEvents = 'none'
      if (active) {
        setSelectedCanvas(null)
      }
    }
  }, [selected])

  const renderChildrenWithProps = () => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        const newProps = {
          active,
          selected,
          setOptions
        }
        return React.cloneElement(child, newProps)
      }
    })
  }

  return (
    <div ref={viewContainer} className={styles.viewContainer}>
      {renderChildrenWithProps()}
    </div>
  )
}
