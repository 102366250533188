import React, { CSSProperties } from 'react'

export interface TickIconProps {
  color?: string
  scale?: number
  style?: CSSProperties
}

export const TickIcon: React.FC<TickIconProps> = ({
  scale = 1,
  color = 'white',
  style
}) => {
  return (
    <svg
      width={`${12 * scale}`}
      height={`${12 * scale}`}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        d='M11.8927 1.31438L10.3137 0.0690266C10.2648 0.0287611 10.2044 0 10.1354 0C10.0664 0 10.0031 0.0287611 9.9542 0.0719027L4.21062 7.45199C4.21062 7.45199 1.95288 5.28053 1.8896 5.21726C1.82633 5.15398 1.74292 5.04757 1.61637 5.04757C1.48982 5.04757 1.4323 5.13673 1.36615 5.20288C1.31726 5.25465 0.511947 6.10022 0.115044 6.52013C0.0920354 6.54602 0.0776549 6.5604 0.0575221 6.58053C0.0230088 6.62943 0 6.68407 0 6.74447C0 6.80774 0.0230088 6.85951 0.0575221 6.90841L0.138053 6.98319C0.138053 6.98319 4.14447 10.8314 4.21062 10.8976C4.27677 10.9637 4.3573 11.0471 4.47522 11.0471C4.59027 11.0471 4.68518 10.9235 4.73982 10.8688L11.9042 1.66527C11.9387 1.61637 11.9617 1.56173 11.9617 1.49845C11.9617 1.42655 11.933 1.36615 11.8927 1.31438Z'
        fill={color}
      />
    </svg>
  )
}
