import {
  GET_TEAMS,
  CREATE_TEAM,
  JOIN_TEAM,
  EDIT_TEAM,
  DELETE_TEAM,
  GET_TEAM_PLAYERS,
  SET_TEAM
} from './types'

import { CREATE_PLAYER, DELETE_PLAYER } from '../players/types'

import { GET_ORGANISATIONS } from '../organisations/types'

import { REMOVE_USER_DATA } from '../types'

import _ from 'lodash'

import { TeamsState } from './types'

const initialState: TeamsState = {
  hasValidData: false,
  rawData: {},
  items: {},
  options: [],
  fetched: false,
  selectedTeam: null,
  selectedBTeam: null
}
const createOption = (team) => {
  return {
    name: team.name,
    image: {
      logo: team.logo,
      color: team.color
    },
    value: team.id,
    id: team.id,
    sportType: team.sportType
  }
}

export function teamsReducer(state = initialState, action) {
  let teams, team, options, id, rawData, rawItem
  switch (action.type) {
    case GET_ORGANISATIONS:
      if (action.payload.length < 1) {
        return {
          ...state
        }
      } else {
        return state
      }
    case GET_TEAMS:
      teams = action.payload.data.coach
      options = teams.map((team) => {
        return createOption(team)
      })
      teams = _.keyBy(teams, 'id')
      return {
        ...state,
        items: teams,
        rawData: teams,
        options: options,
        fetched: true,
        hasValidData: true
      }
    case CREATE_TEAM:
      team = action.payload
      return {
        ...state,
        items: { ...state.items, [action.payload.id]: team },
        rawData: { ...state.rawData, [action.payload.id]: team },
        options: [...state.options, createOption(team)]
      }
    case JOIN_TEAM:
      team = action.payload
      return {
        ...state,
        items: { ...state.items, [action.payload.id]: team },
        rawData: { ...state.rawData, [action.payload.id]: team },
        options: [...state.options, createOption(team)],
        hasValidData: false
      }
    case EDIT_TEAM:
      id = action.payload.id
      teams = { ...state.items }
      teams[id] = action.payload
      rawData = { ...state.rawData }
      rawData[id] = action.payload
      options = state.options.map((teamOption) => {
        if (teamOption.value === action.payload.id) {
          return createOption(action.payload)
        }
        return teamOption
      })
      return { ...state, items: teams, rawData, options }
    case DELETE_TEAM:
      teams = { ...state.items }
      rawData = { ...state.rawData }
      options = state.options.filter(
        (teamOption) => teamOption.value !== action.payload.id
      )
      delete teams[action.payload.id]
      return { ...state, items: teams, rawData, options }

    case SET_TEAM:
      return { ...state, selectedTeam: action.payload }

    case CREATE_PLAYER:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.teamId]: {
            ...state.items[action.payload.teamId]
          }
        },
        rawData: {
          ...state.rawData,
          [action.payload.teamId]: {
            ...state.rawData[action.payload.teamId]
          }
        }
      }
    case DELETE_PLAYER:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.teamId]: {
            ...state.items[action.payload.teamId]
          }
        },
        rawData: {
          ...state.rawData,
          [action.payload.teamId]: {
            ...state.rawData[action.payload.teamId]
          }
        }
      }

    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
