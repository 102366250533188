import { useMemo } from 'react'
import { NavbarProps } from '../../components/Navbar/Navbar'
import { ViewNavbarProps } from '../../components/Views/Views.types'
import { isLocal } from '../../metrics_server/env'
import {
  useResumeActiveSession,
  useSessions
} from '../../metrics_server/sessions/hooks'
import { useTeams } from '../../metrics_server/teams/hooks'
import { useAppDispatch } from '../../store/hooks'
import { setRedirect } from '../../ui/router/actions'
import * as StartSessionScreen from '../../views/StartSession/config'
import { MainProps } from '../../components/Views/Main/Main.types'

export const getHomeNavbarCenterButtonProps = (
  isLocal,
  isSessionActive,
  getActiveSession,
  setupNewSession,
  dataFetched
) => {
  const navBarProps = {
    callback: setupNewSession,
    text: 'New Session',
    icon: 'plus',
    visible: isLocal,
    disabled: !dataFetched
  }

  if (isSessionActive) {
    navBarProps.callback = getActiveSession
    navBarProps.text = 'Resume Session'
    navBarProps.icon = 'recording'
  }

  return navBarProps
}

export function useMatchTrackerNavbarProps(view: MainProps): ViewNavbarProps {
  const dispatch = useAppDispatch()
  const sessions = useSessions()
  const teams = useTeams()

  const resumeActiveSession = useResumeActiveSession()

  return useMemo(() => {
    const navBarCenterButtonProps = getHomeNavbarCenterButtonProps(
      isLocal,
      !!sessions.activeSession,
      resumeActiveSession,
      () => {
        dispatch(setRedirect(StartSessionScreen.path))
      },
      !!teams.selectedTeam
    )

    return {
      enabled: true,
      centerButton: {
        ...navBarCenterButtonProps,
        loading: false
      }
    }
  }, [sessions, teams, dispatch, resumeActiveSession])
}
