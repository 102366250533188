import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './StrackEventLabel.module.scss'
import { Strack } from '../Strack/Strack.types'
import playerAvatar from '../../assets/img/player_avatar.svg'
import { useSelectedFormattedEvent } from '../../metrics_server/events/hooks'
import { eventTypes } from '../../metrics_server/events/data_types'

export interface StrackEventLabelProps {
  strack: Strack
  show: boolean
}

type styleType = {
  left?: number | string
  top?: number | string
  right?: number | string
  bottom?: number | string
}

const defaultContainerStyle = {
  transform: 'translateY(0%)'
}

const defaultArrowStyle = {
  left: 0,
  top: 0
}

const defaultCardContainerStyle = {
  left: 0,
  top: '66.6%'
}

const defaultCardStyle = {
  left: 0
}

export const StrackEventLabel = ({ strack, show }: StrackEventLabelProps) => {
  // Event //
  const formattedEvent = useSelectedFormattedEvent()
  // ===== //

  const [containerStyle, setContainerStyle] = useState(defaultContainerStyle)
  const [arrowStyle, setArrowStyle] = useState<styleType>(defaultArrowStyle)
  const [cardContainerStyle, setCardContainerStyle] = useState<styleType>(
    defaultCardContainerStyle
  )
  const [cardStyle, setCardStyle] = useState(defaultCardStyle)

  const initialDataPoint = useMemo(() => {
    if (
      formattedEvent &&
      formattedEvent.eventType === eventTypes.items.flight.value
    ) {
      return formattedEvent.rawData?.data
        ? formattedEvent.rawData.data[0]
        : null
    }
  }, [formattedEvent])

  useEffect(() => {
    if (formattedEvent) {
      getStylePropertiesForLabel()
    }
  }, [formattedEvent])

  const getStylePropertiesForLabel = useCallback(() => {
    if (!initialDataPoint) return
    const params = {
      xPosition: initialDataPoint.pos.x,
      yPosition: initialDataPoint.pos.y,
      fieldHeight: strack.field.height
    }

    const { xPosition, yPosition, fieldHeight } = params

    const lablePosition =
      xPosition < 0 && yPosition > fieldHeight / 2
        ? 'leftHigh'
        : xPosition < 0 && yPosition < fieldHeight / 2
        ? 'leftLow'
        : xPosition > 0 && yPosition > fieldHeight / 2
        ? 'rightHigh'
        : xPosition > 0 && yPosition < fieldHeight / 2
        ? 'rightLow'
        : ''

    switch (lablePosition) {
      case 'leftHigh':
        setContainerStyle(defaultContainerStyle)
        setArrowStyle(defaultArrowStyle)
        setCardContainerStyle(defaultCardContainerStyle)
        setCardStyle(defaultCardStyle)
        break
      case 'leftLow':
        setContainerStyle({
          transform: 'translateY(-100%)'
        })
        setArrowStyle({
          left: 0,
          bottom: 0
        })
        setCardContainerStyle({
          left: 0,
          top: 0
        })
        setCardStyle(defaultCardStyle)
        break
      case 'rightHigh':
        setContainerStyle(defaultContainerStyle)
        setCardContainerStyle({
          right: 0,
          bottom: 0
        })
        setArrowStyle(defaultArrowStyle)
        setCardStyle(defaultCardStyle)
        break
      case 'rightLow':
        setContainerStyle({
          transform: 'translateY(-100%)'
        })
        setArrowStyle({
          left: 0,
          bottom: 0
        })
        setCardContainerStyle({
          right: 0,
          top: 0
        })
        setCardStyle(defaultCardStyle)
        break

      default:
        break
    }
  }, [initialDataPoint, strack.field.height])

  if (!formattedEvent) return null

  // Event label only working for flight events //
  if (formattedEvent.eventType !== eventTypes.items.flight.value) return null

  const { rawData, player, team, metrics, eventType, ballSerial } =
    formattedEvent

  if (eventType !== eventTypes.items.flight.value) return null

  if (!rawData || !show) return <noscript />

  if (!initialDataPoint) return <noscript />

  const scaledFlightStart = strack.getCanvasCoordinate(
    strack.scale,
    initialDataPoint.pos.x,
    initialDataPoint.pos.y
  )

  return (
    <div className={styles.eventOverlayContainer}>
      <div
        style={{
          top: `${scaledFlightStart.scaleY}px`,
          left: `${scaledFlightStart.scaleX}px`
        }}
        className={styles.flightMarker}
      >
        <div
          id='container-fl'
          style={containerStyle}
          className={styles.container}
        >
          <div
            id='arrow-fl'
            style={arrowStyle}
            className={styles.flightArrow}
          ></div>
          <div
            id='cardcontainer-fl'
            style={cardContainerStyle}
            className={styles.flightCardContainer}
          >
            <div id='card-fl' style={cardStyle} className={styles.flightCard}>
              {player ? (
                <div
                  className={styles.imageContainer}
                  style={{
                    border: `${player.selected?.color || 'black'} 2px solid`
                  }}
                >
                  <img src={player.selected?.img || playerAvatar} alt=''></img>
                </div>
              ) : (
                <div
                  className={styles.imageContainer}
                  style={{ border: `black 2px solid` }}
                >
                  <img src={playerAvatar} alt=''></img>
                </div>
              )}

              <div className={styles.info}>
                <div className={styles.names}>
                  {player ? (
                    <h5>{player.selected?.fullName}</h5>
                  ) : (
                    <h5>No player found</h5>
                  )}
                  {team && (
                    <h5 style={{ fontStyle: 'italic' }}>
                      {team.selected?.name}
                    </h5>
                  )}
                </div>
                <div className={styles.stats}>
                  <div>
                    <p>Ball ID</p>
                    <h4>{ballSerial ? ballSerial : 'No Ball Id'}</h4>
                  </div>
                  <div>
                    <p>{metrics.dist?.name}</p>
                    <h4>{metrics.dist?.display}</h4>
                  </div>
                  {metrics.lineoutDeviation ? (
                    <div>
                      <p>{metrics.lineoutDeviation?.name}</p>
                      <h4>{metrics.lineoutDeviation?.display}</h4>
                    </div>
                  ) : (
                    <div>
                      <p>{metrics.hangTime?.name}</p>
                      <h4>{metrics.hangTime?.display}</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
