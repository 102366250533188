import _ from 'lodash'
import {
  CHECK_FOR_ORGANISATION,
  CREATE_ORGANISATION,
  GET_ORGANISATIONS,
  JOIN_ORGANISATION,
  UPDATE_NEW_ORGANISATION
} from './types'
import { REMOVE_USER_DATA } from '../types'
import { OrganisationsState } from './types'

const initialState: OrganisationsState = {
  fetched: false,
  hasValidData: false,
  items: {},
  new: {},
  selected: null,
  checkedOrganisation: {
    id: null,
    name: null,
    logo: null,
    threeWords: null,
    teams: []
  } as any
}

export function organisationsReducer(state = initialState, action) {
  let organisations
  switch (action.type) {
    case GET_ORGANISATIONS:
      organisations = _.keyBy(action.payload, 'id')
      return {
        ...state,
        items: organisations,
        selected: action.payload[0] ? action.payload[0].id : null,
        hasValidData: true,
        fetched: true
      }
    case CREATE_ORGANISATION:
      organisations = _.keyBy(organisations, 'id')
      return {
        ...state,
        hasValidData: false,
        new: {}
      }
    case UPDATE_NEW_ORGANISATION:
      organisations = _.keyBy(organisations, 'id')
      return {
        ...state,
        new: {
          ...state.new,
          ...action.payload
        }
      }
    case CHECK_FOR_ORGANISATION:
      return {
        ...state,
        checkedOrganisation: {
          ...action.payload.organisation,
          teams: action.payload.teams
        }
      }

    case JOIN_ORGANISATION:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload
        },
        selected: action.payload.id,
        hasValidData: false
      }

    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
