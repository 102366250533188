import { SportTypeKey } from '../data_types'
import { sportTypeKey } from '..'
import {
  PitchArcs,
  Coordinate,
  Pitch,
  PitchCoordinates,
  PitchDimensions
} from '../../pitches/types'

export interface AustalianRulesPitch extends Pitch {
  type: SportTypeKey['australianRules']
  pitch: AustralianRulesPitchDimensions
  arcs: PitchArcs
}

export interface AustralianRulesPitchDimensions extends PitchDimensions {
  length: number
  width: number
  poles: {
    width: number
    height: number
    crossbarHeight: number
  }
  coordinates: AustralianRulesPitchCoordinates
}

export interface AustralianRulesPitchCoordinates extends PitchCoordinates {
  P1: Coordinate
  P2: Coordinate
  P3: Coordinate
  P4: Coordinate
  P5: Coordinate
  P6: Coordinate
  P7: Coordinate
  P8: Coordinate
  P9: Coordinate
  P10: Coordinate
  P11: Coordinate
  P12: Coordinate
  P13: Coordinate
  P14: Coordinate
  P15: Coordinate
  P16: Coordinate
  P17: Coordinate
  P18: Coordinate
  P19: Coordinate
  P20: Coordinate
  P21: Coordinate
  P22: Coordinate
  P23: Coordinate
  P24: Coordinate
  P25: Coordinate
  P26: Coordinate
  P27: Coordinate
  P28: Coordinate
  P29: Coordinate
  P30: Coordinate
  P31: Coordinate
  P32: Coordinate
  P33: Coordinate
  P34: Coordinate
  P35: Coordinate
  P36: Coordinate
  P37: Coordinate
}

const arcs = [
  {
    name: 'UpperArc1',
    start: 'P17',
    end: 'P18',
    a: -0.02453577,
    b: -0.10425262,
    c: 4.41890979,
    d: 9.6
  },
  {
    name: 'UpperArc2',
    start: 'P18',
    end: 'P19',
    a: 0.01440167,
    b: -0.23688977,
    c: 2.90501298,
    d: 22.26684332
  },
  {
    name: 'UpperArc3',
    start: 'P19',
    end: 'P20',
    a: 0.00232774,
    b: -0.06612136,
    c: 1.64748764,
    d: 35.03140658
  },
  {
    name: 'UpperArc4',
    start: 'P20',
    end: 'P21',
    a: 0.00032323,
    b: -0.02117727,
    c: 1.02198967,
    d: 46.38428605
  },
  {
    name: 'UpperArc5',
    start: 'P21',
    end: 'P22',
    a: 0.00003759,
    b: -0.0099492,
    c: 0.58524736,
    d: 59.04238964
  },
  {
    name: 'UpperArc6',
    start: 'P22',
    end: 'P23',
    a: -0.00004331,
    b: -0.00397228,
    c: 0.23304035,
    d: 67.05853939
  },
  {
    name: 'UpperArc7',
    start: 'P23',
    end: 'P24',
    a: 0.00018784,
    b: -0.00988811,
    c: 0,
    d: 69.8025
  },
  {
    name: 'UpperArc8',
    start: 'P24',
    end: 'P25',
    a: 0.00040378,
    b: -0.01990096,
    c: -0.16353673,
    d: 67.05669774
  },
  {
    name: 'UpperArc9',
    start: 'P25',
    end: 'P26',
    a: 0.00060359,
    b: -0.02690186,
    c: -0.47466377,
    d: 59.03531272
  },
  {
    name: 'UpperArc10',
    start: 'P26',
    end: 'P27',
    a: 0.00222557,
    b: -0.06008603,
    c: -0.86780698,
    d: 46.36941511
  },
  {
    name: 'UpperArc11',
    start: 'P27',
    end: 'P28',
    a: 0.00443761,
    b: -0.11538705,
    c: -1.4082281,
    d: 35.0100936
  },
  {
    name: 'UpperArc12',
    start: 'P28',
    end: 'P29',
    a: 0.07400896,
    b: -0.67904156,
    c: -2.3448339,
    d: 22.23899719
  },
  {
    name: 'LowerArc24',
    start: 'P13',
    end: 'P14',
    a: 0.07530736,
    b: -0.06174251,
    c: -4.42767685,
    d: -9.6
  },
  {
    name: 'LowerArc23',
    start: 'P12',
    end: 'P13',
    a: 0.00454932,
    b: 0.02963471,
    c: -2.36013641,
    d: -22.20287774
  },
  {
    name: 'LowerArc22',
    start: 'P11',
    end: 'P12',
    a: 0.00230568,
    b: -0.00159638,
    c: -1.41960474,
    d: -34.91460686
  },
  {
    name: 'LowerArc21',
    start: 'P10',
    end: 'P11',
    a: 0.00061334,
    b: -0.00349681,
    c: -0.87514538,
    d: -46.23280997
  },
  {
    name: 'LowerArc20',
    start: 'P9',
    end: 'P10',
    a: 0.00040795,
    b: -0.00441112,
    c: -0.47832838,
    d: -58.97028795
  },
  {
    name: 'LowerArc19',
    start: 'P8',
    end: 'P9',
    a: 0.00018954,
    b: -0.00238941,
    c: -0.16471624,
    d: -67.03977376
  },
  {
    name: 'LowerArc18',
    start: 'P37',
    end: 'P8',
    a: -0.00004277,
    b: 0.00673222,
    c: 0,
    d: -69.8025
  },
  {
    name: 'LowerArc17',
    start: 'P36',
    end: 'P37',
    a: 0.0000372,
    b: 0.007637,
    c: 0.23219225,
    d: -67.05669774
  },
  {
    name: 'LowerArc16',
    start: 'P35',
    end: 'P36',
    a: 0.00031972,
    b: 0.00495639,
    c: 0.58316149,
    d: -59.03531272
  },
  {
    name: 'LowerArc15',
    start: 'P34',
    end: 'P35',
    a: 0.0023048,
    b: 0.00198193,
    c: 1.01850351,
    d: -46.36941511
  },
  {
    name: 'LowerArc14',
    start: 'P33',
    end: 'P34',
    a: 0.01424992,
    b: -0.04188108,
    c: 1.64233508,
    d: -35.0100936
  },
  {
    name: 'LowerArc13',
    start: 'P32',
    end: 'P33',
    a: -0.02450912,
    b: 0.34861444,
    c: 2.89626034,
    d: -22.23899719
  }
]

const coordinates = {
  P1: {
    x: 0,
    y: 0,
    z: 0
  },
  P10: {
    x: -41.74585763,
    y: -58.97028795,
    z: 0
  },
  P11: {
    x: -58.45407344,
    y: -46.23280997,
    z: 0
  },
  P12: {
    x: -67.55989271,
    y: -34.91460686,
    z: 0
  },
  P13: {
    x: -73.96923403,
    y: -22.20287774,
    z: 0
  },
  P14: {
    x: -77.28,
    y: -9.6,
    z: 0
  },
  P15: {
    x: -77.28,
    y: -3.2,
    z: 0
  },
  P16: {
    x: -77.28,
    y: 3.2,
    z: 0
  },
  P17: {
    x: -77.28,
    y: 9.6,
    z: 0
  },
  P18: {
    x: -73.94520779,
    y: 22.26684332,
    z: 0
  },
  P19: {
    x: -67.48431141,
    y: 35.03140658,
    z: 0
  },
  P2: {
    x: -1.5,
    y: 0,
    z: 0
  },
  P20: {
    x: -58.30395502,
    y: 46.38428605,
    z: 0
  },
  P21: {
    x: -41.61833711,
    y: 59.04238964,
    z: 0
  },
  P22: {
    x: -21.66065651,
    y: 67.05853939,
    z: 0
  },
  P23: {
    x: 0,
    y: 69.8025,
    z: 0
  },
  P24: {
    x: 21.7546963,
    y: 67.05669774,
    z: 0
  },
  P25: {
    x: 41.79787526,
    y: 59.03531272,
    z: 0
  },
  P26: {
    x: 58.55267055,
    y: 46.36941511,
    z: 0
  },
  P27: {
    x: 67.76888934,
    y: 35.0100936,
    z: 0
  },
  P28: {
    x: 74.25234144,
    y: 22.23899719,
    z: 0
  },
  P29: {
    x: 77.59,
    y: 9.6,
    z: 0
  },
  P3: {
    x: -5,
    y: 0,
    z: 0
  },
  P30: {
    x: 77.59,
    y: 3.2,
    z: 0
  },
  P31: {
    x: 77.59,
    y: -3.2,
    z: 0
  },
  P32: {
    x: 77.59,
    y: -9.6,
    z: 0
  },
  P33: {
    x: 74.25234144,
    y: -22.23899719,
    z: 0
  },
  P34: {
    x: 67.76888934,
    y: -35.0100936,
    z: 0
  },
  P35: {
    x: 58.55267055,
    y: -46.36941511,
    z: 0
  },
  P36: {
    x: 41.79787526,
    y: -59.03531272,
    z: 0
  },
  P37: {
    x: 21.7546963,
    y: -67.05669774,
    z: 0
  },
  P4: {
    x: -25,
    y: 25,
    z: 0
  },
  P5: {
    x: 25,
    y: 25,
    z: 0
  },
  P6: {
    x: 25,
    y: -25,
    z: 0
  },
  P7: {
    x: -25,
    y: -25,
    z: 0
  },
  P8: {
    x: 0,
    y: -69.8025,
    z: 0
  },
  P9: {
    x: -21.73310756,
    y: -67.03977376,
    z: 0
  }
}

export const defaultPitch: AustalianRulesPitch = {
  id: '0',
  name: 'Default Australian Rules Pitch',
  type: sportTypeKey.australianRules,
  anchors: null,
  arcs: arcs,
  pitch: {
    length: 139.605,
    width: 154.87,
    poles: {
      width: 0,
      height: 0,
      crossbarHeight: 0
    },
    coordinates: coordinates
  }
}

const config = {
  id: '0',
  name: '',
  anchors: {},
  pitch: {
    length: 139.605,
    width: 154.87,
    poles: { width: 0, height: 0, crossbarHeight: 0 },
    coordinates: {
      P1: { x: 0, y: 0, z: 0 },
      P10: { x: -41.74585763, y: -58.97028795, z: 0 },
      P11: { x: -58.45407344, y: -46.23280997, z: 0 },
      P12: { x: -67.55989271, y: -34.91460686, z: 0 },
      P13: { x: -73.96923403, y: -22.20287774, z: 0 },
      P14: { x: -77.28, y: -9.6, z: 0 },
      P15: { x: -77.28, y: -3.2, z: 0 },
      P16: { x: -77.28, y: 3.2, z: 0 },
      P17: { x: -77.28, y: 9.6, z: 0 },
      P18: { x: -73.94520779, y: 22.26684332, z: 0 },
      P19: { x: -67.48431141, y: 35.03140658, z: 0 },
      P2: { x: -1.5, y: 0, z: 0 },
      P20: { x: -58.30395502, y: 46.38428605, z: 0 },
      P21: { x: -41.61833711, y: 59.04238964, z: 0 },
      P22: { x: -21.66065651, y: 67.05853939, z: 0 },
      P23: { x: 0, y: 69.8025, z: 0 },
      P24: { x: 21.7546963, y: 67.05669774, z: 0 },
      P25: { x: 41.79787526, y: 59.03531272, z: 0 },
      P26: { x: 58.55267055, y: 46.36941511, z: 0 },
      P27: { x: 67.76888934, y: 35.0100936, z: 0 },
      P28: { x: 74.25234144, y: 22.23899719, z: 0 },
      P29: { x: 77.59, y: 9.6, z: 0 },
      P3: { x: -5, y: 0, z: 0 },
      P30: { x: 77.59, y: 3.2, z: 0 },
      P31: { x: 77.59, y: -3.2, z: 0 },
      P32: { x: 77.59, y: -9.6, z: 0 },
      P33: { x: 74.25234144, y: -22.23899719, z: 0 },
      P34: { x: 67.76888934, y: -35.0100936, z: 0 },
      P35: { x: 58.55267055, y: -46.36941511, z: 0 },
      P36: { x: 41.79787526, y: -59.03531272, z: 0 },
      P37: { x: 21.7546963, y: -67.05669774, z: 0 },
      P4: { x: -25, y: 25, z: 0 },
      P5: { x: 25, y: 25, z: 0 },
      P6: { x: 25, y: -25, z: 0 },
      P7: { x: -25, y: -25, z: 0 },
      P8: { x: 0, y: -69.8025, z: 0 },
      P9: { x: -21.73310756, y: -67.03977376, z: 0 }
    }
  },
  type: 'AustralianRules',
  arcs: [
    {
      name: 'UpperArc1',
      start: 'P17',
      end: 'P18',
      a: -0.02453577,
      b: -0.10425262,
      c: 4.41890979,
      d: 9.6
    },
    {
      name: 'UpperArc2',
      start: 'P18',
      end: 'P19',
      a: 0.01440167,
      b: -0.23688977,
      c: 2.90501298,
      d: 22.26684332
    },
    {
      name: 'UpperArc3',
      start: 'P19',
      end: 'P20',
      a: 0.00232774,
      b: -0.06612136,
      c: 1.64748764,
      d: 35.03140658
    },
    {
      name: 'UpperArc4',
      start: 'P20',
      end: 'P21',
      a: 0.00032323,
      b: -0.02117727,
      c: 1.02198967,
      d: 46.38428605
    },
    {
      name: 'UpperArc5',
      start: 'P21',
      end: 'P22',
      a: 0.00003759,
      b: -0.0099492,
      c: 0.58524736,
      d: 59.04238964
    },
    {
      name: 'UpperArc6',
      start: 'P22',
      end: 'P23',
      a: -0.00004331,
      b: -0.00397228,
      c: 0.23304035,
      d: 67.05853939
    },
    {
      name: 'UpperArc7',
      start: 'P23',
      end: 'P24',
      a: 0.00018784,
      b: -0.00988811,
      c: 0,
      d: 69.8025
    },
    {
      name: 'UpperArc8',
      start: 'P24',
      end: 'P25',
      a: 0.00040378,
      b: -0.01990096,
      c: -0.16353673,
      d: 67.05669774
    },
    {
      name: 'UpperArc9',
      start: 'P25',
      end: 'P26',
      a: 0.00060359,
      b: -0.02690186,
      c: -0.47466377,
      d: 59.03531272
    },
    {
      name: 'UpperArc10',
      start: 'P26',
      end: 'P27',
      a: 0.00222557,
      b: -0.06008603,
      c: -0.86780698,
      d: 46.36941511
    },
    {
      name: 'UpperArc11',
      start: 'P27',
      end: 'P28',
      a: 0.00443761,
      b: -0.11538705,
      c: -1.4082281,
      d: 35.0100936
    },
    {
      name: 'UpperArc12',
      start: 'P28',
      end: 'P29',
      a: 0.07400896,
      b: -0.67904156,
      c: -2.3448339,
      d: 22.23899719
    },
    {
      name: 'LowerArc24',
      start: 'P13',
      end: 'P14',
      a: 0.07530736,
      b: -0.06174251,
      c: -4.42767685,
      d: -9.6
    },
    {
      name: 'LowerArc23',
      start: 'P12',
      end: 'P13',
      a: 0.00454932,
      b: 0.02963471,
      c: -2.36013641,
      d: -22.20287774
    },
    {
      name: 'LowerArc22',
      start: 'P11',
      end: 'P12',
      a: 0.00230568,
      b: -0.00159638,
      c: -1.41960474,
      d: -34.91460686
    },
    {
      name: 'LowerArc21',
      start: 'P10',
      end: 'P11',
      a: 0.00061334,
      b: -0.00349681,
      c: -0.87514538,
      d: -46.23280997
    },
    {
      name: 'LowerArc20',
      start: 'P9',
      end: 'P10',
      a: 0.00040795,
      b: -0.00441112,
      c: -0.47832838,
      d: -58.97028795
    },
    {
      name: 'LowerArc19',
      start: 'P8',
      end: 'P9',
      a: 0.00018954,
      b: -0.00238941,
      c: -0.16471624,
      d: -67.03977376
    },
    {
      name: 'LowerArc18',
      start: 'P37',
      end: 'P8',
      a: -0.00004277,
      b: 0.00673222,
      c: 0,
      d: -69.8025
    },
    {
      name: 'LowerArc17',
      start: 'P36',
      end: 'P37',
      a: 0.0000372,
      b: 0.007637,
      c: 0.23219225,
      d: -67.05669774
    },
    {
      name: 'LowerArc16',
      start: 'P35',
      end: 'P36',
      a: 0.00031972,
      b: 0.00495639,
      c: 0.58316149,
      d: -59.03531272
    },
    {
      name: 'LowerArc15',
      start: 'P34',
      end: 'P35',
      a: 0.0023048,
      b: 0.00198193,
      c: 1.01850351,
      d: -46.36941511
    },
    {
      name: 'LowerArc14',
      start: 'P33',
      end: 'P34',
      a: 0.01424992,
      b: -0.04188108,
      c: 1.64233508,
      d: -35.0100936
    },
    {
      name: 'LowerArc13',
      start: 'P32',
      end: 'P33',
      a: -0.02450912,
      b: 0.34861444,
      c: 2.89626034,
      d: -22.23899719
    }
  ]
}
