import React from 'react'
export interface ShirtIconProps {
  primaryColor?: string
  secondaryColor?: string
  id?: string
  scale: number
  active?: boolean
}

export const ShirtIcon = ({
  primaryColor,
  secondaryColor,
  id,
  scale,
  active = true
}: ShirtIconProps) => {
  return (
    <svg
      width={`${36 * scale}`}
      height={`${36 * scale}`}
      viewBox='0 0 70 74'
      fill='none'
      id={id}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M69.6706 18.4875C69.6706 18.4875 62.9989 4.70799 61.2489 3.67452C59.4989 2.64105 49.7647 2.87071 46.1554 1.37793C46.1554 1.37793 41.6711 6.88975 34.9994 6.88975C28.3276 6.88975 23.8433 1.37793 23.8433 1.37793C20.1246 2.87071 10.4998 2.64105 8.74985 3.67452C6.99988 4.70799 0.21875 18.4875 0.21875 18.4875C3.93743 27.3294 12.6873 29.2815 12.6873 29.2815L16.1872 23.54L14.5466 67.979C22.9683 73.1464 29.0932 73.4909 34.9994 73.4909C40.9055 73.4909 46.921 73.1464 55.3428 68.0939L53.7022 23.6549L57.2021 29.3963C57.2021 29.2815 66.0613 27.4442 69.6706 18.4875'
        fill={primaryColor}
        id={id}
        fillOpacity={active ? '1' : '0.3'}
      />
      <path
        d='M35.2182 8.26773C33.4682 8.26773 31.8276 7.92324 30.0776 7.23426C28.7651 6.66011 27.3433 5.97114 26.0308 5.0525C23.8434 3.55972 22.4215 1.9521 22.4215 1.9521L24.4996 0.229659L23.5152 1.03347L24.4996 0.229659C24.4996 0.229659 25.7027 1.60761 27.6714 2.98557C29.4214 4.13386 32.1557 5.62665 35.2182 5.62665C38.7181 5.62665 42.1087 3.67454 45.2805 0L47.3586 1.72244C45.3898 4.01903 43.4211 5.74148 41.2337 6.77494C39.3743 7.69358 37.4056 8.26773 35.2182 8.26773'
        fill={secondaryColor}
        id={id}
        fillOpacity={active ? '1' : '0.3'}
      />
      <path
        d='M14.2185 26.8699C10.3904 26.5254 7.10925 24.5733 4.48429 21.0136C2.95307 18.9467 1.96871 16.8797 1.53122 15.7314L0 18.9467C0.546865 20.095 1.31248 21.3581 2.29683 22.6212C3.82806 24.6881 5.57802 26.2958 7.43737 27.4441C9.07796 28.4775 10.8279 29.1665 12.7966 29.3962L14.2185 26.8699'
        fill={secondaryColor}
        id={id}
        fillOpacity={active ? '1' : '0.3'}
      />
      <path
        d='M55.7803 26.9851C59.6083 26.6407 62.8895 24.6886 65.5145 21.1288C67.0457 19.0619 68.0301 16.995 68.4675 15.8467L69.9988 18.9471C69.4519 20.0954 68.6863 21.3585 67.7019 22.6216C66.1707 24.6886 64.4207 26.2962 62.5614 27.4445C60.9208 28.4779 59.0615 29.1669 57.2021 29.3966L55.7803 26.9851Z'
        fill={secondaryColor}
        id={id}
        fillOpacity={active ? '1' : '0.3'}
      />
    </svg>
  )
}
