import _ from 'lodash'
import { AnyAction } from 'redux'
import {
  SetInsightsConfigsAction,
  SetSelecteInsightsLayoutAction
} from './actions'

import { insightsDummyState } from './data'

import {
  ConfigTypes,
  InsightsActionType,
  InsightsActionTypes,
  InsightsCardRequest,
  InsightsTableRequest
} from './types'

import { InsightsState, InsightsCardConfig, InsightsTableConfig } from './types'

const {
  DELETE_INSIGHTS_LAYOUT,
  UPDATE_INSIGHTS_LAYOUT,
  SET_INSIGHTS_LAYOUTS,
  SET_SELECTED_INSIGHTS_LAYOUT,
  EDIT_INSIGHTS_LAYOUT,
  DELETE_EDITED_INSIGHTS_LAYOUT,
  SET_INSIGHTS_CONFIGS,
  UPDATE_INSIGHTS_CONFIG,
  UPDATE_INSIGHTS_TABLE_DATA,
  UPDATE_INSIGHTS_CARD_DATA,
  UPDATE_INSIGHTS_DATA_FETCH_QUEUE,
  ADD_INSIGHTS_LAYOUT_ITEM,
  REMOVE_INSIGHTS_LAYOUT_ITEM,
  UPDATE_INSIGHTS_LAYOUT_CONTENT_ID
} = InsightsActionType

export const initialState: InsightsState = insightsDummyState

export function insightsReducer(
  state = initialState,
  action: SetInsightsConfigsAction | SetSelecteInsightsLayoutAction | AnyAction
): InsightsState {
  let layouts,
    tableConfigsArray: InsightsTableConfig[],
    tableConfigs: any,
    cardConfigsArray: InsightsCardConfig[],
    cardConfigs: any
  const { sessionLayout, competitionLayout, homeLayout } =
    state.layouts.selectedId

  switch (action.type) {
    case SET_SELECTED_INSIGHTS_LAYOUT:
      return {
        ...state,
        layouts: {
          ...state.layouts,
          selectedId: {
            ...state.layouts.selectedId,
            [action.payload.type]: action.payload.layoutId
          }
        }
      }
    case SET_INSIGHTS_LAYOUTS:
      layouts = _.keyBy(action.payload, 'id')
      return {
        ...state,
        layouts: {
          ...state.layouts,
          items: {
            ...state.layouts.items,
            ...layouts
          }
        }
      }
    case EDIT_INSIGHTS_LAYOUT:
      return {
        ...state,
        layouts: {
          ...state.layouts,
          editedItems: {
            ...state.layouts.editedItems,
            [action.payload.id]: action.payload
          }
        }
      }
    case DELETE_EDITED_INSIGHTS_LAYOUT:
      layouts = { ...state.layouts.editedItems }
      delete layouts[action.payload]
      return {
        ...state,
        layouts: {
          ...state.layouts,
          editedItems: layouts
        }
      }
    case UPDATE_INSIGHTS_LAYOUT:
      return {
        ...state,
        layouts: {
          ...state.layouts,
          items: {
            ...state.layouts.items,
            [action.payload.id]: action.payload
          }
        }
      }
    case DELETE_INSIGHTS_LAYOUT:
      layouts = { ...state.layouts.items }
      delete layouts[action.payload]
      return {
        ...state,
        layouts: {
          ...state.layouts,
          selectedId: {
            sessionLayout:
              sessionLayout === action.payload ? null : sessionLayout,
            competitionLayout:
              competitionLayout === action.payload ? null : competitionLayout,
            homeLayout: homeLayout === action.payload ? null : homeLayout
          },
          items: layouts
        }
      }
    case SET_INSIGHTS_CONFIGS:
      tableConfigsArray = action.payload.filter(
        (config: InsightsTableConfig) => {
          return !config.config.type || config.config.type === 'tables'
        }
      )
      tableConfigs = _.keyBy(tableConfigsArray, 'id')
      cardConfigsArray = action.payload.filter((config: InsightsCardConfig) => {
        return config.config.type === 'cards'
      })
      cardConfigs = _.keyBy(cardConfigsArray, 'id')

      return {
        ...state,
        tables: {
          ...state.tables,
          configs: {
            ...state.tables.configs,
            ...tableConfigs
          }
        },
        cards: {
          ...state.cards,
          configs: {
            ...state.cards.configs,
            ...cardConfigs
          }
        }
      }

    case REMOVE_INSIGHTS_LAYOUT_ITEM:
      let l = state.layouts.editedItems[action.payload.layoutId]
        ? state.layouts.editedItems[action.payload.layoutId]
        : state.layouts.items[action.payload.layoutId]

      let layout = l.layout.reactGridLayouts[
        action.payload.type as ConfigTypes
      ].layout.filter((item) => {
        return item.i !== action.payload.itemId
      })

      let contentMap = {
        ...l.layout.reactGridLayouts[action.payload.type as ConfigTypes]
          .contentMap
      }
      delete contentMap[action.payload.itemId]

      return {
        ...state,
        layouts: {
          ...state.layouts,
          editedItems: {
            ...state.layouts.editedItems,
            [action.payload.layoutId as string]: {
              ...l,
              layout: {
                ...l.layout,
                reactGridLayouts: {
                  ...l.layout.reactGridLayouts,
                  [action.payload.type as ConfigTypes]: {
                    layout,
                    contentMap
                  }
                }
              }
            }
          }
        }
      }

    case UPDATE_INSIGHTS_CONFIG:
      let type = action.payload.config.type
      return {
        ...state,
        [type]: {
          ...state[type],
          configs: {
            ...state[type].configs,
            [action.payload.id]: action.payload
          }
        }
      }
    case UPDATE_INSIGHTS_TABLE_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          data: {
            ...state.tables.data,
            [action.payload.id]: action.payload.data
          }
        }
      }
    case UPDATE_INSIGHTS_CARD_DATA:
      return {
        ...state,
        cards: {
          ...state.cards,
          data: {
            ...state.cards.data,
            [action.payload.id]: action.payload.data
          }
        }
      }
    case UPDATE_INSIGHTS_DATA_FETCH_QUEUE:
      console.log('updating fetch queue')
      return {
        ...state,
        dataFetchQueue: {
          ...state.dataFetchQueue,
          [action.payload.shortId]: action.payload
        }
      }
    default:
      return state
  }
}
