import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  DataTypeConfig
} from '../../data_types'

export type SessionStateTypeFeatures = {
  exampleSessionStateFeature: boolean
}

export const defaultSessionStateTypeFeatures: SessionStateTypeFeatures = {
  exampleSessionStateFeature: true
}

export const sessionStateTypesConfig = {
  notStarted: {
    key: 'notStarted',
    name: 'Not Started',
    value: 'NotStarted',
    props: {
      features: defaultSessionStateTypeFeatures
    }
  },
  started: {
    key: 'started',
    name: 'Started',
    value: 'Started',
    props: {
      features: defaultSessionStateTypeFeatures
    }
  },
  finished: {
    key: 'finished',
    name: 'Finished',
    value: 'Finished',
    props: {
      features: defaultSessionStateTypeFeatures
    }
  }
} as const

export type SessionStateTypeProps = {
  features: SessionStateTypeFeatures
}

export type SessionStateTypes = DataTypes<typeof sessionStateTypesConfig>

export type SessionStateTypeKey = DataTypeKey<SessionStateTypes>

export type SessionStateTypeKeys = DataTypeKeys<SessionStateTypeKey>

export type SessionStateTypeValues = DataTypeValues<SessionStateTypeKey>

export const getSessionStateTypeGroup = (items: {
  [key in SessionStateTypeKeys]?: SessionStateTypes[key]
}) => {
  return getDataTypeGroup<
    SessionStateTypeKeys,
    SessionStateTypeValues,
    SessionStateTypes,
    SessionStateTypeProps
  >(items)
}

export type SessionStateTypeGroup = ReturnType<typeof getSessionStateTypeGroup>

export type SessionStateTypeConfig = DataTypeConfig<
  SessionStateTypeKeys,
  SessionStateTypeValues,
  SessionStateTypeProps
>

export type SessionStateType =
  SessionStateTypeGroup['items'][SessionStateTypeKeys]

export const sessionStateTypes = getSessionStateTypeGroup(
  sessionStateTypesConfig
)

export const isSessionStateType = generateTypeChecks<
  SessionStateTypeKeys,
  SessionStateTypeGroup
>(sessionStateTypes)
