import { useCallback, useMemo, useReducer } from 'react'
import { Options } from '../metrics_server/data_types'

export interface Filter<v> {
  options: Options<v>
  value: v
}

export interface TimeFilter {
  value: number
}

export type SearchStrFilter = {
  value: string
}

export interface FilterMultiple<v> {
  options: Options<v>
  value: v[]
}

export function useFilterReducer<Filters>(initialFilterState: Filters) {
  const UPDATE_FILTER = 'UPDATE_FILTER'
  const UPDATE_OPTIONS = 'UPDATE_OPTIONS'

  const filterReducer = (state: Filters, action) => {
    switch (action.type) {
      case UPDATE_FILTER:
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value
          }
        }
      case UPDATE_OPTIONS:
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            options: action.payload.options
          }
        }
      default:
        return state
    }
  }

  const [filters, dispatch] = useReducer<typeof filterReducer>(
    filterReducer,
    initialFilterState
  )

  const updateFilterValue = useCallback((name: keyof Filters, value) => {
    dispatch({
      type: UPDATE_FILTER,
      payload: { name, value }
    })
  }, [])

  const updateFilterOptions = (name: keyof Filters, options) => {
    dispatch({
      type: UPDATE_OPTIONS,
      payload: { name, options }
    })
  }

  return useMemo(() => {
    return {
      filters,
      updateFilterValue,
      updateFilterOptions
    }
  }, [filters, updateFilterValue, updateFilterOptions])
}
