import { useState, useEffect } from 'react'

export const useImageInputLogic = (props) => {
  const [img, setImage] = useState(props.input.value)

  useEffect(() => {
    props.input.onChange(img)
  }, [img])

  const getFiles = (file: any) => {
    if (file.type.slice(0, 5) === 'image') {
      setImage(file.base64)
    } else {
      console.log('Error!: That file was not an image')
    }
  }
  return { getFiles, img }
}
