import React from 'react'
import { connect } from 'react-redux'

import styles from './Notification.module.scss'

import { notificationActions } from '../../../metrics_server/notifications'
import { DiagnosticNotification } from './DiagnosticNotification/DiagnosticNotification'
import { DownloadNotification } from './DownloadNotification/DownloadNotification'
import {
  NotificationsState,
  NotificationType
} from '../../../metrics_server/notifications/types'

export interface NotificationProps {
  notifications: NotificationsState
  clearNotification: (notification: NotificationType) => void
  clearNotifications: () => void
}

const Notification = (props: NotificationProps) => {
  const { notifications } = props

  const renderNotifications = (notification: NotificationType, index) => {
    const { timestamp, arrivalTime, type, text } = notification
    const timeDifference = arrivalTime - timestamp
    const isRelevantNotification = timeDifference <= 20

    if (type && isRelevantNotification && notifications.on) {
      return (
        <React.Fragment key={index}>
          <DiagnosticNotification
            clear={() => props.clearNotification(notification)}
            notification={notification}
          />
        </React.Fragment>
      )
    }

    if (text && text.includes('Downloading')) {
      return (
        <React.Fragment key={index}>
          <div className={styles.notification}>
            <DownloadNotification
              clear={() => props.clearNotification(notification)}
              notification={notification}
            />
          </div>
        </React.Fragment>
      )
    }
    return null
  }

  return (
    <div className={styles.notificationContainer}>
      {notifications.popUpNotifications.map(renderNotifications)}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications
  }
}

export default connect(mapStateToProps, notificationActions)(Notification)
