import { sportableColors } from '../../../constants/sportableColors'
import React from 'react'

export interface RefereeIconProps {
  color?: string
  scale: number
}

export const RefereeIcon = ({
  color = sportableColors.colors.sportableGreyDark,
  scale
}: RefereeIconProps) => {
  return (
    <svg
      width={`${35 * scale}`}
      height={`${42 * scale}`}
      viewBox='0 0 31 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.2134 17.8541C20.2134 17.7769 20.1363 17.6998 20.1363 17.6226C18.8247 19.1657 17.2046 20.0143 15.3529 20.0143C13.5013 20.0143 11.8811 19.1657 10.6467 17.6226C10.6467 17.6998 10.5696 17.7769 10.5696 17.8541C10.9553 18.5484 10.1067 19.9372 15.3529 25.8006C20.8306 19.86 19.9048 18.4713 20.2134 17.8541Z'
        fill={color}
      />
      <path
        d='M7.71509 21.0944V37.0646H9.33526V22.0974C9.02665 21.5573 8.87235 21.0173 8.71805 20.5544C8.40944 20.7858 8.10084 20.9401 7.71509 21.0944Z'
        fill={color}
      />
      <path
        d='M10.4926 37.0646H12.1127V25.7235C11.5727 25.1834 11.0326 24.4891 10.4926 23.8719V37.0646Z'
        fill={color}
      />
      <path
        d='M5.16917 24.8748C3.16325 25.492 1.85168 27.3436 1.85168 29.4267V37.1418H3.16325V31.9727C3.16325 29.6582 4.47481 27.6522 6.48073 26.7264V24.5662C6.01783 24.6434 6.24928 24.5662 5.16917 24.8748Z'
        fill={color}
      />
      <path
        d='M13.8871 27.4208C13.6557 27.2665 13.5013 27.035 13.2699 26.8036V27.9609C13.4242 27.8065 13.6557 27.5751 13.8871 27.4208Z'
        fill={color}
      />
      <path
        d='M14.5043 32.4356C14.0414 32.2813 13.5785 31.9727 13.2699 31.5869V37.0646H14.8901V35.2902H14.5043V32.4356Z'
        fill={color}
      />
      <path
        d='M0 27.4979V37.0646H0.771508V29.3496C0.771508 26.8036 2.39168 24.4891 4.8605 23.7176C6.86642 23.1003 6.48067 23.1775 6.55782 23.1775V21.4802C7.40648 21.1716 4.5519 22.0974 3.70324 22.406C1.46587 23.1003 0 25.1834 0 27.4979Z'
        fill={color}
      />
      <path
        d='M4.39751 37.0646H6.55773V27.9608C3.3174 30.0439 4.78326 33.67 4.39751 37.0646Z'
        fill={color}
      />
      <path
        d='M16.9731 27.4208C17.2046 27.5751 17.436 27.8065 17.5904 27.9609V26.8036C17.3589 27.035 17.2046 27.2665 16.9731 27.4208Z'
        fill={color}
      />
      <path
        d='M17.6675 35.3673H16.0474V37.1418H17.6675V35.3673Z'
        fill={color}
      />
      <path
        d='M27.1571 22.406C25.9227 22.0202 24.9968 21.7116 24.3025 21.4802V23.2546C24.3796 23.2546 24.071 23.1775 25.9998 23.7947C28.4686 24.5662 30.0888 26.8036 30.0888 29.4267V37.1418H30.8603V27.4979C30.8603 25.1834 29.3944 23.1003 27.1571 22.406Z'
        fill={color}
      />
      <path
        d='M24.3025 27.9608V37.0646H26.4627C26.077 33.67 27.5428 30.0439 24.3025 27.9608Z'
        fill={color}
      />
      <path
        d='M24.3025 24.4891V26.6493C29.0858 28.9638 27.2342 34.3644 27.62 37.0646H28.9315V29.3496C28.9315 26.4178 27.0799 25.3377 24.3025 24.4891Z'
        fill={color}
      />
      <path
        d='M21.5251 22.0974V37.0646H23.1453V21.0944C23.6082 21.2487 22.6824 20.9401 22.1424 20.5544C22.0652 21.0173 21.8337 21.5573 21.5251 22.0974Z'
        fill={color}
      />
      <path
        d='M18.7477 37.0646H20.3678V23.8719C19.9049 24.4891 19.2877 25.1834 18.7477 25.7235V37.0646Z'
        fill={color}
      />
      <path
        d='M23.068 11.9135C22.7594 12.9164 22.065 13.6108 21.525 13.4565C20.522 16.8511 18.2075 19.1657 15.5072 19.1657C12.8069 19.1657 10.4924 16.774 9.48945 13.4565C7.79213 13.7651 7.02062 9.2132 8.87224 9.6761C8.33219 7.90163 8.25504 5.97286 8.79509 4.19839C9.1037 3.11828 9.7209 2.65538 10.4924 2.11532C10.9553 1.72957 11.0325 2.03817 11.3411 1.96102C11.5725 1.96102 11.9583 1.49811 11.9583 1.42096C13.7327 -0.662111 21.2935 -1.27932 22.065 5.43281C22.1422 6.05001 22.2193 8.44169 22.1422 9.75325C22.2965 9.75325 22.3736 9.75325 22.5279 9.75325C23.1451 9.90755 23.3766 10.9105 23.068 11.9135Z'
        fill={color}
      />
      <path
        d='M15.8159 26.5721C15.5844 26.4178 15.353 26.495 15.1215 26.5721C13.8871 25.1834 10.5696 21.403 10.1067 19.0114C10.0295 19.1657 9.9524 19.32 9.7981 19.4743C10.4925 22.0202 13.6556 25.5692 14.8129 26.8807C14.5814 27.2665 14.7357 27.5751 14.9672 27.8065C14.0414 28.038 13.4242 28.8095 13.4242 29.8125C13.4242 30.8926 14.2728 31.7412 15.2758 31.8184V34.5958H17.5132V32.4356H16.6645V32.0498L17.5132 31.5869V29.8125C17.5903 28.8095 16.896 27.9608 15.9702 27.7294C16.2016 27.4979 16.3559 27.1122 16.1245 26.7264C17.3589 25.4149 20.4449 21.8659 21.1393 19.32C21.0621 19.1657 20.9078 19.0113 20.8307 18.857C20.3678 21.403 17.0503 25.1834 15.8159 26.5721Z'
        fill={color}
      />
    </svg>
  )
  // return (
  //   <svg
  //     width={`${4 * scale}`}
  //     height={`${40 * scale}`}
  //     viewBox='0 0 41 28'
  //     fill='none'
  //     xmlns='http://www.w3.org/2000/svg'
  //   >
  //     <path
  //       fill-rule='evenodd'
  //       clip-rule='evenodd'
  //       d='M25.9567 0.000676278C26.1554 0.00839059 26.3465 0.0815133 26.502 0.209352C26.6576 0.337192 26.7695 0.51305 26.8213 0.711224L27.7293 4.00083C27.7652 4.12305 27.7772 4.25144 27.7645 4.37848C27.7519 4.50553 27.7149 4.62869 27.6556 4.74082C27.5964 4.85294 27.5161 4.95177 27.4195 5.03154C27.3229 5.1113 27.2119 5.17041 27.0929 5.20541C26.974 5.24041 26.8494 5.25061 26.7266 5.23541C26.6038 5.22021 26.4851 5.17992 26.3775 5.11688C26.27 5.05384 26.1756 4.96932 26.1 4.86824C26.0244 4.76717 25.969 4.65153 25.9371 4.52813L25.0291 1.23856C24.9866 1.09197 24.9785 0.937012 25.0055 0.786549C25.0326 0.636086 25.094 0.494451 25.1847 0.373411C25.2754 0.252372 25.3928 0.155414 25.527 0.0905888C25.6613 0.0257633 25.8086 -0.00506006 25.9567 0.000676278Z'
  //       fill={color}
  //     />
  //     <path
  //       fill-rule='evenodd'
  //       clip-rule='evenodd'
  //       d='M30.6106 0.374611C30.7485 0.373809 30.885 0.404647 31.01 0.464907C31.135 0.525166 31.2455 0.613343 31.3335 0.723063C31.4216 0.832782 31.4849 0.961307 31.5189 1.09936C31.553 1.23742 31.5569 1.38156 31.5304 1.52137L30.9031 5.10072C30.884 5.22757 30.8405 5.34922 30.7752 5.45846C30.7099 5.5677 30.6241 5.66233 30.5228 5.7368C30.4216 5.81127 30.3069 5.86407 30.1855 5.89208C30.0642 5.92009 29.9386 5.92274 29.8163 5.89989C29.6939 5.87703 29.5773 5.82912 29.4731 5.75899C29.369 5.68886 29.2795 5.59794 29.2099 5.49155C29.1403 5.38517 29.0921 5.26547 29.068 5.13954C29.0439 5.0136 29.0444 4.88397 29.0696 4.75825L29.6969 1.1789C29.733 0.955162 29.8444 0.751754 30.0116 0.604594C30.1787 0.457433 30.3909 0.375986 30.6106 0.374611Z'
  //       fill={color}
  //     />
  //     <path
  //       fill-rule='evenodd'
  //       clip-rule='evenodd'
  //       d='M35.6333 2.85086C35.8265 2.84544 36.0166 2.9022 36.1772 3.01327C36.3378 3.12433 36.461 3.28421 36.5297 3.47076C36.5984 3.65731 36.6092 3.86129 36.5606 4.05447C36.5121 4.24764 36.4065 4.42042 36.2586 4.54891L33.5677 6.95221C33.4752 7.03499 33.3677 7.09812 33.2516 7.13802C33.1354 7.17791 33.0127 7.19379 32.8906 7.18473C32.7685 7.17566 32.6493 7.14185 32.5399 7.08521C32.4304 7.02856 32.3328 6.95022 32.2527 6.85463C32.1726 6.75904 32.1115 6.64808 32.073 6.52811C32.0344 6.40813 32.0191 6.28151 32.028 6.15543C32.0368 6.02935 32.0696 5.90631 32.1246 5.79334C32.1795 5.68036 32.2555 5.57967 32.3481 5.49702L35.039 3.09375C35.2032 2.94298 35.4137 2.85695 35.6333 2.85086Z'
  //       fill={color}
  //     />
  //     <path
  //       fill-rule='evenodd'
  //       clip-rule='evenodd'
  //       d='M26.3821 6.83917C31.1586 6.79938 35.2568 10.1614 36.4428 14.7305C36.8926 14.4633 37.419 14.2929 37.9716 14.2929C39.6421 14.2929 41 15.7107 41 17.4313C41 19.1426 39.6421 20.5547 37.9716 20.5547C37.419 20.5547 36.8926 20.3977 36.4428 20.1322C35.2735 24.6526 31.2567 28.0085 26.4694 28.0085C22.2157 28.0085 18.572 25.3475 16.9911 21.5958C16.9749 21.5572 16.9486 21.5137 16.9329 21.4751C15.5764 17.8043 13.095 15.7207 10.5849 14.4438C7.88626 13.1135 4.61348 12.6332 1.01913 12.6332C0.481704 12.6332 0 12.1427 0 11.5921V7.89537C0 7.34472 0.481704 6.83922 1.01913 6.83917H26.0909C26.1998 6.84206 26.2875 6.83352 26.3821 6.83917ZM15.1712 8.93648H14.4577V10.0983H15.1712V8.93648ZM26.3967 8.95154C26.2499 8.9525 26.1227 8.94227 25.9744 8.95154C21.6305 9.23504 18.1996 12.9105 18.1996 17.4313C18.2649 18.7177 18.4016 19.6951 18.8984 20.8565C20.1775 23.8233 23.0773 25.896 26.4694 25.896C31.0628 25.896 34.7393 22.1163 34.7393 17.4313C34.8218 12.4654 30.473 8.73575 26.3967 8.95154ZM37.9716 16.4053C37.3959 16.4053 36.9524 16.8416 36.9524 17.4313C36.9524 17.9932 37.3959 18.4574 37.9716 18.4574C38.5201 18.4574 38.9471 17.9932 38.9471 17.4313C38.9471 16.8416 38.5201 16.4053 37.9716 16.4053Z'
  //       fill={color}
  //     />
  //     <path
  //       fill-rule='evenodd'
  //       clip-rule='evenodd'
  //       d='M26.4696 10.6264C30.132 10.6264 33.1379 13.6752 33.1379 17.4313C33.1379 21.1875 30.132 24.2514 26.4696 24.2514C22.822 24.2514 19.8158 21.1875 19.8158 17.4313C19.8158 13.6752 22.822 10.6264 26.4696 10.6264ZM26.4696 12.7388C23.9332 12.7388 21.8687 14.8151 21.8687 17.4313C21.8687 20.0476 23.9332 22.139 26.4696 22.139C29.0273 22.139 31.085 20.0476 31.085 17.4313C31.085 14.8151 29.0273 12.7388 26.4696 12.7388Z'
  //       fill={color}
  //     />
  //   </svg>
  // )
}
