import { Option } from '../../components/Forms/Fields/SelectField/SelectField'

export namespace UnitSystemName {
  export const American = 'American'
  export const British = 'British'
  export const Metric = 'Metric'
  export const SI = 'SI'
  export const Canadian = 'Canadian'
  export const Default = 'Default'
}

export type UnitSystemNames = keyof typeof UnitSystemName
export type UnitSystemTypeValues =
  | (typeof UnitSystemName)[keyof typeof UnitSystemName]

export type UnitsState = {
  selected: UnitSystemNames
  systemOptions: Option[]
}

export type UnitSystems = {
  [key in UnitSystemNames]: UnitSystem
}

export type UnitType = {
  name: string
  abbreviation: string
  conversion?: number
}

export type UnitSystem = {
  name: UnitSystemNames
  key: UnitSystemNames
  units: {
    distance: UnitType
    time: UnitType
    speed: UnitType
    revs: UnitType
    percentage: UnitType
    angle: UnitType
  }
}

// Actions

export interface UpdateUnitAction {
  type: string
  payload: string
}

export type UpdateUnit = (data: any) => UpdateUnitAction
