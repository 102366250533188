import { sleep } from './helpers'

export const WebSocketController = {
  websockets: {},
  recconnectIntervals: {},

  connectWebSocket: function (
    host,
    channel,
    callback,
    callbackName = 'callback'
  ) {
    if (!this.websockets[channel]) {
      try {
        this.websockets[channel] = {
          callbacks: [
            {
              callback,
              name: callbackName
            }
          ]
        }
        let authorization = localStorage.getItem('authorization')
        let a = authorization.split('Bearer ')
        let token = ''
        if (a[1] && a[0] === '') {
          token = a[1]
        }
        this.websockets[channel].socket = new WebSocket(
          `ws://${host}${channel}?token=${token}`
        )
        this.websockets[channel].socket.onmessage = (evt) => {
          try {
            this.websockets[channel].callbacks.forEach((x) => {
              x.callback(JSON.parse(evt.data))
            })
            // if (channel === 'position') timeLag(JSON.parse(evt.data))
          } catch (e) {
            console.log(
              `${channel} websocket ${callbackName} error ---`,
              e.message
            )
            console.log(e)
          }
        }
        this.websockets[channel].socket.onclose = async (evt) => {
          if (evt.code !== 3001) {
            let callbacks = this.websockets[channel]
              ? this.websockets[channel].callbacks
              : []
            delete this.websockets[channel]
            console.log(`${channel} websocket attempting to reconnect`)
            await sleep(2000)
            callbacks.forEach((x) => {
              this.connectWebSocket(host, channel, x.callback, x.name)
            })
          } else {
            console.log(`${channel} websocket connection closed`)
          }
        }
        this.websockets[channel].socket.onopen = () => {
          console.log(`${channel} websocket connection opened`)
        }
        this.websockets[channel].socket.onerror = () => {
          console.log(`${channel} - websocket connection error`)
        }
      } catch (e) {
        console.log(e.message)
      }
    } else {
      if (
        !this.websockets[channel].callbacks.some((x) => x.name === callbackName)
      ) {
        this.websockets[channel].callbacks.push({
          callback,
          name: callbackName
        })
      }
      // console.log(`${channel} websocket callback updated`)
    }
  },

  checkForUser: function (connect) {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'))
      if (user.id) {
        connect()
      }
    }
  },

  disconnectWebSocket: function (channel, callbackName = 'callback') {
    if (this.websockets[channel]) {
      try {
        this.websockets[channel].callbacks = this.websockets[
          channel
        ].callbacks.filter((x) => x.name !== callbackName)
        if (this.websockets[channel].callbacks.length < 1) {
          this.websockets[channel].socket.close(3001)
          delete this.websockets[channel]
        }
      } catch (e) {
        console.log(e.message)
      }
    } else {
      console.log(`${channel} websocket connection not active`)
    }
  },

  closeAllSockets: function () {
    for (let channel in this.websockets) {
      this.websockets[channel].callbacks.forEach((x) => {
        this.disconnectWebSocket(channel, x.name)
      })
    }
  },

  doSend: function (message, channel) {
    try {
      this.websockets[channel].send(message)
    } catch (e) {
      console.log(e.message)
    }
  }
}

// setInterval(() => {
//   timeLag(messageBuffer[0])
// }, 50)

// let mostRecentTimestamp = 0

// function timeLag(temp) {
//   if (temp.timestamp > mostRecentTimestamp) {
//     mostRecentTimestamp = temp.timestamp
//   } else if (mostRecentTimestamp - temp.timestamp > 0.2) {
//     console.log(
//       'Lag on tag: ',
//       temp.tagId,
//       ' = ',
//       mostRecentTimestamp - temp.timestamp
//     )
//   }
// }

export default WebSocketController
