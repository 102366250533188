import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
// import { store } from '../../index';
import { Provider, ReactReduxContext } from 'react-redux'
import styles from './Portal.module.scss'

interface PortalProps {
  store?: {}
  children: React.ReactNode
}

class Portal extends Component<PortalProps> {
  portal = null

  componentWillMount() {
    this.portal = document.createElement('div')
    this.portal.className = styles.portal
    document.body.prepend(this.portal)
  }

  componentWillUnmount() {
    document.body.removeChild(this.portal)
  }

  render() {
    const { children } = this.props
    return ReactDOM.createPortal(
      <ReactReduxContext.Consumer>
        {((ctx) => <Provider store={ctx.store}>{children}</Provider>).bind(
          this
        )}
      </ReactReduxContext.Consumer>,
      this.portal!
    )
  }

  // render() {
  //   return <noscript />
  // }
}

export default Portal
