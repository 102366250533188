import React, { Component } from 'react'
import { Canvas2D } from './2DCanvas'
import { Canvas3D } from './3DCanvas'
import styles from './Canvas.module.scss'

interface CanvasContainerProps {
  hidden?: boolean
  id: string
  show2DOnly: boolean
  view: string
  cover: string
}

export class CanvasContainer extends Component<CanvasContainerProps> {
  private canvasDiv: React.RefObject<HTMLInputElement>

  constructor(props) {
    super(props)

    this.canvasDiv = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { hidden } = this.props
    if (prevProps.hidden && !hidden) {
      this.canvasDiv.current.style.display = 'block'
    } else if (!prevProps.hidden && hidden) {
      this.canvasDiv.current.style.display = 'none'
    }
  }

  render() {
    const { cover, id, show2DOnly, view } = this.props
    let canvasStyles = {
      height: '100%'
    }

    return (
      <div id={`${id}-canvas-section`} className={styles.trackingContainer}>
        <div
          id={`${id}-strack-canvas-container`}
          style={canvasStyles}
          className={styles.canvasContainer}
        >
          {!show2DOnly ? (
            <React.Fragment>
              <Canvas3D id={id} hidden={view !== '3D'} />
              <Canvas2D id={id} cover={cover} hidden={view !== '2D'} />
            </React.Fragment>
          ) : (
            <Canvas2D id={id} cover={cover} />
          )}
        </div>
      </div>
    )
  }
}

export default CanvasContainer
