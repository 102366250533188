export interface StatusState {
  dbsync: DBSyncStatus
}

export interface DBSyncStatus {
  connection: string
  connectionStatus: string
  syncStatus: SyncStatuses
  recentError: string
  userAgent: string
}

export namespace SyncStatus {
  export const ERROR = 'ERROR'
  export const OK = 'OK'
  export const DISABLED = 'DISABLED'
  export const SERVER = 'SERVER'
}

export type SyncStatuses = keyof typeof SyncStatus

export namespace StatusActionTypes {
  export const SET_DBSYNC_STATUS = 'SET_DBSYNC_STATUS'
}
