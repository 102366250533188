import React from 'react'
import styles from './SelectionSidebar.module.scss'

export interface SelectionSidebarProps {
  children: any
}

export const SelectionSideBar = ({ graphicsSelectionList, input }) => {
  return (
    <>
      {graphicsSelectionList.map((selection, index) => {
        const isSelected = input.id === selection.id
        return (
          <div
            key={`Selection-SideBar ${index}`}
            className={`${styles.selectionContainer} ${
              isSelected && styles.selectionContainerActive
            }`}
            onClick={() => {
              input.onSelect(selection.id)
            }}
          >
            <h5
              className={`${styles.selection} ${
                isSelected && styles.selectionActive
              }`}
            >
              {selection.selectionName}
            </h5>
          </div>
        )
      })}
    </>
  )
}
