import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './Targets.module.scss'
import {
  getSelectedTarget,
  getTargetMetricsTableData,
  getTargetRadiusTableData,
  operatorTypes
} from '../../../metrics_server/targets/functions'

import { Button } from '../../../components/Button/Button'
import { Table } from '../../../components/Table/Table'
import { PlayerKeyItem } from '../../../components/PlayerKeyItem/PlayerKeyItem'
import { TargetCard } from '../../../components/TargetCard/TargetCard'
import { PitchOverlayCard } from '../../../components/PitchOverlayCard/PitchOverlayCard'
import { ListInset } from '../../../components/ListInset/ListInset'
import { CardList } from '../../../components/CardList/CardList'
import { FlightData } from '../../../metrics_server/events/flight/types'
import { TargetsState, Target } from '../../../metrics_server/targets/types'
import { Strack, CanvasStyle } from '../../../components/Strack/Strack.types'
import { UnitSystem } from '../../../metrics_server/units/types'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'
import { useNewTargetModal } from '../modals/new_target'
import { useChallenge, useTargets } from '../../../metrics_server/targets/hooks'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { useEvents } from '../../../metrics_server/events/hooks'
import { useAppDispatch } from '../../../store/hooks'
import {
  setSelectedTarget,
  updateTargetPlayerSessions,
  updateTarget
} from '../../../metrics_server/targets/actions'

export interface TargetsProps {
  strackReady: boolean
  strack: Strack
  canvasStyle: CanvasStyle
  canvasView: string
  setOptions
  active: boolean | string
  filteredFlights: FlightData[]
  toggleCanvasVisibility: (canvasId: string) => void
  canvasId: string
}

export function Targets({
  strackReady,
  strack,
  canvasStyle,
  canvasView,
  setOptions,
  active,

  toggleCanvasVisibility,
  canvasId,

  filteredFlights
}: TargetsProps) {
  // Modals //
  const { openNewTargetModal } = useNewTargetModal()
  // ====== //

  // Redux //
  const targets = useTargets()
  const events = useEvents()
  const dispatch = useAppDispatch()
  // ====== //

  // Session //
  const { sport, playersSessions, players } = useSelectedFormattedSession()
  // ======== //

  // Targets //
  const { challenge } = useChallenge()
  // ======= //

  useEffect(() => {
    if (strackReady) {
      strack.initiateTargetCanvas(() => {
        drawTargets(
          strack,
          strackReady,
          targets,
          target ? [target] : [],
          unitSystem
        )
      })
    }
  }, [strackReady])

  const [radius, setRadius] = useState(null)
  const [metric, setMetric] = useState(null)
  const [operator, setOperator] = useState(null)
  const [coords, setCoords] = useState(null)

  const defaultOperators = {
    hangTime: 2,
    dist: 2,
    efficiency: 2,
    endOverEndEfficiency: 2,
    spin: 2
  }

  const unitSystem = useUnitsSystem(sport)

  const [initialOperators, setInitialOperators] = useState(defaultOperators)

  const [target, setTarget] = useState(getSelectedTarget(challenge, targets))

  useEffect(() => {
    const newTarget = { ...getSelectedTarget(challenge, targets) }
    if (target && newTarget.id !== target.id) {
      setInitialOperators(defaultOperators)
    }
    setTarget(newTarget)
  }, [challenge.Targets, targets.selected.selectedTarget, initialOperators])

  // TODO: This needs a refactor - hiding and showing canavases should be handled further up the tree - same goes for session target component //
  const previousChallengeArrayLength = useRef(challenge.Targets.length)
  useEffect(() => {
    if (
      previousChallengeArrayLength.current === 0 &&
      challenge.Targets.length > 0
    ) {
      toggleCanvasVisibility(canvasId)
    } else if (
      previousChallengeArrayLength.current > 0 &&
      challenge.Targets.length === 0
    ) {
      toggleCanvasVisibility(canvasId)
    }
    previousChallengeArrayLength.current = challenge.Targets.length
  }, [challenge.Targets.length])

  useEffect(() => {
    setTargetMetricsTableData(
      getTargetMetricsTableData(
        target,
        events,
        unitSystem,
        initialOperators,
        sport
      )
    )
    setTargetRadiusTableData(getTargetRadiusTableData(target))
    drawTargets(
      strack,
      strackReady,
      targets,
      target ? [target] : [],
      unitSystem
    )
  }, [target])

  // Metrics Table
  const [metricsTableHeaders] = useState([
    { name: 'Metric', key: 'name', width: 40, type: 'text' },
    {
      name: 'Value',
      key: 'value',
      width: 30,
      type: 'text',
      input: {
        onChange: (item, value) => updateTargetMetric(item, value),
        type: 'text'
      }
    },
    {
      name: 'Operator',
      key: 'operator',
      width: 30,
      type: 'text',
      input: {
        onChange: (item, value) => updateMetricOperator(item, value),
        type: 'select',
        selectOptions: [
          { name: '<', value: 1 },
          { name: '>', value: 2 }
        ]
      }
    }
  ])

  const [targetMetricsTableData, setTargetMetricsTableData] = useState(
    getTargetMetricsTableData(
      target,
      events,
      unitSystem,
      initialOperators,
      sport
    )
  )

  // Target Radius Table
  const radiiTableHeaders = useMemo(
    () => [
      {
        name: 'Target Area',
        key: 'area',
        width: 50,
        type: 'color'
      },
      {
        name: `Radius (${unitSystem.units.distance.abbreviation})`,
        key: 'radius',
        width: 50,
        type: 'number',
        input: {
          onChange: (item, value) => updateTargetRadius(item, value),
          type: 'text'
        }
      }
    ],
    [unitSystem]
  )

  const [targetRadiusTableData, setTargetRadiusTableData] = useState(
    getTargetRadiusTableData(target)
  )

  const drawTargets = (
    strack: Strack,
    strackReady: boolean,
    targets: TargetsState,
    targetsArray: Target[],
    unitSystem: UnitSystem
  ) => {
    if (strackReady) {
      strack.setTargets(
        targetsArray,
        targets.selected.selectedTarget,
        (targetId) => {
          dispatch(setSelectedTarget(targetId))
        },
        updateTargetPosition,
        unitSystem
      )
      strack.drawTargets()
    }
  }

  const updateTargetPosition = (coords) => {
    setCoords({ x: coords.pitchX, y: coords.pitchY })
  }

  useEffect(() => {
    if (coords) {
      const data = { ...target }
      data.x = coords.x
      data.y = coords.y
      dispatch(updateTarget(data, data.id))
    }
  }, [coords])

  const updatePlayersInTarget = (item, target) => {
    const playerSession = playersSessions.byPlayerId.map[item.id]
    if (playerSession)
      dispatch(updateTargetPlayerSessions(playerSession.id, target))
  }

  // Update Radius
  const updateTargetRadius = (item: any, value: any) => {
    setRadius({ index: item.id, value: parseFloat(value) })
  }

  useEffect(() => {
    if (radius) {
      const data = { ...target }
      data.radii[radius.index] = radius.value
      dispatch(updateTarget(data, data.id))
    }
  }, [radius])

  // Update target metric
  const updateTargetMetric = (item, value) => {
    setMetric({
      ...item,
      value: parseFloat(value)
    })
  }

  useEffect(() => {
    if (metric) {
      const data = { ...target }
      let value = metric.value
      if (metric.percentage) value = value / 100

      data[metric.key] = {}
      if (target[metric.key]) {
        if (!target[metric.key].greaterThan && !target[metric.key].lessThan) {
          const type =
            initialOperators[metric.key] === operatorTypes.greaterThan
              ? 'greaterThan'
              : 'lessThan'
          data[metric.key][type] = value
        } else if (metric.operator === operatorTypes.lessThan) {
          data[metric.key].lessThan = value
        } else if (metric.operator === operatorTypes.greaterThan) {
          data[metric.key].greaterThan = value
        }
      }
      dispatch(updateTarget(data, data.id))
    }
  }, [metric])

  // Update operator

  const updateMetricOperator = (item, value) => {
    setOperator({
      key: item.key,
      value
    })
  }

  useEffect(() => {
    if (operator) {
      const data = { ...target }
      data[operator.key] = {}
      if (target[operator.key].greaterThan || target[operator.key].lessThan) {
        if (parseInt(operator.value) === operatorTypes.lessThan) {
          data[operator.key].lessThan =
            target[operator.key].greaterThan || target[operator.key].lessThan
        } else if (parseInt(operator.value) === operatorTypes.greaterThan) {
          data[operator.key].greaterThan =
            target[operator.key].lessThan || target[operator.key].greaterThan
        }
        dispatch(updateTarget(data, target.id))
      } else {
        // If value hasn't been set on target yet update metric operator state locally local
        setInitialOperators({
          ...initialOperators,
          [operator.key]: parseInt(operator.value)
        })
      }
    }
  }, [operator])

  const playersInTarget = useMemo(() => {
    if (target && target.targetPlayerSessions) {
      return target.targetPlayerSessions.map((tps) => {
        const playerSession = playersSessions.byId.map[tps.playersSessionsId]
        if (playerSession) {
          return playerSession.playerId
        } else {
          return null
        }
      })
    }
    return []
  }, [target, playersSessions])

  return (
    <React.Fragment>
      {/* TODO: Draw selected flights on canvas - need to move up the event filter in FlightSelect */}
      {/* <StrackEvents
        events={strackEvents}
        active={active}
        canvasView={canvasView}
        strack={strack}
        canvasStyle={canvasStyle}
        strackReady={strackReady}
      /> */}
      <div className={styles.addTargetButton}>
        <Button
          className={`button btn--primary btn--thin  btn-full-width`}
          handleClick={openNewTargetModal}
        >
          Add Target
        </Button>
      </div>
      <div className={styles.targetsContainer}>
        <CardList
          col={12}
          items={challenge.Targets || []}
          scrollerId={`scroller-${1}`}
          padding={'0 10px 0 0'}
          border={true}
          selected={targets.selected.selectedTarget}
        >
          <TargetCard />
        </CardList>
      </div>

      {challenge.Targets?.length < 1 ? (
        <div className={styles.noTargetContainer}>
          <div className={styles.placeHolderText}>Add target...</div>
        </div>
      ) : (
        <React.Fragment>
          <div className={styles.targetHeaderContainer}>
            <h3>
              {target && target.name ? target.name : 'Select a target...'}
            </h3>
          </div>
          <div className={styles.targetInfoContainer}>
            {target && strackReady && (
              <PitchOverlayCard
                position={'bottom'}
                width={'180px'}
                height={'30px'}
                dropdown={false}
              >
                <div className={styles.targetInfo}>
                  <p>Drag target into position</p>
                </div>
              </PitchOverlayCard>
            )}
          </div>
          <div className={styles.targetDetailContainer}>
            <CardList
              col={12}
              items={[{}]}
              scrollerId={`scroller-${2}`}
              className='maxHeight'
            >
              <div className={styles.detailInnerContainer}>
                <div className={styles.title}>
                  <h5>Set target metrics</h5>
                </div>
                <div className={styles.tableContainer}>
                  <Table
                    // Table props
                    options={{
                      initialOrder: 'dec',
                      initialSortBy: 'startTime',
                      sortActive: false
                    }}
                    headerFont={13}
                    tableClass={'minimalistBlack'}
                    className={'small-container'}
                    smallHead={true}
                    data={targetMetricsTableData}
                    headers={metricsTableHeaders}
                  />
                </div>
              </div>
            </CardList>
          </div>
          <div className={styles.radiusSelectionContainer}>
            <CardList
              col={12}
              items={[{}]}
              scrollerId={`scroller-${2}`}
              className='maxHeight'
            >
              <div className={styles.detailInnerContainer}>
                <div className={styles.title}>
                  <h5>Set target area</h5>
                </div>
                <div className={styles.tableContainer}>
                  <Table
                    // Table props
                    options={{
                      initialOrder: 'dec',
                      initialSortBy: 'startTime',
                      sortActive: false
                    }}
                    headerFont={13}
                    tableClass={'minimalistBlack'}
                    className={'small-container'}
                    smallHead={true}
                    data={targetRadiusTableData}
                    headers={radiiTableHeaders}
                  />
                </div>
              </div>
            </CardList>
          </div>
          <div className={styles.playerSelectContainer}>
            <CardList
              col={12}
              items={[{}]}
              scrollerId={`scroller-${2}`}
              className='maxHeight'
            >
              <div className={styles.detailInnerContainer}>
                <div className={styles.title}>
                  <h5>Select players in target</h5>
                </div>
                <div className={styles.tableContainer}>
                  <ListInset items={players.all.list}>
                    <PlayerKeyItem
                      playerIds={playersInTarget}
                      onUpdate={(name, checkedList, item) =>
                        updatePlayersInTarget(item, target)
                      }
                    />
                  </ListInset>
                </div>
              </div>
            </CardList>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
