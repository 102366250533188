import React, { useState } from 'react'
import { FormContainer } from '../../../components/Forms/Form/Form'
import styles from './AuthFormWithPasswordValidation.module.scss'
import { getPasswordValidations } from '../functions'
import { blacklistedWords } from '../data'
import { AuthValidateValues, FormValidationErrors } from '../types'

type Field = {
  name: string
  type: string
  textType?: string
  label: string
}

type AuthFormWithPasswordValidationProps = {
  fields: Field[]
  validate?: (values: AuthValidateValues) => FormValidationErrors
  handleSubmit: (values: AuthValidateValues) => void
}

export const AuthFormWithPasswordValidation = ({
  fields,
  validate,
  handleSubmit
}: AuthFormWithPasswordValidationProps) => {
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [passwordMismatch, setPasswordMismatch] = useState(true)
  const [passwordTooShort, setPasswordTooShort] = useState(true)
  const [blacklistedWord, setBlacklistedWord] = useState(false)
  const [validations, setValidations] = useState({
    length: false,
    match: false,
    uppercase: false,
    number: false
  })

  const handleValidation = (values) => {
    const errors = validate(values)

    setInvalidPassword(!!errors.passwordInvalid)
    setPasswordMismatch(!!errors.passwordMismatch)
    setPasswordTooShort(!!errors.passwordTooShort)
    setBlacklistedWord(!!errors.blacklistedWord)

    setValidations(
      getPasswordValidations(values.password, values.password_confirmation)
    )

    return errors
  }

  return (
    <FormContainer
      fields={fields}
      validate={handleValidation}
      onSubmit={handleSubmit}
      submitText={'Confirm'}
      disableSubmit={
        invalidPassword ||
        passwordMismatch ||
        passwordTooShort ||
        blacklistedWord
      }
    >
      <div className='row'>
        <p
          className={`${
            invalidPassword || passwordTooShort || blacklistedWord
              ? styles.highlightError
              : ''
          } small-text`}
        >
          Passwords must not include blacklisted words:{' '}
          {blacklistedWords.join(', ')}.
        </p>
      </div>
      <div className={styles.validations}>
        <ul>
          <li>{validations.length ? '✅' : '❌'} 12 characters</li>
          <li>{validations.match ? '✅' : '❌'} Passwords match</li>
          <li>
            {validations.uppercase ? '✅' : '❌'} At least one uppercase letter
          </li>
          <li>{validations.number ? '✅' : '❌'} At least one number</li>
        </ul>
      </div>
    </FormContainer>
  )
}
