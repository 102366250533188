import React, { useMemo, useState } from 'react'
import { Table } from '../../../../components/Table/Table'
import styles from './DrillsMetrics.module.scss'
import { useSelectedFormattedSession } from '../../../../metrics_server/sessions/hooks'

import {
  usePlayerBreakdown,
  useSelectedFormattedDrill
} from '../../../../metrics_server/drills/hooks'

import { MetricTypeKeys } from '../../../../metrics_server/metrics/data_types'
import {
  addPlayerToDrill,
  removePlayerFromDrill,
  setPlayerBibInDrillThunk
} from '../../../../metrics_server/drills/thunks'
import { useAppDispatch } from '../../../../store/hooks'
import { TableHeader } from '../../../../components/Table/Table.types'
import { usePlayerSelectModal } from '../../../../views/Session/modals/player_select'
import { useMetricColumnSelectModal } from '../../../../views/Session/modals/column_select'
import { generateMetricTypeClass } from '../../../metrics/data_types'
import { useUnitsSystem } from '../../../units/hooks'

export const DrillsMetrics = (props) => {
  const dispatch = useAppDispatch()
  // Session //
  const formattedSession = useSelectedFormattedSession()
  const { playerSummaryMetricTypes } = formattedSession

  // Drill //
  const formattedDrill = useSelectedFormattedDrill()

  // Units //
  const unitSystem = useUnitsSystem(formattedSession.sport)

  // Player Breakdown Data //
  const formattedPlayerBreakdowns = usePlayerBreakdown()

  // TODO: add player selection back in //
  const [selectedPlayerIds, setSelectedPlayerIds] = useState(
    Object.keys(formattedPlayerBreakdowns.players.list).slice(0, 2)
  )

  const [selectedPlayerMetricColumns, setSelectedPlayerMetricColumns] =
    useState<MetricTypeKeys[]>(playerSummaryMetricTypes.keys)

  // Modals //
  const { openMetricColumnSelectModal } = useMetricColumnSelectModal(
    playerSummaryMetricTypes,
    selectedPlayerMetricColumns,
    (columns) => {
      setSelectedPlayerMetricColumns(columns)
    }
  )
  const { openPlayerSelectModal } = usePlayerSelectModal(
    formattedSession.players.all,
    selectedPlayerIds,
    setSelectedPlayerIds
  )

  // Table Setup //

  const controls = useMemo(() => {
    if (!formattedDrill) return []
    if (formattedDrill.isPending) return []
    return [
      {
        name: 'Add/Remove Metrics',
        callback: () => openMetricColumnSelectModal(0)
      }
      // {
      //   name: 'Select Players',
      //   callback: () => openPlayerSelectModal()
      // }
    ]
  }, [formattedDrill, openMetricColumnSelectModal])

  const tableHeaders = useMemo(() => {
    if (!formattedDrill) return []

    const headers: TableHeader[] = [
      { name: 'Player', key: 'playerName', width: 15, type: 'text' }
    ]

    // Dynamically construct the Bib column based on finishedOrLiveDrill
    const bibColumn: TableHeader = {
      name: 'Bib',
      key: 'bib',
      width: 10,
      type: 'text'
    }

    if (formattedDrill.isPending) {
      headers.unshift({
        name: '',
        key: 'isInDrill',
        width: 1,
        input: {
          type: 'checkbox',
          onChange: (item, bool) => {
            if (bool) {
              dispatch(
                addPlayerToDrill({
                  drillId: formattedDrill.id,
                  playerId: item.playerId,
                  bibNumber: 1
                })
              )
            } else {
              dispatch(
                removePlayerFromDrill({
                  drillId: formattedDrill.id,
                  playerId: item.playerId,
                  bibNumber: 1
                })
              )
            }
          }
        }
      })
      bibColumn.width = 15
      bibColumn.input = {
        onChange: (item, value) => {
          dispatch(
            setPlayerBibInDrillThunk({
              drillId: formattedDrill.id,
              playerId: item.playerId,
              bibNumber: parseInt(value)
            })
          )
        },
        type: 'toggle',
        selectOptions: formattedDrill.bibs.options
      }
      headers.push(bibColumn)
    } else {
      // Add the rest of the columns to the array, including the dynamically constructed Bib column
      headers.push(
        bibColumn, // Add the bibColumn, which may or may not have the input property
        ...selectedPlayerMetricColumns.map((metricKey) => {
          const metricClass = generateMetricTypeClass(
            playerSummaryMetricTypes.items[metricKey]
          )
          return {
            name: metricClass.getMetricDisplayWithUnits(unitSystem, 1),
            key: metricKey,
            width: 10,
            type: 'text'
          }
        })
      )
    }

    return headers
  }, [
    unitSystem,
    selectedPlayerMetricColumns,
    playerSummaryMetricTypes,
    formattedDrill,
    dispatch
  ])

  const tableData = useMemo(() => {
    if (!formattedDrill) return []
    // Generate player rows //
    const playerRowData = formattedSession.players.all.list
      .filter((player) => {
        const drillPlayer = formattedDrill.drillPlayers.map[player.id]
        if (!drillPlayer && formattedDrill.isStarted) return false
        return true
      })
      .map((player) => {
        const drillPlayer = formattedDrill.drillPlayers.map[player.id]
        const playerBreakdown = formattedPlayerBreakdowns.players.map[player.id]

        const row = {
          playerName: player.nameAndNumber,
          playerId: player.id,
          id: player.id,
          isInDrill: !!drillPlayer,
          bib: null
        }

        if (!drillPlayer) return row

        if (formattedDrill.isPending) {
          row.bib = drillPlayer.bib ? drillPlayer.bib.id : null
        } else {
          row.bib = drillPlayer.bib.name
        }

        if (!playerBreakdown) return row

        for (const key in playerBreakdown.metrics) {
          const metricKey = key as MetricTypeKeys
          row[metricKey] =
            playerBreakdown.metrics[metricKey].formattedValue || '-'
        }

        return row
      })

    const totalBreakdown = formattedPlayerBreakdowns.totals

    // Generate total row if it exists //
    if (totalBreakdown && !formattedDrill.isPending) {
      const totalMetricValues = {}
      for (const key in totalBreakdown.metrics) {
        const metricKey = key as MetricTypeKeys
        totalMetricValues[metricKey] =
          totalBreakdown.metrics[metricKey].formattedValue || '-'
      }
      const totalRowData = {
        playerName: 'Total',
        __backgroundColor: 'rgba(0,0,0,0.1)',
        __disabled: {
          isInDrill: true,
          bib: true
        },
        __bottom: true,
        __hidden: {
          isInDrill: true,
          bib: true
        },
        ...totalMetricValues
      }
      return [...playerRowData, totalRowData]
    } else {
      return playerRowData
    }
  }, [formattedPlayerBreakdowns, formattedDrill, formattedSession])

  // ============================ //

  if (!playerSummaryMetricTypes) {
    return null
  }

  return (
    <div className={styles.tableContainer}>
      <div className={styles.metricsTable}>
        <div className='card-table-container'>
          <Table
            controls={controls}
            options={{
              initialOrder: 'asc',
              initialSortBy: 'playerName',
              sortActive: true
            }}
            headerFont={13}
            smallHead={true}
            tableClass={'minimalistBlack'}
            className={'container'}
            data={tableData}
            headers={tableHeaders}
            id={'pass-summary-table'}
          />
        </div>
      </div>
    </div>
  )
}
