import React from 'react'
import Profile from './Profile/Profile'
import { Organisation } from './Organisation/Organisation'
import { Network } from './Network/Network'

import { useAppSelector } from '../../store/hooks'
import * as userActions from '../../metrics_server/user/actions'
import { setForgotPassword } from '../../ui/authentication/actions'
import { useAppDispatch } from '../../store/hooks'

const actions = { ...userActions }

export const useSettingsProps = (props) => {
  const {
    appConfig,
    toggleModal,
    updateUser,
    forceSync,
    getActiveSession,
    setupNewSession
  } = props

  const dispatch = useAppDispatch()

  const signoutUser = () => dispatch(actions.signoutUser())

  const [pitches, sessions, user, router, organisations, version, teams] =
    useAppSelector((state) => [
      state.pitches,
      state.sessions,
      state.user,
      state.router,
      state.organisations,
      state.version,
      state.teams
    ])

  const redirectToChangePassword = () => {
    dispatch(setForgotPassword(true))
    signoutUser()
  }

  let views = [
    {
      id: 'profile',
      Content: Profile,
      title: 'Profile',

      reqPermissionLevel: 0
    },
    {
      id: 'organisation',
      Content: Organisation,
      title: 'Organisation',
      reqPermissionLevel: 0
    },
    {
      id: 'network',
      Content: Network,
      title: 'Network',
      reqPermissionLevel: 0
    }
    // {
    //   id: 'support',
    //   Component: Support,
    //   title: 'Support',
    //   reqPermissionLevel: 0,
    //   props: { webAppDomain: version.webAppDomain }
    // },
    // {id: 'usage', Component: Usage, title: 'Hardware Usage', reqPermissionLevel: 0},
    // {id: 'privacy', Component: Privacy, title: 'Privacy', reqPermissionLevel: 0},
    // { id: 'faq', Component: Faq, title: 'FAQ', reqPermissionLevel: 0 }
  ]

  // Filter Settings by Permission level
  views = views.filter((x) => {
    if (user.data.permissionLevel >= x.reqPermissionLevel) {
      return true
    }
    return false
  })

  return {
    views,

    pitches,
    sessions,
    user,
    router,
    organisations,
    version,
    teams,

    toggleModal,
    signoutUser,
    updateUser,
    forceSync,
    setForgotPassword,
    getActiveSession,
    setupNewSession,

    redirectToChangePassword
  }
}
