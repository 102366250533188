import { FormattedSession, SessionConfig } from '../../sessions/types'
import { RawTimeEventData, TimeEventData, TimeEventFilters } from './types'
import { TimeEventTypeGroup, timeEventTypes } from './data_types'
import { eventTypes } from '../data_types'
import { FormattedEventData } from '../types'

export const getCurrentPeriod = (
  timeEvents: TimeEventData[],
  timeEventTypes: TimeEventTypeGroup
): TimeEventData => {
  let currentPeriod: TimeEventData

  timeEvents.forEach((timeEvent) => {
    if (eventTypes.isType('time', timeEvent.event?.type)) {
      const timeEventType = timeEventTypes.getTypeByValue(timeEvent.type)
      if (!currentPeriod && timeEventType.props.features.period)
        currentPeriod = timeEvent
      if (
        timeEvent.type > currentPeriod?.type &&
        timeEventType.props.features.period
      )
        currentPeriod = timeEvent
    }
  })
  return currentPeriod
}

export const isInPlay = (
  timeEvents: TimeEventData[],
  timeEventTypes: TimeEventTypeGroup
): boolean => {
  const currentPeriod = getCurrentPeriod(timeEvents, timeEventTypes)
  return currentPeriod && !currentPeriod.timeEnd
}

export const getStoppages = (timeEvents: TimeEventData[]): TimeEventData[] => {
  return timeEvents.filter((timeEvent) =>
    timeEventTypes.isType('stoppage', timeEvent.type)
  )
}

export const isPlayStopped = (timeEvents: TimeEventData[]): boolean => {
  const stoppages = getStoppages(timeEvents)
  return stoppages.some((x) => !x.timeEnd)
}

export const generateCodingTableTimeEventRowData = (
  timeEvent: TimeEventData,
  timeEvents: any[],
  sessionConfig: SessionConfig
) => {
  const rows = []

  const { live, timeEventTypes } = sessionConfig

  const timeEventType = timeEventTypes.getTypeByValue(timeEvent.type)

  const currentPeriod = getCurrentPeriod(timeEvents, timeEventTypes)

  const timeEventName = {
    start: `${timeEventType.name} ${timeEventType.props.features.startName}`,
    end: `${timeEventType.name} ${timeEventType.props.features.endName}`
  }

  const startTime = {
    typeName: timeEventName.start,
    type: timeEvent.type,
    id: `${timeEvent.id}-start`,
    eventId: timeEvent.eventId,
    start: true,
    half: timeEvent.type,
    __hidden: {
      deleteTimeEvent: currentPeriod?.eventId !== timeEvent.eventId || !live
    },
    deleteTimeEvent: 'bin',
    startTime: timeEvent.startTime * 1000
  }
  rows.push(startTime)

  if (timeEvent.timeEnd) {
    startTime.__hidden.deleteTimeEvent = true
    const timeEnd = {
      typeName: timeEventName.end,
      type: timeEvent.type,
      id: `${timeEvent.id}-end`,
      eventId: timeEvent.eventId,
      half: timeEvent.type,
      __hidden: {
        deleteTimeEvent: currentPeriod?.eventId !== timeEvent.eventId || !live
      },
      deleteTimeEvent: 'bin',
      startTime: timeEvent.timeEnd * 1000
    }
    rows.push(timeEnd)
  }

  return rows
}

export const filterTimeEvents = (
  timeEvents: any[],
  { stoppages }: TimeEventFilters,
  formattedSession: FormattedSession
) => {
  const { timeEventTypes } = formattedSession

  const filteredTimeEvents = []
  timeEvents.forEach((timeEvent) => {
    // TODO: Hacky way to filter out none time events
    if (eventTypes.isType('time', timeEvent.event?.type)) {
      const timeEventType = timeEventTypes.getTypeByValue(timeEvent.type)
      if (
        stoppages.value &&
        timeEventTypes.isType('stoppage', timeEventType.value)
      )
        return
      filteredTimeEvents.push(timeEvent)
    }
  })
  return filteredTimeEvents
}

export const generateFormattedTimeData = (
  eventType: typeof eventTypes.items.time,
  timeEvent: RawTimeEventData,
  formattedSession: FormattedSession
): FormattedEventData => {
  const { timeEventTypes, startTime } = formattedSession
  const timeEventType = timeEventTypes.getTypeByValue(timeEvent.type)
  const timeEventName = {
    start: `${timeEventType.name} ${timeEventType.props.features.startName}`,
    end: `${timeEventType.name} ${timeEventType.props.features.endName}`
  }
  let types = {
    typeName: timeEventType.name,
    type: timeEvent.type
  }
  if (timeEvent.timeEnd) {
    types = {
      typeName: timeEventName.end,
      type: timeEvent.type
    }
  }
  const sessionStartTime =
    (timeEvent.event.startTime - startTime.unixSeconds) * 1000
  const formattedTimeEvent = {
    id: timeEvent.id,
    sessionStartTime: sessionStartTime,
    rawData: timeEvent,
    ignore: false,
    eventType: eventType.value,
    typeName: timeEventType.name,
    type: {
      selected: timeEventType,
      options: null
    },
    subType: null,
    sessionId: timeEvent.event.sessionId,
    startTime: timeEvent.event.startTime,
    endTime: timeEvent.event.endTime,
    types: types,
    team: null,
    player: null,
    metrics: {},

    compareTagPosition: null,

    features: timeEventType.props.features
  }
  return formattedTimeEvent
}
