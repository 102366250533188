import React from 'react'
import styles from './NotificationsListItem.module.scss'

import downloadIcon from '../../../../assets/img/download-inverse.svg'
import batteryLow from '../../../../assets/img/tag_low_batt.svg'
import { useNotificationsListItemLogic } from './NotificationsListItem.logic'

import {
  checkLowBattLevelAndReturnCorrectText,
  renderDeviceIcon
} from '../../../../utils/helpers'
import { NotificationType } from '../../../../metrics_server/notifications/types'
import { getSport } from '../../../../metrics_server/sports/functions'
import { sportableColors } from '../../../../const'
import { useSessions } from '../../../../metrics_server/sessions/hooks'

export interface NotificationsListItemProps {
  notification: NotificationType
  handleClearNotification: (notification: NotificationType) => void
}

export const NotificationsListItem = ({
  notification,
  handleClearNotification
}: NotificationsListItemProps) => {
  const {
    isLowBattery,
    deviceType,
    timestamp,
    title,
    description,
    convertedTime,
    isDownloadNotification,
    text,
    downloadNotificationTimestamp
  } = useNotificationsListItemLogic(notification)

  const icon = downloadIcon

  const sessions = useSessions()

  // download notification
  if (isDownloadNotification) {
    return (
      <div className={styles.outerContainer}>
        <div className={styles.diagsNotificationContainer}>
          <div
            style={{
              backgroundImage: `url(${icon})`
            }}
            className={styles.icon}
          />

          <div className={styles.infoContainer}>
            <div className={styles.timestampAndTitle}>
              <h5
                style={{
                  color: '#323233',
                  paddingLeft: '10px'
                }}
              >
                {text}{' '}
              </h5>
              <p style={{ fontStyle: 'italic' }}>
                {downloadNotificationTimestamp}
              </p>
            </div>
          </div>
        </div>
        <p
          id='notificationClose'
          style={{
            cursor: 'pointer',
            color: 'gray'
          }}
          onClick={() => handleClearNotification(notification)}
        >
          x
        </p>
      </div>
    )
  }

  if (!deviceType || !timestamp) {
    return null
  }

  const NotificationContent = ({
    deviceType,
    sport,
    convertedTime,
    title,
    description,
    isLowBattery,
    handleClearNotification,
    notification
  }) => {
    return (
      <div className={styles.outerContainer}>
        <div className={styles.diagsNotificationContainer}>
          <div className={styles.deviceIcon}>
            {renderDeviceIcon(deviceType, sport)}
          </div>
          <div className={styles.separator} />
          <div className={styles.infoContainer}>
            <div className={styles.timestampAndTitle}>
              <h5 style={{ color: 'gray' }}>{convertedTime}</h5>
              <h5
                style={{
                  color: '#323233',
                  paddingLeft: '10px'
                }}
              >
                {title}
              </h5>
            </div>
            {description && (
              <div className={styles.description}>
                {isLowBattery && (
                  <img
                    src={batteryLow}
                    alt='batt'
                    className={styles.batteryImage}
                    style={{ paddingRight: '10px' }}
                  />
                )}
                <h5 style={{ color: sportableColors.colors.colorError }}>
                  {isLowBattery
                    ? checkLowBattLevelAndReturnCorrectText(description)
                    : description}
                </h5>
              </div>
            )}
          </div>
        </div>
        <p
          id='notificationClose'
          style={{
            cursor: 'pointer',
            color: 'gray'
          }}
          onClick={() => handleClearNotification(notification)}
        >
          x
        </p>
      </div>
    )
  }

  let sport

  if (sessions.activeSession?.sportType) {
    sport = getSport(sessions.activeSession.sportType)
    return (
      <NotificationContent
        {...{
          deviceType,
          sport,
          convertedTime,
          title,
          description,
          isLowBattery,
          handleClearNotification,
          notification
        }}
      />
    )
  }

  return (
    <NotificationContent
      {...{
        deviceType,
        sport,
        convertedTime,
        title,
        description,
        isLowBattery,
        handleClearNotification,
        notification
      }}
    />
  )

  return null
}
