import pitchTexture from '../../../assets/img/pitch-texture.png'
import { calculateOffsetAndScale } from '../field'

import { drawBoxingLines } from '../sports/boxing'
import { drawRugbyLines } from '../sports/rugby'
import { drawPitchNumbers, drawRugbyLeagueLines } from '../sports/rugby_league'
import {
  drawAmericanFootballPitchNumbers,
  drawAmericanFootballLines
} from '../sports/american_football'
import {
  drawCanadianFootballPitchNumbers,
  drawCanadianFootballLines
} from '../sports/canadian_football'
import { createAustralianRulesGround } from '../sports/australian_rules'

import * as BABYLON from '@babylonjs/core'
import nflTxImg from '../../../assets/img/nfl-texture.png'
import { sportableColors } from '../../../const'
import { getRgbValues } from '../../helpers'
import { drawDemonstrationLines } from '../sports/demonstration'
import { drawAustralianRulesLines } from '../sports/australian_rules'
import {
  SportTypeValues,
  sportTypes
} from '../../../metrics_server/sports/data_types'
import { drawSoccerLines } from '../sports/soccer'

export function load3D(strack, endLoading) {
  let { field, canvasId3D, view, pitchType, fieldWidth, fieldHeight } = strack
  // Calculate 3D ground dimensions

  const sport = sportTypes.getTypeByValue(pitchType as SportTypeValues)
  strack.textureCanvasWidth = fieldWidth * sport.props.pitch.textureCanvasScale
  strack.textureCanvasHeight =
    fieldHeight * sport.props.pitch.textureCanvasScale
  let minOffset = sport.props.pitch.minPadding3D

  let os = calculateOffsetAndScale(
    field,
    strack.textureCanvasWidth,
    strack.textureCanvasHeight,
    fieldWidth,
    fieldHeight,
    false,
    minOffset
  )
  strack.threeDOffsetX = os.offsetX
  strack.threeDOffsetY = os.offsetY
  strack.threeDScale = os.scale
  strack.threeDMeshScale =
    sport.props.pitch.textureCanvasScale / strack.threeDScale

  strack.canvas3DPixelScale = strack.threeDScale

  // Get 3D canvas
  strack.canvas = document.getElementById(canvasId3D)

  const { canvas, canvasHeight, canvasWidth } = strack

  // Set 3D Canvas width and height based on canvas container width
  canvas.height = parseInt(canvasHeight, 10)
  canvas.width = canvasWidth

  // Initiate Babylon JS engine
  strack.engine = new BABYLON.Engine(canvas, true, null, false)

  // Add listeners to pitch
  window.addEventListener('dblclick', (e) => {
    if (strack.scene) {
      const pickResult = strack.scene.pick(
        strack.scene.pointerX,
        strack.scene.pointerY
      )
      if (pickResult.hit) {
        if (pickResult.pickedMesh.id === 'ground') {
          strack.changeCameraTarget(pickResult.pickedPoint)
        }
      }
    }
  })

  //--> Create scene and load models
  createScene(strack)

  //--> Animation RenderLoop

  endLoading()

  strack.engine.runRenderLoop(() => {
    if (view === '3D') {
      strack.runBufferPos(strack.setPos.bind(strack), null)
    } else {
      strack.scene.render()
    }
  })

  // ---------> Rugby Ball
  // BABYLON.SceneLoader.ImportMesh("", "/static/scenes/", rugbyBallMesh.split("/")[3], scene, function (newMeshes) {
  //     // Set the target of the camera to the first imported mesh
  //     console.log(newMeshes)
  // });
}

// SCENE

const createScene = (strack) => {
  let { field, engine, canvas, pitchType, dimensions } = strack

  const sport = sportTypes.getTypeByValue(pitchType as SportTypeValues)

  // Initialise BABYLON scene
  strack.scene = new BABYLON.Scene(engine)

  const { scene } = strack

  generateLight(strack)

  // Set Transparent Background
  scene.clearColor = new BABYLON.Color4(0, 0, 0, 0)

  let cameraY = field.height * sport.props.pitch.cameraYScale

  // Create an Arc Rotate Camera
  strack.camera = new BABYLON.ArcRotateCamera(
    'Camera',
    -Math.PI / 2,
    1.0,
    strack.initCameraZoom,
    new BABYLON.Vector3(0, 0, cameraY),
    scene
  )

  const { camera } = strack

  camera.attachControl(canvas, true, false)
  camera.inputs.attached['keyboard'].detachControl()
  camera.upperBetaLimit = 1.5708

  createMaterials(strack)

  drawPitch(strack, null)

  createPlayers(strack)
  createBalls(strack)

  generateShadows(strack)

  //--> Create Axis
  // showAxis(strack)

  // New sport needs to be added here
  switch (pitchType) {
    case sportTypes.items.demonstration.value:
      createGround(strack)
      break
    case sportTypes.items.rugbyUnion.value:
      createGround(strack)
      // Check if there are more than 34 points on dimensions, i.e. if the old coordinate system is being used
      let oldCoords = !!dimensions.P35
      if (oldCoords) {
        renderRugbyPoles(
          dimensions.P31,
          dimensions.P33,
          dimensions.P35,
          dimensions.P37,
          scene
        )
      } else {
        renderRugbyPoles(
          dimensions.P31,
          dimensions.P32,
          dimensions.P33,
          dimensions.P34,
          scene
        )
      }
      break
    case sportTypes.items.rugbyLeague.value:
      createGround(strack)
      renderRugbyPoles(
        dimensions.P35,
        dimensions.P36,
        dimensions.P37,
        dimensions.P38,
        scene
      )
      break
    case sportTypes.items.americanFootball.value:
      createGround(strack)
      renderFootballGoals(
        [dimensions.P28, dimensions.P27, dimensions.P29, dimensions.P30],
        scene
      )
      break
    case sportTypes.items.canadianFootball.value:
      createGround(strack)
      renderFootballGoals(
        [dimensions.P32, dimensions.P31, dimensions.P33, dimensions.P34],
        scene
      )
      break
    case sportTypes.items.australianRules.value:
      createAustralianRulesGround(strack)
      renderAustralianRulesGoals(strack)
      break
    case sportTypes.items.soccer.value:
      renderSoccerGoals(
        dimensions.P16,
        dimensions.P15,
        dimensions.P33,
        dimensions.P34,
        scene
      )
      createGround(strack)
      break
    default:
      break
  }

  scene.autoClear = false // Color buffer
  scene.autoClearDepthAndStencil = false // Depth and stencil, obviously
}

//
const clear3DPitch = (ctx, strack) => {
  const {
    fieldWidth,
    fieldHeight,
    threeDOffsetX,
    threeDOffsetY,
    canvas3DPixelScale
  } = strack
  ctx.clearRect(
    0,
    0,
    (fieldWidth + 2 * threeDOffsetX) * canvas3DPixelScale,
    (fieldHeight + 2 * threeDOffsetY) * canvas3DPixelScale
  )
}

// ---> Draw pitch
const drawPitch = (strack, callback) => {
  const {
    pitchType,
    materials,
    canvas3DPixelScale,
    textureCanvasWidth,
    textureCanvasHeight
  } = strack

  const ctx = materials.groundDynamicTexture.getContext()

  let backgroundImg = new Image()

  // testing code
  const root = document.getElementById('root')

  // New sport needs to be added here
  switch (pitchType) {
    case sportTypes.items.demonstration.value:
      drawDemonstrationLines(
        strack,
        'rgba(255,255,255,0.9)',
        ctx,
        canvas3DPixelScale,
        '3D'
      )
      break
    case sportTypes.items.rugbyUnion.value:
      backgroundImg.src = pitchTexture
      backgroundImg.onload = function () {
        ctx.drawImage(backgroundImg, 0, 0)
        if (materials) {
          materials.groundDynamicTexture.update()
          if (callback) callback()
          drawRugbyLines(
            strack,
            'rgba(255,255,255,0.9)',
            ctx,
            canvas3DPixelScale,
            '3D'
          )
          materials.groundDynamicTexture.update()
        }
      }
      break
    case sportTypes.items.rugbyLeague.value:
      backgroundImg.src = pitchTexture
      backgroundImg.onload = function () {
        ctx.drawImage(backgroundImg, 0, 0)
        if (materials) {
          materials.groundDynamicTexture.update()
          if (callback) callback()
          drawRugbyLeagueLines(
            strack,
            'rgba(255,255,255,0.9)',
            ctx,
            canvas3DPixelScale,
            '3D'
          )
          drawPitchNumbers(strack, ctx)
          materials.groundDynamicTexture.update()
        }
      }
      break
    case sportTypes.items.americanFootball.value:
      backgroundImg.src = pitchTexture
      backgroundImg.onload = function () {
        ctx.drawImage(backgroundImg, 0, 0)
        if (materials) {
          materials.groundDynamicTexture.update()
          if (callback) callback()
          drawAmericanFootballLines(
            strack,
            'rgba(255,255,255,1)',
            3,
            ctx,
            canvas3DPixelScale,
            '3D'
          )
          const { field, dimensions, get3DCanvasCoordinate } = strack
          drawAmericanFootballPitchNumbers(
            field,
            dimensions,
            get3DCanvasCoordinate,
            canvas3DPixelScale,
            ctx,
            canvas3DPixelScale * 1.7
          )
          materials.groundDynamicTexture.update()
        }
      }
      break
    // case sportTypes.items.boxing?.value:
    //   drawBoxingLines(strack, 'rgba(255,255,255,0.9)')
    //   break
    case sportTypes.items.australianRules.value:
      backgroundImg.src = pitchTexture
      backgroundImg.onload = function () {
        const centerX = (textureCanvasWidth - backgroundImg.width) / 2
        const centerY = (textureCanvasHeight - backgroundImg.height) / 2
        if (materials) {
          ctx.drawImage(backgroundImg, centerX, centerY)
          materials.groundDynamicTexture.update()
          if (callback) callback()
          drawAustralianRulesLines(
            strack,
            'rgba(255,255,255,0.9)',
            ctx,
            canvas3DPixelScale,
            {
              width: textureCanvasWidth,
              height: textureCanvasHeight
            },
            '3D'
          )
          materials.groundDynamicTexture.update()
        }
      }
      break
    case sportTypes.items.canadianFootball.value:
      backgroundImg.src = pitchTexture
      backgroundImg.onload = function () {
        ctx.drawImage(backgroundImg, 0, 0)
        if (materials) {
          materials.groundDynamicTexture.update()
          if (callback) callback()
          drawCanadianFootballLines(
            strack,
            'rgba(255,255,255,1)',
            3,
            ctx,
            canvas3DPixelScale,
            '3D'
          )
          const { field, dimensions, get3DCanvasCoordinate } = strack
          drawCanadianFootballPitchNumbers(
            field,
            dimensions,
            get3DCanvasCoordinate,
            canvas3DPixelScale,
            ctx,
            canvas3DPixelScale * 1.7
          )
          materials.groundDynamicTexture.update()
        }
      }
      break
    case sportTypes.items.soccer.value:
      backgroundImg.src = pitchTexture
      backgroundImg.onload = function () {
        ctx.drawImage(backgroundImg, 0, 0)
        if (materials) {
          materials.groundDynamicTexture.update()
          if (callback) callback()
          drawSoccerLines(
            strack,
            'rgba(255,255,255,1)',
            ctx,
            canvas3DPixelScale,
            '3D'
          )
          materials.groundDynamicTexture.update()
        }
      }
      break
    default:
      break
  }
}

// Draw teams on 3D canvas
export const drawTryZones = (strack, teams, highlightedSide) => {
  const {
    get3DCanvasCoordinate,
    canvas3DPixelScale,
    dimensions,
    materials,
    pitchType
  } = strack

  if (!materials) return

  const ctx = materials.groundDynamicTexture.getContext()

  clear3DPitch(ctx, strack)

  drawPitch(strack, () => {
    let leftTryZoneOriginNumber,
      leftTryZoneDestNumber,
      rightTryZoneOriginNumber,
      rightTryZoneDestNumber

    // New sport needs to be added here
    switch (pitchType) {
      case sportTypes.items.rugbyUnion.value:
        leftTryZoneOriginNumber = 'P26'
        leftTryZoneDestNumber = 'P2'
        rightTryZoneOriginNumber = 'P17'
        rightTryZoneDestNumber = 'P11'
        break
      case sportTypes.items.rugbyLeague.value:
        leftTryZoneOriginNumber = 'P30'
        leftTryZoneDestNumber = 'P2'
        rightTryZoneOriginNumber = 'P19'
        rightTryZoneDestNumber = 'P13'
        break
      case sportTypes.items.americanFootball.value:
        leftTryZoneOriginNumber = 'P26'
        leftTryZoneDestNumber = 'P2'
        rightTryZoneOriginNumber = 'P15'
        rightTryZoneDestNumber = 'P13'
        break
      case sportTypes.items.canadianFootball.value:
        leftTryZoneOriginNumber = 'P30'
        leftTryZoneDestNumber = 'P2'
        rightTryZoneOriginNumber = 'P17'
        rightTryZoneDestNumber = 'P15'
        break
      case sportTypes.items.soccer.value:
        leftTryZoneOriginNumber = 'P12'
        leftTryZoneDestNumber = 'P17'
        rightTryZoneOriginNumber = 'P31'
        rightTryZoneDestNumber = 'P5'
        break
      default:
        return
    }
    // Draw left background

    const leftTryZoneOrigin = {
      x: dimensions[leftTryZoneOriginNumber].x,
      y: dimensions[leftTryZoneOriginNumber].y
    }

    const leftTryZoneDest = {
      x: dimensions[leftTryZoneDestNumber].x,
      y: dimensions[leftTryZoneDestNumber].y
    }

    const leftTryZoneOriginCanvasCoords = get3DCanvasCoordinate(
      canvas3DPixelScale,
      leftTryZoneOrigin.x,
      leftTryZoneOrigin.y
    )

    ctx.globalAlpha = 0.3

    if (highlightedSide == null) {
      ctx.fillStyle = teams[0].color
    } else {
      ctx.fillStyle =
        highlightedSide === 0 ? sportableColors.colors.blue : 'black'
    }

    ctx.fillRect(
      leftTryZoneOriginCanvasCoords.scaleX,
      leftTryZoneOriginCanvasCoords.scaleY,
      (leftTryZoneDest.x - leftTryZoneOrigin.x) * canvas3DPixelScale,
      (leftTryZoneOrigin.y - leftTryZoneDest.y) * canvas3DPixelScale
    )

    // const leftImg = new Image()
    // leftImg.onload = () => {
    //   console.log(teams[0].logo)
    //   ctx.font = '30px MarkPro'
    //   ctx.fillText('Hello World', 10, 50)
    //   ctx.drawImage(
    //     leftImg,
    //     get3DCanvasCoordinate(
    //       canvas3DPixelScale,
    //       leftTryZoneOriginCanvasCoords.scaleX,
    //       leftTryZoneOriginCanvasCoords.scaleY,
    //       40,
    //       40
    //     )
    //   )
    //   materials.groundDynamicTexture.update()
    // }
    // leftImg.src = teams[0].logo

    // Draw right background
    const rightTryZoneOrigin = {
      x: dimensions[rightTryZoneOriginNumber].x,
      y: dimensions[rightTryZoneOriginNumber].y
    }

    const rightTryZoneDest = {
      x: dimensions[rightTryZoneDestNumber].x,
      y: dimensions[rightTryZoneDestNumber].y
    }

    const rightTryZoneOriginCanvasCoords = get3DCanvasCoordinate(
      canvas3DPixelScale,
      rightTryZoneOrigin.x,
      rightTryZoneOrigin.y
    )

    if (highlightedSide == null) {
      ctx.fillStyle = teams[1]?.color
    } else {
      ctx.fillStyle =
        highlightedSide === 1 ? sportableColors.colors.blue : 'black'
    }

    ctx.fillRect(
      rightTryZoneOriginCanvasCoords.scaleX,
      rightTryZoneOriginCanvasCoords.scaleY,
      (rightTryZoneDest.x - rightTryZoneOrigin.x) * canvas3DPixelScale,
      (rightTryZoneOrigin.y - rightTryZoneDest.y) * canvas3DPixelScale
    )

    // var rightImg = new Image()
    // rightImg.onload = () => {
    //   coverCtx.drawImage(rightImg, getCanvasCoordinate(canvas2DPixelScale, fieldWidth / 2 + field.l1 / 2).scaleX - logoWidth / 2, getCanvasCoordinate(canvas2DPixelScale, null, fieldHeight / 2).scaleY - logoHeight / 2, logoWidth, logoHeight)
    // }
    // rightImg.src = rugbyXLogoRight

    ctx.globalAlpha = 1

    materials.groundDynamicTexture.update()
  })
}

// ----> Create Materials

const createMaterials = (strack) => {
  strack.materials = {}
  let { materials, scene, textureCanvasWidth, textureCanvasHeight } = strack

  // Ground Texture & Material
  materials.groundDynamicTexture = new BABYLON.DynamicTexture(
    'groundTexture',
    { width: textureCanvasWidth, height: textureCanvasHeight },
    scene,
    false
  )
  materials.ground = new BABYLON.StandardMaterial('Mat', scene)
  materials.ground.diffuseTexture = materials.groundDynamicTexture

  // Colors

  materials.blue = new BABYLON.StandardMaterial('blueMaterial', scene)
  materials.blue.diffuseColor = new BABYLON.Color3(0.3, 0.3, 1)

  materials.red = new BABYLON.StandardMaterial('redMaterial', scene)
  materials.red.diffuseColor = new BABYLON.Color3(1, 0.3, 0.3)

  materials.yellow = new BABYLON.StandardMaterial('yellowMaterial', scene)
  materials.yellow.diffuseColor = new BABYLON.Color3(1, 1, 0.3)

  materials.black = new BABYLON.StandardMaterial('black', scene)
  materials.black.emissiveColor = new BABYLON.Color3(0, 0, 0)
  materials.black.diffuseColor = new BABYLON.Color3(0, 0, 0)
  materials.black.specularColor = new BABYLON.Color3(0, 0, 0)

  // Ball
  materials.ball = new BABYLON.StandardMaterial('texture1', scene)
  materials.ball.diffuseColor = new BABYLON.Color3(1, 1, 1)
  materials.ball.emissiveColor = new BABYLON.Color3(0.6, 0.6, 0)

  // Player

  //--> Team Materials
  materials.playerA = new BABYLON.StandardMaterial('texture1', scene)
  materials.playerA.diffuseColor = new BABYLON.Color3(1.0, 0.2, 0.7)

  materials.playerB = new BABYLON.StandardMaterial('texture1', scene)
  materials.playerB.diffuseColor = new BABYLON.Color3(0.2, 0.7, 1.0)

  // Flights
  setPlayerMaterials(strack)

  materials.default = new BABYLON.StandardMaterial('kicksMaterial', scene)
  materials.default.diffuseColor = new BABYLON.Color3(0, 0, 0)

  materials.success = new BABYLON.StandardMaterial('kicksMaterial', scene)
  materials.success.diffuseColor = new BABYLON.Color3(0, 1, 0)

  materials.selected = new BABYLON.StandardMaterial('selectedMaterial', scene)
  materials.selected.diffuseColor = new BABYLON.Color3(1, 1, 0)

  materials.outerMaterial = new BABYLON.StandardMaterial(
    'selectedMaterial',
    scene
  )
  materials.outerMaterial.alpha = 0
}

const setPlayerMaterials = (strack) => {
  const { session, materials, scene } = strack

  materials.players = {}

  if (session && session.playersSessions) {
    // Generate Material for each player
    session.playersSessions.byPlayerId.list.forEach((playerSession) => {
      if (playerSession.player.color) {
        materials.players[playerSession.playerId] =
          new BABYLON.StandardMaterial(playerSession.playerId, scene)
        const { r, g, b } = getRgbValues(playerSession.player.color)
        materials.players[playerSession.playerId].diffuseColor =
          new BABYLON.Color3(r / 255, g / 255, b / 255)
      }
    })
  }
}

// TODO
// materials in strack kickMaterials to flightMaterials and kickMaterial to default
// selectedMaterial to selected and successKickMaterial to success

// LIGHT

const generateLight = (strack) => {
  const { scene } = strack
  // Light
  strack.light1 = new BABYLON.HemisphericLight(
    'light1',
    new BABYLON.Vector3(0, 50, 0),
    scene
  )
  strack.light1.specular = new BABYLON.Color3(0, 0, 0)
  strack.light1.intensity = 1
}

//--> 3D Balls

const createBalls = (strack) => {
  strack.balls = {}

  let {
    sessionTags,
    field,
    materials,
    ballSphereDiameter,
    ballOutlineWidth,
    scene,
    balls
  } = strack
  const ballTags = sessionTags.filter((x) => x.playerId == 0)

  //--> Create + Position Balls

  // Create Meshes
  for (let i = 0; i < ballTags.length; i++) {
    const ballMesh = BABYLON.Mesh.CreateSphere(
      'ball' + i,
      10.0,
      ballSphereDiameter,
      scene
    )
    // ballMesh.customOutline = BABYLON.Mesh.CreateSphere(
    //   'outline' + i,
    //   10,
    //   ballSphereDiameter + ballOutlineWidth,
    //   scene,
    //   false,
    //   BABYLON.Mesh.BACKSIDE
    // )
    // ballMesh.customOutline.parent = ballMesh
    // ballMesh.customOutline.material = materials.black
    ballMesh.material = materials.ball
    let ballTag = ballTags[i]
    let ballObj = {
      mesh: ballMesh,
      number: 0,
      teamId: null,
      team: null,
      ball: true
    }
    ballObj.mesh.position.x = -(field.width / 2) + 10 * (i - 23)
    ballObj.mesh.position.z = -10

    balls[ballTag.tagId] = ballObj
  }
}

//--> 3D Players

const createPlayers = (strack) => {
  const {
    sessionTags,
    playerSphereDiameter,
    scene,
    materials,
    teams,
    players
  } = strack

  const playerTags = sessionTags.filter((x) => x.playerId !== 0)

  // TODO when working test making playerMesh local variable

  strack.playerMesh = {
    A: BABYLON.MeshBuilder.CreateSphere(
      'sphere',
      { diameter: playerSphereDiameter, segments: 32 },
      scene
    ),
    B: BABYLON.MeshBuilder.CreateSphere(
      'playerB',
      { diameter: playerSphereDiameter },
      scene
    )
  }

  strack.playerMesh.A.convertToUnIndexedMesh()
  strack.playerMesh.A.material = materials.playerA
  strack.playerMesh.A.setEnabled(false)

  strack.playerMesh.B.convertToUnIndexedMesh()
  strack.playerMesh.B.material = materials.playerB
  strack.playerMesh.B.setEnabled(false)

  strack.playerPlane = BABYLON.Mesh.CreatePlane(
    'playerPlane',
    1.2,
    scene,
    false
  )
  strack.playerPlane.convertToUnIndexedMesh()
  strack.playerPlane.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
  strack.playerPlane.setEnabled(false)

  for (var i = 0; i < playerTags.length; i++) {
    const playerTag = playerTags[i]

    if (!playerTag.player) playerTag.player = {}

    let team = 'A'
    if (playerTag.teamId == teams.B.id) {
      team = 'B'
    }

    let playerObj = {
      mesh: strack.playerMesh[team].createInstance(`player${team}${i + 1}`),
      plane: strack.playerPlane.clone(`playerPlane${team}${i + 1}`),
      planeTexture: new BABYLON.DynamicTexture(
        `playerPlaneTexture${team}${i + 1}`,
        512,
        scene,
        true
      ),
      number: playerTag.player.playerNumber,
      teamId: playerTag.teamId,
      playerId: playerTag.player.playerId,
      team,
      ball: false
    }

    playerObj.plane.position.x = 200
    playerObj.mesh.position.x = 200

    players[playerTag.tagId] = playerObj
  }

  //--> Position and add material to players
  const { playersXpos, playersSpacing } = strack

  for (let tagId in players) {
    let player = players[tagId]

    player.plane.material = new BABYLON.StandardMaterial(
      `playerPlane${player.team}-${tagId}`,
      scene
    )
    player.plane.material.diffuseTexture = player.planeTexture
    player.plane.material.specularColor = new BABYLON.Color3(0, 0, 0)
    player.plane.material.emissiveColor = new BABYLON.Color3(1, 1, 1)
    player.plane.material.backFaceCulling = false
    player.planeTexture.drawText(
      player.number,
      null,
      360,
      'bold 312px verdana',
      '#4E4E4E',
      '#FFFFFF'
    )
  }
}

//--> Shadows

const generateShadows = (strack) => {
  //--> Shadows
  // var shadowGenerator = new BABYLON.ShadowGenerator(1024, light1);
  // for (var tagId in players) {
  //   if (players.hasOwnProperty(tagId)) {
  //     shadowGenerator.getShadowMap().renderList.push(players[tagId].mesh);
  //   }
  // }
  // shadowGenerator.useBlurVarianceShadowMap = true;
}

//--> Axis

const showAxis = (strack) => {
  const size = 5

  const { scene } = strack

  let makeTextPlane = (text, color, size) => {
    var dynamicTexture = new BABYLON.DynamicTexture(
      'DynamicTexture',
      50,
      scene,
      true
    )
    dynamicTexture.hasAlpha = true
    dynamicTexture.drawText(
      text,
      5,
      40,
      'bold 48px Arial',
      color,
      'transparent',
      true
    )
    var plane = BABYLON.Mesh.CreatePlane('TextPlane', size, scene, true)
    plane.material = new BABYLON.StandardMaterial('TextPlaneMaterial', scene)
    plane.material.backFaceCulling = false
    // plane.material.specularColor = new BABYLON.Color3(0, 0, 0)
    // plane.material.diffuseTexture = dynamicTexture
    return plane
  }

  var axisX = BABYLON.Mesh.CreateLines(
    'axisX',
    [
      BABYLON.Vector3.Zero(),
      new BABYLON.Vector3(size, 0, 0),
      new BABYLON.Vector3(size * 0.95, 0.05 * size, 0),
      new BABYLON.Vector3(size, 0, 0),
      new BABYLON.Vector3(size * 0.95, -0.05 * size, 0)
    ],
    scene
  )
  axisX.color = new BABYLON.Color3(1, 0, 0)

  var xChar = makeTextPlane('X', 'red', size / 10)
  xChar.position = new BABYLON.Vector3(0.9 * size, -0.05 * size, 0)

  var axisY = BABYLON.Mesh.CreateLines(
    'axisY',
    [
      BABYLON.Vector3.Zero(),
      new BABYLON.Vector3(0, size, 0),
      new BABYLON.Vector3(-0.05 * size, size * 0.95, 0),
      new BABYLON.Vector3(0, size, 0),
      new BABYLON.Vector3(0.05 * size, size * 0.95, 0)
    ],
    scene
  )
  axisY.color = new BABYLON.Color3(0, 1, 0)

  var yChar = makeTextPlane('Z', 'green', size / 10)
  yChar.position = new BABYLON.Vector3(0, 0.9 * size, -0.05 * size)

  var axisZ = BABYLON.Mesh.CreateLines(
    'axisZ',
    [
      BABYLON.Vector3.Zero(),
      new BABYLON.Vector3(0, 0, size),
      new BABYLON.Vector3(0, -0.05 * size, size * 0.95),
      new BABYLON.Vector3(0, 0, size),
      new BABYLON.Vector3(0, 0.05 * size, size * 0.95)
    ],
    scene
  )
  axisZ.color = new BABYLON.Color3(0, 0, 1)
  var zChar = makeTextPlane('Y', 'blue', size / 10)
  zChar.position = new BABYLON.Vector3(0, 0.05 * size, 0.9 * size)
}

// Ground
const createGround = (strack) => {
  let {
    fieldHeight,
    textureCanvasWidth,
    textureCanvasHeight,
    threeDOffsetY,
    threeDScale,
    materials,
    scene
  } = strack

  //--> Create ground

  strack.groundInner = BABYLON.Mesh.CreateGround(
    'ground',
    textureCanvasWidth / threeDScale,
    textureCanvasHeight / threeDScale,
    1,
    scene
  )
  strack.groundInner.position.x = 0
  strack.groundInner.position.z = fieldHeight / 2
  strack.groundInner.position.y = 0
  strack.groundInner.receiveShadows = true

  strack.groundInner.material = materials.ground

  //=============================p====================================

  var ground1 = BABYLON.Mesh.CreateGround(
    'ground1',
    textureCanvasHeight / threeDScale,
    1,
    1,
    scene
  )

  ground1.position.x = -(textureCanvasWidth / threeDScale) / 2
  ground1.position.z = fieldHeight / 2
  ground1.position.y = -0.5
  ground1.rotation.x = -Math.PI / 2
  ground1.rotation.y = -Math.PI / 2
  ground1.rotation.z = -Math.PI

  var groundMaterial1 = new BABYLON.StandardMaterial('textureGround', scene)
  groundMaterial1.specularColor = new BABYLON.Color3(0, 0, 0)
  groundMaterial1.emissiveColor = new BABYLON.Color3(1, 1, 1)
  groundMaterial1.diffuseTexture = new BABYLON.Texture(nflTxImg, scene)
  ground1.material = groundMaterial1

  var ground2 = BABYLON.Mesh.CreateGround(
    'ground2',
    textureCanvasHeight / threeDScale,
    1,
    1,
    scene
  )

  ground2.position.x = textureCanvasWidth / threeDScale / 2
  ground2.position.z = fieldHeight / 2
  ground2.position.y = -0.5
  ground2.rotation.x = -Math.PI / 2
  ground2.rotation.y = -Math.PI / 2

  ground2.material = groundMaterial1

  var ground3 = BABYLON.Mesh.CreateGround(
    'ground3',
    textureCanvasWidth / threeDScale,
    1,
    1,
    scene
  )

  ground3.position.x = 0
  ground3.position.z = -threeDOffsetY
  ground3.position.y = -0.5
  ground3.rotation.x = -Math.PI / 2

  var groundMaterial3 = new BABYLON.StandardMaterial('textureGround', scene)
  groundMaterial3.specularColor = new BABYLON.Color3(0, 0, 0)
  groundMaterial3.emissiveColor = new BABYLON.Color3(1, 1, 1)
  groundMaterial3.diffuseTexture = new BABYLON.Texture(nflTxImg, scene)
  ground3.material = groundMaterial3

  var ground4 = BABYLON.Mesh.CreateGround(
    'ground4',
    textureCanvasWidth / threeDScale,
    1,
    1,
    scene
  )

  ground4.position.x = 0
  ground4.position.z = textureCanvasHeight / threeDScale - threeDOffsetY
  ground4.position.y = -0.5
  ground4.rotation.x = -Math.PI / 2
  ground4.rotation.y = Math.PI

  ground4.material = groundMaterial3
}

const renderFootballGoals = (poleCoordinates, scene) => {
  let poleHeight = 16
  let poleDiameter = 0.2
  var pole1 = BABYLON.Mesh.CreateCylinder(
    'cyl1',
    3.05,
    poleDiameter,
    poleDiameter,
    24,
    scene
  )
  pole1.position.x = (poleCoordinates[0].x + poleCoordinates[1].x) / 2
  pole1.position.y = 3.05 / 2
  pole1.position.z = (poleCoordinates[0].y + poleCoordinates[1].y) / 2
  var pole2 = BABYLON.Mesh.CreateCylinder(
    'cyl1',
    poleCoordinates[0].y - poleCoordinates[1].y,
    poleDiameter,
    poleDiameter,
    24,
    scene
  )
  pole2.position.x = (poleCoordinates[0].x + poleCoordinates[1].x) / 2
  pole2.position.y = 3.05
  pole2.position.z = (poleCoordinates[0].y + poleCoordinates[1].y) / 2
  pole2.rotation.x = Math.PI / 2
  var pole3 = BABYLON.Mesh.CreateCylinder(
    'cyl1',
    7.62,
    poleDiameter,
    poleDiameter,
    24,
    scene
  )
  pole3.position.x = poleCoordinates[0].x
  pole3.position.y = 6.86
  pole3.position.z = poleCoordinates[0].y
  var pole4 = BABYLON.Mesh.CreateCylinder(
    'cyl1',
    7.62,
    poleDiameter,
    poleDiameter,
    24,
    scene
  )
  pole4.position.x = poleCoordinates[1].x
  pole4.position.y = 6.86
  pole4.position.z = poleCoordinates[1].y
  var pole5 = BABYLON.Mesh.CreateCylinder(
    'cyl1',
    3.05,
    poleDiameter,
    poleDiameter,
    24,
    scene
  )
  pole5.position.x = (poleCoordinates[2].x + poleCoordinates[3].x) / 2
  pole5.position.y = 3.05 / 2
  pole5.position.z = (poleCoordinates[2].y + poleCoordinates[3].y) / 2
  var pole6 = BABYLON.Mesh.CreateCylinder(
    'cyl1',
    poleCoordinates[2].y - poleCoordinates[3].y,
    poleDiameter,
    poleDiameter,
    24,
    scene
  )
  pole6.position.x = (poleCoordinates[2].x + poleCoordinates[3].x) / 2
  pole6.position.y = 3.05
  pole6.position.z = (poleCoordinates[2].y + poleCoordinates[3].y) / 2
  pole6.rotation.x = Math.PI / 2
  var pole7 = BABYLON.Mesh.CreateCylinder(
    'cyl1',
    7.62,
    poleDiameter,
    poleDiameter,
    24,
    scene
  )
  pole7.position.x = poleCoordinates[2].x
  pole7.position.y = 6.86
  pole7.position.z = poleCoordinates[2].y
  var pole8 = BABYLON.Mesh.CreateCylinder(
    'cyl1',
    7.62,
    poleDiameter,
    poleDiameter,
    24,
    scene
  )
  pole8.position.x = poleCoordinates[3].x
  pole8.position.y = 6.86
  pole8.position.z = poleCoordinates[3].y
}

const renderAustralianRulesGoals = ({ dimensions, scene }) => {
  const poleHeightCenter = 16
  const poleHeightOuter = 11
  let poleDiameter = 0.2

  function getGoalPostPosition(x, y) {
    return {
      scaleX: x,
      scaleY: y
    }
  }
  const pole1Coords = getGoalPostPosition(dimensions.P14.x, dimensions.P14.y)
  const pole2Coords = getGoalPostPosition(dimensions.P15.x, dimensions.P15.y)
  const pole3Coords = getGoalPostPosition(dimensions.P16.x, dimensions.P16.y)
  const pole4Coords = getGoalPostPosition(dimensions.P17.x, dimensions.P17.y)
  const pole5Coords = getGoalPostPosition(dimensions.P29.x, dimensions.P29.y)
  const pole6Coords = getGoalPostPosition(dimensions.P30.x, dimensions.P30.y)
  const pole7Coords = getGoalPostPosition(dimensions.P31.x, dimensions.P31.y)
  const pole8Coords = getGoalPostPosition(dimensions.P32.x, dimensions.P32.y)

  var pole1 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder',
    { height: poleHeightOuter, diameter: poleDiameter },
    scene
  )
  pole1.position.x = pole1Coords.scaleX
  pole1.position.y = poleHeightOuter / 2
  pole1.position.z = pole1Coords.scaleY

  var pole2 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder',
    { height: poleHeightCenter, diameter: poleDiameter },
    scene
  )
  pole2.position.x = pole2Coords.scaleX
  pole2.position.y = poleHeightCenter / 2
  pole2.position.z = pole2Coords.scaleY

  var pole3 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder',
    { height: poleHeightCenter, diameter: poleDiameter },
    scene
  )
  pole3.position.x = pole3Coords.scaleX
  pole3.position.y = poleHeightCenter / 2
  pole3.position.z = pole3Coords.scaleY

  var pole4 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder',
    { height: poleHeightOuter, diameter: poleDiameter },
    scene
  )
  pole4.position.x = pole4Coords.scaleX
  pole4.position.y = poleHeightOuter / 2
  pole4.position.z = pole4Coords.scaleY

  var pole5 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder',
    { height: poleHeightOuter, diameter: poleDiameter },
    scene
  )
  pole5.position.x = pole5Coords.scaleX
  pole5.position.y = poleHeightOuter / 2
  pole5.position.z = pole5Coords.scaleY

  var pole6 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder',
    { height: poleHeightCenter, diameter: poleDiameter },
    scene
  )
  pole6.position.x = pole6Coords.scaleX
  pole6.position.y = poleHeightCenter / 2
  pole6.position.z = pole6Coords.scaleY

  var pole7 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder',
    { height: poleHeightCenter, diameter: poleDiameter },
    scene
  )
  pole7.position.x = pole7Coords.scaleX
  pole7.position.y = poleHeightCenter / 2
  pole7.position.z = pole7Coords.scaleY

  var pole8 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder',
    { height: poleHeightOuter, diameter: poleDiameter },
    scene
  )
  pole8.position.x = pole8Coords.scaleX
  pole8.position.y = poleHeightOuter / 2
  pole8.position.z = pole8Coords.scaleY
}

const renderSoccerGoals = (
  bottomLeftPostCoords,
  topLeftPostCoords,
  topRightPostCoords,
  bottomRightPostCoords,
  scene
) => {
  let postHeight = 2.44 // in meters
  let postDiameter = 0.12 // typically a bit thicker than rugby posts
  let crossbarLength = 7.32 // in meters, distance between posts

  // Left Goal - Bottom Post
  var post1 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder',
    { height: postHeight, diameter: postDiameter },
    scene
  )
  post1.position.x = bottomLeftPostCoords.x
  post1.position.y = postHeight / 2
  post1.position.z = bottomLeftPostCoords.y

  // Left Goal - Top Post
  var post2 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder2',
    { height: postHeight, diameter: postDiameter },
    scene
  )
  post2.position.x = topLeftPostCoords.x
  post2.position.y = postHeight / 2
  post2.position.z = topLeftPostCoords.y

  // Left Goal - Crossbar
  var crossbar1 = BABYLON.MeshBuilder.CreateCylinder(
    'crossbar1',
    {
      height: crossbarLength,
      diameter: postDiameter
    },
    scene
  )
  crossbar1.position.x = (bottomLeftPostCoords.x + topLeftPostCoords.x) / 2
  crossbar1.position.y = postHeight
  crossbar1.position.z = (bottomLeftPostCoords.y + topLeftPostCoords.y) / 2
  crossbar1.rotation.x = Math.PI / 2 // Rotate around the X axis

  // Right Goal - Bottom Post
  var post3 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder3',
    { height: postHeight, diameter: postDiameter },
    scene
  )
  post3.position.x = bottomRightPostCoords.x
  post3.position.y = postHeight / 2
  post3.position.z = bottomRightPostCoords.y

  // Right Goal - Top Post
  var post4 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder4',
    { height: postHeight, diameter: postDiameter },
    scene
  )
  post4.position.x = topRightPostCoords.x
  post4.position.y = postHeight / 2
  post4.position.z = topRightPostCoords.y

  // Right Goal - Crossbar

  var crossbar2 = BABYLON.MeshBuilder.CreateCylinder(
    'crossbar2',
    {
      height: crossbarLength,
      diameter: postDiameter
    },
    scene
  )
  crossbar2.position.x = (bottomRightPostCoords.x + topRightPostCoords.x) / 2
  crossbar2.position.y = postHeight
  crossbar2.position.z = (bottomRightPostCoords.y + topRightPostCoords.y) / 2
  crossbar2.rotation.x = Math.PI / 2 // Rotate around the X axis
}

const renderRugbyPoles = (
  bottomLeftPoleCoords,
  topLeftPoleCoords,
  topRightPoleCoords,
  bottomRightPoleCoords,
  scene
) => {
  let poleHeight = 16
  let poleDiameter = 0.2

  // Left Posts - Bottom Vertical
  var pole1 = BABYLON.MeshBuilder.CreateCylinder(
    'cylinder',
    { height: poleHeight, diameter: poleDiameter },
    scene
  )
  pole1.position.x = bottomLeftPoleCoords.x
  pole1.position.y = poleHeight / 2
  pole1.position.z = bottomLeftPoleCoords.y

  // Left Posts - Top Vertical
  var pole2 = BABYLON.MeshBuilder.CreateCylinder(
    'cyl2',
    { height: 16, diameter: poleDiameter },
    scene
  )
  pole2.position.x = topLeftPoleCoords.x
  pole2.position.y = poleHeight / 2
  pole2.position.z = topLeftPoleCoords.y

  // Left Posts - Crossbar
  var pole3 = BABYLON.MeshBuilder.CreateCylinder(
    'cyl3',
    {
      height: topLeftPoleCoords.y - bottomLeftPoleCoords.y,
      diameter: poleDiameter
    },
    scene
  )
  let leftCrossbarHeight = 3
  if (topLeftPoleCoords.z > 0 && bottomLeftPoleCoords.z > 0)
    leftCrossbarHeight = (topLeftPoleCoords.z + bottomLeftPoleCoords.z) / 2
  pole3.position.x = bottomLeftPoleCoords.x
  pole3.position.y = leftCrossbarHeight
  pole3.position.z =
    bottomLeftPoleCoords.y + (topLeftPoleCoords.y - bottomLeftPoleCoords.y) / 2
  pole3.rotation.x = Math.PI / 2

  // Right Posts - Top Vertical
  var pole4 = BABYLON.MeshBuilder.CreateCylinder(
    'cyl4',
    { height: poleHeight, diameter: poleDiameter },
    scene
  )
  pole4.position.x = topRightPoleCoords.x
  pole4.position.y = poleHeight / 2
  pole4.position.z = topRightPoleCoords.y

  // Right Posts - Bottom Vertical
  var pole5 = BABYLON.MeshBuilder.CreateCylinder(
    'cyl5',
    { height: 16, diameter: poleDiameter },
    scene
  )
  pole5.position.x = bottomRightPoleCoords.x
  pole5.position.y = poleHeight / 2
  pole5.position.z = bottomRightPoleCoords.y

  // Right Posts - Crossbar
  var pole6 = BABYLON.MeshBuilder.CreateCylinder(
    'cyl6',
    {
      height: topRightPoleCoords.y - bottomRightPoleCoords.y,
      diameter: poleDiameter
    },
    scene
  )
  let rightCrossbarHeight = 3
  if (topRightPoleCoords.z > 0 && bottomRightPoleCoords.z > 0)
    rightCrossbarHeight = (topRightPoleCoords.z + bottomRightPoleCoords.z) / 2
  pole6.position.x = bottomRightPoleCoords.x
  pole6.position.y = rightCrossbarHeight
  pole6.position.z =
    bottomRightPoleCoords.y +
    (topRightPoleCoords.y - bottomRightPoleCoords.y) / 2
  pole6.rotation.x = Math.PI / 2
}
