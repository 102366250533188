import React from 'react'

export const Team = ({ color }) => {
  return (
    <svg width='29px' height='30px' viewBox='0 0 29 30' version='1.1'>
      <title>Path 3</title>
      <desc>Created with Sketch.</desc>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='navbar/team-off'
          transform='translate(-1.000000, 0.000000)'
          stroke={color}
          strokeWidth='2'
        >
          <g id='navbar/team'>
            <path
              d='M25,10 C25,17.3333333 21.6666667,22.3333333 15,25 C9,22.3333333 6,17.3333333 6,10 L15.5,5 L25,10 Z'
              id='Path-3'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
