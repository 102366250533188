import * as Sentry from '@sentry/react'
import { Env, Environment } from '../utils/env'
import { User } from '../metrics_server/user/types'
// import { ipcRenderer } from 'electron'
// Forcing rebuild
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { OptionalFeedbackConfiguration } from '@sentry-internal/feedback/types/types'

let sentrySetup = false

export function initSentry(env: Environment, version: string, dsn: string) {
  init(env, version, dsn)

  // Send version and environment to the main process //
  // ipcRenderer.send('set-sentry-release-environment', { version, env })
}

function init(env: Environment, version: string, dsn: string) {
  if (window.location.port === '3001') {
    console.log('Sentry disabled')
    return
  }
  switch (env.ENV) {
    case undefined:
    case Env.UNKNOWN:
      setTimeout(() => {
        init(env, version, dsn)
      }, 1_000)
      return
    case Env.DEVELOPMENT:
      console.debug('skip sentry initialization due it being development')
      return
  }

  const sentryUrls = [/^https:\/\/.*.sportable.com.*/g, /^http:\/\/.*:8888.*/g]

  const sentryConfig: Sentry.BrowserOptions = {
    dsn: dsn,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    integrations: [
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        maskAllInputs: false,
        networkCaptureBodies: true,
        networkDetailAllowUrls: [window.location.origin, ...sentryUrls],

        // Block recording of 3D canvas as babylonjs and sentry don't play well together //
        block: ['.canvas-3D']
      }),
      Sentry.httpClientIntegration(),
      Sentry.replayCanvasIntegration(),
      Sentry.browserTracingIntegration()
    ],
    release: `${version}`,
    environment: env.ENV,
    tracePropagationTargets: sentryUrls,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  }
  Sentry.init(sentryConfig)
  updateSentryUser()
  sentrySetup = true
}

export function isSentrySetup() {
  return sentrySetup
}

export const openSentryBugReporting = (toggleKeyboardShortcuts) => {
  const options: OptionalFeedbackConfiguration = {
    colorScheme: 'light',
    autoInject: false,
    showEmail: false,
    showName: false,
    showBranding: false,
    onFormClose: () => toggleKeyboardShortcuts(true),
    onFormOpen: () => toggleKeyboardShortcuts(false),
    onSubmit: () => {
      alert('Bug report submitted successfully!')
    },
    onError: (error) => {
      alert('Sorry, there was a problem submitting your bug. Please try again.')
      console.error('Sentry submit error:', error)
    }
  }

  const user = updateSentryUser()
  if (user) {
    options.user = {
      email: user.email,
      id: user.id,
      username: `${user.firstName} ${user.lastName}`
    }
  }
  const feedback = new Sentry.Feedback(options)
  feedback.openDialog()
}

const updateSentryUser = (): User | null => {
  if (localStorage) {
    const user: User = JSON.parse(localStorage.getItem('user'))
    if (user) {
      Sentry.setUser({
        email: user.email,
        id: user.id,
        username: `${user.firstName} ${user.lastName}`
      })

      // Send user information to the main process
      // ipcRenderer.send('set-sentry-user', user)

      return user
    }
  }
  Sentry.setUser(null)
  return null
}
