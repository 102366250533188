import React, { useEffect, useMemo, useState } from 'react'
import {
  useActiveFormattedDrill,
  useDrills,
  useFormattedDrills
} from '../../hooks'
import { getRowId, Table } from '../../../../components/Table/Table'
import { useAppDispatch } from '../../../../store/hooks'
import { selectDrill } from '../../slice'
import BasicSelect from '../../../../components/Material/Select'
import { Button } from '../../../../components/Button/Button'
import { createNewDrill, startDrill, stopDrill } from '../../thunks'
import { sportableColors } from '../../../../constants/sportableColors'
import { useSelectedFormattedSession } from '../../../sessions/hooks'

export const DrillsList = () => {
  const dispatch = useAppDispatch()
  const {
    drillsWithColorId,
    drills: { selectedDrillId }
  } = useDrills()

  const formattedDrills = useFormattedDrills()
  const activeFormattedDrill = useActiveFormattedDrill()
  const formattedSession = useSelectedFormattedSession()
  const { live } = formattedSession

  const drillsTableHeaders = [
    { name: 'Name', key: 'drillName', width: 45 },
    { name: 'Start time', key: 'startTime', type: 'date', width: 15 },
    { name: 'End time', key: 'endTime', type: 'date', width: 15 },
    { name: 'Duration', key: 'duration', type: 'duration', width: 15 },
    { name: 'Players', key: 'playerCount', width: 10 },
    {
      name: '',
      key: 'action',
      type: 'icon',
      input: {
        type: 'icon',
        callback: (item) => {
          if (item.isPending && !activeFormattedDrill) {
            dispatch(startDrill(item.id))
          }
          if (item.isActive) {
            dispatch(stopDrill(item.id))
          }
        }
      },
      width: 10
    }
  ]

  const drillsTableData = useMemo(() => {
    return formattedDrills.map((formattedDrill) => {
      let endTimeData: string | number = formattedDrill.endTime.unixMil
      if (formattedDrill.isActive) {
        endTimeData = 'Ongoing'
      } else if (formattedDrill.isPending) {
        endTimeData = '-'
      }

      let actionIcon = ''
      let actionColor = ''
      // let backgroundColor = '#fff'
      let color = '#000'

      if (formattedDrill.isPending) {
        actionIcon = 'play'
        actionColor = '#00dd00'
        // backgroundColor = sportableColors.colors.colorWarning50
        if (activeFormattedDrill) {
          actionColor = 'grey'
        }
      } else if (formattedDrill.isActive) {
        actionIcon = 'stop'
        actionColor = '#ff0000'
        // backgroundColor = sportableColors.colors.colorSuccess50
      }

      const back = formattedDrill.color

      return {
        __tagColor: formattedDrill.color,
        // __backgroundColor: backgroundColor,
        id: formattedDrill.id,
        drillName: formattedDrill.name,
        startTime: formattedDrill.isStarted
          ? formattedDrill.startTime.unixMil
          : 'Pending',
        endTime: endTimeData,
        duration: {
          startTime: formattedDrill.startTime.unixSeconds,
          endTime: formattedDrill.endTime.unixSeconds,
          inputUnit: 'seconds',
          displayUnit: 'minutes'
        },
        playerCount: formattedDrill.drillPlayers.count,
        isPending: formattedDrill.isPending,
        isActive: formattedDrill.isActive,
        isFinished: formattedDrill.isFinished,
        action: {
          icon: actionIcon,
          color: actionColor
        }
      }
    })
  }, [formattedDrills])

  // Handle new drill name input //
  const [newDrillName, setNewDrillName] = useState(
    `Drill ${formattedDrills.length + 1}`
  )
  useEffect(() => {
    setNewDrillName(`Drill ${formattedDrills.length + 1}`)
  }, [formattedDrills])

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box'
      }}
    >
      {live && (
        <div
          style={{
            height: '70px'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              columnGap: '20px'
            }}
          >
            <BasicSelect
              label={'New Drill Name'}
              variant='standard'
              selected={newDrillName}
              onChange={(value) => setNewDrillName(value)}
              labelShrink={true}
              size={'small'}
            />
            <Button
              className='btn--primary'
              handleClick={() => {
                dispatch(
                  createNewDrill({
                    name: newDrillName,
                    quickStart: false
                  })
                )
              }}
              style={{
                marginTop: '5px',
                width: '100%',
                height: '40px'
              }}
            >
              {`Add ${newDrillName}`}
            </Button>
          </div>
        </div>
      )}
      <div
        style={{
          height: 'calc(100% - 70px)',
          paddingTop: !live ? '5px' : undefined
        }}
      >
        <Table
          options={{
            initialOrder: 'dec',
            initialSortBy: 'name',
            sortActive: true
          }}
          id='drillsTable'
          highlightRow={(tableId, item) => {
            dispatch(selectDrill(item.id))
          }}
          highlightedRow={getRowId(selectedDrillId, 'drillsTable')}
          searchBy={['drillName']}
          checkBoxes={[
            {
              key: 'hidePending',
              label: 'Hide Pending',
              filter: (item) => {
                return !item.isPending
              }
            },
            {
              key: 'hideFinished',
              label: 'Hide Finished',
              filter: (item) => {
                return !item.isFinished
              }
            }
          ]}
          headerFont={14}
          smallHead={true}
          tableSize='large'
          tableClass={'minimalistBlack'}
          className={'container'}
          data={drillsTableData}
          headers={drillsTableHeaders}
        />
      </div>
    </div>
  )
}
