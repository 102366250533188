import React from 'react'
import styles from './Documents.module.scss'

interface PrivacyProps {}

export const Privacy: React.FC<PrivacyProps> = () => {
  return (
    <div>
      <div>
        <p>
          This Privacy Policy sets out how Sportable Technologies Limited
          (registered number 09044327 whose registered office is at Willow
          House, 2nd Floor 72-74 Paul Street, London, England, EC2A 4NA)
          (“Sportable”, “we”, “us” or “our”) collects and uses any personal data
          we collect or receive about you in respect of Sportable’s products,
          services and/or platforms (collectively, the “Sportable Service”), why
          we collect and use your personal data, who we might share this
          personal data with and your rights in relation to your personal data.
        </p>
        <p>
          We are committed to protecting and respecting your privacy and
          complying with our obligations under the General Data Protection
          Regulation and the Data Protection Act 2018 (the “Privacy Laws”) and
          all other laws relevant to the handling of your personal data in
          respect of the Sportable Service. We are the data controller for the
          purposes of the Privacy Laws (meaning that we are responsible for
          deciding how your personal data is used) and for ensuring it is
          handled in accordance with applicable privacy laws.{' '}
        </p>
        <p>
          When we refer to “personal data”, we are referring to any information
          which can directly or indirectly identify you, as explained more fully
          below.
        </p>
      </div>
      <div>
        <h5>
          WHAT PERSONAL DATA WE COLLECT ABOUT YOU (OR RECEIVE FROM THIRD
          PARTIES)
        </h5>
        <p>
          <strong>Information that you provide to us</strong> – this may include
          any of the following types of personal data which you provide to us
          when you register with the Sportable Service, use the Sportable
          Service, enquire about any aspect of the Sportable Service, or
          otherwise when you communicate or interact directly with us:
        </p>
        <ul>
          <li>
            <strong>Identity Data</strong> – first name, last name, age,
            photograph, position, team name, gender, username or other user
            identifier;
          </li>
          <li>
            <strong>Profile Data</strong> – you may provide feedback to
            Sportable through surveys or feedback forms and indicate your
            preferences to receiving marketing (from us or third parties).
          </li>
          <li>
            <strong>Contact Data</strong> – your phone number(s), email address
            and postal address;{' '}
          </li>
          <li>
            <strong>Financial Data</strong> – bank or payment card details and
            details of any purchases you make through the Sportable Service; and
          </li>
          <li>
            <strong>Special Category Data</strong> - we may ask you to provide
            certain special category data (such as health (for example height
            and weight) data) which will be used only to assess your performance
            and to improve our performance analysis and measurement software
            (unless we specify and explain to you other purposes at the time of
            collection). These data are seen as more sensitive and are therefore
            afforded greater protection under the Privacy Laws. Prior to
            collecting and using any such special category data, we will obtain
            explicit consent from you (unless we have another legal basis for
            collecting and using this information).
          </li>
        </ul>
        <p>
          <strong>Information that we collect about you</strong> – when you
          visit or use the Sportable Service we may collect the following types
          of personal data, using our software, hardware and other technologies:
        </p>
        <ul>
          <li>
            <strong>Technical Data</strong> – this might include your IP
            address, account log-in information, browser information, location
            information, operating system and system configuration, network and
            software identification and device information;
          </li>
          <li>
            <strong>Usage Data</strong> – this includes information about how
            you use the Sportable Service, such as click throughs, time spent on
            page, download errors; and
          </li>
          <li>
            <strong>Special Category Data</strong> - see above. In some cases we
            may collect this special category data from you (such as biometric
            data). Prior to collecting and using any such special category data,
            we will obtain explicit consent from you (unless we have another
            legal basis for collecting and using this information).
          </li>
        </ul>
        <p>
          <strong>Information that we receive about you from others</strong> –
          we may receive certain of the information about you referred to above
          from the team, club, league or other entity of which you are a member
          or representative and with whom we have a contractual relationship (a
          “Customer”). If we receive information from third parties we may
          combine this with information that we currently hold about you and
          process such combined information for the purposes set out below.
        </p>
        <p>
          In addition to the above, please note that we may use and share
          aggregated data. This may have originally generated from your personal
          data but is not treated as personal data under the Privacy Laws (as it
          doesn’t individually identify you). On such basis, we can use this
          data for any purpose. If this aggregated data is ever combined with
          other data in such a way that you can be personally identified, we
          will only use that data in accordance with this Privacy Policy.
        </p>
        <p>
          <strong>
            It is important that the information we hold about you is accurate.
            Please keep us informed of any changes to your personal information.
          </strong>
        </p>
      </div>
      <div>
        <h5>WHY WE USE YOUR DATA</h5>
        <p>
          We have set out in the table below, the purposes for using your
          personal data and the legal basis for doing so:
        </p>
        <table>
          <tr>
            <th>Purpose</th>
            <th>Types of Data</th>
            <th>Lawful basis</th>
          </tr>
          <tr>
            <td>To register you as a Sportable user</td>
            <td>Identity, Contact, Financial, Special Category</td>
            <td>
              <p>Performance of a contract with you and/or a Customer</p>
              <p>Consent</p>
            </td>
          </tr>
          <tr>
            <td>For internal administration and record keeping purposes</td>
            <td>All</td>
            <td>
              <p>Performance of a contract with you and/or a Customer</p>
              <p>Necessary to comply with a legal obligation</p>
              <p>
                Necessary for our legitimate interests (for effective business
                administration and service provision)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              To provide, maintain, improve and develop the Sportable Service{' '}
            </td>
            <td>All</td>
            <td>
              <p>Performance of a contract with you and/or a Customer</p>
              <p>
                Necessary for our legitimate interests (for effective business
                administration and service provision)
              </p>
              <p>Consent</p>
            </td>
          </tr>
          <tr>
            <td>
              To notify you of changes to our Privacy Policy, our Terms and
              Conditions or other changes to our services or products{' '}
            </td>
            <td>Identity, Contact </td>
            <td>
              <p>Performance of a contract with you and/or a Customer</p>
              <p>Necessary to comply with a legal obligation</p>
            </td>
          </tr>
          <tr>
            <td>
              To answer your enquiries which may involve contacting you by post,
              e-mail or phone{' '}
            </td>
            <td>Identity, Contact </td>
            <td>
              <p>Performance of a contract with you and/or a Customer</p>
              <p>
                Necessary for our legitimate interests (to ensure our Customers
                and users are informed and satisfied with our service)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              To enable us to process payments which you make, or a Customer
              makes on your behalf{' '}
            </td>
            <td>Identity, Contact, Financial </td>
            <td>
              <p>Performance of a contract with you and/or a Customer</p>
            </td>
          </tr>
          <tr>
            <td>To contact you about Sportable promotions </td>
            <td>Identity, Contact, Profile </td>
            <td>
              <p>
                Necessary for our legitimate interests (to improve our products
                and services and develop our business generally)
              </p>
              <p>Consent</p>
            </td>
          </tr>
          <tr>
            <td>
              To contact you about thirdparty products and services which we
              believe may be relevant to you (where you have indicated you would
              like to hear about these){' '}
            </td>
            <td>Identity, Contact, Profile </td>
            <td>
              <p>Consent</p>
            </td>
          </tr>
          <tr>
            <td>
              To improve and personalise your experience of the Sportable
              Service by delivering more relevant content and advertising and to
              ensure that the Sportable Service is delivered to you in the most
              effective way possible{' '}
            </td>
            <td>Identity, Contact, Profile, Technical, Usage </td>
            <td>
              <p>
                Necessary for our legitimate interests (to develop our business,
                improve our service and overall user experience and inform our
                marketing strategy)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              To administer the Sportable Service, including trouble shooting,
              testing and analysis and to enable you to participate in
              interactive features of the Sportable Service{' '}
            </td>
            <td>All (excluding Special Category Data) </td>
            <td>
              <p>Performance of a contract with you and/or a Customer</p>
              <p>
                Necessary for our legitimate interests (to ensure that the
                Sportable Service is fully functional and operating in the most
                effective way for you)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              To verify your identity and detect fraud and security issues
            </td>
            <td>All (excluding Special Category Data) </td>
            <td>
              <p>
                Necessary for our legitimate interests (to prevent and detect
                fraudulent activity, security incidents and criminal activity)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              To enable you to participate in prize competitions, prize draws or
              complete a survey
            </td>
            <td>Identity, Contact, Profile, Technical, Usage </td>
            <td>
              <p>
                Necessary for our legitimate interests (to develop our business,
                promote new products and services, obtain feedback from users to
                improve our services)
              </p>
            </td>
          </tr>
        </table>
        <p>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose.{' '}
        </p>
        <p>
          If we need to use your personal data for an unrelated purpose, we will
          notify you and we will explain the legal basis which allows us to do
          so.
        </p>
      </div>
      <div>
        <h5>HOW LONG DO WE KEEP YOUR DATA FOR?</h5>
        <p>
          We will keep your personal data for as long as you are a registered
          user of the Sportable Service or for as long as the term of our
          contract with the relevant Customer (as applicable). After this point
          we may still need to keep hold of your personal data if there is a
          legal reason for doing so (such as for tax purposes or where we need
          to resolve any disputes with you or a Customer). In such
          circumstances, we will only retain the minimum amount of data
          necessary for such reason. We may retain aggregated data for any
          purposes.
        </p>
      </div>
      <div>
        <h5>THIRD PARTY WEBSITE LINKS</h5>
        <p>
          Please note that the Sportable Service may include links to third
          party websites. If you choose to click through to the relevant third
          party’s website, you will be subject to the terms and conditions of
          use and privacy policy of the relevant third party. Sportable does not
          accept any responsibility or liability for these policies of your use
          of such third-party websites. Please check the terms of these policies
          before using the relevant third party’s website.
        </p>
      </div>
      <div>
        <h5>INTERNATIONAL TRANSFERS OF YOUR DATA</h5>
        <p>
          Currently, Sportable does not transfer any of your personal data
          outside of the European Economic Area ("EEA"); however, in the future,
          Sportable may need to transfer your personal data to other countries
          outside of the EEA. This may happen if any of our servers are from
          time to time located in a country outside of the EEA or if any of our
          service providers are located in a country outside of the EEA. Before
          we proceed with any such transfer, we will ensure that we have a legal
          ground for doing so and have made sure that all adequate protections
          are in place. These countries may not have similar data protection
          laws to the EEA. If we transfer your personal data outside of the EEA
          in this way, we will take the appropriate steps to ensure that your
          privacy rights continue to be protected and that we continue to
          process your data in a fair and lawful manner and ensure we have
          explicit consent.{' '}
        </p>
        <p>
          Where relevant, you will have the right to see a copy of any
          safeguards we put in place for international transfers of your data.
        </p>
      </div>
      <div>
        <h5>DISCLOSURE OF YOUR PERSONAL DATA</h5>
        <p>We may disclose your personal data for the following purposes:</p>
        <ul>
          <li>
            Where you have explicitly consented to us doing so, to any Customer
            of which you are a member or representative, for the purposes of
            fulfilling our contractual obligations to that Customer.{' '}
          </li>
          <li>
            Where you have explicitly consented to us doing so, to our
            third-party service providers who assist us with delivering the
            Sportable Service.
          </li>
          <li>
            Where you have explicitly consented to us doing so, we may disclose
            your personal data to third parties for marketing purposes. You can
            opt-out of any marketing preferences at any time by following the
            process referred to below.{' '}
          </li>
          <li>
            We may also be required to share your personal data with third
            parties if required by law or regulation. In such circumstances, we
            will make sure that the disclosure is only to the extent required by
            law or regulation.
          </li>
        </ul>
        <p>
          If we share personal data with third parties, we will ensure that
          access is limited on a strictly need to know basis and is subject to
          suitable obligations relating to confidentiality and security.
        </p>
      </div>
      <div>
        <h5>WHERE WE STORE YOUR PERSONAL DATA</h5>
        <p>
          We will store your personal data on servers in the European Economic
          Area (unless we relocate such servers, see “International Transfers of
          Your Data”) and will ensure that such personal data is held securely
          in accordance with good industry standards. Where we have given you a
          password to access a part of the Sportable Service you are responsible
          for keeping this password secure and confidential. Please do not share
          this with anyone else.
        </p>
        <p>
          Please be aware that the transmission of data via the internet is not
          completely secure. Although Sportable uses its best endeavours to keep
          your data secure, we cannot guarantee that information transmitted to
          us via the internet will be completely secure and any transmission is
          at your own risk.{' '}
        </p>
      </div>
      <div>
        <h5>CHANGES TO OUR PRIVACY POLICY</h5>
        <p>
          If we amend our Privacy Policy, it will be published on the Sportable
          website or other Sportable platform so please check regularly to see
          if there have been any changes. We may also email you to notify you of
          any substantial changes to this Privacy Policy if appropriate.
        </p>
      </div>
      <div>
        <h5>YOUR RIGHTS</h5>
        <p>In certain situations, you are entitled to: </p>
        <ul>
          <li>access a copy of your personal data;</li>
          <li>correct your personal data; </li>
          <li>erase your personal data; </li>
          <li>
            object to the processing of your personal data where we are relying
            on a legitimate interest (as set out in the above table);{' '}
          </li>
          <li>restrict the processing of your personal data; </li>
          <li>
            request the transfer of your personal data to a third party; or{' '}
          </li>
          <li>
            where you have provided your consent to certain of our processing
            activities, in certain circumstances, you may withdraw your consent
            at any time (but please note that we may continue to process such
            personal data if we have legitimate legal grounds for doing so).{' '}
          </li>
        </ul>
        <p>
          If you wish to exercise any of these rights, please contact us using
          the details set out in the “Contact Us” section below. You will not
          have to pay a fee to exercise any of these rights, unless your request
          is clearly unfounded, repetitive or excessive (in which case we can
          charge a reasonable fee). Alternatively, we may refuse to comply with
          your request in these circumstances.
        </p>
        <p>
          In addition to the above, please note that you have the right to make
          a complaint at any time to the Information Commissioner’s Office or
          your relevant regulator if you are concerned about the way in which we
          are handling your personal data.{' '}
        </p>
      </div>
      <div>
        <h5>HOW TO OPT-OUT</h5>
        <p>
          You can opt-out of any third-party marketing that you have previously
          opted into (or any Sportable marketing) either by contacting us using
          the details set out below in the “Contact Us” section or by using the
          opt-out function detailed in the relevant marketing email.
        </p>
      </div>
      <div>
        <h5>CONTACT US</h5>
        <p>
          If at any time you wish to get in touch with us, whether that is to
          exercise your rights or otherwise, please contact:
        </p>
        <p>
          Email:{' '}
          <a href='mailto:support@sportable.com'>support@sportable.com</a>
        </p>
        <p>
          Please write to us at: Sportable Technologies Limited, 2nd Floor,
          Willow House, 72-74 Paul Street, London EC2A 4NA
        </p>
      </div>
      <div>
        <p>
          <strong>
            Both parties agree to be bound by the terms of this Privacy Policy
            and our Terms and Conditions which together form the agreement
            between the parties.
          </strong>
        </p>
      </div>
    </div>
  )
}
