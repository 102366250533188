export interface ThemeType {
  matchTracker: {
    appColor: string
  }
  commentator: {
    appColor: string
  }
}

export default {
  matchTracker: {
    appColor: '#0099cc'
  },
  commentator: {
    appColor: '#8CB09D'
  }
} as ThemeType
