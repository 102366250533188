import React from 'react'
import { Provider } from 'react-redux'
import { getStore } from '.'
import { isLocal } from '../metrics_server/env'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'

// Check for Jwt token and set user
const userString = localStorage.getItem('user') || '{}'
const user = JSON.parse(userString)

// Check if user has come from verify email
// If so remove application cache and cookie
export let isVerifyEmailLink = false
if (!isLocal) {
  const initialLocation = { ...window.location }
  const urlParams = new URLSearchParams(initialLocation.search)
  const verifyParam = urlParams.get('verify')
  if (verifyParam) {
    isVerifyEmailLink = true
  }
}

export const initialState = {
  authentication: {
    signedIn: !!user.id,
    error: null,
    signingIn: false,
    forgotPassword: false
  },
  user: {
    data: !isVerifyEmailLink ? user : {}
  }
}

export const ProviderWrapper = (children, appInitialState) => {
  const store = getStore({ ...initialState, ...appInitialState })

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>{children()}</Provider>
    </LocalizationProvider>
  )
}
