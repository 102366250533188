import React from 'react'
import styles from './Documents.module.scss'

interface TermsProps {}

export const Terms: React.FC<TermsProps> = () => {
  return (
    <ol className={styles.mainOlList}>
      <li>
        <h5>Introduction</h5>
        <ol>
          <li>
            These T&C set out the terms and conditions governing your usage of
            the Sportable hardware and/or software that we make available to you
            (the <strong>Sportable Service</strong>), and any data inputted
            into, or generated through your usage of, the Sportable Service (the{' '}
            <strong>Generated Data</strong>).{' '}
          </li>
          <li>
            References in these T&C to: (a) <strong>we</strong>,{' '}
            <strong>us</strong> or <strong>our</strong> means Sportable
            Technologies Ltd, a company registered in England and Wales under
            company number 9044327; and (b) <strong>you</strong> or{' '}
            <strong>your</strong> means the entity to whom we make the Sportable
            Service available and each person employed or engaged by, or whom
            otherwise acts under the authority of, that entity.{' '}
          </li>
          <li>
            YOUR USAGE OF ANY ELEMENT OF THE SPORTABLE SERVICE CONSTITUTES YOUR
            ACCEPTANCE OF THESE T&C.
          </li>
        </ol>
      </li>
      <li>
        <h5>Licence to use the Sportable Service</h5>
        <ol>
          <li>
            In consideration of the delivery/supply of the Sportable Service and
            you agreeing to abide by the terms of these T&C, we hereby grant to
            you a non-exclusive, non-transferable, non-sublicensible, revocable
            licence to use the Sportable Service in the United Kingdom on the
            terms of these T&C. If we notify you that the Sportable Service may
            only be used by a limited number of your users, you shall ensure
            that only that number of users uses the Sportable Service.
          </li>
          <li>
            You acknowledge that the Sportable Service is licensed to you and
            not sold. You acknowledge and agree that title to (and all
            intellectual property rights in) all elements of the Sportable
            Service shall at all times remain owned by us. Risk in the tangible
            elements of the Sportable Service shall remain with you from the
            time that those elements are made available to you until the time
            that those elements are returned to us. If you provide any ideas,
            suggestions or recommendations to us regarding the Sportable Service
            (<strong>Feedback</strong>), you agree that we are free to retain,
            use and incorporate such Feedback in our products and/or services,
            without payment of royalties or other consideration to you.{' '}
          </li>
          <li>
            You shall not make any application to register any intellectual
            property rights in or to, or in any way relating to, the Sportable
            Service, or otherwise question or dispute any of our intellectual
            property rights in the Sportable Service.
          </li>
          <li>
            You must only use the Sportable Service for the purpose of
            monitoring the performance of your users of the Sportable Service
            and for general research purposes (the{' '}
            <strong>Permitted Purpose</strong>). You are expressly prohibited
            from selling, leasing, supplying or otherwise commercially
            exploiting any element of the Sportable Service.
          </li>
        </ol>
      </li>
      <li>
        <h5>Your obligations in respect of the Sportable Service </h5>
        <ol>
          <li>
            You must: (a) at all times keep all tangible elements of the
            Sportable Service in good order and repair and ensure that the
            Sportable Service is properly operated in accordance with any
            instructions that we provide to you; (b) notify us within 24 hours
            of becoming aware that any tangible element of the Sportable Service
            has been lost, stolen or damaged beyond economic repair; (c) keep
            any log-in data and passwords used by you in relation to the
            Sportable Service strictly confidential; (d) not use the Sportable
            Service for any illegal or unauthorised purpose, and must ensure
            that your usage of the Sportable Service is at all times in
            accordance with all applicable laws and regulations; (e) not alter
            or remove any proprietary or identifying markings on any tangible
            element of the Sportable Service; (f) not make any alteration,
            modification or repairs to any element of the Sportable Service
            without our prior written consent; and (g) not copy, reverse
            engineer, adapt, decompile, vary or modify any element of the
            Sportable Service, or authorise or knowingly permit any person to do
            the same.
          </li>
          <li>
            In any event, you shall not use, copy, reverse engineer, adapt,
            decompile, vary or modify any of our intellectual property rights,
            either alone or with any other person, to create a service,
            software, hardware or products that are the same as or similar to
            the Sportable Service.{' '}
          </li>
        </ol>
      </li>
      <li>
        <h5>Generated Data </h5>
        <ol>
          <li>
            Each party acknowledges and agrees that title to (and all
            intellectual property rights in) the Generated Data shall at all
            times remain owned by you, provided that you shall not (and shall
            not permit or allow any third party to) use the Generated Data for
            any purpose other than the Permitted Purpose. You are expressly
            prohibited from selling, licensing or otherwise commercially
            exploiting any of the Generated Data.
          </li>
          <li>
            You agree that we may access and use the Generated Data at any time:
            (a) for the purpose of performing our obligations to, and monitoring
            our rights against, you; and (b) for aggregate reporting purposes,
            including for use in respect of the research and development of new
            products and services, and enhancements to existing products and
            services, provided that we shall ensure that the identity of any one
            individual or group of individuals cannot be ascertained from our
            usage of the Generated Data.
          </li>
          <li>
            You shall comply at all times with the Data Protection Act 1998, and
            shall be responsible for obtaining all necessary consents required
            in relation to the collection, storage, use and transfer of the
            Generated Data, including any consents required in accordance with
            any privacy law that applies to the relevant user. You acknowledge
            and agree that to the extent that we are required to process
            personal data on your behalf, that personal data may be transferred
            or stored outside the European Economic Area.
          </li>
        </ol>
      </li>
      <li>
        <h5>Indemnity </h5>
        <p>
          YOU INDEMNIFY, RELEASE AND HOLD HARMLESS US, OUR AFFILIATES AND EACH
          OF OUR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS AND CONTRACTORS (
          <strong>INDEMNIFIED PARTIES</strong>) FROM AND AGAINST ANY AND ALL
          LOSSES, DAMAGES, LIABILITIES, OBLIGATIONS, HARM, CHARGES, COSTS AND
          EXPENSES (INCLUDING ALL REASONABLE LEGAL FEES) INCURRED BY AN
          INDEMNIFIED PARTY IN CONNECTION WITH ANY INJURY OR DAMAGE TO ANY
          PERSON OR PROPERTY ARISING DIRECTLY OR INDIRECTLY FROM THE SPORTABLE
          SERVICE OR ITS USE BY YOU, OTHER THAN WHERE SUCH DAMAGE OR LOSS HAS
          BEEN DIRECTLY CAUSED BY OUR NEGLIGENCE OR THE NEGLIGENCE OF OUR
          EMPLOYEES, AGENTS OR SUBCONTRACTORS WHEN ACTING ON OUR BEHALF.
        </p>
      </li>
      <li>
        <h5>Termination </h5>
        <ol>
          <li>
            We may terminate your usage of the Sportable Service (including all
            licences granted to you hereunder) at any time on seven days’ prior
            written notice to you (and such notice may be provided by email).
          </li>
          <li>
            On termination you must deliver up to us, at your expense, any
            tangible elements of the Sportable Service (including all copies of
            the hardware and software) in your possession or under your control.
          </li>
        </ol>
      </li>
      <li>
        <h5>Limitation of liability </h5>
        <ol>
          <li>
            NOTHING IN THESE T&C SHALL LIMIT OR EXCLUDE OUR LIABILITY FOR: (A)
            DEATH OR PERSONAL INJURY CAUSED BY OUR NEGLIGENCE, OR THE NEGLIGENCE
            OF OUR EMPLOYEES, AGENTS OR SUBCONTRACTORS (AS APPLICABLE); (B)
            FRAUD OR FRAUDULENT MISREPRESENTATION; OR (C) ANY MATTER IN RESPECT
            OF WHICH IT WOULD BE UNLAWFUL FOR US TO EXCLUDE OR RESTRICT
            LIABILITY.
          </li>
          <li>
            SUBJECT TO CLAUSE 7.1:
            <ol>
              <li>
                YOU ARE RESPONSIBLE FOR ALL RISKS OF, OR IN CONNECTION WITH,
                YOUR OPERATION AND USE OF THE SPORTABLE SERVICE, INCLUDING RISKS
                OF THIRD PARTY DAMAGE TO PERSONS OR PROPERTY AND RISKS OF
                LIABILITY TO PERSONS WHO, OR WHOSE PROPERTY, MAY BE DAMAGED BY
                THE USE OF THE SPORTABLE SERVICE;
              </li>
              <li>
                WE SHALL UNDER NO CIRCUMSTANCES WHATSOEVER BE LIABLE TO YOU,
                WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), BREACH OF
                STATUTORY DUTY, OR OTHERWISE, FOR ANY LOSS OF PROFIT, OR ANY
                INDIRECT OR CONSEQUENTIAL LOSS ARISING UNDER OR IN CONNECTION
                WITH OUR SUPPLY OF THE SPORTABLE SERVICE TO YOU; AND{' '}
              </li>
              <li>
                OUR TOTAL LIABILITY TO YOU IN RESPECT OF ALL OTHER LOSSES
                ARISING UNDER OR IN CONNECTION WITH OUR SUPPLY OF THE SPORTABLE
                SERVICE TO YOU, WHETHER IN CONTRACT, TORT (INCLUDING
                NEGLIGENCE), BREACH OF STATUTORY DUTY, OR OTHERWISE, SHALL IN NO
                CIRCUMSTANCES EXCEED £100 IN AGGREGATE.
              </li>
              <li>
                ALL WARRANTIES, CONDITIONS AND OTHER TERMS IMPLIED BY STATUTE OR
                COMMON LAW ARE, TO THE FULLEST EXTENT PERMITTED BY LAW, EXCLUDED
                FROM THESE T&C.
              </li>
            </ol>
          </li>
          <li>
            ALL WARRANTIES, CONDITIONS AND OTHER TERMS IMPLIED BY STATUTE OR
            COMMON LAW ARE, TO THE FULLEST EXTENT PERMITTED BY LAW, EXCLUDED
            FROM THESE T&C
          </li>
        </ol>
      </li>
      <li>
        <h5>General </h5>
        <ol>
          <li>
            <span className={styles.underline}>Use of name/logo.</span> You
            grant to us a non-exclusive, royalty-free, perpetual, worldwide
            licence to use your name and logo in our customer lists and
            marketing materials in a manner no more materially prominent than
            any other customer referred to in those lists and materials.{' '}
          </li>
          <li>
            <span className={styles.underline}>
              Assignment and other dealings.
            </span>{' '}
            You may not assign, transfer, mortgage, charge, subcontract, declare
            a trust over or deal in any other manner with any or all of your
            rights or obligations under these T&C without our prior written
            consent.
          </li>
          <li>
            <span className={styles.underline}>Confidentiality.</span> Each
            party undertakes that it shall not at any time disclose to any
            person any confidential information concerning the business,
            affairs, customers, clients or suppliers of the other party.
          </li>
          <li>
            <span className={styles.underline}>Force Majeure.</span> We shall
            not be in breach of our obligations to you, nor liable for delay in
            performing, or failure to perform, any of those obligations, if such
            delay or failure results from acts, events, circumstances, omissions
            or causes beyond our reasonable control.
          </li>
          <li>
            <span className={styles.underline}>Severability.</span> If any
            provision of these T&C is or becomes invalid, illegal or
            unenforceable, it shall be deemed modified to the minimum extent
            necessary to make it valid, legal and enforceable.
          </li>
          <li>
            <span className={styles.underline}>Entire agreement.</span> These
            T&C apply to our provision of the Sportable Service to the exclusion
            of any other terms, guarantees or prior representations that you may
            seek to impose or incorporate or which are implied by trade, custom,
            practice or course of dealing.
          </li>
          <li>
            <span className={styles.underline}>Governing law.</span> These T&C,
            and any dispute or claim (including non-contractual disputes or
            claims) arising out of or in connection with them or their subject
            matter or formation, shall be governed by and construed in
            accordance with the law of England and Wales.
          </li>
          <li>
            <span className={styles.underline}>Jurisdiction.</span> Each party
            irrevocably agrees that the courts of England and Wales shall have
            exclusive jurisdiction to settle any dispute or claim (including
            non-contractual disputes or claims) arising out of or in connection
            with these T&C or their subject matter or formation.
          </li>
        </ol>
      </li>
    </ol>
  )
}
