// Used in validation to update the operator notes of a flight

import React, { useCallback } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { FormContainer } from '../../../components/Forms/Form/Form'
import { updateEvent } from '../../../metrics_server/events/actions'
import { toggleModal } from '../../../ui/modal/actions'
import { useSelectedFormattedEvent } from '../../../metrics_server/events/hooks'

export const useFlightNotesModal = (operatorNotes) => {
  // Redux //
  const dispatch = useAppDispatch()
  // ====== //

  const formattedEvent = useSelectedFormattedEvent()

  const openFlightNotesModal = useCallback(() => {
    const fields = [
      {
        name: 'notes',
        type: 'text',
        label: 'Notes',
        initialValue: operatorNotes?.notes || ''
      }
    ]
    const ChildComponent = () => (
      <FormContainer
        fields={fields}
        onSubmit={(values) => {
          const notes = values.notes
          // TODO: do we need to set the teamId if we are filtering the player options by team
          dispatch(
            updateEvent({
              id: formattedEvent.id,
              operatorNotes: {
                ...formattedEvent.operator,
                notes
              }
            })
          )
          dispatch(toggleModal({}))
        }}
        focusOnMount={'notes'}
      />
    )

    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        wrapper: true,
        title: 'Flight Notes',
        ChildComponent,
        className: 'modalSmall'
      })
    )
  }, [formattedEvent])

  return {
    openFlightNotesModal
  }
}
