import React from 'react'
import { CustomFieldRenderProps } from '../Fields.types'
import FileBase64 from 'react-file-base64'
import cameraImg from '../../../../assets/img/camera.png'
import { useImageInputLogic } from './Image.logic'
import styles from './Image.module.scss'

interface ImageProps extends CustomFieldRenderProps {}

export const ImageInput: React.FC<ImageProps> = (props) => {
  const { getFiles, img } = useImageInputLogic(props)
  return (
    <div>
      <div className={styles.imgLabel}>
        {props.label && <label htmlFor={props.input.name}>{props.label}</label>}
        {props.meta && props.meta.touched && props.meta.error && (
          <div className={styles.error}>
            <span>{props.meta.error}</span>
          </div>
        )}
      </div>
      <div className={styles.imageUploader}>
        <label className={styles.customFileUpload}>
          <img
            className={img && styles.img}
            src={!img ? cameraImg : img}
            alt=''
          />
          <FileBase64 onDone={getFiles} />
        </label>
      </div>
    </div>
  )
}
