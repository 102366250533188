import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import Select from '../../Inputs/Select/Select'
import { useHardwareFilter } from '../../../../metrics_server/hardware/filter'
import { usePitches } from '../../../../metrics_server/pitches/hooks'
import { useAppDispatch } from '../../../../store/hooks'
import { updateTagList } from '../../../../metrics_server/pitches/actions'

export const FullPitchTagListSetupForm = () => {
  const dispatch = useAppDispatch()
  const { setupTagList } = usePitches()

  const { filteredHardware } = useHardwareFilter(
    {
      type: 'PlayerTag',
      assigned: 'all'
    },
    'setup'
  )

  const [first, second, third] = setupTagList

  const [firstTagListItem, setFirstTagListItem] = useState(first)
  const [secondTagListItem, setSecondTagListItem] = useState(second)
  const [thirdTagListItem, setThirdTagListItem] = useState(third)

  const tagOptionsList = [firstTagListItem, secondTagListItem, thirdTagListItem]
  const [uniqueOptionsList, setUniqueOptionsList] = useState(tagOptionsList)

  const [defaultOption] = filteredHardware.optionsWithNull

  useEffect(() => {
    setUniqueOptionsList([
      firstTagListItem,
      secondTagListItem,
      thirdTagListItem
    ])
  }, [firstTagListItem, secondTagListItem, thirdTagListItem])

  const handleUpdateTagList = (index, name, value) => {
    if (name === 'masterSelectOne') {
      setFirstTagListItem(value)
      dispatch(updateTagList(index, value))
    }

    if (name === 'masterSelectTwo') {
      setSecondTagListItem(value)
      dispatch(updateTagList(index, value))
    }

    if (name === 'masterSelectThree') {
      setThirdTagListItem(value)
      dispatch(updateTagList(index, value))
    }
  }

  return (
    <div className={styles.selectContainer}>
      <h5>Set tag list:</h5>
      <div className={styles.tagSelect}>
        <div
          style={{
            fontSize: '13px',
            color: 'blue',
            textDecoration: 'underline',
            fontWeight: 700
          }}
        >
          1:
        </div>
        <div className='smallSelectContainer'>
          <Select
            className='small-select'
            defaultSelection={defaultOption}
            options={filteredHardware.optionsWithNull}
            selectedOptions={uniqueOptionsList}
            input={{
              name: 'masterSelectOne',
              value: firstTagListItem
            }}
            updateValue={(name, value) => handleUpdateTagList(0, name, value)}
          />
        </div>
        <div
          style={{
            fontSize: '13px',
            color: 'orange',
            textDecoration: 'underline',
            fontWeight: 700
          }}
        >
          2:
        </div>
        <div className='smallSelectContainer'>
          <Select
            className='small-select'
            defaultSelection={defaultOption}
            options={filteredHardware.optionsWithNull}
            selectedOptions={uniqueOptionsList}
            input={{
              name: 'masterSelectTwo',
              value: secondTagListItem
            }}
            updateValue={(name, value) => handleUpdateTagList(1, name, value)}
          />
        </div>
        <div
          style={{
            fontSize: '13px',
            color: 'red',
            textDecoration: 'underline',
            fontWeight: 700
          }}
        >
          3:
        </div>
        <div className='smallSelectContainer'>
          <Select
            className='small-select'
            defaultSelection={defaultOption}
            options={filteredHardware.optionsWithNull}
            selectedOptions={uniqueOptionsList}
            input={{
              name: 'masterSelectThree',
              value: thirdTagListItem
            }}
            updateValue={(name, value) => handleUpdateTagList(2, name, value)}
          />
        </div>
      </div>
    </div>
  )
}
