import React from 'react'
import { SportableIcon } from '../../../Icons/SportableIcon/SportableIcon'

import { Options } from '../../../Options/Options'
import { SelectDropdown } from '../../../SelectDropdown/SelectDropdown'
import { CommentatorButton } from '../../../CommentatorButton/CommentatorButton'

import styles from './Header.module.scss'

interface HeaderProps {
  title
  options
  view
  customInputs
  iconClick: (e) => void
}

export function Header({
  title,
  view,
  options,
  customInputs,
  iconClick
}: HeaderProps) {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.matchDashboardContainer}>
        <div className={styles.headerWrapper}>
          <div onClick={iconClick} className={styles.headerIcon}>
            <SportableIcon width={55} color={'white'} />
          </div>
          <div className={styles.title}>
            {title ? title : <h4>{view.name}</h4>}
          </div>
        </div>
        <div className={styles.headerWrapper}>
          {/* <LockIcon style={{ margin: '0px 5px' }} /> */}
          {!!customInputs && (
            <>
              {customInputs
                .filter((input) => !input.hidden)
                .map((input) => {
                  if (input.type === 'select') {
                    return (
                      <SelectDropdown
                        key={input.name}
                        isMulti={false}
                        options={input.options}
                        value={input.value}
                        onChange={input.onChange}
                        style={{ margin: '0px 5px' }}
                        placeholder='Select layout'
                      />
                    )
                  } else if (input.type === 'button') {
                    return (
                      <CommentatorButton
                        handleClick={input.onClick}
                        key={input.name}
                        title={input.name}
                        style={{ margin: '0px 5px' }}
                      />
                    )
                  }
                })}
            </>
          )}
          {/* options */}
          {!!options && (
            <div className={styles.optionsContainer}>
              <div className={styles.optionsBorder}></div>
              <Options
                options={options}
                backgroundColor={'transparent'}
                color={'#323233'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
