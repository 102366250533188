import React from 'react'
// import TimePicker from 'react-time-picker'
// OR
import 'react-time-picker/dist/TimePicker.css'
import TimePicker from 'react-time-picker/dist/entry.nostyle'
// OR
import './styles.css'

export const SportableTimePicker = ({
  onChange,
  value,
  format,
  disableClock,
  clearIcon,
  maxDetail
}) => {
  return (
    <TimePicker
      onChange={onChange}
      value={value}
      format={format}
      disableClock={disableClock}
      clearIcon={clearIcon}
      maxDetail={maxDetail}
    />
  )
}
