import {
  GET_TEAMS,
  CREATE_TEAM,
  JOIN_TEAM,
  GET_TEAM_PLAYERS,
  EDIT_TEAM,
  DELETE_TEAM,
  SET_TEAM,
  FILTER_TEAM
} from './types'

import axios from 'axios'
import { setError } from '../../ui/error/actions'
import { joinOrganisation } from '../organisations/actions'
import { request } from '../../utils/request_handler'
import { API_ROOT_URL } from '../../const'

// Get teams of logged in user
export function getTeams(callback) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: GET_TEAMS,
        payload: response
      })
      console.log('response.data', response.data)
      callback(response.data)
      // let playerRequests = response.data.coach.map((team) => {
      //   return dispatch(getTeamPlayers(team.id))
      // })
      // axios.all(playerRequests).then((values) => {
      //   callback()
      // })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get teams. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: 'teams',
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

// Create new team
export function createTeam(data, callback) {
  return (dispatch, getState) => {
    const { selected } = getState().organisations
    data.organisationId = selected
    const success = (response) => {
      dispatch({
        type: CREATE_TEAM,
        payload: response.data
      })
      dispatch(setTeam(response.data.id))
      callback()
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to create team. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: 'teams',
      data,
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

export function joinTeam(data, callback) {
  return (dispatch, getState) => {
    const { checkedOrganisation } = getState().organisations
    data.organisationId = checkedOrganisation.id
    data.threeWords = checkedOrganisation.threeWords
    const success = (response) => {
      dispatch({
        type: JOIN_TEAM,
        payload: response.data
      })
      dispatch(getTeamPlayers(response.data.id))
      dispatch(setTeam(response.data.id))
      dispatch(joinOrganisation(checkedOrganisation))
      callback()
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to join team. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: 'teams/setup',
      data,
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

// Get all players for a specified team
export function getTeamPlayers(id) {
  return (dispatch, getState) => {
    const success = (response) => {
      dispatch({
        type: GET_TEAM_PLAYERS,
        payload: { teamId: id, data: response.data }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get team ${
                  getState().teams.rawData[id].name
                } players. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `teams/${id}/players`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Edit team

export function editTeam(id, data) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: EDIT_TEAM,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to edit team. API request failed. Check log for more detail.`
        })
      )
    }

    // Remove Players from data
    const postData = { ...data }

    const options = {
      url: `teams/${id}`,
      data: postData,
      method: 'put',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

// Delete team
export function deleteTeam(id) {
  return (dispatch, getState) => {
    const success = (response) => {
      dispatch({
        type: DELETE_TEAM,
        payload: id
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to delete team ${
                  getState().teams.items[id].name
                } players. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `teams/${id}`,
      method: 'delete',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

export function setTeam(teamId) {
  return {
    type: SET_TEAM,
    payload: teamId
  }
}

export function filterTeams(value) {
  return {
    type: FILTER_TEAM,
    value
  }
}
