import { AnyAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit'
import promiseMiddleware from 'redux-promise'

import * as ui from '../ui'
import * as ms from '../metrics_server'

export const getStore = (initialState) => {
  return configureStore({
    reducer: {
      ...ui.reducers,
      ...ms.reducers
    },
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      })
  })
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export interface RootState extends ms.ApiState, ui.UIState {}
export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>
