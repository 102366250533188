import React from 'react'
import { useActiveMomentDuration } from '../../../utils/moment/hooks'
import moment from '../../../utils/moment'

export type DurationCellProps = {
  startTime: number
  endTime: number
  inputUnit: moment.unitOfTime.DurationConstructor
  displayUnit: moment.unitOfTime.DurationConstructor
}

export function DurationCell({
  startTime,
  endTime,
  inputUnit,
  displayUnit
}: DurationCellProps) {
  if (!startTime && !endTime) {
    return '-'
  }

  const duration = useActiveMomentDuration(startTime, endTime, inputUnit) as any

  return <>{duration._milliseconds < 1000 ? '0:00' : duration.format()}</>
}
