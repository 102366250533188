import React from 'react'
import ReactTooltip from 'react-tooltip'
import { QAReports } from '../../../../metrics_server/hardware/types'

export interface QaToolTipProps {
  qaReports?: QAReports
  colorError: string
  colorSuccess?: string
  colorWarning?: string
  icon: string | JSX.Element
  unknownQA?: boolean
}

export const QaToolTip: React.FC<QaToolTipProps> = ({
  qaReports,
  colorError,
  colorSuccess,
  icon,
  unknownQA = false
}) => {
  // if qaReports is undefined then show unknown QA warning
  if (unknownQA || !qaReports) {
    return (
      <div data-tip data-for='qaWarning' data-testid='qaWarningUnknown'>
        {icon}
        <ReactTooltip id='qaWarning' aria-haspopup='true' role='example'>
          <p data-testid='fieldQAText'>
            Field and Hardware QA:{' '}
            <span
              data-testid='fieldQATextStyle'
              style={{
                color: colorError
              }}
            >
              Unknown
            </span>
          </p>
        </ReactTooltip>
      </div>
    )
  }

  const { fieldQA, hwQA, retired, investigate } = qaReports
  const isQAPassed =
    fieldQA.passed &&
    hwQA.passed &&
    !!retired.passed === false &&
    !!investigate.passed === false

  // if both pass, then don't show anything
  if (isQAPassed) {
    return null
  }

  return (
    <div data-tip data-for='qaWarning' data-testid='qaWarning'>
      {icon}
      <ReactTooltip id='qaWarning' aria-haspopup='true' role='example'>
        <p data-testid='fieldQAText'>
          Field QA passed:{' '}
          <span
            data-testid='fieldQATextStyle'
            style={{
              color: fieldQA.passed ? colorSuccess : colorError
            }}
          >{`${fieldQA.passed !== '' ? !!fieldQA.passed : 'Unknown'}`}</span>
        </p>
        <p data-testid='hardwareQAText'>
          Hardware QA passed:{' '}
          <span
            data-testid='hardwareQATextStyle'
            style={{
              color: hwQA.passed ? colorSuccess : colorError
            }}
          >{`${hwQA.passed !== '' ? !!hwQA.passed : 'Unknown'}`}</span>
        </p>
        <p data-testid='investigateQAText'>
          Investigate:{' '}
          <span
            data-testid='investigateQATextStyle'
            style={{
              color:
                investigate.passed !== '' && !investigate.passed
                  ? colorSuccess
                  : colorError
            }}
          >{`${
            investigate.passed !== ''
              ? investigate.passed === false
                ? 'No'
                : 'Yes'
              : 'Unknown'
          }`}</span>
        </p>
        <p data-testid='retiredQAText'>
          Retired:{' '}
          <span
            data-testid='retiredQATextStyle'
            style={{
              color:
                retired.passed !== '' && !retired.passed
                  ? colorSuccess
                  : colorError
            }}
          >{`${
            retired.passed !== ''
              ? retired.passed === false
                ? 'No'
                : 'Yes'
              : 'Unknown'
          }`}</span>
        </p>
      </ReactTooltip>
    </div>
  )
}
