import { PitchSetup } from './PitchSetup/PitchSetup'
import { BallSetup } from './BallSetup/BallSetup'
import { PlayerShirtTagAssignment } from './PlayerShirtTagAssignment/PlayerShirtTagAssignment'
import { MainProps, Tabs } from '../../components/Views/Main/Main.types'
import { Pitch } from '../../components/Icons/PitchIcon/PitchIcon'
import { SportableBall } from '../../components/Icons/SportableBall/SportableBall'
import CellTowerIcon from '@mui/icons-material/CellTower'
import { TeamLogo } from '../../components/TeamLogo/TeamLogo'
import { BroadcastIntegration } from './BroadcastIntegration/BroadcastIntegration'
import { useFormattedSession } from '../../metrics_server/sessions/hooks'
import { useBroadcastIntegrationSessionState } from '../../metrics_server/broadcast_integration/hooks'
import { RefereeIcon } from '../../components/Icons/RefereeIcon/RefereeIcon'

export enum SessionSetupTabs {
  PITCH = 'PITCH',
  TEAM_A = 'TEAM_A',
  TEAM_B = 'TEAM_B',
  OFFICIATING_TEAM = 'OFFICIATING_TEAM',
  BALL_SETUP = 'BALL_SETUP',
  BROADCAST_INTERGRATION = 'BROADCAST_INTERGRATION'
}

export const tabConfig: Tabs = {
  [SessionSetupTabs.PITCH]: {
    key: SessionSetupTabs.PITCH,
    name: 'Pitch',
    Content: PitchSetup,
    Icon: Pitch,
    strack: {
      options: {
        teams: true,
        anchors: true,
        babylonActive: false,
        canvasId: 'pitch',
        cover: 'rugby-cover',
        show2DOnly: true,
        anchorSetup: true
      },
      canvasStyle: {
        width: '65%',
        height: '100%',
        padding: '15px 7.5px 15px 15px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: '0%',
        left: '0%',
        overflow: 'hidden'
      }
    }
  },
  [SessionSetupTabs.TEAM_A]: {
    key: SessionSetupTabs.TEAM_A,
    name: '',
    Content: PlayerShirtTagAssignment
  },
  [SessionSetupTabs.TEAM_B]: {
    key: SessionSetupTabs.TEAM_B,
    name: '',
    Content: PlayerShirtTagAssignment
  },
  [SessionSetupTabs.BALL_SETUP]: {
    key: SessionSetupTabs.BALL_SETUP,
    name: 'Balls',
    Content: BallSetup,
    Icon: SportableBall
  },
  [SessionSetupTabs.BROADCAST_INTERGRATION]: {
    key: SessionSetupTabs.BROADCAST_INTERGRATION,
    name: 'Broadcast',
    Content: BroadcastIntegration,
    Icon: CellTowerIcon
  }
}

export const useSessionSetupTabConfig = (view: MainProps) => {
  const formattedSession = useFormattedSession('setup')
  const { homeTeam, awayTeam, sport, isOfficiatingMode, officiationTeam } =
    formattedSession
  const broadcastIntegrationSessionState = useBroadcastIntegrationSessionState()

  const tabs = { ...view.tabs }
  // HOME TEAM
  tabs[SessionSetupTabs.TEAM_A].name = homeTeam.name
  tabs[SessionSetupTabs.TEAM_A].props = {
    teamId: homeTeam.id
  }
  tabs[SessionSetupTabs.TEAM_A].Icon = () =>
    TeamLogo({
      color: homeTeam.color,
      logo: homeTeam.logo,
      size: 30
    })

  // AWAY TEAM - delete tab if no away team (training mode)
  if (awayTeam) {
    tabs[SessionSetupTabs.TEAM_B].name = awayTeam.name
    tabs[SessionSetupTabs.TEAM_B].props = {
      teamId: awayTeam.id
    }
    tabs[SessionSetupTabs.TEAM_B].Icon = () =>
      TeamLogo({
        color: awayTeam.color,
        logo: awayTeam.logo,
        size: 30
      })
  } else {
    delete tabs[SessionSetupTabs.TEAM_B]
  }

  if (isOfficiatingMode) {
    // create officiating team tab
    tabs[SessionSetupTabs.OFFICIATING_TEAM] = {
      key: SessionSetupTabs.OFFICIATING_TEAM,
      name: 'Officials',
      Content: PlayerShirtTagAssignment,
      props: {
        teamId: officiationTeam?.id,
        key: SessionSetupTabs.OFFICIATING_TEAM
      },
      Icon: RefereeIcon
    }
  }

  // Set ball icon based on sport
  tabs[SessionSetupTabs.BALL_SETUP].Icon = ({ scale, color }) =>
    SportableBall({ scale, color, sportType: sport.value })

  // Set pitch icon based on sport
  tabs[SessionSetupTabs.PITCH].Icon = ({ scale, color }) =>
    Pitch({ scale, color, sportType: sport.value })

  // Broadcast integration tab enable / disable
  if (!broadcastIntegrationSessionState.isEnabled) {
    delete tabs[SessionSetupTabs.BROADCAST_INTERGRATION]
  }

  // Set initial tab
  const initialTab = tabs[SessionSetupTabs.PITCH]

  return { tabs, initialTab }
}
