import { isLocal } from '../../metrics_server/env'
import { AppConfig } from '../../App/App.types'
import * as HomeScreen from '../../views/TemplateDashboard/config'
import * as SessionScreen from '../../views/Session/config'
import { AppName, AppTypeKey } from '../types'
import { initialState } from '../../metrics_server/sessions/reducer'
import Theme from '../../ui/config/theme'

export const appConfig: AppConfig = {
  landing: HomeScreen.path,
  type: {
    name: AppName.commentatorTool,
    value: AppTypeKey.commentatorTool
  },
  isLocal,
  routes: {
    home: {
      ...HomeScreen.route,
      authenticate: true
    },
    session: {
      ...SessionScreen.remoteDashboardSessionRoute,
      authenticate: true
    }
  },
  initialSessionState: {
    ...initialState,
    filter: {
      ...initialState.filter,
      options: {
        ...initialState.filter.options,
        state: 'Started'
      }
    }
  },
  theme: Theme.commentator
}
