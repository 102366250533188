import React from 'react'

import { CreateSessionForm } from './CreateSessionForm/CreateSessionForm'

export const formConfig = {
  createSession: {
    key: 'createSession',
    name: 'Create Session',
    Component: CreateSessionForm,
    title: () => {
      return (
        <h1>
          Create <span>your session</span>
        </h1>
      )
    }
  }
}

export const formMatrix = [[formConfig.createSession]]
