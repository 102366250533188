import { SET_REDIRECT, SET_INITIAL_ROUTE, SET_URL_SEARCH_PARAMS } from './types'

import { RouterState } from './types'

const initialState: RouterState = {
  redirect: '',
  prevRoute: '',
  initialRoute: null,
  footerRoutes: [
    '/teams',
    '/sessionsetup',
    '/activitylist',
    '/dashboard',
    '/settings'
  ],
  urlSearchParams: null,
  action: null
}

export function routerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REDIRECT:
      return {
        ...state,
        prevRoute: state.redirect || state.initialRoute,
        redirect: action.payload.route,
        action: action.payload.action
      }
    case SET_INITIAL_ROUTE:
      return {
        ...state,
        initialRoute: action.payload
      }
    case SET_URL_SEARCH_PARAMS:
      return {
        ...state,
        urlSearchParams: action.payload
      }
    default:
      return state
  }
}
