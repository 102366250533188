import React, { useMemo } from 'react'
import { goToLanding } from '../../../ui/router/actions'
import { useAppDispatch } from '../../../store/hooks'
import { MainProps } from './Main.types'
import { ViewNavbarProps } from '../Views.types'

export function useDefaultHeaderProps(view: MainProps) {
  const dispatch = useAppDispatch()

  return useMemo(() => {
    return {
      title: view.name,
      iconClick: () => {
        dispatch(goToLanding())
      },
      backButton: view.backPath && {
        route: view.backPath
      },
      ToolbarInner: null
    }
  }, [view, dispatch])
}

export const useDefaultTabConfig = (view: MainProps) => {
  return useMemo(() => {
    const tabs = { ...view.tabs }
    if (!tabs) return { tabs: null, initialTab: null }
    const initialTab = Object.values(tabs)[0]
    return { tabs, initialTab }
  }, [view])
}

export const useNoNavbarProps = (): ViewNavbarProps => {
  return {
    enabled: false,
    centerButton: null
  }
}
