// Config
import { configReducer, configActions } from './config'
import { ConfigState } from './config/types'

// Types
import { AuthenticationState } from './authentication/types'
import { RouterState } from './router/types'
import { ErrorState } from './error/types'
import { SuccessState } from './success/types'
import { InfoState } from './info/types'
import { ModalState } from './modal/types'
import { LoadingState } from './loading/types'

// Reducers
import { authenticationReducer } from './authentication/reducer'
import { routerReducer } from './router/reducer'
import { errorReducer } from './error/reducer'
import { successReducer } from './success/reducer'
import { infoReducer } from './info/reducer'
import { modalReducer } from './modal/reducer'
import { loadingReducer } from './loading/reducer'

// Actions
import * as authenticationActions from './authentication/actions'
import * as routerActions from './router/actions'
import * as errorActions from './error/actions'
import * as successActions from './success/actions'
import * as infoActions from './info/actions'
import * as modalActions from './modal/actions'
import * as loadingActions from './loading/actions'

// Types
export interface UIState {
  config: ConfigState
  authentication: AuthenticationState
  router: RouterState
  error: ErrorState
  success: SuccessState
  info: InfoState
  modal: ModalState
  loading: LoadingState
}

// Reducers
export const reducers = {
  config: configReducer,
  authentication: authenticationReducer,
  router: routerReducer,
  error: errorReducer,
  success: successReducer,
  info: infoReducer,
  modal: modalReducer,
  loading: loadingReducer
}

// Actions
export const actions = {
  config: configActions,
  authentication: authenticationActions,
  router: routerActions,
  error: errorActions,
  success: successActions,
  info: infoActions,
  modal: modalActions,
  loading: loadingActions
}
