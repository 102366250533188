import { Action } from 'redux'

export const SET_ERROR = 'SET_ERROR'

export interface ErrorState {
  message?: string
  proceed?: () => void
  type?: string
  contactSupport?: boolean
  error?: any
}

export type SetErrorAction = {
  type: typeof SET_ERROR
  payload: ErrorState
}

export type SetError = (info: ErrorState) => SetErrorAction
