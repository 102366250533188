import React, { useState } from 'react'
import { AuthenticationForms } from '../form_config'

// Components
import { FormContainer } from '../../../components/Forms/Form/Form'

import styles from './SignupForm.module.scss'

const validate = (values) => {
  const errors = {
    email: undefined,
    terms: undefined
  }
  if (!values.email) {
    errors.email = 'Required'
  }
  if (!values.terms) {
    errors.terms = 'Terms'
  }
  return errors
}

export interface SignupFormProps {
  switchForm: (formKey: string) => void
  openDocument: (type: string) => void
  requestEmailVerification: (values, formKey, callback) => void
  authentication: any
}

export const SignupForm = (props: SignupFormProps) => {
  const { requestEmailVerification, authentication, openDocument, switchForm } =
    props

  const [fields, setFields] = useState(generateFields(true))

  function generateFields(showPassword) {
    return [
      {
        name: 'email',
        type: 'text',
        label: 'Email address'
      },
      {
        name: 'terms',
        type: 'checkbox',
        label: "I agree to Sportable's Terms & Conditions and Privacy Policy"
      }
    ]
  }

  const handleSubmit = (values) => {
    requestEmailVerification(
      values,
      AuthenticationForms.signup,
      switchToNextForm
    )
  }

  const switchToNextForm = () => {
    switchForm(AuthenticationForms.verifyEmail)
  }

  return (
    <>
      <FormContainer
        fields={fields}
        validate={validate}
        loading={authentication.isLoading}
        // validateOnBlur={true}
        onSubmit={handleSubmit}
        submitText={'Create'}
      >
        <div className={styles.termsContainer}>
          <p>
            <button
              type='button'
              className='button link'
              onClick={(e) => {
                e.preventDefault()
                openDocument('terms')
              }}
            >
              Terms & Conditions
            </button>{' '}
            and{' '}
            <button
              type='button'
              className='button link'
              onClick={(e) => {
                e.preventDefault()
                openDocument('privacy')
              }}
            >
              Privacy Policy
            </button>
          </p>
        </div>
      </FormContainer>
      <p className='authError'>{authentication && authentication.error}</p>
    </>
  )
}
