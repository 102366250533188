import React, { useEffect, useRef, useState } from 'react'
import { Tabs } from '../Main/Main.types'
import { Content } from './Content/Content'
// CHANGE TO DASHBOARD TABS
import styles from './Dashboard.module.scss'
import { Header } from './Header/Header'
import { SideNavBar } from './SideNavBar/SideNavBar'

interface Router {
  redirect: string
  footerRoutes: string[]
  prevRoute: string
  action: string
}

interface Location {
  pathname: string
}

type SetRedirectPop = (pathname: string) => void
type SetRedirect = (pathname: string) => void
type StartSession = () => void

interface DashboardProps {
  router?: Router
  location?: Location
  history?: string[]
  setRedirectPop?: SetRedirectPop
  setRedirect?: SetRedirect
  startSession?: StartSession
  hiddenCanvases?: string[]
  title: string
  iconClick: (e) => void
  onTabChange?: (tabs, activeTabKey) => void
  view
  tabs
  initialTab
}

export const DashboardView = (props: DashboardProps) => {
  const { view, title, iconClick, history, tabs, initialTab } = props
  const { redirect, action } = props.router

  // Tab Config
  const dashboardTabs: Tabs = tabs ? tabs : { ...view.tabs }

  let dashboardTabsArray, activeTab, selectedTab

  if (dashboardTabs) {
    dashboardTabsArray = Object.values(dashboardTabs)
    // Sort tabs based on index
    dashboardTabsArray.sort(function (a, b) {
      const keyA = a.index,
        keyB = b.index
      // Compare the 2 dates
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })

    activeTab = initialTab ? initialTab : dashboardTabsArray[0]
    selectedTab = initialTab ? initialTab : dashboardTabsArray[0]
  }

  // const getOptions = (tabKey, view) => {
  //   let tab, options
  //   if (tabKey) tab = view.tabs[tabKey]
  //   if (tab && tab.useOptions) {
  //     options = tab.useOptions(props)
  //   }

  //   if (view.useOptions) {
  //     options
  //       ? (options = [...view.useOptions(props), ...options])
  //       : (options = view.useOptions(props))
  //   }

  //   return options
  // }

  const [activeTabKey, setActiveTabKey] = useState(!!activeTab && activeTab.key)
  const [selectedTabKey, setSelectedTabKey] = useState(
    !!selectedTab && selectedTab.key
  )
  // const [activeTabName, setActiveTabName] = useState(
  //   !!activeTab && activeTab.name
  // )

  const [tabsArray] = useState(dashboardTabsArray)

  let tab, options, customInputs
  if (activeTab && activeTab.key) tab = view.tabs[activeTab && activeTab.key]
  if (tab && tab.useOptions) {
    const tabOptions = tab.useOptions(props)
    options = tabOptions.options
    customInputs = tabOptions.customInputs
  }

  if (view.useOptions) {
    options
      ? (options = [...view.useOptions(props), ...options])
      : (options = view.useOptions(props))
  }

  const header = useRef()
  const content = useRef()
  const sidebar = useRef()

  /*============== Tabs =======================*/

  const switchTab = (tabKey) => {
    if (activeTabKey !== tabKey) {
      setSelectedTabKey(tabKey)
    }
    // IF HOME, then redirect to HOME
  }

  const updateActiveTab = (tab) => {
    const { onTabChange, tabs } = props

    if (onTabChange) onTabChange(tabs, tab)

    setActiveTabKey(tab)
  }

  useEffect(() => {
    if (redirect && action === 'PUSH') {
      history.push(redirect)
    }
  }, [redirect])

  return (
    <div className={styles.viewContainer}>
      <div className={styles.header} ref={header}>
        <Header
          {...props}
          options={options}
          customInputs={customInputs}
          title={title}
          view={view}
          iconClick={iconClick}
        />
      </div>
      <div className={styles.container}>
        <div ref={sidebar} className={styles.sidebar}>
          <SideNavBar
            switchTab={switchTab}
            selectedTabKey={selectedTabKey}
            tabsArray={tabsArray}
          />
        </div>
        <div ref={content} className={styles.content}>
          <Content
            {...props}
            view={view}
            switchTab={switchTab}
            updateActiveTab={updateActiveTab}
            activeTabKey={activeTabKey}
            selectedTabKey={selectedTabKey}
            tabsArray={tabsArray}
          />
        </div>
      </div>
    </div>
  )
}
