import React from 'react'
import { PitchesState } from '../../../../metrics_server/pitches/types'
import { ToggleModal } from '../../../../ui/modal/types'
import { Card } from '../../../CardList/CardList'
import { SideBarView } from '../../Views.types'

export interface ContentProps {
  toggleModal: ToggleModal
  pitches: PitchesState
  inView: string
  views: SideBarView[]
  setRedirect: (pathname: string) => void
}

export function Content(props: ContentProps) {
  const { inView, views, setRedirect } = props
  /*========== Render ===========*/

  const renderSettingCards = (settingView, inView, index) => {
    return (
      <div key={index} id={settingView.id}>
        <Card item={settingView} col={12}>
          <settingView.Content
            {...settingView}
            setRedirect={setRedirect}
            {...props}
          />
        </Card>
      </div>
    )
  }

  return (
    <div>
      {views.map((settingView, index) => {
        return renderSettingCards(settingView, inView, index)
      })}
    </div>
  )
}
