import React from 'react'
import * as Sentry from '@sentry/react'

import { connect } from 'react-redux'
import _ from 'lodash'

import * as errorActions from '../../ui/error/actions'
import { SetError } from '../../ui/error/types'

const actions = _.assign({}, errorActions)

interface DispatchProps {
  setError: SetError
}

interface OwnProps {
  children: React.ReactNode
}

type ErrorBoundaryProps = OwnProps & DispatchProps

const ErrorBoundary = ({ children, setError }: ErrorBoundaryProps) => {
  const errorHandler: (
    error: Error,
    componentStack: string,
    eventId: string
  ) => void = (errorData) => {
    setError({
      message: `Unexpected error occurred. You will be redirected to the home page`,
      error: errorData,
      type: 'fatal',
      proceed: () => {
        window.location.reload()
      }
    })
  }

  return (
    <Sentry.ErrorBoundary onError={errorHandler}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default connect<null, DispatchProps, OwnProps>(
  null,
  actions
)(ErrorBoundary)
