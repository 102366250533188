import React from 'react'
import { FormattedDevice } from '../../../metrics_server/hardware/types'
import { Strack } from '../Strack.types'
import styles from './StrackAnchors.module.scss'
import { StrackAnchor } from './StrackAnchor/StrackAnchor'
import { useFormattedHardware } from '../../../metrics_server/hardware/hooks'

export interface StrackAnchorsProps {
  strack: Strack
}

export const StrackAnchors = ({ strack }: StrackAnchorsProps) => {
  const { anchorSetup } = strack
  const formattedHardware = useFormattedHardware()

  const getAnchorCanvasPosition = (pitchAnchor, strack) => {
    return strack.getCanvasCoordinate(
      null,
      pitchAnchor.pos.x,
      pitchAnchor.pos.y
    )
  }

  const renderAnchor = (device: FormattedDevice, strack) => {
    const pitchAnchor = anchorSetup.anchorConfig[device.id]

    if (pitchAnchor && device) {
      const anchorCanvasPosition = getAnchorCanvasPosition(pitchAnchor, strack)
      let side = 'top'
      if (pitchAnchor.pos.y > 35) side = 'bottom'
      return (
        <div
          key={device.id}
          style={{
            top: `${anchorCanvasPosition.scaleY}px`,
            left: `${anchorCanvasPosition.scaleX}px`
          }}
          className={styles.anchorPosition}
        >
          <StrackAnchor key={device.id} side={side} anchor={device} />
          <div className={styles.anchorMarker}>
            {device.status.value === 'online' && (
              <React.Fragment>
                <div className={styles.anchorOnlineRing}></div>
                <div className={styles.anchorOnlineRing}></div>
                <div className={styles.anchorOnlineRing}></div>
              </React.Fragment>
            )}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className={styles.anchorOverlay}>
      {strack &&
        formattedHardware.types.anchor.devices.list.map((anchor) =>
          renderAnchor(anchor, strack)
        )}
    </div>
  )
}
