import React, { useState } from 'react'
import { Team } from '../../../metrics_server/teams/types'
import TeamFormGeneric from '..//../../components/Forms/TeamForm/TeamForm'
import { isLocal } from '../../../metrics_server/env'
import { OrganisationAndTeamForms } from '../form_config'

const validate = (values: any) => {
  const errors = {
    teamName: undefined
  }
  if (!values.teamName) {
    errors.teamName = 'Required'
  }
  return errors
}

export interface TeamFormProps {
  team?: Team
  change: (field: string, value: any) => void
  newTeam: boolean
  isSetup: boolean
  isNewOrg: boolean
  switchForm: (form: OrganisationAndTeamForms) => void
  goBack: () => void
  handleSecondaryBtn: () => void
  joinTeam: (values, hideForm) => void
  createTeam: (values, hideForm) => void
}

export const TeamForm = (props: TeamFormProps) => {
  const { newTeam, isSetup, isNewOrg, switchForm, goBack } = props

  const [loading, setLoading] = useState(false)

  const handleSecondaryBtn = () => {
    if (isLocal) {
      goBack()
    } else if (isSetup && isNewOrg) {
      switchForm(OrganisationAndTeamForms.joinTeam)
    } else {
      switchForm(OrganisationAndTeamForms.joinTeam)
    }
  }

  const hideForm = () => {
    props.switchForm(null)
  }

  const handleSubmit = (values) => {
    setLoading(true)
    props.createTeam(values, hideForm)
  }
  return (
    <TeamFormGeneric
      newTeam={newTeam}
      handleProceed={handleSubmit}
      handleSecondaryBtn={handleSecondaryBtn}
      loading={loading}
    />
  )
}
