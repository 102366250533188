export interface SubSessionsState {
  items: SubSession[]
}

export type SubSession = {
  id: string
}

export const GET_SUB_SESSIONS = 'GET_SUB_SESSIONS'
export const CREATE_SUB_SESSION = 'CREATE_SUB_SESSION'
export const DELETE_SUB_SESSION = 'DELETE_SUB_SESSION'
export const UPDATE_SUB_SESSION = 'UPDATE_SUB_SESSION'
