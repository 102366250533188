import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getDrillsRequest,
  updateDrillRequest,
  // deleteDrillRequest,
  addPlayerToDrillRequest,
  removePlayerFromDrillRequest,
  setPlayerBibInDrillRequest,
  startDrillRequest,
  stopDrillRequest,
  getDrillPlayerBreakdownRequest,
  createDrill
} from './api'

import {
  startLoading,
  stopLoading,
  setDrills,
  // removeDrill,
  updateDrill,
  updateDrillPlayerBreakdowns,
  addDrill,
  selectDrill
} from './slice'
import { handleAPIError, setError } from '../../ui/error/actions'
import {
  Drill,
  PlayerBib,
  UpdateDrillRequestBody,
  UpdatePlayerInDrillRequestBody
} from './types'
import {
  FormattedSession,
  RawSessionData,
  SessionsState
} from '../sessions/types'
import { getPlayerIdsInSession } from '../sessions/functions'
import { getSport } from '../sports/functions'
import { getDefaultRegion } from './functions'
import { UnitSystemTypeValues } from '../units/types'

export type CreateNewDrillArgs = {
  quickStart: boolean
  name: string
}

export const createNewDrill = createAsyncThunk(
  'createNewDrill',
  async (
    { quickStart, name }: { quickStart: boolean; name: string },
    { dispatch, getState }: { dispatch: any; getState: any }
  ) => {
    const { drills, sessions } = getState()

    // Find previous drill //
    const prevDrill = drills.drills[drills.drills.length - 1] as Drill

    // Generate playerIds from session //
    const session = sessions.rawData[sessions.selectedId] as RawSessionData
    const sport = getSport(session.sportType)
    const playerIds = getPlayerIdsInSession(session)

    // Generate the player bibs for the new drill using playerIds and previous drills bib setup //
    const playerBibs: PlayerBib = {}
    playerIds.forEach((playerId) => {
      playerBibs[playerId] = prevDrill?.playerBibs[playerId] || 1
    })

    const body = {
      name,
      sessionId: session.id,
      region: getDefaultRegion(session.pitch.pitch.coordinates, sport),
      playerBibs,
      bibs: sport.props.features.bibs,
      bibTeamDirection: [2, 3]
    }

    dispatch(startLoading())

    try {
      const response = await createDrill(body)
      // Add the new drill to the store
      dispatch(addDrill(response.data))
      // If quickStart is true, start the drill immediately
      if (quickStart) {
        dispatch(startDrill(response.data.id))
      }
      dispatch(selectDrill(response.data.id))
      dispatch(stopLoading())
      return response.data
    } catch (error) {
      dispatch(handleAPIError(error, 'errorDetail'))
      dispatch(stopLoading())
      return error
    }
  }
)

export const getDrillsForSession = createAsyncThunk(
  'getDrillsForSession',
  async (sessionId: string, { dispatch }) => {
    dispatch(startLoading())
    try {
      const response = await getDrillsRequest(sessionId)
      dispatch(setDrills(response.data))
    } catch (error) {
      dispatch(operationFailed(error, 'Failed to get Session Drills'))
      dispatch(stopLoading())
    }
  }
)

export const updateExistingDrill = createAsyncThunk(
  'updateExistingDrill',
  async (data: UpdateDrillRequestBody, { dispatch }) => {
    dispatch(startLoading())
    try {
      const response = await updateDrillRequest(data)
      dispatch(updateDrill(response.data))
    } catch (error) {
      dispatch(operationFailed(error, 'Failed to Update Drill.'))
      dispatch(stopLoading())
    }
  }
)

// export const deleteExistingDrill = createAsyncThunk(
//   'deleteDrill',
//   async (data: { sessionId: string; drillId: string }, { dispatch }) => {
//     dispatch(startLoading())
//     try {
//       const response = await deleteDrillRequest(data)
//       dispatch(removeDrill(response.data))
//     } catch (error) {
//       dispatch(operationFailed(error, 'Failed to Delete Drill.'))
//     }
//   }
// )

// Add player to a drill?
export const addPlayerToDrill = createAsyncThunk(
  'addPlayerToDrill',
  async (data: UpdatePlayerInDrillRequestBody, { dispatch }) => {
    dispatch(startLoading())
    try {
      const response = await addPlayerToDrillRequest(data)
      dispatch(updateDrill(response.data))
    } catch (error) {
      dispatch(operationFailed(error, 'Failed to add player to drill'))
      dispatch(stopLoading())
    }
  }
)

// Remove player from a drill

export const removePlayerFromDrill = createAsyncThunk(
  'drills/removePlayerFromDrill',
  async (data: UpdatePlayerInDrillRequestBody, { dispatch }) => {
    dispatch(startLoading())
    try {
      const response = await removePlayerFromDrillRequest(data)
      dispatch(updateDrill(response.data))
    } catch (error) {
      dispatch(operationFailed(error, 'Failed to remove player drom drill'))
      dispatch(stopLoading())
    }
  }
)

// Set player bib in a drill
export const setPlayerBibInDrillThunk = createAsyncThunk(
  'setBibInDrill',
  async (data: UpdatePlayerInDrillRequestBody, { dispatch }) => {
    dispatch(startLoading())
    try {
      const response = await setPlayerBibInDrillRequest(data)
      dispatch(updateDrill(response.data))
    } catch (error) {
      dispatch(operationFailed(error, 'Failed to set player bib in drill'))
      dispatch(stopLoading())
    }
  }
)

// Start drill
export const startDrill = createAsyncThunk(
  'startDrill',
  async (id: string, { dispatch }) => {
    dispatch(startLoading())
    try {
      const response = await startDrillRequest(id)
      dispatch(updateDrill(response.data))
    } catch (error) {
      dispatch(operationFailed(error, 'Failed to start drill'))
      dispatch(stopLoading())
    }
  }
)

// Stop drill
export const stopDrill = createAsyncThunk(
  'stopDrill',
  async (id: string, { dispatch }) => {
    dispatch(startLoading())
    try {
      const response = await stopDrillRequest(id)
      dispatch(updateDrill(response.data))
    } catch (error) {
      dispatch(operationFailed(error, 'Failed to stop drill'))
      dispatch(stopLoading())
    }
  }
)

// Get player breakdown for a drill
export const getPlayerBreakdownForDrill = createAsyncThunk(
  'getPlayerBreakdownForDrill',
  async (
    {
      drillId,
      unitSystemValue
    }: { drillId: string; unitSystemValue: UnitSystemTypeValues },
    { dispatch }
  ) => {
    try {
      const response = await getDrillPlayerBreakdownRequest({
        drillId,
        unitSystemValue
      })
      dispatch(
        updateDrillPlayerBreakdowns({
          drillId,
          rawPlayerBreakdowns: response.data
        })
      )
    } catch (error) {
      dispatch(
        operationFailed(error, 'Failed to get player breakdown for drill')
      )
    }
  }
)

export const operationFailed = (error: any, message: string) => {
  return setError({
    message:
      error.response && error.response.data && error.response.data.detail
        ? error.response.data.detail
        : message
  })
}
