import {
  AUTH_ERROR,
  AUTH_LOADING,
  SET_FORGOT_PASSWORD,
  SET_USER,
  SIGNED_IN
} from './action_types'

import {
  updateUserInLocalStorage,
  updateTokenInLocalStorage
} from './functions'

import { AuthenticationState } from './types'

export function authenticationReducer(
  state: AuthenticationState = {
    error: null,
    isLoading: false,
    signedIn: false,
    forgotPassword: false
  },
  action
) {
  switch (action.type) {
    case SET_USER:
      let user = action.payload.userData

      return {
        ...state,
        data: user
      }
    case SET_FORGOT_PASSWORD:
      let forgotPassword = action.payload
      return {
        ...state,
        forgotPassword
      }
    case AUTH_ERROR:
      return { ...state, error: action.payload }
    case AUTH_LOADING:
      return { ...state, isLoading: action.payload }
    case SIGNED_IN:
      return { ...state, signedIn: action.payload }
    default:
      return state
  }
}
