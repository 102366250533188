import React from 'react'
import { sportableColors } from '../../../const'

interface TagIconTypes {
  color?: string
  scale?: number
  strokeWidth?: number
  active?: boolean
}

export const TagIcon: React.FC<TagIconTypes> = ({
  color = sportableColors.colors.sportableGreyDark,
  scale = 0.4,
  strokeWidth,
  active
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${28 * scale}`}
      height={`${42 * scale}`}
      viewBox='0 0 28 42'
    >
      <g fill='none' fillRule='evenodd' transform='translate(1 1)'>
        <path
          stroke={color}
          strokeWidth={strokeWidth || 2}
          strokeOpacity={active ? '1' : '0.3'}
          d='M9.463 0h7.074a8.571 8.571 0 0 1 8.568 8.33l.68 24.032A7.429 7.429 0 0 1 18.358 40H7.64a7.429 7.429 0 0 1-7.425-7.638L.895 8.329A8.571 8.571 0 0 1 9.463 0z'
        />
        <ellipse
          cx='13'
          cy='31.429'
          stroke={color}
          strokeWidth={strokeWidth || 2}
          strokeOpacity={active ? '1' : '0.3'}
          rx='3.391'
          ry='3.429'
        />
        <g fill={color} fillOpacity={active ? '1' : '0.3'}>
          <path d='M6.3 13.699c0-.133.172-.175.408-.17.273.001.374.112.372.22h.357c-.004-.333-.308-.549-.754-.549-.431 0-.722.196-.722.517 0 .674 1.184.317 1.184.622 0 .14-.201.18-.462.178-.281-.005-.398-.115-.396-.22h-.355c-.006.33.304.548.78.548.46 0 .77-.196.77-.519 0-.659-1.181-.333-1.181-.627M8.64 13.881h-.606v-.333h.606c.117 0 .172.052.172.169 0 .117-.055.164-.172.164zm.05-.645H7.7v1.573h.335v-.613h.653c.263 0 .458-.16.458-.48 0-.262-.175-.48-.458-.48zM10.887 13.856l-.002-.308h.686c.117 0 .172.048.172.16 0 .099-.053.148-.172.148h-.684zm.612.191h.14c.264 0 .44-.143.44-.395 0-.24-.157-.416-.44-.416h-1.085v1.573h.335l-.002-.652h.355c.228 0 .294.128.548.652h.401c-.226-.512-.364-.737-.692-.762zM13.787 13.236h-1.605v.328h.634v1.245h.335v-1.245h.636v-.328M14.212 14.182l.312-.63.312.63h-.624zm.123-.946l-.824 1.573h.391l.148-.299h.946l.148.299h.394l-.825-1.573h-.378zM16.681 14.479h-.665l-.002-.324h.667c.105 0 .172.052.172.164 0 .115-.067.16-.172.16zm-.043-.915c.105 0 .172.04.172.153 0 .11-.067.148-.172.148h-.624l-.002-.3h.626zm.129.445c.23-.016.378-.126.378-.357 0-.24-.156-.416-.439-.416H15.68v1.573h1.07c.265 0 .44-.166.44-.418 0-.245-.167-.375-.422-.382zM17.736 13.236h-.335v1.573h1.242v-.328h-.907v-1.245M18.776 14.809h1.33v-.328h-.995v-.317h.977v-.308h-.977v-.292h.994v-.328h-1.329v1.573' />
          <path d='M9.903 13.319l-.005.005a.135.135 0 0 1-.092.037.135.135 0 0 1-.097-.042.666.666 0 0 1-.176-.47.666.666 0 0 1 .18-.475.135.135 0 0 1 .093-.039.135.135 0 0 1 .097.042.666.666 0 0 1 .176.471c0 .194-.07.366-.176.47zm.464-.544a.999.999 0 0 0-.275-.652l-.006-.006h-.001l-.001-.002A.404.404 0 0 0 9.806 12c-.101 0-.2.041-.278.115v.001l-.002.001-.005.005a.999.999 0 0 0-.276.653H5.652v.149h3.594c.016.257.113.49.274.649l.01.01a.404.404 0 0 0 .276.113.404.404 0 0 0 .287-.123.999.999 0 0 0 .274-.65h9.979v-.148h-9.98z' />
        </g>
      </g>
    </svg>
  )
}
