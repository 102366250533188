import React from 'react'
import { sportableColors } from '../../../const'
import styles from './NotesIcon.module.scss'

export interface NotesIconProps {
  color?: string
  scale?: number
  handleClick?: () => void
}

export const NotesIcon = ({
  color = sportableColors.colors.sportableGreyDark,
  scale = 1,
  handleClick
}: NotesIconProps) => {
  return (
    <div className={styles.iconContainer} onClick={handleClick}>
      <svg
        width={`${34 * scale}`}
        height={`${41 * scale}`}
        viewBox='0 0 34 41'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21.3548 2L32 12.4359M7.28387 11.9615H15.7032M7.28387 32.8523H12.7323M7.28387 17.1795H24.0935M7.28387 22.4069H24.0935M7.28387 27.6344H24.0935M32 12.4359H23.2903C22.777 12.4359 22.2847 12.236 21.9217 11.8802C21.5588 11.5243 21.3548 11.0417 21.3548 10.5385V2H3.93548C3.42216 2 2.92986 2.19991 2.56689 2.55575C2.20392 2.91158 2 3.3942 2 3.89744V37.1026C2 37.6058 2.20392 38.0884 2.56689 38.4443C2.92986 38.8001 3.42216 39 3.93548 39H30.0645C30.5778 39 31.0701 38.8001 31.4331 38.4443C31.7961 38.0884 32 37.6058 32 37.1026V12.4359Z'
          stroke={color}
          strokeWidth='2.08333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}
