import React from 'react'
import { Anchors } from '../../../../metrics_server/pitches/types'
import { PlayersState } from '../../../../metrics_server/players/types'
import { Team } from '../../../../metrics_server/teams/types'
import { StrackPitch } from '../../../Strack/Strack.types'
import { StrackCanvas } from '../../../Strack/StrackContainer/StrackCanvas'
import StrackContainer from '../../../Strack/StrackContainer/StrackContainer'
import { StrackStandard } from '../../../Strack/StrackContainer/StrackStandard'
import { DashboardProps, Tab } from '../Dashboard.types'
import { TabContentWrapper } from './TabContentWrapper'

interface ContentProps {
  view: DashboardProps
  tabsArray: Tab[]
  activeTabKey: string
  selectedTabKey: string
  switchTab: (tabName: string) => void
  updateActiveTab: (tabName: string) => void
  animationComplete?: boolean
  playersSessions?: []
  pitch?: StrackPitch
  anchorConfig?: Anchors
  team?: Team
  teamB?: Team
  players?: PlayersState
  hiddenCanvases?: string[]
}

export function Content(props: ContentProps) {
  const { tabsArray, view } = props

  const {
    activeTabKey,
    selectedTabKey,
    updateActiveTab,
    animationComplete,
    playersSessions,
    pitch,
    anchorConfig,
    team,
    teamB,
    players,
    hiddenCanvases = []
  } = props

  return (
    <React.Fragment>
      <StrackContainer padding={0} hiddenCanvases={hiddenCanvases}>
        {tabsArray ? (
          tabsArray.map((tab, index) => {
            if (tab.strack) {
              return (
                <StrackCanvas
                  {...props}
                  key={index}
                  active={activeTabKey === tab.key}
                  selected={selectedTabKey === tab.key}
                  canvasId={tab.strack.options.canvasId}
                  strackOptions={{
                    playersSessions,
                    pitch,
                    anchorConfig,
                    team,
                    teamB,
                    players,
                    ...tab.strack.options
                  }}
                  canvasStyle={tab.strack.canvasStyle}
                >
                  <TabContentWrapper
                    active={activeTabKey === tab.key}
                    selectedTabKey={selectedTabKey}
                    updateActiveTab={updateActiveTab}
                  >
                    <tab.Content
                      {...props}
                      active={activeTabKey === tab.key}
                      activeTabKey={activeTabKey}
                    />
                  </TabContentWrapper>
                </StrackCanvas>
              )
            } else {
              return (
                <StrackStandard
                  active={activeTabKey === tab.key}
                  selected={selectedTabKey === tab.key}
                  key={index}
                >
                  <TabContentWrapper
                    active={activeTabKey === tab.key}
                    selectedTabKey={selectedTabKey}
                    updateActiveTab={updateActiveTab}
                  >
                    <tab.Content
                      {...props}
                      active={activeTabKey === tab.key}
                      activeTabKey={activeTabKey}
                    />
                  </TabContentWrapper>
                </StrackStandard>
              )
            }
          })
        ) : (
          <view.Content {...props} animationComplete={animationComplete} />
        )}
      </StrackContainer>
    </React.Fragment>
  )
}
