import {
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS_HISTORY,
  CLEAR_NOTIFICATION_IN_HISTORY,
  TOGGLE_NOTIFICATIONS
} from './types'

export function addNotification(notification) {
  const timeNow = new Date().getTime() / 1000
  const updatedNotification = { ...notification, arrivalTime: timeNow }

  return (dispatch) => {
    dispatch({
      type: ADD_NOTIFICATION,
      payload: updatedNotification
    })
    setTimeout(() => {
      dispatch(clearNotification(updatedNotification))
    }, 4000)
  }
}

export function clearNotifications() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_NOTIFICATIONS
    })
  }
}

export function clearNotification(notification) {
  return (dispatch) => {
    dispatch({
      type: CLEAR_NOTIFICATION,
      payload: notification
    })
  }
}

export function clearNotificationsHistory() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_NOTIFICATIONS_HISTORY
    })
  }
}

export function clearNotificationInHistory(notification) {
  return (dispatch) => {
    dispatch({
      type: CLEAR_NOTIFICATION_IN_HISTORY,
      payload: notification
    })
  }
}

export function toggleNotifications(bool) {
  return {
    type: TOGGLE_NOTIFICATIONS,
    payload: bool
  }
}
