import { Route } from '../../App/App.types'
import { DashboardProps } from '../../components/Views/Dashboard/Dashboard.types'
import { MainProps } from '../../components/Views/Main/Main.types'
import { ViewType } from '../../components/Views/Views.types'
import { SessionContainer } from './Container'
import { useSessionHeaderProps } from './header'
import { useSessionOptions } from './options'
import { useSessionViewStrackProps } from './strack'

import { sessionTabConfig, useSessionTabConfig } from './tab_config'

export const path = '/session'

export const matchTrackerSessionRoute: Route<MainProps> = {
  path,
  view: {
    type: ViewType.MAIN,
    name: 'Session',
    tabs: sessionTabConfig,
    Container: SessionContainer,
    useHeaderProps: useSessionHeaderProps,
    useTabConfig: useSessionTabConfig,
    useStrackProps: useSessionViewStrackProps,
    useOptions: useSessionOptions
  }
}

export const remoteDashboardSessionRoute = {
  path,
  view: {
    type: ViewType.DASHBOARD,
    name: 'Session',
    tabs: sessionTabConfig,
    Container: SessionContainer,
    useHeaderProps: useSessionHeaderProps,
    useTabConfig: useSessionTabConfig
  } as DashboardProps
}
