import { SET_SUCCESS } from './types'

import { SetSuccess } from './types'

export const setSuccess: SetSuccess = (success) => {
  return {
    type: SET_SUCCESS,
    payload: success
  }
}
