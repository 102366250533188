import React, { useState } from 'react'

import requireAuth from '../ui/authentication/hoc'

import { CustomRoute } from './Route'

import { Switch, Route, Redirect } from 'react-router-dom'

// Types
import { Routes } from './App.types'

interface CustomRoutesProps {
  appRoutes: Routes
  landing: string
}

export function CustomRoutes(props: CustomRoutesProps) {
  const { appRoutes, landing } = props
  const [connectedRoutesArray] = useState(Object.values(appRoutes))

  function renderCustomRoute(route, props: CustomRoutesProps) {
    return (
      <Route
        key={route.path}
        exact
        path={route.path}
        render={(reactRouterProps) => {
          return <CustomRoute {...props} {...reactRouterProps} route={route} />
        }}
      />
    )
  }

  return (
    <Switch>
      {connectedRoutesArray.map((route, index) =>
        renderCustomRoute(route, props)
      )}
      <Redirect to={landing} />
    </Switch>
  )
}
