import { useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from '../../store/hooks'
import { usePitches } from '../pitches/hooks'
import WebSocketController from '../../utils/websockets'
import { WS_ROOT_URL } from '../../const'
import { RawPackets } from './types'

export const useHardware = () => {
  const hardware = useAppSelector((state) => state.hardware)
  return hardware
}

export const useFormattedHardware = () => {
  const hardware = useHardware()
  const pitches = usePitches()

  return hardware.formattedHardware
}

export const useBallInPlay = () => {
  const formattedHardware = useFormattedHardware()

  const ballInPlay = useMemo(() => {
    return formattedHardware.types['ball'].devices.list.find(
      (item) => item.isInPlay
    )
  }, [formattedHardware])

  return { ballInPlay }
}

export const useRawWebsocketPacketCapture = () => {
  const [onlinePacketCapture, setOnlinePacketCapture] = useState<RawPackets>({})

  // Set up ref for interval
  const tagCheck = useRef(null)

  // Listen to raw websocket and update strack buffer once strack has been successfully initiated
  useEffect(() => {
    const onlinePacketCapture = {},
      offlinePacketCapture = {}

    WebSocketController.connectWebSocket(
      WS_ROOT_URL,
      'raw',
      (data) => {
        if (!onlinePacketCapture[data.id]) {
          delete offlinePacketCapture[data.id]
        }
        onlinePacketCapture[data.id] = data
        const timeNow = new Date().getTime() / 1000
        onlinePacketCapture[data.id].packetArrivalTime = timeNow
      },
      'raw-packet-capture'
    )

    tagCheck.current = setInterval(() => {
      for (const tagId in onlinePacketCapture) {
        const timeNow = new Date().getTime() / 1000
        const tag = onlinePacketCapture[tagId]
        if (timeNow - tag.packetArrivalTime > 5) {
          offlinePacketCapture[tag.id] = tag
          delete onlinePacketCapture[tag.id]
        }
      }

      setOnlinePacketCapture(onlinePacketCapture)
    }, 2000)

    return () => {
      WebSocketController.disconnectWebSocket('raw', 'raw-packet-capture')

      clearInterval(tagCheck.current)
    }
  }, [])

  return { onlinePacketCapture }
}
