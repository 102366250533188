import trophyIcon from '../../assets/img/trophy.svg'

export const useStatisticLogic = (icon: string) => {
  // icons
  const checkIcon = () => {
    if (icon === 'trophy') {
      return trophyIcon
    }
    return icon
  }
  const iconSrc = checkIcon()

  return {
    iconSrc
  }
}
