import React from 'react'
import { useToggleLogic } from './Toggle.logic'
import { CustomFieldRenderProps } from '../Fields.types'
import styles from './Toggle.module.scss'

export interface ToggleProps extends CustomFieldRenderProps {
  size?: 'table' | 'xs' | 's' | 'm' | 'l'
  isSkillTracker?: boolean
  toggleColor?: string
  input: CustomFieldRenderProps['Input']
  width?: number | string
  disabled?: boolean
}

type Input = {
  value: string | number
  onChange: (arg0: string | number) => void
}

type Option = {
  name: string
  value: string | number
}

export const Toggle: React.FC<ToggleProps> = (props) => {
  const { toggleStyle, sliderStyle, toggleItemStyle, size } =
    useToggleLogic(props)

  const renderOption = (option: Option, index: number) => {
    let isSelected = props.input.value === option.value
    if (!props.input.value && props.input.value !== 0) {
      isSelected = index === 0
    }

    const handleClick = () => {
      if (!props.disabled) {
        props.input.onChange(option.value)
      }
    }

    return (
      <div
        onClick={handleClick}
        style={toggleItemStyle}
        className={`${styles.toggle} ${isSelected ? styles.toggleActive : ''} ${
          props.disabled ? styles.disabled : ''
        } ${size}`}
        key={option.value}
      >
        {option.name}
      </div>
    )
  }

  return (
    <div
      style={{ width: props.width } || toggleStyle}
      className={styles.toggleContainer}
    >
      {props.options.map(renderOption)}
      <div style={sliderStyle} className={`${styles.toggleSlider}`}></div>
    </div>
  )
}
