import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  DataTypeConfig
} from '../../data_types'

export type SubSessionTypeFeatures = {
  disableOption: boolean
  broadcastIntegrationAvailable: boolean
}

export const defaultSubSessionTypeFeatures: SubSessionTypeFeatures = {
  disableOption: false,
  broadcastIntegrationAvailable: false
}

export const subSessionTypesConfig = {
  unknown: {
    key: 'unknown',
    name: 'Unknown',
    value: 0,
    props: {
      features: { ...defaultSubSessionTypeFeatures, disableOption: true }
    }
  },
  broadcast: {
    key: 'broadcast',
    name: 'Broadcast',
    value: 1,
    props: {
      features: {
        ...defaultSubSessionTypeFeatures,
        broadcastIntegrationAvailable: true
      }
    }
  },
  demo: {
    key: 'demo',
    name: 'Demo',
    value: 2,
    props: {
      features: {
        ...defaultSubSessionTypeFeatures
      }
    }
  },
  dataGathering: {
    key: 'dataGathering',
    name: 'Data Gathering',
    value: 3,
    props: {
      features: {
        ...defaultSubSessionTypeFeatures
      }
    }
  },
  test: {
    key: 'test',
    name: 'Test',
    value: 4,
    props: {
      features: {
        ...defaultSubSessionTypeFeatures,
        broadcastIntegrationAvailable: true
      }
    }
  },
  teamPractice: {
    key: 'teamPractice',
    name: 'Team Practice',
    value: 5,
    props: {
      features: {
        ...defaultSubSessionTypeFeatures
      }
    }
  },
  playerOnly: {
    key: 'playerOnly',
    name: 'Player Only',
    value: 6,
    props: {
      features: {
        ...defaultSubSessionTypeFeatures
      }
    }
  }
} as const

export type SubSessionTypeProps = {
  features: SubSessionTypeFeatures
}

export type SubSessionTypes = DataTypes<typeof subSessionTypesConfig>

export type SubSessionTypeKey = DataTypeKey<SubSessionTypes>

export type SubSessionTypeKeys = DataTypeKeys<SubSessionTypeKey>

export type SubSessionTypeValues = DataTypeValues<SubSessionTypeKey>

export const getSubSessionTypeGroup = (items: {
  [key in SubSessionTypeKeys]?: SubSessionTypes[key]
}) => {
  return getDataTypeGroup<
    SubSessionTypeKeys,
    SubSessionTypeValues,
    SubSessionTypes,
    SubSessionTypeProps
  >(items)
}

export type SubSessionTypeGroup = ReturnType<typeof getSubSessionTypeGroup>

export type SubSessionTypeConfig = DataTypeConfig<
  SubSessionTypeKeys,
  SubSessionTypeValues,
  SubSessionTypeProps
>

export type SubSessionType = SubSessionTypeGroup['items'][SubSessionTypeKeys]

export const subSessionTypes = getSubSessionTypeGroup(subSessionTypesConfig)

export const isSubSessionType = generateTypeChecks<
  SubSessionTypeKeys,
  SubSessionTypeGroup
>(subSessionTypes)
