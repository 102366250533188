import { useMemo } from 'react'
import { useSessionStrackProps } from '../../components/Strack/hooks'
import { useSessions } from '../../metrics_server/sessions/hooks'
import { MatchTrackerSessionTabs } from './tab_config'
import { useChallengeArray } from '../../metrics_server/targets/hooks'
import { ViewStrackProps } from '../../components/Views/Views.types'

export const useSessionViewStrackProps = (view): ViewStrackProps => {
  const sessions = useSessions()
  const strackProps = useSessionStrackProps(sessions.selectedId)

  // Targets //
  const challengeArray = useChallengeArray()
  // ======= //

  // Manage canvas visibility //
  const hiddenCanvases = useMemo(() => {
    const { tabs } = view
    const hiddenCanvases = []

    if (tabs[MatchTrackerSessionTabs.CHALLENGE] && challengeArray.length < 1) {
      hiddenCanvases.push(
        tabs[MatchTrackerSessionTabs.CHALLENGE].strack.options.canvasId
      )
    }

    if (tabs[MatchTrackerSessionTabs.DRILLS]) {
      hiddenCanvases.push(
        tabs[MatchTrackerSessionTabs.DRILLS].strack.options.canvasId
      )
    }
    return hiddenCanvases
  }, [view, challengeArray.length])
  // ============= //

  return useMemo(() => {
    return {
      enabled: true,
      ...strackProps,
      hiddenCanvases
    }
  }, [strackProps, hiddenCanvases])
}
