import React, { useState } from 'react'
import {
  useBroadcastIntegrationPlayersCheck,
  useBroadcastIntegrationSessionState
} from '../../../metrics_server/broadcast_integration/hooks'
import styles from '../SessionSetup.module.scss'
import { Toggle } from '../../../components/Forms/Fields/Toggle/Toggle'
import { useFormattedSession } from '../../../metrics_server/sessions/hooks'
import { BroadcastIntegrationTable } from '../../..//metrics_server/broadcast_integration/components/BroadcastIntegrationTable'

export const BroadcastIntegration = () => {
  const { teams } = useFormattedSession('setup')

  useBroadcastIntegrationPlayersCheck()

  const { numberOfMatchedPlayersInSession, numberOfUnmatchedPlayersInSession } =
    useBroadcastIntegrationSessionState()

  // const [showTables, setShowTables] = useState(false)
  const [selectedTeamId, setSelectedTeamId] = useState(teams.options[0].value)

  return (
    <div className={styles.broadcastIntegrationContainer}>
      <div className={styles.broadcastIntegrationOverview}>
        <h3>Overview</h3>
        <h5>Matched players: {numberOfMatchedPlayersInSession}</h5>
        <h5>Unmatched players: {numberOfUnmatchedPlayersInSession}</h5>
      </div>
      <div className={styles.broadcastIntegrationTableContainer}>
        {/* table toggle */}
        <div className={styles.broadcastIntegrationTableToggle}>
          <Toggle
            input={{
              value: selectedTeamId,
              onChange: setSelectedTeamId
            }}
            options={teams.options}
          />
        </div>
        {}
        <div className={styles.broadcastIntegrationTable}>
          <BroadcastIntegrationTable teamId={selectedTeamId} />
        </div>
      </div>
    </div>
  )
}
