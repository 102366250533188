import {
  InsightsActionType,
  InsightsActionTypes,
  LayoutTypes,
  InsightsDashboardLayout,
  InsightsTableConfig,
  InsightsCardConfig,
  InsightsState,
  ConfigTypes
} from './types'

import {
  getSelectedBroker,
  getSportscasterBaseURL
} from '../sportscaster/functions'
import { setError } from '../../ui/error/actions'
import { request, SuccessCallback } from '../../utils/request_handler'

export const {
  DELETE_INSIGHTS_LAYOUT,
  UPDATE_INSIGHTS_LAYOUT,
  SET_INSIGHTS_LAYOUTS,
  SET_SELECTED_INSIGHTS_LAYOUT,
  EDIT_INSIGHTS_LAYOUT,
  DELETE_EDITED_INSIGHTS_LAYOUT,
  SET_INSIGHTS_CONFIGS,
  UPDATE_INSIGHTS_CONFIG,
  UPDATE_INSIGHTS_TABLE_DATA,
  UPDATE_INSIGHTS_CARD_DATA,
  UPDATE_INSIGHTS_DATA_FETCH_QUEUE,
  ADD_INSIGHTS_LAYOUT_ITEM,
  REMOVE_INSIGHTS_LAYOUT_ITEM,
  UPDATE_INSIGHTS_LAYOUT_CONTENT_ID
} = InsightsActionType

export type Action<T, P> = {
  type: T
  payload: P
}

export type SetSelecteInsightsLayoutAction = Action<
  typeof SET_SELECTED_INSIGHTS_LAYOUT,
  { layoutId: string; type: LayoutTypes }
>

export function setSelectedInsightsLayout(
  layoutId: string,
  type: LayoutTypes
): SetSelecteInsightsLayoutAction {
  return {
    type: SET_SELECTED_INSIGHTS_LAYOUT,
    payload: { layoutId: layoutId, type }
  }
}

export const editInsightsLayout = (
  layout: InsightsDashboardLayout
): Action<InsightsActionTypes, InsightsDashboardLayout> => {
  return {
    type: EDIT_INSIGHTS_LAYOUT,
    payload: layout
  }
}

export const deleteEditedInsightsLayout = (
  layoutId: string
): Action<InsightsActionTypes, string> => {
  return {
    type: DELETE_EDITED_INSIGHTS_LAYOUT,
    payload: layoutId
  }
}

export const getInsightsLayouts = (userId) => {
  return (dispatch, getState) => {
    const { sportscaster } = getState()
    const selectedSportscaster = getSelectedBroker(sportscaster)
    const baseURL = getSportscasterBaseURL(selectedSportscaster)

    const success = (response) => {
      dispatch({
        type: SET_INSIGHTS_LAYOUTS,
        payload: response.data
      })
    }
    const error = (error) => {
      dispatch(
        setError({
          message: `Failed to layouts. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `api/commentator/layout/user/${userId}`,
      method: 'get',
      baseURL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export const updateInsightsLayout = (
  data: InsightsDashboardLayout,
  type: LayoutTypes,
  editedId: string
) => {
  return (dispatch, getState) => {
    const { user, sportscaster } = getState()
    data.userId = user.data.id

    const selectedSportscaster = getSelectedBroker(sportscaster)
    const baseURL = getSportscasterBaseURL(selectedSportscaster)

    const success = (response) => {
      dispatch({
        type: SET_SELECTED_INSIGHTS_LAYOUT,
        payload: { layoutId: response.data.id, type }
      })
      dispatch({
        type: UPDATE_INSIGHTS_LAYOUT,
        payload: response.data
      })
      dispatch(deleteEditedInsightsLayout(editedId))
    }
    const error = (error) => {
      dispatch(
        setError({
          message: `Failed to create / update layout. API request failed. Check log for more detail.`
        })
      )
    }

    const newData = { ...data } as any
    newData.layout = JSON.stringify(data.layout)

    const options = {
      url: `api/commentator/layout`,
      method: 'post',
      data,
      baseURL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export function addInsightsLayoutItem(
  insightsLayout: InsightsDashboardLayout,
  configId: string,
  type: ConfigTypes
) {
  return (dispatch, getState) => {
    const newInsightsLayout = { ...insightsLayout }
    const { contentMap, layout } =
      newInsightsLayout.layout.reactGridLayouts[type]
    const contentMapKeys = Object.keys(contentMap)
    const k = contentMapKeys
      .filter((key) => !isNaN(parseInt(key)))
      .map((key) => parseInt(key))
    const maxKey = Math.max(...k)
    const i = maxKey + 1 + ''
    contentMap[i] = configId

    if (type === 'tables') {
      let lowestItem = {
        y: 0,
        h: 0
      }
      if (layout.length > 0) {
        lowestItem = layout.reduce((a, b) => {
          if (a.y + a.h < b.y + b.h) return b
          return a
        })
      }
      layout.push({ i, x: 0, y: lowestItem.y + lowestItem.h, w: 1, h: 1 })
    } else {
      let lowestItem = {
        x: 0,
        w: 0
      }
      if (layout.length > 0) {
        lowestItem = layout.reduce((a, b) => {
          if (a.x + a.w < b.x + b.w) return b
          return a
        })
      }
      layout.push({
        i,
        x: lowestItem.x + lowestItem.w,
        y: 0,
        w: 1,
        h: 1,
        isResizable: false
      })
    }

    dispatch(editInsightsLayout(newInsightsLayout))
  }
}

export function removeInsightsLayoutItem(
  layoutId: string,
  itemId: string,
  type: ConfigTypes
) {
  return {
    type: REMOVE_INSIGHTS_LAYOUT_ITEM,
    payload: { layoutId, itemId, type }
  }
}

// export function setSelectedInsightsConfig(layoutId: string, type: LayoutTypes): Action<InsightsActionTypes, { layoutId: string, type: LayoutTypes }> {
//     return {
//         type: SET_SELECTED_INSIGHTS_CONFIG,
//         payload: { layoutId: layoutId, type }
//     }
// }

export type SetInsightsConfigsAction = Action<
  typeof SET_INSIGHTS_CONFIGS,
  (InsightsCardConfig | InsightsTableConfig)[]
>

export const getInsightsConfigs = (userId) => {
  return (dispatch, getState) => {
    const { sportscaster } = getState()
    const selectedSportscaster = getSelectedBroker(sportscaster)
    const baseURL = getSportscasterBaseURL(selectedSportscaster)

    const success: SuccessCallback<
      (InsightsCardConfig | InsightsTableConfig)[]
    > = (response) => {
      dispatch({
        type: SET_INSIGHTS_CONFIGS,
        payload: response.data
      })
    }
    const error = (error) => {
      dispatch(
        setError({
          message: `Failed to get configs. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `api/commentator/config/user/${userId}`,
      method: 'get',
      baseURL,
      withCredentials: true
    }
    return request<(InsightsCardConfig | InsightsTableConfig)[]>(
      options,
      success,
      error,
      dispatch
    )
  }
}

export const updateInsightsConfig = (
  data: InsightsTableConfig | InsightsCardConfig,
  layoutId
) => {
  return (dispatch, getState) => {
    const { user, insights, sportscaster } = getState()
    data.userId = user.data.id

    const selectedSportscaster = getSelectedBroker(sportscaster)
    const baseURL = getSportscasterBaseURL(selectedSportscaster)

    const success = (response) => {
      dispatch({
        type: UPDATE_INSIGHTS_CONFIG,
        payload: response.data
      })
      const layout =
        insights.layouts.editedItems[layoutId] ||
        insights.layouts.items[layoutId]
      if (!data.id) {
        dispatch(
          addInsightsLayoutItem(layout, response.data.id, data.config.type)
        )
      }
    }
    const error = (error) => {
      dispatch(
        setError({
          message: `Failed to create / update config. API request failed. Check log for more detail.`
        })
      )
    }

    const options = {
      url: `api/commentator/config`,
      method: 'post',
      data,
      baseURL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

export const requestInsightsData = (
  config: InsightsTableConfig | InsightsCardConfig,
  sessionId: string,
  type: 'table' | 'card' = 'table'
) => {
  return (dispatch, getState) => {
    const { sportscaster } = getState()

    const selectedBroker = getSelectedBroker(sportscaster)
    const baseURL = getSportscasterBaseURL(selectedBroker)

    const success = (response) => {
      let actionType = UPDATE_INSIGHTS_TABLE_DATA
      if (type === 'card') actionType = UPDATE_INSIGHTS_CARD_DATA
      dispatch({
        type: actionType,
        payload: { id: config.id, data: response.data }
      })
    }
    const error = (error) => {
      dispatch(
        setError({
          message: `Failed to create / update config. API request failed. Check log for more detail.`
        })
      )
    }

    const options = {
      url: `api/commentator/v2/${sportscaster.locationName}/${type}`,
      method: 'post',
      data: {
        ...config.config,
        sessionId
      },
      baseURL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Update data live
export function updateInsightsDataFetchQueue(data) {
  return {
    type: UPDATE_INSIGHTS_DATA_FETCH_QUEUE,
    payload: data
  }
}
