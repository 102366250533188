import React from 'react'
import FileBase64 from 'react-file-base64'
import cameraImg from '../../../../assets/img/camera.png'
import { useImageInputLogic } from './Image.logic'
import styles from './Image.module.scss'

export interface ImageInputProps {
  onImageChange: (file: any) => void
  label: string
  name: string
  meta?: Meta
  img: string
  input: Input
}

type OnChange = (e: any) => void

interface Input {
  value: string | number
  name: string
  onChange: OnChange
}

interface Meta {
  touched: boolean
  error: boolean
  initial: any
}

export const ImageInput: React.FC<ImageInputProps> = (props) => {
  const { getFiles, img } = useImageInputLogic(props)
  return (
    <div>
      <div className={styles.imgLabel}>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        {props.meta && props.meta.touched && props.meta.error && (
          <div className={styles.error}>
            <span>{props.meta.error}</span>
          </div>
        )}
      </div>
      <div className={styles.imageUploader}>
        <label className={styles.customFileUpload}>
          <img
            className={img && styles.img}
            src={!img ? cameraImg : img}
            alt=''
          />
          <FileBase64 onDone={getFiles} />
        </label>
      </div>
    </div>
  )
}
