import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import SuccessMessage from './SuccessMessage'

import { SetSuccess, SuccessState } from '../../ui/success/types'
import { ToggleModal } from '../../ui/modal/types'

import * as modalActions from '../../ui/modal/actions'
import * as successActions from '../../ui/success/actions'

const actions = _.assign({}, modalActions, successActions)

interface StateProps {
  success: SuccessState
}

interface DispatchProps {
  toggleModal: ToggleModal
  setSuccess: SetSuccess
}

export class Success extends Component<StateProps & DispatchProps> {
  componentDidUpdate() {
    const { toggleModal, setSuccess, success } = this.props
    const { message, proceed } = success
    if (message) {
      toggleModal({
        active: true,
        handleProceed: () => {
          setSuccess({})
          toggleModal({})
          if (proceed) proceed()
        },
        ChildComponent: SuccessMessage,
        className: 'modalSmall'
      })
    }
  }

  render() {
    return <noscript />
  }
}

function mapStateToProps(state) {
  return {
    success: state.success
  }
}

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  actions
)(Success)
