import { useMemo } from 'react'
import { useAppSelector } from '../../store/hooks'
import { formatPlayer } from './functions'

export const usePlayers = () => {
  const players = useAppSelector((state) => state.players)
  return players
}

export const useFormattedPlayer = (playerId) => {
  const players = usePlayers()

  return useMemo(() => {
    const player = players.rawData[playerId]

    return formatPlayer(player)
  }, [players.rawData[playerId]])
}
