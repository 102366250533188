import React, { Component } from 'react'

import styles from '../../Forms.module.scss'

type OnChange = (name: string, files: FileList, fileName: string) => void

interface FileInputProps {
  name: string
  onChange: OnChange
  label: string
  small?: boolean
  value: string
  accept?: string
}

interface FileInputState {
  fileSelected: boolean
}

class FileInput extends Component<FileInputProps, FileInputState> {
  fileInput: any
  constructor(props) {
    super(props)
    this.state = {
      fileSelected: false
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props
    if (prevProps.value && !value) {
      this.fileInput.value = ''
    }
  }

  // Callback
  setFile = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = this.props
    let files = (e.target as HTMLInputElement).files
    let value = (e.target as HTMLInputElement).value
    if (files[0]) {
      this.props.onChange(name, files, value)
      // this.props.onChange(name, files, `/${files[0].name}`)
    }
  }

  render() {
    const { fileSelected } = this.state
    const { label, name, value, small, accept } = this.props
    return (
      <div className={`${styles.narrowFormInput}`}>
        <div className={styles.imgLabel}>
          {label && (
            <label className={small ? styles.smallLabel : ''} htmlFor={name}>
              {label}
            </label>
          )}
        </div>
        <div className={styles.imageUploader}>
          <input
            style={
              small
                ? { fontSize: '13px', display: 'inline-block' }
                : { display: 'inline-block' }
            }
            ref={(input) => (this.fileInput = input)}
            type='file'
            id='selectFiles'
            onChange={this.setFile}
            accept={accept ? accept : ''}
          />
          {/* <label className={styles.customFileUpload}>
            <div className={styles.lineOne}/>
            <div className={styles.lineTwo}/>
            <br />
          </label> */}
        </div>
      </div>
    )
  }
}

export default FileInput
