import { HomeIcon } from '../../components/Icons/HomeIcon/HomeIcon'
import { DashboardIcon } from '../../components/Icons/DashboardIcon/DashboardIcon'
import { Tabs } from '../../components/Views/Dashboard/Dashboard.types'
import { useOptions } from './options'
import { SessionList } from '../ActivityList/SessionList/SessionList'

export enum CommentatorSessionTabs {
  SESSION_LIST = 'SESSION_LIST'
}

export const tabConfig: Tabs = {
  [CommentatorSessionTabs.SESSION_LIST]: {
    key: CommentatorSessionTabs.SESSION_LIST,
    name: 'Match Dashboard',
    Content: SessionList,
    useOptions: useOptions,
    Icon: HomeIcon
  }
}

export const useTabConfig = (props) => {
  const { appConfig, view, router } = props

  const tabs = { ...view.tabs }

  let initialTab = tabs[CommentatorSessionTabs.SESSION_LIST]

  return { tabs, initialTab }
}
