import { drawCircle, drawLine } from '../2D/drawing'

export function applyRugbyLeagueSettings(field, poles, dimensions) {
  field.height = dimensions.P24.y
  field.width = dimensions.P13.x - dimensions.P1.x
  field.tryLineDistance = dimensions.P12.x - dimensions.P2.x

  field.planes = {
    left: dimensions.P1.x,
    right: dimensions.P13.x,
    top: dimensions.P24.y,
    bottom: dimensions.P7.y
  }

  field.scaledTen = field.tryLineDistance / 10
  field.edges = 3

  // Set origin to base
  field.originOffsetX = 0
  field.originOffsetY = field.height / 2

  poles.height = dimensions.P32.z
  poles.width = dimensions.P33.y - dimensions.P31.y
  poles.crossbarHeight = dimensions.P31.z
  poles.diameter = 0.2

  field.color = 'transparent'
}
export function drawRugbyLeagueLines(props, color, ctx, pixelScale, view) {
  const { field, dimensions } = props

  let getCanvasCoordinate = props.getCanvasCoordinate

  if (view === '3D') {
    getCanvasCoordinate = props.get3DCanvasCoordinate
  }

  // Draw mown patches

  let numberOfVerticalPatches = 20
  let numberOfHorizontalPatches = numberOfVerticalPatches * 0.7

  let verticalPatchWidth = field.tryLineDistance / numberOfVerticalPatches
  let horizontalPatchWidth = field.height / numberOfHorizontalPatches

  let patchOrigin = dimensions.P29

  for (let i = 0; i < numberOfVerticalPatches; i++) {
    if (i % 2 === 0) {
      let scaledOrigin = getCanvasCoordinate(
        pixelScale,
        patchOrigin.x + i * verticalPatchWidth,
        patchOrigin.y,
        true
      )
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fillRect(
        scaledOrigin.scaleX,
        scaledOrigin.scaleY,
        verticalPatchWidth * pixelScale,
        field.height * pixelScale
      )
    }
  }

  for (let i = 0; i < numberOfHorizontalPatches; i++) {
    if (i % 2 === 0) {
      let scaledOrigin = getCanvasCoordinate(
        pixelScale,
        patchOrigin.x,
        patchOrigin.y - i * horizontalPatchWidth,
        true
      )
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fillRect(
        scaledOrigin.scaleX,
        scaledOrigin.scaleY,
        field.tryLineDistance * pixelScale,
        horizontalPatchWidth * pixelScale
      )
    }
  }

  // Draw Borders
  ctx.beginPath()
  ctx.setLineDash([0])
  ctx.moveTo(
    getCanvasCoordinate(pixelScale, dimensions.P1.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P1.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P30.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P30.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P18.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P18.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P13.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P13.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P1.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P1.y).scaleY
  )
  ctx.lineWidth = 3
  ctx.strokeStyle = color
  ctx.stroke()

  // Draw trylines
  ctx.moveTo(
    getCanvasCoordinate(pixelScale, dimensions.P2.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P2.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P29.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P29.y).scaleY
  )
  ctx.moveTo(
    getCanvasCoordinate(pixelScale, dimensions.P12.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P12.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P19.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P19.y).scaleY
  )

  ctx.lineWidth = 3
  ctx.strokeStyle = color
  ctx.stroke()

  // Drawing 10 yard markers and dashes

  let startingXLower = 3
  let startingXHigher = 28

  ctx.beginPath()

  for (var i = 0; i <= 8; i++) {
    let lowerPlanePoint = `P${startingXLower}`
    let higherPlanePoint = `P${startingXHigher}`

    ctx.moveTo(
      getCanvasCoordinate(pixelScale, dimensions[lowerPlanePoint].x).scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions[lowerPlanePoint].y)
        .scaleY
    )
    ctx.lineTo(
      getCanvasCoordinate(pixelScale, dimensions[higherPlanePoint].x).scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions[higherPlanePoint].y)
        .scaleY
    )

    // Draw dashes
    ctx.moveTo(
      getCanvasCoordinate(pixelScale, dimensions[lowerPlanePoint].x - 2.5)
        .scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions.P34.y).scaleY
    )
    ctx.lineTo(
      getCanvasCoordinate(pixelScale, dimensions[lowerPlanePoint].x + 2.5)
        .scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions.P34.y).scaleY
    )

    ctx.moveTo(
      getCanvasCoordinate(pixelScale, dimensions[lowerPlanePoint].x - 2.5)
        .scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions.P33.y).scaleY
    )
    ctx.lineTo(
      getCanvasCoordinate(pixelScale, dimensions[lowerPlanePoint].x + 2.5)
        .scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions.P33.y).scaleY
    )

    ctx.moveTo(
      getCanvasCoordinate(pixelScale, dimensions[lowerPlanePoint].x - 2.5)
        .scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions.P32.y).scaleY
    )
    ctx.lineTo(
      getCanvasCoordinate(pixelScale, dimensions[lowerPlanePoint].x + 2.5)
        .scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions.P32.y).scaleY
    )

    ctx.moveTo(
      getCanvasCoordinate(pixelScale, dimensions[lowerPlanePoint].x - 2.5)
        .scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions.P31.y).scaleY
    )
    ctx.lineTo(
      getCanvasCoordinate(pixelScale, dimensions[lowerPlanePoint].x + 2.5)
        .scaleX,
      getCanvasCoordinate(pixelScale, null, dimensions.P31.y).scaleY
    )

    startingXLower++
    startingXHigher--
  }

  ctx.lineWidth = 3
  ctx.strokeStyle = color
  ctx.stroke()

  // Draw poles
  drawCircle(
    dimensions.P35.x,
    dimensions.P35.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P36.x,
    dimensions.P36.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )

  drawCircle(
    dimensions.P37.x,
    dimensions.P37.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P38.x,
    dimensions.P38.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
}

export const drawPitchNumbers = (strack, ctx) => {
  const { dimensions, get3DCanvasCoordinate, canvas3DPixelScale } = strack

  let numberCanvasCoordPoints = [
    'P28',
    'P27',
    'P26',
    'P25',
    'P24',
    'P23',
    'P22',
    'P21',
    'P20'
  ]

  let numbers = [
    '1  0',
    '2  0',
    '3  0',
    '4  0',
    '5  0',
    '4  0',
    '3  0',
    '2  0',
    '1  0'
  ]

  let upperY = (dimensions.P31.y + dimensions.P32.y) / 2 - 0.5
  let lowerY = (dimensions.P33.y + dimensions.P34.y) / 2 - 0.5

  numberCanvasCoordPoints.forEach((point, i) => {
    const scaledCanvasCoord = get3DCanvasCoordinate(
      canvas3DPixelScale,
      dimensions[point].x,
      null
    )

    const scaledUpperYCoord = get3DCanvasCoordinate(
      canvas3DPixelScale,
      null,
      upperY
    )

    const scaledLowerYCoord = get3DCanvasCoordinate(
      canvas3DPixelScale,
      null,
      lowerY
    )

    ctx.textAlign = 'center'
    ctx.fillStyle = '#FFFFFF'
    ctx.strokeStyle = '#FFFFFF'
    ctx.font = '16px Mark Pro'

    ctx.fillText(numbers[i], scaledCanvasCoord.scaleX, scaledLowerYCoord.scaleY)
    ctx.fillText(numbers[i], scaledCanvasCoord.scaleX, scaledUpperYCoord.scaleY)
  })
}
