import React, { Component } from 'react'
import { CustomFieldRenderProps } from '../Fields.types'
import { StarIcon } from '../../../Icons/StarIcon/StarIcon'

import styles from './Checkbox.module.scss'

type OnClicked = (value: boolean) => void

interface Meta {
  touched: boolean
  error: boolean
  initial: any
}

interface CheckboxProps extends CustomFieldRenderProps {
  size?: string
  label?: string
  disabled?: boolean
  type?: string
}

class Checkbox extends Component<CheckboxProps> {
  // handleClick() {
  //   let { input } = this.props

  //   this.props.input.onChange(!input.value)
  // }
  handleClick() {
    const { input, onClicked } = this.props

    // Call the onClicked prop if it's provided
    if (onClicked) {
      onClicked(!input.value)
    }

    // Update the input value
    input.onChange(!input.value)
  }

  render() {
    const { size, input, disabled } = this.props

    const containerStyle = {
      margin: size === 'small' ? '0' : '10px 0'
    }
    const checkboxStyle = {
      width: size === 'small' ? '15px' : '17px',
      height: size === 'small' ? '15px' : '17px'
    }

    return (
      <div
        style={containerStyle}
        className={`checkbox-container ${disabled ? styles.disabled : ''}`}
        onClick={this.handleClick.bind(this)}
      >
        {this.props.type === 'highlight' && <StarIcon checked={input.value} />}
        {(!this.props.type || this.props.type === 'checkbox') && (
          <div style={checkboxStyle} className='checkbox'>
            <svg
              width='17px'
              height='17px'
              viewBox='0 0 15 14'
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
            >
              <desc>Created with Sketch.</desc>
              <defs></defs>
              <g
                id='Symbols'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'
              >
                <g
                  id='selected'
                  transform='translate(-4.000000, -5.000000)'
                  fillRule='nonzero'
                >
                  {input.value && (
                    <polyline
                      style={{
                        strokeDashoffset: `${0}`,
                        strokeDasharray: `100%`,
                        opacity: `${1}`
                      }}
                      id='tick'
                      stroke='#F64645'
                      strokeWidth='3'
                      points='6.5,13.5 9.5,16.5 16.5,8.5 '
                      strokeLinejoin='round'
                      strokeLinecap='round'
                    />
                  )}
                </g>
              </g>
            </svg>
          </div>
        )}
        {this.props.label && (
          <div className='checkbox-label-container'>
            <p>{this.props.label}</p>
            {this.props.meta &&
              this.props.meta.touched &&
              this.props.meta.error && <p className='checkbox-error'>*</p>}
          </div>
        )}
      </div>
    )
  }
}

export default Checkbox
