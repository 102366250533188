import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from './modalActions'
import Portal from '../Portal/Portal'
import ModalOverlay from './Overlay'
import ModalContent from './ModalContent'
import { ModalSettings } from './Modal.types'
import styles from './Modal.module.scss'
import { ModalState } from '../../ui/modal/types'

interface StateProps {
  modal: ModalSettings
}

interface DispatchProps {
  toggleModal: actions.ToggleModal
}

interface OwnProps {}

type ModalProps = StateProps & DispatchProps & OwnProps

export class Modal extends Component<ModalProps> {
  getModal(type) {
    const { modal, toggleModal } = this.props

    const handleClose = () => {
      toggleModal({})
    }

    if (!modal.handleClose) {
      modal.handleClose = handleClose
    }

    return (
      <div className={styles.modalContainer}>
        <ModalContent modal={modal} />
      </div>
    )
  }

  render() {
    const { modal, toggleModal } = this.props
    return (
      <div>
        <ModalOverlay active={modal.active} toggleModal={toggleModal} />
        <div>
          <Portal>{modal.active && this.getModal(modal.type)}</Portal>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    modal: state.modal
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  actions
)(Modal)
