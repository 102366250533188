export function drawPointTarget(
  strack,
  scale,
  target,
  selectedTarget,
  setSelectedTarget,
  updateTargetPosition,
  unitSystem
) {
  let { getCanvasCoordinate, createCanvasElement } = strack
  let scaledOrigin = getCanvasCoordinate(scale, target.x, target.y)

  let div = createCanvasElement('targetCanvas pointTarget', target.id)

  let largestScaledRadius = target.radii[2] * scale
  div.style.left = `${scaledOrigin.scaleX}px`
  div.style.top = `${scaledOrigin.scaleY}px`
  div.style.width = `${largestScaledRadius * 2}`
  div.style.transform = `translateX(-50%) translateY(-50%)`

  let innerHtml = ``

  for (var i = 0; i < target.radii.length; i++) {
    let radius = target.radii[i] * unitSystem.units.distance.conversion
    let scaledRadius = radius * scale
    innerHtml =
      innerHtml +
      `<div style="width: ${scaledRadius * 2}px; height: ${
        scaledRadius * 2
      }px" class="radii-${i} radii"></div>`
    if (i === 2) {
      innerHtml =
        innerHtml +
        `<div style="width: ${scaledRadius * 2}px; height: ${
          scaledRadius * 2
        }px" class="radii-event-area ${
          selectedTarget === target.id ? 'selectedTarget' : ''
        }" id=${target.id}></div>`
    }
  }

  div.innerHTML = innerHtml

  let el = document.getElementById(target.id)

  // Add click event listener
  if (!updateTargetPosition) {
    el.addEventListener('click', (e) => {
      setSelectedTarget(e.target.id)
    })
  }

  // Add drag and pinch listeners
  if (updateTargetPosition) {
    initTouchEvents(el, (x, y) => {
      let pitchCoords = strack.getPitchCoordinate(scale, x, y)
      updateTargetPosition(pitchCoords)
    })
  }

  target.div = div
}

export function drawAreaTarget(strack, scale, target, i) {
  let { scaleCoordinate, createCanvasElement } = strack
  let scaledOrigin = scaleCoordinate(scale, target.origin.x, target.origin.y)

  let div = createCanvasElement('targetCanvas areaTarget', target.id)

  div.style.left = `${scaledOrigin.scaleX}px`
  div.style.top = `${scaledOrigin.scaleY}px`

  div.style.width = `${target.dimensions.x * scale}px`
  div.style.height = `${target.dimensions.y * scale}px`

  // Add drag and pinch listeners
  initTouchEvents(div)

  target.div = div
}

function initTouchEvents(el, callback) {
  // Install event handlers for the pointer target

  el.onpointerdown = pointerdown_handler
  el.onpointermove = pointermove_handler

  // Use same handler for pointer{up,cancel,out,leave} events since
  // the semantics for these events - in this app - are the same.
  el.onpointerup = pointerup_handler

  // Pinch globals
  var evCache = new Array(2)
  var prevDiff = 0
  let prevDirection = 0

  // Drag globals
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0

  function pointerdown_handler(ev) {
    // The pointerdown event signals the start of a touch interaction.
    // This event is cached to support 2-finger gestures
    evCache.push(ev)
    pos3 = ev.clientX
    pos4 = ev.clientY
  }

  function stringToFloats(str) {
    var regex = /[+-]?\d+(\.\d+)?/g
    var floats = str.match(regex).map(function (v) {
      return parseFloat(v)
    })
    return floats
  }

  function pointermove_handler(ev) {
    ev.preventDefault()
    // This function implements a 2-pointer horizontal pinch/zoom gesture.
    //
    // If the distance between the two pointers has increased (zoom in),
    // the target element's background is changed to "pink" and if the
    // distance is decreasing (zoom out), the color is changed to "lightblue".
    //
    // This function sets the target element's border to "dashed" to visually
    // indicate the pointer's target received a move event.

    // Drag controller
    if (ev.pressure > 0 && !ev.shiftKey) {
      if (pos3 === 0 && pos4 === 0) return
      pos1 = pos3 - ev.clientX
      pos2 = pos4 - ev.clientY

      pos3 = ev.clientX
      pos4 = ev.clientY
      // set the element's new position:
      let container = ev.target.parentElement
      container.style.top = container.offsetTop - pos2 + 'px'
      container.style.left = container.offsetLeft - pos1 + 'px'
    }

    // Pinch controller
    if (ev.pressure > 0 && ev.shiftKey) {
      evCache[0] = evCache[1]
      evCache[1] = ev

      let direction

      if (evCache[0] && evCache[1]) {
        var curDiff = evCache[0].pageY - evCache[1].pageY
        if (curDiff > 0) {
          direction = 1
          // let width = ev.target.style.width
          // let newWidth = parseInt(width) + 1
          // let left = ev.target.style.left
          // let newLeft = parseInt(left) - 1
          // ev.target.style.width = `${newWidth}px`
          // ev.target.style.left = `${newLeft}px`
          //
          // let height = ev.target.style.height
          // let newHeight = parseInt(height) + 1
          // let top = ev.target.style.top
          // let newTop = parseInt(top) - 1
          // ev.target.style.height = `${newHeight}px`
          // ev.target.style.top = `${newTop}px`

          let transform = ev.target.style.transform
          if (transform) {
            var numArray = stringToFloats(transform)
            if (numArray[0]) {
              ev.target.style.transform = `scale(${numArray[0] + 0.05})`
            } else {
              ev.target.style.transform = 'scale(1)'
            }
          } else {
            ev.target.style.transform = 'scale(1)'
          }
        } else if (curDiff < 0) {
          direction = -1
          // let width = ev.target.style.width
          // let newWidth = parseInt(width) - 1
          // let left = ev.target.style.left
          // let newLeft = parseInt(left) + 1
          // ev.target.style.width = `${newWidth}px`
          // ev.target.style.left = `${newLeft}px`
          //
          // let height = ev.target.style.height
          // let newHeight = parseInt(height) - 1
          // let top = ev.target.style.top
          // let newTop = parseInt(top) + 1
          // ev.target.style.height = `${newHeight}px`
          // ev.target.style.top = `${newTop}px`

          let transform = ev.target.style.transform
          if (transform) {
            let numArray = stringToFloats(transform)
            if (numArray[0]) {
              ev.target.style.transform = `scale(${numArray[0] - 0.05})`
            } else {
              ev.target.style.transform = 'scale(1)'
            }
          } else {
            ev.target.style.transform = 'scale(1)'
          }
        }
      }
      if (direction < 0 && prevDirection >= 0) {
        prevDirection = -1
      } else if (direction > 0 && prevDirection <= 0) {
        prevDirection = 1
      }
    }

    // Find this event in the cache and update its record with this event
    // for (var i = 0; i < evCache.length; i++) {
    //   if (ev.pointerId == evCache[i].pointerId) {
    //     evCache[i] = ev;
    //   break;
    //   }
    // }

    // If two pointers are down, check for pinch gestures
    // if (evCache.length == 2) {
    //
    //   // Calculate the distance between the two pointers
    //   var curDiff = Math.abs(evCache[0].clientX - evCache[1].clientX);
    //
    //   if (prevDiff > 0) {
    //     if (curDiff > prevDiff) {
    //       // The distance between the two pointers has increased
    //       log("Pinch moving OUT -> Zoom in", ev);
    //       ev.target.style.background = "pink";
    //     }
    //     if (curDiff < prevDiff) {
    //       // The distance between the two pointers has decreased
    //       log("Pinch moving IN -> Zoom out",ev);
    //       ev.target.style.background = "lightblue";
    //     }
    //   }
    //
    //   // Cache the distance for the next move event
    //   prevDiff = curDiff;
    // }
  }

  function pointerup_handler(ev) {
    // Remove this pointer from the cache and reset the target's
    // background and border
    // remove_event(ev);

    let container = ev.target.parentElement

    callback(parseFloat(container.style.left), parseFloat(container.style.top))

    evCache = new Array(2)
    prevDiff = 0

    // If the number of pointers down is less than two then reset diff tracker
    // if (evCache.length < 2) {
    //   prevDiff = -1;
    // }
  }

  function remove_event(ev) {
    // Remove this event from the target's cache
    for (var i = 0; i < evCache.length; i++) {
      if (evCache[i].pointerId == ev.pointerId) {
        evCache.splice(i, 1)
        break
      }
    }
  }
}
