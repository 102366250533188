import React from 'react'
import { Button } from '../Button/Button'
import { NewPlayerIcon } from '../Icons/NewPlayerIcon/NewPlayerIcon'
import styles from './CreatePlayerPlaceholder.module.scss'

export interface CreatePlayerPlaceholderProps {
  createPlayer: (data) => any
}

export const CreatePlayerPlaceholder = ({ createPlayer }) => {
  return (
    <div id='placeholder' className={styles.placeHolderContainer}>
      <NewPlayerIcon />
      <div className={styles.placeHolderText}>
        You don’t have players in this team, let’s add some players!
      </div>
      <Button
        id='placeholder-btn'
        type='submit'
        className='btn--border btn--long'
        handleClick={createPlayer}
      >
        <h4 id='create-player'>Create player profile</h4>
      </Button>
    </div>
  )
}
