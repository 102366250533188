import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  getTypesConfigFromJson,
  Options,
  TypesJson
} from '../data_types'
import { GetMetricOptions, MetricHandler } from './metric'
import { FormattedSession } from '../sessions/types'
import { isOutcomeType } from '../outcomes/data_types'
import { UnitSystem } from '../units/types'
import { isEventTypeData } from '../events/functions'

export const metricTypesConfig = {
  dist: {
    key: 'dist',
    value: 'Distance',
    name: 'Distance',
    props: {
      hideOnDetailCard: true,
      abbr: 'Dist',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  distanceToCorner: {
    key: 'distanceToCorner',
    value: 'DistanceToCornerFlag',
    name: 'Distance to corner flag',
    props: {
      abbr: 'Dist to corner',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  hangTime: {
    key: 'hangTime',
    value: 'HangTime',
    name: 'Hang time',
    props: {
      hideOnDetailCard: true,
      abbr: 'Hang time',
      decimal: 2,
      type: 'time',
      format: 'number'
    }
  },
  timeToTarget: {
    key: 'timeToTarget',
    value: 'TimeToTarget',
    name: 'Time To Target',
    props: {
      abbr: 'TTT',
      decimal: 2,
      type: 'time',
      format: 'number'
    }
  },
  speed: {
    key: 'speed',
    value: 'Speed',
    name: 'Speed',
    props: {
      abbr: 'Speed',
      decimal: 1,
      type: 'speed',
      format: 'number'
    }
  },
  maxHeight: {
    key: 'maxHeight',
    value: 'MaxHeight',
    name: 'Max Height',
    props: {
      abbr: 'Height',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  spin: {
    key: 'spin',
    value: 'Spin',
    name: 'Spin',
    props: {
      abbr: 'Spin',
      decimal: 1,
      type: 'revs',
      format: 'number'
    }
  },
  efficiency: {
    key: 'efficiency',
    value: 'Efficiency',
    name: 'Spiral efficiency',
    props: {
      hideOnDetailCard: true,
      abbr: 'Spiral',
      decimal: 0,
      type: 'percentage',
      format: 'number'
    }
  },
  endOverEndEfficiency: {
    key: 'endOverEndEfficiency',
    value: 'EndOverEndEfficiency',
    name: 'End over end efficiency',
    props: {
      hideOnDetailCard: true,
      abbr: 'EoE',
      decimal: 0,
      type: 'percentage',
      format: 'number'
    }
  },
  groundReloadTime: {
    key: 'groundReloadTime',
    value: 'GroundReloadTime',
    name: 'Ground reload time',
    props: {
      abbr: 'Ground reload',
      decimal: 1,
      type: 'time',
      format: 'number'
    }
  },
  reloadTime: {
    key: 'reloadTime',
    value: 'ReloadTime',
    name: 'Reload time',
    props: {
      abbr: 'Reload time',
      decimal: 2,
      type: 'time',
      format: 'number'
    }
  },
  passDirection: {
    key: 'passDirection',
    value: 'PassDirection',
    name: 'Pass Direction',
    props: {
      abbr: 'Direction',
      format: 'text',
      type: null,
      options: [
        { name: 'Right', value: 'Right' },
        { name: 'Left', value: 'Left' },
        { name: 'Backward', value: 'Backward' },
        { name: 'Forward', value: 'Forward' },
        { name: 'Sideways', value: 'Sideways' }
      ]
    }
  },
  success: {
    key: 'success',
    value: 'Success',
    name: 'Success',
    props: {
      abbr: 'Success',
      bool: true,
      format: 'text',
      type: null,
      options: [
        {
          name: 'Success',
          value: true
        },
        {
          name: 'Failure',
          value: false
        }
      ]
    }
  },
  confirmed: {
    key: 'confirmed',
    value: 'Confirmed',
    name: 'Confirm',
    props: {
      abbr: 'Confirmed',
      bool: true,
      format: 'text',
      type: null,
      // tagOnClick: (callback) => callback('confirmed', true),
      options: [
        {
          name: 'Yes',
          value: true
        },
        {
          name: 'No',
          value: false
        },
        {
          name: 'Unset',
          value: null
        }
      ]
    }
  },
  deviationFromCenterOfPoles: {
    key: 'deviationFromCenterOfPoles',
    value: 'DistanceToPoleCentre',
    name: 'Distance to pole centre',
    props: {
      abbr: 'Dist to pole centre',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  lineoutDeviation: {
    key: 'lineoutDeviation',
    value: 'LineoutDeviation',
    name: 'Lineout Deviation',
    props: {
      abbr: 'Deviation',
      decimal: 2,
      type: 'distance',
      format: 'number'
    }
  },
  territorialGain: {
    key: 'territorialGain',
    value: 'TerritorialGain',
    name: 'Territorial Gain',
    props: {
      tagOnClick: (callback) => callback('territorialGain', 0),
      getTag: () => 'ZERO',
      abbr: 'Gain',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  inPitchHangTime: {
    key: 'inPitchHangTime',
    value: 'InPitchHangTime',
    name: 'In pitch hang time',
    props: {
      hideOnDetailCard: true,
      abbr: '*Hang time',
      decimal: 2,
      type: 'time',
      format: 'number',
      getValue: (event) => {
        // Check if inPitchHangTime exists, if not use hangTime //
        return event.inPitchHangTime ? event.inPitchHangTime : event.hangTime
      }
    }
  },
  inPitchDistance: {
    key: 'inPitchDistance',
    value: 'InPitchDistance',
    name: 'In pitch distance',
    props: {
      hideOnDetailCard: true,
      abbr: '*Distance',
      decimal: 1,
      type: 'distance',
      format: 'number',
      getValue: (event) => {
        // Check if inPitchDistance exists, if not use distance //
        return event.inPitchDistance ? event.inPitchDistance : event.dist
      }
    }
  },
  bouncedToTouch: {
    key: 'bouncedToTouch',
    value: 'BouncedToTouch',
    name: 'Bounced into touch',
    props: {
      abbr: '*Bounced into touch',
      type: null,
      format: 'text',
      getValue: (event) => {
        return event.bouncedToTouch ? 'Yes' : 'No'
      },
      disable: (event) => {
        if (!event.madeTouch) return true
      }
    }
  },
  bounceTouchTime: {
    key: 'bounceTouchTime',
    value: 'BounceTouchTime',
    name: 'Bounce-touch time',
    props: {
      abbr: '*Bounce-touch time',
      decimal: 1,
      type: 'time',
      format: 'number',
      disable: (event) => {
        if (!event.madeTouch || !event.bouncedToTouch) return true
      }
    }
  },
  madeTouch: {
    key: 'madeTouch',
    value: 'MadeTouch',
    name: 'Made touch',
    props: {
      abbr: '*Made touch',
      type: null,
      format: 'text',
      getValue: (event) => {
        return event.madeTouch ? 'Yes' : 'No'
      },
      disable: (event) => {
        if (!event.madeTouch) return true
      }
    }
  },
  probabilityForward: {
    key: 'probabilityForward',
    value: 'ProbabilityForward',
    name: 'Probability Forward',
    props: {
      abbr: 'Probability Fwd',
      decimal: 0,
      type: 'percentage',
      format: 'number',
      disable: (event) => {
        if (!event.forward) return true
      }
    }
  },
  forward: {
    key: 'forward',
    value: 'Forward',
    name: 'Forward Pass',
    props: {
      abbr: 'Forward Pass',
      type: null,
      format: 'text',
      options: [
        { name: 'Yes', value: true },
        { name: 'No', value: false }
      ]
    }
  },
  carryXVelocity: {
    key: 'carryXVelocity',
    value: 'CarryXVelocity',
    name: 'Carry X Velocity',
    props: {
      abbr: 'Carry X',
      decimal: 1,
      type: 'speed',
      format: 'number',
      disable: (event) => {
        if (!event.forward) return true
      }
    }
  },
  passXVelocity: {
    key: 'passXVelocity',
    value: 'PassXVelocity',
    name: 'Pass X Velocity',
    props: {
      abbr: 'Pass X',
      decimal: 1,
      type: 'speed',
      format: 'number',
      disable: (event) => {
        if (!event.forward) return true
      }
    }
  },
  relativeVelX: {
    key: 'relativeVelX',
    value: 'RelativeVelX',
    name: 'Relative Velocity',
    props: {
      abbr: 'Relative Vel',
      decimal: 1,
      type: 'speed',
      format: 'number',
      disable: (event) => {
        if (!event.forward) return true
      }
    }
  },
  lineoutDeviated: {
    key: 'lineoutDeviated',
    value: 'LineoutDeviated',
    name: 'Lineout Deviated',
    props: {
      abbr: 'Lineout Deviated',
      type: null,
      format: 'text',
      readonly: true,
      options: [
        {
          name: 'Straight',
          value: 0
        },
        {
          name: 'Deviated',
          value: 1
        }
      ]
    }
  },
  lineoutDeviationAngle: {
    key: 'lineoutDeviationAngle',
    value: 'LineoutDeviationAngle',
    name: 'Lineout Deviation Angle',
    props: {
      abbr: 'Deviation Angle',
      decimal: 1,
      type: 'angle',
      format: 'number'
    }
  },
  distanceToOppositionLine: {
    key: 'distanceToOppositionLine',
    value: 'DistanceToOppositionLine',
    name: 'Distance to opposition line',
    props: {
      abbr: 'Dist to opp line',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  positionX: {
    key: 'positionX',
    value: 'PositionX',
    name: 'Position X',
    props: {
      abbr: 'Position X',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  positionY: {
    key: 'positionY',
    value: 'PositionY',
    name: 'Position Y',
    props: {
      abbr: 'Position Y',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  throwSpeed: {
    key: 'throwSpeed',
    value: 'ThrowSpeed',
    name: 'Throw Speed',
    props: {
      abbr: 'Thr. Spd.',
      decimal: 1,
      type: 'speed',
      format: 'number'
    }
  },
  outcome: {
    key: 'outcome',
    value: 'Outcome',
    name: 'Outcome',
    props: {
      abbr: 'Outcome',
      type: null,
      format: 'text'
    }
  },
  targetPlayerId: {
    key: 'targetPlayerId',
    value: 'TargetPlayerId',
    name: 'Target Player',
    props: {
      abbr: 'Target Player',
      type: null,
      format: 'text',
      options: (event, formattedSession: FormattedSession) => {
        if (isEventTypeData.flight(event)) {
          const { teams, players } = formattedSession
          const team = teams.map[event.teamID]

          if (!team) return []

          const options = players[team.id].optionsWithNull

          const foundPlayer = options.find(
            (player) => player.value === event['targetPlayerId']
          )

          if (!foundPlayer && event['targetPlayerId']) {
            const player = players.all.map[event['targetPlayerId']]
            return [
              ...options,
              {
                value: player?.id || event['targetPlayerId'],
                name:
                  player?.nameAndNumber ||
                  'Player not found: ' + event['targetPlayerId']
              }
            ]
          } else {
            return options
          }
        }
      }
    }
  },
  toPlayerId: {
    key: 'toPlayerId',
    value: 'ToPlayerId',
    name: 'Receiver',
    props: {
      abbr: 'To Player',
      type: null,
      format: 'text',
      options: (event, formattedSession) => {
        if (isEventTypeData.flight(event)) {
          const { teams, teamsSessions, players } = formattedSession
          const teamSession = teamsSessions.map[event.teamID]
          const team = teams.map[event.teamID]

          if (!team) return []

          const oppositionTeam = teams.map[teamSession?.oppositionTeamId]

          const flightType = formattedSession.flightTypes.getTypeByValue(
            event.type
          )
          const outcome = flightType.props.outcomeTypes.getTypeByValue(
            event.outcome
          )
          // If the outcome is an interception, the opposition team is the target and set player options accordingly
          let options = players[team.id].optionsWithNull
          if (isOutcomeType.interception(outcome) && oppositionTeam) {
            options = oppositionTeam.players.optionsWithNull
          }

          const foundPlayer = options.find(
            (player) => player.value === event['toPlayerId']
          )

          if (!foundPlayer && event['toPlayerId']) {
            const player = players.all.map[event['toPlayerId']]
            return [
              ...options,
              {
                value: player?.id || event['toPlayerId'],
                name:
                  player?.nameAndNumber ||
                  'Player not found: ' + event['toPlayerId']
              }
            ]
          } else {
            return options
          }
        }
      }
    }
  },
  rotation: {
    key: 'rotation',
    value: 'rotation',
    name: 'Rotation',
    props: {
      decimal: 0,
      type: 'percentage',
      format: 'number',
      abbr: 'Rotation',
      getValue: (event) => {
        return event.endOverEndEfficiency > event.efficiency
          ? event.endOverEndEfficiency
          : event.efficiency
      },
      getTag: (event) => {
        return event.endOverEndEfficiency > event.efficiency ? 'EoE' : 'Spiral'
      }
    }
  },
  detailCardDistance: {
    key: 'detailCardDistance',
    value: 'detailCardDistance',
    name: 'Distance',
    props: {
      decimal: 1,
      type: 'distance',
      format: 'number',
      abbr: 'New Distance',
      getValue: (event) => {
        return event.dist
      },
      getTag: (event, formattedSession, unitSystem) => {
        if (isEventTypeData.flight(event)) {
          const eventType = formattedSession.flightTypes.getTypeByValue(
            event.type
          )
          const metric = eventType.props.metricTypes.items.inPitchDistance
          if (
            metric &&
            event.inPitchDistance &&
            event.inPitchDistance !== event.dist
          ) {
            const metricInfo = generateMetricTypeClass(metric)
            return metricInfo.getMetricValueWithUnits(
              event.inPitchDistance,
              unitSystem,
              event,
              formattedSession
            )
          }
        }
      }
    }
  },
  detailCardHangTime: {
    key: 'detailCardHangTime',
    value: 'detailCardHangTime',
    name: 'Hang Time',
    props: {
      decimal: 1,
      type: 'time',
      format: 'number',
      abbr: 'New Hang Time',
      getValue: (event) => {
        return event.hangTime
      },
      getTag: (event, formattedSession, unitSystem) => {
        if (isEventTypeData.flight(event)) {
          const eventType = formattedSession.flightTypes.getTypeByValue(
            event.type
          )
          const metric = eventType.props.metricTypes.items.inPitchHangTime
          if (metric && event.inPitchHangTime) {
            const metricInfo = generateMetricTypeClass(metric)
            return metricInfo.getMetricValueWithUnits(
              event.inPitchHangTime,
              unitSystem,
              event,
              formattedSession
            )
          }
        }
      }
    }
  },
  snapToPass: {
    key: 'snapToPass',
    value: 'SnapToPass',
    name: 'Snap To Pass',
    props: {
      abbr: 'Snap To Pass',
      decimal: 2,
      type: 'time',
      format: 'number'
    }
  },

  // Player metrics //
  totalPasses: {
    key: 'totalPasses',
    value: 'TotalPasses',
    name: 'Total Passes',
    props: {
      abbr: 'No. Passes',
      decimal: 0,
      type: 'count',
      format: 'number'
    }
  },
  totalPassCompletion: {
    key: 'totalPassCompletion',
    value: 'TotalPassCompletion',
    name: 'Passes Completed',
    props: {
      abbr: 'Passes Completed',
      decimal: 0,
      type: 'count',
      format: 'number'
    }
  },
  totalForwardPasses: {
    key: 'totalForwardPasses',
    value: 'TotalForwardPasses',
    name: 'Forward Passes',
    props: {
      abbr: 'No. Forward Passes',
      decimal: 0,
      type: 'count',
      format: 'number'
    }
  },
  totalBackwardPasses: {
    key: 'totalBackwardPasses',
    value: 'TotalBackwardPasses',
    name: 'Backward Passes',
    props: {
      abbr: 'No. Backward Passes',
      decimal: 0,
      type: 'count',
      format: 'number'
    }
  },
  totalSidewaysPasses: {
    key: 'totalSidewaysPasses',
    value: 'TotalSidewaysPasses',
    name: 'Sideways Passes',
    props: {
      abbr: 'No. Sideways Passes',
      decimal: 0,
      type: 'count',
      format: 'number'
    }
  },
  totalShotsAtGoal: {
    key: 'totalShotsAtGoal',
    value: 'TotalShotsAtGoal',
    name: 'Shots At Goal',
    props: {
      abbr: 'No. Shots At Goal',
      decimal: 0,
      type: 'count',
      format: 'number'
    }
  },
  averageRecycleTime: {
    key: 'averageRecycleTime',
    value: 'AverageRecycleTime',
    name: 'Average Recycle Time',
    props: {
      abbr: 'Avg Recycle Time',
      decimal: 1,
      type: 'time',
      format: 'number'
    }
  },
  totalGoalsScored: {
    key: 'totalGoalsScored',
    value: 'TotalGoalsScored',
    name: 'Goals Scored',
    props: {
      abbr: 'No. Goals Scored',
      decimal: 0,
      type: 'count',
      format: 'number'
    }
  },

  // amFooty metrics //
  averageThrowDistance: {
    key: 'averageThrowDistance',
    value: 'AverageThrowDistance',
    name: 'Avg Throw Distance',
    props: {
      abbr: 'Avg Throw Dist',
      decimal: 1,
      type: 'distance',
      format: 'number'
    }
  },
  averageSpeed: {
    key: 'averageSpeed',
    value: 'AverageSpeed',
    name: 'Average Speed',
    props: {
      abbr: 'Avg Speed',
      decimal: 1,
      type: 'speed',
      format: 'number'
    }
  },
  averageEfficiency: {
    key: 'averageEfficiency',
    value: 'AverageEfficiency',
    name: 'Avg Efficiency',
    props: {
      abbr: 'Avg Efficiency',
      decimal: 0,
      type: 'percentage',
      format: 'number'
    }
  },
  averageSpin: {
    key: 'averageSpin',
    value: 'AverageSpin',
    name: 'Avg Spin',
    props: {
      abbr: 'Avg Spin',
      decimal: 1,
      type: 'revs',
      format: 'number'
    }
  }
} as const

export type MetricTypeProps = {
  abbr?: string
  type?:
    | 'distance'
    | 'time'
    | 'speed'
    | 'revs'
    | 'percentage'
    | 'angle'
    | 'count'
  decimal?: number
  bool?: boolean
  format?: 'text' | 'number' | 'boolean'
  readonly?: boolean
  options?: Readonly<Options<any>> | Options<any> | GetMetricOptions
  disableInTrainingMode?: boolean
  name?: string
  officiatingModeOnly?: boolean
  hideOnDetailCard?: boolean
  getValue?: (rawMetrics: any) => number | string | boolean
  getTag?: (
    rawMetrics: any,
    formattedSession: FormattedSession,
    unitSystem: UnitSystem
  ) => string
  disable?: (rawMetrics: any) => boolean
  tagOnClick?: (callback) => void
}

export type MetricTypes = DataTypes<typeof metricTypesConfig>

export type MetricTypeKey = DataTypeKey<MetricTypes>

export type MetricTypeKeys = DataTypeKeys<MetricTypeKey>

export type MetricTypeValues = DataTypeValues<MetricTypeKey>

export const getMetricTypeGroup = (items: {
  [key in MetricTypeKeys]?: MetricTypes[key]
}) => {
  return getDataTypeGroup<
    MetricTypeKeys,
    MetricTypeValues,
    MetricTypes,
    MetricTypeProps
  >(items)
}

export type MetricTypeGroup = ReturnType<typeof getMetricTypeGroup>

export type MetricTypeConfig = DataTypeConfig<
  MetricTypeKeys,
  MetricTypeValues,
  MetricTypeProps
>

export const metricTypes = getMetricTypeGroup(metricTypesConfig)

export type MetricType = ReturnType<(typeof metricTypes)['getTypeByValue']>

export function generateMetricTypeClass(dataTypeConfig: MetricTypeConfig) {
  if (!dataTypeConfig) return null
  return new MetricHandler(dataTypeConfig)
}

export type MetricTypeJson = {
  key: MetricTypeKeys
  name: string
  abbr: string
  decimal: number
}

export type MetricTypesJson = TypesJson<MetricTypeKeys, MetricTypeJson>

export function getMetricTypesConfigFromJson(
  typesJson: (
    | string
    | {
        key: string
        name?: string
        abbr?: string
      }
  )[] = [],
  typesConfig: Partial<typeof metricTypesConfig> = {}
) {
  return getTypesConfigFromJson<string, typeof typesConfig>(
    typesJson,
    typesConfig,
    metricTypesConfig
  )
}
