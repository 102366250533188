import React from 'react'

const CustomTextarea = ({ input, label, style, ...rest }) => (
  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
    {label && <label>{label}</label>}
    <textarea {...input} style={{ ...style, resize: 'none' }} {...rest} />
  </div>
)

export default CustomTextarea
