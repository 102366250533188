export const SET_REDIRECT = 'SET_REDIRECT'
export const SET_INITIAL_ROUTE = 'SET_INITIAL_ROUTE'
export const SET_URL_SEARCH_PARAMS = 'SET_URL_SEARCH_PARAMS'

export interface RouterState {
  redirect: string
  prevRoute: string
  initialRoute: string
  footerRoutes: string[]
  urlSearchParams: URLSearchParams & SportableURLParams
  action: string
}
export interface SportableURLParams {
  forgot_password: string
  verify: string
}
