import React from 'react'

import { SigninForm } from './SigninForm/SigninForm'
import { SignupForm } from './SignupForm/SignupForm'
import { RequestPasswordForm } from './RequestPasswordForm/RequestPasswordForm'
import { CreatePasswordForm } from './CreatePasswordForm/CreatePasswordForm'
import { VerifyEmailForm } from './VerifyEmailForm/VerifyEmailForm'
import { ConfirmPasswordForm } from './ConfirmPasswordForm/ConfirmPasswordForm'

import { Form } from '../../components/Views/Form/Form.types'

import sportableLogo from '../../assets/img/sportable-login.svg'

export enum AuthenticationForms {
  signin = 'signin',
  signup = 'signup',
  verifyEmail = 'verifyEmail',
  createPassword = 'createPassword',
  requestPassword = 'requestPassword',
  confirmNewPassword = 'confirmNewPassword'
}

export interface AuthFormConfig {
  signin: Form
  signup: Form
  verifyEmail: Form
  createPassword: Form
  requestPassword: Form
  confirmNewPassword: Form
}

export const formConfig: AuthFormConfig = {
  [AuthenticationForms.signin]: {
    key: AuthenticationForms.signin,
    name: 'Sign in',
    Component: SigninForm,
    title: () => {
      return (
        <h1>
          Login
          <br />
          to <span>HAL0</span>
        </h1>
      )
    },
    logoImg: sportableLogo
  },
  [AuthenticationForms.signup]: {
    key: AuthenticationForms.signup,
    name: 'Sign up',
    Component: SignupForm,
    title: () => {
      return (
        <h1>
          Create <span>new account</span>
        </h1>
      )
    },
    previousFormKey: AuthenticationForms.signin
  },
  [AuthenticationForms.verifyEmail]: {
    key: AuthenticationForms.verifyEmail,
    name: 'Verify Email',
    Component: VerifyEmailForm,
    title: () => {
      return (
        <h1>
          Verify <span>email address</span>
        </h1>
      )
    },
    previousFormKey: AuthenticationForms.signin
  },
  [AuthenticationForms.createPassword]: {
    key: AuthenticationForms.createPassword,
    name: 'Create Password',
    Component: CreatePasswordForm,
    title: () => {
      return (
        <h1>
          Create your <span>password</span>
        </h1>
      )
    }
  },
  [AuthenticationForms.requestPassword]: {
    key: AuthenticationForms.requestPassword,
    name: 'Request Password',
    Component: RequestPasswordForm,
    title: () => {
      return (
        <h1>
          Confirm <span>email</span>
        </h1>
      )
    },
    previousFormKey: AuthenticationForms.signin
  },
  [AuthenticationForms.confirmNewPassword]: {
    key: AuthenticationForms.confirmNewPassword,
    name: 'Confirm Password',
    Component: ConfirmPasswordForm,
    title: () => {
      return (
        <h1>
          Confirm <span>password</span>
        </h1>
      )
    },
    previousFormKey: AuthenticationForms.signin
  }
}

export const formMatrix = [
  [
    formConfig.signin,
    formConfig.signup,
    formConfig.requestPassword,
    formConfig.createPassword
  ],
  [null, formConfig.verifyEmail, formConfig.confirmNewPassword]
]
