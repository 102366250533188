import React from 'react'
import styles from './AssignmentStatus.module.scss'
import { Pitch } from '../Icons/PitchIcon/PitchIcon'
import { SportableBall } from '../Icons/SportableBall/SportableBall'
import CellTowerIcon from '@mui/icons-material/CellTower'
import { ShirtIcon } from '../Icons/ShirtIcon/ShirtIcon'
import { TagIcon } from '../Icons/TagIcon/TagIcon'
import { useBroadcastIntegrationSessionState } from '../../metrics_server/broadcast_integration/hooks'
import {
  useFormattedSession,
  useSessionAssignmentStatus
} from '../../metrics_server/sessions/hooks'
import { sportableColors } from '../../constants/sportableColors'

export const AssignmentStatus: React.FC = () => {
  const { sport } = useFormattedSession('setup')
  const assignmentStatus = useSessionAssignmentStatus()
  const broadcastIntegrationSessionData = useBroadcastIntegrationSessionState()

  return (
    <div className={styles.container}>
      <h6 className={styles.statusText}>Setup status:</h6>
      <div className={styles.teamStatusIcons}>
        <div className={styles.logo}>
          {assignmentStatus.pitch && (
            <div id={'pitch'} className={styles.tickBox}>
              ✓
            </div>
          )}
          <Pitch scale={0.63} active={assignmentStatus.pitch} sport={sport} />
        </div>
        <div className={styles.break}>|</div>
        <div className={styles.logo}>
          {assignmentStatus.tags && (
            <div id='tag' className={styles.tickBox}>
              ✓
            </div>
          )}
          <TagIcon
            color={sportableColors.colors.sportableGreyDark}
            scale={0.66}
            active={assignmentStatus.tags}
          />
        </div>
        <div className={styles.break}>|</div>
        <div className={styles.logo}>
          {assignmentStatus.shirts && (
            <div id='shirt' className={styles.tickBox}>
              ✓
            </div>
          )}
          <ShirtIcon
            primaryColor={sportableColors.colors.sportableGrey}
            secondaryColor={sportableColors.colors.sportableGreyDark}
            id={'shirt'}
            scale={0.74}
            active={assignmentStatus.shirts}
          />
        </div>
        <div className={styles.break}>|</div>
        <div
          className={styles.logo}
          title={
            assignmentStatus.offlineBalls
              ? 'Offline balls have been selected'
              : 'Balls have been selected'
          }
        >
          {assignmentStatus.balls && !assignmentStatus.offlineBalls && (
            <div id='ball' className={styles.tickBox}>
              ✓
            </div>
          )}
          {assignmentStatus.offlineBalls && (
            <div id='ball' className={styles.tickBox}>
              ?
            </div>
          )}
          <SportableBall
            scale={0.74}
            active={assignmentStatus.balls}
            sport={sport}
          />
        </div>
        {broadcastIntegrationSessionData.isEnabled && (
          <>
            <div className={styles.break}>|</div>
            <div
              className={styles.logo}
              title={
                broadcastIntegrationSessionData.sessionIntegrationCompletion
                  .isComplete
                  ? 'Session broadcast integration is complete'
                  : broadcastIntegrationSessionData.sessionIntegrationCompletion
                      .errors
                  ? broadcastIntegrationSessionData.sessionIntegrationCompletion
                      .errors[0]
                  : ''
              }
            >
              {broadcastIntegrationSessionData.sessionIntegrationCompletion
                .isComplete && (
                <div id='ball' className={styles.tickBox}>
                  ✓
                </div>
              )}
              <CellTowerIcon />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
