import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useDrills } from '../../../metrics_server/drills/hooks'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { useAppDispatch } from '../../../store/hooks'
import {
  getDrillsForSession,
  getPlayerBreakdownForDrill
} from '../../../metrics_server/drills/thunks'
import { MqttContext } from '../../../metrics_server/mqtt/Provider'
import { usePrevious } from 'use-hooks'
import { getSelectedBroker } from '../../../metrics_server/sportscaster/functions'
import { useSportscaster } from '../../../metrics_server/sportscaster/hooks'
import { DrillsState, selectDrill } from '../../../metrics_server/drills/slice'
import { Drill } from '../../../metrics_server/drills/types'
import { updateDrillTimeLineProgressBar } from '../../../metrics_server/drills/functions'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'
import { useEvents } from '../../../metrics_server/events/hooks'

interface DrillsContextType {
  drills: DrillsState
  drillsWithColorId: Drill[]
  drillOptions: Array<{ value: string; name: string }>
  loading: boolean
  live: boolean
  hours: number
  setHours: React.Dispatch<React.SetStateAction<number>>
  sessionDuration: number
  progress: number | null
  setProgress: React.Dispatch<React.SetStateAction<number | null>>
  progressColor: string
  setProgressColor: React.Dispatch<React.SetStateAction<string>>
  timeGridHeightOffset: number
  setTimeGridHeightOffset: React.Dispatch<React.SetStateAction<number>>
  selectedDrillTime: number | null
  setSelectedDrillTime: React.Dispatch<React.SetStateAction<number | null>>
  selectedDrill: Drill | null
  setSelectedDrill: React.Dispatch<React.SetStateAction<Drill | null>>
  enableStartDrill: boolean
  setEnableStartDrill: React.Dispatch<React.SetStateAction<boolean>>
  pendingDrillId: string
  setPendingDrillId: React.Dispatch<React.SetStateAction<string>>
  pendingDrillName: string
  setPendingDrillName: React.Dispatch<React.SetStateAction<string>>
  drillsMetricsPitchEventsView: number
  setDrillsMetricsPitchEventsView: React.Dispatch<React.SetStateAction<number>>
  handleSetPendingDrillId: (value: string) => void
  handleDrillSegmentClick: (
    drillId: string
  ) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  resetDrillSelection: () => void
}

const DrillsContext = createContext<DrillsContextType | null>(null)

export const DrillsProvider = ({
  children,
  toggleCanvasVisibility,
  canvasId,
  hiddenCanvases,
  strack
}: {
  children: ReactNode
  toggleCanvasVisibility: (canvasId: string) => void
  canvasId: string
  hiddenCanvases: string[]
  strack
}) => {
  const formattedSession = useSelectedFormattedSession()
  const { id: sessionId, startTime, endTime, live } = formattedSession

  const sessionStartTime = startTime.unixSeconds
  const sessionEndTime = endTime?.unixSeconds

  // Redux //
  const {
    drills,
    drillsWithColorId,
    drillOptions,
    updateElapsedTimeOfSelectedDrill
  } = useDrills()
  const { loading } = drills
  const sportscaster = useSportscaster()

  const dispatch = useAppDispatch()

  const unitSystem = useUnitsSystem(formattedSession.sport)

  const events = useEvents()

  // Time //
  const [hours, setHours] = useState(1)
  const sessionDuration = hours * 60 * 60 // 3 hours in milliseconds
  const [progress, setProgress] = useState(null)
  const [progressColor, setProgressColor] = useState('red')

  const [timeGridHeightOffset, setTimeGridHeightOffset] = useState(30)

  // Drills state//
  const [selectedDrillTime, setSelectedDrillTime] = useState(null)
  const [selectedDrill, setSelectedDrill] = useState(null)
  const [enableStartDrill, setEnableStartDrill] = useState(false)

  const [pendingDrillId, setPendingDrillId] = useState('')
  const [pendingDrillName, setPendingDrillName] = useState(
    `Drill ${drills.drills.length}`
  )

  // View //
  const [drillsMetricsPitchEventsView, setDrillsMetricsPitchEventsView] =
    useState(0)
  const previousView = usePrevious(drillsMetricsPitchEventsView)

  // Manage Player Breakdowns //
  const mqttContext = useContext(MqttContext)
  const drillTopic = useRef(null)

  useEffect(() => {
    const drillId = drills.selectedDrillId

    if (!drillId) return

    // Fetch player metrics for selected drill
    dispatch(
      getPlayerBreakdownForDrill({ drillId, unitSystemValue: unitSystem.key })
    )

    // Get selected broker client
    const selectedBroker = getSelectedBroker(sportscaster)
    const client = selectedBroker ? mqttContext[selectedBroker.WSSHost] : null

    // Disconnect from previous drill mqtt topic
    if (client && drillTopic.current) {
      client.unsubscribe(drillTopic.current)
    }

    // Create and save new drill mqtt topic
    const newDrillTopic = `drill/${drillId}/events`
    drillTopic.current = newDrillTopic

    // Connect to drill mqtt topic
    if (client) {
      client.subscribe(newDrillTopic, (data) => {
        console.log(data, '==============drill event======================')
        // Fetch player metrics for selected drill
        dispatch(
          getPlayerBreakdownForDrill({
            drillId,
            unitSystemValue: unitSystem.key
          })
        )
      })
    }

    return () => {
      // Disconnect from drill mqtt topic
      if (client) {
        client.unsubscribe(drillTopic.current)
      }
    }
  }, [
    unitSystem,
    drills.selectedDrillId,
    sportscaster.selectedBrokerHost,
    dispatch
  ])

  useEffect(() => {
    const drillId = drills.selectedDrillId

    if (!drillId) return

    // Fetch player metrics for selected drill
    dispatch(
      getPlayerBreakdownForDrill({ drillId, unitSystemValue: unitSystem.key })
    )
  }, [events.rawData])

  useEffect(() => {
    const updateHoursBasedOnSessionLength = () => {
      const now = live ? new Date().getTime() / 1000 : sessionEndTime // Use current time if live, or end time if not
      const elapsedTime = now - sessionStartTime // Calculate elapsed time
      const sessionLengthInHours = Math.ceil(elapsedTime / 3600) // Convert to hours and round up

      // Update hours if the session length is greater
      if (sessionLengthInHours >= hours) {
        setHours(sessionLengthInHours)
      }
    }

    updateHoursBasedOnSessionLength() // Update on component mount/change

    // If the session is live, set up an interval for continuous checks
    if (live) {
      const intervalId = setInterval(updateHoursBasedOnSessionLength, 60000) // Check every minute
      return () => clearInterval(intervalId) // Cleanup on unmount
    }
  }, [live, hours, sessionStartTime, sessionEndTime])

  // Set selected Drill by selectedDrillId
  useEffect(() => {
    if (drills.selectedDrillId) {
      setSelectedDrill(
        drills.drills.find((drill) => drill.id === drills.selectedDrillId)
      )
    }
  }, [drills.selectedDrillId, drills.drills])

  useEffect(() => {
    // Check if the selected pending drill is not 'None'
    if (pendingDrillId !== '') {
      // Find the drill in drillOptions by its value
      const selectedDrill = drillOptions.find(
        (drill) => drill.value === pendingDrillId
      )
      // If found, update the drillName with the selected drill's name
      if (selectedDrill) {
        setEnableStartDrill(true)
      }
    } else {
      setEnableStartDrill(false)
    }
  }, [pendingDrillId, drillOptions])

  useEffect(() => {
    setPendingDrillName(`Drill ${drills.drills.length + 1}`)
  }, [drills.drills.length])

  // GET Drills on load
  useEffect(() => {
    dispatch(getDrillsForSession(sessionId))
  }, [sessionId, dispatch])

  // useEffect to set elapsed time of selected Drill in 00:00:00 format
  useEffect(() => {
    if (selectedDrill && !pendingDrillId) {
      let intervalId = null

      updateElapsedTimeOfSelectedDrill(selectedDrill, setSelectedDrillTime)

      updateElapsedTimeOfSelectedDrill(selectedDrill, setSelectedDrillTime)
      intervalId = setInterval(
        () =>
          updateElapsedTimeOfSelectedDrill(selectedDrill, setSelectedDrillTime),
        1000
      ) // Update every second

      return () => {
        if (intervalId) {
          clearInterval(intervalId) // Clear interval on component unmount or when selectedSegmentId changes
        }
      }
    }
  }, [selectedDrill, drills.drills, pendingDrillId])

  useEffect(() => {
    // Set new drill in strack
    if (strack) {
      strack.selectDrill(selectedDrill)
    }
  }, [selectedDrill, strack])

  useEffect(() => {
    const interval = setInterval(
      () =>
        updateDrillTimeLineProgressBar(
          formattedSession,
          sessionDuration,
          setProgress,
          setProgressColor
        ),
      1000
    )

    updateDrillTimeLineProgressBar(
      formattedSession,
      sessionDuration,
      setProgress,
      setProgressColor
    ) // Initial update

    return () => clearInterval(interval) // Cleanup on unmount
  }, [sessionStartTime, sessionDuration, sessionEndTime, formattedSession])

  // Toggle canvas visibility based on the selected view
  useEffect(() => {
    if (drillsMetricsPitchEventsView === 1) {
      toggleCanvasVisibility(canvasId)
    }

    if (previousView === 1 && drillsMetricsPitchEventsView !== 1) {
      toggleCanvasVisibility(canvasId)
    }
  }, [drillsMetricsPitchEventsView, hiddenCanvases, toggleCanvasVisibility])

  // Handlers //

  const handleSetPendingDrillId = (value) => {
    if (value === '') {
      return resetDrillSelection()
    }
    setPendingDrillId(value)
    dispatch(selectDrill(value))
    const selectedDrill = drills.drills.find((drill) => drill.id === value)
    setSelectedDrill(selectedDrill)
    setSelectedDrillTime(null)
  }

  const handleDrillSegmentClick = (drillId) => (e) => {
    e.stopPropagation()
    const selectedDrill = drills.drills.find((drill) => drill.id === drillId)
    setSelectedDrill(selectedDrill)
    dispatch(selectDrill(drillId))
    setPendingDrillId('')
  }

  const resetDrillSelection = useCallback(() => {
    setSelectedDrill(null)
    setSelectedDrillTime(null)
    setPendingDrillId('')
    dispatch(selectDrill(null))
  }, [dispatch])

  return (
    <DrillsContext.Provider
      value={{
        drills,
        drillsWithColorId,
        drillOptions,
        loading,
        hours,
        setHours,
        sessionDuration,
        progress,
        setProgress,
        progressColor,
        setProgressColor,
        timeGridHeightOffset,
        setTimeGridHeightOffset,
        selectedDrillTime,
        setSelectedDrillTime,
        selectedDrill,
        setSelectedDrill,
        enableStartDrill,
        setEnableStartDrill,
        pendingDrillId,
        setPendingDrillId,
        pendingDrillName,
        setPendingDrillName,
        drillsMetricsPitchEventsView,
        setDrillsMetricsPitchEventsView,
        handleSetPendingDrillId,
        handleDrillSegmentClick,
        resetDrillSelection,
        live
      }}
    >
      {children}
    </DrillsContext.Provider>
  )
}

export const useDrillsContext = () => useContext(DrillsContext)
