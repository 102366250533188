import { START_LOADING, STOP_LOADING } from './types'
import { StartLoadingAction, StopLoadingAction, LoadingState } from './types'

export function loadingReducer(
  state: LoadingState = [],
  action: StartLoadingAction | StopLoadingAction
) {
  let newState
  switch (action.type) {
    case START_LOADING:
      newState = state.filter((id) => id !== action.payload)
      return [...newState, action.payload]
    case STOP_LOADING:
      return state.filter((id) => id !== action.payload)
    default:
      return state
  }
}
