import { Sport, SportTypeValues, isSportType, sportTypes } from './data_types'

export const getSport = (sportType: SportTypeValues) => {
  return sportTypes.getTypeByValue(sportType)
}

export function isRugby(
  sport: Sport
): sport is
  | typeof sportTypes.items.rugbyUnion
  | typeof sportTypes.items.rugbyLeague {
  return isSportType.rugbyUnion(sport) || isSportType.rugbyLeague(sport)
}

export function isRugbyUnion(
  sport: Sport
): sport is typeof sportTypes.items.rugbyUnion {
  return isSportType.rugbyUnion(sport)
}

export function isSoccer(
  sport: Sport
): sport is typeof sportTypes.items.soccer {
  return isSportType.soccer(sport)
}

export function isAmFooty(
  sport: Sport
): sport is typeof sportTypes.items.americanFootball {
  return isSportType.americanFootball(sport)
}

export function isAFL(
  sport: Sport
): sport is typeof sportTypes.items.australianRules {
  return isSportType.australianRules(sport)
}
