import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { BroadcastIntegrationData, GeniusFixtureRoster } from './types'

export interface BroadcastIntegrationState {
  status: BroadcastIntegrationData
  disabled: boolean
  fixtureDate: Date
  data: BroadcastIntegrationData
  ignoredPlayerIds: string[]
  fetchingPlayerIds: string[]
  roster: GeniusFixtureRoster
}

export const emptyBroadcastIntegrationData: BroadcastIntegrationData = {
  currentFixture: {
    certainty: 0,
    geniusData: '',
    geniusID: '',
    geniusTrackingId: '',
    matchedAgainst: '',
    sportableId: '',
    errors: []
  },
  playerIds: {},
  teamIds: {}
}

export const emptyBroadcastIntegrationPlayerResult = {
  certainty: 0,
  geniusData: '',
  geniusID: '',
  geniusTrackingId: '',
  matchedAgainst: '',
  sportableId: '',
  errors: []
}

const initialState: BroadcastIntegrationState = {
  status: emptyBroadcastIntegrationData,
  disabled: false,
  fixtureDate: new Date(),
  data: emptyBroadcastIntegrationData,
  ignoredPlayerIds: [],
  fetchingPlayerIds: [],
  roster: null
}

const broadcastIntegrationState = createSlice({
  name: 'broadcastIntegration',
  initialState,
  reducers: {
    updateGeniusStatus: (
      state,
      action: PayloadAction<BroadcastIntegrationData>
    ) => {
      state.data = action.payload
    },
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.disabled = action.payload
    },
    setFixtureDate: (state, action) => {
      state.fixtureDate = action.payload
    },
    updateFixtureFromAPIResponse: (
      state,
      action: PayloadAction<BroadcastIntegrationData>
    ) => {
      state.data = action.payload
    },
    updatePlayersFromAPIResponse: (
      state,
      action: PayloadAction<BroadcastIntegrationData['playerIds']>
    ) => {
      for (const key in action.payload) {
        state.data.playerIds[key] =
          action.payload[key] || state.data.playerIds[key]
      }
    },
    updateIgnoredPlayerId: (state, action: PayloadAction<string>) => {
      const playerId = action.payload
      if (state.ignoredPlayerIds.includes(playerId)) {
        state.ignoredPlayerIds = state.ignoredPlayerIds.filter(
          (id) => id !== playerId
        )
      } else {
        state.ignoredPlayerIds.push(playerId)
      }
    },
    addFetchingPlayerIds: (state, action: PayloadAction<string[]>) => {
      state.fetchingPlayerIds = [...state.fetchingPlayerIds, ...action.payload]
    },
    removeFetchingPlayerIds: (state, action: PayloadAction<string[]>) => {
      state.fetchingPlayerIds = state.fetchingPlayerIds.filter(
        (id) => !action.payload.includes(id)
      )
    },
    removeBroadcastIntegrationData: (state) => {
      state.data = emptyBroadcastIntegrationData
    },
    setRoster: (state, action: PayloadAction<GeniusFixtureRoster>) => {
      state.roster = action.payload
    }
  }
})

export const {
  updateGeniusStatus,
  setDisabled,
  setFixtureDate,
  updateFixtureFromAPIResponse,
  updatePlayersFromAPIResponse,
  updateIgnoredPlayerId,
  addFetchingPlayerIds,
  removeFetchingPlayerIds,
  removeBroadcastIntegrationData,
  setRoster
} = broadcastIntegrationState.actions
export const broadcastIntegrationReducer = broadcastIntegrationState.reducer
