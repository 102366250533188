import React from 'react'
import { sportableColors } from '../../../constants/sportableColors'

export interface BackButtonProps {
  scale?: number
  iconColor?: string
  textColor?: string
  buttonText?: string
  style: React.CSSProperties
  handleClick?: () => void
}

export function BackButton({
  scale = 1,
  iconColor = sportableColors.colors.sportableGreyDark,
  textColor = sportableColors.colors.sportableGreyDark,
  buttonText = 'Back',
  style,
  handleClick
}: BackButtonProps) {
  // Create a new state to handle hover
  const [hovered, setHovered] = React.useState(false)

  // Define inline styles
  const styles = {
    button: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: '0',
      outline: 'none'
    },
    svg: {
      marginRight: '-5px'
    },
    path: {
      stroke: iconColor,
      opacity: hovered ? 1 : 0.7,
      strokeWidth: '2.25',
      strokeMiterlimit: '10'
    },
    text: {
      color: textColor,
      opacity: hovered ? 1 : 0.7,
      fontSize: '14px',
      textTransform: 'capitalize',
      paddingLeft: '10px'
    }
  }

  return (
    <div style={style} onClick={handleClick}>
      <button
        style={styles.button}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <svg
          style={styles.svg}
          width={`${31 * scale}`}
          height={`${31 * scale}`}
          viewBox='0 0 31 31'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M15.5 2C8.04688 2 2 8.04688 2 15.5C2 22.9531 8.04688 29 15.5 29C22.9531 29 29 22.9531 29 15.5C29 8.04688 22.9531 2 15.5 2Z'
            style={styles.path}
          />
          <path
            d='M18.3125 22.25L11.5625 15.5L18.3125 8.75'
            style={styles.path}
          />
        </svg>
        <span style={styles.text as any}>{buttonText}</span>
      </button>
    </div>
  )
}
