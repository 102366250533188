import { css } from '@emotion/react'
import React from 'react'
import { sportableColors } from '../../const/index'
import { useTheme } from '../../ui/config/hook'
import styles from './AddNewButton.module.scss'

export interface AddNewButtonProps {
  id?: string
  title?: string
  disabled?: boolean
  editMode?: boolean
  size?: number
  addCallBack: () => void
  horizontal?: boolean
  style?: React.CSSProperties
}

export const AddNewButton: React.FC<AddNewButtonProps> = (props) => {
  const {
    id = 'add-player',
    title,
    editMode,
    disabled,
    size = 45,
    addCallBack,
    horizontal = false,
    style
  } = props

  const theme = useTheme()
  const fillColor = theme
    ? theme.appColor
    : sportableColors.colors.sportableBlue

  if (!title && horizontal) {
    return (
      <div style={props.style}>
        <svg
          width={`${size}px`}
          height={`${size}px`}
          viewBox='0 0 128 35'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='128' height='35' rx='5' fill={fillColor} />
          <path
            d='M62.3823 23.1406V13.3111H64.6124V23.1406H62.3823ZM58.5826 19.3409V17.1108H68.4121V19.3409H58.5826Z'
            fill='white'
          />
        </svg>
      </div>
    )
  }

  return (
    <div
      style={{
        opacity: editMode || disabled ? '0.3' : '1',
        flexDirection: horizontal ? 'row' : undefined,
        ...style
      }}
      className={styles.buttonContainer}
    >
      <svg
        id={id}
        style={{ cursor: 'pointer' }}
        onClick={!editMode || !disabled ? addCallBack : undefined}
        xmlns='http://www.w3.org/2000/svg'
        width={`${size}px`}
        height={`${size}px`}
        viewBox='0 0 50 50'
      >
        <g fill='none' fillRule='evenodd'>
          <path
            fill={fillColor}
            d='M5 0h40a5 5 0 0 1 5 5v40a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5z'
          />
          <path
            fill='#FFF'
            d='M26.4 23.6H32v2.8h-5.6V32h-2.8v-5.6H18v-2.8h5.6V18h2.8v5.6z'
          />
        </g>
      </svg>
      {title && (
        <div className={styles.buttonTitle}>
          <h5>{title}</h5>
        </div>
      )}
    </div>
  )
}

const badgeStyle = (count: number) =>
  css(`
    ::after {
      content: '${count}';
      background: ${sportableColors.colors.sportableBlue};
      color: white;
      position: absolute;
      right: 0;
      top: 0px;
      width: ${count < 10 ? '16px' : '18px'};
      height: ${count < 10 ? '16px' : '18px'};
      border-radius: 100%;
      font-size: ${count < 10 ? '10px' : '12px'};
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    }
  `)
