import { useState, useReducer } from 'react'
import { aggregationOperatorTypes } from '../../../metrics_server/metrics/aggregation_operators/data_types'
import { useInsights } from '../../../metrics_server/insights/hooks'
import {
  InsightsTableRequest,
  InsightsTableConfig,
  InsightsTableFormTypeValues,
  InsightsTableFormTypeKey
} from '../../../metrics_server/insights/types'

export const useInsightsFormLogic = (configId: string) => {
  const { tables } = useInsights()

  const selectedTableConfig = tables.configs[configId]

  type State = {
    tableConfig: InsightsTableConfig
    requests: {
      [key in InsightsTableFormTypeValues]: InsightsTableRequest
    }
  }

  const actionTypes = {
    UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
    UPDATE_REQUEST: 'UPDATE_REQUEST'
  }

  type ActionTypeKeys = keyof typeof actionTypes

  type Action = { type: ActionTypeKeys; payload }

  function updateTableConfig(value): Action {
    return {
      type: 'UPDATE_TABLE_CONFIG',
      payload: value
    }
  }

  function updateRequest(type, data): Action {
    return {
      type: 'UPDATE_REQUEST',
      payload: { type, data }
    }
  }

  function insightsRequestReducer(state: State, action: Action): State {
    switch (action.type) {
      case actionTypes.UPDATE_TABLE_CONFIG:
        return {
          ...state,
          tableConfig: {
            ...state.tableConfig,
            ...action.payload
          }
        }
      case actionTypes.UPDATE_REQUEST:
        return {
          ...state,
          requests: {
            ...state.requests,
            [action.payload.type]: {
              ...state.requests[action.payload.type],
              ...action.payload.data
            }
          }
        }
      default:
        return state
    }
  }

  const selectedTableType = selectedTableConfig?.config.tableType

  const initialState: State = {
    tableConfig: {
      userId: null,
      id: configId,
      name: selectedTableConfig?.name || '',
      colour: null,
      config: null
    },
    requests: {
      [InsightsTableFormTypeKey.recentEvents]:
        selectedTableType === InsightsTableFormTypeKey.recentEvents
          ? selectedTableConfig.config
          : ({
              tableType: InsightsTableFormTypeKey.recentEvents,
              length: 8,
              tableRows: null,
              flightType: 'Pass',
              flightSubTypes: [],
              columnQuery: [
                {
                  stat: 'Distance',
                  aggregation: aggregationOperatorTypes.items.NoOp.value
                }
              ]
            } as InsightsTableRequest),
      [InsightsTableFormTypeKey.topN]:
        selectedTableType === InsightsTableFormTypeKey.topN
          ? selectedTableConfig.config
          : ({
              tableType: InsightsTableFormTypeKey.topN,
              length: 5,
              tableRows: null,
              flightType: 'Pass',
              flightSubTypes: [],
              columnQuery: [
                {
                  stat: 'Distance',
                  aggregation: aggregationOperatorTypes.items.NoOp.value
                }
              ]
            } as InsightsTableRequest),
      [InsightsTableFormTypeKey.custom]:
        selectedTableType === InsightsTableFormTypeKey.custom
          ? selectedTableConfig.config
          : ({
              tableType: InsightsTableFormTypeKey.custom,
              tableRows: {
                playerIds: [],
                allTeams: true
              },
              columnQuery: []
            } as InsightsTableRequest)
    }
  }

  const [state, dispatch] = useReducer(insightsRequestReducer, initialState)

  const [formType, setFormType] = useState<InsightsTableFormTypeValues>(
    selectedTableType || 'RecentEvents'
  )

  return {
    state,
    dispatch,
    updateTableConfig,
    updateRequest,
    formType,
    setFormType
  }
}
