import React, { useCallback, useMemo } from 'react'
import { useAppSelector } from '../../store/hooks'
import { getUnitSystem } from './functions'
import { UnitSystem } from './types'
import { setUnitSystem } from './actions'
import { toggleModal } from '../../ui/modal/actions'
import { SelectForm } from '../../components/Forms/SelectForm/SelectForm'
import { useAppDispatch } from '../../store/hooks'
import { Sport } from '../sports/data_types'

export function useUnits() {
  const units = useAppSelector((state) => state.units)
  return units
}

export function useUnitsSystem(sport: Sport): UnitSystem {
  const units = useUnits()

  return useMemo(() => {
    return getUnitSystem(units, sport)
  }, [units.selected, units.systemOptions])
}

// Get unit system options inlcuding default
export function useUnitSystemOptions() {
  const { systemOptions } = useUnits()

  const unitSystemOptions = useMemo(() => {
    const updatedSystemOptions = [
      { name: 'Default', value: 'Default' },
      ...systemOptions
    ]
    return updatedSystemOptions
  }, [systemOptions])

  return unitSystemOptions
}

export const useOpenUnitSelectModal = () => {
  const dispatch = useAppDispatch()
  const units = useUnits()
  const unitSystemOptions = useUnitSystemOptions()

  const openUnitSelect = useCallback(() => {
    const handleSubmit = (values) => {
      dispatch(setUnitSystem(values.selected))
      dispatch(toggleModal({}))
    }

    dispatch(
      toggleModal({
        active: true,
        handleProceed: () => {
          dispatch(toggleModal({}))
        },
        ChildComponent: () => {
          return (
            <SelectForm
              handleSubmit={handleSubmit}
              initialValue={units.selected}
              options={unitSystemOptions}
              title={'Select unit system'}
              submitText={'Select'}
            />
          )
        },
        wrapper: true,
        className: 'modalSmall',
        width: '50%',
        handleSecondaryBtn: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }, [dispatch, units, unitSystemOptions])

  return openUnitSelect
}
