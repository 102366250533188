import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actions from '../router/actions'

let mounted = false

// Types
interface AuthenticationHOCProps {
  authentication
  router
  location
  history
  setInitialRoute
  setUrlSearchParams
  setRedirect
}

export default (ChildComponent, initialRoute): any => {
  class ComposedComponent extends Component<AuthenticationHOCProps> {
    // Our component just got rendered
    UNSAFE_componentWillMount() {
      const {
        authentication,
        location,
        history,
        setInitialRoute,
        setUrlSearchParams
      } = this.props
      if (!mounted) {
        const urlParams = new URLSearchParams(location.search)
        let paramsState = {}
        urlParams.forEach((value, key) => {
          paramsState[key] = value
        })
        setUrlSearchParams(paramsState)
        if (authentication.signedIn) {
          history.push(initialRoute)
          setInitialRoute(initialRoute)
        } else if (!authentication.signedIn) {
          history.push('/login')
          setInitialRoute('/login')
        }
      }
    }

    componentDidMount() {
      mounted = true
    }

    // Our component just got updated
    componentDidUpdate(prevProps) {
      const { authentication, router } = this.props
      this.shouldNavigateAway(
        prevProps.authentication.signedIn,
        authentication.signedIn
      )
    }

    shouldNavigateAway(prevSignedIn, signedIn) {
      const { setRedirect } = this.props
      if (!prevSignedIn && signedIn) {
        setRedirect(initialRoute)
      } else if (prevSignedIn && !signedIn) {
        setRedirect('/login')
      }
    }

    render() {
      const { initialRoute } = this.props.router
      if (!initialRoute) return <noscript />
      return <ChildComponent {...this.props} />
    }
  }

  function mapStateToProps(state) {
    return {
      authentication: state.authentication,
      router: state.router
    }
  }

  return connect(mapStateToProps, actions)(ComposedComponent)
}
