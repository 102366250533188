import React, { useState } from 'react'
import { OrganisationsState } from '../../../metrics_server/organisations/types'
import { FormContainer } from '../../../components/Forms/Form/Form'
import TextInput from '../../../components/Forms/Inputs/TextInput/TextInput'
import { ImageInput } from '../../../components/Forms/Inputs/Image/Image'
import { Button } from '../../../components/Button/Button'

import styles from '../../../components/Forms/Form/Form.module.scss'

const validate = (values) => {
  const errors = {} as { name?: string }
  if (!values.name) {
    errors.name = 'Required'
  }
  return errors
}

export interface CreateOrganisationFormProps {
  change: (field: string, value: any) => void
  formIndex: any
  organisations: OrganisationsState
  updateNewOrgansationState: (values: any, callback: () => void) => void
  switchForm: (formIndex: number) => void
}

export const CreateOrganisationForm = (props: CreateOrganisationFormProps) => {
  function handleImageChange(base64) {
    props.change('img', base64)
  }

  function handleSubmit(values) {
    props.updateNewOrgansationState(values, switchToNextForm)
  }

  function switchToNextForm() {
    props.switchForm(props.formIndex.createTeam)
  }

  const newOrg = props.organisations.new

  const [img, setimg] = useState(newOrg?.logo || '')

  const [fields, setFields] = useState([
    {
      name: 'logo',
      label: 'Upload logo',
      component: ImageInput,
      onImageChange: handleImageChange,
      img: img,
      type: 'img'
    },
    {
      name: 'name',
      label: 'First name',
      placeholder: 'Name',
      component: TextInput,
      type: 'text'
    }
  ])

  return (
    <>
      <FormContainer
        fields={fields}
        validate={validate}
        onSubmit={(values) => handleSubmit(values)}
        submitText={'Create'}
        title={'Create organisation'}
        // secondaryButtonText={'Join existing organisation'}
        // secondaryButtonHandleClick={() =>
        //   props.switchForm(props.formIndex.joinOrganisation)
        // }
      />

      <div className={styles.buttonsContainer}>
        <Button
          handleClick={() => props.switchForm(props.formIndex.joinOrganisation)}
          className='btn--plain'
        >
          Join existing organisation
        </Button>
      </div>
    </>
  )
}
