import { useAppSelector } from '../../store/hooks'
import { getAppTypeCheck } from './functions'
import { AppTypeCheck } from './types'

export function useUser() {
  const user = useAppSelector((state) => state.user)
  return user
}

export function useAppTypeCheck(): AppTypeCheck {
  const user = useUser()
  return getAppTypeCheck(user)
}
