import { JSXElementConstructor } from 'react'

export const TOGGLE_MODAL = 'TOGGLE_MODAL'

export interface ToggleModalAction {
  type: 'TOGGLE_MODAL'
  payload: ModalState
}

export type ToggleModal = (ModalState) => ToggleModalAction

export type HandleProceed = (any) => void
export type HandleClose = (any) => void

export interface ModalState {
  active: boolean
  handleProceed?: HandleProceed
  handleClose?: HandleClose
  ChildComponent?: JSXElementConstructor<any>
  className: string
}
