import { UserState } from './types'

export const blacklistedWords = ['sportable', 'sp0rtabletech', 'password']

export const userDummyState: UserState = {
  data: {
    id: '10',
    firstName: 'Oliver',
    lastName: 'Moore',
    permissionLevel: 999,
    email: 'oli@sportable.com'
  }
}
