import { DrillsProvider } from './DrillsContext'
import { Drills } from './Drills'

export const DrillsProviderWrapper = (props) => (
  <DrillsProvider
    toggleCanvasVisibility={props.toggleCanvasVisibility}
    canvasId={props.canvasId}
    hiddenCanvases={props.hiddenCanvases}
    strack={props.strack}
  >
    <Drills {...props} />
  </DrillsProvider>
)
