import { DataType, Options } from '../data_types'
import { RawFlightEventData } from '../events/flight/types'
import {
  aggregationOperatorTypes,
  AggregationOperatorTypeValues
} from '../metrics/aggregation_operators/data_types'
import { FormattedSession } from '../sessions/types'
import { UnitSystem } from '../units/types'
import { MetricTypeConfig } from './data_types'
import { FormattedMetricAggregation } from './types'

export type GetMetricOptions = (
  rawMetrics: object,
  formattedSession: FormattedSession
) => Options<any>

export class MetricHandler<C extends MetricTypeConfig> extends DataType<
  C['key'],
  C['value'],
  C['props']
> {
  props: C['props']

  constructor(props) {
    super(props)

    for (const key in props) {
      this[key] = props[key]
    }
  }

  getMetricValueWithUnits(
    value: string | number | boolean,
    unitSystem: UnitSystem,
    rawMetrics: object,
    formattedSession: FormattedSession,
    customDecimal?: number
  ) {
    const { bool, type } = this.props
    value = this.getMetricValue(
      value,
      rawMetrics,
      formattedSession,
      customDecimal
    )

    if (value === null || value === undefined) return null

    // Apply units //
    let unit = unitSystem.units[type]
    if (bool) unit = unitSystem.units.percentage

    return `${value}\u2009${unit ? `${unit.abbreviation}` : ''}`
  }

  getMetricValue(
    value: string | number | boolean,
    rawMetrics: object,
    formattedSession: FormattedSession,
    customDecimal?: number
  ): string | number | boolean {
    const { decimal, type, options, getValue, format } = this.props

    // If metric has a custom value generator //
    if (getValue && rawMetrics) {
      value = getValue(rawMetrics)
    }

    // Change any strings to numbers //
    // This should probably be fixed in the MS //
    if (typeof value === 'string' && format === 'number') {
      value = parseFloat(value)
    }

    // Convert decimal to percentage //
    if (
      typeof value === 'number' &&
      type === 'percentage' &&
      // Probability forward - we are being sent a percentage, but are expecting decimals and backend cannot update this
      // TODO: Remove this when backend is updated
      // TODO: we should probably implement 2 types, percentage and decimal //
      this.key !== 'probabilityForward'
    ) {
      value = value * 100
    }

    // If metric has a set of options, get the option name using the value //
    if (options) {
      let resolvedOptions
      if (typeof options === 'object') {
        resolvedOptions = options
      } else if (
        typeof options === 'function' &&
        rawMetrics &&
        formattedSession
      ) {
        resolvedOptions = options(rawMetrics, formattedSession)
      } else {
        resolvedOptions = []
      }
      const option = resolvedOptions.find((option) => option.value === value)
      value = option?.name || 'Unknown value'
    }

    // if value is a number round it to the specified decimal places //
    if (typeof value === 'number') {
      if (customDecimal) {
        value = value.toFixed(customDecimal)
      } else if (!isNaN(decimal)) {
        value = value.toFixed(decimal)
      }
    }

    return value
  }

  getMetricDisplayWithUnits(unitSystem: UnitSystem, maxLength: number) {
    const {
      name,
      props: { type, abbr }
    } = this
    const unit = unitSystem.units[type]
    let displayName = `${name} ${unit ? `(${unit.abbreviation})` : ''}`
    if (maxLength) {
      if (name.length > maxLength)
        displayName = `${abbr} ${unit ? `(${unit.abbreviation})` : ''}`
    }
    return displayName
  }

  isBoolean() {
    return this.props.bool
  }

  getFormattedMetric(
    initialValue,
    unitSystem: UnitSystem,
    aggregation?: AggregationOperatorTypeValues,
    formattedSession?: FormattedSession
  ): FormattedMetricAggregation {
    let name
    switch (aggregation) {
      case aggregationOperatorTypes.items.Mean.value:
        name = 'Average ' + this.name
        break
      case aggregationOperatorTypes.items.Maximum.value:
        name = 'Maximum ' + this.name
        break
      case aggregationOperatorTypes.items.Minimum.value:
        name = 'Minimum ' + this.name
        break
      case aggregationOperatorTypes.items.Sum.value:
        name = 'Total ' + this.name
        break
      default:
        name = this.name
        break
    }
    return {
      name,
      value: initialValue ? initialValue : 0,
      // TODO: refactor this code to formatted session and events //
      formattedValue: initialValue
        ? this.getMetricValueWithUnits(
            initialValue,
            unitSystem,
            null,
            formattedSession,
            null
          )
        : '-',
      count: initialValue ? 1 : 0,
      aggregation
    }
  }

  updateFormattedMetricValue(
    formattedMetric: FormattedMetricAggregation,
    newValue: number,
    unitSystem: UnitSystem,
    rawMetrics: object,
    formattedSession: FormattedSession
  ) {
    switch (formattedMetric.aggregation) {
      case aggregationOperatorTypes.items.Mean.value:
        formattedMetric.value =
          (formattedMetric.value * formattedMetric.count + newValue) /
          (formattedMetric.count + 1)
        break
      case aggregationOperatorTypes.items.Maximum.value:
        if (newValue > formattedMetric.value) formattedMetric.value = newValue
        break
      case aggregationOperatorTypes.items.Minimum.value:
        if (newValue < formattedMetric.value) formattedMetric.value = newValue
        break
      case aggregationOperatorTypes.items.Sum.value:
        formattedMetric.value = formattedMetric.value + newValue
        break
      default:
        formattedMetric.value = newValue
        break
    }
    // TODO: refactor this code to formatted session and events //
    formattedMetric.formattedValue = this.getMetricValueWithUnits(
      formattedMetric.value,
      unitSystem,
      rawMetrics,
      formattedSession,
      null
    )
    formattedMetric.count++
    return formattedMetric
  }

  getMetricFromFlights(
    flights: RawFlightEventData[],
    unitSystem: UnitSystem,
    aggregation: AggregationOperatorTypeValues,
    formattedSession: FormattedSession
  ) {
    let formattedMetric = this.getFormattedMetric(
      0,
      unitSystem,
      aggregation,
      formattedSession
    )
    if (this.props.format !== 'number') return formattedMetric
    flights.forEach((flight) => {
      const value = flight[this.key as string]
      if (typeof value === 'number') {
        formattedMetric = this.updateFormattedMetricValue(
          formattedMetric,
          value,
          unitSystem,
          flight,
          formattedSession
        )
      }
    })
    return formattedMetric
  }
}
