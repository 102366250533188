var alphabet = '0123456789ABCDEFGHJKMNPQRSTVWXYZ'

export function base32Encode(num) {
  const fiveOnes = 31

  var buffer = []

  // Break the argument into 5-bit bytes, big-end first. Each base 32 digit
  // encodes 5 bits of information. There are 6 5-bit bytes plus 2 bits in a
  // 32 bit unsigned int.
  var bytes = [
    (num >> 30) & fiveOnes,
    (num >> 25) & fiveOnes,
    (num >> 20) & fiveOnes,
    (num >> 15) & fiveOnes,
    (num >> 10) & fiveOnes,
    (num >> 5) & fiveOnes,
    (num >> 0) & fiveOnes
  ]

  // We don't want the base-32 result to be zero-padded, so we'll ignore
  // everything up to the first non-zero value. However, special case: if the
  // input argument is 0, then the result should be "0".
  var firstNonZeroIndex = 6

  // Encode each of the 5-bit bytes into the corresponding base-32 rune.
  for (var i = 0; i < bytes.length; i++) {
    var byte = bytes[i]
    buffer[i] = alphabet[byte]
    if (byte != 0 && firstNonZeroIndex == 6) {
      // Keep track of the index of the first non-zero byte so we can
      // slice off the leading zeros at the end.
      firstNonZeroIndex = i
    }
  }

  let array = buffer.slice(firstNonZeroIndex)
  let str = array.join('')
  return str
}
