import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Drill, RawPlayerBreakdowns } from './types'
import { getColor } from '../../utils/helpers'

export interface DrillsState {
  drills: Drill[]
  playerBreakdowns: {
    [drillId: string]: RawPlayerBreakdowns
  }
  loading: boolean
  error: string | null
  selectedDrillId: string | null
  selectedDrill: Drill | null
  goals: boolean
}

const initialState: DrillsState = {
  drills: [],
  playerBreakdowns: {},
  loading: false,
  error: null,
  selectedDrillId: null,
  selectedDrill: null,
  goals: true
}

const drillsSlice = createSlice({
  name: 'drills',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true
      state.error = null
    },
    stopLoading: (state) => {
      state.loading = false
    },
    toggleGoals: (state) => {
      state.goals = !state.goals
    },
    selectDrill: (state, action: PayloadAction<Drill['id']>) => {
      state.selectedDrillId = action.payload
      state.selectedDrill =
        state.drills.find((drill) => drill.id === action.payload) || null
    },
    updateDrillRegion: (state, action) => {
      const { drillId, region } = action.payload
      const drillIndex = state.drills.findIndex((drill) => drill.id === drillId)
      if (drillIndex !== -1) {
        state.drills[drillIndex].extraInfo.region = region
      }
    },
    setDrills: (state, action: PayloadAction<Drill[]>) => {
      // Apply color to the drills
      const drills = action.payload.map((drill, index) => ({
        ...drill,
        color: getColor(index)
      }))
      state.drills = drills
      state.loading = false
      state.error = null
    },
    addDrill: (state, action: PayloadAction<Drill>) => {
      // Apply color to the drill
      const drill = {
        ...action.payload,
        color: getColor(state.drills.length)
      }
      state.drills.push(drill)
      state.loading = false
      state.error = null
    },
    // removeDrill: (
    //   state,
    //   action: PayloadAction<{ sessionId: string; drillId: string }>
    // ) => {
    //   // TODO: might need to check the session id as well
    //   state.drills = state.drills.filter(
    //     (drill) => drill.id !== action.payload.drillId
    //   )
    //   state.loading = false
    //   state.error = null
    // },
    updateDrill: (state, action: PayloadAction<Drill>) => {
      // Apply color to the drill before saving
      const index = state.drills.findIndex(
        (drill) => drill.id === action.payload.id
      )
      state.drills[index] = {
        color: state.drills[index]?.color,
        ...action.payload
      }
      state.loading = false
      state.error = null
      // update selected drill if it's the same drill
      if (state.selectedDrillId === action.payload.id) {
        state.selectedDrill = action.payload
      }
    },
    updateDrillPlayerBreakdowns: (
      state,
      action: PayloadAction<{
        drillId: string
        rawPlayerBreakdowns: RawPlayerBreakdowns
      }>
    ) => {
      state.playerBreakdowns[action.payload.drillId] =
        action.payload.rawPlayerBreakdowns
    }
  }
  // extraReducers: (builder) => {
  //   // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
  //   builder.addCase(updateUser.fulfilled, (state, { payload }) => {
  //     state.entities[payload.id] = payload
  //   })
  //   builder.addCase(updateUser.rejected, (state, action) => {
  //     if (action.payload) {
  //       // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
  //       state.error = action.payload.errorMessage
  //     } else {
  //       state.error = action.error.message
  //     }
  //   })
  // },
})

export const {
  startLoading,
  stopLoading,
  setDrills,
  addDrill,
  updateDrill,
  updateDrillRegion,
  selectDrill,
  updateDrillPlayerBreakdowns,
  toggleGoals
} = drillsSlice.actions
export const drillsReducer = drillsSlice.reducer
