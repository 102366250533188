import { SET_REDIRECT, SET_INITIAL_ROUTE, SET_URL_SEARCH_PARAMS } from './types'

export function setRedirect(route) {
  return (dispatch, getState) => {
    const { router } = getState()
    // redirect user if router.redirect is not initilized or user is not navagating to same page
    if (!router.redirect || router.redirect !== route) {
      dispatch({
        type: SET_REDIRECT,
        payload: { route, action: 'PUSH' }
      })
    }
  }
}

export const goBack = () => {
  return (dispatch, getState) => {
    const { router } = getState()
    if (router.prevRoute) {
      dispatch(setRedirect(router.prevRoute))
    }
  }
}

export const goToLanding = () => {
  return (dispatch, getState) => {
    const { application } = getState().config
    dispatch(setRedirect(application.landing))
  }
}

export function setRedirectPop(route) {
  return {
    type: SET_REDIRECT,
    payload: { route, action: 'POP' }
  }
}

export function setInitialRoute(route) {
  return {
    type: SET_INITIAL_ROUTE,
    payload: route
  }
}

export function setUrlSearchParams(params) {
  return {
    type: SET_URL_SEARCH_PARAMS,
    payload: params
  }
}
