// Used in validation and overview to select which metrics you want to see in the event tables

import React, { useCallback } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { toggleModal } from '../../ui/modal/actions'
import { PlayerForm } from './components/PlayerForm/PlayerForm'
import { PlayerData } from './types'
import { Sport } from '../sports/data_types'
import { Team } from '../teams/types'

// Hook for opening player form in a modal
export const usePlayerFormModal = () => {
  const dispatch = useAppDispatch()

  const openPlayerFormModal = useCallback(
    (sport?: Sport, playerData?: PlayerData, teamId?: string) => {
      const ChildComponent = () => {
        return (
          <PlayerForm
            teamId={teamId}
            sport={sport}
            playerData={playerData}
            handleProceed={() => dispatch(toggleModal({}))}
          />
        )
      }

      // Open modal with above ChildComponent
      dispatch(
        toggleModal({
          active: true,
          type: 'confirm',
          wrapper: true,
          handleProceed: () => {
            dispatch(toggleModal({}))
          },
          ChildComponent,
          className: 'modalSmall',
          handleSecondaryBtn: () => {
            dispatch(toggleModal({}))
          }
        })
      )
    },
    [dispatch]
  )

  return {
    openPlayerFormModal
  }
}
