export const SET_INFO = 'SET_INFO'

export interface InfoState {
  message?: string
  proceed?: () => void
  type?: string
  header?: string
}

export type SetInfoAction = {
  type: typeof SET_INFO
  payload: InfoState
}

export type SetInfo = (info: InfoState) => SetInfoAction
