import React from 'react'
import type { InputHTMLAttributes } from 'react'
import { Button, Typography } from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import CancelIcon from '@mui/icons-material/Cancel'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  img: Image
  onUpload: (value) => void
}
type Image = {
  img: string
}

export const FileField = ({ img, onUpload }: Props) => {
  return (
    <>
      <Button
        variant='contained'
        component='label'
        endIcon={<FileUploadIcon sx={{ color: 'white' }} />}
      >
        Upload File
        <input
          type='file'
          hidden
          onChange={({ target }) => onUpload(target.files)}
        />
      </Button>
      {img ? (
        <Typography
          variant='button'
          display='block'
          gutterBottom
          sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}
        >
          {img[0].name}
          <CancelIcon
            onClick={() => onUpload('')}
            sx={{ cursor: 'pointer', color: '#f44336' }}
          />
        </Typography>
      ) : (
        ''
      )}
    </>
  )
}
