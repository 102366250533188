import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup
} from '../../data_types'

export type AggregationOperatorTypeFeatures = {
  exampleAggregationOperatorFeature: boolean
}

export const defaultAggregationOperatorTypeFeatures: AggregationOperatorTypeFeatures =
  {
    exampleAggregationOperatorFeature: true
  }

export const aggregationOperatorTypesConfig = {
  NoOp: {
    key: 'NoOp',
    value: 'NoOp',
    name: 'NoOp',
    props: { features: defaultAggregationOperatorTypeFeatures }
  },
  Mean: {
    key: 'Mean',
    value: 'Mean',
    name: 'Mean',
    props: { features: defaultAggregationOperatorTypeFeatures }
  },
  Maximum: {
    key: 'Maximum',
    value: 'Maximum',
    name: 'Maximum',
    props: { features: defaultAggregationOperatorTypeFeatures }
  },
  Minimum: {
    key: 'Minimum',
    value: 'Minimum',
    name: 'Minimum',
    props: { features: defaultAggregationOperatorTypeFeatures }
  },
  Count: {
    key: 'Count',
    value: 'Count',
    name: 'Count',
    props: { features: defaultAggregationOperatorTypeFeatures }
  },
  Sum: {
    key: 'Sum',
    value: 'Sum',
    name: 'Sum',
    props: { features: defaultAggregationOperatorTypeFeatures }
  },
  Percentage: {
    key: 'Percentage',
    value: 'Percentage',
    name: 'Percentage',
    props: { features: defaultAggregationOperatorTypeFeatures }
  }
} as const

export type AggregationOperatorTypeProps = {
  features: AggregationOperatorTypeFeatures
}

export type AggregationOperatorTypes = DataTypes<
  typeof aggregationOperatorTypesConfig
>

export type AggregationOperatorTypeKey = DataTypeKey<AggregationOperatorTypes>

export type AggregationOperatorTypeKeys =
  DataTypeKeys<AggregationOperatorTypeKey>

export type AggregationOperatorTypeValues =
  DataTypeValues<AggregationOperatorTypeKey>

export const getAggregationOperatorTypeGroup = (items: {
  [key in AggregationOperatorTypeKeys]?: AggregationOperatorTypes[key]
}) => {
  return getDataTypeGroup<
    AggregationOperatorTypeKeys,
    AggregationOperatorTypeValues,
    AggregationOperatorTypes,
    AggregationOperatorTypeProps
  >(items)
}

export type AggregationOperatorTypeGroup = ReturnType<
  typeof getAggregationOperatorTypeGroup
>

export type AggregationOperatorTypeConfig = DataTypeConfig<
  AggregationOperatorTypeKeys,
  AggregationOperatorTypeValues,
  AggregationOperatorTypeProps
>

export const aggregationOperatorTypes = getAggregationOperatorTypeGroup(
  aggregationOperatorTypesConfig
)
