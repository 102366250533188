import React from 'react'
import styles from './PlayersGrid.module.scss'
import { AddNewButton } from '../AddNewButton/AddNewButton'
import { Drag } from '../Drag/Drag'
import { usePlayerFormModal } from '../../metrics_server/players/modals'
import { Button } from '../Button/Button'
import {
  useFormattedSession,
  usePlayerAssignmentFunctions
} from '../../metrics_server/sessions/hooks'
import { SessionPlayer } from '../Player/SessionPlayer/SessionPlayer'
import { usePlayerFilter } from '../../metrics_server/players/filter'
import Select from '../Forms/Inputs/Select/Select'
import BasicSelect from '../Material/Select'

export interface PlayersGridProps {
  teamId: string
  sessionId: string | 'setup'
}

export const PlayerAssignmentGrid = ({
  teamId,
  sessionId
}: PlayersGridProps) => {
  const { id, sport, isTrainingMode, sessionData } =
    useFormattedSession(sessionId)

  const { filteredPlayers, filters, updateFilterValue } = usePlayerFilter(
    {
      team: [teamId]
    },
    'setup'
  )

  const handleSearchChange = (event) => {
    updateFilterValue('searchStr', event)
  }

  const { addAllTeamsPlayersToSession, removeAllTeamsPlayersFromSession } =
    usePlayerAssignmentFunctions(teamId)

  const { openPlayerFormModal } = usePlayerFormModal()

  return (
    <div className={styles.playerAssignmentGridContainer}>
      <div className={styles.filtersContainer}>
        <div style={{ width: '180px', marginRight: '20px' }}>
          <BasicSelect
            label={'Search Players'}
            variant='standard'
            selected={filters.searchStr.value}
            onChange={(e) => handleSearchChange(e)}
            labelShrink={true}
            size={'small'}
            fontSize={20}
          />
        </div>
        <div className='smallSelectContainer'>
          <div style={{ fontSize: '13px' }}>Assignment status:</div>
          <Select
            className='small-select'
            options={filters.assigned.options}
            updateValue={updateFilterValue}
            input={{
              name: 'assigned',
              value: filters.assigned.value
            }}
          />
        </div>
        {(isTrainingMode || !sport.props.features.positionNumbers) && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ padding: '2px' }}>
              <Button
                className={'btn--primary-thin'}
                handleClick={addAllTeamsPlayersToSession}
                style={{ alignSelf: 'center' }}
              >
                Add All Players
              </Button>
            </div>
            <div style={{ padding: '2px' }}>
              <Button
                className={'btn--border-thin'}
                handleClick={removeAllTeamsPlayersFromSession}
                style={{ alignSelf: 'center', width: '100%' }}
              >
                Remove All Players
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className={styles.playerListScroller}>
        <div className={styles.playerAssignmentGridContent}>
          <div data-testid='addPlayerButtonContainer'>
            <AddNewButton
              addCallBack={() => openPlayerFormModal(sport, null, teamId)}
              size={45}
              title={'Add new player'}
              horizontal={true}
            />
          </div>
          {filteredPlayers.list.map((player, index) => {
            return (
              <React.Fragment key={player.id}>
                <Drag>
                  <SessionPlayer sessionId={id} playerId={player.id} />
                </Drag>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}
