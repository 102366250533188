import { RawSessionData } from '../sessions/types'
import { RawTeamData } from '../teams/types'

export interface BroadcastingState {
  enabled: boolean | null
  publishedFlights: string[]
  msState: any
  state: RawBroadcastingState
  autoBroadcastEnabled: boolean
}

export type RawBroadcastingState = {
  session: RawSessionData
  sessionControl: {
    possession: RawTeamData
    sides: [RawTeamData, RawTeamData]
  }
  host: string
  broadcastingEnabled: boolean
}

export const TOGGLE_AUTO_BROADCASTING = 'TOGGLE_AUTO_BROADCASTING'
export const SET_FLIGHT_PUBLISHED = 'SET_FLIGHT_PUBLISHED'
export const UPDATE_BROADCAST_STATE = 'UPDATE_BROADCAST_STATE'
