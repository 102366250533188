import {
  // CREATE_PLAYER,
  SET_SUCCESS
} from './types'

import { SuccessState } from './types'

const intitialState: SuccessState = {}

export function successReducer(state = intitialState, action) {
  switch (action.type) {
    case SET_SUCCESS:
      return action.payload
    default:
      return state
  }
}
