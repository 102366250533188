import React, { Component } from 'react'
import styles from './Canvas.module.scss'

interface Canvas2DProps {
  id: string
  cover: string
  hidden?: boolean
}

export class Canvas2D extends Component<Canvas2DProps> {
  render() {
    const { id, hidden, cover } = this.props
    return (
      <div
        id={`${id}-2D-canvas-container`}
        style={{ height: '100%', width: '100%', position: 'absolute' }}
        className={
          hidden
            ? `${styles.canvasHidden} ${styles.canvasContainer2d}`
            : styles.canvasContainer2d
        }
      >
        <canvas id={`${id}-2D`} className={styles.trackingFieldCanvas}></canvas>
        <canvas id={`${id}-${cover}`} className={styles.cover}></canvas>
        <canvas
          style={{ zIndex: -1 }}
          id={`${id}-texture`}
          className={styles.cover}
        ></canvas>
      </div>
    )
  }
}
