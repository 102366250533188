import React from 'react'
import { ProviderWrapper } from '../store/Provider'
import { MSUserHOC } from './MSUserHOC'
import App from '../App/App'
import { AppConfig } from '../App/App.types'
import '../index.scss'

export function createSportableApp(
  initialState,
  appConfig: AppConfig,
  storybook?
) {
  return ProviderWrapper(
    (props) => {
      const MSApp = MSUserHOC(App)
      return (
        <MSApp
          storybook={storybook}
          appConfig={appConfig}
          landing={appConfig.landing}
        />
      )
    },
    { ...initialState, config: { application: appConfig } }
  )
}
