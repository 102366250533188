// /* eslint-disable */
// uses tailwindcss classes

import React, { useEffect, useMemo, useState } from 'react'
import {
  DeviceState,
  HardwareState
} from '../../../metrics_server/hardware/types'
import { PitchesState } from '../../../metrics_server/pitches/types'
import { sportableColors } from '../../../constants/sportableColors'
import { distance3D } from '../../../utils/helpers'
import * as Sentry from '@sentry/react'
import { environment } from '../../../metrics_server/env'
import { useFormattedHardware } from '../../../metrics_server/hardware/hooks'
import { ChevronDown } from '../../../components/Icons/ChevronDown/ChevronDown'
import {
  toggleCollapsedHardware,
  toggleCollapseAllHardware
} from '../../../metrics_server/hardware/actions'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { Button } from '../../../components/Button/Button'
import BasicSelect from '../../../components/Material/Select'
import { TagIcon } from '../../../components/Icons/TagIcon/TagIcon'

export interface CFLHardwareProps {
  active: boolean
  selectedTabKey: string
  updateActiveTab: (tabName: string) => void
  hardware: HardwareState
  pitches: PitchesState
}

export type OverviewHardwareDevice = {
  id: number
  type: string
  location: DeviceState['dataMetrics']['location']
  encodedId: string
  imu: string
  imuQuality: number
  rangingQuality: number
  positionDataRate: number
  color: string
  statusColor: string
  inSession: boolean
}

// Added these to prevent sentry from being spammed with errors
// Currently set so sentry exception is only captured once per app load
// Remove capturedException to fire once per tag
const missingTagInfo = {}
let capturedException = false

// TODO: Move to functions file or hardware hook
const getAnchorMatrix = (hardware: HardwareState, pitches, version) => {
  const { inUse } = pitches

  let masterId, masterIndex, masterInPitchSetup

  // find master and set id, index and inPitchSetup
  for (const hardwareId in hardware.diagnostics.hardwareState) {
    const dev = hardware.diagnostics.hardwareState[hardwareId]
    if (dev && dev.slowSensors.uwbRole === 'uwbMain') {
      masterId = dev.id
      masterIndex = hardware.diagnostics.hardwareIndex.indexOf(masterId)
      masterInPitchSetup = inUse.anchors[masterId]
    }
  }

  const data = hardware.diagnostics.hardwareIndex.map((x, i) => {
    const item = {
      index: null,
      anchor: i <= hardware.diagnostics.finalAnchorIndex,
      id: hardware.diagnostics.encodedHardwareIndex[i]
    }

    hardware.diagnostics.hardwareIndex.forEach((y, j) => {
      const device = hardware.diagnostics.hardwareState[y]

      let rssiValue = hardware.diagnostics.rssiMatrix[i][j]

      if (i === masterIndex && rssiValue === -105 && device) {
        rssiValue = device.masterRSSI
      }

      try {
        if (device.type === 'Anchor') {
          const anchorInPitchSetup = inUse.anchors[y]
          const uwbDistance =
            hardware.diagnostics.distanceMatrix[j]?.[masterIndex]
          let positionHealth, positionHealthColor

          if (anchorInPitchSetup && masterInPitchSetup) {
            const distanceToMaster = distance3D(
              anchorInPitchSetup.pos,
              masterInPitchSetup.pos
            )
            positionHealth = Math.abs(distanceToMaster - uwbDistance).toFixed(2)

            if (positionHealth <= 0.3) {
              positionHealthColor = 'rgba(71, 206, 71, 1)'
            } else if (positionHealth <= 1) {
              positionHealthColor = 'rgba(254, 126, 3, 1)'
            } else if (positionHealth > 1) {
              positionHealthColor = 'rgba(255, 0, 0, 1)'
            }
          }
          if (x === y) {
            item[y] = {
              imuLen: `${hardware.diagnostics.imuLenMatrix[i][j]}`,
              rssi: rssiValue,
              master: device.slowSensors.uwbRole === 'uwbMain'
            }
          } else {
            item[y] = {
              count: `${hardware.diagnostics.countMatrix[i][j]}`,
              rssi: rssiValue,
              distance: `${hardware.diagnostics.distanceMatrix[i][j]}`,
              serial: hardware.diagnostics.encodedHardwareIndex[j],
              positionQuality:
                hardware.diagnostics.hardwareState[y]?.dataMetrics
                  ?.positionQuality?.percentage,
              master: device.slowSensors.uwbRole === 'uwbMain',
              id: device.id,
              positionHealth,
              positionHealthColor
              // deamonStats:
              //   hardware.diagnostics.hardwareState[y]?.dataMetrics
              //     ?.daemonStats
            }
          }
          if (hardware.diagnostics.countMatrix[i][j] <= 10) {
            item[y].color = 'rgba(255, 0, 0, 1)'
          } else if (hardware.diagnostics.countMatrix[i][j] >= 16) {
            item[y].color = 'rgba(71, 206, 71, 1)'
          } else {
            item[y].color = 'rgba(254, 126, 3, 1)'
          }
        }
      } catch (error) {
        if (!missingTagInfo[y] && !capturedException) {
          console.log(
            `no device found in hardware state for: ${y}. Capturing error`
          )
          Sentry.captureException(
            `no device found in hardware state for: ${y}`,
            {
              tags: {
                LocationName: version.app?.LocationName,
                Product: version.app?.Product,
                Environment: environment.ENV,
                Deployment: environment.deployment
              }
            }
          )
          capturedException = true
          missingTagInfo[y] = true
        }
      }
    })
    return {
      ...item
    }
  })

  // filter out non-anchors
  const filteredAnchorMatrix = Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value.anchor !== false)
  )

  return filteredAnchorMatrix
}

// TODO: adding type to prop here causes an issue when setting the initial tabs
export const CFLHardware = (props: any) => {
  const { active, hardware, pitches, version } = props

  const dispatch = useAppDispatch()

  const formattedHardware = useFormattedHardware()

  const [anchorMatrix, setAnchorMatrix] = useState(
    getAnchorMatrix(hardware, pitches, version)
  )
  const [collapsedDevices, setCollapsedDevices] = useState(false)

  useEffect(() => {
    setAnchorMatrix(getAnchorMatrix(hardware, pitches, version))
  }, [hardware, pitches])

  const getTagAndBallsData = (hardware) => {
    const onlineTagsAndBalls = [
      ...hardware.diagnostics.status.online.balls,
      ...hardware.diagnostics.status.online.tags,
      ...hardware.diagnostics.status.sleep.balls,
      ...hardware.diagnostics.status.sleep.tags
    ]

    const deviceData = []
    for (const tagId in hardware.diagnostics.hardwareState) {
      // if(!onlineTagsAndBalls.includes(parseInt(tagId))) continue
      if (onlineTagsAndBalls.includes(parseInt(tagId))) {
        const tag = hardware.diagnostics.hardwareState[tagId]
        const index = hardware.diagnostics.hardwareIndex.indexOf(
          parseInt(tagId)
        )

        const acc = Number(
          hardware.diagnostics.hardwareState[tagId]?.accStdDeviation
        ).toFixed(2)

        const location =
          hardware.diagnostics.hardwareState[tagId]?.dataMetrics.location

        const positionDataRate =
          hardware.diagnostics.hardwareState[tagId]?.positionDataRate

        const dataMetrics =
          hardware.diagnostics.hardwareState[tagId]?.dataMetrics

        let imuLenDisplay = '-'
        let imuLen = 0
        if (index >= 0) {
          imuLen = hardware.diagnostics.imuLenMatrix[index][index]
        }

        const isOnline =
          hardware.diagnostics.status.online.balls.includes(tag.id) ||
          hardware.diagnostics.status.online.tags.includes(tag.id)
            ? 'Online'
            : 'Sleep'

        if (typeof imuLen === 'number') imuLenDisplay = imuLen.toFixed(1)

        const device: OverviewHardwareDevice = {
          id: tag.id,
          type: tag.type,
          location: location,
          encodedId: tag.serial,
          imu: imuLenDisplay,
          imuQuality: dataMetrics?.imu?.quality,
          rangingQuality: dataMetrics?.ranging?.quality,
          positionDataRate,
          color:
            Number(dataMetrics?.ranging?.quality || 0) <= 6
              ? 'rgba(255, 0, 0, 1)'
              : Number(dataMetrics?.ranging?.quality || 0) >= 10
              ? 'rgba(71, 206, 71, 1)'
              : 'rgba(254, 126, 3, 1)',
          statusColor:
            isOnline === 'Online'
              ? sportableColors.colors.colorSuccess
              : isOnline === 'Sleep'
              ? sportableColors.colors.colorWarning
              : 'black',
          inSession: tag.inSession
        }

        deviceData.push(device)
      }
    }

    const categorized = {
      Ball: [],
      PlayerTag: []
    }

    deviceData.forEach((device) => {
      if (device.type === 'Ball') {
        categorized.Ball.push(device)
      } else if (device.type === 'PlayerTag') {
        categorized.PlayerTag.push(device)
      }
    })

    return categorized
  }

  const ballsAndTags = useMemo(() => getTagAndBallsData(hardware), [hardware])

  useEffect(() => {
    dispatch(toggleCollapseAllHardware(!collapsedDevices))
  }, [collapsedDevices, dispatch])

  const [filteredTagsAndBalls, setFilteredTagsAndBalls] = useState([])

  const [serialSearchString, setSerialSearchString] = useState('')

  const handleSearchChange = (value) => {
    setSerialSearchString(value)
  }

  useEffect(() => {
    if (serialSearchString === '') {
      setFilteredTagsAndBalls([])
      return
    }

    const search = serialSearchString.toLowerCase()

    const tagsAndBalls = [...ballsAndTags['Ball'], ...ballsAndTags['PlayerTag']]

    const filteredTagsAndBalls = tagsAndBalls.filter((tag) => {
      return tag.encodedId.toLowerCase().includes(search)
    })

    setFilteredTagsAndBalls(filteredTagsAndBalls)
  }, [ballsAndTags, serialSearchString])

  if (!active) return null

  return (
    <div
      className={`overflow-y-auto h-[calc(100vh-150px)] pt-2 p-4 scroll-m-1 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-transparent scrollbar-rounded-thumb`}
    >
      <div className='flex justify-end items-center space-x-4'>
        <BasicSelect
          label={'Search Devices'}
          variant='standard'
          selected={serialSearchString}
          onChange={(e) => handleSearchChange(e)}
          labelShrink={true}
          size={'small'}
          fontSize={15}
          width={'180px'}
        />
        <Button
          className={'btn--primary'}
          handleClick={() => setCollapsedDevices(!collapsedDevices)}
          style={{ alignSelf: 'center' }}
        >
          {`${collapsedDevices ? 'Expand' : 'Collapse'} Devices`}
        </Button>
        {/* <span
            className={`transition-transform duration-300 ${
              collapsedDevices ? 'rotate-0' : 'rotate-180'
            }`}
            onClick={() => setCollapsedDevices(!collapsedDevices)}
          >
            <ChevronDown
              scale={1.5}
              strokeWidth={2}
              color={sportableColors.colors.sportableBlue}
            />
          </span> */}
      </div>

      <AnchorMatrixRow data={anchorMatrix} hardware={hardware} />
      <BallRow
        data={ballsAndTags['Ball']}
        hardware={hardware}
        filteredTagsAndBalls={filteredTagsAndBalls}
      />
      <PlayerTagRow
        data={ballsAndTags['PlayerTag']}
        hardware={hardware}
        filteredTagsAndBalls={filteredTagsAndBalls}
      />
    </div>
  )
}

const AnchorMatrixRow = ({
  data,
  hardware
}: {
  data: {
    [k: string]: {
      index: any
      anchor: boolean
      id: string
    }
  }
  hardware: HardwareState
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState('0')
  const [showAnchorInfo, setShowAnchorInfo] = useState(true)

  // Create a mapping of full names to abbreviations
  // const abbreviateKey = (key) => {
  //   const mapping = {
  //     imuCount: 'IMUCt.',
  //     uwbCount: 'UWBCt.',
  //     imuDrops: 'IMUDr.',
  //     uwbDrops: 'UWBDr.',
  //     imuRepeats: 'IMURp.',
  //     rangeRepeats: 'RngRp.',
  //     rangeCount: 'RngCt.',
  //     rangeImuCount: 'R-IMUCt.',
  //     rangeLockCount: 'R-LkCt.',
  //     avgAlgorithmTime: 'Alg.Time'
  //   }

  //   return mapping[key] || key
  // }

  // check if data is empty object

  const selectedRowData = data[selectedRowIndex]

  const selectedRowSerial = selectedRowData?.id

  const allRows = Object.keys(data).map((key) => ({ key, value: data[key].id }))
  const sortedRows = allRows.sort((a, b) => a.value.localeCompare(b.value))

  const allAnchors = data['0']
    ? Object.keys(data['0']).filter(
        (key) =>
          key !== 'id' &&
          key !== 'index' &&
          key !== 'anchor' &&
          key !== 'positionHealth'
      )
    : []

  return (
    <div className='flex flex-col'>
      <h3
        className='flex justify-between items-center cursor-pointer w-36'
        onClick={() => setShowAnchorInfo(!showAnchorInfo)}
      >
        Anchors
        <span
          className={`transition-transform duration-300 ${
            showAnchorInfo ? 'rotate-180' : 'rotate-0'
          }`}
        >
          <ChevronDown
            scale={1}
            strokeWidth={2}
            color={sportableColors.colors.sportableBlue}
          />
        </span>
      </h3>
      {selectedRowData && showAnchorInfo && (
        <div className='flex flex-row items-center'>
          <div className='flex flex-col mr-8 w-52'>
            <select
              className='p-2 z-10 mt-2 w-36 rounded font-semibold text-base'
              value={selectedRowIndex}
              onChange={(e) => setSelectedRowIndex(e.target.value)}
              style={{
                border: `5px solid ${sportableColors.colors.sportableGrey}`
              }}
            >
              {sortedRows.map((item) => (
                <option key={item.key} value={item.key}>
                  {item.value}
                </option>
              ))}
            </select>
          </div>

          <div className='grid grid-cols-16 gap-1 w-full'>
            {allAnchors
              .sort((a, b) => {
                const masterA =
                  selectedRowData[a]?.master ||
                  hardware.diagnostics.hardwareState[a]?.master
                const masterB =
                  selectedRowData[b]?.master ||
                  hardware.diagnostics.hardwareState[b]?.master

                return masterB - masterA // Ensure master is rendered first
              })
              .map((anchorKey) => {
                const columnData = selectedRowData[anchorKey] || {}

                return (
                  <div
                    key={anchorKey}
                    className='p-1 rounded'
                    style={{
                      border:
                        selectedRowSerial ===
                        hardware.diagnostics.hardwareState[anchorKey]?.serial
                          ? `5px solid ${sportableColors.colors.sportableBlue}`
                          : `${
                              columnData.color
                                ? `5px solid ${columnData.color}`
                                : `5px solid ${sportableColors.colors.sportableGrey}`
                            }`
                    }}
                  >
                    <div className='p-1 flex flex-col items-center'>
                      <h5 className='mb-1'>
                        {columnData.serial ||
                          hardware.diagnostics.hardwareState[anchorKey]?.serial}
                        {columnData.master ||
                        hardware.diagnostics.hardwareState[anchorKey]?.master
                          ? '(M)'
                          : ''}
                      </h5>
                      {columnData.count &&
                      columnData.rssi &&
                      columnData.distance ? (
                        <>
                          <p className='text-xs whitespace-nowrap'>{`${columnData.count}Hz`}</p>
                          <p className='text-xs whitespace-nowrap'>{`${columnData.rssi}dBm`}</p>
                          <p className='text-xs whitespace-nowrap'>{`${columnData.distance}m`}</p>
                        </>
                      ) : (
                        <>
                          <p className='text-xs whitespace-nowrap'>{`${columnData.imuLen}`}</p>
                          <p className='text-xs whitespace-nowrap'>{`${columnData.rssi}dBm`}</p>
                        </>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      )}

      {/* Position Health Row */}
      {selectedRowData && showAnchorInfo && (
        <div className='flex flex-row items-center mt-4'>
          <div className='flex flex-col mr-8 w-52'>
            <div
              className='p-2 text-base font-semibold w-32 rounded'
              style={{
                border: `5px solid ${sportableColors.colors.sportableGrey}`
              }}
            >
              Position Health
            </div>
          </div>

          <div className='grid grid-cols-16 gap-1 w-full'>
            {allAnchors.map((anchorKey) => {
              const columnData = selectedRowData[anchorKey] || {}
              return (
                <div key={anchorKey} className='p-1'>
                  <div
                    className='border p-2 text-center rounded'
                    style={{
                      border: `${
                        columnData.positionHealthColor
                          ? `5px solid ${columnData.positionHealthColor}`
                          : `5px solid ${sportableColors.colors.sportableGrey}`
                      }`
                    }}
                  >
                    {columnData.positionHealth || '-'}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {/* disabling row until confirmation from Loji and Dario as to what they want here */}
      {/* Link Quality Row */}
      {/* <div className='flex flex-row items-center mt-4'>
        <div className='flex flex-col mr-8 w-52'>
          <div
            className='p-2 text-base font-semibold w-32 rounded'
            style={{
              border: `5px solid ${sportableColors.colors.sportableGrey}`
            }}
          >
            Link Quality
          </div>
        </div>

        <div className='grid grid-cols-16 gap-1 w-full'>
          {allAnchors.map((anchorKey) => {
            const columnData = selectedRowData[anchorKey] || {}
            return (
              <div key={anchorKey} className='p-1'>
                <div
                  className='border p-2 text-center rounded'
                  style={{
                    border: `${
                      columnData.color
                        ? `5px solid ${columnData.color}`
                        : `5px solid ${sportableColors.colors.sportableGrey}`
                    }`
                  }}
                >
                  {columnData.positionQuality || '-'}
                </div>
              </div>
            )
          })}
        </div>
      </div> */}

      {/* Daemon Stats Row
      <div className='flex flex-row items-center mt-4'>
        <div className='flex flex-col mr-8 w-52'>
          <div
            className='p-2 text-base font-semibold w-32 rounded'
            style={{
              border: `5px solid ${sportableColors.colors.sportableGrey}`
            }}
          >
            Daemon Stats
          </div>
        </div>

        <div className='grid grid-cols-16 gap-1 w-full'>
          {allAnchors.map((anchorKey) => {
            const columnData = selectedRowData[anchorKey] || {}
            return (
              <div key={anchorKey} className='p-1'>
                <div
                  className='border p-2 text-center rounded'
                  style={{
                    border: `${
                      columnData.color
                        ? `5px solid ${columnData.color}`
                        : `5px solid ${sportableColors.colors.sportableGrey}`
                    }`
                  }}
                >
                  {columnData.deamonStats
                    ? Object.keys(columnData.deamonStats).map((key) => (
                        <div key={key} className='text-xs whitespace-nowrap'>
                          {`${abbreviateKey(key)}: ${
                            columnData.deamonStats[key].percentage ||
                            columnData.deamonStats[key].time
                          }`}
                        </div>
                      ))
                    : '-'}
                </div>
              </div>
            )
          })}
        </div>
      </div> */}
    </div>
  )
}

const BallRow = ({ data, hardware, filteredTagsAndBalls }) => {
  const [isOnPitch, setIsOnPitch] = useState(false)
  const [showBalls, setShowBalls] = useState(true)

  // if (hardware.diagnostics.status.online.balls.length === 0) {
  //   return <EmptyState title='No Ball Tags' />
  // }

  const filteredData = data.filter((ball) => {
    if (isOnPitch) {
      return ball?.location?.inPitch
    }
    return true
  })

  return (
    <div className='flex flex-row mt-14'>
      <div className='flex flex-col mr-8 w-52'>
        <h3
          className='flex justify-between items-center cursor-pointer w-36'
          onClick={() => setShowBalls(!showBalls)}
        >
          Balls
          <span
            className={`transition-transform duration-300 ${
              showBalls ? 'rotate-180' : 'rotate-0'
            }`}
          >
            <ChevronDown
              scale={1}
              strokeWidth={2}
              color={sportableColors.colors.sportableBlue}
            />
          </span>
        </h3>
        {showBalls && (
          <div className='flex mt-4 z-10 rounded'>
            <div
              onClick={() => setIsOnPitch(false)}
              className={`text-xs whitespace-nowrap cursor-pointer px-4 py-2 transition-colors duration-200 font-semibold rounded-l  ${
                isOnPitch ? 'hover:bg-gray-300' : 'text-white'
              }`}
              style={{
                backgroundColor: isOnPitch
                  ? '#E5E7EB'
                  : sportableColors.colors.sportableBlue
              }}
            >
              ALL
            </div>
            <div
              onClick={() => setIsOnPitch(true)}
              className={`text-xs whitespace-nowrap cursor-pointer px-4 py-2 transition-colors duration-200 font-semibold rounded-r  ${
                isOnPitch ? 'text-white' : 'hover:bg-gray-300'
              }`}
              style={{
                backgroundColor: isOnPitch
                  ? sportableColors.colors.sportableBlue
                  : '#E5E7EB'
              }}
            >
              ON PITCH
            </div>
          </div>
        )}
      </div>
      {showBalls && (
        <div className='grid grid-cols-16 gap-1 w-full'>
          {filteredData.map((ball, index) => (
            <div key={index} className='p-1'>
              <BallItem
                ball={ball}
                isOnPitch={isOnPitch}
                filteredTagsAndBalls={filteredTagsAndBalls}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

type BallItemProps = {
  ball: OverviewHardwareDevice
  isOnPitch: boolean
  filteredTagsAndBalls: OverviewHardwareDevice[]
}

const BallItem = ({ ball, isOnPitch, filteredTagsAndBalls }: BallItemProps) => {
  const dispatch = useAppDispatch()

  const isBallItemCollapsed = useAppSelector(
    (state) => state.hardware.collapsedHardwareIds[ball.encodedId]
  )

  const handleToggleCollapsed = () => {
    dispatch(toggleCollapsedHardware(ball.encodedId))
  }

  const getBackgroundStyles = (ball) => {
    if (ball?.location?.ballInPlay && ball?.location?.inPitch) {
      return 'rgba(71, 206, 71, 0.4)'
    }

    if (isOnPitch || ball?.location?.inPitch) {
      return `${sportableColors.colors.sportableBlueLight}`
    }

    return ``
  }

  const getHighlightedStyles = (ball) => {
    if (filteredTagsAndBalls.length > 0) {
      if (filteredTagsAndBalls.find((item) => item.encodedId === ball)) {
        return sportableColors.colors.flightHighlight
      }
    }
    return ``
  }

  return (
    <div
      className='relative flex flex-col items-center rounded'
      style={{
        border: `5px solid ${ball.color}`,
        backgroundColor: getBackgroundStyles(ball)
      }}
      onClick={handleToggleCollapsed}
    >
      {/* Device status indicator */}
      <div
        className='w-2.5 h-2.5 absolute -left-2 -top-2 rounded-full'
        style={{
          backgroundColor: ball.statusColor,
          border: `2px solid black`
        }}
      >
        {/* <TagIcon /> */}
      </div>

      {/* Device in session indicator */}
      {ball.inSession && (
        <div
          className='w-3 h-3 absolute -right-2 -top-2 rounded-full flex justify-center items-center'
          style={{
            backgroundColor: 'white',
            border: `2px solid black`,
            fontSize: '0.6rem',
            fontWeight: 'bold',
            lineHeight: '1.2rem'
          }}
        >
          <div className='mt-0.5'>S</div>
        </div>
      )}

      <div
        className='w-2.5 h-2.5 absolute -left-2 -top-2 rounded-full'
        style={{
          backgroundColor: ball.statusColor,
          border: `2px solid black`
        }}
      >
        {/* <TagIcon /> */}
      </div>

      <h5
        className={`m-1 ${'font-bold'}`}
        style={{
          backgroundColor: getHighlightedStyles(ball.encodedId)
        }}
      >
        {ball.encodedId}
      </h5>
      {!isBallItemCollapsed && (
        <>
          <p
            className={`${
              isOnPitch && ball?.location?.inPitch
                ? 'text-xs whitespace-nowrap font-bold'
                : 'text-xs whitespace-nowrap'
            }`}
          >
            {ball?.positionDataRate
              ? `Pos: ${ball.positionDataRate.toFixed(2)}Hz`
              : 'N/A'}
          </p>
          <p
            className={`${
              isOnPitch && ball?.location?.inPitch
                ? 'text-xs whitespace-nowrap font-bold'
                : 'text-xs whitespace-nowrap'
            }`}
          >
            {`RngQ: ${ball?.rangingQuality ? ball?.rangingQuality : '-'}`}
          </p>
          <p
            className={`${
              isOnPitch && ball?.location?.inPitch
                ? 'text-xs whitespace-nowrap font-bold'
                : 'text-xs whitespace-nowrap'
            }`}
          >
            {ball?.imu ? `IMU: ${ball?.imu}Hz` : 'N/A'}
          </p>

          <p
            className={`${
              isOnPitch && ball?.location?.inPitch
                ? 'text-xs whitespace-nowrap font-bold'
                : 'text-xs whitespace-nowrap'
            }`}
          >
            {`IMUQ: ${ball?.imuQuality ? ball?.imuQuality : '-'}`}
          </p>
        </>
      )}
    </div>
  )
}

const PlayerTagRow = ({ data, hardware, filteredTagsAndBalls }) => {
  const [isOnPitch, setIsOnPitch] = useState(false)
  const [showTags, setShowTags] = useState(true)

  // if (hardware.diagnostics.status.online.tags.length === 0) {
  //   return <EmptyState title='No Player Tags' />
  // }

  const filteredData = data.filter((tag) => {
    if (isOnPitch) {
      return tag?.location?.inPitch
    }
    return true
  })

  return (
    <div className='flex flex-row mt-14'>
      <div className='flex flex-col mr-8 w-52'>
        <h3
          className='flex justify-between items-center cursor-pointer w-36'
          onClick={() => setShowTags(!showTags)}
        >
          Tags
          <span
            className={`transition-transform duration-300 ${
              showTags ? 'rotate-180' : 'rotate-0'
            }`}
          >
            <ChevronDown
              scale={1}
              strokeWidth={2}
              color={sportableColors.colors.sportableBlue}
            />
          </span>
        </h3>
        {showTags && (
          <div className='flex mt-4 z-10 rounded'>
            <div
              onClick={() => setIsOnPitch(false)}
              className={`text-xs whitespace-nowrap cursor-pointer px-4 py-2 transition-colors duration-200 font-semibold rounded-l  ${
                isOnPitch ? 'hover:bg-gray-300' : 'text-white'
              }`}
              style={{
                backgroundColor: isOnPitch
                  ? '#E5E7EB'
                  : sportableColors.colors.sportableBlue
              }}
            >
              ALL
            </div>
            <div
              onClick={() => setIsOnPitch(true)}
              className={`text-xs whitespace-nowrap cursor-pointer px-4 py-2 transition-colors duration-200 font-semibold rounded-r  ${
                isOnPitch ? 'text-white' : 'hover:bg-gray-300'
              }`}
              style={{
                backgroundColor: isOnPitch
                  ? sportableColors.colors.sportableBlue
                  : '#E5E7EB'
              }}
            >
              ON PITCH
            </div>
          </div>
        )}
      </div>
      {showTags && (
        <div className='grid grid-cols-16 gap-1 w-full'>
          {filteredData.map((tag, index) => (
            <div key={index} className='p-1'>
              <PlayerTagItem
                tag={tag}
                isOnPitch={isOnPitch}
                filteredTagsAndBalls={filteredTagsAndBalls}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

type TagItemProps = {
  tag: OverviewHardwareDevice
  isOnPitch: boolean
  filteredTagsAndBalls: OverviewHardwareDevice[]
}

const PlayerTagItem = ({
  tag,
  isOnPitch,
  filteredTagsAndBalls
}: TagItemProps) => {
  const dispatch = useAppDispatch()

  const isTagItemCollapsed = useAppSelector(
    (state) => state.hardware.collapsedHardwareIds[tag.encodedId]
  )

  const handleToggleCollapsed = () => {
    dispatch(toggleCollapsedHardware(tag.encodedId))
  }

  const getBackgroundStyles = (tag) => {
    if (isOnPitch || tag?.location?.inPitch) {
      return `${sportableColors.colors.sportableBlueLight}`
    }
    return ``
  }

  const getHighlightedStyles = (tag) => {
    if (filteredTagsAndBalls.length > 0) {
      if (filteredTagsAndBalls.find((item) => item.encodedId === tag)) {
        return sportableColors.colors.flightHighlight
      }
    }
    return ``
  }

  return (
    <div
      className={`relative p-1 flex flex-col items-center rounded ${
        isOnPitch && !tag?.location?.inPitch ? 'hidden' : ''
      }`}
      style={{
        border: `5px solid ${tag.color}`,
        backgroundColor: getBackgroundStyles(tag)
      }}
      onClick={handleToggleCollapsed}
    >
      {/* Device status indicator */}
      <div
        className='w-2.5 h-2.5 absolute -left-2 -top-2 rounded-full'
        style={{
          backgroundColor: tag.statusColor,
          border: `2px solid black`
        }}
      ></div>

      {/* Device in session indicator */}
      {tag.inSession && (
        <div
          className='w-3 h-3 absolute -right-2 -top-2 rounded-full flex justify-center items-center'
          style={{
            backgroundColor: 'white',
            border: `2px solid black`,
            fontSize: '0.6rem',
            fontWeight: 'bold',
            lineHeight: '1.2rem'
          }}
        >
          <div className='mt-0.5'>S</div>
        </div>
      )}

      <h5
        className={`mb-1  ${'font-bold'}`}
        style={{ backgroundColor: getHighlightedStyles(tag.encodedId) }}
      >
        {tag.encodedId}
      </h5>
      {!isTagItemCollapsed && (
        <>
          {' '}
          <p
            className={`${
              isOnPitch && tag?.location?.inPitch
                ? 'text-xs whitespace-nowrap font-bold'
                : 'text-xs whitespace-nowrap'
            }`}
          >
            {tag?.positionDataRate
              ? `Pos: ${tag.positionDataRate.toFixed(2)}Hz`
              : 'N/A'}
          </p>
          <p
            className={`${
              isOnPitch && tag?.location?.inPitch
                ? 'text-xs whitespace-nowrap font-bold'
                : 'text-xs whitespace-nowrap'
            }`}
          >
            {`RngQ: ${tag?.rangingQuality ? tag?.rangingQuality : '-'}`}
          </p>
          <p
            className={`${
              isOnPitch && tag?.location?.inPitch
                ? 'text-xs whitespace-nowrap font-bold'
                : 'text-xs whitespace-nowrap'
            }`}
          >
            {tag?.imu ? `IMU: ${tag?.imu}` : 'N/A'}
          </p>
          <p
            className={`${
              isOnPitch && tag?.location?.inPitch
                ? 'text-xs whitespace-nowrap font-bold'
                : 'text-xs whitespace-nowrap'
            }`}
          >
            {`IMUQ: ${tag?.imuQuality ? tag?.imuQuality : '-'}`}
          </p>
        </>
      )}
    </div>
  )
}
