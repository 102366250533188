import html2canvas from 'html2canvas'

export function generateTexture(strack) {
  const { base, lines, teams, numbers } = strack.layers
  if (base) {
    const baseCtx = base.getContext('2d')

    if (lines) baseCtx.drawImage(lines, 0, 0)
    if (teams) baseCtx.drawImage(teams, 0, 0, base.width, base.height)
    if (numbers) baseCtx.drawImage(numbers, 0, 0, base.width, base.height)
    // document.body.appendChild(base)
    setTimeout(() => {
      const fullQuality = base.toDataURL('image/jpeg')
      strack.updateGroundTexture(fullQuality)
    }, 0)
  }
}

export function removeLayers(strack) {
  strack.layers = {
    base: null,
    lines: null,
    teams: null,
    numbers: null
  }
}

export function updateLayer(type, layer, strack) {
  strack.layers[type] = layer
  setTimeout(() => {
    generateTexture(strack)
  }, 0)
}

export function getLayerFromHtml(html, strack, update) {
  html2canvas(html, {
    backgroundColor: null
  }).then((canvas) => {
    update(canvas)
  })
}
