import React from 'react'

export interface SeparatorProps {
  color?: string
  length?: number
  style?: any
  horizontal: boolean
}

export const Separator = ({
  color = '#3F4059',
  length = 125,
  style,
  horizontal
}: SeparatorProps) => {
  return (
    <div
      style={{
        width: horizontal ? length : 1,
        height: horizontal ? 1 : length,
        backgroundColor: color,
        borderRadius: 10,
        ...style
      }}
    ></div>
  )
}
