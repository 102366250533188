import { ViewNavbarProps } from '../../components/Views/Views.types'
import {
  useSessions,
  useStartSession
} from '../../metrics_server/sessions/hooks'

export function useSessionSetupNavbarProps(): ViewNavbarProps {
  const sessions = useSessions()
  const { runChecksAndStartSession, sessionReadyToStart } = useStartSession()

  const callback = runChecksAndStartSession
  const text = 'Start Session'
  const icon = 'tick'
  const loading = sessions.creatingSession
  const disabled = !sessionReadyToStart

  return {
    enabled: true,
    centerButton: {
      visible: true,
      callback,
      text,
      icon,
      loading,
      disabled
    }
  }
}
