import { isSessionModeType } from '../sessions/modes/data_types'
import { isSubSessionType } from '../sessions/sub_sessions/data_types'
import { FormattedSession } from '../sessions/types'
import {
  BroadcastIntegrationState,
  emptyBroadcastIntegrationPlayerResult
} from './slice'
import {
  BroadcastIntegrationErrors,
  FormattedBroadcastIntegrationPlayer,
  FormattedBroadcastIntegrationTeams
} from './types'
import { FormattedPlayer } from '../players/types'
import { FormattedTeam, Team } from '../teams/types'
import { FixtureCheckRequestBody, PlayerCheckRequestBody } from './api'
import { IDMap } from '../types'
import { getSumOfObjectFieldsInArray } from '../../utils/helpers'

export const formatBroadcastIntegrationSessionData = (
  broadcastIntegration: BroadcastIntegrationState,
  formattedSession: FormattedSession,
  broadcastIntegrationEnabledInMSConfig: boolean
) => {
  const {
    type,
    mode,
    subType,
    sport,
    homeTeam,
    awayTeam,
    teams,
    playersSessions
  } = formattedSession

  // Check if broadcast integration is available (sport, sessionType, subSessionType)

  const isAvailable =
    broadcastIntegrationEnabledInMSConfig &&
    !!sport.props.features?.broadcastIntegrationAvailable &&
    !!type.props.features?.broadcastIntegrationAvailable &&
    !!subType.props.features?.broadcastIntegrationAvailable

  // Check if broadcast integration is required (live, broadcast for available sport)
  const isBroadcastIntegrationRequired =
    isAvailable &&
    isSubSessionType.broadcast(subType) &&
    isSessionModeType.live(mode)

  // Enabled if available and not disabled

  const isEnabled =
    (isAvailable && !broadcastIntegration.disabled) ||
    isBroadcastIntegrationRequired

  // Fixture date required if enabled and not live broadcast
  const fixtureDateRequired =
    isEnabled &&
    !(isSubSessionType.broadcast(subType) && isSessionModeType.live(mode))

  // Check if session teams match broadcast integration data teams
  let isValid = false

  if (homeTeam && awayTeam) {
    isValid =
      homeTeam.id ===
        broadcastIntegration.data.teamIds?.[homeTeam.id]?.sportableId &&
      awayTeam.id ===
        broadcastIntegration.data.teamIds?.[awayTeam.id]?.sportableId
  }

  // Get fixture state
  const isFixtureMatched: boolean =
    isValid && !!broadcastIntegration.data.currentFixture?.geniusID
  const fixtureCertainty: number =
    isValid && broadcastIntegration.data.currentFixture?.certainty
  const fixtureErrors = isValid
    ? broadcastIntegration.data.currentFixture?.errors
    : []

  // Get team A state
  const isTeamAMatched: boolean =
    isValid && !!broadcastIntegration.data.teamIds?.[homeTeam?.id]?.geniusID
  const teamAMatchCertainty: number =
    isValid && broadcastIntegration.data.teamIds?.[homeTeam?.id]?.certainty
  const teamAErrors: BroadcastIntegrationErrors = isValid
    ? broadcastIntegration.data.teamIds?.[homeTeam?.id]?.errors
    : []

  // Get team B state
  const isTeamBMatched: boolean =
    isValid && !!broadcastIntegration.data.teamIds?.[awayTeam?.id]?.geniusID
  const teamBMatchCertainty: number =
    isValid && broadcastIntegration.data.teamIds?.[awayTeam?.id]?.certainty
  const teamBErrors: BroadcastIntegrationErrors = isValid
    ? broadcastIntegration.data.teamIds?.[awayTeam?.id]?.errors
    : []

  // Get team and player data
  const teamsAndPlayers = formatBroadcastIntegrationTeamAndPlayerData(
    broadcastIntegration,
    formattedSession,
    teams.map
  )

  // Get total number of matched / unmatched players
  const numberOfMatchedPlayers = getSumOfObjectFieldsInArray(
    Object.values(teamsAndPlayers),
    'numberOfMatchedPlayers'
  )
  const numberOfUnmatchedPlayers = getSumOfObjectFieldsInArray(
    Object.values(teamsAndPlayers),
    'numberOfUnmatchedPlayers'
  )

  // Get total number of matched / unmatched players in session
  const numberOfMatchedPlayersInSession = getSumOfObjectFieldsInArray(
    Object.values(teamsAndPlayers),
    'numberOfMatchedPlayers',
    (team) => {
      return playersSessions.byPlayerId.list.some(
        (playerSession) => !!team.playersMap[playerSession.playerId]
      )
    }
  )
  const numberOfUnmatchedPlayersInSession = getSumOfObjectFieldsInArray(
    Object.values(teamsAndPlayers),
    'numberOfUnmatchedPlayers',
    (team) => {
      return playersSessions.byPlayerId.list.some(
        (playerSession) => !!team.playersMap[playerSession.playerId]
      )
    }
  )

  // Is broadcast integration session setup complete
  const sessionIntegrationCompletion =
    isBroadcastIntegrationSessionSetupComplete(
      isEnabled,
      broadcastIntegration,
      teamsAndPlayers
    )

  return {
    isValid,
    isFixtureMatched,
    fixtureCertainty,
    fixtureErrors,
    isTeamAMatched,
    teamAMatchCertainty,
    teamAErrors,
    isTeamBMatched,
    teamBMatchCertainty,
    teamBErrors,

    isAvailable,
    isEnabled,
    fixtureDateRequired,
    isBroadcastIntegrationRequired,
    sessionIntegrationCompletion,

    teamsAndPlayers,
    numberOfMatchedPlayers,
    numberOfUnmatchedPlayers,
    numberOfMatchedPlayersInSession,
    numberOfUnmatchedPlayersInSession
  }
}

export type FormattedBroadcastIntegrationSessionData = ReturnType<
  typeof formatBroadcastIntegrationSessionData
>

export const formatBroadcastIntegrationTeamAndPlayerData = (
  broadcastIntegration: BroadcastIntegrationState,
  formattedSession: FormattedSession,
  teamMap: IDMap<Team>
) => {
  const { playersSessions } = formattedSession
  const formattedBroadcastIntegrationTeams =
    {} as FormattedBroadcastIntegrationTeams

  // Loop through teams and format
  for (const teamId in broadcastIntegration.data.teamIds) {
    const broadcastIntegrationTeamResult =
      broadcastIntegration.data.teamIds[teamId]

    // Get team data
    const teamData = teamMap[teamId]

    formattedBroadcastIntegrationTeams[teamId] = {
      numberOfMatchedPlayers: 0,
      numberOfUnmatchedPlayers: 0,
      teamData,
      broadcastIntegrationResult: broadcastIntegrationTeamResult,
      playersList: [],
      playersMap: {}
    }

    // Loop through players, format and add to team
    for (const playerId in playersSessions.byPlayerId.map) {
      const playerSession = playersSessions.byPlayerId.map[playerId]
      const broadcastIntegrationPlayerResult =
        broadcastIntegration.data.playerIds[playerId]

      // Get player data
      const { player } = playerSession

      const isPlayerIgnored =
        broadcastIntegration.ignoredPlayerIds.includes(playerId)

      const isFetching =
        broadcastIntegration.fetchingPlayerIds.includes(playerId)

      // Check if player is in team
      if (teamId === player?.teamId) {
        // Create formatted player data
        const formatBroadcastIntegrationPlayerData = {
          playerData: player,
          playerSession,
          broadcastIntegrationResult:
            broadcastIntegrationPlayerResult ||
            emptyBroadcastIntegrationPlayerResult,
          isPlayerIgnored,
          isFetching,
          isMatched: !!broadcastIntegrationPlayerResult?.geniusID
        }

        // Increment number of matched / unmatched players
        if (
          formatBroadcastIntegrationPlayerData.broadcastIntegrationResult
            .geniusID
        ) {
          formattedBroadcastIntegrationTeams[teamId].numberOfMatchedPlayers++
        } else {
          formattedBroadcastIntegrationTeams[teamId].numberOfUnmatchedPlayers++
        }

        // Add formatted player data to team
        formattedBroadcastIntegrationTeams[teamId].playersList.push(
          formatBroadcastIntegrationPlayerData
        )
        formattedBroadcastIntegrationTeams[teamId].playersMap[playerId] =
          formatBroadcastIntegrationPlayerData
      }
    }
  }
  return formattedBroadcastIntegrationTeams
}

export const getPlayerFromGeniusPlayerId = (
  broadcastIntegrationTeams: FormattedBroadcastIntegrationTeams,
  geniusPlayerId: string
) => {
  let player: FormattedBroadcastIntegrationPlayer
  for (const teamId in broadcastIntegrationTeams) {
    const team = broadcastIntegrationTeams[teamId]
    for (const playerId in team.playersMap) {
      const playerData = team.playersMap[playerId]
      if (playerData.broadcastIntegrationResult.geniusID === geniusPlayerId) {
        player = playerData
        break
      }
    }
  }
  return player
}

// get team data for broadcast integration request body
export const getBroadcastIntegrationFixtureCheckRequestBody = (
  teamA: Team,
  teamB: Team,
  fixtureDate: Date
): FixtureCheckRequestBody => {
  return {
    homeTeam: {
      name: teamA.name,
      id: teamA.id
    },
    awayTeam: {
      name: teamB.name,
      id: teamB.id
    },
    dataTime: fixtureDate
  }
}

// get player data for broadcast integration request body from session
export const getBroadcastIntegrationPlayersCheckRequestBodyFromSession = (
  session: FormattedSession
) => {
  let requestBodyData: PlayerCheckRequestBody[] = []

  session.teams.list.forEach((team) => {
    if (team) {
      requestBodyData = [
        ...requestBodyData,
        ...getBroadcastIntegrationPlayersCheckRequestBodyFromTeam(team)
      ]
    }
  })

  return requestBodyData
}

// get player data for broadcast integration request body from team
export const getBroadcastIntegrationPlayersCheckRequestBodyFromTeam = (
  team: FormattedTeam
) => {
  const requestBodyData: PlayerCheckRequestBody[] = team.players.list.map(
    (player) => {
      return getBroadcastIntegrationPlayerCheckRequestBody(player, team)
    }
  )
  return requestBodyData
}

// get player data for broadcast integration request body
export const getBroadcastIntegrationPlayerCheckRequestBody = (
  player: FormattedPlayer,
  team: FormattedTeam
): PlayerCheckRequestBody => {
  return {
    playerId: player.id,
    teamName: team.name,
    firstName: player.firstName,
    lastName: player.lastName
  }
}

export const isBroadcastIntegrationSessionSetupComplete = (
  isEnabled: boolean,
  broadcastIntegration: BroadcastIntegrationState,
  teamsAndPlayers: FormattedBroadcastIntegrationTeams
) => {
  const errors: string[] = []

  // Return true if broadcast integration is not enabled
  if (!isEnabled) {
    return { isComplete: true, errors: [] }
  }

  // Return false if fixture is not matched
  if (!broadcastIntegration.data.currentFixture?.geniusID) {
    errors.push('Broadcast integration enabled and fixture not matched')
    return { isComplete: false, errors }
  }

  let isComplete = true

  // Loop through player matches and check if genius id exists or the player is ignored
  for (const teamId in teamsAndPlayers) {
    const broadcastIntegrationTeam = teamsAndPlayers[teamId]
    for (const playerId in broadcastIntegrationTeam.playersMap) {
      const broadcastIntegrationPlayer =
        broadcastIntegrationTeam.playersMap[playerId]

      if (broadcastIntegrationPlayer.isFetching) {
        errors.push('There are still players being matched please wait')
        isComplete = false
        break
      }

      if (
        !broadcastIntegrationPlayer.broadcastIntegrationResult.geniusID &&
        !broadcastIntegration.ignoredPlayerIds.includes(playerId)
      ) {
        errors.push('Player has failed to match and not been ignored')
        isComplete = false
        break
      }
    }
  }

  return { isComplete, errors }
}

export type SessionBroadcastIntergrationCompletion = ReturnType<
  typeof isBroadcastIntegrationSessionSetupComplete
>
