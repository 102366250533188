import {
  SET_SESSION_CHALLENGES,
  CREATE_CHALLENGE,
  UPDATE_CHALLENGE,
  SET_SELECTED_CHALLENGE,
  SET_SELECTED_TARGET,
  CREATE_TARGET,
  UPDATE_TARGET,
  DELETE_TARGET,
  ADD_TARGET_PLAYER_SESSION,
  REMOVE_TARGET_PLAYER_SESSION
} from './types'

import { REMOVE_USER_DATA } from '../types'

import _ from 'lodash'

import { TargetsState } from './types'

const initialState: TargetsState = {
  items: {},
  sessionId: null,
  selected: {
    id: null,
    selectedTarget: null
  },
  blankTarget: {
    id: null,
    hangTime: {
      greaterThan: 0,
      lessThan: 0
    },
    dist: {
      greaterThan: 0,
      lessThan: 0
    },
    efficiency: {
      greaterThan: 0,
      lessThan: 0
    },
    endOverEndEfficiency: {
      greaterThan: 0,
      lessThan: 0
    },
    spin: {
      greaterThan: 0,
      lessThan: 0
    },
    x: -10,
    y: 50,
    radii: [2, 6, 10],
    targetPlayerSessions: null,
    subSessionId: null,
    targetSummary: {}
  }
}

export function targetsReducer(state = initialState, action) {
  let data, challengeId
  switch (action.type) {
    case SET_SESSION_CHALLENGES:
      data = action.payload.data.map((challenge) => {
        if (!challenge.Targets) challenge.Targets = []
        return challenge
      })
      data = _.keyBy(action.payload.data, 'id')

      let selectedChallenge = state.selected

      if (!data[state.selected.id]) {
        selectedChallenge = {
          id: action.payload.data[0] ? action.payload.data[0].id : null,
          selectedTarget: action.payload.firstTarget
            ? action.payload.firstTarget.id
            : null
        }
      }

      return {
        ...state,
        items: data,
        sessionId: action.payload.sessionId || state.sessionId,
        selected: selectedChallenge
      }
    case CREATE_CHALLENGE:
      if (!action.payload.data.Targets) action.payload.data.Targets = []
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.data.id]: action.payload.data
        },
        selected: {
          id: action.payload.data.id,
          selectedTarget: null
        }
      }

    case UPDATE_CHALLENGE:
      if (!action.payload.Targets) action.payload.Targets = []
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload
        }
      }

    case SET_SELECTED_CHALLENGE:
      return {
        ...state,
        selected: {
          id: action.payload.id,
          selectedTarget: action.payload.Targets[0]
            ? action.payload.Targets[0].id
            : null
        }
      }

    case SET_SELECTED_TARGET:
      return {
        ...state,
        selected: {
          ...state.selected,
          selectedTarget: action.payload
        }
      }

    case CREATE_TARGET:
      challengeId = action.payload.subSessionId
      return {
        ...state,
        items: {
          ...state.items,
          [challengeId]: {
            ...state.items[challengeId],
            Targets: [...state.items[challengeId].Targets, action.payload]
          }
        },
        selected: {
          ...state.selected,
          selectedTarget: action.payload.id
        }
      }

    case UPDATE_TARGET:
      challengeId = action.payload.subSessionId
      data = state.items[challengeId].Targets.map((target) => {
        if (target.id === action.payload.id) return action.payload
        return target
      })
      return {
        ...state,
        items: {
          ...state.items,
          [challengeId]: {
            ...state.items[challengeId],
            Targets: [...data]
          }
        }
      }

    case DELETE_TARGET:
      challengeId = action.payload.subSessionId
      let challenge = state.items[challengeId]
      data = challenge.Targets.filter((target) => {
        if (target.id === action.payload.id) return false
        return true
      })
      let selectedTarget = null
      if (
        action.payload.id === state.selected.selectedTarget &&
        data.length > 0
      ) {
        selectedTarget = data[0].id
      }
      return {
        ...state,
        items: {
          ...state.items,
          [challengeId]: {
            ...state.items[challengeId],
            Targets: [...data]
          }
        },
        selected: {
          ...state.selected,
          selectedTarget
        }
      }

    case ADD_TARGET_PLAYER_SESSION: {
      challengeId = action.payload.challengeId
      data = state.items[challengeId].Targets.map((target) => {
        if (!target.targetPlayerSessions) target.targetPlayerSessions = []
        if (target.id === action.payload.data.targetId)
          return {
            ...target,
            targetPlayerSessions: [
              ...target.targetPlayerSessions,
              action.payload.data
            ]
          }
        return target
      })
      return {
        ...state,
        items: {
          ...state.items,
          [challengeId]: {
            ...state.items[challengeId],
            Targets: data
          }
        }
      }
    }
    case REMOVE_TARGET_PLAYER_SESSION: {
      challengeId = action.payload.challengeId
      data = state.items[challengeId].Targets.map((target) => {
        if (!target.targetPlayerSessions) return target
        if (target.id === action.payload.targetPlayerSession.targetId)
          return {
            ...target,
            targetPlayerSessions: target.targetPlayerSessions.filter(
              (x) => x.id !== action.payload.targetPlayerSession.id
            )
          }
        return target
      })
      return {
        ...state,
        items: {
          ...state.items,
          [challengeId]: {
            ...state.items[challengeId],
            Targets: data
          }
        }
      }
    }

    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
