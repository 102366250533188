import { defaultPitch } from './pitch'
import { australianRulesEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'
import dataTypes from './data_types_config.json'
import {
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../../metrics/data_types'

export const australianRulesProps = {
  eventTypes: australianRulesEventTypes,
  playerSummaryMetricTypes: getMetricTypeGroup(
    getMetricTypesConfigFromJson(
      dataTypes.playerSummary.metrics,
      metricTypesConfig
    )
  ),
  pitch: {
    default: defaultPitch,
    setupTypes: pitchSetupTypes,
    minPadding2D: 50,
    minPadding3D: 50,
    cameraYScale: 0,
    textureCanvasScale: 8,
    enableBench: true
  },
  features: {
    targetEvents: ['kick'],
    positionNumbers: false,
    maxPlayers: 22,
    defaultUnitSystem: 'Metric',
    keyboardShortcuts: {},
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false,
    bibs: {}
  } as const
}
