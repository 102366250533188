import React, { useReducer } from 'react'
import { Button } from '../Button/Button'
import Checkbox from '../Forms/Inputs/Checkbox/Checkbox'
import styles from './NetworkSettings.module.scss'
import { environment } from '../../metrics_server/env'

export interface NetworkSettingsProps {
  children: any
}

export const NetworkSettings = (props) => {
  const initialState = {
    newUrl: environment.metricsServer.host,
    newPort: environment.metricsServer.port,
    newProto: environment.metricsServer.protocol
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'update_url':
        return { ...state, newUrl: action.payload }
      case 'update_port':
        return { ...state, newPort: action.payload }
      case 'update_proto':
        return { ...state, newProto: action.payload }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const handleUrlChange = (event) => {
    dispatch({ type: 'update_url', payload: event.target.value })
  }

  const handlePortChange = (event) => {
    dispatch({ type: 'update_port', payload: event.target.value })
  }

  const switchUrl = () => {
    window.localStorage.setItem('ms_host', state.newUrl)
    window.localStorage.setItem('ms_port', state.newPort)
    window.localStorage.setItem('proto', state.newProto)

    window.localStorage.removeItem('user')
    window.location.reload()
  }

  const handleCheckboxChange = (value) => {
    dispatch({
      type: 'update_proto',
      payload: value ? 'https://' : 'http://'
    })
  }

  const { newProto } = state

  return (
    <div className={styles.networkSettingsContainer}>
      <div>
        <h5>Current server IP:</h5>
        <p>{environment.metricsServer.host}</p>
      </div>
      <div>
        <div>
          <h5>Select new server IP:</h5>
          <input value={state.newUrl} onChange={handleUrlChange} />
        </div>
        <div>
          <h5>Port:</h5>
          <input value={state.newPort} onChange={handlePortChange} />
        </div>
        <div>
          <Checkbox
            onClicked={(value) => handleCheckboxChange(value)}
            label='SSL'
            checked={newProto === 'https://'}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            id='submit-team'
            type='submit'
            className='btn--primary'
            handleClick={switchUrl}
          >
            Switch
          </Button>
          <Button
            id='submit-team'
            type='submit'
            className='btn--plain'
            handleClick={props.handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}
