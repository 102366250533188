import React from 'react'
import { sportableColors } from '../../constants/sportableColors'
import { useTheme } from '../../ui/config/hook'

export interface PitchLinesBackgroundProps {
  id?: string
}

export const PitchLinesBackground = ({ id }: PitchLinesBackgroundProps) => {
  const theme = useTheme()
  const fillColor = theme
    ? theme.appColor
    : sportableColors.colors.sportableBlue

  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 1024 768'
      fill='none'
      preserveAspectRatio='xMidYMid slice'
      xmlns='http://www.w3.org/2000/svg'
      style={{ backgroundSize: 'cover' }}
      id={id}
    >
      <g clipPath='url(#clip0_1314_13881)'>
        <path d='M1024 0H0V768H1024V0Z' fill={fillColor} />
        <mask
          id='mask0_1314_13881'
          // style='mask-type:luminance'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='1024'
          height='768'
        >
          <path d='M1024 0H0V768H1024V0Z' fill='white' />
        </mask>
        <g mask='url(#mask0_1314_13881)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M-611.16 333.361L363.688 2021.85L1367.65 1442.21L392.805 -246.278L-611.16 333.361ZM-594.76 337.765L368.08 2005.45L1351.26 1437.81L388.419 -229.873L-594.76 337.765Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M-82.4166 -184.871L-92.1955 -179.226L166.22 268.363L172.218 278.752L181.997 273.106L-82.4166 -184.871Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M-130.108 270.761L-124.462 280.54L103.712 148.804L98.0657 139.025L-130.108 270.761Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M-436.895 623.904L-434.078 628.784L560.108 54.7906L557.291 49.9109L-436.895 623.904Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M-285.399 886.304L-282.576 891.194L711.61 317.2L708.787 312.311L-285.399 886.304Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M-133.902 1148.7L-131.079 1153.59L863.107 579.6L860.284 574.711L-133.902 1148.7Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M260.917 225.369L311.729 313.378L316.619 310.556L265.806 222.546L260.917 225.369Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M433.677 125.626L484.489 213.635L489.379 210.812L438.566 122.803L433.677 125.626Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M441.727 203.555L444.55 208.444L509.742 170.805L506.92 165.916L441.727 203.555Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M282.14 326.115L284.963 331.005L350.156 293.366L347.333 288.477L282.14 326.115Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M127.308 415.508L130.131 420.397L164.357 400.637L161.534 395.747L127.308 415.508Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M23.0002 475.73L25.8231 480.62L60.0492 460.859L57.2263 455.97L23.0002 475.73Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M492.143 228.774L505.317 251.592L512.651 247.357L499.477 224.54L492.143 228.774Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M512.845 264.63L526.018 287.447L533.352 283.213L520.179 260.396L512.845 264.63Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M533.546 300.486L546.72 323.303L554.054 319.069L540.88 296.252L533.546 300.486Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M555.189 337.972L568.362 360.789L575.696 356.555L562.523 333.737L555.189 337.972Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M575.89 373.828L589.064 396.645L596.398 392.411L583.224 369.593L575.89 373.828Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M596.591 409.683L609.765 432.501L617.099 428.266L603.926 405.449L596.591 409.683Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M618.234 447.169L631.407 469.986L638.742 465.752L625.568 442.935L618.234 447.169Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M638.935 483.025L652.109 505.842L659.443 501.608L646.269 478.791L638.935 483.025Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M659.637 518.881L672.81 541.698L680.144 537.464L666.971 514.647L659.637 518.881Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M681.279 556.367L694.452 579.184L701.787 574.95L688.613 552.132L681.279 556.367Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M701.98 592.222L715.154 615.04L722.488 610.805L709.314 587.988L701.98 592.222Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M722.682 628.078L735.855 650.896L743.19 646.661L730.016 623.844L722.682 628.078Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M744.324 665.564L757.498 688.382L764.832 684.147L751.658 661.33L744.324 665.564Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M765.025 701.42L778.199 724.237L785.533 720.003L772.36 697.186L765.025 701.42Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M785.727 737.276L798.9 760.093L806.235 755.859L793.061 733.042L785.727 737.276Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M394.661 458.902L428.536 517.576L435.87 513.341L401.995 454.668L394.661 458.902Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M469.939 589.287L503.814 647.961L511.148 643.726L477.273 585.053L469.939 589.287Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M545.217 719.672L579.092 778.346L586.426 774.111L552.551 715.438L545.217 719.672Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M-40.509 710.148L-6.63394 768.821L0.709991 764.581L-33.165 705.908L-40.509 710.148Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_1314_13881'>
          <rect width='1024' height='768' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
