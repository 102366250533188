export interface GraphicsState {
  // HighestHangTimeKick: {
  //   '1x1': {
  //     image: null
  //     generating: false
  //   }
  //   '16x9': {
  //     image: null
  //     generating: false
  //   }
  // }
  graphicsTypes: any
  tournaments: any
  aspectRatios: any
}

export const UPDATE_GRAPHIC_STATE = 'UPDATE_GRAPHIC_STATE'
export const SET_GRAPHICS_TYPES = 'SET_GRAPHICS_TYPES'
export const SET_TOURNAMENTS = 'SET_TOURNAMENTS'
export const SET_ASPECT_RATIOS = 'SET_ASPECT_RATIOS'
