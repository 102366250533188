import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { CardList } from '../../../components/CardList/CardList'
import { CanvasStyle, Strack } from '../../../components/Strack/Strack.types'
import { Table } from '../../../components/Table/Table'
import { Anchor } from '../../../metrics_server/pitches/types'
import styles from './Pitch.module.scss'
import {
  useFormattedHardware,
  useHardware,
  useRawWebsocketPacketCapture
} from '../../../metrics_server/hardware/hooks'
import { setSelectedDevice } from '../../../metrics_server/hardware/functions'
import { useAppDispatch } from '../../../store/hooks'
import BasicSelect from '../../../components/Material/Select'
import { usePitches } from '../../../metrics_server/pitches/hooks'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

export interface AnchorHealthProps {
  strack: Strack
  strackReady: boolean
  canvasStyle: CanvasStyle
  active: boolean
}

export function Pitch({ strack, strackReady }: AnchorHealthProps) {
  const dispatch = useAppDispatch()
  const { inUse } = usePitches()
  const { selectedDevice } = useHardware()
  const formattedHardware = useFormattedHardware()
  const { onlinePacketCapture } = useRawWebsocketPacketCapture()
  const selectedDeviceLastPacket = onlinePacketCapture?.[selectedDevice]

  // show / hide anchor table //

  const [showAnchorTable, setShowAnchorTable] = useState(true)

  // Handle anchor selection //

  const [selectedAnchors, setSelectedAnchors] = useState<Anchor[]>(
    _.values(inUse.anchors)
  )

  const toggleAnchor = useCallback(
    (anchorId) => {
      if (!anchorId) {
        setSelectedAnchors([])
      } else if (anchorId === 'all') {
        setSelectedAnchors(_.values(inUse.anchors))
      } else {
        const anchor = { ...inUse.anchors[anchorId] }
        console.log(anchor)
        let newSelectedAnchorArray = [...selectedAnchors]

        if (
          newSelectedAnchorArray.some(
            (selectedAnchor) => anchor.id === selectedAnchor.id
          )
        ) {
          newSelectedAnchorArray = newSelectedAnchorArray.filter(
            (selectedAnchor) => anchor.id !== selectedAnchor.id
          )
        } else {
          newSelectedAnchorArray.push(anchor)
        }

        setSelectedAnchors(newSelectedAnchorArray)
      }
    },
    [selectedAnchors]
  )

  // Update selected acnhors in strack //
  useEffect(() => {
    if (strackReady) {
      strack.diags.selectedAnchors = selectedAnchors
    }
  }, [selectedAnchors, strackReady, strack])

  // ======================= //

  // Generate anchor table data //

  const anchorTableHeaders = [
    { name: 'Anchor ID', key: 'encodedId', width: 20 },
    { name: 'Dist', key: 'dist', width: 34 },
    { name: 'RSSI', key: 'rssi', width: 34 },
    {
      name: '',
      key: 'selected',
      width: 12,
      input: {
        type: 'checkbox',
        onChange: (item, value) => {
          toggleAnchor(item.id)
        }
      }
    }
  ]

  const anchorTableData = useMemo(() => {
    const { anchors } = inUse
    const anchorTableData = []
    for (const anchorId in anchors) {
      const anchor = anchors[anchorId]
      const row = {
        id: parseInt(anchorId),
        encodedId: anchor.encodedId,
        selected: selectedAnchors.some((x) => x.id === anchor.id),
        index: anchor.index,
        dist: null,
        rssi: null
      }

      // Set tag data
      if (selectedDeviceLastPacket) {
        const ranges = selectedDeviceLastPacket.ranges
        if (ranges) {
          const rangeItem = selectedDeviceLastPacket.ranges.find(
            (item) => item.source === anchor.id
          )
          if (rangeItem) {
            row.dist = rangeItem.range.toFixed(1) || '-'
            row.rssi = rangeItem.rssi.toFixed(1) || '-'
          }
        }
      }

      // Only add to table of anchor is online
      if (formattedHardware.devices.map[anchor.id]) anchorTableData.push(row)
    }
    return anchorTableData
  }, [selectedDeviceLastPacket, inUse, selectedAnchors, formattedHardware])

  const anchorTableControls = [
    { name: 'Clear all', callback: () => toggleAnchor(null) },
    { name: 'Select all', callback: () => toggleAnchor('all') }
  ]

  // ======================= //

  // Generate tag table data //

  const tagTableHeaders = [
    { name: 'Tag ID', key: 'encodedId', width: 20 },
    { name: 'Meas Len', key: 'measLength', width: 20 },
    { name: 'IMU Rate', key: 'imu', width: 20 },
    { name: 'Acc Std Dev', key: 'acc', width: 28 },
    {
      name: '',
      key: 'selected',
      width: 12,
      input: {
        type: 'checkbox',
        onChange: (item, value) => {
          dispatch(setSelectedDevice(item.id))
        }
      }
    }
  ]

  const tagTableData = useMemo(() => {
    const tagTableData = []
    for (const tagId in onlinePacketCapture) {
      const tag = onlinePacketCapture[tagId]

      const device = formattedHardware.devices.map[tag.id]

      const row = {
        id: tag.id,
        measLength: tag.ranges ? tag.ranges.length : '-',
        encodedId: device?.serial,
        imu: device?.imuLength.value || '-',
        acc: device?.accelerationStdDev.value.toFixed(2) || '-',
        selected: tag.id === selectedDevice
      }

      tagTableData.push(row)
    }
    return tagTableData
  }, [onlinePacketCapture, selectedDevice, formattedHardware])

  const tagOptions = useMemo(() => {
    const options = Object.values(onlinePacketCapture).map((tag) => {
      return {
        value: tag.id,
        name: tag.serial
      }
    })
    options.unshift({ value: null, name: 'Select a tag' })
    return options
  }, [onlinePacketCapture])

  // ======================= //

  return (
    <div className={styles.pitchViewContainer}>
      {/* <div className={styles.tagSelectContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.tagSelect}>
            <BasicSelect
              label={'Tag'}
              options={tagOptions}
              selected={selectedDevice}
              onChange={(value) => dispatch(setSelectedDevice(value))}
              size={'small'}
              variant={'standard'}
            />
          </div>
        </CardList>
      </div> */}
      <div
        className={styles.topTableContainer}
        style={showAnchorTable ? { height: '50%' } : { height: '53px' }}
      >
        {/* Anchor Table */}
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div
            className={styles.anchorTableToggleButton}
            onClick={() => setShowAnchorTable(!showAnchorTable)}
          >
            {showAnchorTable ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </div>

          <div className='card-table-container'>
            {showAnchorTable && (
              <Table
                id={'anchors'}
                options={{
                  initialOrder: 'asc',
                  initialSortBy: 'index',
                  sortActive: true
                }}
                headerFont={13}
                tableClass={'minimalistBlack'}
                className={'container'}
                data={anchorTableData}
                smallHead={true}
                headers={anchorTableHeaders}
                controls={anchorTableControls}
              />
            )}
          </div>
        </CardList>
      </div>
      <div
        className={styles.bottomRightTableContainer}
        style={
          showAnchorTable ? { height: '50%' } : { height: 'calc(100% - 53px)' }
        }
      >
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${2}`}
          className='maxHeight'
        >
          <div className='card-table-container'>
            <Table
              searchBy={['encodedId']}
              options={{
                initialOrder: 'asc',
                initialSortBy: 'id',
                sortActive: true
              }}
              headerFont={13}
              tableClass={'minimalistBlack'}
              smallHead={true}
              className={'container'}
              data={tagTableData}
              headers={tagTableHeaders}
            />
          </div>
        </CardList>
      </div>
    </div>
  )
}
