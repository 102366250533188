import { ViewType } from '../../components/Views/Views.types'
import { useHeaderProps } from './header'
import { tabConfig, useTabConfig } from './tab_config'
import { DashboardProps } from '../../components/Views/Dashboard/Dashboard.types'

export const path = '/home'

export const route = {
  path,
  view: {
    type: ViewType.DASHBOARD,
    name: 'Name',
    tabs: tabConfig,
    useTabConfig: useTabConfig,
    useHeaderProps
  } as DashboardProps
}
