import styles from './MultiState.module.scss'

export type MultiStateProps = {
  name: string
  active?: boolean
  color?: string
  number?: number | JSX.Element
  handleClick?: () => void
  disableHover?: boolean
}

export const MultiState = ({
  name,
  active = false,
  color = 'transparent',
  number,
  handleClick,
  disableHover = false
}: MultiStateProps) => {
  return (
    <div className={styles.statusContainer}>
      <div
        style={{ background: color }}
        className={`${styles.light} ${active ? styles.on : styles.off}`}
      ></div>
      <div
        onClick={() => {
          if (handleClick) handleClick()
        }}
        className={`${styles.statusName} ${
          disableHover ? styles.disableHover : ''
        }`}
      >
        {number === undefined ? (
          <h5 className={styles.statusText}>{name}</h5>
        ) : (
          <h5 className={styles.statusText}>
            {name}: {number}
          </h5>
        )}
      </div>
    </div>
  )
}
