import React, { Component, CSSProperties } from 'react'
import styles from './TextInput.module.scss'
import hidePasswordSvg from '../../../../assets/img/hide_password.svg'
import showPasswordSvg from '../../../../assets/img/show_password.svg'

export type OnChange = (e) => void
export type OnChangeTyped<T> = (e: T) => void

interface Input {
  value: string | number
  defaultValue?: string | number
  name: string
  onChange: OnChange
}

interface Meta {
  touched: boolean
  error: boolean
  initial: any
}

type SetLimits = (value: any) => any

interface TextInputProps {
  input: Input
  meta?: Meta
  type?: string
  setLimits?: SetLimits
  label?: string
  small?: boolean
  disabled?: boolean
  autoComplete?: string
  style?: CSSProperties
  saveUser?: (data) => void
}

interface TextInputState {
  selected: boolean
  showPassword: boolean
}

class TextInput extends Component<TextInputProps, TextInputState> {
  private input: HTMLInputElement
  constructor(props) {
    super(props)

    let selected = false
    if (props.input.value || props.input.value === 0) selected = true

    this.state = {
      selected,
      showPassword: false
    }

    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleOnKeyPress = this.handleOnKeyPress.bind(this)
    this.focus = this.focus.bind(this)

    this.input = null
  }

  componentDidMount() {
    if (this.props.meta) {
      if (this.props.meta.initial || this.props.meta.initial === 0) {
        this.setState({
          selected: true
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.input.value && this.props.input.value) {
      this.setState({
        selected: true
      })
    }
  }

  handleFocus(e) {
    this.setState({
      selected: true
    })
  }

  handleBlur(e) {
    let { value, name } = e.target
    if (!value && value !== 0) {
      this.setState({
        selected: false
      })
    }

    const { type, setLimits } = this.props
    if (type === 'number' && setLimits) {
      value = setLimits(value)
    }

    if (this.props.saveUser) {
      let data = {}
      data[name] = value
      this.props.saveUser(data)
    }
  }

  handleOnKeyPress(e) {
    let { value, name } = e.target
    const { type, setLimits } = this.props
    if (type === 'number' && setLimits) {
      if (e.charCode == 13) {
        setLimits(value)
      } else if (e.keyCode == 13) {
        setLimits(value)
      }
    }
  }

  focus() {
    if (!this.state.selected) {
      this.setState({
        selected: true
      })
      this.input.focus()
    }
  }

  toggleShowPassword = () => {
    const { showPassword } = this.state
    this.setState({ showPassword: !showPassword })
  }

  render() {
    const { label, meta, type, input, small, disabled, style } = this.props
    const { showPassword, selected } = this.state

    let inputType = type

    if (type === 'password' && showPassword) {
      inputType = 'text'
    }

    return (
      <div>
        <div className={styles.inputContainer}>
          <input
            autoComplete='new-password'
            ref={(div) => (this.input = div)}
            onBlur={this.handleBlur}
            onKeyPress={this.handleOnKeyPress}
            onFocus={!disabled && this.handleFocus}
            onChange={!disabled && input.onChange}
            name={input.name}
            value={input.value}
            defaultValue={input.defaultValue}
            className={`${
              meta && meta.touched && meta.error && styles.requiredInput
            } ${disabled && styles.disabled} ${
              small ? styles.smallTextInput : ''
            }`}
            type={inputType}
            style={style}
          />
          {type === 'password' && (
            <div
              onClick={this.toggleShowPassword}
              className={styles.showPasswordContainer}
            >
              <img
                src={showPassword ? hidePasswordSvg : showPasswordSvg}
                alt=''
                className={styles.showPasswordIcon}
              />
            </div>
          )}
          <div
            onClick={this.focus}
            className={
              selected
                ? `${styles.dynamicLabel} ${styles.inputSelected}`
                : styles.dynamicLabel
            }
          >
            {label}
          </div>
          {meta && meta.touched && meta.error && (
            <div className={styles.fieldAsterix}>
              <span>*</span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default TextInput
