import React, { useState, useCallback, useEffect } from 'react'
import { useAppDispatch } from '../../store/hooks'
import {
  useInsights,
  useSelectedLayout
} from '../../metrics_server/insights/hooks'
import { isLayoutEdited } from '../../metrics_server/insights/functions'
import {
  editInsightsLayout,
  removeInsightsLayoutItem
} from '../../metrics_server/insights/actions'

import { Responsive, WidthProvider } from 'react-grid-layout'
import { CommentatorAddNewButton } from '../CommentatorAddNewButton/CommentatorAddNewButton'
import { InsightsTable } from '../InsightsTable/InsightsTable'

import styles from './CommentatorTableGrid.module.scss'
import './styles.css'
import {
  InsightsDashboardLayout,
  ReactGridLayout,
  LayoutTypes
} from '../../metrics_server/insights/types'
import { useInsightsTableFormModal } from '../../views/Session/modals/insights_forms'

const ResponsiveGridLayout = WidthProvider(Responsive)

export interface CommentatorTableGridProps {
  layoutType: LayoutTypes
}

export const CommentatorTableGrid = ({
  layoutType
}: CommentatorTableGridProps) => {
  // Modals //
  const { openInsightsTableFormModal } = useInsightsTableFormModal()
  // ====== //

  const dispatch = useAppDispatch()

  const { selectedLayout } = useSelectedLayout(layoutType)

  const handleLayoutChange = useCallback(
    (gridLayout: ReactGridLayout) => {
      const editedLayout: InsightsDashboardLayout = {
        ...selectedLayout,
        layout: {
          ...selectedLayout.layout,
          reactGridLayouts: {
            ...selectedLayout.layout.reactGridLayouts,
            tables: {
              ...selectedLayout.layout.reactGridLayouts.tables,
              layout: gridLayout
            }
          }
        }
      }
      dispatch(editInsightsLayout(editedLayout))
    },
    [selectedLayout]
  )

  const removeTable = useCallback((itemId) => {}, [selectedLayout])

  const renderInsightsTable = (layoutItem) => {
    const contentId =
      selectedLayout.layout.reactGridLayouts.tables.contentMap[layoutItem.i]

    return (
      <div key={layoutItem.i}>
        <InsightsTable
          layoutItem={layoutItem}
          contentId={contentId}
          removeItem={() => {
            dispatch(
              removeInsightsLayoutItem(
                selectedLayout.id,
                layoutItem.i,
                'tables'
              )
            )
          }}
        />
      </div>
    )
  }

  if (!selectedLayout) return <noscript />

  return (
    <>
      <ResponsiveGridLayout
        className='layout'
        layouts={{
          lg: selectedLayout.layout.reactGridLayouts.tables.layout,
          md: selectedLayout.layout.reactGridLayouts.tables.layout,
          sm: selectedLayout.layout.reactGridLayouts.tables.layout,
          xs: selectedLayout.layout.reactGridLayouts.tables.layout,
          xxs: selectedLayout.layout.reactGridLayouts.tables.layout
        }}
        cols={{ lg: 4, md: 4, sm: 4, xs: 4, xxs: 4 }}
        width={'100%'}
        onDragStop={handleLayoutChange}
        onResizeStop={handleLayoutChange}
        isBounded={true}
        measureBeforeMount={true}
        useCSSTransforms={false}
      >
        {selectedLayout.layout.reactGridLayouts.tables.layout.map(
          renderInsightsTable
        )}
      </ResponsiveGridLayout>
      <div className={styles.addNewTableWrapper}>
        <CommentatorAddNewButton
          handleClick={() => openInsightsTableFormModal(null)}
        />
      </div>
    </>
  )
}
