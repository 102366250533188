import React, { useState } from 'react'
import JSONUpload from '../json_upload'
import { TotalStationEditor } from '../../../TotalStationEditor/TotalStationEditor'
import { Toggle } from '../../../Toggle/Toggle'
import styles from '../styles.module.scss'
import FileInput from '../../Inputs/FileInput/FileInput'
import TextInput from '../../Inputs/TextInput/TextInput'
import { Button } from '../../../Button/Button'
import Checkbox from '../../Inputs/Checkbox/Checkbox'
import { useAnchorPitchSetup } from '../../../../metrics_server/pitches/hooks'
import { useFormattedHardware } from '../../../../metrics_server/hardware/hooks'
import { toggleModal } from '../../../../ui/modal/actions'
import { setError } from '../../../../ui/error/actions'
import { useAppDispatch } from '../../../../store/hooks'
import {
  CSVFile,
  JSONUploadState
} from '../../../../metrics_server/pitches/types'
import { pitchSetupTypes } from '../../../../metrics_server/pitches/data_types'

const TotalStationSetupForm = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const formattedHardware = useFormattedHardware()

  const [csvFile, setCsvFile] = useState<CSVFile>({
    anchorExtension: '',
    anchorFile: '',
    anchorFileWithoutIgnored: undefined,
    anchorJson: undefined,
    anchorValue: '',
    name: '',
    pitchExtension: '',
    pitchFile: undefined,
    pitchFileWithoutIgnored: undefined,
    pitchJson: undefined,
    pitchValue: '',
    save: false
  })

  const { handleAnchorPitchSetup } = useAnchorPitchSetup()

  const [uploadType, setUploadType] = useState<'json' | 'csv'>('csv')
  const [json, setJson] = useState<JSONUploadState>({})
  const handlePitchNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    setCsvFile((file) => {
      return {
        ...file,
        name: e.target.value
      }
    })
  }

  const removeTotalStationCSV = (isPitch?) => {
    setCsvFile((file) => {
      if (isPitch) {
        return {
          ...file,
          pitchValue: '',
          pitchFile: null,
          pitchExtension: '',
          pitchFileWithoutIgnored: null,
          pitchJson: null
        }
      } else {
        return {
          ...file,
          anchorValue: '',
          anchorFile: null,
          anchorExtension: '',
          anchorFileWithoutIgnored: null,
          anchorJson: null
        }
      }
    })
  }

  const handleAnchorCSVFileChange = async (name, files, value) => {
    const fileChange = files[0]
    if (fileChange) {
      setCsvFile((file): CSVFile => {
        return {
          ...file,
          anchorValue: value,
          anchorFile: fileChange,
          anchorExtension: fileChange.type
        }
      })
      openTotalStationCSVEditor(fileChange)
    }
  }

  const handlePitchCSVFileChange = async (name, files, value) => {
    const fileChange = files[0]
    if (fileChange) {
      setCsvFile((file): CSVFile => {
        return {
          ...file,
          pitchValue: value,
          pitchFile: fileChange,
          pitchExtension: fileChange.type
        }
      })
      openTotalStationCSVEditor(fileChange, true)
    }
  }

  const handleSavePitchChange = (value) => {
    setCsvFile((file) => {
      return {
        ...file,
        save: value
      }
    })
  }
  const updateAnchorCSVFile = (newFile, json, fileWithoutIgnored) => {
    setCsvFile((file): CSVFile => {
      return {
        ...file,
        anchorFile: newFile,
        anchorFileWithoutIgnored: fileWithoutIgnored,
        anchorJson: json
      }
    })
  }
  const updatePitchCSVFile = (newFile, json, fileWithoutIgnored) => {
    setCsvFile((file): CSVFile => {
      return {
        ...file,
        pitchFile: newFile,
        pitchFileWithoutIgnored: fileWithoutIgnored,
        pitchJson: json
      }
    })
  }

  const openTotalStationCSVEditor = (file: string, pitchOnly?: boolean) => {
    dispatch(
      toggleModal({
        active: true,
        handleProceed: () => {
          dispatch(toggleModal({}))
        },
        wrapper: true,
        title: 'Update Total Station CSV',
        width: '60%',
        handleClose: () => {
          dispatch(toggleModal({}))
        },
        ChildComponent: (props) => (
          <TotalStationEditor
            totalStationCSVFile={file}
            invalidTotalStationCsvHandler={(errorMessage) => {
              dispatch(
                setError({
                  message: errorMessage
                })
              )
              removeTotalStationCSV()
            }}
            pitchOnly={pitchOnly}
            anchors={formattedHardware.types.anchor.devices}
            updateCsv={(file, json, fileWithoutIgnored) => {
              dispatch(toggleModal({}))
              if (pitchOnly) {
                updatePitchCSVFile(file, json, fileWithoutIgnored)
              } else {
                updateAnchorCSVFile(file, json, fileWithoutIgnored)
              }
            }}
            {...props}
          />
        ),
        className: 'modalSmall'
      })
    )
  }

  return (
    <div>
      <div className={styles.totalStationContainer}>
        <div className={styles.csvInputs}>
          <div>
            <FileInput
              // small={true}
              name={'anchorCsvFile'}
              label={'Import anchor (and pitch) csv:'}
              onChange={handleAnchorCSVFileChange}
              value={csvFile.anchorValue}
              accept={'.csv, .txt'}
            />
            <div className={styles.checkbox}>
              {csvFile?.anchorFile ? (
                <p style={{ fontSize: '13px' }}>
                  {csvFile.anchorValue}
                  {' - '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      openTotalStationCSVEditor(csvFile.anchorFile)
                    }
                  >
                    edit
                  </span>
                  {' - '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => removeTotalStationCSV(false)}
                  >
                    X
                  </span>
                </p>
              ) : (
                <p style={{ fontSize: '13px' }}>No file uploaded</p>
              )}
            </div>
          </div>
          <div>
            <FileInput
              // small={true}
              name={'pitchCsvFile'}
              label={'Import separate pitch csv (optional):'}
              onChange={handlePitchCSVFileChange}
              value={csvFile.pitchValue}
              accept={'.csv, .txt'}
            />
            <div className={styles.checkbox}>
              {csvFile?.pitchFile ? (
                <p style={{ fontSize: '13px' }}>
                  {csvFile.pitchValue}
                  {' - '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      openTotalStationCSVEditor(csvFile.pitchFile, true)
                    }
                  >
                    edit
                  </span>
                  {' - '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => removeTotalStationCSV(true)}
                  >
                    X
                  </span>
                </p>
              ) : (
                <p style={{ fontSize: '13px' }}>No file uploaded</p>
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.savePitch}`}>
          <div className={styles.checkbox}>
            <Checkbox
              label={`Save pitch to database`}
              size='small'
              onClicked={handleSavePitchChange}
              checked={csvFile?.save}
            />
          </div>
          {csvFile?.save && (
            <React.Fragment>
              <div className={styles.smallLabel}>Pitch name:</div>
              <div style={{ width: '60%' }}>
                <TextInput
                  small={true}
                  input={{
                    onChange: handlePitchNameChange,
                    name: 'pitchName',
                    value: csvFile.name
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <div>
          <Button
            disabled={!csvFile.anchorValue}
            handleClick={() => {
              handleAnchorPitchSetup(
                pitchSetupTypes.items.totalStation.value,
                csvFile
              )
            }}
            className='btn--primary'
          >
            Post Data
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TotalStationSetupForm
