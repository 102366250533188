import { UPDATE_USER, SET_USER } from './action_types'
import { UserState } from './types'

export function userReducer(
  state: UserState = {
    data: null
  },
  action
) {
  let user
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        data: action.payload
      }
    case UPDATE_USER:
      user = {
        ...state.data,
        ...action.payload
      }
      return {
        ...state,
        data: user
      }
    default:
      return state
  }
}
