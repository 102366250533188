import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { MainProps, Tab, Tabs } from './Main.types'
import { Option } from '../Views.types'
import { Header } from './Header/Header'
import { Content } from './Content/Content'

import { NavBar } from '../../Navbar/Navbar'
import viewAnimations from '../animations'
import { useRouter } from '../../../ui/router/hooks'
import { useAppDispatch } from '../../../store/hooks'
import { setRedirect, setRedirectPop } from '../../../ui/router/actions'
import {
  useDefaultHeaderProps,
  useDefaultTabConfig,
  useNoNavbarProps
} from './hooks'

import styles from '../Views.module.scss'

export interface MainViewProps {
  path: string
  view: MainProps
  location: Location
  history: string[]
  switchToTab?: string
}

export type SwitchTab = (tabKey: string) => void

const getTabConfig = (tabs: Tabs, initialTab: Tab) => {
  if (!tabs)
    return {
      tabs: null,
      tabsArray: null,
      initialActiveTabKey: null,
      initialSelectedTabKey: null
    }

  let tabsArray: Tab[], activeTab: Tab, selectedTab: Tab

  if (tabs) {
    tabsArray = Object.values(tabs)
    // Sort tabs based on index
    tabsArray.sort(function (a, b) {
      const keyA = a.index,
        keyB = b.index
      // Compare the 2 dates
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })

    activeTab = initialTab ? initialTab : tabsArray[0]
    selectedTab = initialTab ? initialTab : tabsArray[0]
  }

  return {
    tabs,
    tabsArray,
    initialActiveTabKey: !!activeTab && activeTab.key,
    initialSelectedTabKey: !!selectedTab && selectedTab.key
  }
}

export function MainView(props: MainViewProps) {
  const dispatch = useAppDispatch()
  const { view, location, history, switchToTab, path } = props

  const router = useRouter()

  const {
    useHeaderProps = useDefaultHeaderProps,
    useNavbarProps = useNoNavbarProps,
    useTabConfig = useDefaultTabConfig,
    useOptions = () => []
  } = view

  const headerProps = useHeaderProps(view)
  const navbarProps = useNavbarProps(view)

  // ============ Router ================== //
  // Listen for back button and change route
  useEffect(() => {
    if (location.pathname !== router.redirect && location.pathname !== path) {
      dispatch(setRedirectPop(location.pathname))
    }
  }, [location.pathname, router.redirect])

  // Listen for redirect and animate out
  useEffect(() => {
    const { history } = props
    if (router.action === 'PUSH' && router.redirect !== path) {
      history.push(router.redirect)
      animateComponentOut(router.redirect)
    }
  }, [router.redirect])
  // ====================================== //

  /*============== Tabs =======================*/
  const { tabs, initialTab } = useTabConfig(view)

  const { tabsArray, initialSelectedTabKey, initialActiveTabKey } =
    useMemo(() => {
      return getTabConfig(tabs, initialTab)
    }, [tabs, initialTab])

  const [activeTabKey, setActiveTabKey] = useState<string>(initialActiveTabKey)
  const [selectedTabKey, setSelectedTabKey] = useState<string>(
    initialSelectedTabKey
  )

  useEffect(() => {
    setActiveTabKey(initialActiveTabKey)
    setSelectedTabKey(initialSelectedTabKey)
  }, [initialSelectedTabKey, initialActiveTabKey])

  const activeTab = useMemo(() => {
    return tabs[selectedTabKey]
  }, [selectedTabKey, tabs])

  const useTabOptions = useMemo(() => {
    if (activeTab && activeTab.useOptions) {
      return activeTab.useOptions
    }
    return () => ({
      options: []
    })
  }, [activeTab])

  const switchTab: SwitchTab = (tabKey) => {
    if (activeTabKey !== tabKey) {
      setSelectedTabKey(tabKey)
    }
  }

  useEffect(() => {
    if (switchToTab) {
      setSelectedTabKey(switchToTab)
    }
  }, [switchToTab])
  // =================================== //

  // ============ Animation ================== //
  const [animationComplete, setAnimationComplete] = useState<boolean>(false)
  const headerRef = useRef(null)
  const contentRef = useRef(null)
  const footerRef = useRef(null)

  useEffect(() => {
    animateComponentIn()
  }, [])

  const animateComponentIn = useCallback(() => {
    viewAnimations.slideInHeader(headerRef.current, 0.5, () => {
      viewAnimations.showContent(contentRef.current, 0.5, () => {
        setAnimationComplete(true)
      })
    })
    const isPrevFooter = router.footerRoutes.indexOf(router.prevRoute) >= 0
    const isFooter = router.footerRoutes.indexOf(location.pathname) >= 0
    if (!isPrevFooter && isFooter) {
      viewAnimations.slideInFooter(footerRef.current, 0.5)
    }
  }, [location, router])

  const animateComponentOut = useCallback(
    (nextRoute) => {
      viewAnimations.hideContent(contentRef.current, 0.5, () => {
        // Slide out footer
        const isNextFooter = router.footerRoutes.indexOf(nextRoute) >= 0
        const isFooter = router.footerRoutes.indexOf(location.pathname) >= 0
        if (!isNextFooter && isFooter) {
          viewAnimations.slideOutFooter(footerRef.current, 0.5)
        }

        // Slide out header
        viewAnimations.slideOutHeader(headerRef.current, 0.5, () => {
          history.push(nextRoute)
        })
      })
    },
    [history, router, location]
  )
  // =================================== //

  // ============ Options ================== //
  const viewOptions = useOptions(view)
  const tabOptions = useTabOptions(activeTab)

  const options = useMemo(() => {
    return [...viewOptions, ...tabOptions.options]
  }, [viewOptions, tabOptions])
  // =================================== //

  const isFooter =
    router.footerRoutes.indexOf(location.pathname) >= 0 && navbarProps.enabled

  return (
    <div
      className={!isFooter ? styles.viewContainer : styles.viewContainerFooter}
    >
      <div ref={headerRef} className={styles.header}>
        <Header
          {...headerProps}
          view={view}
          options={options}
          // Tabs //
          switchTab={switchTab}
          selectedTabKey={selectedTabKey}
          tabsArray={tabsArray}
        />
      </div>
      <div ref={contentRef} className={styles.content}>
        <Content
          {...props}
          view={view}
          animationComplete={animationComplete}
          switchTab={switchTab}
          updateActiveTab={setActiveTabKey}
          activeTabKey={activeTabKey}
          selectedTabKey={selectedTabKey}
          tabsArray={tabsArray}
        />
      </div>
      {isFooter && (
        <div ref={footerRef} className='navbar-z-index'>
          <NavBar
            {...navbarProps}
            goTo={(route) => dispatch(setRedirect(route))}
            active={location.pathname}
          />
        </div>
      )}
    </div>
  )
}
