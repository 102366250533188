import React from 'react'
import * as SessionScreen from '../Session/config'

export function useTargetSetupHeaderProps(props) {
  return {
    title: <h4>Targets Setup</h4>,
    backButton: {
      text: 'Session',
      route: SessionScreen.path
    }
  }
}
