import React, { useState } from 'react'
import styles from './ExpandableCard.module.scss'

export interface ExpandableCardProps {
  width: string
  height: string
  dropdown: boolean
  children: React.ReactNode
}

export const ExpandableCard: React.FC<ExpandableCardProps> = ({
  width,
  height,
  children,
  dropdown
}) => {
  const [expanded, setExpanded] = useState(true)

  let style = {
    width,
    height: expanded ? height : '35px'
  }

  return (
    <div style={style} className={styles.pitchOverlayContainer}>
      {dropdown && (
        <div
          onClick={() => setExpanded(!expanded)}
          className={`${styles.closeContainer}`}
        >
          <div
            className={`${styles.arrow} ${expanded ? styles.up : styles.down}`}
          ></div>
        </div>
      )}
      {children}
    </div>
  )
}
