import { SET_INFO } from './types'

import { SetInfo } from './types'

export const setInfo: SetInfo = (info) => {
  return {
    type: SET_INFO,
    payload: info
  }
}
