import { MetricTypeKeys } from '../metrics/data_types'
import { Coordinate, FlightData, RawFlightEventData } from './flight/types'
import { RawTimeEventData, TimeEventData } from './time/types'
import { GameEventData, RawGameEventData } from './game/types'
import {
  AussieRulesEventData,
  RawAussieRulesEventData
} from './aussie_rules/types'
import { FormattedTeam } from '../teams/types'
import { Options } from '../data_types'
import { OutcomeType, OutcomeTypeValues } from '../outcomes/data_types'
import {
  GameEventSubType,
  GameEventSubTypeValues
} from './game/subType/data_types'
import {
  FlightEventSubType,
  FlightEventSubTypeValues
} from './flight/subTypes/data_types'
import {
  GameEventFeatures,
  GameEventType,
  GameEventTypeValues
} from './game/data_types'
import {
  FlightEventFeatures,
  FlightEventType,
  FlightEventTypeValues
} from './flight/data_types'
import {
  AussieRulesEventFeatures,
  AussieRulesEventType,
  AussieRulesEventTypes,
  AussieRulesEventTypeValues
} from './aussie_rules/data_types'
import {
  TimeEventType,
  TimeEventTypeFeatures,
  TimeEventTypeValues
} from './time/data_types'
import { FormattedPlayer } from '../players/types'
import { eventTypes } from './data_types'
import { FormattedMetric } from '../metrics/types'
import { EventTableData } from './functions'
import {
  AussieRulesEventCrossSectionType,
  AussieRulesEventCrossSectionTypeConfig,
  AussieRulesEventCrossSectionTypes,
  AussieRulesEventCrossSectionTypeValues
} from './aussie_rules/cross-section/data_types'

// Event Types //
export type EventTypes =
  | GameEventType
  | FlightEventType
  | AussieRulesEventType
  | TimeEventType

export type EventSubTypes = GameEventSubType | FlightEventSubType

// Redux State //
export interface EventsState {
  rawData: {
    [k: string]: RawEventData
  }
  tableData: EventTableData[]
  items: {
    [k: string]:
      | FlightData
      | TimeEventData
      | GameEventData
      | AussieRulesEventData
  }
  columns: MetricTypeKeys[]
  flights: {
    [id: string]: FlightData
  }
  flightGraphs: {
    [id: string]: {
      data: LineChartCoordinates[]
    }
  }
  selectedEventId: string
  sessionId: string
  penaltyCountdownActive: boolean
  stoppageEventLoading: boolean
}

export type RawEventData =
  | RawFlightEventData
  | RawGameEventData
  | RawAussieRulesEventData
  | RawTimeEventData

export interface BaseFormattedEventData {
  id: string
  sessionId: string
  sessionStartTime: number
  ignore: boolean
  startTime: number
  endTime: number
  typeName: string
  operator?: {
    id: number
    notes: string
    highlight: boolean
    matchTime: number
  }
  metrics: {
    [key in MetricTypeKeys]?: FormattedMetric
  }
  outcome?: {
    selected: OutcomeType
    options: Options<OutcomeTypeValues>
  }
  team: {
    selected: FormattedTeam
    options: Options<string>
  }
  player: {
    selected: FormattedPlayer
    options: Options<string>
  }
  compareTagPosition: {
    label: string
    x: number
    y: number
    z: number
  }
  hasFailedToRender?: Error
  position?: Coordinate
  ballSerial?: string
}

export interface FormattedFlightEventData extends BaseFormattedEventData {
  eventType: typeof eventTypes.items.flight.value
  rawData: RawFlightEventData
  features: FlightEventFeatures
  type: {
    selected: FlightEventType
    options: Options<FlightEventTypeValues>
  }
  subType: {
    selected: FlightEventSubType
    options: Options<FlightEventSubTypeValues>
  }
  ballSerial: string
}

export interface FormattedGameEventData extends BaseFormattedEventData {
  eventType: typeof eventTypes.items.game.value
  rawData: RawGameEventData
  features: GameEventFeatures
  type: {
    selected: GameEventType
    options: Options<GameEventTypeValues>
  }
  subType: {
    selected: GameEventSubType
    options: Options<GameEventSubTypeValues>
  }
}

export interface FormattedAussieRulesEventData extends BaseFormattedEventData {
  eventType: typeof eventTypes.items.aussieRules.value
  rawData: RawAussieRulesEventData
  features: AussieRulesEventFeatures
  type: {
    selected: AussieRulesEventType
    options: Options<AussieRulesEventTypeValues>
  }
  subType: {
    selected: AussieRulesEventCrossSectionType
    options: Options<AussieRulesEventCrossSectionTypeValues>
  }
  position: Coordinate
  ballSerial: string
}

export interface FormattedTimeEventData extends BaseFormattedEventData {
  eventType: typeof eventTypes.items.time.value
  rawData: RawTimeEventData
  features: TimeEventTypeFeatures
  type: {
    selected: TimeEventType
    options: Options<TimeEventTypeValues>
  }
  subType: null
}

export type FormattedEventData =
  | FormattedFlightEventData
  | FormattedGameEventData
  | FormattedAussieRulesEventData
  | FormattedTimeEventData

// =============//

// Event Data //

export interface EventData<t> {
  id: string
  sessionId: string
  startTime: number
  endTime: number
  type: t
  hasFailedToRender?: Error
}

export type OperatorNotes = {
  highlight: boolean
  id: number
  matchTime: number
  notes: string
}

export interface LineChartCoordinates {
  time: number
  acceleration: number
  gyro?: number
}
export type EventItem =
  | FlightData
  | TimeEventData
  | GameEventData
  | AussieRulesEventData

// =============//

// Redux Action Types //

export const GET_SESSION_FLIGHTS = 'GET_SESSION_FLIGHTS'
export const SELECT_EVENT = 'SELECT_EVENT'
export const UPDATE_FLIGHT = 'UPDATE_FLIGHT'
export const BULK_UPDATING_FLIGHTS = 'BULK_UPDATING_FLIGHTS'
export const ADD_FLIGHT = 'ADD_FLIGHT'
export const UPDATE_SELECTED_COLUMNS = 'UPDATE_SELECTED_COLUMNS'
export const CREATE_TIME_EVENT = 'CREATE_TIME_EVENT'
export const SET_SESSION_TIME_EVENTS = 'SET_SESSION_TIME_EVENTS'
export const SET_STOPPAGE_LOADING = 'SET_STOPPAGE_LOADING'
export const SET_PENALTY_COUNTDOWN_ACTIVE = 'SET_PENALTY_COUNTDOWN_ACTIVE'
export const SET_SESSION_GAME_EVENTS = 'SET_SESSION_GAME_EVENTS'
export const CREATE_SESSION_GAME_EVENT = 'CREATE_SESSION_GAME_EVENT'
export const CHANGE_EVENT_FILTER_TYPE = 'CHANGE_EVENT_FILTER_TYPE'
export const UPDATE_EVENT = 'UPDATE_AUSSIE_RULES_EVENT'
export const GET_EVENT = 'GET_EVENT'
export const CLEAR_SESSION_EVENTS = 'CLEAR_SESSION_EVENTS'
export const ADD_EVENT = 'ADD_EVENT'
export const GET_SESSION_EVENTS = 'GET_SESSION_EVENTS'
export const GET_LINE_GRAPH_DATA = 'GET_LINE_GRAPH_DATA'
export const RESET_SELECTED_EVENT = 'RESET_SELECTED_EVENT'

// =============//
export const GET_FLIGHT = 'GET_FLIGHT'
