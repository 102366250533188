import { SportTypeKey } from '../data_types'
import {
  Coordinate,
  Pitch,
  PitchCoordinates,
  PitchDimensions
} from '../../pitches/types'
import { sportTypeKey } from '..'

export interface RugbyUnionPitch extends Pitch {
  type: SportTypeKey['rugbyUnion']
  pitch: RugbyUnionPitchDimensions
}

export interface RugbyUnionPitchDimensions extends PitchDimensions {
  length: number
  width: number
  poles: {
    width: number
    height: number
    crossbarHeight: number
  }
  coordinates: RugbyUnionPitchCoordinates | RugbyUnionPitchCoordinatesOld
}

export interface RugbyUnionPitchCoordinatesOld extends PitchCoordinates {
  P1: Coordinate
  P2: Coordinate
  P3: Coordinate
  P4: Coordinate
  P5: Coordinate
  P6: Coordinate
  P7: Coordinate
  P8: Coordinate
  P9: Coordinate
  P10: Coordinate
  P11: Coordinate
  P12: Coordinate
  P13: Coordinate
  P14: Coordinate
  P15: Coordinate
  P16: Coordinate
  P17: Coordinate
  P18: Coordinate
  P19: Coordinate
  P20: Coordinate
  P21: Coordinate
  P22: Coordinate
  P23: Coordinate
  P24: Coordinate
  P25: Coordinate
  P26: Coordinate
  P27: Coordinate
  P28: Coordinate
  P29: Coordinate
  P30: Coordinate
  P31: Coordinate
  P32: Coordinate
  P33: Coordinate
  P34: Coordinate
  P35: Coordinate
  P36: Coordinate
  P37: Coordinate
  P38: Coordinate
}

export interface RugbyUnionPitchCoordinates extends PitchCoordinates {
  P1: Coordinate
  P2: Coordinate
  P3: Coordinate
  P4: Coordinate
  P5: Coordinate
  P6: Coordinate
  P7: Coordinate
  P8: Coordinate
  P9: Coordinate
  P10: Coordinate
  P11: Coordinate
  P12: Coordinate
  P13: Coordinate
  P14: Coordinate
  P15: Coordinate
  P16: Coordinate
  P17: Coordinate
  P18: Coordinate
  P19: Coordinate
  P20: Coordinate
  P21: Coordinate
  P22: Coordinate
  P23: Coordinate
  P24: Coordinate
  P25: Coordinate
  P26: Coordinate
  P27: Coordinate
  P28: Coordinate
  P29: Coordinate
  P30: Coordinate
  P31: Coordinate
  P32: Coordinate
  P33: Coordinate
  P34: Coordinate
}

const coordinates = {
  P1: {
    x: -54.69,
    y: 0.07,
    z: 0.01
  },
  P2: {
    x: -47.07,
    y: 0.05,
    z: -0.02
  },
  P3: {
    x: -41.97,
    y: 0.03,
    z: 0.0
  },
  P4: {
    x: -25.07,
    y: 0.02,
    z: 0.0
  },
  P5: {
    x: -10.13,
    y: 0.02,
    z: -0.01
  },
  P6: {
    x: 0.0,
    y: 0.0,
    z: 0.0
  },
  P7: {
    x: 10.12,
    y: 0.01,
    z: 0.0
  },
  P8: {
    x: 25.11,
    y: 0.02,
    z: 0.0
  },
  P9: {
    x: 42.0,
    y: 0.02,
    z: -0.01
  },
  P10: {
    x: 47.1,
    y: 0.05,
    z: 0.0
  },
  P11: {
    x: 54.72,
    y: 0.04,
    z: 0.01
  },
  P12: {
    x: 47.11,
    y: 5.13,
    z: 0.0
  },
  P13: {
    x: 47.11,
    y: 15.14,
    z: 0.0
  },
  P14: {
    x: 47.16,
    y: 55.06,
    z: 0.01
  },
  P15: {
    x: 47.18,
    y: 65.1,
    z: 0.01
  },
  P16: {
    x: 54.77,
    y: 70.19,
    z: 0.02
  },
  P17: {
    x: 47.16,
    y: 70.22,
    z: 0.01
  },
  P18: {
    x: 42.11,
    y: 70.24,
    z: 0.01
  },
  P19: {
    x: 25.16,
    y: 70.23,
    z: 0.01
  },
  P20: {
    x: 10.15,
    y: 70.23,
    z: 0.01
  },
  P21: {
    x: -0.01,
    y: 70.24,
    z: 0.01
  },
  P22: {
    x: -10.13,
    y: 70.23,
    z: 0.01
  },
  P23: {
    x: -25.05,
    y: 70.22,
    z: 0.0
  },
  P24: {
    x: -41.94,
    y: 70.23,
    z: 0.01
  },
  P25: {
    x: -47.06,
    y: 70.21,
    z: 0.0
  },
  P26: {
    x: -54.68,
    y: 70.22,
    z: 0.01
  },
  P27: {
    x: -47.07,
    y: 65.1,
    z: 0.0
  },
  P28: {
    x: -47.1,
    y: 55.08,
    z: 0.0
  },
  P29: {
    x: -47.09,
    y: 15.16,
    z: 0.0
  },
  P30: {
    x: -47.08,
    y: 5.19,
    z: 0.0
  },
  P31: {
    x: -47.04,
    y: 32.24,
    z: 2.94
  },
  P32: {
    x: -46.95,
    y: 37.9,
    z: 2.96
  },
  P33: {
    x: 47.1,
    y: 37.9,
    z: 2.94
  },
  P34: {
    x: 47.12,
    y: 32.24,
    z: 2.93
  }
}

const coordinatesOld = {
  P1: {
    x: -60,
    y: 0,
    z: 0
  },
  P2: {
    x: -50,
    y: 0,
    z: 0
  },
  P3: {
    x: -45,
    y: 0,
    z: 0
  },
  P4: {
    x: -28,
    y: 0,
    z: 0
  },
  P5: {
    x: -10,
    y: 0,
    z: 0
  },
  P6: {
    x: 0,
    y: 0,
    z: 0
  },
  P7: {
    x: 10,
    y: 0,
    z: 0
  },
  P8: {
    x: 28,
    y: 0,
    z: 0
  },
  P9: {
    x: 45,
    y: 0,
    z: 0
  },
  P10: {
    x: 50,
    y: 0,
    z: 0
  },
  P11: {
    x: 60,
    y: 0,
    z: 0
  },
  P12: {
    x: 50,
    y: 5,
    z: 0
  },
  P13: {
    x: 50,
    y: 15,
    z: 0
  },
  P14: {
    x: 50,
    y: 55,
    z: 0
  },
  P15: {
    x: 50,
    y: 65,
    z: 0
  },
  P16: {
    x: 60,
    y: 70,
    z: 0
  },
  P17: {
    x: 50,
    y: 70,
    z: 0
  },
  P18: {
    x: 45,
    y: 70,
    z: 0
  },
  P19: {
    x: 28,
    y: 70,
    z: 0
  },
  P20: {
    x: 10,
    y: 70,
    z: 0
  },
  P21: {
    x: 0,
    y: 70,
    z: 0
  },
  P22: {
    x: -10,
    y: 70,
    z: 0
  },
  P23: {
    x: -28,
    y: 70,
    z: 0
  },
  P24: {
    x: -45,
    y: 70,
    z: 0
  },
  P25: {
    x: -50,
    y: 70,
    z: 0
  },
  P26: {
    x: -60,
    y: 70,
    z: 0
  },
  P27: {
    x: -50,
    y: 65,
    z: 0
  },
  P28: {
    x: -50,
    y: 55,
    z: 0
  },
  P29: {
    x: -50,
    y: 15,
    z: 0
  },
  P30: {
    x: -50,
    y: 5,
    z: 0
  },
  P31: {
    x: -50,
    y: 32.2,
    z: 2.9
  },
  P32: {
    x: -50,
    y: 32.2,
    z: 12.9
  },
  P33: {
    x: -50,
    y: 37.8,
    z: 2.9
  },
  P34: {
    x: -50,
    y: 37.8,
    z: 12.9
  },
  P35: {
    x: 50,
    y: 37.8,
    z: 2.9
  },
  P36: {
    x: 50,
    y: 37.8,
    z: 12.9
  },
  P37: {
    x: 50,
    y: 32.2,
    z: 2.9
  },
  P38: {
    x: 50,
    y: 32.2,
    z: 12.9
  }
}

export const anchorConfig = {
  //   0: {
  //     id: 0,
  //     index: 0,
  //     pos: {
  //       x: 0,
  //       y: 0,
  //       z: 0
  //     },
  //     batt: 15
  //   },
  2101: {
    id: 2101,
    index: 0,
    pos: {
      x: -47.651254281329784,
      y: 14.869080397718175,
      z: 2.4
    },
    batt: 50
  },
  2487: {
    id: 2487,
    index: 0,
    pos: {
      x: -12.34824998569502,
      y: 0,
      z: 2.4
    },
    batt: 90
  },
  2498: {
    id: 2498,
    index: 0,
    pos: {
      x: 9.772150651540237,
      y: 14.916193570717265,
      z: 2.4
    },
    batt: 90
  },
  2499: {
    id: 2499,
    index: 0,
    pos: {
      x: -47.22401918386735,
      y: 54.78612888146681,
      z: 2.4
    },
    batt: 15
  },
  2510: {
    id: 2510,
    index: 0,
    pos: {
      x: -12.348249985695013,
      y: 70.27817060342996,
      z: 2.4
    },
    batt: 90
  },
  2518: {
    id: 2518,
    index: 0,
    pos: {
      x: 9.199962920703292,
      y: 54.880250026097016,
      z: 2.4
    },
    batt: 50
  }
}

export const defaultPitch: RugbyUnionPitch = {
  id: '0',
  name: 'Default Rugby Union Pitch',
  type: sportTypeKey.rugbyUnion,
  anchors: null,
  arcs: null,
  pitch: {
    length: 0,
    width: 0,
    poles: {
      width: 0,
      height: 0,
      crossbarHeight: 0
    },
    coordinates: coordinatesOld
  }
}
