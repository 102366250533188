import React from 'react'
import { statusHooks, StatusTypes } from '../../metrics_server/status'
import { CloudStatus, CloudStatusTypes } from '../CloudStatus/CloudStatus'

export interface MSDBSyncStatusProps {
  color?: string
}

export const MSDBSyncStatus = ({ color }: MSDBSyncStatusProps) => {
  const status = statusHooks.useStatus()

  let cloudStatus: CloudStatusTypes

  switch (status.dbsync.syncStatus) {
    case StatusTypes.SyncStatus.DISABLED:
      cloudStatus = 'disconnected'
      break
    case StatusTypes.SyncStatus.OK:
      cloudStatus = 'upload'
      break
    case StatusTypes.SyncStatus.ERROR:
      cloudStatus = 'disconnected'
      break
    case StatusTypes.SyncStatus.SERVER:
      cloudStatus = 'hidden'
      break
    default:
      cloudStatus = 'loading'
  }

  return <CloudStatus status={cloudStatus} color={color || 'black'} />
}
