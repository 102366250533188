export interface MQTTState {
  brokers: BrokerConfig[]
  selectedBrokerHost: string
}

export interface BrokerConfig {
  WSSHost: string
  Username: string
  Password: string
  SportscasterHost: string
  Host: string
}

export namespace MQTTActionTypes {
  export const SET_BROKERS = 'SET_BROKERS'
}
