import React from 'react'
import { useAddNewWidgetButtonLogic } from './AddNewWidgetButton.logic'
import styles from './AddNewWidgetButton.module.scss'

export interface AddNewWidgetButtonProps {
  handleClick
}

export const AddNewWidgetButton = ({
  handleClick
}: AddNewWidgetButtonProps) => {
  const {} = useAddNewWidgetButtonLogic()
  return (
    <div className={styles.background} onClick={handleClick}>
      <p className={styles.plus}>+</p>
    </div>
  )
}
