import { AppConfig } from '../../App/App.types'
import { isLocal } from '../../metrics_server/env'
import * as ActivityList from '../../views/ActivityList/config'
import * as Diagnostics from '../../views/Diagnostics/config'
import * as Session from '../../views/Session/config'
import * as Settings from '../../views/Settings/config'
import * as StartSession from '../../views/StartSession/config'
import * as Teams from '../../views/Teams/config'
import * as SessionSetup from '../../views/SessionSetup/config'
import * as Targets from '../../views/TargetSetup/config'
import * as CreateOrganisation from '../../views/CreateOrganisation/config'
import { AppName, AppTypeKey } from '../types'
import { initialState } from '../../metrics_server/sessions/reducer'
import Theme from '../../ui/config/theme'

export const appConfig: AppConfig = {
  landing: ActivityList.path,
  type: {
    name: AppName.matchTracker,
    value: AppTypeKey.matchTracker
  },
  isLocal,
  routes: {
    activityList: {
      ...ActivityList.activityListRoute,
      authenticate: true
    },
    teams: {
      ...Teams.teamsListRoute,
      authenticate: true
    },
    settings: {
      ...Settings.settingsRoute,
      authenticate: true
    },
    createSession: {
      ...StartSession.createSessionRoute,
      authenticate: true
    },
    session: {
      ...Session.matchTrackerSessionRoute,
      authenticate: true
    },
    diagnostics: {
      ...Diagnostics.route,
      authenticate: true
    },
    tagAssignment: {
      ...SessionSetup.route,
      authenticate: true
    },
    targets: {
      ...Targets.route,
      authenticate: true
    },
    createOrganisation: {
      ...CreateOrganisation.createOrganisationRoute,
      authenticate: true
    }
  },
  initialSessionState: {
    ...initialState,
    filter: {
      ...initialState.filter,
      options: {
        ...initialState.filter.options,
        state: 'Finished'
      }
    }
  },
  theme: Theme.matchTracker
}
