import { useInsights } from './hooks'
import { ColumnQuery, Options } from './types'
import {
  InsightsDashboardLayouts,
  InsightsState,
  ConfigTypes,
  InsightsTableResponse
} from './types'

import { TableHeader, TableRow } from '../../components/Table/Table.types'
import { TeamLogoCell } from '../../components/Table/CustomCells/TeamLogoCell/TeamLogoCell'
// import { ColumnQueryFormValues } from '../../components/Forms/InsightsTableForm/ColumnForm/ColumnForm'
// import { FlightTypeKey, SubFlightTypeKey } from '../events/flight/types'
// import { FlightMetricTypeKey } from '../metrics/flight/types'
// import { FlightMetricTypes, flightMetricTypes } from '../metrics/flight'
// import { FlightSubTypes, FlightTypes, flightTypes } from '../events/flight'
// import { Sport } from '../sports/data_types'
// import { SportTypes } from '../sports'

export function getItemById<Item>(items: { [id: string]: Item }, id: string) {
  return items[id]
}

export function getSelectedLayout(
  layouts: InsightsDashboardLayouts,
  selectedId: string
) {
  if (layouts.editedItems[selectedId])
    return {
      selectedLayout: getItemById(layouts.editedItems, selectedId),
      isEdited: true
    }
  return {
    selectedLayout: getItemById(layouts.items, selectedId),
    isEdited: false
  }
}

export function isLayoutEdited(
  layouts: InsightsDashboardLayouts,
  layoutId: string
) {
  return !!layouts.editedItems[layoutId]
}

export function getConfigById(
  insights: InsightsState,
  type: ConfigTypes,
  configId: string
) {
  return insights[type][configId]
}

export const getInsightsTableHeaders = (
  data: InsightsTableResponse
): TableHeader[] => {
  if (!data || !data.columnHeadings) return []
  return data.columnHeadings.map((columnHeading, index) => {
    if (columnHeading.name === 'Id') {
      return {
        name: 'Team',
        key: 'teamLogo',
        width: 12,
        type: 'component',
        CustomComponent: TeamLogoCell
      }
    }

    return {
      name: columnHeading.name,
      key: index.toString()
    }
  })
}

export const getInsightsTableData = (
  data: InsightsTableResponse
): TableRow[] => {
  if (!data || !data.cells) return []
  return data.cells.map((row, i) => {
    const x = {
      id: i.toString(),
      tableId: i.toString()
    }

    row.forEach((cell, j) => {
      x[j.toString()] = cell
    })

    return x
  })
}

// export const convertColumnRequestKeysToValues = (
//   formColumns: ColumnQueryFormValues[] = []
// ): ColumnQuery[] => {
//   return formColumns.map((formColumn) => {
//     let column = {} as ColumnQuery

//     column.stat = FlightMetricTypeKey[formColumn.stat]

//     column.aggregation = formColumn.aggregation

//     if (formColumn.flightType !== 'All') {
//       column.flightType = FlightTypeKey[formColumn.flightType]
//     } else {
//       column.flightType = null
//     }

//     column.flightSubTypes = formColumn.flightSubTypes.map(
//       (flightSubTypeKey) => {
//         return SubFlightTypeKey[flightSubTypeKey]
//       }
//     )

//     return column
//   })
// }

// export const convertColumnRequestValuesToKeys = (
//   columnQueries: ColumnQuery[] = [],
//   sport: Sport
// ): ColumnQueryFormValues[] => {
//   console.log(columnQueries)
//   return columnQueries.map((columnQuery, index) => {
//     let column = {} as ColumnQueryFormValues

//     column.id = '' + index

//     sport = SportTypes.items.rugbyUnion

//     const flightType = sport.flightTypes.getTypeByValue(columnQuery.flightType)

//     if (flightType) {
//       column.flightType = flightType
//       column.stat = sport.flightTypes.items.getTypeByValue(columnQuery.f).key
//     } else {
//       column.flightType = 'All'
//     }

//     sport.getAllFlightMetrics

//     column.stat = sport.flightTypes.items.getTypeByValue(columnQuery.f).key

//     column.aggregation = columnQuery.aggregation

//     column.flightSubTypes = columnQuery.flightSubTypes.map(
//       (flightSubTypeValue) => {
//         return FlightSubTypes.getTypeByValue(flightSubTypeValue).key
//       }
//     )

//     return column
//   })
// }
