import React, { useState, useEffect } from 'react'
import { useAppDispatch } from '../../store/hooks'
import {
  useInsights,
  useSelectedLayout
} from '../../metrics_server/insights/hooks'
import { useSportscaster } from '../../metrics_server/sportscaster/hooks'
import { requestInsightsData } from '../../metrics_server/insights/actions'
import {
  getInsightsTableData,
  getInsightsTableHeaders
} from '../../metrics_server/insights/functions'

import { Table } from '../Table/Table'
import { CommentatorButton } from '../CommentatorButton/CommentatorButton'
import { Options } from '../Options/Options'
import { CardList } from '../CardList/CardList'

import styles from './InsightsTable.module.scss'

// Types
import { ReactGridItem } from '../../metrics_server/insights/types'
import Loader from '../Loader/Loader'
import { useInsightsTableFormModal } from '../../views/Session/modals/insights_forms'
import { useSession } from '../../metrics_server/sessions/hooks'

export interface InsightsTableProps {
  layoutItem: ReactGridItem
  contentId: string
  removeItem
}

export const InsightsTable = ({
  layoutItem,
  contentId,
  removeItem
}: InsightsTableProps) => {
  // Modals //
  const { openInsightsTableFormModal } = useInsightsTableFormModal()
  // ====== //

  // Session //
  const { session } = useSession()
  // ====== //

  const { locationName } = useSportscaster()
  const { tables, dataFetchQueue, layouts } = useInsights()
  const dispatch = useAppDispatch()
  const { configs, data } = tables
  const tableConfig = configs[contentId]
  const tableData = data[contentId]

  const [headers, setHeaders] = useState(getInsightsTableHeaders(tableData))
  const [rows, setRows] = useState(getInsightsTableData(tableData))

  const [selectedRow, setSelectedRow] = useState(null)

  useEffect(() => {
    if (tableConfig && locationName) {
      dispatch(requestInsightsData(tableConfig, session.id))
    }
  }, [
    tableConfig,
    dataFetchQueue[tableData ? tableData.shortId : null],
    locationName
  ])

  useEffect(() => {
    setHeaders(getInsightsTableHeaders(tableData))
    setRows(getInsightsTableData(tableData))
  }, [tableData])

  // const tableColourScheme = cardColorSchemes[tableConfig.colour]

  return (
    <CardList
      // key={`card-${layoutItem.contentId}-${layoutItem.i}`}
      col={12}
      items={[{}]}
      scrollerId={`scroller-${layoutItem.i}`}
      className='maxHeight'
    >
      <div className={styles.cardInner}>
        <div
          key={contentId}
          className={styles.container}
          style={{ backgroundColor: tableConfig ? tableConfig.colour : null }}
        >
          <div className={styles.headerContainer}>
            <div className={styles.titleContainer}>
              <h5>{tableConfig ? tableConfig.name : 'No table found'}</h5>
            </div>
            <div className={styles.optionsContainer}>
              <div className={styles.optionsBorder}></div>
              <Options
                options={[
                  {
                    name: 'Edit',
                    handle: () =>
                      openInsightsTableFormModal(
                        tableConfig ? tableConfig.id : null
                      )
                  },
                  {
                    name: 'Remove',
                    handle: removeItem
                  }
                ]}
                backgroundColor={'transparent'}
                color={'#323233'}
              />
            </div>
          </div>
          <div className={styles.tableContainer}>
            {tableConfig && (
              <Table
                // Table props
                highlightRow={(tableId) => setSelectedRow(tableId)}
                highlightedRow={selectedRow}
                // controls
                options={{
                  initialOrder: 'asc',
                  initialSortBy: 'id',
                  sortActive: true
                }}
                headerFont={13}
                tableClass={'minimalistBlack'}
                className={'small-container'}
                smallHead={true}
                data={rows}
                headers={headers}
                id={`${contentId}-${layoutItem.i}-table`}
              />
            )}
          </div>
        </div>
      </div>
    </CardList>
  )
}
