import React from 'react'
import { ExclamationIcon } from '../Icons/ExclamationIcon/ExclamationIcon'
import { Signal } from '../Icons/Signal/Signal'
import styles from '../ListInset/ListInset.module.scss'
import { FormattedDevice } from '../../metrics_server/hardware/types'
import { sportableColors } from '../../const'
import { QAWarningIcon } from '../Icons/QAWarningIcon/QAWarningIcon'
import { QaToolTip } from '../HardwareGrid/HardwareItem/QAInfo/QaToolTip'
import { BatteryIcon } from '../Icons/BatteryIcon/BatteryIcon'

export interface AnchorItemProps {
  item?: FormattedDevice
}

export const AnchorItem = (props: AnchorItemProps) => {
  const { item } = props
  const qaReports = item?.reports ? item.reports : undefined

  return (
    <div
      style={
        item.masterSignalStrength === 1
          ? { background: 'rgba(255, 0, 0, 0.3)' }
          : {}
      }
      className={`${styles.playerKey} ${styles.playerContainer}`}
    >
      <div>
        <div
          style={{ borderColor: item.status.colour }}
          className={styles.anchorImgContainer}
        >
          <BatteryIcon device={item} isAnchor={true} />
          {item.masterSignalStrength >= 0 && (
            <Signal
              scale={1}
              strength={
                item.status.value === 'online' ? item.masterSignalStrength : 0
              }
              color={sportableColors.colors.sportableBlue}
            />
          )}
          {item.masterSignalStrength === 1 && (
            <div className={styles.signalWarning}>
              <div className={styles.warningIcon}>
                <ExclamationIcon scale={1} />
              </div>
              <p>Adjust anchor position</p>
            </div>
          )}
        </div>

        <p></p>
      </div>

      <div>
        {qaReports && (
          <QaToolTip
            icon={
              <QAWarningIcon
                scale={0.7}
                color={
                  qaReports.investigate.passed ||
                  qaReports.retired.passed ||
                  (!qaReports.fieldQA.passed && !qaReports.hwQA.passed)
                    ? sportableColors.colors.colorError
                    : sportableColors.colors.colorWarning
                }
              />
            }
            qaReports={qaReports}
            colorError={sportableColors.colors.colorError}
            colorSuccess={sportableColors.colors.colorSuccess}
            colorWarning={sportableColors.colors.colorWarning}
          />
        )}
        <p style={item.status.value === 'online' ? {} : {}}>
          id: {item.serial}
        </p>
      </div>
    </div>
  )
}
