// Used in player summary tab to select which players you want to see in the player summary table

import React, { useCallback, useState } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { Button } from '../../../components/Button/Button'
import { ColumnForm } from '../../../components/Forms/ColumnForm/ColumnForm'
import { toggleModal } from '../../../ui/modal/actions'
import { FormattedPlayer } from '../../../metrics_server/players/types'
import { Group } from '../../../metrics_server/types'

export const usePlayerSelectModal = (
  players: Group<FormattedPlayer>,
  initialSelectedPlayerIds: string[],
  updateSelected: (selectedPlayerIds: string[]) => void
) => {
  const dispatch = useAppDispatch()

  const openPlayerSelectModal = useCallback(() => {
    const ChildComponent = () => {
      const [selectedPlayerIds, setSelectedPlayers] = useState(
        initialSelectedPlayerIds
      )

      return (
        <>
          <ColumnForm<string>
            title='Select Players'
            options={players.options}
            selected={selectedPlayerIds}
            handleChange={setSelectedPlayers}
            // No maxSelections if there's no limit on the amount of players
            invalidSelectionMessage='* Select at least one player'
          />
          <Button
            handleClick={() => {
              updateSelected(selectedPlayerIds)
              dispatch(toggleModal({}))
            }}
            className='btn--primary'
            type='button'
          >
            Ok
          </Button>
        </>
      )
    }

    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        wrapper: true,
        handleProceed: () => {
          dispatch(toggleModal({}))
        },
        ChildComponent,
        className: 'modalSmall',
        handleSecondaryBtn: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }, [initialSelectedPlayerIds, players.options, updateSelected, dispatch])

  return { openPlayerSelectModal }
}
