import React from 'react'
import { CustomCellProps } from '../../Table.types'
import { TeamLogo } from '../../../TeamLogo/TeamLogo'

export const TeamLogoCell: React.FC<CustomCellProps> = ({ item, header }) => {
  let teamLogo = item[header.key]
  if (!teamLogo) return <div></div>
  if (!teamLogo.logo) return <div></div>
  return <TeamLogo color={teamLogo.color} logo={teamLogo.logo} size={16} />
}
