import {
  sportscasterUrl,
  brokerUrl,
  sportscasterHTTPProtocol,
  sportscasterWSPath,
  sportscasterWSProtocol
} from '../env'

export const sportscasterConfig = {
  WSSHost: brokerUrl,
  Username: 'metrics_server',
  Password: 'Sp0rtableT3ch',
  SportscasterHost: sportscasterUrl,
  Host: 'host',
  protocol: sportscasterWSProtocol,
  path: sportscasterWSPath,
  name: 'host'
}

export const sportscasterStatus = {
  name: 'host',
  host: 'host',
  locationName: '',
  cloud: true
}

export const sportscasterRouteOptions = [
  {
    name: 'Genius Match Summary',
    value: 'genius'
  },
  { name: 'CFL Match Summary', value: '/canadian_football/match_summary' },
  {
    name: 'Match Summary',
    value: '/rugby/match/summary'
  },
  {
    name: 'Event',
    value: '/rugby/event'
  },
  {
    name: 'Locations',
    value: 'locations'
  },
  {
    name: 'Last Clearance Kick to Touch',
    value: '/rugby/kick/clearance/toTouch/last'
  },
  {
    name: 'Passing breakdown',
    value: '/rugby/pass/headToHead'
  },
  {
    name: 'Match Stats',
    value: '/rugby/kick/clearance/headToHead'
  },
  {
    name: 'Highest Hang Time Kick',
    value: '/rugby/kick/hangTime/max/headToHead'
  },
  {
    name: 'Longest Pass by Team',
    value: '/rugby/pass/distance/max/headToHead'
  },
  {
    name: 'Fastest Pass by Team',
    value: '/rugby/pass/speed/max/headToHead'
  },
  {
    name: 'Top 5 Longest Kicks',
    value: '/rugby/kick/distance/topFive'
  },
  {
    name: 'Longest Kick by Team',
    value: '/rugby/kick/distance/max/headToHead'
  },
  {
    name: 'Top 5 Highest Hang Time Kicks',
    value: '/rugby/kick/hangTime/topFive'
  },
  {
    name: 'Restarts',
    value: '/rugby/kick/restart',
    trajectory: true
  },
  {
    name: 'Clearance',
    value: '/rugby/kick/clearance',
    trajectory: true
  },
  {
    name: 'Touch',
    value: '/rugby/kick/toTouch',
    trajectory: true
  },
  {
    name: 'Kicks for Poles',
    value: '/rugby/kick/forPoles',
    trajectory: true
  }
]
