import { SportTypeKey } from '../data_types'
import {
  Coordinate,
  Pitch,
  PitchCoordinates,
  PitchDimensions
} from '../../pitches/types'
import { sportTypeKey } from '..'

export interface RugbyLeaguePitch extends Pitch {
  type: SportTypeKey['rugbyLeague']
  pitch: RugbyLeaguePitchDimensions
}

export interface RugbyLeaguePitchDimensions extends PitchDimensions {
  length: number
  width: number
  poles: {
    width: number
    height: number
    crossbarHeight: number
  }
  coordinates: RugbyLeaguePitchCoordinates
}

export interface RugbyLeaguePitchCoordinates extends PitchCoordinates {
  P1: Coordinate
  P2: Coordinate
  P3: Coordinate
  P4: Coordinate
  P5: Coordinate
  P6: Coordinate
  P7: Coordinate
  P8: Coordinate
  P9: Coordinate
  P10: Coordinate
  P11: Coordinate
  P12: Coordinate
  P13: Coordinate
  P14: Coordinate
  P15: Coordinate
  P16: Coordinate
  P17: Coordinate
  P18: Coordinate
  P19: Coordinate
  P20: Coordinate
  P21: Coordinate
  P22: Coordinate
  P23: Coordinate
  P24: Coordinate
  P25: Coordinate
  P26: Coordinate
  P27: Coordinate
  P28: Coordinate
  P29: Coordinate
  P30: Coordinate
  P31: Coordinate
  P32: Coordinate
  P33: Coordinate
  P34: Coordinate
  P35: Coordinate
  P36: Coordinate
  P37: Coordinate
  P38: Coordinate
}

const coordinates = {
  P1: {
    x: -60,
    y: 0.0,
    z: 0.0
  },
  P2: {
    x: -50,
    y: 0.0,
    z: 0.0
  },
  P3: {
    x: -40,
    y: 0.0,
    z: 0.0
  },
  P4: {
    x: -30,
    y: 0.0,
    z: 0.0
  },
  P5: {
    x: -20,
    y: 0.0,
    z: 0.0
  },
  P6: {
    x: -10,
    y: 0.0,
    z: 0.0
  },
  P7: {
    x: 0,
    y: 0.0,
    z: 0.0
  },
  P8: {
    x: 10,
    y: 0.0,
    z: 0.0
  },
  P9: {
    x: 20,
    y: 0.0,
    z: 0.0
  },
  P10: {
    x: 30,
    y: 0.0,
    z: 0.0
  },
  P11: {
    x: 40,
    y: 0.0,
    z: 0.0
  },
  P12: {
    x: 50,
    y: 0,
    z: 0.0
  },
  P13: {
    x: 60,
    y: 0,
    z: 0.0
  },
  P14: {
    x: 50,
    y: 10,
    z: 0.0
  },
  P15: {
    x: 50,
    y: 20,
    z: 0.0
  },
  P16: {
    x: 50,
    y: 48,
    z: 0.0
  },
  P17: {
    x: 50,
    y: 58,
    z: 0.0
  },
  P18: {
    x: 60,
    y: 68,
    z: 0.0
  },
  P19: {
    x: 50,
    y: 68,
    z: 0.0
  },
  P20: {
    x: 40,
    y: 68,
    z: 0.0
  },
  P21: {
    x: 30,
    y: 68,
    z: 0.0
  },
  P22: {
    x: 20,
    y: 68,
    z: 0.0
  },
  P23: {
    x: 10,
    y: 68,
    z: 0.0
  },
  P24: {
    x: 0,
    y: 68,
    z: 0.0
  },
  P25: {
    x: -10,
    y: 68,
    z: 0.0
  },
  P26: {
    x: -20,
    y: 68,
    z: 0.0
  },
  P27: {
    x: -30,
    y: 68,
    z: 0.0
  },
  P28: {
    x: -40,
    y: 68,
    z: 0.0
  },
  P29: {
    x: -50,
    y: 68,
    z: 0.0
  },
  P30: {
    x: -60,
    y: 68,
    z: 0.0
  },
  P31: {
    x: -50,
    y: 58,
    z: 2.934
  },
  P32: {
    x: -50,
    y: 48,
    z: 12.895
  },
  P33: {
    x: -50,
    y: 20,
    z: 2.934
  },
  P34: {
    x: -50,
    y: 10,
    z: 12.895
  },
  P35: {
    x: -50,
    y: 36,
    z: 2.934
  },
  P36: {
    x: -50,
    y: 32,
    z: 2.934
  },
  P37: {
    x: 50,
    y: 36,
    z: 2.934
  },
  P38: {
    x: 50,
    y: 32,
    z: 2.934
  }
}

export const anchorConfig = {
  0: {
    id: 0,
    pos: {
      x: 0.0,
      y: 0.0,
      z: 0.0
    }
  },
  413401464: {
    id: 413401464,
    serial: 'CA80BR',
    pos: {
      x: -3.161,
      y: -7.865,
      z: 2.426
    }
  },
  413401379: {
    id: 413401379,
    serial: 'CA8093',
    pos: {
      x: -44.185,
      y: -7.899,
      z: 2.511
    }
  },
  413401320: {
    id: 413401320,
    serial: 'CA8078',
    pos: {
      x: -73.169,
      y: 15.061,
      z: 2.566
    }
  },
  413401317: {
    id: 413401317,
    serial: 'CA8075',
    pos: {
      x: -73.694,
      y: 55.085,
      z: 2.609
    }
  },
  413401308: {
    id: 413401308,
    serial: 'CA806W',
    pos: {
      x: -39.783,
      y: 76.185,
      z: 2.388
    }
  },
  413401297: {
    id: 413401297,
    serial: 'CA806H',
    pos: {
      x: 45.609,
      y: 76.058,
      z: 2.593
    }
  },
  413401292: {
    id: 413401292,
    serial: 'CA806C',
    pos: {
      x: 72.74,
      y: 55.166,
      z: 2.409
    }
  },
  413401291: {
    id: 413401291,
    serial: 'CA806B',
    pos: {
      x: 72.972,
      y: 15.124,
      z: 2.454
    }
  },
  413401288: {
    id: 413401288,
    serial: 'CA8068',
    pos: {
      x: 44.807,
      y: -8.387,
      z: 2.544
    }
  }
}

export const defaultPitch: RugbyLeaguePitch = {
  id: '0',
  name: 'Default Rugby League Pitch',
  type: sportTypeKey.rugbyLeague,
  anchors: null,
  arcs: null,
  pitch: {
    length: 0,
    width: 0,
    poles: {
      width: 0,
      height: 0,
      crossbarHeight: 0
    },
    coordinates: coordinates
  }
}
