import { RawBroadcastingState } from './types'

export const getPossessionSide = (broadcastState: RawBroadcastingState) => {
  if (
    broadcastState &&
    broadcastState.sessionControl &&
    broadcastState.sessionControl.sides &&
    broadcastState.sessionControl.sides.length >= 2 &&
    broadcastState.sessionControl.sides[0].id &&
    broadcastState.sessionControl.sides[0].id !== '0'
  ) {
    if (
      broadcastState.sessionControl.possession.id ===
      broadcastState.sessionControl.sides[0].id
    ) {
      return 0
    } else if (
      broadcastState.sessionControl.possession.id ===
      broadcastState.sessionControl.sides[1].id
    ) {
      return 1
    }
  }
  return null
}
