import {
  // API actions
  GET_ORGANISATIONS,
  CREATE_ORGANISATION,
  UPDATE_NEW_ORGANISATION,
  CHECK_FOR_ORGANISATION,
  JOIN_ORGANISATION
} from './types'

import { setError } from '../../ui/error/actions'
import { getPitches } from '../pitches/actions'
import { request } from '../../utils/request_handler'
import { API_ROOT_URL } from '../../const'

// Get teams of logged in user
export function getOrganisations(cb) {
  return (dispatch) => {
    const success = (response) => {
      response.data.forEach((organisation) => {
        dispatch(getPitches(organisation))
      })
      dispatch({
        type: GET_ORGANISATIONS,
        payload: response.data
      })

      // Get all teams in an organisation
      if (response.data[0]) {
        dispatch(getOrganisationsTeams(response.data[0].id, null))
      }

      cb(response.data)
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get organisations. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: 'organisations',
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

export function createOrganisation(team, cb) {
  return (dispatch, getState) => {
    const organisation = getState().organisations.new
    const data = {
      organisation,
      teams: [team]
    }
    const success = (response) => {
      if (cb) cb()
      dispatch({
        type: CREATE_ORGANISATION
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to create organisation. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: 'organisations',
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true,
      data
    }
    request(options, success, error, dispatch)
  }
}

export function checkOrganisation(data, cb) {
  return (dispatch, getState) => {
    const success = (response) => {
      if (cb) cb()
      dispatch({
        type: CHECK_FOR_ORGANISATION,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to find organisation. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: 'organisations/check',
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true,
      data
    }
    request(options, success, error, dispatch)
  }
}

export function getOrganisationsTeams(organisationId, cb) {
  return (dispatch, getState) => {
    const success = (response) => {
      if (cb) cb()
      dispatch({
        type: CHECK_FOR_ORGANISATION,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get organisation teams. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `organisations/${organisationId}/teams`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

export function joinOrganisation(data) {
  return {
    type: JOIN_ORGANISATION,
    payload: data
  }
}

export function updateNewOrgansationState(organisation) {
  return {
    type: UPDATE_NEW_ORGANISATION,
    payload: organisation
  }
}
