import React, { useEffect, useRef, useState } from 'react'
import { request } from '../../utils/request_handler'

import styles from './EndpointTester.module.scss'

import { FormContainer } from '../Forms/Form/Form'

import { JSONEditor } from 'vanilla-jsoneditor'

interface Option {
  name: string
  value: string | number
}

export interface EndpointTesterProps {
  host: string
  routeOptions: Option[]
}

export interface EndpointTesterState {
  data: any
  error: any
}

export function EndpointTester({ host, routeOptions }: EndpointTesterProps) {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  let fields = [
    {
      name: 'route',
      type: 'select',
      label: 'Endpoint',
      options: routeOptions
    }
  ]

  const fetchData = (values) => {
    let route = values.route

    const success = (response) => {
      setData(response.data)
      setError(null)
    }
    const error = (error, errorType) => {
      setError(
        error.response && error.response.data && error.response.data[errorType]
          ? error.response.data[errorType]
          : `Failed without detail from the metrics server`
      )
      setData(null)
    }
    const options = {
      url: route,
      method: 'get',
      baseURL: host,
      withCredentials: true
    }
    return request(options, success, error)
  }

  const refContainer = useRef(null)
  const refEditor = useRef(null)

  useEffect(() => {
    refEditor.current = new JSONEditor({
      target: refContainer?.current,
      props: {
        content: {
          json: data ?? {}
        },
        readOnly: true
        // validator: validator,
        // onChange: (
        //   content: Content,
        //   previousContent: Content,
        //   changeStatus: {
        //     contentErrors: ContentErrors
        //     patchResult: JSONPatchResult | null
        //   }
        // ) => {
        //   setJson(content)
        //   onValidationChange && onValidationChange(changeStatus.contentErrors)
        //   onChange && onChange(content)
        // }
      }
    })

    refEditor.current?.validate()

    return () => {
      if (refEditor.current) {
        refEditor.current?.destroy()
        refEditor.current = null
      }
    }
  }, [data])

  return (
    <div className={styles.container} style={{ height: '100%' }}>
      <FormContainer
        fields={fields}
        onSubmit={fetchData}
        submitText={'Fetch'}
      />
      <div className={styles.editorContainer} ref={refContainer}></div>
    </div>
  )
}
