import React from 'react'

export interface EditIconProps {
  scale?: number
  color?: string
  backgroundColor?: string
  id?: string
}

export const EditIcon: React.FC<EditIconProps> = ({
  scale = 1,
  color = '#FFFFFF',
  backgroundColor = '#323233',
  id
}) => {
  return (
    <svg
      id={id}
      width={`${40 * scale}`}
      height={`${40 * scale}`}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z'
        fill={backgroundColor}
      />
      <path
        d='M20 16C21.1046 16 22 15.1046 22 14C22 12.8954 21.1046 12 20 12C18.8954 12 18 12.8954 18 14C18 15.1046 18.8954 16 20 16Z'
        fill={color}
      />
      <path
        d='M20 22C21.1046 22 22 21.1046 22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22Z'
        fill={color}
      />
      <path
        d='M20 28C21.1046 28 22 27.1046 22 26C22 24.8954 21.1046 24 20 24C18.8954 24 18 24.8954 18 26C18 27.1046 18.8954 28 20 28Z'
        fill={color}
      />
    </svg>
  )
}
