import React, { useEffect, useMemo, useState } from 'react'
import styles from './TotalStationEditor.module.scss'
import { Button } from '../Button/Button'
import { Table } from './../Table/Table'
import { FormattedDevice } from '../../metrics_server/hardware/types'
import { Group } from '../../metrics_server/types'

export interface TotalStationEditorProps {
  totalStationCSVFile: any
  anchors: Group<FormattedDevice, number>
  invalidTotalStationCsvHandler: (error: string) => void
  updateCsv: (csv, json, csvWithoutIgnored) => void
  pitchOnly?: boolean
}

const checkRowValidity = (cells, numberOfColumns) => {
  if (cells.length < 3) return 'Invalid total station data'
  // if (numberOfColumns !== cells.length) return 'Invalid CSV file'
  const invalidDataCell = cells.find((cell, index) => {
    if (index > 0 && index < 4) {
      return isNaN(Number(cell))
    } else {
      return false
    }
  })
  if (invalidDataCell) {
    return `Invalid total station data: ${invalidDataCell} is not a number`
  } else {
    return null
  }
}

export const totalStationCsvToJson = async (csvFile) => {
  const string = await csvFile.text()
  const rows = string.split(/\r?\n/)
  const json = {}
  let error = null

  let numberOfColumns

  rows
    .filter((x) => {
      return x
    })
    .map((row, index) => {
      const cells = row.split(',')

      if (index === 0) numberOfColumns = cells.length

      // Validate row
      const invalidRowErrorMessage = checkRowValidity(cells, numberOfColumns)
      if (invalidRowErrorMessage) error = invalidRowErrorMessage

      const rowObj = {}
      cells.map((cell, index) => {
        rowObj[index] = cell
      })
      switch (index) {
        case 0:
          rowObj['4'] = 'TS'
          break
        case 1:
          rowObj['4'] = 'C1'
          break
        case 2:
          rowObj['4'] = 'C2'
          break
        case 3:
          rowObj['4'] = 'C3'
          break
        case 4:
          rowObj['4'] = 'Origin'
          break
        default:
          break
      }

      json[index] = rowObj
    })

  return { json, error }
}

export const TotalStationEditor: React.FC<TotalStationEditorProps> = ({
  totalStationCSVFile,
  invalidTotalStationCsvHandler,
  anchors,
  updateCsv,
  pitchOnly
}) => {
  const [totalStationJSON, setTotalStationJSON] = useState({})
  const [selectedPointsAndAnchors, setSelectedPointsAndAnchors] = useState([])

  const { anchorSerials, anchorSerialOptions } = useMemo(() => {
    const anchorSerialOptions = pitchOnly
      ? []
      : anchors.options.map((option) => {
          return {
            name: option.name,
            value: option.name
          }
        })
    const anchorSerials = pitchOnly
      ? []
      : anchors.list.map((anchor) => {
          return anchor.serial
        })
    return {
      anchorSerialOptions,
      anchorSerials
    }
  }, [anchors])

  useEffect(() => {
    if (totalStationCSVFile) {
      totalStationCsvToJson(totalStationCSVFile).then(({ json, error }) => {
        if (error) {
          // Total Station Data is invalid
          return invalidTotalStationCsvHandler(error)
        }
        setTotalStationJSON(json)
      })
    }
  }, [totalStationCSVFile])

  useEffect(() => {
    if (totalStationJSON) {
      const newSelectedPointsAndAnchors = []
      for (const key in totalStationJSON) {
        const dataPoint = { ...totalStationJSON[key] }
        if (dataPoint['4'] && dataPoint['4'] !== 'Ignore') {
          newSelectedPointsAndAnchors.push(dataPoint['4'])
        }
      }
      setSelectedPointsAndAnchors(newSelectedPointsAndAnchors)
    }
  }, [totalStationJSON])

  const isMandatoryPoint = (point) => {
    return (
      point === 'TS' ||
      point === 'C1' ||
      point === 'C2' ||
      point === 'C3' ||
      point === 'Origin'
    )
  }

  const autoAssignRemainingPoints = (totalStationJSON) => {
    const newTotalStationJSON = {}
    let pointCount = 1
    for (const key in totalStationJSON) {
      const newDataPoint = { ...totalStationJSON[key] }

      if (!newDataPoint['4']) {
        newDataPoint['4'] = `P${pointCount}`
        pointCount++
      } else if (newDataPoint['4'].charAt(0) === 'P') {
        pointCount++
      }

      newTotalStationJSON[key] = newDataPoint
    }
    setTotalStationJSON(newTotalStationJSON)
  }

  const clearAllPoints = (all) => {
    const newTotalStationJSON = {}
    for (const key in totalStationJSON) {
      const newDataPoint = { ...totalStationJSON[key] }
      if (newDataPoint['4'].charAt(0) === 'P') {
        newDataPoint['4'] = ``
      } else if (all && !isMandatoryPoint(newDataPoint['4'])) {
        newDataPoint['4'] = ``
      }

      newTotalStationJSON[key] = newDataPoint
    }
    setTotalStationJSON(newTotalStationJSON)
  }

  const generateTableData = (totalStationJSON) => {
    const tableData = Object.keys(totalStationJSON).map(function (row, index) {
      const r = { row: row, ...totalStationJSON[row] }
      if (
        totalStationJSON[row]['4'] &&
        totalStationJSON[row]['4'].charAt(0) !== 'P' &&
        totalStationJSON[row]['4'].charAt(0) !== 'O' &&
        totalStationJSON[row]['4'] !== 'Ignore' &&
        !isMandatoryPoint(totalStationJSON[row]['4']) &&
        anchors &&
        anchorSerials.indexOf(totalStationJSON[row]['4']) < 0
      ) {
        totalStationJSON[row]['4'] = ``
      }
      if (index < 5) r.__fixedValue = true
      return r
    })
    return tableData
  }

  const dataPointOptions = [
    { name: '', value: '' },
    { name: 'Ignore', value: 'Ignore' },
    ...anchorSerialOptions
  ]

  // Add options for points P1 - P50 //
  for (let i = 0; i < 50; i++) {
    const option = {
      name: `P${i + 1}`,
      value: `P${i + 1}`
    }
    dataPointOptions.push(option)
  }

  // Add options for objects O1, O2, O3, O4 //
  for (let i = 0; i < 25; i++) {
    const option = {
      name: `O${i + 1}`,
      value: `O${i + 1}`
    }
    dataPointOptions.push(option)
  }

  const toCsv = (json) => {
    let csvString = ''
    json.forEach((x: any, index) => {
      for (const key in x) {
        const value = x[key]
        csvString = csvString + value + (key === '4' ? '' : ',')
      }
      csvString = csvString + '\r\n'
    })
    return csvString
  }

  const onSubmit = (totalStationJSON) => {
    const formattedJSON = Object.values(totalStationJSON)

    // Remove ignored points from json
    const formattedJSONWithoutIgnored = formattedJSON.filter((x) => {
      return x['4'] !== 'Ignore'
    })

    // Convert to csv
    const csvString = toCsv(formattedJSON)
    const csvStringWithoutIgnored = toCsv(formattedJSONWithoutIgnored)

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
    const blobWithoutIgnored = new Blob([csvStringWithoutIgnored], {
      type: 'text/csv;charset=utf-8;'
    })
    updateCsv(blob, formattedJSON, blobWithoutIgnored)
  }

  console.log(dataPointOptions, selectedPointsAndAnchors)

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <Table
          controls={[
            {
              name: 'Auto-assign remaining points',
              callback: () => {
                autoAssignRemainingPoints(totalStationJSON)
              }
            },
            {
              name: 'Clear all',
              callback: () => {
                clearAllPoints(true)
              }
            },
            {
              name: 'Clear points',
              callback: () => {
                clearAllPoints(false)
              }
            }
          ]}
          options={{
            initialOrder: 'dec',
            initialSortBy: 'startTime',
            sortActive: false
          }}
          headerFont={13}
          tableClass={'minimalistBlack'}
          className={'small-container'}
          smallHead={true}
          data={generateTableData(totalStationJSON)}
          headers={[
            { name: '', key: '0', width: 20, type: 'text' },
            { name: '', key: '1', width: 20, type: 'text' },
            { name: '', key: '2', width: 20, type: 'text' },
            { name: '', key: '3', width: 20, type: 'text' },
            {
              name: 'Point',
              key: '4',
              width: 20,
              type: 'text',
              input: {
                onChange: (item, value) => {
                  return setTotalStationJSON({
                    ...totalStationJSON,
                    [item.row]: { ...totalStationJSON[item.row], ['4']: value }
                  })
                },
                type: 'select',
                selectOptions: dataPointOptions,
                unavailableOptions: selectedPointsAndAnchors
              }
            }
          ]}
        />
      </div>
      <div className={styles.button}>
        <Button
          className={'btn--primary'}
          handleClick={(e) => onSubmit(totalStationJSON)}
        >
          Submit
        </Button>
      </div>

      {/* Checkbox for poles */}

      {/* Error message if number of data points is incorrect */}
    </div>
  )
}
