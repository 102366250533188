import { useState, useEffect, useRef } from 'react'

export const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)

  const [clickedOutside, setClickedOutside] = useState(false)

  const ref = useRef(null)

  const handleClickPosition = (event) => {
    const notificationCloseButton = event.target.id === 'notificationClose'
    const clearAllButton = event.target.id === 'clearAll'

    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false)
      return setClickedOutside(false)
    }

    if (ref.current && (notificationCloseButton || clearAllButton)) {
      setIsComponentVisible(true)
      return setClickedOutside(false)
    }

    setClickedOutside(true)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickPosition, true)
    return () => {
      document.removeEventListener('click', handleClickPosition, true)
    }
  })

  return { ref, isComponentVisible, clickedOutside, setIsComponentVisible }
}
