import React, { useEffect, useMemo, useState } from 'react'
import styles from './FlightSelect.module.scss'
import { CardList } from '../../../components/CardList/CardList'
import { Table } from '../../../components/Table/Table'
import {
  CanvasStyle,
  CanvasViewTypes,
  Strack
} from '../../../components/Strack/Strack.types'
import { useChallenge } from '../../../metrics_server/targets/hooks'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { useAppDispatch } from '../../../store/hooks'
import { updateChallenge } from '../../../metrics_server/targets/actions'
import { StrackEvents } from '../../../components/Strack/StrackEvents/StrackEvents'
import { FormattedEventData } from '../../../metrics_server/events/types'
import { generateEventTableData } from '../../../metrics_server/events/functions'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'
import { useFormattedEvents } from '../../../metrics_server/events/hooks'
import { RawFlightEventData } from '../../../metrics_server/events/flight/types'

export interface FlightSelectProps {
  strack: Strack
  strackReady: boolean
  canvasStyle: CanvasStyle
  canvasView: CanvasViewTypes
  setOptions
  active: boolean

  flightEventsFilters
}

export type FlightSelectRow = FormattedEventData & {
  startFlight: boolean
  endFlight: boolean
}

const updateFlights = (flightRows, startTimeFilter, endTimeFilter) => {
  const updatedRows = flightRows.map((flightRow) => {
    const newFlight = {
      ...flightRow,
      startFlight: false,
      endFlight: false
    }
    return newFlight
  })

  for (let i = 0; i < updatedRows.length; i++) {
    const flight = updatedRows[i]
    const prevFlight = updatedRows[i - 1]
    const nextFlight = updatedRows[i + 1]
    if (!prevFlight) {
      if (flight.startTime >= startTimeFilter) {
        flight.startFlight = true
      }
    } else if (
      prevFlight.startTime < startTimeFilter &&
      flight.startTime >= startTimeFilter
    ) {
      flight.startFlight = true
    }
    if (!nextFlight) {
      if (flight.endTime <= endTimeFilter) {
        flight.endFlight = true
      }
    } else if (
      nextFlight.endTime > endTimeFilter &&
      flight.endTime <= endTimeFilter
    ) {
      flight.endFlight = true
    }
  }

  return updatedRows
}

export function FlightSelect({
  strack,
  strackReady,
  canvasStyle,
  canvasView,
  setOptions,
  active,

  flightEventsFilters
}: FlightSelectProps) {
  // Redux //
  const dispatch = useAppDispatch()
  // ===== //

  // Targets //
  const { challenge } = useChallenge()
  // ====== //

  // Session //
  // TODO: Remove session config and replace with formatted session
  const formattedSession = useSelectedFormattedSession()
  const { id, live, sport } = formattedSession
  // ======= //

  const unitSystem = useUnitsSystem(sport)

  const [startTimeFilter, setStartTimeFilter] = useState(challenge.startTime)
  const [endTimeFilter, setEndTimeFilter] = useState(challenge.timeEnd)

  // Transform filtered flights into formatted flight data //
  const formattedFlightData = useFormattedEvents(
    flightEventsFilters.filteredEvents,
    id
  )

  // Transform formatted data into table data //
  const flightTableData = useMemo(() => {
    const tableData = generateEventTableData(
      formattedFlightData.list,
      formattedSession,
      unitSystem
    )
    return updateFlights(tableData, startTimeFilter, endTimeFilter)
  }, [formattedFlightData, startTimeFilter, endTimeFilter])

  useEffect(() => {
    updateChallengeTimes(startTimeFilter, endTimeFilter, challenge)
  }, [startTimeFilter, endTimeFilter])

  const flightTableId = 'flightTable'

  useEffect(() => {
    if (formattedFlightData.list.length > 0 && !live) {
      if (!startTimeFilter) {
        setStartTimeFilter(formattedFlightData.list[0].startTime)
      }
      if (!endTimeFilter) {
        setEndTimeFilter(
          formattedFlightData.list[formattedFlightData.list.length - 1].endTime
        )
      }
    }
  }, [formattedFlightData])

  const updateChallengeTimes = (startTimeFilter, endTimeFilter, challenge) => {
    if (startTimeFilter && endTimeFilter) {
      const data = { ...challenge }
      data.startTime = startTimeFilter
      data.timeEnd = endTimeFilter
      dispatch(updateChallenge(data))
    }
  }

  // Get strack events within the time limits
  const strackEvents = useMemo(() => {
    return flightEventsFilters.filteredEvents.filter(
      (flight: RawFlightEventData) => {
        if (flight.startTime < startTimeFilter) return false
        if (flight.timeEnd > endTimeFilter) return false
        return true
      }
    )
  }, [flightEventsFilters.filteredEvents, startTimeFilter, endTimeFilter])

  return (
    <>
      <StrackEvents
        events={strackEvents}
        active={active}
        canvasView={canvasView}
        strack={strack}
        canvasStyle={canvasStyle}
        strackReady={strackReady}
      />
      <div className={styles.tableContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.selectFlightContainer}>
            <div className={styles.infoContainer}>
              <h5>Select first and last event for this sub-session</h5>
            </div>
            <div className={styles.selectFlightTableContainer}>
              <Table
                // Row props
                id={flightTableId}
                // Table props
                options={{
                  initialOrder: 'asc',
                  initialSortBy: 'startTime',
                  sortActive: false
                }}
                headerFont={13}
                tableClass={'minimalistBlack'}
                className={'container'}
                data={flightTableData}
                headers={[
                  {
                    name: 'Time',
                    key: 'sessionStartTime',
                    width: 25,
                    type: 'time'
                  },
                  { name: 'Player', key: 'fromPlayer', width: 30 },
                  { name: 'Type', key: 'typeName', width: 21 },
                  {
                    key: 'startFlight',
                    name: 'start',
                    width: 9,
                    input: {
                      type: 'checkbox',
                      onChange: (item, bool) =>
                        setStartTimeFilter(item.startTime)
                    }
                  },
                  {
                    key: 'endFlight',
                    name: 'end',
                    width: 9,
                    input: {
                      type: 'checkbox',
                      onChange: (item, bool) => setEndTimeFilter(item.endTime)
                    }
                  }
                ]}
              />
            </div>
          </div>
        </CardList>
      </div>
    </>
  )
}
