import React from 'react'
import { sportableColors } from '../../../constants/sportableColors'

export interface ChevronDownProps {
  scale?: number
  color?: string
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  strokeWidth?: number
  style?: React.CSSProperties
}

export const ChevronDown = ({
  scale = 1,
  color = sportableColors.colors.sportableGreyDark,
  handleClick,
  strokeWidth,
  style
}: ChevronDownProps) => {
  return (
    <div onClick={handleClick || undefined} style={style}>
      <svg
        width={`${26 * scale}`}
        height={`${14 * scale}`}
        viewBox='0 0 26 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1 1L12.9531 12.8125L24.9062 1'
          stroke={color}
          strokeWidth={`${strokeWidth || 1}`}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}
