// Sportscaster
import { sportscasterReducer, sportscasterActions } from './sportscaster'
import { SportscasterState } from './sportscaster/types'

// Insights
import { insightsReducer, insightsActions } from './insights'
import { InsightsState } from './insights/types'

// Events
import { eventsReducer, eventActions } from './events'
import { EventsState } from './events/types'

// Version
import { versionActions, versionReducer } from './version'
import { VersionState } from './version/types'

// Sessions
import { sessionsActions, sessionsReducer } from './sessions'
import { SessionsState } from './sessions/types'

// Pitches
import { pitchesActions, pitchesReducer } from './pitches'
import { PitchesState } from './pitches/types'

// Teams
import { teamActions, teamsReducer } from './teams'
import { TeamsState } from './teams/types'

// Teams
import { organisationActions, organisationsReducer } from './organisations'
import { OrganisationsState } from './organisations/types'

// Hardware
import { hardwareActions, hardwareReducer } from './hardware'
import { HardwareState } from './hardware/types'

// Graphics
import { graphicsActions, graphicsReducer } from './graphics'
import { GraphicsState } from './graphics/types'

// Graphics
import { broadcastingActions, broadcastingReducer } from './broadcasting'
import { BroadcastingState } from './broadcasting/types'

// Players
import { playerActions, playersReducer } from './players'
import { PlayersState } from './players/types'

// Units
import { unitActions, unitReducer } from './units'
import { UnitsState } from './units/types'

// Targets
import { targetActions, targetsReducer } from './targets'
import { TargetsState } from './targets/types'

// MQTT
import { mqttReducer, mqttActions } from './mqtt'
import { MQTTState } from './mqtt/types'

// Notifications
import { notificationsReducer, notificationActions } from './notifications'
import { NotificationsState } from './notifications/types'

// Competitions
import { competitionReducer, competitionActions } from './competitions'
import { CompetitionsState } from './competitions/types'

// Sub Sessions
import { subSessionsReducer, subSessionsActions } from './sub_sessions'
import { SubSessionsState } from './sub_sessions/types'

// Status
import { statusReducer, statusActions } from './status'
import { StatusState } from './status/types'

// User
import { userActions, userReducer } from './user'
import { UserState } from './user/types'

// Broadcast integration
import {
  broadcastIntegrationReducer,
  BroadcastIntegrationState
} from './broadcast_integration/slice'

import { drillsReducer, DrillsState } from './drills/slice'

// Types
export interface ApiState {
  competitions: CompetitionsState
  insights: InsightsState
  events: EventsState
  version: VersionState
  sessions: SessionsState
  sportscaster: SportscasterState
  teams: TeamsState
  organisations: OrganisationsState
  hardware: HardwareState
  broadcasting: BroadcastingState
  graphics: GraphicsState
  units: UnitsState
  players: PlayersState
  targets: TargetsState
  mqtt: MQTTState
  notifications: NotificationsState
  pitches: PitchesState
  subSessions: SubSessionsState
  user: UserState
  status: StatusState
  broadcastIntegration: BroadcastIntegrationState
  drills: DrillsState
}

// Reducers
export const reducers = {
  competitions: competitionReducer,
  insights: insightsReducer,
  version: versionReducer,
  events: eventsReducer,
  sessions: sessionsReducer,
  sportscaster: sportscasterReducer,
  teams: teamsReducer,
  organisations: organisationsReducer,
  hardware: hardwareReducer,
  broadcasting: broadcastingReducer,
  graphics: graphicsReducer,
  units: unitReducer,
  players: playersReducer,
  targets: targetsReducer,
  mqtt: mqttReducer,
  notifications: notificationsReducer,
  pitches: pitchesReducer,
  subSessions: subSessionsReducer,
  user: userReducer,
  status: statusReducer,
  broadcastIntegration: broadcastIntegrationReducer,
  drills: drillsReducer
}

// Actions
export const actions = {
  competition: competitionActions,
  insights: insightsActions,
  events: eventActions,
  version: versionActions,
  session: sessionsActions,
  sportscaster: sportscasterActions,
  teams: teamActions,
  organisations: organisationActions,
  hardware: hardwareActions,
  broadcasting: broadcastingActions,
  graphics: graphicsActions,
  units: unitActions,
  players: playerActions,
  targets: targetActions,
  mqtt: mqttActions,
  notification: notificationActions,
  pitches: pitchesActions,
  subSessions: subSessionsActions,
  user: userActions,
  status: statusActions
}

// Container
export { MSUserHOC } from '../apps/MSUserHOC'
export { MSServerHOC } from '../apps/MSServerHOC'
