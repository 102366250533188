import React from 'react'
import styles from './BatteryIcon.module.scss'
import chargingIconBlack from '../../../assets/img/charging-bolt-black.svg'
import chargingIconYellow from '../../../assets/img/charging-bolt-yellow.svg'
import { FormattedDevice } from '../../../metrics_server/hardware/types'
import { getBatteryLevel } from '../../../metrics_server/hardware/functions'

export interface BatteryIconProps {
  device: FormattedDevice
  isAnchor?: boolean
}

export const BatteryIcon = ({ device, isAnchor }: BatteryIconProps) => {
  const battery = getBatteryLevel(device.battery.value)
  return (
    <div className={styles.container}>
      <img
        className={!isAnchor ? styles.batteryImg : ''}
        src={isAnchor ? battery.anchorImg : battery.batteryImg}
        alt=''
      />
      {device.chargingState === 'trickleCharging' && (
        <img className={styles.chargingIcon} src={chargingIconBlack} alt='' />
      )}
      {device.chargingState === 'charging' && (
        <img className={styles.chargingIcon} src={chargingIconYellow} alt='' />
      )}
    </div>
  )
}
