import React, { useCallback } from 'react'
import styles from './DrillsQuickStart.module.scss'
import { timeEventTypes } from '../../../../metrics_server/events/time/data_types'
import { Button } from '../../../../components/Button/Button'
import { useSelectedFormattedSession } from '../../../../metrics_server/sessions/hooks'
import { useAppDispatch } from '../../../../store/hooks'
import {
  createNewDrill,
  stopDrill
} from '../../../../metrics_server/drills/thunks'
import { useDrills } from '../../../../metrics_server/drills/hooks'

export function DrillsQuickStart() {
  const formattedSession = useSelectedFormattedSession()
  const { drills } = useDrills()
  const { live } = formattedSession

  const dispatch = useAppDispatch()

  const drillStarted = drills.drills.some((drill) => {
    return drill.startTime && !drill.timeEnd
  })

  // Function to count running drills
  const runningDrillsCount = useCallback(() => {
    return drills.drills.filter((drill) => drill.startTime && !drill.timeEnd)
      .length
  }, [drills.drills])

  // Determine button text based on running drills count
  const buttonText = useCallback(() => {
    const count = runningDrillsCount()
    if (count > 1) {
      return 'Stop All Drills'
    } else if (count === 1) {
      return 'Stop Drill'
    } else {
      return `Drill ${drills.drills.length + 1} Quick Start`
    }
  }, [drills.drills.length, runningDrillsCount])

  const handleDrillQuickStart = async () => {
    dispatch(
      createNewDrill({
        name: `Drill ${drills.drills.length + 1} - Quick Start`,
        quickStart: true
      })
    )
  }

  const handleStopDrill = () => {
    drills.drills.forEach((drill) => {
      if (drill.startTime && !drill.timeEnd) {
        dispatch(stopDrill(drill.id))
      }
    })
  }

  if (!live) {
    return null
  }

  return (
    <div className={styles.container}>
      <Button
        className='btn--border btn--thin btn--small'
        handleClick={drillStarted ? handleStopDrill : handleDrillQuickStart}
        style={{
          backgroundColor: drillStarted
            ? timeEventTypes.items.stoppage.props.features.buttons.stop.colour
            : timeEventTypes.items.stoppage.props.features.buttons.start.colour
        }}
        loading={drills.loading}
      >
        {buttonText()}
      </Button>
    </div>
  )
}
