// Used to name a new challenge before creating it and redirecting to challenge screen

import React, { useCallback } from 'react'
import * as TargetScreen from '../../TargetSetup/config'
import { useAppDispatch } from '../../../store/hooks'
import { NameForm } from '../../../components/Forms/NameForm/NameForm'
import { useSession } from '../../../metrics_server/sessions/hooks'
import { createChallenge } from '../../../metrics_server/targets/actions'
import { toggleModal } from '../../../ui/modal/actions'
import { setRedirect } from '../../../ui/router/actions'

export const useNewChallengeModal = () => {
  // Redux //
  const dispatch = useAppDispatch()
  // ====== //

  const sessionConfig = useSession()
  const { session } = sessionConfig

  const openNewChallengeModal = useCallback(() => {
    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        wrapper: true,
        ChildComponent: () => (
          <NameForm
            handleSubmit={(values) => {
              dispatch(
                createChallenge(
                  {
                    ...values,
                    sessionId: session.id,
                    type: 0
                  },
                  () => {
                    dispatch(setRedirect(TargetScreen.path))
                  }
                )
              )
              dispatch(toggleModal({}))
            }}
          />
        ),
        className: 'modalSmall',
        title: 'Create sub-session',
        handleSecondaryBtn: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }, [])

  return {
    openNewChallengeModal
  }
}
