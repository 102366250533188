export type SessionStatesType = {
  name: string
  value: string
}

export const sessionStates: { [key: string]: SessionStatesType } = {
  notStarted: { name: 'Scheduled', value: 'NotStarted' },
  started: { name: 'Active', value: 'Started' },
  finished: { name: 'Finished', value: 'Finished' }
}
