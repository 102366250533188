import {
  GraphicsState,
  SET_ASPECT_RATIOS,
  SET_GRAPHICS_TYPES,
  SET_TOURNAMENTS,
  UPDATE_GRAPHIC_STATE
} from './types'
import { REMOVE_USER_DATA } from '../types'

const initialState: GraphicsState = {
  // HighestHangTimeKick: {
  //   '1x1': {
  //     image: null,
  //     generating: false
  //   },
  //   '16x9': {
  //     image: null,
  //     generating: false
  //   }
  // },
  graphicsTypes: [],
  tournaments: [],
  aspectRatios: []
}

export function graphicsReducer(state = initialState, action) {
  let data
  switch (action.type) {
    case UPDATE_GRAPHIC_STATE:
      data = action.payload.data

      return {
        ...state,
        [data.Graphic]: {
          ...state[data.Graphic],
          [data.AspectRatio]: {
            ...state[data.AspectRatio],
            ...action.payload
          }
        }
      }

    case SET_GRAPHICS_TYPES:
      return {
        ...state,
        graphicsTypes: action.payload
      }

    case SET_TOURNAMENTS:
      return {
        ...state,
        tournaments: action.payload
      }

    case SET_ASPECT_RATIOS:
      return {
        ...state,
        aspectRatios: action.payload
      }

    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
