import {
  // CREATE_PLAYER,
  SET_INFO
} from './types'

import { InfoState } from './types'

const initialState: InfoState = {}

export function infoReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INFO:
      return action.payload
    default:
      return state
  }
}
