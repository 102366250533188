import { SportTypeKey } from '../data_types'
import {
  Coordinate,
  Pitch,
  PitchCoordinates,
  PitchDimensions
} from '../../pitches/types'
import { sportTypeKey } from '..'

export interface CanadianFootballPitch extends Pitch {
  type: SportTypeKey['canadianFootball']
  pitch: CanadianFootballPitchDimensions
}

export interface CanadianFootballPitchDimensions extends PitchDimensions {
  length: number
  width: number
  poles: {
    width: number
    height: number
    crossbarHeight: number
  }
  coordinates: CanadianFootballPitchCoordinates
}

export interface CanadianFootballPitchCoordinates extends PitchCoordinates {
  P1: Coordinate
  P2: Coordinate
  P3: Coordinate
  P4: Coordinate
  P5: Coordinate
  P6: Coordinate
  P7: Coordinate
  P8: Coordinate
  P9: Coordinate
  P10: Coordinate
  P11: Coordinate
  P12: Coordinate
  P13: Coordinate
  P14: Coordinate
  P15: Coordinate
  P16: Coordinate
  P17: Coordinate
  P18: Coordinate
  P19: Coordinate
  P20: Coordinate
  P21: Coordinate
  P22: Coordinate
  P23: Coordinate
  P24: Coordinate
  P25: Coordinate
  P26: Coordinate
  P27: Coordinate
  P28: Coordinate
  P29: Coordinate
  P30: Coordinate
  P31: Coordinate
  P32: Coordinate
  P33: Coordinate
  P34: Coordinate
}

const coordinates = {
  P1: {
    x: -68.58,
    y: 0,
    z: 0
  },
  P10: {
    x: 13.716,
    y: 0,
    z: 0
  },
  P11: {
    x: 22.86,
    y: 0,
    z: 0
  },
  P12: {
    x: 32.004,
    y: 0,
    z: 0
  },
  P13: {
    x: 41.148,
    y: 0,
    z: 0
  },
  P14: {
    x: 50.292,
    y: 0,
    z: 0
  },
  P15: {
    x: 68.58,
    y: 0,
    z: 0
  },
  P16: {
    x: 68.58,
    y: 59.436,
    z: 0
  },
  P17: {
    x: 50.292,
    y: 59.436,
    z: 0
  },
  P18: {
    x: 41.148,
    y: 59.436,
    z: 0
  },
  P19: {
    x: 32.004,
    y: 59.436,
    z: 0
  },
  P2: {
    x: -50.292,
    y: 0,
    z: 0
  },
  P20: {
    x: 22.86,
    y: 59.436,
    z: 0
  },
  P21: {
    x: 13.716,
    y: 59.436,
    z: 0
  },
  P22: {
    x: 4.572,
    y: 59.436,
    z: 0
  },
  P23: {
    x: 0,
    y: 59.436,
    z: 0
  },
  P24: {
    x: -4.572,
    y: 59.436,
    z: 0
  },
  P25: {
    x: -13.716,
    y: 59.436,
    z: 0
  },
  P26: {
    x: -22.86,
    y: 59.436,
    z: 0
  },
  P27: {
    x: -32.004,
    y: 59.436,
    z: 0
  },
  P28: {
    x: -41.148,
    y: 59.436,
    z: 0
  },
  P29: {
    x: -50.292,
    y: 59.436,
    z: 0
  },
  P3: {
    x: -41.148,
    y: 0,
    z: 0
  },
  P30: {
    x: -68.58,
    y: 59.436,
    z: 0
  },
  P31: {
    x: -50.292,
    y: 26.899,
    z: 3.048
  },
  P32: {
    x: -50.292,
    y: 32.537,
    z: 3.048
  },
  P33: {
    x: 50.292,
    y: 32.537,
    z: 3.048
  },
  P34: {
    x: 50.292,
    y: 26.899,
    z: 3.048
  },
  P4: {
    x: -32.004,
    y: 0,
    z: 0
  },
  P5: {
    x: -22.86,
    y: 0,
    z: 0
  },
  P6: {
    x: -13.716,
    y: 0,
    z: 0
  },
  P7: {
    x: -4.572,
    y: 0,
    z: 0
  },
  P8: {
    x: 0,
    y: 0,
    z: 0
  },
  P9: {
    x: 4.572,
    y: 0,
    z: 0
  }
}

export const defaultPitch: CanadianFootballPitch = {
  id: '1',
  name: 'Default Canadian Football Pitch',
  type: sportTypeKey.canadianFootball,
  anchors: null,
  arcs: null,
  pitch: {
    coordinates,
    length: 109.728,
    width: 59.436,
    poles: {
      width: 5.4864,
      height: 5.4864,
      crossbarHeight: 3.048
    }
  }
}
