import React, { useState, useCallback } from 'react'
import { ColumnQuery } from '../../../../metrics_server/insights/types'

export function useColumnQueryForm(initialColumns) {
  const blankColumnQueryFormValues: ColumnQuery = {
    id: '',
    name: '',
    flightType: null,
    flightSubTypes: [],
    aggregation: 'NoOp',
    stat: 'Distance'
  }

  const [columns, setColumns] = useState<ColumnQuery[]>(initialColumns)

  const addColumn = (id) => {
    setColumns([
      ...columns,
      {
        ...blankColumnQueryFormValues,
        id
      }
    ])
  }

  const updateValue = useCallback(
    (id, name, value) => {
      const newColumns = columns.map((item) => {
        if (item.id === id) {
          const newItem = { ...item }
          newItem[name] = value
          return newItem
        }
        return item
      })
      setColumns(newColumns)
    },
    [columns]
  )

  const removeColumn = (id) => {
    const newColumns = [...columns]
    newColumns.filter((column) => {
      return id !== column.id
    })
    setColumns(newColumns)
  }

  return { columns, setColumns, addColumn, removeColumn, updateValue }
}
