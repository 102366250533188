import {
  SelectedSportscaster,
  SportscasterState,
  SportscasterTableRow
} from './types'
import {
  TableHeader,
  TableInputOnChange
} from '../../components/Table/Table.types'

export function getSelectedSportscaster(
  sportscaster: SportscasterState
): SelectedSportscaster {
  const { selectedSportscasterHost, status, config } = sportscaster
  let selectedConfig = config.find((x) => {
    return x.WSSHost === selectedSportscasterHost
  })
  if (!selectedConfig) return {} as SelectedSportscaster
  let selectedStatus = status.find((x) => x.host === selectedConfig.Host)
  if (!selectedStatus) return {} as SelectedSportscaster
  return {
    ...selectedStatus,
    ...selectedConfig
  }
}

export function getSelectedBroker(
  sportscaster: SportscasterState
): SelectedSportscaster {
  const { selectedBrokerHost, status, config } = sportscaster
  let selectedConfig = config.find((x) => {
    return x.WSSHost === selectedBrokerHost
  })
  if (!selectedConfig) return {} as SelectedSportscaster
  let selectedStatus =
    status.find((x) => x.host === selectedConfig.Host) ||
    ({} as SelectedSportscaster)
  return {
    ...selectedStatus,
    ...selectedConfig
  }
}

export function getSportscasterTableData(
  sportscaster: SportscasterState,
  type: 'selectedBrokerHost' | 'selectedSportscasterHost'
): SportscasterTableRow[] {
  return sportscaster.status.map((x) => {
    let row = { ...x } as SportscasterTableRow

    let config = sportscaster.config.find((y) => {
      return y.Host === x.host
    })

    if (!config) return row

    row.id = `${x.host}-${x.locationName}`
    row.statusColor = x.status ? 'green' : 'red'
    row.cloudIcon = x.cloud ? 'cloud' : ''
    row.selected = sportscaster[type] === config.WSSHost
    row.mqtt = sportscaster.health[config.WSSHost]?.mqtt ? 'green' : 'red'
    row.healthy = sportscaster.health[config.WSSHost]?.mqtt
      ? sportscaster.health[config.WSSHost]?.healthy
        ? 'green'
        : 'red'
      : 'transparent'
    row.version = sportscaster.health[config.WSSHost]?.version
    return row
  })
}

export function getSporscasterTableHeaders(
  handleSelectedCheckboxChange: TableInputOnChange
): TableHeader[] {
  return [
    { name: '', key: 'cloudIcon', type: 'icon', width: 5 },
    { name: 'Name', key: 'name', width: 50 },
    { name: 'Version', key: 'version', width: 15 },
    { name: 'MS > Broker', key: 'statusColor', type: 'color', width: 10 },
    { name: 'Broker > UI', key: 'mqtt', type: 'color', width: 10 },
    { name: 'Sportscaster', key: 'healthy', type: 'color', width: 10 }
    // {
    //     key: 'selected',
    //     name: '',
    //     input: {
    //         type: 'checkbox',
    //         onChange: handleSelectedCheckboxChange
    //     },
    //     width: 5
    // },
  ]
}

export function getSportscasterBaseURL(selectedSportscaster) {
  // Use regex to determine protocol and path of broker connection
  let regex = new RegExp('^wss')
  let isWSS = regex.test(selectedSportscaster.WSSHost)
  let protocol = isWSS ? `https://` : `http://`

  return `${protocol}${selectedSportscaster.SportscasterHost}`
}

export function getSportscasterRouteOptions(sportscaster) {
  const selectedSportscaster = getSelectedSportscaster(sportscaster)
  return sportscaster.routeOptions.map((routeOption) => {
    return {
      ...routeOption,
      value: getSportscasterRoute(selectedSportscaster, routeOption.value)
    }
  })
}

export function getSportscasterRoute(selectedSportscaster, route) {
  if (route === 'locations') return `api/v2/locations`
  if (route === 'genius') return `api/broadcast/genius/match_summary`
  return `api/broadcast/v2/${selectedSportscaster.locationName}${route}`
}
