import React from 'react'
import styles from './PitchDimensions.module.scss'
import { Strack } from '../../Strack.types'

export interface PitchDimenstionsInfoCardProps {
  strack: Strack
}

export const PitchDimenstionsInfoCard: React.FC<
  PitchDimenstionsInfoCardProps
> = ({ strack }) => {
  const { field } = strack

  return (
    <div className={styles.pitchDimenstionsInfoCardContainer}>
      <div>Width: {field.width.toFixed(2)}m</div>
      <div>Height: {field.height.toFixed(2)}m</div>
    </div>
  )
}
