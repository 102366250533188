import React from 'react'
import Scroller from '../../../components/Scroller/Scroller'
import { ShirtGrid } from '../../../components/ShirtGrid/ShirtGrid'
import { TeamList } from '../../../components/TeamList/TeamList'
import { PlayerAssignmentGrid } from '../../../components/PlayersGrid/PlayersGrid'
import { HardwareGrid } from '../../../components/HardwareGrid/HardwareGrid'
import { Toggle } from '../../../components/Forms/Fields/Toggle/Toggle'
import { usePlayerShirtTagAssignmentLogic } from './PlayerShirtTagAssignment.logic'
import styles from '../SessionSetup.module.scss'
import { Tab } from '../../../components/Views/Main/Main.types'
import { useFormattedSession } from '../../../metrics_server/sessions/hooks'
import { deviceTypes } from '../../../metrics_server/hardware/data_types'
import { SessionSetupTabs } from '../tab_config'

type PlayerShirtTagAssignmentProps = {
  tab: Tab
}

export const PlayerShirtTagAssignment = (
  props: PlayerShirtTagAssignmentProps
) => {
  const { tab } = props
  const teamId = tab.props.teamId
  const { sport, isTrainingMode } = useFormattedSession('setup')

  const {
    setShirtsOrTeamListView,
    shirtsOrTeamListView,
    playersOrTagsView,
    setPlayersOrTagsView
  } = usePlayerShirtTagAssignmentLogic(teamId)

  if (!teamId) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        Team not found
      </div>
    )
  }

  if (
    isTrainingMode ||
    !sport.props.features.positionNumbers ||
    tab.props.key === SessionSetupTabs.OFFICIATING_TEAM
  ) {
    // TRAINING MODE & FIXED PLAYER NUMBER SETUP //
    return (
      <div className={styles.playerShirtTagsViewContainer}>
        <div className={styles.assignmentDivisionContainer}>
          <PlayerAssignmentGrid teamId={teamId} sessionId='setup' />
        </div>
        <div className={styles.assignmentDivisionContainer}>
          <HardwareGrid
            deviceTypeValue={deviceTypes.items.playerTag.value}
            teamId={teamId}
            isSessionAssignment={true}
          />
        </div>
      </div>
    )
  } else {
    // POSITION NUMBER SETUP //
    return (
      <div className={styles.playerShirtTagsViewContainer}>
        {/* SHIRTS AND TEAM LIST */}
        <div className={styles.assignmentDivisionContainer}>
          <div className={styles.assignmentToggleContainer}>
            <Toggle
              input={{
                value: shirtsOrTeamListView,
                onChange: (value) => setShirtsOrTeamListView(value)
              }}
              options={[
                { name: '1. Shirts', value: 0 },
                { name: '2. Team List', value: 1 }
              ]}
              isSkillTracker={false}
              size={'s'}
            />
          </div>
          <h5 className={styles.title}>Shirts</h5>
          {shirtsOrTeamListView === 0 ? (
            <div className={styles.gridContainer}>
              <Scroller scrollerId='scroller-' scrollerClass='form-scroller'>
                <div>
                  <ShirtGrid teamId={teamId} sessionId='setup' />
                </div>
              </Scroller>
            </div>
          ) : (
            // TEAMLIST
            <div className={styles.gridContainer}>
              <Scroller
                scrollerId='scroller-shirtGrid'
                scrollerClass='form-scroller'
              >
                <div>
                  <TeamList teamId={teamId} sessionId='setup' />
                </div>
              </Scroller>
            </div>
          )}
        </div>
        {/* PLAYERS AND TAGS */}
        <div className={styles.assignmentDivisionContainer}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <div className={styles.assignmentToggleContainer}>
              <Toggle
                input={{
                  value: playersOrTagsView,
                  onChange: (value) => setPlayersOrTagsView(value)
                }}
                options={[
                  { name: '1. Players', value: 0 },
                  { name: '2. Tags', value: 1 }
                ]}
                isSkillTracker={false}
                size={'s'}
              />
            </div>
          </div>

          {playersOrTagsView === 0 ? (
            <>
              <h5 className={styles.title}>Drag & Drop Players onto Shirts</h5>
              <PlayerAssignmentGrid teamId={teamId} sessionId='setup' />
            </>
          ) : (
            <>
              <h5 className={styles.title}>
                Drag & Drop Tags onto Assigned Shirts
              </h5>
              <HardwareGrid
                deviceTypeValue={deviceTypes.items.playerTag.value}
                teamId={teamId}
                isSessionAssignment={true}
              />
            </>
          )}
        </div>
      </div>
    )
  }
}
