import { drawCircle, drawLine } from '../2D/drawing'
import pitchTexture from '../../../assets/img/pitch-texture.png'

export function applyDemonstrationSettings(field, poles, dimensions) {
  field.height = dimensions.P1.y - dimensions.P2.y
  field.width = dimensions.P3.x - dimensions.P2.x
  field.edges = 10

  // Set origin offset to centralise pitch lines
  field.originOffsetX =
    (Math.abs(dimensions.P2.x) - Math.abs(dimensions.P3.x)) / 2
  field.originOffsetY =
    -(Math.abs(dimensions.P2.y) - Math.abs(dimensions.P1.y)) / 2

  field.color = 'rgb(0, 153, 204)'
}

// Draw rugby pitch background on 2D canvas
export const drawDemonstrationLines = (props, color, ctx, pixelScale, view) => {
  const { field, dimensions, canvasElementPixelRatio } = props

  let getCanvasCoordinate = props.getCanvasCoordinate

  if (view === '3D') {
    getCanvasCoordinate = props.get3DCanvasCoordinate
  }

  // Border

  ctx.beginPath()
  ctx.setLineDash([0])
  ctx.moveTo(
    getCanvasCoordinate(pixelScale, dimensions.P1.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P1.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P2.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P2.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P3.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P3.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P4.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P4.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P1.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P1.y).scaleY
  )

  ctx.fillStyle = 'rgba(0,0,0,0.1)'

  ctx.fill()

  ctx.lineWidth = 3
  ctx.strokeStyle = color
  ctx.stroke()

  // Grid lines

  const twoDOffsetY = props.twoDOffsetY
  const twoDOffsetX = props.twoDOffsetX

  for (let i = -200; i < 200; i++) {
    drawLine(
      i,
      field.height * 4,
      i,
      -field.height * 4,
      ctx,
      null,
      i === 0 ? 2 : 1,
      i === 0 ? 'red' : 'rgba(255,255,255,0.5)',
      getCanvasCoordinate,
      pixelScale
    )
  }

  for (let i = -200; i < 200; i++) {
    drawLine(
      field.width * 4,
      i,
      -field.width * 4,
      i,
      ctx,
      null,
      i === 0 ? 2 : 1,
      i === 0 ? 'red' : 'rgba(255,255,255,0.5)',
      getCanvasCoordinate,
      pixelScale
    )
  }
}
