import React from 'react'
import styles from './MainHeader.module.scss'

export interface MainHeaderProps {
  editMode?: boolean
  title: string
  options: Array<Options>
}

interface Options {
  name: string
  callback: () => void
  hidden?: boolean
}

export const MainHeader: React.FC<MainHeaderProps> = ({
  title,
  editMode,
  options
}) => {
  const renderOptions = (option: Options, index: number) => {
    // if (option.hidden) {
    //   return <noscript />
    // }
    return (
      <h5
        style={{ display: option.hidden ? 'none' : 'block' }}
        key={index}
        onClick={option.callback}
        className={styles.optionButton}
      >
        {' '}
        {option.name}
      </h5>
    )
  }
  return (
    <div className={styles.headingContainer}>
      {title && (
        <h2 id={'title'} className={styles.heading}>
          {title}
        </h2>
      )}
      <div style={{ display: 'flex' }}>{options.map(renderOptions)}</div>
    </div>
  )
}
