import { START_LOADING, STOP_LOADING } from './types'
import { StartLoading, StopLoading } from './types'

export const startLoading: StartLoading = (id) => {
  return { type: START_LOADING, payload: id }
}

export const stopLoading: StopLoading = (id) => {
  return { type: STOP_LOADING, payload: id }
}
