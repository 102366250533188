import React from 'react'
import { sportableColors } from '../../../constants/sportableColors'

export interface SummaryIconProps {
  scale?: number
  color?: string
}

export const SummaryIcon: React.FC<SummaryIconProps> = ({
  scale = 1,
  color = sportableColors.colors.sportableGreyDark
}) => {
  return (
    <svg
      width={33 * scale}
      height={33 * scale}
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M2 12.3333C2 7.09578 2 4.47633 3.62778 2.84995C5.25417 1.22217 7.87361 1.22217 13.1111 1.22217H15.8889C21.1264 1.22217 23.7458 1.22217 25.3722 2.84995C27 4.47633 27 7.09578 27 12.3333V17.8888C27 23.1263 27 25.7458 25.3722 27.3722C23.7458 28.9999 21.1264 28.9999 15.8889 28.9999H13.1111C7.87361 28.9999 5.25417 28.9999 3.62778 27.3722C2 25.7458 2 23.1263 2 17.8888V12.3333Z'
        stroke={color}
        strokeWidth='2.25'
      />
      <path
        d='M8.48145 14.1851H19.5926M8.48145 18.3518H15.4259M8.48145 9.5555H19.5926'
        stroke={color}
        strokeWidth='2.25'
        strokeLinecap='round'
      />
    </svg>
  )
}
