import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosPromise,
  AxiosError
} from 'axios'

import { signoutUser } from '../metrics_server/user/actions'
import { setError } from '../ui/error/actions'
import { AppDispatch } from '../store'

export type SuccessCallback<ResponseDataType> = (
  response: AxiosResponse<ResponseDataType>
) => void

export type AppAPIError = AxiosError

export type ErrorCallback = (error: AppAPIError, type: string) => void

export type APIRequestHandler<RequestDataType, ResponseDataType> = (
  handleSuccess: SuccessCallback<ResponseDataType>,
  handleError: ErrorCallback,
  data: RequestDataType
) => ReturnType<typeof request<AxiosResponse<ResponseDataType>>>

export function request<ResponseDataType>(
  options: any,
  successCallback?: SuccessCallback<ResponseDataType>,
  errorCallback?: ErrorCallback,
  dispatch?: AppDispatch
) {
  if (options.withCredentials) {
    options.headers = {
      ...options.headers,
      Authorization: localStorage.getItem('authorization')
    }
  }
  return axios(options)
    .then(
      (response) => {
        if (successCallback)
          successCallback(response as AxiosResponse<ResponseDataType>)
      },
      (error) => {
        if (errorCallback) errorCallback(error, 'error')
        if (dispatch) errorHandler(error, dispatch)
      }
    )
    .catch((error) => {
      // Something happened in setting up the request that triggered an Error
      console.log('Error setting up request,', error)
    })
}

// New request handler for async api requests (return a promise)
// TODOO: URGENT Fix this urgently
export type AppRequest<RequestDataType, ResponseDataType> = (
  data?: RequestDataType
) => any

export function executeAppRequest(options: AxiosRequestConfig) {
  if (options.withCredentials) {
    options.headers = {
      ...options.headers,
      Authorization: localStorage.getItem('authorization')
    }
  }
  return axios(options)
}

const errorHandler = (error: AppAPIError, dispatch: AppDispatch) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data)
    console.log(error.response.status)
    console.log(error.response.headers)

    // Logout user if response is 401 (Unauthorised)
    console.log(error.response)
    if (error.response.status === 401) {
      setTimeout(() => {
        console.log('setting error')
        dispatch(
          setError({
            message: 'User no longer authenticated please login again...',
            proceed: () => dispatch(signoutUser())
          })
        )
      }, 1000)
    }
    console.log(error.config)
  }
}

export const generateAPIErrorMessage = (
  error: AxiosError,
  type: 'errorDetail' | 'error',
  customMessage?: string
) => {
  if (customMessage) return customMessage
  return error.response && error.response.data && error.response.data[type]
    ? error.response.data[type]
    : `API request failed. Check log for more detail.`
}
