import { useMemo } from 'react'
import { useSessionStrackProps } from '../../components/Strack/hooks'
import { useSessions } from '../../metrics_server/sessions/hooks'
import { TargetSetupTabs } from './tab_config'
import { useChallenge } from '../../metrics_server/targets/hooks'
import { ViewStrackProps } from '../../components/Views/Views.types'

export const useTargetSetupViewStrackProps = (view): ViewStrackProps => {
  const sessions = useSessions()
  const strackProps = useSessionStrackProps(sessions.selectedId)

  // Targets //
  const { challenge } = useChallenge()
  // ======= //

  // Manage canvas visibility //
  const hiddenCanvases = useMemo(() => {
    const { tabs } = view
    if (challenge.Targets?.length < 1) {
      return [tabs[TargetSetupTabs.TARGET_SELECT].strack.options.canvasId]
    }
    return []
  }, [challenge.Targets])
  // ============= //

  return useMemo(() => {
    return {
      enabled: true,
      ...strackProps,
      hiddenCanvases
    }
  }, [strackProps, hiddenCanvases])
}
