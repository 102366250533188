import React from 'react'
import { qaStateNames } from '../../../../const'
import styles from './QaCell.module.scss'
import { TableHeader, TableRow } from '../../Table.types'

export interface QaCellProps {
  item: TableRow
  header: TableHeader
  isAdmin: boolean
}

export const QaCell: React.FC<QaCellProps> = ({ item, header, isAdmin }) => {
  let tableIdArray = item.tableId.split('-')
  let lastElement = tableIdArray[tableIdArray.length - 1]
  const isNew = lastElement === 'new'

  let reports = item[header.key]

  const reportsKeysArray = Object.keys(reports)

  const renderQaImg = (key, reports, index) => {
    const isInvestigateOrRetiredQaState =
      key === 'investigate' || key === 'retired'

    let report = reports[key]
    let name = qaStateNames[key]

    return (
      <div
        key={index}
        style={{
          background: isInvestigateOrRetiredQaState
            ? !!report.passed
              ? 'red'
              : 'green'
            : !!report.passed
            ? 'green'
            : 'red'
        }}
        className={styles.qaImgContainer}
      >
        {name[0]}
      </div>
    )
  }

  return (
    <div className={styles.qaCellContainer}>
      <div className={styles.qaStateList}>
        {reportsKeysArray.map((key, i) => renderQaImg(key, reports, i))}
      </div>
      {isAdmin && !isNew && header.input && (
        <div
          className={styles.tableButton}
          onClick={() => header.input.callback(item)}
        ></div>
      )}
    </div>
  )
}
