import React, { useEffect, useMemo, useState } from 'react'
import { environment, isLocal } from '../../metrics_server/env'
import { getShortcuts } from '../../constants/shortcuts'

import { AppInformationToolTipIcon } from '../AppInformationToolTipIcon/AppInformationToolTipIcon'
import { HardwareGrid } from '../HardwareGrid/HardwareGrid'
import { MSDBSyncStatus } from '../MSDBSyncStatus/MSDBSyncStatus'
import { Options } from '../Options/Options'
import { SessionShortcutsToolTip } from '../SessionShortcutsToolTip/SessionShortcutsToolTip'
import { SportscasterTable } from '../SportscasterTable/SportscasterTable'
import Loader from '../Loader/Loader'
import { NetworkSettings } from '../NetworkSettings/NetworkSettings'
import { NotificationsList } from '../Notifications/NotificationsList/NotificationsList'
import { sportableColors } from '../../constants/sportableColors'
import { MIcon } from '../Icons/MIcon/MIcon'
import {
  useOpenUnitSelectModal,
  useUnits
} from '../../metrics_server/units/hooks'
import { MSErrors } from '../MSErrors/MSErrors'
import { isTraining } from '../../metrics_server/sessions/functions'
import {
  DeviceType,
  deviceTypes
} from '../../metrics_server/hardware/data_types'
import { diagnosticsPath } from '../../views/Diagnostics/config'
import { isSentrySetup, openSentryBugReporting } from '../../sentry'
import {
  getAppTypeCheck,
  getTokenExpiration
} from '../../metrics_server/user/functions'
import { useUser } from '../../metrics_server/user/hooks'
import { useHardware } from '../../metrics_server/hardware/hooks'
import { useVersion } from '../../metrics_server/version/hooks'
import { useBroadcasting } from '../../metrics_server/broadcasting/hooks'
import { useSportscaster } from '../../metrics_server/sportscaster/hooks'
import {
  useSessions,
  useStartSession
} from '../../metrics_server/sessions/hooks'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { toggleModal } from '../../ui/modal/actions'
import { toggleAutoBroadcasting } from '../../metrics_server/broadcasting/actions'
import { setInfo } from '../../ui/info/actions'
import {
  clearNotificationInHistory,
  clearNotificationsHistory,
  toggleNotifications
} from '../../metrics_server/notifications/actions'
import { setRedirect } from '../../ui/router/actions'
import {
  setSession,
  toggleKeyboardShortcuts
} from '../../metrics_server/sessions/actions'
import styles from './StatusBar.module.scss'
import { MultiState } from '../MultiState/MultiState'
import { setError } from '../../ui/error/actions'
import ReactTooltip from 'react-tooltip'

export const StatusBar = () => {
  // Redux //
  const user = useUser()
  const hardware = useHardware()
  const version = useVersion()
  const broadcasting = useBroadcasting()
  const sportscaster = useSportscaster()
  const units = useUnits()
  const sessions = useSessions()
  const notifications = useAppSelector((state) => state.notifications)
  const openUnitSelect = useOpenUnitSelectModal()

  const { runChecksAndStartSession, sessionReadyToStart } = useStartSession()

  const dispatch = useAppDispatch()

  const [sportscasterHealth, setSportscasterHealth] = useState(
    sportableColors.colors.colorError
  )

  useEffect(() => {
    const health = getSportscasterHealth(sportscaster.status)
    setSportscasterHealth(health)
  }, [sportscaster.status, sportscaster.status])

  const getSportscasterHealth = (sportscasterStatus) => {
    const healthySportscasters = sportscasterStatus.filter((x) => {
      return x.status
    })

    if (healthySportscasters.length === 0) {
      return sportableColors.colors.colorError
    } else if (healthySportscasters.length === sportscasterStatus.length) {
      return sportableColors.colors.colorSuccess
    } else {
      return sportableColors.colors.colorWarning
    }
  }

  const openNetworkSettings = () => {
    dispatch(
      toggleModal({
        active: true,
        type: 'form',
        message: 'Network settings',
        ChildComponent: NetworkSettings,
        className: 'modalLarge',
        handleClose: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }

  const toggle3D = () => {
    const threeD = localStorage.getItem('3D')
    const isEnabled = threeD === 'enabled'
    dispatch(
      setInfo({
        header: '',
        message: `This will require the app to reload. Are you sure you want to proceed?`,
        proceed: () => {
          localStorage.setItem('3D', isEnabled ? 'disabled' : 'enabled')
          window.location.reload()
        }
      })
    )
  }

  const generateCogOptions = () => {
    const { isAdmin } = getAppTypeCheck(user)

    const options = [
      {
        name: `Unit select - ${units.selected}`,
        handle: openUnitSelect
      },
      {
        name: 'Network Settings',
        handle: openNetworkSettings
      },
      {
        name:
          localStorage.getItem('3D') === 'enabled' ? 'Disable 3D' : 'Enable 3D',
        handle: toggle3D
      }
    ]

    if (isAdmin) {
      options.push({
        name: broadcasting.autoBroadcastEnabled
          ? 'Disable broadcasting'
          : 'Enable broadcasting',
        handle: openBroadcastingConfirmationModal
      })
    }

    if (isSentrySetup()) {
      options.push({
        name: 'Report a bug 🐛',
        handle: () => {
          openSentryBugReporting(toggleKeyboardShortcuts)
        }
      })
    }
    if (user.data && user.data.id) {
      options.push({
        name: notifications.on
          ? `Turn off notifications`
          : `Turn on notifications`,
        handle: () => dispatch(toggleNotifications(!notifications.on))
      })
    }
    if (isAdmin)
      options.push({
        name: `Open Diagnostics`,
        handle: () => dispatch(setRedirect(diagnosticsPath))
      })
    return options
  }

  const openBatteryModal = (deviceType: DeviceType) => {
    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        handleProceed: () => {
          dispatch(toggleModal({}))
        },
        title: `${deviceType.name}s`,
        ChildComponent: () => (
          <HardwareGrid
            deviceTypeValue={deviceType.value}
            hardwareStates={['online', 'sleep']}
          />
        ),
        wrapper: true,
        className: 'modalSmall',
        width: '50%',
        handleSecondaryBtn: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }

  const openSportscasterModal = () => {
    const title = `Brokers: ${sportscaster.locationName}`

    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        handleProceed: () => {
          dispatch(toggleModal({}))
        },

        ChildComponent: () => {
          return <SportscasterTable type={'selectedBrokerHost'} />
        },
        wrapper: true,
        title,

        // modal props

        className: 'modalSmall',
        width: '50%',
        handleSecondaryBtn: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }

  const openBroadcastingConfirmationModal = () => {
    const handleSubmit = () => {
      dispatch(toggleAutoBroadcasting(!broadcasting.autoBroadcastEnabled))
      dispatch(toggleModal({}))
    }

    dispatch(
      setInfo({
        header: 'MQTT Broadcasting',
        message: `Are you sure you want to ${
          broadcasting.autoBroadcastEnabled ? 'disable' : 'enable'
        } automated mqtt broker data?`,
        proceed: () => handleSubmit()
      })
    )
  }

  // // Re-run the filter whenever the notifications history array changes:
  const filteredHistoricalNotifications = useMemo(() => {
    return notifications.allNotificationsHistory.filter(
      (item, index) => index < 25
    )
  }, [notifications.allNotificationsHistory])

  const { ui, server, uiType, app, deamon } = version

  const filteredNotifications = {
    ...notifications,
    allNotificationsHistory: filteredHistoricalNotifications
  }

  const numberOfAlerts = notifications.allNotificationsHistory.length

  const isTrainingMode = isTraining(
    sessions.activeSession?.type || sessions.selected.type
  )

  const appTypeCheck = getAppTypeCheck(user)
  const { isAdmin, isMatchTracker } = appTypeCheck

  const authToken = localStorage.getItem('authorization')
  const daysLeft = getTokenExpiration(authToken)

  return (
    <div className={styles.statusBarContainer}>
      <div className={styles.tooltips}>
        <AppInformationToolTipIcon
          uiType={uiType}
          app={app}
          ui={ui}
          server={server}
          deamon={deamon}
          loginExpiryDays={daysLeft}
        />
        <SessionShortcutsToolTip
          shortcuts={getShortcuts(appTypeCheck, isTrainingMode)}
          sessions={sessions}
        />
      </div>
      <div className={styles.statusBar}>
        <div>
          {isLocal && environment.isDevelopment() && (
            <MultiState
              name='Quick Start Session'
              handleClick={() => {
                let prevSession = localStorage.getItem('prevSession')
                if (!prevSession) {
                  dispatch(setError({ message: 'No previous session found' }))
                }
                prevSession = JSON.parse(prevSession)
                dispatch(setSession(prevSession, 'setup'))
                if (sessionReadyToStart) {
                  runChecksAndStartSession()
                }
              }}
            />
          )}
          {isLocal && (
            <React.Fragment>
              {(isAdmin || isMatchTracker) && (
                <>
                  <MSErrors />
                  <MSDBSyncStatus color={'white'} />
                  <MultiState
                    name='Brokers'
                    number={sportscaster.status.length}
                    color={sportscasterHealth}
                    handleClick={() => openSportscasterModal()}
                  />
                </>
              )}
              {(isAdmin || isMatchTracker) && !isTrainingMode && (
                <div
                  onClick={() => {
                    if (broadcasting.autoBroadcastEnabled !== null) {
                      openBroadcastingConfirmationModal()
                    }
                  }}
                  style={{ display: 'flex' }}
                >
                  {broadcasting.autoBroadcastEnabled !== null ? (
                    <MIcon
                      scale={0.8}
                      color={
                        broadcasting.autoBroadcastEnabled
                          ? 'white'
                          : sportableColors.colors.colorError
                      }
                    />
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        height: '20px',
                        width: '20px'
                      }}
                    >
                      <Loader color={'white'} />
                    </div>
                  )}
                </div>
              )}
              {(isAdmin || isMatchTracker) &&
              hardware.isSeatSwappingAlgoEnabled !== null ? (
                <div
                  className='border-solid border-2 pt-4 pb-4 rounded'
                  style={{
                    borderColor: hardware.isSeatSwappingAlgoEnabled
                      ? sportableColors.colors.colorSuccess
                      : sportableColors.colors.colorError
                  }}
                >
                  <h5
                    className=''
                    style={{
                      color: hardware.isSeatSwappingAlgoEnabled
                        ? sportableColors.colors.colorSuccess
                        : sportableColors.colors.colorError
                    }}
                  >
                    AutoSwap
                  </h5>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    height: '20px',
                    width: '20px'
                  }}
                >
                  <Loader color={'white'} />
                </div>
              )}
              <MultiState
                name='Anchors Online'
                number={hardware.diagnostics.status.online.anchors.length}
                handleClick={() => openBatteryModal(deviceTypes.items.anchor)}
              />
              <MultiState
                name='Tags Online'
                number={
                  <>
                    <span className={styles.onlineNumber}>
                      {hardware.diagnostics.status.online.tags.length}
                    </span>
                    ({hardware.diagnostics.status.sleep.tags.length})
                  </>
                }
                handleClick={() =>
                  openBatteryModal(deviceTypes.items.playerTag)
                }
              />
              <MultiState
                name='Balls Online'
                number={
                  <>
                    <span className={styles.onlineNumber}>
                      {hardware.diagnostics.status.online.balls.length}
                    </span>
                    ({hardware.diagnostics.status.sleep.balls.length})
                  </>
                }
                handleClick={() => openBatteryModal(deviceTypes.items.ball)}
              />
              <ReactTooltip
                id={`seatConfig-tooltip`}
                place='bottom'
                aria-haspopup='true'
              >
                <p>
                  Total:{' '}
                  {!isNaN(hardware.configuration?.total)
                    ? hardware.configuration?.total
                    : '-'}
                </p>
                <p>
                  Anchors:{' '}
                  {!isNaN(hardware.configuration?.anchor)
                    ? hardware.configuration?.anchor
                    : '-'}
                </p>
                <p>
                  Balls:{' '}
                  {!isNaN(hardware.configuration?.ball)
                    ? hardware.configuration?.ball
                    : '-'}
                </p>
                <p>
                  Player Tags:{' '}
                  {!isNaN(hardware.configuration?.playerTag)
                    ? hardware.configuration?.playerTag
                    : '-'}
                </p>
                <p>
                  Low Power:{' '}
                  {!isNaN(hardware.configuration?.lowPower)
                    ? hardware.configuration?.lowPower
                    : '-'}
                </p>
              </ReactTooltip>
              <div data-tip data-for='seatConfig-tooltip'>
                <MultiState
                  name='Seats Available'
                  number={hardware.diagnostics.availableSeats}
                  color={
                    hardware.diagnostics.availableSeats < 0
                      ? sportableColors.colors.colorError
                      : undefined
                  }
                  disableHover={true}
                />
              </div>
            </React.Fragment>
          )}
        </div>
        <div className={styles.activeIndicators}>
          <div className={styles.flexRow}>
            <NotificationsList
              notifications={filteredNotifications}
              clearAllNotifications={() =>
                dispatch(clearNotificationsHistory())
              }
              clearSelectedNotification={(notification) =>
                dispatch(clearNotificationInHistory(notification))
              }
              numberOfAlerts={numberOfAlerts}
            />
          </div>
        </div>
        <div className={styles.activeIndicators}>
          <div className={styles.flexRow}>
            <Options type='settings' options={generateCogOptions()} />
          </div>
        </div>
      </div>
    </div>
  )
}
