import React from 'react'

export interface StarIconProps {
  checked: boolean
  size?: string
  scale?: number
  outlineColor?: string
  fillColor?: string
  style?: React.CSSProperties
}

export const StarIcon: React.FC<StarIconProps> = ({
  checked,
  scale = 1,
  outlineColor = 'black',
  fillColor = '#FFC107',
  style
}) => {
  if (checked) {
    return (
      <div style={style}>
        <svg
          width={`${15 * scale}`}
          height={`${15 * scale}`}
          viewBox='0 0 15 15'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_902_13748)'>
            <path
              d='M7.5 0.75L9.75 5.25L14.25 5.8125L11.16 9.2775L12 14.25L7.5 12L3 14.25L3.8475 9.2775L0.75 5.8125L5.25 5.25L7.5 0.75Z'
              fill={fillColor}
            />
          </g>
          <defs>
            <clipPath id='clip0_902_13748'>
              <rect
                width={`${15 * scale}`}
                height={`${15 * scale}`}
                fill='white'
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    )
  }

  return (
    <div style={style}>
      <svg
        width={`${15 * scale}`}
        height={`${15 * scale}`}
        viewBox='0 0 15 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_902_13756)'>
          <path
            d='M7.5 0.75L5.25 5.25L0.75 5.8125L3.8475 9.2775L3 14.25L7.5 12L12 14.25L11.16 9.2775L14.25 5.8125L9.75 5.25L7.5 0.75ZM7.5 2.43L9.255 5.9475L12.7425 6.3825L10.3575 9.0525L11.01 12.915L7.5 11.16L3.99 12.915L4.6425 9.0525L2.2575 6.3825L5.745 5.9475L7.5 2.43Z'
            fill={outlineColor}
          />
        </g>
        <defs>
          <clipPath id='clip0_902_13756'>
            <rect
              width={`${15 * scale}`}
              height={`${15 * scale}`}
              fill='white'
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
