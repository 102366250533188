import React, { useState } from 'react'
import { AuthenticationForms } from '../form_config'

// Components
import { AuthFormWithPasswordValidation } from '../../../metrics_server/user/components/AuthFormWithPasswordValidation'
import { validatePassword } from '../../../metrics_server/user/functions'

type ConfirmPasswordFormValues = {
  password: string
  password_confirmation: string
}

export interface ConfirmPasswordFormProps {
  switchForm: (formKey: string) => void
  confirmNewPassword: (values, loadingId, cb) => void
}

export const ConfirmPasswordForm = (props: ConfirmPasswordFormProps) => {
  const { switchForm, confirmNewPassword } = props

  const [fields] = useState([
    {
      name: 'password',
      type: 'text',
      textType: 'password',
      label: 'Password'
    },
    {
      name: 'password_confirmation',
      type: 'text',
      textType: 'password',
      label: 'Confirm password'
    }
  ])

  const handleSubmit = (values: ConfirmPasswordFormValues) => {
    confirmNewPassword(values, AuthenticationForms.createPassword, () =>
      switchForm(AuthenticationForms.signin)
    )
  }

  return (
    <AuthFormWithPasswordValidation
      fields={fields}
      handleSubmit={handleSubmit}
      validate={(values) => validatePassword(values)}
    />
  )
}
