import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

export interface BasicTextFieldProps {
  label
  value
  onChange
  type?
  size?
  mb?
}

export default function BasicTextField({
  label,
  value,
  onChange,
  type,
  size,
  mb
}: BasicTextFieldProps) {
  const handleChange = (e) => {
    onChange(e.target.value)
  }

  return (
    <Box
      component='form'
      sx={{
        mb,
        width: '100%'
      }}
      noValidate
      autoComplete='off'
    >
      <TextField
        fullWidth
        onChange={handleChange}
        value={value}
        type={type}
        id='outlined-basic'
        label={label}
        variant='outlined'
        size={size}
      />
    </Box>
  )
}
