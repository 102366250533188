import { MainProps } from '../../components/Views/Main/Main.types'
import { tabConfig, useTargetSetupTabConfig } from './tab_config'
import { ViewType } from '../../components/Views/Views.types'
import { TargetsSetup } from './Container'
import { Route } from '../../App/App.types'
import { useTargetSetupViewStrackProps } from './strack'

export const path = '/targetsetup'

export const route: Route<MainProps> = {
  path,
  view: {
    type: ViewType.MAIN,
    name: 'Targets Setup',
    tabs: tabConfig,
    useTabConfig: useTargetSetupTabConfig,
    useStrackProps: useTargetSetupViewStrackProps,
    Container: TargetsSetup,
    backPath: '/session'
  }
}
