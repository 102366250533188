import React from 'react'
import { TeamLogo } from '../../../components/TeamLogo/TeamLogo'
import { OrganisationsState } from '../../../metrics_server/organisations/types'
import styles from '../styles.module.scss'

type OrganisationProps = {
  organisations: OrganisationsState
}

export function Organisation({ organisations }: OrganisationProps) {
  let organisationArray = Object.values(organisations.items)
  let organisation = organisationArray[0]

  const getWord = (word, index, array) => {
    if (index < array.length - 1) return `${word}, `
    return word
  }

  return (
    <div className={styles.settingsCard}>
      <div className={styles.row}>
        <h1 className={styles.cardTitle}>Organisation</h1>
      </div>
      {!organisation ? (
        <div className={styles.row}>
          <p>Not currently part of an organisation</p>
        </div>
      ) : (
        <React.Fragment>
          <div className={`${styles.row} ${styles.organisationHeader}`}>
            <TeamLogo logo={organisation.logo} color={'black'} size={40} />
            <h1>{organisation.name}</h1>
          </div>
          <div className={styles.row}>
            <div>
              3-word password for inviting new users:{' '}
              <h4 className={styles.row}>
                {organisation.threeWords.map(getWord)}
              </h4>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
