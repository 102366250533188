import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SuccessState } from '../../ui/success/types'
import { Button } from '../Button/Button'

import { HandleClose, HandleProceed } from '../../ui/modal/types'

import styles from './Success.module.scss'
import { SuccessIcon } from '../Icons/SuccessIcon/SuccessIcon'

interface SuccessMessageProps {
  success: SuccessState
  handleProceed?: HandleProceed
  handleClose?: HandleClose
}

export const SuccessMessage = (props: SuccessMessageProps) => {
  const { success, handleProceed, handleClose } = props
  return (
    <div className={styles.successContainer}>
      <h3 className={styles.successTitle}>Success!</h3>
      <SuccessIcon />
      <h5 className={styles.successMessage}>{success.message}</h5>
      <Button
        handleClick={handleProceed}
        className='btn--primary'
        type='button'
      >
        Ok
      </Button>
      {success.type === 'warning' && (
        <Button handleClick={handleClose} className='btn--plain' type='button'>
          Cancel
        </Button>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    success: state.success
  }
}

export default connect(mapStateToProps)(SuccessMessage)
