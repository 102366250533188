import { ViewType } from '../../components/Views/Views.types'
import { formMatrix } from './form_config'
import { FormProps } from '../../components/Views/Form/Form.types'
import { CreateOrganisationContainer } from './Container'

export const path = '/createorganisation'

export const createOrganisationRoute = {
  path: path,
  view: {
    type: ViewType.FORM,
    name: 'Form Test',
    side: 0,
    formMatrix,
    Container: CreateOrganisationContainer
  } as FormProps
}
