import {
  getFlightEventTypeGroup,
  flightEventTypesConfig
} from './flight/data_types'
import { getTimeEventTypeGroup, timeEventTypesConfig } from './time/data_types'
import { getGameEventTypeGroup, gameEventTypesConfig } from './game/data_types'
import {
  getAussieRulesEventTypeGroup,
  aussieRulesEventTypesConfig
} from './aussie_rules/data_types'
import { EventTypeKeys } from './data_types'
import { DataTypeMap } from '../data_types'

export const eventDataTypesMap: DataTypeMap<EventTypeKeys> = {
  flight: {
    getGroupType: getFlightEventTypeGroup,
    dataTypesConfig: flightEventTypesConfig
  },
  time: {
    getGroupType: getTimeEventTypeGroup,
    dataTypesConfig: timeEventTypesConfig
  },
  game: {
    getGroupType: getGameEventTypeGroup,
    dataTypesConfig: gameEventTypesConfig
  },
  aussieRules: {
    getGroupType: getAussieRulesEventTypeGroup,
    dataTypesConfig: aussieRulesEventTypesConfig
  }
}
