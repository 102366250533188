import { API_ROOT_URL } from '../../const'
import { setError } from '../../ui/error/actions'
import { setSuccess } from '../../ui/success/actions'
import { request } from '../../utils/request_handler'

export function forceSync() {
  return (dispatch) => {
    const success = (response) => {
      dispatch(setSuccess({ message: `DB synced` }))
    }
    const error = (error) => {
      dispatch(
        setError({
          message: `Failed to force db sync. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `config/forcesync`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}
