import { useAppSelector } from '../../store/hooks'
import { useMemo } from 'react'

export const useGraphics = () => {
  const graphics = useAppSelector((state) => state.graphics)

  return {
    ...graphics
  }
}

export const useAspectRatioOptions = () => {
  const { aspectRatios } = useGraphics()
  const parsedAspectRatios = useMemo(() => {
    return aspectRatios?.map((aspectRatio) => ({
      name: aspectRatio,
      value: aspectRatio
    }))
  }, [aspectRatios])
  return parsedAspectRatios
}
