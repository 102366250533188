import {
  GET_SUB_SESSIONS,
  CREATE_SUB_SESSION,
  DELETE_SUB_SESSION,
  UPDATE_SUB_SESSION
} from './types'

import { setError } from '../../ui/error/actions'
import { API_ROOT_URL } from '../api/config'
import { request } from '../../utils/request_handler'

export function getSubSessions(sessionId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: GET_SUB_SESSIONS,
        payload: { sessionId: sessionId, data: response.data }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get sub sessions. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}/sub_sessions`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

// Create new subSession
export function createSubSession(data) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: CREATE_SUB_SESSION,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to create sub session. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sub_sessions`,
      method: 'post',
      data: data,
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

// // update sub session
export function updateSubSession(sessionId, data) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: UPDATE_SUB_SESSION,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to update sub session. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sub_sessions/${sessionId}`,
      method: 'put',
      data: data,
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// // delete sub session
export function deleteSubSession(sessionId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: DELETE_SUB_SESSION,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to delete sub session. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sub_sessions/${sessionId}`,
      method: 'delete',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}
