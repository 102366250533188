import {
  // CREATE_PLAYER,
  SET_ERROR
} from './types'

import { ErrorState } from './types'

const initialState: ErrorState = {
  message: null
}

export function errorReducer(state = initialState, action) {
  if (!action.error && action.type === SET_ERROR) {
    return action.payload
  }
  switch (action.type) {
    default:
      return state
  }
}
