import { useMemo } from 'react'
import { UnitSystem } from '../units/types'
import { MetricTypeGroup } from './data_types'
import {
  getMetricsKeyOptionsWithUnits,
  getMetricsOptionsWithUnits
} from './functions'

export function useMetricKeyOptionsWithUnits(
  metricTypes: MetricTypeGroup,
  unitSystem: UnitSystem
) {
  const metricKeyOptionsWithUnits = useMemo(() => {
    return getMetricsKeyOptionsWithUnits(metricTypes, unitSystem)
  }, [metricTypes, unitSystem])
  return metricKeyOptionsWithUnits
}

export function useMetricOptionsWithUnits(
  metricTypes: MetricTypeGroup,
  unitSystem: UnitSystem
) {
  const metricOptionsWithUnits = useMemo(() => {
    return getMetricsOptionsWithUnits(metricTypes, unitSystem)
  }, [metricTypes, unitSystem])
  return metricOptionsWithUnits
}
