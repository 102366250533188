import { drawCircle, drawLine } from '../2D/drawing'
import { convertYardsToMeters } from '../strack'
import { drawCenterDashesOnXPlane } from './canadian_football'

export function applyAmericanFootballSettings(field, poles, dimensions) {
  field.height = dimensions.P24.y
  field.width = dimensions.P13.x - dimensions.P1.x
  field.tryLineDistance = dimensions.P12.x - dimensions.P2.x

  field.planes = {
    left: dimensions.P1.x,
    right: dimensions.P13.x,
    top: dimensions.P20.y,
    bottom: dimensions.P7.y
  }

  field.scaledTen = field.tryLineDistance / 10
  field.edges = 3

  // Set origin to base
  field.originOffsetX = 0
  field.originOffsetY = field.height / 2

  poles.height = 13.72
  poles.width = 5.64
  poles.crossbarHeight = 3.05
  poles.diameter = 0.2

  field.color = 'transparent'
}
export function drawAmericanFootballLines(
  props,
  color,
  lineWidth,
  ctx,
  pixelScale,
  view
) {
  const { field, dimensions } = props

  let getCanvasCoordinate = props.getCanvasCoordinate

  if (view === '3D') {
    getCanvasCoordinate = props.get3DCanvasCoordinate
  }

  // Draw mown patches

  let numberOfVerticalPatches = 20
  let numberOfHorizontalPatches = numberOfVerticalPatches * 0.7

  let verticalPatchWidth = field.tryLineDistance / numberOfVerticalPatches
  let horizontalPatchWidth = field.height / numberOfHorizontalPatches

  let patchOrigin = dimensions.P25

  for (let i = 0; i < numberOfVerticalPatches; i++) {
    if (i % 2 === 0) {
      let scaledOrigin = getCanvasCoordinate(
        pixelScale,
        patchOrigin.x + i * verticalPatchWidth,
        patchOrigin.y,
        true
      )
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fillRect(
        scaledOrigin.scaleX,
        scaledOrigin.scaleY,
        verticalPatchWidth * pixelScale,
        field.height * pixelScale
      )
    }
  }

  // for (let i = 0; i < numberOfHorizontalPatches; i++) {
  //   if (i % 2 === 0) {
  //     let scaledOrigin = getCanvasCoordinate(
  //       pixelScale,
  //       patchOrigin.x,
  //       patchOrigin.y - i * horizontalPatchWidth,
  //       true
  //     )
  //     ctx.fillStyle = 'rgba(0,0,0,0.1)'
  //     ctx.fillRect(
  //       scaledOrigin.scaleX,
  //       scaledOrigin.scaleY,
  //       field.tryLineDistance * pixelScale,
  //       horizontalPatchWidth * pixelScale
  //     )
  //   }
  // }

  // Draw Borders
  ctx.beginPath()
  ctx.setLineDash([0])
  ctx.moveTo(
    getCanvasCoordinate(pixelScale, dimensions.P1.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P1.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P26.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P26.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P14.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P14.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P13.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P13.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P1.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P1.y).scaleY
  )
  ctx.lineWidth = lineWidth
  ctx.strokeStyle = color
  ctx.stroke()

  // Draw trylines
  ctx.moveTo(
    getCanvasCoordinate(pixelScale, dimensions.P2.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P2.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P25.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P25.y).scaleY
  )
  ctx.moveTo(
    getCanvasCoordinate(pixelScale, dimensions.P12.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P12.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P15.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P15.y).scaleY
  )

  ctx.lineWidth = lineWidth
  ctx.strokeStyle = color
  ctx.stroke()

  // Ten yard lines
  const lowerTenYardPoints = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const upperTenYardPoints = [25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15]

  ctx.beginPath()

  for (var i = 0; i < lowerTenYardPoints.length; i++) {
    let lowerPlanePoint = dimensions[`P${lowerTenYardPoints[i]}`]
    let higherPlanePoint = dimensions[`P${upperTenYardPoints[i]}`]

    let lastLowerPlanePoint = dimensions[`P${lowerTenYardPoints[i - 1]}`]
    let lastHigherPlanePoint = dimensions[`P${upperTenYardPoints[i - 1]}`]
    if (i === 0) continue

    // Draw lines between 10 yard lines
    let midX = (lowerPlanePoint.x - lastLowerPlanePoint.x) / 2

    ctx.moveTo(
      getCanvasCoordinate(pixelScale, lowerPlanePoint.x - midX).scaleX,
      getCanvasCoordinate(pixelScale, null, lowerPlanePoint.y).scaleY
    )
    ctx.lineTo(
      getCanvasCoordinate(pixelScale, higherPlanePoint.x - midX).scaleX,
      getCanvasCoordinate(pixelScale, null, higherPlanePoint.y).scaleY
    )

    // Draw center dashes

    drawCenterDashesOnXPlane(
      lowerPlanePoint.x - midX,
      ctx,
      field,
      getCanvasCoordinate,
      pixelScale
    )

    // Draw one yard line markers

    let oneYardLineSpacing = (lowerPlanePoint.x - lastLowerPlanePoint.x) / 10

    for (var j = 1; j < 10; j++) {
      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, 0).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, 1).scaleY
      )

      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height / 2 - 6.1).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height / 2 - 6.1 - 1).scaleY
      )

      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height / 2 + 6.1).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height / 2 + 6.1 + 1).scaleY
      )

      ctx.moveTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height - 1).scaleY
      )
      ctx.lineTo(
        getCanvasCoordinate(
          pixelScale,
          lastLowerPlanePoint.x + oneYardLineSpacing * j
        ).scaleX,
        getCanvasCoordinate(pixelScale, null, field.height).scaleY
      )
    }

    if (i === lowerTenYardPoints.length - 1) continue

    // Draw 10 yard lines

    ctx.moveTo(
      getCanvasCoordinate(pixelScale, lowerPlanePoint.x).scaleX,
      getCanvasCoordinate(pixelScale, null, lowerPlanePoint.y).scaleY
    )
    ctx.lineTo(
      getCanvasCoordinate(pixelScale, higherPlanePoint.x).scaleX,
      getCanvasCoordinate(pixelScale, null, higherPlanePoint.y).scaleY
    )

    drawCenterDashesOnXPlane(
      lowerPlanePoint.x,
      ctx,
      field,
      getCanvasCoordinate,
      pixelScale
    )
  }

  ctx.lineWidth = lineWidth / 2
  ctx.strokeStyle = color
  ctx.stroke()

  ctx.beginPath()

  // Draw poles
  drawCircle(
    dimensions.P28.x,
    dimensions.P28.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P27.x,
    dimensions.P27.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )

  drawCircle(
    dimensions.P29.x,
    dimensions.P29.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P30.x,
    dimensions.P30.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
}

export const drawAmericanFootballPitchNumbers = (
  field,
  dimensions,
  getCanvasCoordinate,
  canvasPixelScale,
  ctx,
  fontSize
) => {
  ctx.save()

  let numberCanvasCoordPoints = [
    'P24',
    'P23',
    'P22',
    'P21',
    'P20',
    'P19',
    'P18',
    'P17',
    'P16'
  ]

  let numbers = ['1 0', '2 0', '3 0', '4 0', '5 0', '4 0', '3 0', '2 0', '1 0']

  let upperY = field.height - 8 + 0.5
  let lowerY = 8 - 0.5

  // let upperCY = field.height - 3.75
  // let lowerCY = 3.75
  ctx.font = `${fontSize * 1.5}px Clarendon`
  ctx.textAlign = 'center'
  ctx.fillStyle = 'rgba(255, 255, 255, 0.9)'
  ctx.textBaseline = 'middle'
  ctx.strokeStyle = 'rgba(255, 255, 255, 0.9)'

  const scaledUpperYCoord = getCanvasCoordinate(canvasPixelScale, null, upperY)

  const scaledLowerYCoord = getCanvasCoordinate(canvasPixelScale, null, lowerY)

  ctx.font = `${fontSize}px Clarendon`

  numberCanvasCoordPoints.forEach((point, i) => {
    const scaledCanvasCoord = getCanvasCoordinate(
      canvasPixelScale,
      dimensions[point].x,
      null
    )

    ctx.fillText(numbers[i], scaledCanvasCoord.scaleX, scaledLowerYCoord.scaleY)
    ctx.fillText(numbers[i], scaledCanvasCoord.scaleX, scaledUpperYCoord.scaleY)
  })

  ctx.restore()
}
