import { ViewType } from '../../components/Views/Views.types'
import { formMatrix } from './form_config'
import { AuthenticationContainer } from './Container'
import { FormProps } from '../../components/Views/Form/Form.types'

export const path = '/login'

export const authenticationRoute = {
  path: path,
  view: {
    type: ViewType.FORM,
    name: 'Form Test',
    side: 1,
    formMatrix,
    Container: AuthenticationContainer
  } as FormProps
}
