import React, { useState } from 'react'
import { AuthenticationForms } from '../form_config'
// Components
import { FormContainer } from '../../../components/Forms/Form/Form'
import { Button } from '../../../components/Button/Button'
import { AuthenticationState } from '../../../ui/authentication/types'

import styles from './SigninForm.module.scss'

const validate = (values) => {
  const errors = {} as any

  return errors
}

export interface SigninFormProps {
  switchForm: (formKey: string) => void
  signinUser: (values, formKey, callback) => void
  authentication: AuthenticationState
}

export const SigninForm = (props: SigninFormProps) => {
  const { authentication, switchForm, signinUser } = props

  const [fields, setFields] = useState(generateFields())

  function generateFields() {
    return [
      {
        name: 'email',
        type: 'text',
        label: 'Email address'
      },
      {
        name: 'password',
        type: 'text',
        textType: 'password',
        label: 'Password'
      }
    ]
  }

  const handleSubmit = (values) => {
    signinUser(values, AuthenticationForms.signin, () => {
      switchForm(null)
    })
  }

  const switchToSignup = () => {
    switchForm(AuthenticationForms.signup)
  }

  const switchToRequestPassword = () => {
    switchForm(AuthenticationForms.requestPassword)
  }

  return (
    <>
      <FormContainer
        fields={fields}
        validate={validate}
        validateOnBlur={true}
        onSubmit={handleSubmit}
        loading={authentication.isLoading}
        submitText={'Login'}
        secondaryButtonText={'New account'}
        secondaryButtonHandleClick={switchToSignup}
      >
        <div className={styles.forgotPasswordContainer}>
          <p className='authError'>{authentication && authentication.error}</p>
          <Button
            handleClick={switchToRequestPassword}
            type='button'
            className='link'
          >
            forgot password?
          </Button>
        </div>
      </FormContainer>
    </>
  )
}
