import React from 'react'
import { CommentatorButton } from '../CommentatorButton/CommentatorButton'
import styles from './CommentatorAddNewButton.module.scss'

export interface CommentatorAddNewButtonProps {
  title?: string
  handleClick: () => void
}

export const CommentatorAddNewButton = ({
  title = 'Add New',
  handleClick
}: CommentatorAddNewButtonProps) => {
  return (
    <div className={styles.buttonWrapper}>
      <CommentatorButton title='Add Table' handleClick={handleClick} />
    </div>
  )
}
