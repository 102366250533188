import React from 'react'
import styles from './CommentatorButton.module.scss'

export interface CommentatorButtonProps {
  disabled?: boolean
  className?: string
  handleClick?: (event) => void
  type?: string
  style?: React.CSSProperties
  id?: string
  title: string
}

export const CommentatorButton = ({
  disabled,
  className,
  handleClick,
  type,
  style,
  id,
  title
}: CommentatorButtonProps) => {
  return (
    <button
      className={styles.button}
      onClick={handleClick}
      style={style}
      id={id}
    >
      <div className={styles.buttonText}>{title}</div>
    </button>
  )
}
