import { signoutUser } from '../../metrics_server/user/actions'
import {
  AppAPIError,
  generateAPIErrorMessage
} from '../../utils/request_handler'
import { ErrorState, SetErrorAction } from './types'
import { SET_ERROR } from './types'

export type Action<T, P> = {
  type: T
  payload: P
}

export function setError(error: ErrorState): SetErrorAction {
  return {
    type: SET_ERROR,
    payload: error
  }
}

export function handleAPIError(
  error: AppAPIError,
  errorType: 'error' | 'errorDetail',
  customMessage?: string
) {
  return (dispatch) => {
    if (!error.response) return
    // If the error is a 401 (Unauthorised), signout the user //
    if (error.response.status === 401) {
      setTimeout(() => {
        dispatch(
          setError({
            message: 'User no longer authenticated please login again...',
            proceed: () => dispatch(signoutUser())
          })
        )
      }, 1000)
    } else {
      dispatch(
        setError({
          message: generateAPIErrorMessage(error, errorType, customMessage)
        })
      )
    }
  }
}
