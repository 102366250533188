import { MQTTState, MQTTActionTypes } from './types'
import { REMOVE_USER_DATA } from '../types'
import { sportscasterConfig } from '../sportscaster/data'

const { SET_BROKERS } = MQTTActionTypes

const initialState: MQTTState = {
  brokers: [],
  selectedBrokerHost: null
}

export function mqttReducer(state = initialState, action): MQTTState {
  switch (action.type) {
    case SET_BROKERS:
      return {
        ...state,
        brokers: [...state.brokers, ...action.payload.brokers],
        selectedBrokerHost: action.payload.selectedHost
      }
    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
