import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup
} from '../../../data_types'
import { EventTypeProps } from '../../data_types'
import { FlightEventFeatures } from '../data_types'

export const flightEventSubTypesConfig = {
  unclassified: {
    key: 'unclassified' as const,
    value: 0 as const,
    name: 'Unclassified',
    props: null
  },
  boxKick: {
    key: 'boxKick',
    value: 1,
    name: 'Box Kick',
    props: null
  },
  chip: {
    key: 'chip',
    value: 2,
    name: 'Chip',
    props: null
  },
  clearance: {
    key: 'clearance',
    value: 3,
    name: 'Clearance',
    props: null
  },
  crossfield: {
    key: 'crossfield',
    value: 4,
    name: 'Crossfield',
    props: null
  },
  conversion: {
    key: 'conversion',
    value: 5,
    name: 'Conversion',
    props: null
  },
  restart: {
    key: 'restart',
    value: 6,
    name: 'Restart',
    props: null
  },
  grubber: {
    key: 'grubber',
    value: 7,
    name: 'Grubber',
    props: null
  },
  dropGoal: {
    key: 'dropGoal',
    value: 8,
    name: 'Drop Goal',
    props: null
  },
  penalty: {
    key: 'penalty',
    value: 9,
    name: 'Penalty',
    props: null
  },
  bomb: {
    key: 'bomb',
    value: 10,
    name: 'Bomb',
    props: null
  },
  restart22: {
    key: 'restart22',
    value: 11,
    name: '22m Restart',
    props: null
  },
  penaltyToTouch: {
    key: 'penaltyToTouch',
    value: 12,
    name: 'Penalty To Touch',
    props: null
  },
  upAndUnder: {
    key: 'upAndUnder',
    value: 13,
    name: 'Up & Under',
    props: null
  },
  fiftyTwentyTwo: {
    key: 'fiftyTwentyTwo',
    value: 14,
    name: '50/22',
    props: null
  },
  goalLineDropOut: {
    key: 'goalLineDropOut',
    value: 15,
    name: 'Goal Line Drop Out',
    props: null
  },
  punt: {
    key: 'punt',
    value: 16,
    name: 'Punt',
    props: null
  },
  fortyTwenty: {
    key: 'fortyTwenty',
    value: 17,
    name: 'Forty Twenty',
    props: null
  },
  kickOff: {
    key: 'kickOff',
    value: 18,
    name: 'Kick Off',
    props: null
  },
  fieldGoal: {
    key: 'fieldGoal',
    value: 19,
    name: 'Field Goal',
    props: null
  },
  pat: {
    key: 'pat',
    value: 20,
    name: 'Point After Touchdown',
    props: null
  },
  onSideKick: {
    key: 'onSideKick',
    value: 21,
    name: 'On Side Kick',
    props: null
  },
  fieldKick: {
    key: 'fieldKick',
    value: 22,
    name: 'Kick',
    props: null
  },
  rebound50: {
    key: 'rebound50',
    value: 23,
    name: 'Rebound 50',
    props: null
  },
  setShot: {
    key: 'setShot',
    value: 24,
    name: 'Set Shot',
    props: null
  },
  snap: {
    key: 'snap',
    value: 25,
    name: 'Snap',
    props: null
  },
  inside50: {
    key: 'inside50',
    value: 26,
    name: 'Inside 50',
    props: null
  },
  run: {
    key: 'run',
    value: 27,
    name: 'Run',
    props: null
  },
  ground: {
    key: 'ground',
    value: 28,
    name: 'Ground',
    props: null
  },
  markPlayOn: {
    key: 'markPlayOn',
    value: 29,
    name: 'Mark Play On',
    props: null
  },
  freeKick: {
    key: 'freeKick',
    value: 30,
    name: 'Free Kick',
    props: null
  },
  quickLineout: {
    key: 'quickLineout',
    value: 256,
    name: 'Quick Lineout',
    props: null
  },
  shovel: {
    key: 'shovel',
    value: 512,
    name: 'Shovel',
    props: null
  },
  lateral: {
    key: 'lateral',
    value: 513,
    name: 'Lateral',
    props: null
  },
  forward: {
    key: 'forward',
    value: 514,
    name: 'Forward',
    props: null
  },
  longSnap: {
    key: 'longSnap',
    value: 515,
    name: 'Long Snap',
    props: null
  },
  shotgun: {
    key: 'shotgun',
    value: 516,
    name: 'Shotgun',
    props: null
  },
  underCentre: {
    key: 'underCentre',
    value: 1024,
    name: 'Under Centre',
    props: null
  },
  puntSnap: {
    key: 'puntSnap',
    value: 1025,
    name: 'Punt Snap',
    props: null
  },
  fieldGoalSnap: {
    key: 'fieldGoalSnap',
    value: 1026,
    name: 'Field Goal Snap',
    props: null
  },
  soccerAerialPass: {
    key: 'soccerAerialPass',
    value: 768,
    name: 'Aerial Pass',
    props: null
  },
  soccerFloorPass: {
    key: 'soccerFloorPass',
    value: 769,
    name: 'Floor Pass',
    props: null
  }
  // soccerFreeKick: {
  //   key: 'soccerFreeKick',
  //   value: 770,
  //   name: 'Free Kick',
  //   props: null
  // }
} as const

export type FlightEventSubTypeProps = EventTypeProps<null, FlightEventFeatures>

export type FlightEventSubTypes = DataTypes<typeof flightEventSubTypesConfig>

export type FlightEventSubTypeKey = DataTypeKey<FlightEventSubTypes>

export type FlightEventSubTypeKeys = DataTypeKeys<FlightEventSubTypeKey>

export type FlightEventSubTypeValues = DataTypeValues<FlightEventSubTypeKey>

export const getFlightEventSubTypeGroup = (items: {
  [key in FlightEventSubTypeKeys]?: FlightEventSubTypes[key]
}) => {
  return getDataTypeGroup<
    FlightEventSubTypeKeys,
    FlightEventSubTypeValues,
    FlightEventSubTypes,
    FlightEventSubTypeProps
  >(items)
}

export type FlightEventSubTypeGroup = ReturnType<
  typeof getFlightEventSubTypeGroup
>

export type FlightEventSubTypeConfig = DataTypeConfig<
  FlightEventSubTypeKeys,
  FlightEventSubTypeValues,
  FlightEventSubTypeProps
>

export const flightEventSubTypes = getFlightEventSubTypeGroup(
  flightEventSubTypesConfig
)

export type FlightEventSubType = ReturnType<
  typeof flightEventSubTypes.getTypeByValue
>
