import { SET_UNIT_SYSTEM, SET_UNIT_SYSTEMS } from './action_types'

import * as unitSystems from './unit_systems'

import { UnitsState, UnitSystemName, UnitSystemNames } from './types'

export const initialState: UnitsState = {
  selected:
    (localStorage.getItem('UNIT_SYSTEM') as UnitSystemNames) ||
    UnitSystemName.SI,
  systemOptions: [{ name: 'SI', value: UnitSystemName.SI }]
}

export function unitReducer(state = initialState, action) {
  let systemOptions = [
    {
      name: 'SI',
      value: UnitSystemName.SI
    }
  ]
  let isUnitSystemAvailable = false
  let unitSystemValues = []
  switch (action.type) {
    case SET_UNIT_SYSTEM:
      localStorage.setItem('UNIT_SYSTEM', action.payload)
      return {
        ...state,
        selected: action.payload
      }
    case SET_UNIT_SYSTEMS:
      if (action.payload) {
        unitSystemValues = action.payload
      }

      systemOptions =
        unitSystemValues.length > 0
          ? unitSystemValues.map((systemName) => {
              return {
                name: unitSystems[systemName].name,
                value: systemName
              }
            })
          : []

      systemOptions.unshift({ name: 'SI', value: UnitSystemName.SI })

      isUnitSystemAvailable =
        state.selected === 'Default' ||
        unitSystemValues.includes(state.selected) ||
        state.selected === UnitSystemName.SI

      if (!isUnitSystemAvailable) {
        localStorage.setItem('UNIT_SYSTEM', UnitSystemName.SI)
      }

      return {
        ...state,
        systemOptions: systemOptions,
        selected: isUnitSystemAvailable ? state.selected : UnitSystemName.SI
      }

    default:
      return state
  }
}
