import Moment from 'react-moment'
import React, { useEffect, useState } from 'react'
import { ProfileIcon } from '../../Icons/ProfileIcon/ProfileIcon'
import { SportableBall } from '../../Icons/SportableBall/SportableBall'
import { Options } from '../../Options/Options'
import styles from '../SessionList.module.scss'
import { useAppDispatch } from '../../../store/hooks'
import {
  getSession,
  getUploadStatus,
  setUploadStatus
} from '../../../metrics_server/sessions/actions'
import { CloudStatus } from '../../CloudStatus/CloudStatus'
import SyncIcon from '@mui/icons-material/Sync'
import {
  useFormattedSession,
  useSessionDownload,
  useSessions
} from '../../../metrics_server/sessions/hooks'
import { setRedirect } from '../../../ui/router/actions'
import * as SessionScreen from '../../../views/Session/config'
import { RawSessionData } from '../../../metrics_server/sessions/types'
import { useEditSessionFormModal } from '../../Forms/EditSessionForm/EditSessionForm'
import { RefereeIcon } from '../../Icons/RefereeIcon/RefereeIcon'
import { sportableColors } from '../../../constants/sportableColors'

export interface SessionCardProps {
  item?: RawSessionData
}

export const SessionCard: React.FC<SessionCardProps> = (props) => {
  const dispatch = useAppDispatch()
  const { item } = props
  const { selectedId } = useSessions()
  const session = useFormattedSession(item.id)
  const {
    id,
    startTime,
    endTime,
    sport,
    type,
    subType,
    mode,
    live,
    isSessionUploaded,
    homeTeam,
    awayTeam,
    players,
    balls,
    hostname,
    locationName
  } = session

  // Upload Status //
  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    if (!isSessionUploaded || isSessionUploaded) {
      setUploading(false)
    }
  }, [isSessionUploaded])
  // ==================== //

  const handleUploadStatus = (e) => {
    e.stopPropagation()
    setUploading(true)
    dispatch(setUploadStatus(session.id))
  }
  const handleRefreshStatus = (e) => {
    e.stopPropagation()
    setUploading(true)
    dispatch(getUploadStatus(session.id))
  }

  // Edit Session //
  const { openEditSessionModal } = useEditSessionFormModal(session)

  // Download Session //
  const { openDownloadModal } = useSessionDownload(session)

  return (
    <div
      id={session.id}
      onClick={() =>
        dispatch(
          getSession(session, () => dispatch(setRedirect(SessionScreen.path)))
        )
      }
      className={`${selectedId === id ? styles.selectedCard : ''} ${
        styles.cardContainer
      }`}
    >
      <div className={styles.sessionContainer}>
        <div className={styles.sessionNameContainer}>
          <div className={styles.sessionInfoLeftContainer}>
            <div className={styles.sessionType}>{type.name}</div>
            <div id='session-name'>{session.name}</div>
            <div>{sport.name}</div>
            <div>
              {mode.name} | {subType?.name}
            </div>
          </div>
          <div className={styles.sessionInfoRightContainer}>
            <div>
              {homeTeam?.name}
              {awayTeam ? ` | ${awayTeam.name}` : ''}
            </div>
          </div>
        </div>
        <div className={styles.sessionDetailsContainer}>
          <div className={styles.dateContainer}>
            <h2>
              {startTime.month.formattedShort} {startTime.day.formatted}
            </h2>
            <h2 className={styles.yearFont}>{startTime.year.formatted}</h2>
          </div>
          <div className={styles.timesContainer}>
            <div className={styles.timeComponent}>
              <label>Start time</label>
              <h4>{startTime.time.value}</h4>
            </div>
            <div className={styles.timeComponent}>
              {session.state.value !== 'Started' ? (
                <React.Fragment>
                  <label>End time</label>
                  <h4>{endTime ? endTime.time.value : 'Scheduled'}</h4>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <label>
                    End time{' '}
                    {live ? (
                      ''
                    ) : (
                      <span className={'tag'}>| (ON OTHER DEVICE)</span>
                    )}{' '}
                  </label>
                  <h4>Session active </h4>
                </React.Fragment>
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <div
                className={styles.noOfPlayers}
                data-tip
                data-for={`playersTooTip-${session.id}`}
                data-testid={`playersTooTip-${session.id}`}
              >
                <ProfileIcon color={'#0099CC'} scale={0.8} />
                {/* <ReactTooltip id={`playersTooTip-${session.id}`}>
                  <Grid container spacing={2}>
                    <Grid container item xs={6} direction='column'>
                      <h5 style={{ whiteSpace: 'nowrap' }}>
                        {team.name} &nbsp;
                      </h5>
                      {getPlayerName(0)}
                    </Grid>
                    <Grid container item xs={6} direction='column'>
                      <h5 style={{ whiteSpace: 'nowrap' }}>
                        {teamB.name} &nbsp;
                      </h5>
                      {getPlayerName(1)
                    </Grid>
                  </Grid>
                </ReactTooltip> */}
                <h4>{players.all.count}</h4>
              </div>
              <div className={styles.noOfBalls}>
                <SportableBall
                  color={sportableColors.colors.sportableGreyDark}
                  scale={0.6}
                  sport={sport}
                />
                <h4>{balls.count}</h4>
              </div>
              <div className={styles.noOfBalls}>
                {session.isOfficiatingMode ? <RefereeIcon scale={0.6} /> : ''}
              </div>
            </div>
          </div>
          <div className={styles.sessionOptionsContainer}>
            <div className={styles.hostnameContainer}>
              <h6>{`${hostname} | ${locationName}`}</h6>
            </div>
            {session.state.value === 'Finished' && (
              <>
                <div className={styles.cloudUploadStatus}>
                  {isSessionUploaded ? (
                    <CloudStatus status={'uploaded'} />
                  ) : uploading ? (
                    <CloudStatus status={'loading'} />
                  ) : (
                    <CloudStatus
                      status={'upload'}
                      onClick={(e) => handleUploadStatus(e)}
                    />
                  )}
                </div>
                <div className={styles.syncLogo}>
                  {uploading ? (
                    <CloudStatus status={'loading'} />
                  ) : (
                    <div onClick={(e) => handleRefreshStatus(e)}>
                      <SyncIcon />
                    </div>
                  )}
                </div>
              </>
            )}
            <div>
              <Options
                options={[
                  {
                    name: 'Edit',
                    handle: () => openEditSessionModal()
                  },
                  {
                    name: 'Download',
                    handle: () => {
                      openDownloadModal()
                    }
                  },
                  // {name: "Delete", handle: ()=>console.log('delete')},
                  {
                    name: 'Copy Session ID',
                    handle: () => navigator.clipboard.writeText(id)
                  }
                ]}
                type='edit'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionCard
