import { SET_SELECTED_SESSION_COLLECTION } from './action_types'
import { CompetitionsState } from './types'

const initialState: CompetitionsState = {
  items: {},
  selected: null
}

export const competitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_SESSION_COLLECTION:
      return {
        ...state,
        selected: action.payload
      }
    default:
      return state
  }
}
