import React from 'react'
import styles from './Tabs.module.scss'
import { Tab } from '../../Dashboard.types'

interface TabsProps {
  tabs: Tab[]
  selectedTabKey: string
  switchTab: (tabKey: string) => void
}

export function Tabs({ tabs, selectedTabKey, switchTab }: TabsProps) {
  const renderTabs = () => {
    return tabs.map((tab, index) => {
      return (
        <li
          key={index}
          className={styles.navText}
          onClick={() => switchTab(tab.key)}
          // redirect back to HOME / INSIGHTS
          // onClick={
          //   selectedTabKey === 'HOME'
          //     ? () => setRedirect(tab.route)
          //     : () => switchTab(tab.key)
          // }
        >
          {/* {selectedTabKey === tab.key ? (
            <SideBarScreenIndicatorIcon
              active={true}
              style={{ marginTop: '4px' }}
            />
          ) : (
            <div style={{ paddingRight: '8px' }}>
              <SideBarScreenIndicatorIcon
                active={false}
                style={{ marginTop: '4px' }}
              />
            </div>
          )} */}

          <tab.Icon
            color={selectedTabKey === tab.key ? '#3F4059' : undefined}
          />
          {/* <div style={{ width: '10px' }}></div> */}
        </li>
      )
    })
  }

  return <div className={styles.container}>{renderTabs()}</div>
}
