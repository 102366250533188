// Used in validation and to change the 'from' team of a flight

import React, { useCallback } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { SelectForm } from '../../../components/Forms/SelectForm/SelectForm'
import { updateEvent } from '../../../metrics_server/events/actions'
import { useSelectedFormattedEvent } from '../../../metrics_server/events/hooks'
import { toggleModal } from '../../../ui/modal/actions'
import { eventTypes } from '../../../metrics_server/events/data_types'
import { flightEventTypes } from '../../../metrics_server/events/flight/data_types'

export const useChangeFromTeamModal = () => {
  const dispatch = useAppDispatch()
  const formattedEvent = useSelectedFormattedEvent()

  const openChangeFromTeamModal = useCallback(() => {
    if (!formattedEvent) return
    const { id, team, eventType } = formattedEvent

    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        wrapper: true,
        title: 'Select Team',
        className: 'modalLarge',

        ChildComponent: () => (
          <SelectForm
            handleSubmit={(values) => {
              // TODO: do we need to set the teamId if we are filtering the player options by team
              // This is clunky - we should be able to just pass the player id to the updateEvent action
              const data = {
                id,
                fromPlayerId: null,
                teamID: null,
                playerId: null,
                toPlayerId: null,
                targetPlayerId: null,
                teamId: null,
                forward: null,
                passDirection: null,
                passXVelocity: null,
                carryXVelocity: null,
                probabilityForward: null
              }
              if (
                formattedEvent &&
                eventType === eventTypes.items.flight.value &&
                formattedEvent.type.selected.value !==
                  flightEventTypes.items.pass.value
              ) {
                data.teamID = values.selected
                data.fromPlayerId = null
                data.toPlayerId = null
                data.targetPlayerId = null
              } else if (
                formattedEvent &&
                eventType === eventTypes.items.flight.value &&
                formattedEvent.type.selected.value ===
                  flightEventTypes.items.pass.value &&
                values.selected !== team.selected?.id
              ) {
                data.teamID = values.selected
                data.fromPlayerId = null
                data.toPlayerId = null
                data.targetPlayerId = null

                // TODO: Forward pass changes
                // This should be handled on the backend
                data.id = formattedEvent.id
                data.forward = !formattedEvent.rawData.forward
                data.passDirection =
                  formattedEvent.rawData.passDirection === 'Right'
                    ? 'Left'
                    : 'Right'
                data.passXVelocity = -formattedEvent.rawData.passXVelocity
                data.carryXVelocity = -formattedEvent.rawData.carryXVelocity
                data.probabilityForward =
                  100 - formattedEvent.rawData.probabilityForward
              } else if (
                formattedEvent &&
                eventType === eventTypes.items.game.value
              ) {
                delete data.teamID
                data.teamId =
                  values.selected === 'none' ? null : values.selected
                data.playerId = null
              }
              dispatch(updateEvent(data))
              dispatch(toggleModal({}))
            }}
            options={team.options}
            initialValue={team?.selected ? team.selected.id : 'none'}
            // title={'Select Team'}
          />
        ),
        handleSecondaryBtn: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }, [formattedEvent])

  return {
    openChangeFromTeamModal
  }
}
