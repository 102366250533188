import * as BABYLON from '@babylonjs/core'
import { drawCircle, drawLine } from '../2D/drawing'
import * as earcut from 'earcut'

import pitchTexture from '../../../assets/img/pitch-texture.png'
import {
  PitchArc,
  PitchArcs,
  PitchCoordinates
} from '../../../metrics_server/pitches/types'
import { StrackController } from '../strack'

export const polynomialEquation = (arc: PitchArc, x) => {
  let y =
    arc.a * Math.pow(x, 3) +
    arc.b * Math.pow(x, 2) +
    arc.c * Math.pow(x, 1) +
    arc.d
  return y
}
const goalSquareLength = 9
const horizontalCenteringOffset = 1.7

export function applyAustralianRulesSettings(field, dimensions) {
  field.height = dimensions.P23.y - dimensions.P8.y
  field.width = dimensions.P32.x - dimensions.P14.x
  field.tryLineDistance = dimensions.P10.x - dimensions.P2.x
  field.edges = 3

  field.planes = {
    left: dimensions.P15.x,
    right: dimensions.P30.x,
    top: dimensions.P23.y,
    bottom: dimensions.P8.y
  }

  // Set origin offset to centralise pitch lines
  field.originOffsetX =
    (Math.abs(dimensions.P14.x) - Math.abs(dimensions.P32.x)) / 2
  field.originOffsetY =
    (Math.abs(dimensions.P23.y) - Math.abs(dimensions.P8.y)) / 2

  field.color = '#089b64'

  // Add x and y coordinates of the left and right goal squares
  field.leftGoalSquare = {
    topLeft: { x: dimensions.P16.x, y: dimensions.P16.y },
    topRight: { x: dimensions.P16.x + goalSquareLength, y: dimensions.P16.y },
    bottomRight: {
      x: dimensions.P15.x + goalSquareLength,
      y: dimensions.P15.y
    },
    bottomLeft: { x: dimensions.P15.x, y: dimensions.P15.y }
  }
  field.rightGoalSquare = {
    topLeft: { x: dimensions.P31.x - goalSquareLength, y: dimensions.P31.y },
    topRight: { x: dimensions.P31.x, y: dimensions.P31.y },
    bottomRight: { x: dimensions.P30.x, y: dimensions.P30.y },
    bottomLeft: { x: dimensions.P30.x - goalSquareLength, y: dimensions.P30.y }
  }

  // Center left pitch edge
  field.centerLeftPitchEdge = {
    x: field.leftGoalSquare.topLeft.x,
    y: (field.leftGoalSquare.topLeft.y + field.leftGoalSquare.bottomLeft.y) / 2
  }

  // Center right pitch edge
  field.centerRightPitchEdge = {
    x: field.rightGoalSquare.topRight.x,
    y:
      (field.rightGoalSquare.topRight.y + field.rightGoalSquare.bottomRight.y) /
      2
  }
}

// Draw pitch background on 2D canvas
export const drawAustralianRulesLines = (
  props: {
    arcs: PitchArcs
    dimensions: PitchCoordinates
    [key: string]: any
    twoDCanvas: any
  },
  color,
  ctx,
  pixelScale,
  canvas,
  view
) => {
  const { field, dimensions, arcs } = props

  let { canvasElementPixelRatio } = props

  let getCanvasCoordinate = (scale, x, y, fixed?) => {
    return props.getCanvasCoordinate(scale, x, y, fixed, true)
  }

  if (view === '3D') {
    getCanvasCoordinate = props.get3DCanvasCoordinate
  }
  // Outer boundary

  const outerBoundaryScale = 1.03

  let boundaryCanvasWidth = canvas.width * outerBoundaryScale
  let boundaryCanvasHeight = canvas.height * outerBoundaryScale

  ctx.save()

  let translateX = (canvas.width - boundaryCanvasWidth) / 2
  let translateY = (canvas.height - boundaryCanvasHeight) / 2

  ctx.translate(translateX, translateY)

  ctx.scale(outerBoundaryScale, outerBoundaryScale)

  const outerBoundaryCoordinates = getBoundaryCoordinates(
    dimensions,
    arcs,
    outerBoundaryScale
  )
  drawBoundaryCoordinates(
    ctx,
    pixelScale,
    getCanvasCoordinate,
    outerBoundaryCoordinates,
    'rgba(255,255,255, 0)',
    3,
    false
  )

  ctx.clip()

  ctx.fillStyle = 'rgba(0,0,0,0.1)'

  ctx.fillRect(
    0,
    0,
    canvas.width * outerBoundaryScale,
    canvas.height * outerBoundaryScale
  )

  ctx.restore()

  // Inner boundary

  const boundaryCoordinates = getBoundaryCoordinates(dimensions, arcs, 1)
  drawBoundaryCoordinates(
    ctx,
    pixelScale,
    getCanvasCoordinate,
    boundaryCoordinates,
    'rgba(255,255,255, 0.8)',
    6,
    true
  )

  // Draw mown patches

  let numberOfVerticalPatches = 10
  let numberOfHorizontalPatches = 10

  let verticalPatchWidth = boundaryCanvasWidth / numberOfVerticalPatches
  let horizontalPatchWidth = boundaryCanvasHeight / numberOfHorizontalPatches

  let scaledOrigin = {
    scaleX: 0,
    scaleY: 0
  }

  for (let i = 0; i < numberOfVerticalPatches; i++) {
    if (i % 2 === 0) {
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fillRect(
        scaledOrigin.scaleX + i * verticalPatchWidth,
        scaledOrigin.scaleY,
        verticalPatchWidth,
        boundaryCanvasHeight
      )
    }
  }

  for (let i = 0; i < numberOfHorizontalPatches; i++) {
    if (i % 2 === 0) {
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fillRect(
        scaledOrigin.scaleX,
        scaledOrigin.scaleY + i * horizontalPatchWidth,
        boundaryCanvasWidth,
        horizontalPatchWidth
      )
    }
  }

  // Draw goal squares

  // Right goal square

  const p30 = getCanvasCoordinate(
    pixelScale,
    dimensions.P30.x,
    dimensions.P30.y
  )
  const p31 = getCanvasCoordinate(
    pixelScale,
    dimensions.P31.x,
    dimensions.P31.y
  )
  const outer30 = getCanvasCoordinate(
    pixelScale,
    dimensions.P30.x - goalSquareLength,
    dimensions.P30.y
  )
  const outer31 = getCanvasCoordinate(
    pixelScale,
    dimensions.P31.x - goalSquareLength,
    dimensions.P31.y
  )

  ctx.lineWidth = 3
  ctx.lineCap = 'round'
  ctx.strokeStyle = 'rgba(255,255,255,0.8)'
  ctx.beginPath()
  ctx.moveTo(p30.scaleX, p30.scaleY)
  ctx.lineTo(outer30.scaleX, outer30.scaleY)
  ctx.lineTo(outer31.scaleX, outer31.scaleY)
  ctx.lineTo(p31.scaleX, p31.scaleY)
  ctx.closePath()
  ctx.stroke()

  // Left goal square

  const p15 = getCanvasCoordinate(
    pixelScale,
    dimensions.P15.x,
    dimensions.P15.y
  )
  const p16 = getCanvasCoordinate(
    pixelScale,
    dimensions.P16.x,
    dimensions.P16.y
  )

  const outer15 = getCanvasCoordinate(
    pixelScale,
    dimensions.P15.x + goalSquareLength,
    dimensions.P15.y
  )
  const outer16 = getCanvasCoordinate(
    pixelScale,
    dimensions.P16.x + goalSquareLength,
    dimensions.P16.y
  )

  ctx.lineWidth = 3
  ctx.lineCap = 'round'
  ctx.strokeStyle = 'rgba(255,255,255,0.8)'
  ctx.beginPath()
  ctx.moveTo(p15.scaleX, p15.scaleY)
  ctx.lineTo(outer15.scaleX, outer15.scaleY)
  ctx.lineTo(outer16.scaleX, outer16.scaleY)
  ctx.lineTo(p16.scaleX, p16.scaleY)
  ctx.closePath()
  ctx.stroke()

  // Draw center square

  const p4 = getCanvasCoordinate(pixelScale, dimensions.P4.x, dimensions.P4.y)
  const p5 = getCanvasCoordinate(pixelScale, dimensions.P5.x, dimensions.P5.y)
  const p6 = getCanvasCoordinate(pixelScale, dimensions.P6.x, dimensions.P6.y)
  const p7 = getCanvasCoordinate(pixelScale, dimensions.P7.x, dimensions.P7.y)

  ctx.lineWidth = 3
  ctx.lineCap = 'round'
  ctx.strokeStyle = 'rgba(255,255,255,0.8)'
  ctx.beginPath()
  ctx.moveTo(p4.scaleX, p4.scaleY)
  ctx.lineTo(p5.scaleX, p5.scaleY)
  ctx.lineTo(p6.scaleX, p6.scaleY)
  ctx.lineTo(p7.scaleX, p7.scaleY)
  ctx.closePath()
  ctx.stroke()

  // Draw center circles

  drawCircle(
    dimensions.P1.x,
    dimensions.P1.y,
    ctx,
    (dimensions.P1.x - dimensions.P3.x) * pixelScale,
    'rgba(255,255,255,0.8)',
    null,
    3,
    getCanvasCoordinate,
    pixelScale,
    null
  )

  drawCircle(
    dimensions.P1.x,
    dimensions.P1.y,
    ctx,
    (dimensions.P1.x - dimensions.P2.x) * pixelScale,
    'rgba(255,255,255,0.8)',
    null,
    3,
    getCanvasCoordinate,
    pixelScale,
    null
  )

  // Draw 50 meter lines

  // Left 50 meter circle

  const textRotationFactor = 3.67
  const textArcAngle = Math.PI / 4.45

  let leftCenterCoords = {
    x: (dimensions.P16.x + dimensions.P15.x) / 2,
    y: dimensions.P16.y + dimensions.P15.y
  }

  const leftCenter = getCanvasCoordinate(
    pixelScale,
    leftCenterCoords.x,
    leftCenterCoords.y
  )
  ctx.beginPath()
  ctx.lineWidth = 3
  ctx.arc(
    leftCenter.scaleX,
    leftCenter.scaleY,
    50 * pixelScale,
    -Math.PI / 5,
    Math.PI / 5,
    false
  )
  ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)'
  ctx.stroke()

  ctx.beginPath()
  ctx.lineWidth = 3
  ctx.arc(
    leftCenter.scaleX,
    leftCenter.scaleY,
    50 * pixelScale,
    (4 * Math.PI) / 5,
    (6 * Math.PI) / 5,
    false
  )
  ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)'
  ctx.stroke()

  ctx.beginPath()
  ctx.lineWidth = 3
  ctx.arc(
    leftCenter.scaleX,
    leftCenter.scaleY,
    50 * pixelScale,
    (1 * Math.PI) / 4,
    (3 * Math.PI) / 4,
    false
  )
  ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)'
  ctx.stroke()

  ctx.beginPath()
  ctx.lineWidth = 3
  ctx.arc(
    leftCenter.scaleX,
    leftCenter.scaleY,
    50 * pixelScale,
    (5 * Math.PI) / 4,
    (7 * Math.PI) / 4,
    false
  )
  ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)'
  ctx.stroke()

  // Text

  const upperLeft50Coords = {
    x: leftCenterCoords.x + 50 * Math.cos(textArcAngle),
    y: leftCenterCoords.y + 50 * Math.sin(textArcAngle)
  }

  const upperLeft50 = getCanvasCoordinate(
    pixelScale,
    upperLeft50Coords.x,
    upperLeft50Coords.y
  )

  ctx.font = '25px Arial'
  ctx.fillStyle = 'rgba(255, 0, 0, 0.6)'
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'

  ctx.save()
  ctx.translate(upperLeft50.scaleX, upperLeft50.scaleY)
  ctx.rotate(Math.PI / textRotationFactor)
  ctx.fillText('50', 0, 0)
  ctx.restore()

  const lowerLeft50Coords = {
    x: leftCenterCoords.x + 50 * Math.cos(-textArcAngle),
    y: leftCenterCoords.y + 50 * Math.sin(-textArcAngle)
  }

  const lowerLeft50 = getCanvasCoordinate(
    pixelScale,
    lowerLeft50Coords.x,
    lowerLeft50Coords.y
  )

  ctx.save()
  ctx.translate(lowerLeft50.scaleX, lowerLeft50.scaleY)
  ctx.rotate(Math.PI + -Math.PI / textRotationFactor)
  ctx.fillText('50', 0, 0)
  ctx.restore()

  // Right 50 meter circle

  let rightCenterCoords = {
    x: (dimensions.P30.x + dimensions.P31.x) / 2,
    y: dimensions.P30.y + dimensions.P31.y
  }

  const rightCenter = getCanvasCoordinate(
    pixelScale,
    rightCenterCoords.x,
    rightCenterCoords.y
  )
  ctx.beginPath()
  ctx.lineWidth = 3
  ctx.arc(
    rightCenter.scaleX,
    rightCenter.scaleY,
    50 * pixelScale,
    (4 * Math.PI) / 5,
    (6 * Math.PI) / 5,
    false
  )
  ctx.strokeStyle = 'rgba(0, 0, 255, 0.5)'
  ctx.stroke()

  ctx.beginPath()
  ctx.lineWidth = 3
  ctx.arc(
    rightCenter.scaleX,
    rightCenter.scaleY,
    50 * pixelScale,
    (-1 * Math.PI) / 5,
    (1 * Math.PI) / 5,
    false
  )
  ctx.strokeStyle = 'rgba(0, 0, 255, 0.5)'
  ctx.stroke()

  ctx.beginPath()
  ctx.lineWidth = 3
  ctx.arc(
    rightCenter.scaleX,
    rightCenter.scaleY,
    50 * pixelScale,
    (1 * Math.PI) / 4,
    (3 * Math.PI) / 4,
    false
  )
  ctx.strokeStyle = 'rgba(0, 0, 255, 0.5)'
  ctx.stroke()

  ctx.beginPath()
  ctx.lineWidth = 3
  ctx.arc(
    rightCenter.scaleX,
    rightCenter.scaleY,
    50 * pixelScale,
    (5 * Math.PI) / 4,
    (7 * Math.PI) / 4,
    false
  )
  ctx.strokeStyle = 'rgba(0, 0, 255, 0.5)'
  ctx.stroke()

  // Right 50 text

  const upperRight50Coords = {
    x: rightCenterCoords.x - 50 * Math.cos(textArcAngle),
    y: rightCenterCoords.y + 50 * Math.sin(textArcAngle)
  }

  const upperRight50 = getCanvasCoordinate(
    pixelScale,
    upperRight50Coords.x,
    upperRight50Coords.y
  )

  ctx.fillStyle = 'rgba(0, 0, 255, 0.6)'

  ctx.save()
  ctx.translate(upperRight50.scaleX, upperRight50.scaleY)
  ctx.rotate(-Math.PI / textRotationFactor)
  ctx.fillText('50', 0, 0)
  ctx.restore()

  const lowerRight50Coords = {
    x: rightCenterCoords.x - 50 * Math.cos(-textArcAngle),
    y: rightCenterCoords.y + 50 * Math.sin(-textArcAngle)
  }

  const lowerRight50 = getCanvasCoordinate(
    pixelScale,
    lowerRight50Coords.x,
    lowerRight50Coords.y
  )

  ctx.save()
  ctx.translate(lowerRight50.scaleX, lowerRight50.scaleY)
  ctx.rotate(-(Math.PI + -Math.PI / textRotationFactor))
  ctx.fillText('50', 0, 0)
  ctx.restore()

  // drawCircle(
  //   (dimensions.P16.x + dimensions.P15.x) / 2,
  //   dimensions.P16.y + dimensions.P15.y,
  //   ctx,
  //   50 * pixelScale,
  //   'rgba(255, 0, 0, 0.5)',
  //   null,
  //   3,
  //   getCanvasCoordinate,
  //   pixelScale,
  //   null
  // )

  // drawCircle(
  //   (dimensions.P30.x + dimensions.P31.x) / 2,
  //   dimensions.P30.y + dimensions.P31.y,
  //   ctx,
  //   50 * pixelScale,
  //   'rgba(0, 0, 255, 0.5)',
  //   null,
  //   3,
  //   getCanvasCoordinate,
  //   pixelScale,
  //   null
  // )

  drawBoundaryCoordinates(
    ctx,
    pixelScale,
    getCanvasCoordinate,
    boundaryCoordinates,
    'rgba(255,255,255, 1)',
    6,
    true
  )

  // drawBoundary(
  //   ctx,
  //   pixelScale,
  //   dimensions,
  //   arcs,
  //   getCanvasCoordinate,
  //   'rgba(255,255,255, 1)',
  //   6,
  //   true
  // )

  // Draw goal squares

  // Draw interchange gates

  // 23 top point

  // Draw dashes

  // Draw left gate

  // Draw right gate
}

function drawBoundary(
  ctx,
  pixelScale,
  dimensions,
  arcs,
  getCanvasCoordinate,
  color,
  lineWidth,
  clip
) {
  ctx.lineWidth = lineWidth
  // ctx.lineCap = 'round'
  ctx.strokeStyle = color

  let startCoord = getCanvasCoordinate(
    pixelScale,
    dimensions.P17.x,
    dimensions.P17.y
  )

  ctx.beginPath()

  ctx.moveTo(startCoord.scaleX, startCoord.scaleY)

  for (let i = 0; i < 12; i++) {
    let arc = arcs[i]

    let startPoint = dimensions[arc.start]
    let endPoint = dimensions[arc.end]

    let dx = Math.abs(endPoint.x - startPoint.x)
    let interval = Math.abs(dx / 100)

    let arcStart = getCanvasCoordinate(pixelScale, startPoint.x, startPoint.y)

    ctx.lineTo(arcStart.scaleX, arcStart.scaleY)

    for (let x = 0; x <= dx; x = x + interval) {
      // Draw arc

      let y = polynomialEquation(arc, x)

      //  Draw kick trajectory
      let coord = getCanvasCoordinate(pixelScale, x + startPoint.x, y)

      ctx.lineTo(coord.scaleX, coord.scaleY)
    }
  }

  const p29 = getCanvasCoordinate(
    pixelScale,
    dimensions.P29.x,
    dimensions.P29.y
  )
  const p30 = getCanvasCoordinate(
    pixelScale,
    dimensions.P30.x,
    dimensions.P30.y
  )
  const p31 = getCanvasCoordinate(
    pixelScale,
    dimensions.P31.x,
    dimensions.P31.y
  )
  const p32 = getCanvasCoordinate(
    pixelScale,
    dimensions.P32.x,
    dimensions.P32.y
  )

  ctx.lineTo(p29.scaleX, p29.scaleY)
  ctx.lineTo(p30.scaleX, p30.scaleY)
  ctx.lineTo(p31.scaleX, p31.scaleY)
  ctx.lineTo(p32.scaleX, p32.scaleY)

  // Lower arcs

  for (let i = arcs.length - 1; i >= 12; i--) {
    let arc = arcs[i]

    let startPoint = dimensions[arc.start]
    let endPoint = dimensions[arc.end]

    let dx = Math.abs(endPoint.x - startPoint.x)
    let interval = -(dx / 100)

    let arcStart = getCanvasCoordinate(pixelScale, startPoint.x, startPoint.y)

    ctx.lineTo(arcStart.scaleX, arcStart.scaleY)

    for (let x = dx; x >= 0; x = x + interval) {
      // Draw arc

      let y = polynomialEquation(arc, x)

      //  Draw kick trajectory
      let coord = getCanvasCoordinate(pixelScale, x + startPoint.x - dx, y)

      ctx.lineTo(coord.scaleX, coord.scaleY)
    }
  }

  const p14 = getCanvasCoordinate(
    pixelScale,
    dimensions.P14.x,
    dimensions.P14.y
  )
  const p15 = getCanvasCoordinate(
    pixelScale,
    dimensions.P15.x,
    dimensions.P15.y
  )
  const p16 = getCanvasCoordinate(
    pixelScale,
    dimensions.P16.x,
    dimensions.P16.y
  )
  const p17 = getCanvasCoordinate(
    pixelScale,
    dimensions.P17.x,
    dimensions.P17.y
  )

  ctx.lineTo(p14.scaleX, p14.scaleY)
  ctx.lineTo(p15.scaleX, p15.scaleY)
  ctx.lineTo(p16.scaleX, p16.scaleY)
  ctx.lineTo(p17.scaleX, p17.scaleY)
  ctx.closePath()
  if (clip) ctx.clip()
  ctx.stroke()
}

function getBoundaryCoordinates(dimensions, arcs, scale) {
  const boundaryCoordinates = []

  // Upper arcs
  for (let i = 0; i < 12; i++) {
    let arc = arcs[i]

    let startPoint = dimensions[arc.start]
    let endPoint = dimensions[arc.end]

    let dx = Math.abs(endPoint.x - startPoint.x)
    let interval = Math.abs(dx / 100)

    let arcStart = {
      x: startPoint.x * scale,
      y: startPoint.y * scale
    }

    boundaryCoordinates.push(arcStart)

    for (let x = 0; x <= dx; x = x + interval) {
      let y = polynomialEquation(arc, x)

      let coord = {
        x: (x + startPoint.x) * scale,
        y: y * scale
      }

      boundaryCoordinates.push(coord)
    }
  }

  // Add P29, P30, P31, and P32
  boundaryCoordinates.push({
    x: dimensions.P29.x * scale,
    y: dimensions.P29.y * scale
  })
  boundaryCoordinates.push({
    x: dimensions.P30.x * scale,
    y: dimensions.P30.y * scale
  })
  boundaryCoordinates.push({
    x: dimensions.P31.x * scale,
    y: dimensions.P31.y * scale
  })
  boundaryCoordinates.push({
    x: dimensions.P32.x * scale,
    y: dimensions.P32.y * scale
  })

  // Lower arcs
  for (let i = arcs.length - 1; i >= 12; i--) {
    let arc = arcs[i]

    let startPoint = dimensions[arc.start]
    let endPoint = dimensions[arc.end]

    let dx = Math.abs(endPoint.x - startPoint.x)
    let interval = -(dx / 100)

    let arcStart = {
      x: startPoint.x * scale,
      y: startPoint.y * scale
    }

    boundaryCoordinates.push(arcStart)

    for (let x = dx; x >= 0; x = x + interval) {
      let y = polynomialEquation(arc, x)

      let coord = {
        x: (x + startPoint.x - dx) * scale,
        y: y * scale
      }

      boundaryCoordinates.push(coord)
    }
  }

  // Add P14, P15, P16, and P17
  boundaryCoordinates.push({
    x: dimensions.P14.x * scale,
    y: dimensions.P14.y * scale
  })
  boundaryCoordinates.push({
    x: dimensions.P15.x * scale,
    y: dimensions.P15.y * scale
  })
  boundaryCoordinates.push({
    x: dimensions.P16.x * scale,
    y: dimensions.P16.y * scale
  })
  boundaryCoordinates.push({
    x: dimensions.P17.x * scale,
    y: dimensions.P17.y * scale
  })

  return boundaryCoordinates
}

function drawBoundaryCoordinates(
  ctx,
  pixelScale,
  getCanvasCoordinate,
  coordinates,
  color,
  lineWidth,
  clip
) {
  ctx.lineWidth = lineWidth
  // ctx.lineCap = 'round'
  ctx.strokeStyle = color

  ctx.beginPath()

  const startCoord = getCanvasCoordinate(
    pixelScale,
    coordinates[0].x,
    coordinates[0].y
  )
  ctx.moveTo(startCoord.scaleX, startCoord.scaleY)

  for (let i = 1; i < coordinates.length; i++) {
    const coord = getCanvasCoordinate(
      pixelScale,
      coordinates[i].x,
      coordinates[i].y
    )
    ctx.lineTo(coord.scaleX, coord.scaleY)
  }

  if (clip) ctx.clip()

  ctx.stroke()
}

export function createAustralianRulesGround({
  dimensions,
  arcs,
  fieldHeight,
  textureCanvasWidth,
  textureCanvasHeight,
  threeDOffsetY,
  threeDScale,
  scene,
  materials,
  threeDMeshScale
}) {
  const boundaryCoordinates = getBoundaryCoordinates(
    dimensions,
    arcs,
    threeDMeshScale
  )

  // Define the points of the polynomial function that creates the outline of the pitch
  const babylonBoundaryPoints = boundaryCoordinates.map((coord) => {
    return new BABYLON.Vector2(coord.x, coord.y)
  })

  // Use the PolygonMeshBuilder class to create a mesh with the outline
  var path = []

  // console.log(path)
  // console.log(babylonBoundaryPoints)
  // const height = 10
  // for (var i = 0; i < babylonBoundaryPoints.length; i++) {
  //   path.push(babylonBoundaryPoints[i].clone().add(new BABYLON.Vector3(0, 0, height)));
  // }
  // path.reverse();
  // path.push(babylonBoundaryPoints[0].clone().add(new BABYLON.Vector3(0, 0, height)));

  console.log(earcut.default ? earcut.default : earcut)

  var mesh = new BABYLON.PolygonMeshBuilder(
    'ground',
    babylonBoundaryPoints,
    scene,
    earcut.default ? earcut.default : earcut
  )

  const polygon = mesh.build(false, 4) //updatable or not, depth
  polygon.position.y = 0

  // Create a new StandardMaterial
  polygon.material = materials.ground

  // scene.addMesh(mesh);

  // var mesh = BABYLON.MeshBuilder.ExtrudePolygon("pitchOutline", { shape: babylonBoundaryPoints, depth: height, path: path }, scene, earcut);
}
