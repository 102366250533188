// Used in sportscaster tab to test sportscaster endpoints

import React, { useCallback, useMemo } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { EndpointTester } from '../../../components/EndpointTester/EndpointTester'
import {
  getSportscasterBaseURL,
  getSportscasterRouteOptions
} from '../../../metrics_server/sportscaster/functions'
import {
  useSelectedSportscaster,
  useSportscaster
} from '../../../metrics_server/sportscaster/hooks'
import { toggleModal } from '../../../ui/modal/actions'

export const useEndpointTestModal = () => {
  // Redux //
  const sportscaster = useSportscaster()
  const dispatch = useAppDispatch()
  // ====== //

  const selectedSportscaster = useSelectedSportscaster()

  const baseURL = useMemo(() => {
    return getSportscasterBaseURL(selectedSportscaster)
  }, [selectedSportscaster])

  const openEndpointTestModal = useCallback(() => {
    let routeOptions = getSportscasterRouteOptions(sportscaster)

    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        ChildComponent: () => (
          <EndpointTester host={baseURL} routeOptions={routeOptions} />
        ),
        message: 'Are you sure?',
        className: 'modalSmall',
        wrapper: true,
        handleClose: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }, [baseURL])

  return {
    openEndpointTestModal
  }
}
