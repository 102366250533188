import React, { useEffect, useState } from 'react'
import { useQaStateFormLogic } from './QaStateForm.logic'
import { qaStateNames } from '../../const'
import styles from './QaStateForm.module.scss'
import { Button } from '../Button/Button'
import { Table } from '../Table/Table'

export interface QaStateFormProps {
  item: any
  reports: any
  handleProceed: (qaState, item) => void
  handleClose: (any) => void
}

export const QaStateForm: React.FC<QaStateFormProps> = ({
  item,
  reports,
  handleProceed,
  handleClose
}) => {
  const [qaState, setQaState] = useState(reports)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    let tableDataState = Object.keys(qaState).map((key) => ({
      key,
      ...qaState[key]
    }))
    tableDataState = tableDataState.map((x) => {
      x.name = qaStateNames[x.key]
      x.formattedUpdatedAt = new Date(x.updatedAt).toLocaleString()
      return x
    })
    setTableData(tableDataState)
  }, [qaState])

  const updateQaState = (item, value, headerKey) => {
    let newState = {
      ...qaState,
      [item.key]: {
        ...item,
        [headerKey]: value
      }
    }
    setQaState(newState)
  }

  let tableHeaders = [
    { key: 'name', name: 'Name', width: 15 },
    {
      key: 'passed',
      name: 'Passed',
      input: {
        type: 'checkbox',
        onChange: (item, value) => updateQaState(item, value, 'passed')
      },
      width: 5
    },
    { key: 'formattedUpdatedAt', name: 'Updated at', width: 20 },
    { key: 'updatedBy', name: 'Updated by', width: 20 },
    {
      key: 'note',
      name: 'Note',
      width: 40,
      type: 'text',
      input: {
        type: 'text',
        onChange: (item, value) => updateQaState(item, value, 'note')
      }
    }
  ]

  return (
    <div>
      <h3>Update QA State</h3>
      <div className={styles.tableContainer}>
        <Table
          data={tableData}
          headers={tableHeaders}
          // controls
          controls={[]}
          headerFont={13}
          options={{
            initialOrder: 'asc',
            initialSortBy: 'id',
            sortActive: true
          }}
          tableClass={'minimalistBlack'}
          className={'small-container'}
          id={'table'}
        />
      </div>

      <div
        style={{ marginTop: '30px', justifyContent: 'right', display: 'flex' }}
      >
        <Button
          type={'submit'}
          className={'btn--primary'}
          handleClick={() => handleProceed(qaState, item)}
        >
          <h5>Save</h5>
        </Button>
        <div style={{ marginLeft: '10px' }} />
        <Button className={'button'} handleClick={handleClose}>
          <h5>Cancel</h5>
        </Button>
      </div>
    </div>
  )
}
