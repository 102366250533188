import React, { LegacyRef, RefObject } from 'react'
import { useHover } from '../../../utils/hooks/useHover'

export interface EditPencilProps {
  handleClick?: () => void
  scale?: number
  id?: string
  className?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  pencilColor?: string
}

export const EditPencilIcon = ({
  handleClick,
  scale = 1,
  id,
  className,
  backgroundColor = '#42ADE2',
  hoverBackgroundColor = '#2E8AB4',
  pencilColor = 'white'
}: EditPencilProps) => {
  const [hoverRef, isHovered] = useHover<any>()

  return (
    <div id={id} onClick={handleClick} ref={hoverRef}>
      <svg
        width={`${55 * scale}`}
        height={`${55 * scale}`}
        viewBox='0 0 55 55'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          cx='27.5'
          cy='27.5'
          r='27.5'
          fill={isHovered ? hoverBackgroundColor : backgroundColor}
        />
        <g clipPath='url(#clip0_1208_13881)'>
          <path
            d='M37.7297 9L33.5642 13.1655L41.8345 21.4358L46 17.2703L37.7297 9ZM32.1807 14.549L13.6182 33.1115L21.8885 41.3818L40.451 22.8193L32.1807 14.549ZM12.3564 34.6166L10 45L20.3834 42.6436L12.3564 34.6166Z'
            fill={pencilColor}
          />
        </g>
        <defs>
          <clipPath id='clip0_1208_13881'>
            <rect
              width='35'
              height='35'
              fill={pencilColor}
              transform='translate(10 10)'
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
