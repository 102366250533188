// Used in validation and overview to select which metrics you want to see in the event tables

import React, { useCallback } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { toggleModal } from '../../ui/modal/actions'
import { BroadcastIntegrationErrors } from './types'

// Hook for opening player form in a modal
export const useBroadcastIntegrationErrorModal = () => {
  const dispatch = useAppDispatch()

  const openBroadcastIntegrationErrorModal = useCallback(
    (errors: BroadcastIntegrationErrors) => {
      const ChildComponent = () => {
        if (!errors) return null
        return errors.map((error) => {
          return <div>{error}</div>
        })
      }

      // Open modal with above ChildComponent
      dispatch(
        toggleModal({
          active: true,
          type: 'confirm',
          wrapper: true,
          handleProceed: () => {
            dispatch(toggleModal({}))
          },
          ChildComponent,
          className: 'modalSmall',
          handleSecondaryBtn: () => {
            dispatch(toggleModal({}))
          }
        })
      )
    },
    [dispatch]
  )

  return {
    openBroadcastIntegrationErrorModal
  }
}
