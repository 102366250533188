import React from 'react'

export interface SportableIconProps {
  scale?: number
  width?: number
  height?: number
  color?: string
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  style?: React.CSSProperties
}

export const SportableIcon = ({
  color = '#3F4059',
  width,
  height,
  style
}: SportableIconProps) => {
  return (
    <svg
      width={width ? width : '100%'}
      height={height ? height : '100%'}
      viewBox='0 0 47 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M31.016 14.1345C31.016 17.5031 29.9932 20.446 28.4718 22.484C26.9506 24.5215 25.0627 25.519 23.2141 25.519C21.3654 25.519 19.4775 24.5215 17.9564 22.484C16.435 20.446 15.4121 17.5031 15.4121 14.1345C15.4121 10.7659 16.435 7.82295 17.9564 5.78501C19.4775 3.74745 21.3654 2.75 23.2141 2.75C25.0627 2.75 26.9506 3.74745 28.4718 5.78501C29.9932 7.82295 31.016 10.7659 31.016 14.1345Z'
        stroke={color}
        strokeWidth='5.5'
      />
      <path d='M33.7656 13.7062H46.428' stroke={color} strokeWidth='3' />
      <path d='M0 13.7062H12.6623' stroke={color} strokeWidth='3' />
    </svg>
  )
}
