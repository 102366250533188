import { StatusActionTypes } from './types'

import { setError } from '../../ui/error/actions'
import { request } from '../../utils/request_handler'
import { API_ROOT_URL } from '../../const'

const { SET_DBSYNC_STATUS } = StatusActionTypes

// Get DB sync status
export const getDBSyncStatus = () => {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: SET_DBSYNC_STATUS,
        payload: response.data
      })
    }
    const error = (error, errorType) => {
      // dispatch(
      //     setError({
      //         message: error.response && error.response.data && error.response.data[errorType] ? error.response.data[errorType] : `Failed to get MS status. API request failed. Check log for more detail.`
      //     })
      // )
    }
    const options = {
      url: `config/dbsync/status`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}
