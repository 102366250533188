export const subscribeToFlightTopics = (
  client,
  unitSystem,
  locationName,
  handleMessage
) => {
  // Subscribe to mqtt topic
  if (client) {
    console.log(
      'subscribing: ',
      `v1/${locationName}/sportscaster_flights/${unitSystem.key}`
    )
    client.subscribe(
      `v1/${locationName}/sportscaster_flights/${unitSystem.key}`,
      (data, topic) => {
        handleMessage(data)
      }
    )
  }
}

export const unsubscribeFromFlightTopics = (
  client,
  unitSystem,
  locationName
) => {
  if (client) {
    client.unsubscribe(
      `v1/${locationName}/sportscaster_flights/${unitSystem.key}`
    )
  }
}

export const subscribeToEventTopics = (
  client,
  unitSystem,
  locationName,
  handleMessage
) => {
  // Subscribe to mqtt topic
  if (client) {
    client.subscribe(
      `v1/${locationName}/events/all/${unitSystem.key}`,
      (data, topic) => {
        handleMessage(data)
      }
    )
  }
}

export const unsubscribeFromEventTopics = (
  client,
  unitSystem,
  locationName
) => {
  if (client) {
    client.unsubscribe(`v1/${locationName}/events/all/${unitSystem.key}`)
  }
}

export const subscribeToDrillEventTopics = (
  client,
  drillId,
  locationName,
  handleMessage
) => {
  // Subscribe to mqtt topic
  if (client) {
    client.subscribe(
      `v1/${locationName}/drill/${drillId}/events`,
      (data, topic) => {
        console.log(data, '==============drill======================')
        handleMessage(data)
      }
    )
  }
}

// unsubscribe
export const unsubscribeFromDrillEventTopics = (
  client,
  drillId,
  locationName
) => {
  if (client) {
    client.unsubscribe(`v1/${locationName}/drill/${drillId}/events`)
  }
}
