import React from 'react'
import { Team } from '../../../metrics_server/teams/types'
import TeamFormGeneric from '..//../../components/Forms/TeamForm/TeamForm'

export interface TeamFormProps {
  team?: Team
  newTeam: boolean
  handleProceed: (values: any) => void
  handleSecondaryBtn?: () => void
}

const TeamForm = (props: TeamFormProps) => {
  const handleSubmit = (values) => {
    const updatedTeam = { ...props.team, ...values }
    updatedTeam.logo = values.logo || props.team.logo
    props.handleProceed(updatedTeam)
  }

  return (
    <TeamFormGeneric
      team={props.team}
      newTeam={props.newTeam}
      handleProceed={handleSubmit}
      handleSecondaryBtn={props.handleSecondaryBtn}
    />
  )
}

export default TeamForm
