import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import InfoMessage from './InfoMessage'

import { InfoState, SetInfo } from '../../ui/info/types'
import { ToggleModal, ModalState } from '../../ui/modal/types'

import * as modalActions from '../../ui/modal/actions'
import * as infoActions from '../../ui/info/actions'

const actions = _.assign({}, modalActions, infoActions)

interface StateProps {
  info: InfoState
}

interface DispatchProps {
  toggleModal: ToggleModal
  setInfo: SetInfo
}

export class Info extends Component<StateProps & DispatchProps> {
  componentDidUpdate() {
    const { info, setInfo, toggleModal } = this.props
    const { message, proceed } = info
    if (message) {
      const modalObject: ModalState = {
        active: true,
        handleProceed: () => {
          setInfo({})
          toggleModal({})
          if (proceed) proceed()
        },
        ChildComponent: InfoMessage,
        className: 'modalSmall'
      }
      if (proceed) {
        modalObject.handleClose = () => {
          this.props.toggleModal({})
        }
      }
      this.props.toggleModal(modalObject)
    }
  }

  render() {
    return <noscript />
  }
}

function mapStateToProps(state) {
  return {
    info: state.info
  }
}

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  actions
)(Info)
