// Events Filter //

import { useEffect, useMemo, useRef } from 'react'
import {
  AussieRulesEventTypeKeys,
  AussieRulesEventTypeValues
} from './aussie_rules/data_types'
import { EventTypeKeys, EventTypeValues } from './data_types'
import { FlightEventTypeKeys, FlightEventTypeValues } from './flight/data_types'
import { filterFlights } from './flight/functions'
import { useEvents } from './hooks'
import { useSelectedFormattedSession } from '../sessions/hooks'
import { filterGameEvents } from './game/functions'
import { filterAussieRulesEvents } from './aussie_rules/functions'
import { filterTimeEvents } from './time/functions'
import { TimeEventTypeKeys } from './time/data_types'
import { GameEventTypeKeys, GameEventTypeValues } from './game/data_types'
import { FlightEventSubTypeValues } from './flight/subTypes/data_types'
import { Filter, FilterMultiple, useFilterReducer } from '../../hooks/filter'

export interface EventFilters {
  type: Filter<EventTypeValues | 'All'>
  highlighted: Filter<boolean>
  ignored: Filter<boolean>
  sessionId: Filter<string>
}

export type EventInitialValues = {
  [key in keyof EventFilters]?: EventFilters[key]['value']
}

// Flights //

export interface FlightFilters {
  team: Filter<string>
  player: FilterMultiple<string>
  type: Filter<FlightEventTypeValues | 'All'>
  kickTypes: FilterMultiple<FlightEventSubTypeValues | 'All'>
  madeTouch: Filter<boolean>
  passTypes: FilterMultiple<FlightEventSubTypeValues | 'All'>
  forward?: Filter<'forward' | 'normal' | 'All'>
}

export type FlightInitialValues = {
  [key in keyof FlightFilters]?: FlightFilters[key]['value']
}

// ======== //

// Time //
export interface TimeEventFilters {
  stoppages: Filter<boolean>
}

export type TimeEventInitialValues = {
  [key in keyof TimeEventFilters]?: TimeEventFilters[key]['value']
}
// ===== //

// Game //
export interface GameEventFilters {
  possession: Filter<boolean>
  team: Filter<string>
  type: Filter<GameEventTypeValues | 'All'>
}

export type GameEventInitialValues = {
  [key in keyof GameEventFilters]?: GameEventFilters[key]['value']
}
// ===== //

// Aussie Rules //
export interface AussieRulesEventFilters {
  team: Filter<string>
  type: Filter<AussieRulesEventTypeValues | 'All'>
}

export type AussieRulesEventInitialValues = {
  [key in keyof AussieRulesEventFilters]?: AussieRulesEventFilters[key]['value']
}
// =============//

type InitialValues = {
  [key in EventTypeKeys]?: key extends 'flight'
    ? FlightInitialValues
    : key extends 'time'
    ? TimeEventInitialValues
    : key extends 'game'
    ? GameEventInitialValues
    : key extends 'aussieRules'
    ? AussieRulesEventInitialValues
    : never
}

export type AllEventTypeKeys =
  | EventTypeKeys
  | FlightEventTypeKeys
  | TimeEventTypeKeys
  | GameEventTypeKeys
  | AussieRulesEventTypeKeys

const arraysContainSameElements = (a, b) => {
  if (a.length !== b.length) return false
  const sortedA = [...a].sort()
  const sortedB = [...b].sort()
  return sortedA.every((val, index) => val === sortedB[index])
}

export type EventsFilters = ReturnType<typeof useEventsFilters>

export function useEventsFilters(
  initialValues: InitialValues,
  visibleEventTypeKeys: AllEventTypeKeys[]
) {
  const events = useEvents()

  // Handle changes to visible event types to avoid unnecessary rereders //
  const prevStoredVisibleEventTypeKeys = useRef<AllEventTypeKeys[]>([])

  const storedVisibleEventTypeKeys = useMemo(() => {
    if (
      !arraysContainSameElements(
        visibleEventTypeKeys,
        prevStoredVisibleEventTypeKeys.current
      )
    ) {
      prevStoredVisibleEventTypeKeys.current = visibleEventTypeKeys
      return visibleEventTypeKeys
    }

    return prevStoredVisibleEventTypeKeys.current
  }, [visibleEventTypeKeys])

  // Session //
  const formattedSession = useSelectedFormattedSession()
  const { eventTypes } = formattedSession
  // ===== //

  // Determine the latest event
  const latestEvent = useMemo(() => {
    const eventIds = Object.keys(events.rawData)
    if (eventIds.length > 0) {
      const lastEventId = eventIds[eventIds.length - 1]
      return events.rawData[lastEventId]
    }
    return null
  }, [events.rawData])

  const newEvents = useMemo(() => {
    return Object.values(events.rawData)
    // New array of events comes in - one or more are new //
    // Check to see if any of the new events are relevant to the filter by comparing with the visibleEventTypeKeys //
    // If so return the new events //
    // Needs thought and work in the event filter refactor //
    // return Object.values(events.rawData).filter((event) => {
    //   const eventRef = eventsRef.current[event.id]
    //   const isNewEvent = eventRef !== event
    //   if (isNewEvent) {
    //     console.log('isNewEvent')
    //     eventsRef.current[event.id] = event
    //     return true
    //   }
    //   return false
    // })
  }, [events.rawData])

  const initialEventFilterValues = useMemo(() => {
    return {
      sessionId: formattedSession.id
    }
  }, [formattedSession])

  const eventsFilter = useEventFilter(
    newEvents,
    initialEventFilterValues,
    storedVisibleEventTypeKeys
  )

  const flightsFilter = useFlightsFilter(eventsFilter.filteredEvents)

  const gameEventsFilter = useGameEventsFilter(
    eventsFilter.filteredEvents,
    initialValues.game,
    storedVisibleEventTypeKeys
  )

  const timeEventsFilter = useTimeEventsFilter(eventsFilter.filteredEvents)

  const aussieRulesEventsFilter = useAussieRulesEventsFilter(
    eventsFilter.filteredEvents
  )

  const filteredEvents = useMemo(() => {
    if (eventTypes.isType('flight', eventsFilter.filters.type.value)) {
      return flightsFilter.filteredEvents
    } else if (eventTypes.isType('game', eventsFilter.filters.type.value)) {
      return gameEventsFilter.filteredEvents
    } else if (eventTypes.isType('time', eventsFilter.filters.type.value)) {
      return timeEventsFilter.filteredEvents
    } else if (
      eventTypes.isType('aussieRules', eventsFilter.filters.type.value)
    ) {
      return aussieRulesEventsFilter.filteredEvents
    } else {
      return eventsFilter.filteredEvents
    }
  }, [
    eventsFilter,
    flightsFilter,
    gameEventsFilter,
    timeEventsFilter,
    aussieRulesEventsFilter
  ])

  return useMemo(() => {
    return {
      eventsFilter,
      flightsFilter,
      gameEventsFilter,
      timeEventsFilter,
      aussieRulesEventsFilter,
      filteredEvents,
      latestEvent
    }
  }, [filteredEvents])
}

export type EventsFilter = ReturnType<typeof useEventsFilters>

function getEventTypesFromAllEventTypeKeys(
  eventTypes,
  visibleEventTypeKeys
): EventTypeKeys[] {
  const eventTypeKeys = new Set<EventTypeKeys>()
  visibleEventTypeKeys.forEach((eventTypeKey) => {
    const eventType = eventTypes.items[eventTypeKey]
    if (eventType) return eventTypeKeys.add(eventTypeKey)
    for (const key in eventTypes.items) {
      const eventType = eventTypes.items[key as EventTypeKeys]
      if (eventType.props.types?.items[eventTypeKey])
        return eventTypeKeys.add(eventType.key)
    }
  })
  return Array.from(eventTypeKeys)
}

export function useEventFilter(
  eventsArray,
  initialValues: EventInitialValues,
  visibleEventTypeKeys: AllEventTypeKeys[]
) {
  // Session //
  const { eventTypes } = useSelectedFormattedSession()
  // ===== //

  const eventTypeInitialValues = getEventTypesFromAllEventTypeKeys(
    eventTypes,
    visibleEventTypeKeys
  )

  const initialEventFilterState: EventFilters = {
    type: {
      options: eventTypes.getOptionsByKeys(eventTypeInitialValues),
      value: 'All'
    },
    sessionId: {
      value: initialValues.sessionId,
      options: null
    },
    ignored: {
      options: [
        {
          name: 'True',
          value: true
        },
        {
          name: 'False',
          value: false
        }
      ],
      value: true
    },
    highlighted: {
      options: [
        {
          name: 'True',
          value: true
        },
        {
          name: 'False',
          value: false
        }
      ],
      value: false
    }
  }

  const { filters, updateFilterValue } = useFilterReducer<EventFilters>(
    initialEventFilterState
  )

  // Filter //
  const filteredEvents = useMemo(() => {
    let updatedEventsArray
    if (filters.type.value !== 'All') {
      updatedEventsArray = eventsArray.filter((event) => {
        if (event.sessionId && event.sessionId !== filters.sessionId.value)
          return false
        if (event.event?.sessionId !== filters.sessionId.value) return false
        if (!filters.ignored.value && event.ignore) return false
        if (filters.highlighted.value && !event.operatorNotes?.highlight)
          return false
        if (event.event && event.event.type === filters.type.value) {
          if (filters.type.value !== 'All') {
            const eventType = eventTypes.getTypeByValue(filters.type.value)
            if (!eventType) return false
            if (!eventTypeInitialValues.includes(eventType.key)) return false
            return true
          }
        }
        return false
      })
    } else {
      updatedEventsArray = eventsArray.filter((event) => {
        if (event.sessionId && event.sessionId !== filters.sessionId.value)
          return false
        if (event.event?.sessionId !== filters.sessionId.value) return false
        if (!filters.ignored.value && event.ignore) return false
        if (filters.highlighted.value && !event.operatorNotes?.highlight)
          return false

        if (event.event) {
          const eventType = eventTypes.getTypeByValue(event.event.type)
          if (!eventType) return false
          const eventTypeValue = event.type
          if (visibleEventTypeKeys.includes(eventType.key)) return true
          if (
            eventTypes.items.flight &&
            eventType.value === eventTypes.items.flight.value
          ) {
            const eventSubType =
              eventType.props.types?.getTypeByValue(eventTypeValue)
            if (!eventSubType) return false
            if (!visibleEventTypeKeys.includes(eventSubType.key)) return false
          } else if (
            eventTypes.items.time &&
            eventType.value === eventTypes.items.time.value
          ) {
            const eventSubType =
              eventType.props.types?.getTypeByValue(eventTypeValue)
            if (!eventSubType) return false
            if (!visibleEventTypeKeys.includes(eventSubType.key)) return false
          } else if (
            eventTypes.items.game &&
            eventType.value === eventTypes.items.game.value
          ) {
            const eventSubType =
              eventType.props.types?.getTypeByValue(eventTypeValue)
            if (!eventSubType) return false
            if (!visibleEventTypeKeys.includes(eventSubType.key)) return false
          } else if (
            eventTypes.items.aussieRules &&
            eventType.value === eventTypes.items.aussieRules.value
          ) {
            const eventSubType =
              eventType.props.types?.getTypeByValue(eventTypeValue)
            if (!eventSubType) return false
            if (!visibleEventTypeKeys.includes(eventSubType.key)) return false
          }
        }
        return true
      })
    }
    return updatedEventsArray
  }, [eventsArray, filters, visibleEventTypeKeys])

  return useMemo(() => {
    return {
      filters,
      updateFilterValue,
      filteredEvents
    }
  }, [filters, updateFilterValue, filteredEvents])
}

// Flight Filter //

export function useFlightsFilter(eventsArray) {
  // Session //
  const { flightTypes, teams, players, sport } = useSelectedFormattedSession()
  // ====== //

  const flightTypeOptions = flightTypes?.optionsWithAll
  const kickTypeOptions = flightTypes?.items.kick?.props.types.options || []
  const passTypeOptions = flightTypes?.items.pass?.props.types.options || []
  const passMetricTypes = flightTypes?.items.pass?.props.metricTypes

  const initialFlightFilterState: FlightFilters = {
    type: {
      options: flightTypeOptions,
      value: 'All'
    },
    kickTypes: {
      options: kickTypeOptions,
      value: []
    },
    passTypes: {
      options: passTypeOptions,
      value: []
    },
    team: {
      options: teams.optionsWithAll,
      value: 'All'
    },
    player: {
      options: players.all.optionsWithAll,
      value: []
    },
    madeTouch: {
      options: [
        { value: false, name: 'All' },
        { value: true, name: 'Yes' }
      ],
      value: false
    }
  }

  if (passMetricTypes?.items.forward)
    initialFlightFilterState.forward = {
      options: [
        { value: 'All', name: 'All' },
        { value: 'forward', name: 'Forward' },
        { value: 'normal', name: 'Normal' }
      ],
      value: 'All'
    }

  const { filters, updateFilterValue, updateFilterOptions } =
    useFilterReducer<FlightFilters>(initialFlightFilterState)

  // Update Player Options on Team Change //
  useEffect(() => {
    const teamId = filters.team.value === 'All' ? 'all' : filters.team.value
    const filteredPlayerOptions = players[teamId].optionsWithAll
    updateFilterOptions('player', filteredPlayerOptions)
  }, [filters.team])
  // ============== //

  // Filter //
  const filteredEvents = useMemo(() => {
    return filterFlights(eventsArray, filters, sport)
  }, [eventsArray, filters])

  return useMemo(() => {
    return { filters, updateFilterValue, filteredEvents }
  }, [filters, updateFilterValue, filteredEvents])
}
// ========== //

// Game Event Filter //

export function useGameEventsFilter(
  eventsArray,
  initialValues: GameEventInitialValues,
  visibleEventTypeKeys: AllEventTypeKeys[]
) {
  // Store //

  const { gameEventTypes, teams } = useSelectedFormattedSession()

  const gameEventTypeInitialValues = visibleEventTypeKeys as GameEventTypeKeys[]

  const initialGameEventFilterState: GameEventFilters = {
    type: {
      options: gameEventTypes.getOptionsByKeys(gameEventTypeInitialValues),
      value: 'All'
    },
    possession: {
      options: [
        {
          name: 'True',
          value: true
        },
        {
          name: 'False',
          value: false
        }
      ],
      value: false
    },
    team: {
      options: teams.optionsWithAll,
      value: 'All'
    }
  }

  const { filters, updateFilterValue } = useFilterReducer<GameEventFilters>(
    initialGameEventFilterState
  )

  const filteredEvents = useMemo(() => {
    return filterGameEvents(
      eventsArray,
      filters,
      visibleEventTypeKeys,
      gameEventTypes
    )
  }, [eventsArray, filters])

  return useMemo(() => {
    return {
      filters,
      updateFilterValue,
      filteredEvents
    }
  }, [filters, updateFilterValue, filteredEvents])
}

// ============== //

// Aussie Rules Filter //

export function useAussieRulesEventsFilter(eventsArray) {
  // Store //

  const formattedSession = useSelectedFormattedSession()
  const { australianRulesEventTypes, teams } = formattedSession

  const initialAussieRulesEventFilterState: AussieRulesEventFilters = {
    type: {
      options: australianRulesEventTypes?.optionsWithAll,
      value: 'All'
    },
    team: {
      options: teams.optionsWithAll,
      value: 'All'
    }
  }

  const { filters, updateFilterValue } =
    useFilterReducer<AussieRulesEventFilters>(
      initialAussieRulesEventFilterState
    )

  const filteredEvents = useMemo(() => {
    return filterAussieRulesEvents(eventsArray, filters, formattedSession)
  }, [eventsArray, filters, formattedSession])

  return useMemo(() => {
    return {
      filters,
      updateFilterValue,
      filteredEvents
    }
  }, [filters, updateFilterValue, filteredEvents])
}

export function useTimeEventsFilter(eventsArray) {
  const formattedSession = useSelectedFormattedSession()

  const initialTimeEventFilterState: TimeEventFilters = {
    stoppages: {
      options: [
        {
          name: 'True',
          value: true
        },
        {
          name: 'False',
          value: false
        }
      ],
      value: false
    }
  }

  const { filters, updateFilterValue } = useFilterReducer<TimeEventFilters>(
    initialTimeEventFilterState
  )

  const filteredEvents = useMemo(() => {
    return filterTimeEvents(eventsArray, filters, formattedSession)
  }, [eventsArray, filters, formattedSession])

  return useMemo(() => {
    return {
      filters,
      updateFilterValue,
      filteredEvents
    }
  }, [filters, updateFilterValue, filteredEvents])
}
