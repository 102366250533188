import { SportscasterActionTypes, SportscasterState } from './types'
import { sportscasterConfig, sportscasterRouteOptions } from './data'
import { REMOVE_USER_DATA } from '../types'

const {
  GET_SPORTSCASTER_CONFIG,
  SET_SPORTSCASTER_HEALTH,
  SET_LOCATION_NAME,
  GET_SPORTSCASTER_FLIGHTS,
  CLEAR_SPORTSCASTER,
  SET_SPORTSCASTER_EVENT,
  ADD_SPORTSCASTER_FLIGHT,
  SET_SPORTSCASTER_STATUS,
  SET_SELECTED_SPORTSCASTER,
  SET_SELECTED_BROKER,
  SET_CONNECTION_ESTABLISHED,
  SET_SPORTSCASTER_LOCATION_NAMES
} = SportscasterActionTypes

const initialState: SportscasterState = {
  fetched: false,
  selectedSportscasterHost: null,
  selectedBrokerHost: null,
  connectionsEstablished: [],
  locationNames: [],
  locationName: '',
  brokers: [],
  status: [],
  config: [],
  health: {},
  data: {
    payload: null
  },
  events: [],
  selectedEventId: null,
  sessionId: null,
  routeOptions: sportscasterRouteOptions
}

export function sportscasterReducer(
  state = initialState,
  action
): SportscasterState {
  switch (action.type) {
    case GET_SPORTSCASTER_CONFIG:
      return {
        ...state,
        config: action.payload.data,
        selectedSportscasterHost: sportscasterConfig.WSSHost,
        selectedBrokerHost: sportscasterConfig.WSSHost
      }
    case SET_SPORTSCASTER_LOCATION_NAMES:
      return {
        ...state,
        locationNames: action.payload,
        locationName: action.payload[0]
      }
    case SET_LOCATION_NAME:
      let newStatus = state.status.map((s) => {
        s.locationName = action.payload
        return s
      })
      return {
        ...state,
        locationName: action.payload,
        status: newStatus
      }
    case SET_SELECTED_SPORTSCASTER:
      return {
        ...state,
        selectedSportscasterHost: action.payload
      }
    case SET_SELECTED_BROKER:
      return {
        ...state,
        selectedBrokerHost: action.payload
      }
    case SET_SPORTSCASTER_HEALTH:
      return {
        ...state,
        health: {
          ...state.health,
          [action.payload.host]: {
            ...state.health[action.payload.host],
            ...action.payload
          }
        }
      }
    case SET_SPORTSCASTER_STATUS:
      return {
        ...state,
        status: action.payload,
        locationName: action.payload[0]?.locationName
      }

    case GET_SPORTSCASTER_FLIGHTS:
      return {
        ...state,
        data: action.payload.data,
        fetched: true
      }

    case CLEAR_SPORTSCASTER:
      return {
        ...state,
        data: { payload: null },
        events: [],
        selectedEventId: null,
        fetched: false
      }

    case SET_SPORTSCASTER_EVENT:
      return {
        ...state,
        selectedEventId: action.payload
      }

    case ADD_SPORTSCASTER_FLIGHT:
      return {
        ...state,
        events: [action.payload, ...state.events]
      }

    case SET_CONNECTION_ESTABLISHED: {
      return {
        ...state,
        connectionsEstablished: [
          ...state.connectionsEstablished,
          action.payload
        ]
      }
    }

    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
