import React, { useState, useEffect } from 'react'

export const TabContentWrapper = (props) => {
  const { updateActiveTab, active, selectedTabKey, children } = props

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (isMounted) {
      if (active) {
        updateActiveTab(selectedTabKey)
      }
    } else {
      setIsMounted(true)
    }
  }, [selectedTabKey])

  const renderChildren = (props) => {
    return React.cloneElement(children, {
      ...props
    })
  }

  return renderChildren(props)
}
