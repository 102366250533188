import { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useSelectedFormattedSession } from '../sessions/hooks'
import { formatDrill, formatPlayerBreakdown } from './functions'
import { useUnitsSystem } from '../units/hooks'
import { Drill, FormattedPlayerBreakdowns } from './types'
import { getEmptyGroup } from '../sessions/functions'
import { addItemToGroup } from '../types'

// TODO add return type to useDrills
export const useDrills = () => {
  const drills = useAppSelector((state) => state.drills)

  let drillOptions = drills.drills
    .filter((drill) => !drill.startTime)
    .map((drill) => ({
      value: drill.id,
      name: drill.name || 'Unnamed Drill'
    }))

  drillOptions = [{ name: 'None', value: '' }, ...drillOptions]

  const updateElapsedTimeOfSelectedDrill = (
    selectedDrill,
    setSelectedDrillTime
  ) => {
    let end
    if (selectedDrill.timeEnd) {
      end = selectedDrill.timeEnd
    } else {
      end = new Date().getTime() / 1000
    }
    const elapsedTime = end - selectedDrill.startTime
    setSelectedDrillTime(elapsedTime)
  }

  return {
    drills,
    drillsWithColorId: drills.drills,
    drillOptions,
    updateElapsedTimeOfSelectedDrill
  }
}

export const useDrillsGroup = () => {
  const { drills } = useDrills()

  return useMemo(() => {
    const drillGroup = getEmptyGroup<Drill>()

    drills.drills.forEach((drill) => {
      addItemToGroup(drillGroup, drill, drill.id, 'id', drill.name)
    })

    return drillGroup
  }, [drills.drills])
}

export const useSelectedFormattedDrill = () => {
  const {
    drills: { selectedDrill }
  } = useDrills()
  return useFormattedDrill(selectedDrill?.id)
}

export const useActiveFormattedDrill = () => {
  const { drills } = useDrills()
  const activeDrill = drills.drills.find(
    (drill) => drill.startTime && !drill.timeEnd
  )
  return useFormattedDrill(activeDrill?.id)
}

export const useFormattedDrills = () => {
  const formattedSession = useSelectedFormattedSession()
  const drillGroup = useDrillsGroup()

  return useMemo(() => {
    return drillGroup.list.map((drill) => {
      return formatDrill(drill, formattedSession)
    })
  }, [drillGroup, formattedSession])
}

export const useFormattedDrill = (drillId: string) => {
  const formattedSession = useSelectedFormattedSession()
  const drillGroup = useDrillsGroup()
  const drill = drillGroup.map[drillId]

  const selectedDrill = useMemo(() => {
    return formatDrill(drill, formattedSession)
  }, [drill, formattedSession])

  return selectedDrill
}

export const usePlayerBreakdown = () => {
  const {
    drills: { selectedDrillId, playerBreakdowns }
  } = useDrills()

  const formattedSession = useSelectedFormattedSession()

  const unitSystem = useUnitsSystem(formattedSession.sport)

  const formattedPlayerBreakdowns = useMemo<FormattedPlayerBreakdowns>(() => {
    if (playerBreakdowns[selectedDrillId]) {
      return formatPlayerBreakdown(
        playerBreakdowns[selectedDrillId],
        formattedSession,
        unitSystem
      )
    }
    return {
      players: getEmptyGroup(),
      totals: null
    }
  }, [playerBreakdowns, formattedSession, selectedDrillId, unitSystem])

  return formattedPlayerBreakdowns
}
