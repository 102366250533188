import React, { Component } from 'react'
import { connect } from 'react-redux'

import { DocumentsContainer } from '../../components/Documents/DocumentsContainer'

import { formConfig } from './form_config'

// API types
import { UserState } from '../../metrics_server/user/types'
import { RouterState } from '../../ui/router/types'
import { AuthenticationState, AuthError } from '../../ui/authentication/types'

import * as userActions from '../../metrics_server/user/actions'
import * as authenticationActions from '../../ui/authentication/actions'

const actions = {
  ...userActions,
  ...authenticationActions
}

type LoginData = {
  email: string
  password: string
}

type Loading = {
  componentId: string
  type: string
}

type Callback = (success?: Callback) => void

export interface AuthenticationContainerProps {
  // TODO: move actions to typescript
  // ===== * Actions * ======
  setForgotPassword: (value: boolean) => void
  signinUser: (data: LoginData, loading: Loading, callback: Callback) => void
  signupUser: (data: LoginData, loading: Loading, callback: Callback) => void
  requestEmailVerification: (
    data: LoginData,
    loading: Loading,
    callback: Callback
  ) => void
  requestNewPassword: (
    data: LoginData,
    loadingId: string,
    callback: Callback
  ) => void
  confirmNewPassword: (
    data: LoginData,
    loadingId: string,
    callback: Callback
  ) => void
  setUrlSearchParams: (params) => void

  toggleModal: (modal: any) => void

  router: RouterState

  authentication: AuthenticationState
  authenticationError: AuthError
  user: UserState
}

export interface AuthenticationContainerState {
  initialFormKey: string
  componentId: string
  loadingContainers: {
    signinFormButton: string
    signupFormButton: string
    verifyEmailButton: string
    createPasswordButton: string
    requestPassword: string
  }
}

export const AuthenticationContainer: any = (ChildComponent, props) => {
  class ComposedComponent extends Component<
    AuthenticationContainerProps,
    AuthenticationContainerState
  > {
    constructor(props) {
      super(props)

      let initialFormKey = formConfig.signin.key

      if (props.authentication.forgotPassword)
        initialFormKey = formConfig.requestPassword.key

      if (props.router.urlSearchParams?.['verify']) {
        initialFormKey = formConfig.createPassword.key
      }

      if (props.router.urlSearchParams?.['forgot_password']) {
        initialFormKey = formConfig.confirmNewPassword.key
      }

      this.state = {
        initialFormKey,
        componentId: 'authentication',
        loadingContainers: {
          signinFormButton: 'signin',
          signupFormButton: 'signup',
          verifyEmailButton: 'verify',
          createPasswordButton: 'password',
          requestPassword: 'requestPassword'
        }
      }

      // function
      this.signinUser = this.signinUser.bind(this)
      this.requestEmailVerification = this.requestEmailVerification.bind(this)
      this.signupUser = this.signupUser.bind(this)
    }

    componentWillUnmount() {
      this.props.setForgotPassword(false)
      this.props.setUrlSearchParams({})
    }

    /*============= Form ============*/

    onSwitchForm = (nextFormKey, prevFormKey) => {
      this.props.authenticationError('')
    }

    /* ============ Modals ==========*/

    openDocument = (docType) => {
      this.props.toggleModal({
        width: '60%',
        active: true,
        type: 'confirm',
        wrapper: true,
        ChildComponent: DocumentsContainer,
        docType,
        className: 'modalSmall',
        title:
          docType === 'terms'
            ? 'Sportable Terms and Conditions (T&C)'
            : 'SPORTABLE TECHNOLOGIES PRIVACY POLICY',
        handleClose: () => {
          this.props.toggleModal({})
        }
      })
    }

    /*=========== Authentication component action handlers ============= */

    signinUser(values, loadingId, cb) {
      this.props.signinUser(values, loadingId, () => {
        cb()
      })
    }

    requestEmailVerification(value, loadingId, cb) {
      this.props.requestEmailVerification(value, loadingId, () => {
        cb()
      })
    }

    signupUser(values, loadingId, cb) {
      const { urlSearchParams } = this.props.router
      values.token = urlSearchParams.verify
      console.log(values, 'values')
      this.props.signupUser(values, loadingId, () => {
        cb()
      })
    }

    requestNewPassword = (values, loadingId, cb) => {
      this.props.requestNewPassword(values, loadingId, (success) => {
        cb(success)
      })
    }

    confirmNewPassword = (values, loadingId, cb) => {
      const { urlSearchParams } = this.props.router
      values.token = urlSearchParams.forgot_password
      this.props.confirmNewPassword(values, loadingId, () => {
        cb()
      })
    }

    render() {
      const { componentId, loadingContainers } = this.state
      const { urlSearchParams } = this.props.router
      const { user, authentication } = this.props

      return (
        <ChildComponent
          {...props}
          {...this.props}
          // Form actions
          initialFormKey={this.state.initialFormKey}
          onSwitchForm={this.onSwitchForm}
          urlSearchParams={urlSearchParams}
          user={user}
          signinUser={this.signinUser}
          signupUser={this.signupUser}
          requestEmailVerification={this.requestEmailVerification}
          requestNewPassword={this.requestNewPassword}
          confirmNewPassword={this.confirmNewPassword}
          openDocument={this.openDocument}
          setUrlSearchParams={this.props.setUrlSearchParams}
          authentication={authentication}
          authenticationError={this.props.authenticationError}
          loadingContainers={loadingContainers}
          componentId={componentId}
        />
      )
    }
  }

  function mapStateToProps(state) {
    return {
      authentication: state.authentication,
      user: state.user
    }
  }

  return connect(mapStateToProps, actions)(ComposedComponent)
}
