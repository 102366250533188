import React from 'react'
import styles from './Tabs.module.scss'
import { sportableColors } from '../../../../../const'
import { Tab } from '../../Main.types'

interface TabsProps {
  tabs: Tab[]
  selectedTabKey: string
  switchTab: (tabKey: string) => void
}

export const Tabs = ({ tabs, selectedTabKey, switchTab }: TabsProps) => {
  return (
    <div className={styles.container}>
      {tabs.map(({ key, name, Icon }) => (
        <div
          key={key}
          id={`tab-${key}`}
          className={`${styles.tab} ${
            selectedTabKey === key && styles.selectedTab
          }`}
          onClick={() => switchTab(key)}
        >
          {Icon && (
            <div className={styles.icon}>
              <Icon
                color={
                  selectedTabKey === key
                    ? sportableColors.colors.sportableBlue
                    : undefined
                }
                scale={0.7}
              />
            </div>
          )}
          <p className={`${selectedTabKey === key ? styles.underline : ''}`}>
            {name}
          </p>
        </div>
      ))}
    </div>
  )
}
