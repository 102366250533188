import React from 'react'
import { sportableColors } from '../../../constants/sportableColors'

export interface SportscasterIconProps {
  scale?: number
  color?: string
}

export const SportscasterIcon: React.FC<SportscasterIconProps> = ({
  scale = 1,
  color = sportableColors.colors.sportableGreyDark
}) => {
  return (
    <svg
      width={35 * scale}
      height={27 * scale}
      viewBox='0 0 35 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3333 11.25C15.9797 11.25 15.6405 11.408 15.3905 11.6893C15.1404 11.9706 15 12.3522 15 12.75C15 13.1478 15.1404 13.5294 15.3905 13.8107C15.6405 14.092 15.9797 14.25 16.3333 14.25H17.6666C18.3739 14.25 19.0521 14.5661 19.5522 15.1287C20.0523 15.6913 20.3333 16.4544 20.3333 17.25C20.3333 18.0457 20.0523 18.8087 19.5522 19.3713C19.0521 19.9339 18.3739 20.25 17.6666 20.25H16.3333C15.7818 20.2499 15.2438 20.0576 14.7935 19.6993C14.3431 19.3411 14.0026 18.8347 13.8186 18.2498C13.7635 18.0633 13.7756 17.8599 13.8523 17.6833C13.9291 17.5068 14.0644 17.3712 14.2291 17.3057C14.3939 17.2403 14.5749 17.2502 14.7332 17.3333C14.8915 17.4164 15.0144 17.5662 15.0756 17.7503C15.1677 18.0427 15.3381 18.2959 15.5633 18.4749C15.7885 18.6539 16.0575 18.75 16.3333 18.75H17.6666C18.0202 18.75 18.3594 18.592 18.6094 18.3107C18.8595 18.0294 19 17.6478 19 17.25C19 16.8522 18.8595 16.4706 18.6094 16.1893C18.3594 15.908 18.0202 15.75 17.6666 15.75H16.3333C15.626 15.75 14.9478 15.4339 14.4477 14.8713C13.9476 14.3087 13.6666 13.5457 13.6666 12.75C13.6666 11.9544 13.9476 11.1913 14.4477 10.6287C14.9478 10.0661 15.626 9.75 16.3333 9.75H17.6666C18.2182 9.75006 18.7561 9.94244 19.2064 10.3007C19.6568 10.6589 19.9974 11.1653 20.1813 11.7503C20.2365 11.9367 20.2244 12.1401 20.1476 12.3167C20.0708 12.4932 19.9355 12.6288 19.7708 12.6943C19.606 12.7597 19.4251 12.7498 19.2668 12.6667C19.1085 12.5836 18.9855 12.4338 18.9243 12.2498C18.8322 11.9573 18.6619 11.7041 18.4366 11.5251C18.2114 11.3461 17.9424 11.25 17.6666 11.25H16.3333Z'
        fill={color}
      />
      <path
        d='M17 2.78298C15.0336 2.78291 13.1426 3.60954 11.7193 5.09139C10.296 6.57324 9.44957 8.5966 9.35562 10.7418C9.3403 11.0998 9.19919 11.4377 8.96163 11.685C8.72407 11.9324 8.40835 12.0702 8.08014 12.0698H7.64647C6.29336 12.0698 4.99566 12.6568 4.03887 13.7018C3.08207 14.7468 2.54455 16.1641 2.54455 17.6419C2.54455 19.1197 3.08207 20.537 4.03887 21.5819C4.99566 22.6269 6.29336 23.214 7.64647 23.214H26.3535C27.7066 23.214 29.0043 22.6269 29.9611 21.5819C30.9179 20.537 31.4555 19.1197 31.4555 17.6419C31.4555 16.1641 30.9179 14.7468 29.9611 13.7018C29.0043 12.6568 27.7066 12.0698 26.3535 12.0698H25.9182C25.5902 12.0697 25.275 11.9317 25.0378 11.6844C24.8006 11.4371 24.6597 11.0995 24.6444 10.7418C24.5504 8.5966 23.704 6.57324 22.2807 5.09139C20.8574 3.60954 18.9664 2.78291 17 2.78298ZM6.9322 9.32089C7.32798 6.71682 8.55714 4.35067 10.4008 2.64377C12.2445 0.936862 14.583 0 17 0C19.417 0 21.7555 0.936862 23.5992 2.64377C25.4429 4.35067 26.672 6.71682 27.0678 9.32089C29.0257 9.52317 30.8372 10.5409 32.1264 12.1629C33.4156 13.785 34.0835 15.8868 33.9916 18.0324C33.8998 20.1779 33.0551 22.2025 31.6329 23.686C30.2107 25.1696 28.3202 25.9982 26.3535 26H7.64647C5.67981 25.9982 3.78927 25.1696 2.36708 23.686C0.9449 22.2025 0.100245 20.1779 0.00836393 18.0324C-0.0835176 15.8868 0.584427 13.785 1.87362 12.1629C3.16281 10.5409 4.97428 9.52317 6.9322 9.32089Z'
        fill={color}
      />
    </svg>
  )
}
