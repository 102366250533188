import { passwordCheck } from '../../utils/helpers'
import { blacklistedWords } from './data'
import {
  AppTypeCheck,
  AuthValidateValues,
  FormValidationErrors,
  UserState
} from './types'

export function getAppTypeCheck(user: UserState): AppTypeCheck {
  const isMatchTracker = user?.data?.permissionLevel === 0
  const isBroadcaster = user?.data?.permissionLevel === 1
  const isCommentator = user?.data?.permissionLevel === 2
  const isTraining = user?.data?.permissionLevel === 3
  const isMatchDay = user?.data?.permissionLevel === 4
  const isExternalSoccerTraining = user?.data?.permissionLevel === 5
  const isAdmin =
    !isMatchTracker &&
    !isBroadcaster &&
    !isCommentator &&
    !isTraining &&
    !isMatchDay &&
    !isExternalSoccerTraining

  return {
    isMatchTracker,
    isBroadcaster,
    isCommentator,
    isTraining,
    isMatchDay,
    isExternalSoccerTraining,
    isAdmin
  }
}
export const base64UrlDecode = (base64Url) => {
  // Convert Base64Url to Base64
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  // Pad with '=' to ensure it's a valid Base64 string
  const padding = 4 - (base64.length % 4)
  const paddedBase64 = padding < 4 ? base64 + '='.repeat(padding) : base64

  return atob(paddedBase64) // Decode from Base64
}

// Function to extract the expiration from a JWT token
export const getTokenExpiration = (token) => {
  if (!token) {
    return
  }
  // Remove 'Bearer ' if present
  const jwt = token.split(' ')[1]

  // Split JWT into its components
  const [, payload] = jwt.split('.')

  // Base64Url-decode the payload to get the claims
  const decodedPayload = JSON.parse(base64UrlDecode(payload))

  // Retrieve the expiration claim
  const expirationUnix = decodedPayload.exp

  if (!expirationUnix) {
    return '-'
  }

  // Get the current time in Unix epoch seconds
  const currentUnix = Math.floor(Date.now() / 1000)

  // Calculate the remaining time until expiration in seconds
  const timeRemaining = expirationUnix - currentUnix

  if (timeRemaining <= 0) {
    return 0
  }

  // Convert the time remaining into days
  const daysLeft = Math.floor(timeRemaining / (60 * 60 * 24))

  return daysLeft // Return only the number of days left
}

export const validatePassword = (values: AuthValidateValues) => {
  const errors: FormValidationErrors = {}
  if (!values.password) {
    errors.password = 'Required'
  }
  if (!values.password_confirmation) {
    errors.password_confirmation = 'Required'
  }
  if (values.password && values.password.length < 12) {
    errors.passwordTooShort = 'Password must be at least 12 characters long'
  }
  if (values.password && !passwordCheck(values.password)) {
    errors.passwordInvalid =
      'Password must contain at least one uppercase letter and one number'
  }
  if (
    values.password &&
    blacklistedWords.some((word) =>
      values.password.toLowerCase().includes(word)
    )
  ) {
    errors.blacklistedWord = 'Password contains blacklisted words'
  }
  if (
    !values.password ||
    !values.password_confirmation ||
    (values.password && values.password !== values.password_confirmation)
  ) {
    errors.passwordMismatch = 'Passwords do not match'
  }
  return errors
}

export const getPasswordValidations = (password, password_confirmation) => ({
  length: password && password.length >= 12,
  match: password && password === password_confirmation,
  uppercase: password && /[A-Z]/.test(password),
  number: password && /[0-9]/.test(password)
})
