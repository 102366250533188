import React, { useEffect, useState, useCallback } from 'react'
import { useDraggableListLogic } from './DraggableList.logic'
import styles from './DraggableList.module.scss'
import { Responsive, WidthProvider } from 'react-grid-layout'
import BasicTextField from '../Material/Text'
import BasicSelect from '../Material/Select'
import MultipleSelectChip from '../Material/MultipleSelect'
import Box, { BoxProps } from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import MenuIcon from '@mui/icons-material/Menu'
import AddIcon from '@mui/icons-material/Add'
import Stack from '@mui/material/Stack'

const ResponsiveGridLayout = WidthProvider(Responsive)

export interface DraggableListProps {
  values
  onChange
  onAddRow
  onRemoveRow
  renderComponent
}

// function Item(props: BoxProps) {
//   const { sx, ...other } = props;
//   return (
//     <Box
//       sx={{
//         bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
//         color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
//         border: '1px solid',
//         borderColor: (theme) =>
//           theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
//         p: 1,
//         m: 1,
//         borderRadius: 2,
//         fontSize: '0.875rem',
//         fontWeight: '700',
//         ...sx,
//       }}
//       {...other}
//     />
//   );
// }

export const DraggableList = ({
  values,
  onChange,
  onAddRow,
  onRemoveRow,
  renderComponent
}: DraggableListProps) => {
  const updateLayout = (values) => {
    return values.map((item, index) => {
      return { i: item.id, x: 0, y: index, w: 1, h: 1 }
    })
  }

  const [layout, setLayout] = useState(updateLayout(values))

  // useEffect(() => {
  //   setLayout(updateLayout(values))
  // }, [values])

  const addRow = () => {
    const uniq = 'id' + new Date().getTime()
    setLayout([...layout, { i: uniq, x: 0, y: layout.length, w: 1, h: 1 }])
    onAddRow(uniq)
  }

  const handleLayoutChange = (newLayout) => {
    const newValues = []
    newLayout.forEach((layoutItem) => {
      const value = values.find((x) => layoutItem.i === x.id)
      newValues.splice(layoutItem.y, 0, { ...value })
    })
    onChange(newValues)
  }

  return (
    <div>
      <ResponsiveGridLayout
        className='layout'
        layout={layout}
        cols={{ lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 }}
        rowHeight={50}
        onLayoutChange={handleLayoutChange}
        measureBeforeMount={true}
        // onDragStop={handleLayoutChange}
        // onResizeStop={handleLayoutChange}
      >
        {layout.map((layoutItem, index) => {
          let item = values.find((item) => item.id === layoutItem.i)
          return (
            <div key={layoutItem.i}>
              <Box
                sx={{
                  gap: 2,
                  display: 'grid',
                  gridTemplateColumns: '40px repeat(1, 1fr) 40px'
                }}
              >
                <IconButton size='small' aria-label='menu'>
                  <MenuIcon fontSize='small' />
                </IconButton>
                <div>{renderComponent(item)}</div>
                <IconButton size='small' aria-label='delete'>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Box>
            </div>
          )
        })}
      </ResponsiveGridLayout>
      <Stack
        sx={{ pr: 2, display: 'flex', justifyContent: 'flex-end' }}
        direction='row'
        spacing={2}
      >
        <Button
          onClick={addRow}
          color='success'
          size='small'
          variant='contained'
          endIcon={<AddIcon htmlColor='white' />}
        >
          New row
        </Button>
      </Stack>
    </div>
  )
}
