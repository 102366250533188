import ReactTooltip from 'react-tooltip'
import { useDrillsContext } from '../../../../views/Session/Drills/DrillsContext'
import {
  calculateDrillSegmentStyle,
  createDrillTimeGrid,
  createDrillTimeMarkers,
  formatDrillSessionDuration,
  generateDrillTimeSegments
} from '../../../../metrics_server/drills/functions'
import { useSelectedFormattedSession } from '../../../../metrics_server/sessions/hooks'
import styles from './DrillTimeLine.module.scss'
import { sportableColors } from '../../../../constants/sportableColors'
import { useEffect, useState } from 'react'

export const DrillTimeLine = () => {
  const {
    resetDrillSelection,
    drillsWithColorId,
    progress,
    progressColor,
    selectedDrill,
    timeGridHeightOffset,
    handleDrillSegmentClick,
    hours,
    sessionDuration,
    live
  } = useDrillsContext()

  const { startTime, endTime } = useSelectedFormattedSession()
  const drillSegments = generateDrillTimeSegments(
    drillsWithColorId,
    startTime.unixSeconds
  )

  const [elapsedTime, setElapsedTime] = useState(0)

  useEffect(() => {
    if (live) {
      const interval = setInterval(() => {
        const now = new Date().getTime() / 1000
        setElapsedTime(now - startTime.unixSeconds)
      }, 1000) // Update every second
      return () => clearInterval(interval) // Cleanup on unmount
    } else {
      if (endTime) {
        setElapsedTime(endTime.unixSeconds - startTime.unixSeconds)
      }
    }
  }, [live, endTime, startTime])

  const formattedElapsedTime = formatDrillSessionDuration(elapsedTime)

  return (
    <div className={styles.outerWrapper}>
      {createDrillTimeMarkers(hours)}
      <div className={styles.timeLineContainer} onClick={resetDrillSelection}>
        <div
          style={{
            backgroundColor: sportableColors.colors.sportableGrey,
            height:
              drillSegments.length > 0
                ? `${25 + (drillSegments.length - 1) * timeGridHeightOffset}px`
                : '150px',
            minHeight: '150px',
            position: 'relative',
            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
          }}
        >
          {createDrillTimeGrid(hours)}
          {progress && (
            <div
              style={{
                position: 'absolute',
                left: live ? `${progress + 0.1}%` : `${progress + 1}%`,
                top: '0',
                bottom: '0',
                width: '1px',
                backgroundColor: progressColor,
                zIndex: 3,
                pointerEvents: 'none'
              }}
            ></div>
          )}
          {drillSegments.map((drillSegment, index) => {
            if (!drillSegment.start || !drillSegment.end) return null
            return (
              <div key={drillSegment.id}>
                <div
                  data-tip
                  data-for={drillSegment.id}
                  onClick={handleDrillSegmentClick(drillSegment.id)}
                  style={{
                    ...calculateDrillSegmentStyle(
                      sessionDuration,
                      drillSegment,
                      index,
                      selectedDrill
                    )
                  }}
                >
                  <div
                    className={styles.verticalBanner}
                    style={{
                      backgroundColor: drillSegment.color
                    }}
                  ></div>
                  <span className={styles.drillSegmentTitle}>
                    {drillSegment.end - drillSegment.start > 6 &&
                      `${drillSegment.title} - ${drillSegment.formattedDuration}`}
                  </span>
                </div>
                {drillSegment.end - drillSegment.start < 6 && (
                  <ReactTooltip id={drillSegment.id} aria-haspopup='true'>
                    <p>
                      {drillSegment.title} - {drillSegment.formattedDuration}
                    </p>
                  </ReactTooltip>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div className={styles.sessionDuration}>{formattedElapsedTime}</div>
    </div>
  )
}
