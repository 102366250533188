import React from 'react'
import { EditPencilIcon } from '../../Icons/EditPencilIcon/EditPencilIcon'
import { TickIcon } from '../../Icons/TickIcon/TickIcon'
import { PlayerAvatar } from '../../PlayerAvatar/PlayerAvatar'
import { PlayerProps } from '../Player'
import styles from '../Player.module.scss'

export interface DefaultPlayerProps extends PlayerProps {
  tileSize?: string
}

export const DefaultPlayer = (props: DefaultPlayerProps) => {
  const { player, editMode, editPlayer, className, addPlayer, tileSize } = props

  return (
    <div
      className={`${styles.playerTileContainer} ${styles[className]} ${tileSize}`}
      id={player.id}
      key={player.id}
    >
      {!addPlayer && (
        <div className={styles.tickBox}>
          <TickIcon style={{ position: 'absolute', left: '3px', top: '4px' }} />
        </div>
      )}
      {player.img ? (
        <div className={styles.imageContainer}>
          {editMode && (
            <div
              id='edit-player'
              onClick={() => editPlayer(player)}
              className={styles.editBox}
            >
              <EditPencilIcon scale={0.35} />
            </div>
          )}
          <img
            draggable={false}
            id={player.id}
            className={`${styles.img}`}
            src={player.img}
            alt=''
          />
        </div>
      ) : (
        <div className={styles.avatarContainer} id={player.id}>
          {editMode && (
            <div
              id='edit-player'
              onClick={() => editPlayer(player)}
              className={styles.editBox}
            >
              <EditPencilIcon scale={0.35} />
            </div>
          )}
          <PlayerAvatar autoScale />
        </div>
      )}

      <div id='player-name' className={styles.playerDetailsContainer}>
        <p className={styles.nameInfo}>
          <b>{player.lastName}</b>,
        </p>
        <div className={styles.tagAndNumberContainer}>
          <div className={styles.tagInfo}>{player.firstName}</div>
          <p className={styles.number}>{player?.number}</p>
        </div>
      </div>
    </div>
  )
}
