import React, { Component } from 'react'
import styles from './ScollerX.module.scss'

interface ScollerXProps {
  children: JSX.Element
  scrollerId?: string
  scrollerClass?: string
}

export interface ScollerXState {
  position: any
}

class ScrollerX extends Component<ScollerXProps, ScollerXState> {
  private inner: React.RefObject<HTMLInputElement>
  private container: React.RefObject<HTMLInputElement>
  private scroller: React.RefObject<HTMLInputElement>

  static defaultProps = {
    scrollerClass: 'form-scroller-x'
  }

  constructor(props) {
    super(props)

    this.state = {
      position: null
    }
    this.inner = React.createRef()
    this.container = React.createRef()
    this.scroller = React.createRef()
  }

  componentDidMount() {
    let containerWidth = this.container.current.getBoundingClientRect().width,
      innerWidth = this.inner.current.getBoundingClientRect().width
    if (innerWidth <= containerWidth) {
      this.scroller.current.style.display = 'none'
    }
  }

  componentDidUpdate(prevProps) {
    let containerWidth = this.container.current.getBoundingClientRect().width,
      innerWidth = this.inner.current.getBoundingClientRect().width
    if (innerWidth <= containerWidth) {
      this.scroller.current.style.display = 'none'
    } else {
      this.scroller.current.style.display = 'flex'
    }
    if (this.props.scrollerId !== prevProps.scrollerId) {
      this.container.current.scrollTop = 0
    }
  }

  scrollBar(e) {
    this.updateScrollPosition(
      e.target,
      this.inner.current,
      this.scroller.current
    )
  }

  renderChildren() {
    return React.cloneElement(this.props.children, {
      ref: this.inner
    })
  }

  //inner width needs to be the big one

  updateScrollPosition(container, inner, scroller) {
    let containerLeft = container.getBoundingClientRect().left,
      containerWidth = container.getBoundingClientRect().width,
      innerleft = inner.getBoundingClientRect().left,
      innerWidth = inner.getBoundingClientRect().width,
      innerRelativeleft = containerLeft - innerleft,
      scrollerWidth = scroller.getBoundingClientRect().width,
      scrollOffset = (containerWidth - scrollerWidth) / containerWidth,
      scrollBarWidth =
        containerWidth * (innerRelativeleft / (innerWidth - containerWidth))
    this.scroller.current.style.left = `${scrollBarWidth * scrollOffset}px`
  }

  render() {
    const { scrollerClass, scrollerId } = this.props
    return (
      <div className={styles.scrollerContainer}>
        <div
          style={{ height: '100%', width: '100%' }}
          onScroll={this.scrollBar.bind(this)}
          ref={this.container}
        >
          {this.renderChildren()}
        </div>
        <div
          id={scrollerId}
          ref={this.scroller}
          className={scrollerClass}
        ></div>
      </div>
    )
  }
}

export default ScrollerX

// ID
// class - check classes
// wrap scroller in div with height
