import { StatusActionTypes, StatusState } from './types'

const { SET_DBSYNC_STATUS } = StatusActionTypes

const initialState: StatusState = {
  dbsync: {
    connection: null,
    connectionStatus: null,
    syncStatus: null,
    recentError: null,
    userAgent: null
  }
}

export function statusReducer(state = initialState, action): StatusState {
  switch (action.type) {
    case SET_DBSYNC_STATUS:
      return {
        ...state,
        dbsync: action.payload
      }
    default:
      return state
  }
}
