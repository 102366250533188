import React, { useEffect, useMemo, useState } from 'react'
import { AddNewButton } from '../../../components/AddNewButton/AddNewButton'
import { CreatePlayerPlaceholder } from '../../../components/CreatePlayerPlaceholder/CreatePlayerPlaceholder'
import { MainHeader } from '../../../components/MainHeader/MainHeader'
import { SearchableDropdown } from '../../../components/SearchableDropdown/SearchableDropdown'
import { TeamGrid } from '../../../components/TeamGrid/TeamGrid'
import { PlayerData } from '../../../metrics_server/players/types'
import { useTeamOptions, useTeams } from '../../../metrics_server/teams/hooks'
import { Team } from '../../../metrics_server/teams/types'
import styles from '../Teams.module.scss'
import { SearchInput } from '../../../components/SearchInput/SearchInput'
import BasicSelect from '../../../components/Material/Select'

export interface TeamsContentProps {
  team: Team
  teamsArray: Team[]
  teamPlayers: PlayerData[]
  selectedPlayers: PlayerData[]
  switchTeam: (teamId: string) => void
  editMode: boolean
  toggleEditMode: () => void
  handleEditClick: (player: PlayerData) => void
  handleRemoveClick: (player: PlayerData) => void
  createNewPlayer: () => void
  playerSelectToggle: (player: PlayerData) => void
  addTeamCallback: () => void
  setRedirect: (pathname: string) => any
}

export function TeamsContent({
  team,
  teamPlayers,
  selectedPlayers,
  switchTeam,
  editMode,
  toggleEditMode,
  handleEditClick,
  handleRemoveClick,
  createNewPlayer,
  playerSelectToggle,

  setRedirect
}: TeamsContentProps) {
  const teams = useTeams()
  const { teamOptions } = useTeamOptions()

  const onSelect = (value) => {
    if (value) {
      switchTeam(value)
    }
  }
  const [players, setPlayers] = useState(teamPlayers)
  const [searchInput, setSearchInput] = useState('')
  const searchPlayers = (input) => setSearchInput(input.toLowerCase())

  useEffect(() => {
    const searchedPlayers = teamPlayers?.filter((player) => {
      const fullName = (
        (player.firstName || '') +
        ' ' +
        (player.lastName || '')
      ).toLowerCase()

      const number = player.number

      if (number === parseInt(searchInput)) {
        return true
      }

      return fullName.includes(searchInput.toLowerCase())
    })

    setPlayers(searchedPlayers)
  }, [searchInput, teamPlayers])

  return (
    <React.Fragment>
      <div className={styles.teamContent}>
        <div className={styles.teamsDropdownContainer}>
          <AddNewButton
            addCallBack={() => setRedirect('/createorganisation')}
            style={{ paddingRight: '20px' }}
            size={45}
          />

          <SearchableDropdown
            items={teamOptions}
            onSelect={onSelect}
            value={teams.selectedTeam}
            label={'Teams'}
            disabledValue={'Second Team'}
            isMultiple={false}
            minWidth={400}
          />
        </div>
      </div>
      <div className={styles.playerContent}>
        {teamPlayers && (
          <React.Fragment>
            {teamPlayers.length == 0 ? (
              <CreatePlayerPlaceholder createPlayer={createNewPlayer} />
            ) : (
              <React.Fragment>
                <div className={styles.playerSectionHeader}>
                  <MainHeader
                    title={editMode ? 'Edit players' : 'Players'}
                    options={[
                      {
                        name: editMode ? 'Done' : 'Edit players',
                        callback: toggleEditMode
                      }
                      // {name: 'Add existing player profile', callback: this.modalToggle}
                    ]}
                  />
                  <div style={{ width: '250px', marginRight: '20px' }}>
                    <BasicSelect
                      label={'Search Players'}
                      variant='standard'
                      selected={searchInput}
                      onChange={searchPlayers}
                      labelShrink={true}
                      size={'medium'}
                      fontSize={20}
                    />
                  </div>
                </div>
                <div className={styles.playersGridContainer}>
                  <TeamGrid
                    editMode={editMode}
                    removePlayer={handleRemoveClick}
                    editPlayer={handleEditClick}
                    addPlayer={createNewPlayer}
                    players={players}
                    selectedPlayers={selectedPlayers}
                    playerSelectToggle={playerSelectToggle}
                  />
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}
