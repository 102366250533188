import React from 'react'
import batteryLow from '../../../../assets/img/tag_low_batt.svg'

import moment from '../../../../utils/moment'
import styles from '../Notification.module.scss'

import {
  checkLowBattLevelAndReturnCorrectText,
  renderDeviceIcon
} from '../../../../utils/helpers'
import { NotificationType } from '../../../../metrics_server/notifications/types'
import { getSport } from '../../../../metrics_server/sports/functions'
import { useSessions } from '../../../../metrics_server/sessions/hooks'

export interface DiagnosticNotificationProps {
  notification: NotificationType
  clear: () => void
}

export const DiagnosticNotification = ({
  notification,
  clear
}: DiagnosticNotificationProps) => {
  const { deviceType, timestamp, title, description } = notification

  const convertedTime = moment.unix(timestamp).format('h:mm:ss')

  const isLowBattery = description
    ? description.includes('Low Battery')
    : undefined

  const sessions = useSessions()
  const sport = getSport(sessions.activeSession?.sportType || undefined)

  if (!deviceType || !timestamp) {
    return null
  }

  return (
    <div className={styles.diagsNotificationContainer}>
      <div className={styles.tagIcon}>
        {renderDeviceIcon(deviceType, sport)}
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.separator} />
        <div className={styles.tagId}>
          <p style={{ color: 'gray' }}>{convertedTime}</p>
          <h5 style={{ color: '#ffffff' }}>{title}</h5>
        </div>
        {description && (
          <>
            <div className={styles.separator} />
            <div className={styles.description}>
              {isLowBattery && (
                <img
                  src={batteryLow}
                  alt='batt'
                  className={styles.batteryImage}
                />
              )}
              <h5 style={{ padding: '0 5px', color: '#0099cc' }}>
                {isLowBattery
                  ? checkLowBattLevelAndReturnCorrectText(description)
                  : description}
              </h5>
            </div>
          </>
        )}
      </div>

      <p
        style={{
          cursor: 'pointer',
          color: '#ffffff'
        }}
        onClick={clear}
      >
        x
      </p>
    </div>
  )
}
