import React from 'react'
import styles from '../HardwareItem.module.scss'
import { FormattedDevice } from '../../../../metrics_server/hardware/types'
import { BatteryIcon } from '../../../Icons/BatteryIcon/BatteryIcon'
import { QAInfo } from '../QAInfo/QAInfo'

export interface HardwareCommonProps {
  device: FormattedDevice
}

export const HardwareCommon: React.FC<HardwareCommonProps> = ({ device }) => {
  return (
    <div className={`${styles['hardware-item-container']}`}>
      <div
        style={{ backgroundColor: device.status.colour }}
        className={`${styles['hardware-item-sharedContainer']}`}
      >
        <div className={styles['hardware-item-encodedId']}>{device.serial}</div>
        <div className={styles['hardware-item-batteryContainer']}>
          <BatteryIcon device={device} />
        </div>
        <div className={styles['hardware-item-bottomDivBatteryAndQAInfo']}>
          <div>
            <h6 className={styles['hardware-item-batteryPercentage']}>
              {device.battery.display}
            </h6>
          </div>
          {device.reports ? (
            <QAInfo qaReports={device.reports} />
          ) : (
            <div style={{ marginTop: '5px' }}></div>
          )}
        </div>
      </div>
    </div>
  )
}
