import { request } from '../../utils/request_handler'
import { setError } from '../../ui/error/actions'
import { setSuccess } from '../../ui/success/actions'
import { API_ROOT_URL } from '../../const'

export function clearDiagnostics() {
  return (dispatch) => {
    const success = (response) => {
      dispatch(setSuccess({ message: `Diagnostics Cleared` }))
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to clear diagnostics. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `config/clear_diagnostics`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}
