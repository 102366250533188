import React from 'react'

import { useMemo } from 'react'
import { useAppDispatch } from '../../store/hooks'

import { NameForm } from '../../components/Forms/NameForm/NameForm'

import {
  useInsights,
  useLayoutOptions,
  useSelectedLayout
} from '../../metrics_server/insights/hooks'
import * as insightsActions from '../../metrics_server/insights/actions'
import { LayoutType } from '../../metrics_server/insights/types'
import { useSportscaster } from '../../metrics_server/sportscaster/hooks'
import { useSessions } from '../../metrics_server/sessions/hooks'

export function useSessionOptions(view) {
  const { selectedId } = useSessions()

  return [
    {
      name: 'Copy Session ID',
      handle: () => navigator.clipboard.writeText(selectedId)
    }
  ]
}

export function useMatchDashboardOptions(props) {
  const dispatch = useAppDispatch()

  const { layouts } = useInsights()

  const { locationNames } = useSportscaster()

  const { selectedLayout } = useSelectedLayout(LayoutType.sessionLayout)

  const { layoutOptions } = useLayoutOptions()

  const toggleSaveLayoutModal = (callback) => {
    const { toggleModal } = props
    toggleModal({
      active: true,
      type: 'confirm',
      wrapper: true,
      title: 'Save Layout',
      className: 'modalSmall',
      ChildComponent: () => {
        return (
          <NameForm
            handleSubmit={(values) => {
              callback(values.name)
              toggleModal({})
            }}
          />
        )
      }
    })
  }

  const customInputs = useMemo(() => {
    const selectedLayoutId = layouts.selectedId.sessionLayout
    const editedLayout = layouts.editedItems[selectedLayoutId]
    const isDefault = selectedLayoutId === 'default'

    return [
      {
        name: 'Undo',
        type: 'button',
        onClick: () => {
          dispatch(
            insightsActions.deleteEditedInsightsLayout(
              layouts.selectedId.sessionLayout
            )
          )
        },
        hidden: !editedLayout
      },
      {
        name: 'Save',
        type: 'button',
        onClick: () => {
          const data = {
            ...selectedLayout
          }
          dispatch(
            insightsActions.updateInsightsLayout(
              data,
              LayoutType.sessionLayout,
              selectedLayoutId
            )
          )
        },
        hidden: !editedLayout || isDefault
      },
      {
        name: 'Save as',
        type: 'button',
        onClick: () => {
          toggleSaveLayoutModal((name) => {
            const data = {
              ...selectedLayout,
              name
            }
            delete data.id
            dispatch(
              insightsActions.updateInsightsLayout(
                data,
                LayoutType.sessionLayout,
                selectedLayoutId
              )
            )
          })
        },
        hidden: !editedLayout
      },
      {
        name: 'layout',
        type: 'select',
        options: layoutOptions,
        value: layoutOptions.find(
          (option) => option.value === selectedLayoutId
        ),
        onChange: (value) => {
          if (value)
            dispatch(
              insightsActions.setSelectedInsightsLayout(
                value,
                LayoutType.sessionLayout
              )
            )
        }
      }
    ]
  }, [layoutOptions, layouts, selectedLayout, locationNames])

  const options = [
    {
      name: 'Option 2',
      handle: () => {
        console.log('fire 2')
      }
    }
  ]

  return {
    customInputs,
    options
  }
}
