import {
  GET_PITCHES,
  SET_PITCH_IN_USE,
  UPDATE_PITCH_SIDE,
  UPDATE_MASTER,
  UPDATE_ANCHOR_BUMP,
  SET_AUTO_LOCATE_ACTIVE,
  TOGGLE_AUTO_SETUP,
  UPDATE_PITCH_SETUP_TAG_LIST,
  UPDATE_PITCH_SETUP_TYPE
} from './types'

import { REMOVE_USER_DATA } from '../types'

import _ from 'lodash'
import { encodeHardwareId } from '../../utils/encoding/index'
import { PitchesState } from './types'
import { pitchSetupTypes } from './data_types'

const initialState: PitchesState = {
  items: {},
  pitchType: 0,
  inUse: null,
  side: 0,
  master: 0,
  anchorBump: 1.5,
  autoLocateActive: false,
  isAutoSetup: true,
  setupTagList: [null, null, null],
  pitchJson: '',
  anchorJson: '',
  setupType: pitchSetupTypes.items.auto.value
}

export function pitchesReducer(state = initialState, action) {
  let data
  switch (action.type) {
    case GET_PITCHES:
      data = action.payload
      data = _.keyBy(data, 'id')
      return {
        ...state,
        items: {
          ...state.items,
          ...data
        }
      }
    case SET_PITCH_IN_USE:
      data = action.payload
      if (data.anchors) {
        for (const anchorId in data.anchors) {
          data.anchors[anchorId].encodedId = encodeHardwareId(anchorId)
        }
      } else {
        data.anchors = {}
      }

      if (!data.pitch) {
        data.pitch = {}
      }

      return {
        ...state,
        inUse: data,
        master: null,
        anchorBump: 1.5,
        autoLocateActive: false,
        setupTagList: [null, null, null]
      }
    case UPDATE_PITCH_SIDE:
      return {
        ...state,
        side: action.payload
        // inUse: {
        //   ...state.inUse,
        //   anchors: {}
        // }
      }
    case UPDATE_MASTER:
      return {
        ...state,
        master: action.payload
      }
    case UPDATE_ANCHOR_BUMP:
      return {
        ...state,
        anchorBump: action.payload
      }
    case TOGGLE_AUTO_SETUP:
      return {
        ...state,
        isAutoSetup: action.payload
      }
    case UPDATE_PITCH_SETUP_TYPE:
      return {
        ...state,
        setupType: action.payload,
        side:
          action.payload === pitchSetupTypes.items.pitch.value ? 0 : state.side
      }
    case UPDATE_PITCH_SETUP_TAG_LIST:
      data = [...state.setupTagList]
      data[action.payload.index] = action.payload.tagId
      return {
        ...state,
        setupTagList: data
      }

    case SET_AUTO_LOCATE_ACTIVE:
      return {
        ...state,
        autoLocateActive: action.payload
      }

    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
