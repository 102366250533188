import { useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from '../../store/hooks'
import { useFormattedSession, useSession } from '../sessions/hooks'
import { formatEventData } from './functions'
import { getMetricHeaders } from '../../metrics_server/units/functions'
import { FormattedEventData, RawEventData } from './types'
import { useUnitsSystem } from '../units/hooks'
import { updateEvent } from './actions'
import { TeamLogoCell } from '../../components/Table/CustomCells/TeamLogoCell/TeamLogoCell'
import { useAppDispatch } from '../../store/hooks'
import { getEmptyGroup } from '../sessions/functions'
import { Group, addItemToGroup } from '../types'

export const useEvents = () => {
  const events = useAppSelector((state) => state.events)
  return events
}

export const useValidationTableHeaders = (timeColumn?: string) => {
  const dispatch = useAppDispatch()

  const events = useEvents()

  const sessionConfig = useSession()
  const { sport, flightMetrics } = sessionConfig

  const unitSystem = useUnitsSystem(sport)
  const { columns } = events

  const selectedColumns = useMemo(() => {
    return getMetricHeaders(columns, flightMetrics, unitSystem, 12, 12)
  }, [columns, unitSystem])

  return useMemo(
    () => [
      {
        name: '',
        key: 'highlight',
        accessorKey: 'highlight',
        width: 9,
        input: {
          type: 'highlight',
          onChange: (item, bool) => {
            dispatch(
              updateEvent({
                id: item.id,
                operatorNotes: { ...item.operatorNotes, highlight: bool }
              })
            )
          }
        }
      },
      {
        name: 'Time',
        key: timeColumn,
        accessorKey: timeColumn,
        width: 25,
        type: timeColumn === 'sessionStartTime' ? 'time' : 'date'
      },
      {
        name: 'Team',
        key: 'teamLogo',
        accessorKey: 'teamLogo',
        width: 9,
        type: 'component',
        CustomComponent: TeamLogoCell
      },
      {
        name: 'Player',
        key: 'fromPlayer',
        accessorKey: 'fromPlayer',
        width: 25
      },
      { name: 'Type', key: 'typeName', accessorKey: 'typeName', width: 16 },
      ...selectedColumns,
      {
        name: 'Ignore',
        key: 'ignore',
        accessorKey: 'ignore',
        width: 9,
        input: {
          type: 'checkbox',
          onChange: (item, bool) => {
            dispatch(updateEvent({ id: item.id, ignore: bool }))
          }
        }
      }
    ],
    [timeColumn, selectedColumns]
  )
}

export const useSelectedFormattedEvent = () => {
  const { selectedEventId } = useEvents()
  return useFormattedEvent(selectedEventId)
}

export const useFormattedEvent = (eventId: string) => {
  // Get raw event data //
  const { rawData } = useEvents()
  const event = rawData[eventId]

  // Get session data + unit system //
  const sessionId = event?.sessionId || event?.event?.sessionId
  const formattedSession = useFormattedSession(sessionId)
  const unitSystem = useUnitsSystem(formattedSession?.sport)

  // Format and memoize event //
  const formattedEvent = useMemo(() => {
    if (formattedSession && formattedSession.id === sessionId && unitSystem) {
      return formatEventData(event, formattedSession, unitSystem)
    }
  }, [event, formattedSession, unitSystem])

  return formattedEvent
}

export const useFormattedEvents = (
  events: RawEventData[],
  sessionId: string
) => {
  const formattedSession = useFormattedSession(sessionId)
  const { sport } = formattedSession
  const unitSystem = useUnitsSystem(sport)

  const [formattedEvents, setFormattedEvents] = useState<
    Group<FormattedEventData>
  >(getEmptyGroup<FormattedEventData>())

  // Update events if session or unit system changes //
  useEffect(() => {
    const newFormattedEvents = getEmptyGroup<FormattedEventData>()
    if (unitSystem && formattedSession) {
      events.forEach((event) => {
        const formattedEvent = formatEventData(
          event,
          formattedSession,
          unitSystem
        )
        if (formattedEvent) {
          addItemToGroup(
            newFormattedEvents,
            formattedEvent,
            formattedEvent.id,
            'id',
            formattedEvent.id
          )
        }
      })
    }
    setFormattedEvents(newFormattedEvents)
  }, [unitSystem, formattedSession])

  // Only format new or updated events by storing events in ref //
  const eventsRef = useRef({})
  useEffect(() => {
    const newFormattedEvents = getEmptyGroup<FormattedEventData>()
    events.forEach((event) => {
      const eventRef = eventsRef.current[event.id]
      const currentFormattedEvent = formattedEvents.map[event.id]
      const formattedEvent =
        eventRef !== event || !currentFormattedEvent
          ? formatEventData(event, formattedSession, unitSystem)
          : currentFormattedEvent

      if (formattedEvent) {
        addItemToGroup(
          newFormattedEvents,
          formattedEvent,
          formattedEvent.id,
          'id',
          formattedEvent.id
        )
      }

      eventsRef.current[event.id] = event
    })
    setFormattedEvents(newFormattedEvents)
  }, [events])

  return formattedEvents
}
