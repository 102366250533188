import { AxiosResponse } from 'axios'
import { AppRequest, executeAppRequest } from '../../utils/request_handler'
import { API_ROOT_URL } from '../api/config'
import {
  BroadcastIntegrationData,
  BroadcastIntegrationPlayerCheckFields,
  BroadcastIntegrationTeamCheckFields,
  GeniusFixtureRoster
} from './types'

// Check genius integration status

export type GeniusStatusResponseBody = AxiosResponse<BroadcastIntegrationData>

export const checkGeniusStatusRequest: AppRequest<
  null,
  GeniusStatusResponseBody
> = async () => {
  const options = {
    url: 'genius/status',
    method: 'get' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Check if matching genius fiuxture exists

export type FixtureCheckRequestBody = {
  homeTeam: BroadcastIntegrationTeamCheckFields
  awayTeam: BroadcastIntegrationTeamCheckFields
  dataTime: Date
}

export type FixtureCheckResponseBody = AxiosResponse<BroadcastIntegrationData>

export const checkFixtureRequest: AppRequest<
  FixtureCheckRequestBody,
  FixtureCheckResponseBody
> = async (data) => {
  const options = {
    url: 'genius/resolve_fixture',
    data,
    method: 'post' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Check if matching genius player exists

export type PlayerCheckRequestBody = BroadcastIntegrationPlayerCheckFields

export type PlayersCheckResponseBody = AxiosResponse<
  BroadcastIntegrationData['playerIds']
>

export const checkPlayerRequest: AppRequest<
  PlayerCheckRequestBody,
  PlayersCheckResponseBody
> = async (data) => {
  const options = {
    url: 'genius/resolve_player',
    data,
    method: 'post' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Check if matching genius players exist

export type PlayersCheckRequestBody = BroadcastIntegrationPlayerCheckFields[]

export const checkPlayersRequest: AppRequest<
  PlayersCheckRequestBody,
  PlayersCheckResponseBody
> = async (data) => {
  const options = {
    url: 'genius/resolve_players',
    data,
    method: 'post' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Get genius fixture team roster //
export type GetGeniusFixtureTeamRosterResponseBody =
  AxiosResponse<GeniusFixtureRoster>

export const getGeniusFixtureRosterRequest: AppRequest<
  null,
  GetGeniusFixtureTeamRosterResponseBody
> = async () => {
  const options = {
    url: 'genius/roster',
    method: 'get' as const,
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}
