import React from 'react'
import {
  useSportscaster,
  useSportscasterTableProps
} from '../../metrics_server/sportscaster/hooks'
import { Table } from '../Table/Table'

export interface SportscasterTableProps {
  type: 'selectedBrokerHost' | 'selectedSportscasterHost'
}

export const SportscasterTable: React.FC<SportscasterTableProps> = ({
  type
}) => {
  const sportscaster = useSportscaster()

  const { tableHeaders, tableData } = useSportscasterTableProps(
    sportscaster,
    type
  )

  return (
    <div>
      <Table
        data={tableData}
        headers={tableHeaders}
        // controls
        controls={[]}
        headerFont={13}
        options={{
          initialOrder: 'asc',
          initialSortBy: 'id',
          sortActive: true
        }}
        tableClass={'minimalistBlack'}
        className={'small-container'}
        id={'tableNew'}
      />
    </div>
  )
}
