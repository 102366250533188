import React, { useMemo, useState } from 'react'
import { Button } from '../../../components/Button/Button'
import { CardList } from '../../../components/CardList/CardList'
import { Table } from '../../../components/Table/Table'
import { TableRow } from '../../../components/Table/Table.types'
import { sportableColors } from '../../../const'
import {
  DeviceState,
  FormattedDevice
} from '../../../metrics_server/hardware/types'
import styles from './Controller.module.scss'
import { useHardware } from '../../../metrics_server/hardware/hooks'
import { Group } from '../../../metrics_server/types'
import { useAppDispatch } from '../../../store/hooks'
import {
  disableRSSIAction,
  enableRSSIAction,
  resetSeatSwapping,
  serviceAction,
  updateSeatSwappingAlgoState
} from '../../../metrics_server/hardware/actions'
import { setError } from '../../../ui/error/actions'
import { Toggle } from '../../../components/Forms/Fields/Toggle/Toggle'
import DropdownWithSendButton from '../../../components/Table/CustomCells/Dropdown/DropdownWithSendButton'
import { setInfo } from '../../../ui/info/actions'
import { useSessions } from '../../../metrics_server/sessions/hooks'
import { subSessionTypes } from '../../../metrics_server/sessions/sub_sessions/data_types'

export function Controller() {
  const dispatch = useAppDispatch()

  const { formattedHardware, isSeatSwappingAlgoEnabled } = useHardware()
  const { activeSession } = useSessions()

  const [highlightedRow, setHighlightedRow] = useState(null)
  const [deviceTabView, setDeviceTabView] = useState('balls')

  const isBallsView = deviceTabView === 'balls'
  const isTagsView = deviceTabView === 'tags'
  const isAnchorsView = deviceTabView === 'anchors'

  const isBroadcast =
    activeSession?.subType === subSessionTypes.items.broadcast.value

  const getRowData = (devices: Group<FormattedDevice, number>) => {
    return devices.list.map((device) => {
      const row = {} as DeviceState & TableRow
      row.id = device.id
      row.serial = device.serial
      row.lastOnline = device.lastOnline * 1000
      row.highlightColor = device.status.colour
      row.state = device.state
      row.status = device.status.display
      // row.state = `${device.status.display} (${device.state})`
      row.__buttons = {
        unseat: {
          text: 'Unseat',
          value: 'unseat'
        }
      }
      row.dropDownOptions = {
        offline: {
          standby: {
            text: 'Stand by',
            value: 'standby',
            disabled: device.productName === 'Junco'
          },
          noHibernate: {
            text: 'No Hibernate',
            value: 'hibernate/disable'
          },
          hibernate: {
            text: 'Hibernate',
            value: 'hibernate'
          },
          shutdown: {
            text: 'Shutdown',
            value: 'shutdown'
          },
          reset: {
            text: 'Reset',
            value: 'reset'
          }
        },
        sleep: {
          demote: {
            text: 'Demote',
            value: 'power/demote'
          },
          timeLapse: {
            text: 'Timelapse',
            value: 'balls/timelapse'
          }
        },
        online: {
          promote: {
            text: 'Promote',
            value: 'power/promote'
          },
          online: {
            text: 'Online',
            value: 'balls/online'
          }
        }
      }
      return row
    })
  }

  const ballTableData = useMemo(() => {
    return getRowData(formattedHardware.types.ball.devices)
  }, [formattedHardware])

  const tagTableData = useMemo(() => {
    return getRowData(formattedHardware.types.playerTag.devices)
  }, [formattedHardware])

  const anchorTableData = useMemo(() => {
    return getRowData(formattedHardware.types.anchor.devices)
  }, [formattedHardware])

  const getTableHeaders = () => {
    const callback = (item, value) => {
      dispatch(
        setError({
          message: `${value}: ${item.serial} - Are you sure?`,
          type: 'warning',
          proceed: () => {
            dispatch(serviceAction([item.id], value, true))
          }
        })
      )
    }

    return [
      { name: 'Serial', key: 'serial' },
      { name: 'Last Online', key: 'lastOnline', type: 'date' },
      {
        name: '',
        key: 'unseat',
        input: {
          type: 'button',
          callback,
          text: 'Unseat'
        }
      },
      {
        name: 'Offline',
        key: 'offline',
        type: 'component',
        CustomComponent: DropdownWithSendButton,
        group: 'offline',
        width: 30,
        callback
      },
      {
        name: 'Sleep',
        key: 'sleep',
        type: 'component',
        CustomComponent: DropdownWithSendButton,
        group: 'sleep',
        width: 30,
        callback
      },
      {
        name: 'Online',
        key: 'online',
        type: 'component',
        CustomComponent: DropdownWithSendButton,
        group: 'online',
        width: 30,
        callback
      },
      { name: 'Status', key: 'status' },
      { name: 'State', key: 'state' }
    ]
  }

  return (
    <React.Fragment>
      <div className={styles.deviceTable}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.controlTitle}>
            <Toggle
              input={{
                value: deviceTabView,
                onChange: (value) => setDeviceTabView(value)
              }}
              options={[
                { name: 'Balls', value: 'balls' },
                { name: 'Tags', value: 'tags' },
                { name: 'Anchors', value: 'anchors' }
              ]}
              width={200}
              size={'s'}
            />
          </div>
          {isBallsView && (
            <div className={styles.controlTable}>
              <Table
                options={{
                  initialOrder: 'asc',
                  initialSortBy: 'id',
                  sortActive: true
                }}
                searchBy={['serial']}
                highlightRow={(id) => setHighlightedRow(id)}
                highlightedRow={highlightedRow}
                headerFont={13}
                smallHead={true}
                tableClass={'minimalistBlack'}
                className={'container'}
                // bodyFont={13}
                data={ballTableData}
                headers={getTableHeaders()}
                groups={
                  ballTableData.length > 0 && {
                    online: {
                      name: 'Online',
                      color: sportableColors.colors.colorSuccess
                    },
                    offline: {
                      name: 'Offline',
                      color: sportableColors.colors.colorError
                    },
                    sleep: {
                      name: 'Sleep',
                      color: sportableColors.colors.colorWarning
                    }
                  }
                }
                controls={
                  // Hide control buttons if session's mode is broadcast
                  !isBroadcast
                    ? [
                        {
                          name: 'Reset all',
                          callback: () =>
                            dispatch(
                              serviceAction(
                                ballTableData.map((x) => x.id),
                                'reset'
                              )
                            )
                        },
                        {
                          name: 'Standby all',
                          callback: () =>
                            dispatch(
                              serviceAction(
                                ballTableData.map((x) => x.id),
                                'standby'
                              )
                            )
                        },
                        {
                          name: 'Shutdown all',
                          callback: () =>
                            dispatch(
                              serviceAction(
                                ballTableData.map((x) => x.id),
                                'shutdown'
                              )
                            )
                        }
                      ]
                    : null
                }
              />
            </div>
          )}

          {isTagsView && (
            <div className={styles.controlTable}>
              <Table
                options={{
                  initialOrder: 'asc',
                  initialSortBy: 'id',
                  sortActive: true
                }}
                searchBy={['serial']}
                highlightRow={(id) => setHighlightedRow(id)}
                highlightedRow={highlightedRow}
                headerFont={13}
                smallHead={true}
                tableClass={'minimalistBlack'}
                className={'container'}
                // bodyFont={13}
                data={tagTableData}
                headers={getTableHeaders()}
                groups={
                  tagTableData.length > 0 && {
                    online: {
                      name: 'Online',
                      color: sportableColors.colors.colorSuccess
                    },
                    offline: {
                      name: 'Offline',
                      color: sportableColors.colors.colorError
                    },
                    sleep: {
                      name: 'Sleep',
                      color: sportableColors.colors.colorWarning
                    }
                  }
                }
                controls={[
                  {
                    name: 'Reset all',
                    callback: () =>
                      dispatch(
                        serviceAction(
                          tagTableData.map((x) => x.id),
                          'reset'
                        )
                      )
                  },
                  {
                    name: 'Standby all',
                    callback: () =>
                      dispatch(
                        serviceAction(
                          tagTableData.map((x) => x.id),
                          'standby'
                        )
                      )
                  },
                  {
                    name: 'Shutdown all',
                    callback: () =>
                      dispatch(
                        serviceAction(
                          tagTableData.map((x) => x.id),
                          'shutdown'
                        )
                      )
                  }
                  // {
                  //   name: 'Shutdown all',
                  //   callback: () =>
                  //     dispatch(
                  //       serviceAction(
                  //         tagTableData.map((x) => x.id),
                  //         'shutdown'
                  //       )
                  //     )
                  // }
                ]}
              />
            </div>
          )}
          {isAnchorsView && (
            <div className={styles.controlTable}>
              <Table
                options={{
                  initialOrder: 'asc',
                  initialSortBy: 'id',
                  sortActive: true
                }}
                searchBy={['serial']}
                highlightRow={(id) => setHighlightedRow(id)}
                highlightedRow={highlightedRow}
                headerFont={13}
                smallHead={true}
                tableClass={'minimalistBlack'}
                className={'container'}
                // bodyFont={13}
                data={anchorTableData}
                headers={getTableHeaders()}
                groups={
                  anchorTableData.length > 0 && {
                    online: {
                      name: 'Online',
                      color: sportableColors.colors.colorSuccess
                    },
                    offline: {
                      name: 'Offline',
                      color: sportableColors.colors.colorError
                    },
                    sleep: {
                      name: 'Sleep',
                      color: sportableColors.colors.colorWarning
                    }
                  }
                }
                controls={[
                  {
                    name: 'Reset all',
                    callback: () =>
                      dispatch(
                        serviceAction(
                          anchorTableData.map((x) => x.id),
                          'reset'
                        )
                      )
                  },
                  {
                    name: 'Standby all',
                    callback: () =>
                      dispatch(
                        serviceAction(
                          anchorTableData.map((x) => x.id),
                          'standby'
                        )
                      )
                  }
                ]}
              />
            </div>
          )}
        </CardList>
      </div>

      <div className={styles.generalControls}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.rssiButtons}>
            <Button
              handleClick={() => {
                dispatch(enableRSSIAction())
              }}
              className='btn--primary'
            >
              Enable RSSI
            </Button>
            <Button
              handleClick={() => {
                dispatch(disableRSSIAction())
              }}
              className='btn--primary'
            >
              Disable RSSI
            </Button>
          </div>
          <div className='p-4 pt-2'>
            <h5 className='pt-2 pb-2'>Seat Swapping</h5>
            <Button
              handleClick={() => {
                dispatch(
                  setInfo({
                    header: `${
                      isSeatSwappingAlgoEnabled ? 'Disable' : 'Enable'
                    } seat swapping`,
                    message: `Are you sure you want to ${
                      isSeatSwappingAlgoEnabled ? 'disable' : 'enable'
                    } seat swapping?`,
                    proceed: () => dispatch(updateSeatSwappingAlgoState())
                  })
                )
              }}
              className={`btn--border btn--small ${
                isSeatSwappingAlgoEnabled
                  ? styles.seatSwappingEnabled
                  : styles.seatSwappingDisabled
              }`}
              type='button'
            >
              Auto Seat Swapping: {isSeatSwappingAlgoEnabled ? 'ON' : 'OFF'}
            </Button>
            <div className='pt-4 w-full'>
              <Button
                handleClick={() => {
                  dispatch(
                    setInfo({
                      header: 'Reset seat swapping',
                      message: `Are you sure you want to reset seat swapping?`,
                      proceed: () => dispatch(resetSeatSwapping())
                    })
                  )
                }}
                className={`btn--primary`}
                style={{
                  width: '100%'
                }}
                type='button'
              >
                Reset Seat Swapping
              </Button>
            </div>
          </div>
        </CardList>
      </div>
    </React.Fragment>
  )
}
