import { setError } from '../../ui/error/actions'
import { request } from '../../utils/request_handler'
import { getSportscasterBaseURL } from '../sportscaster/functions'
import { MQTTActionTypes } from './types'

const { SET_BROKERS } = MQTTActionTypes

export function getBrokers(config) {
  return (dispatch, getState) => {
    let baseURL = getSportscasterBaseURL(config)
    const success = (response) => {
      let brokers = response.data
        .filter((x) => {
          if (!x.Host) return false
          return true
        })
        .map((x) => {
          if (!x.SportscasterHost) x.SportscasterHost = config.SportscasterHost
          if (!x.WSSHost) {
            x.WSSHost = config.WSSHost
            x.protocol = config.protocol
            x.path = config.path
          }
          return x
        })

      dispatch({
        type: SET_BROKERS,
        payload: { brokers, selectedHost: config.WSSHost }
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get brokers from sportscaster. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: 'api/v2/brokers',
      method: 'get',
      baseURL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}
