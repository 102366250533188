import React, { JSXElementConstructor, ReactElement, useEffect } from 'react'
import { Field, FieldProps, Form, FormProps, FormSpy } from 'react-final-form'
import { Button } from '../../Button/Button'
import Checkbox from '../Fields/Checkbox/Checkbox'
import { FileField } from '../Fields/FileField/FileField'
import { ImageInput } from '../Fields/Image/Image'
import { Toggle } from '../Fields/Toggle/Toggle'
import { TeamSelectBar } from '../../TeamSelectBar/TeamSelectBar'
import { SearchableDropdown } from '../../SearchableDropdown/SearchableDropdown'
import { DatePickerWrapper } from '../Fields/DatePickerWrapper/DatePickerWrapper'
import { useTheme } from '../../../ui/config/hook'
import BasicSelect from '../../Material/Select'
import styles from './Form.module.scss'
import { Options } from '../../../metrics_server/data_types'
import Loader from '../../Loader/Loader'
import ColorPicker from '../../ColorPicker/ColorPicker'
import CustomTextarea from '../../Material/CustomTextarea'

function getOptionByValue<v>(options: any[], value: v) {
  return options.find((option) => option.value === value)
}

export interface FormContainerProps extends FormProps {
  valuesToChange?
  fields: FieldProps<any, any>[]
  title?: string | JSXElementConstructor<any>
  message?: string
  submitText?: string | ReactElement
  disableSubmit?: boolean
  hideButton?: boolean
  secondaryButtonText?: string
  secondaryButtonHandleClick?: () => void
  focusOnMount?: string
  formImage?: JSXElementConstructor<any>
  loading?: boolean
}

export const FormContainer: React.FC<FormContainerProps> = (props) => {
  return (
    <Form
      {...props}
      render={(formProps) => {
        return (
          <FormWrapper
            {...props}
            {...formProps}
            handleSubmit={(values) => {
              formProps.handleSubmit(values)
            }}
          />
        )
      }}
    />
  )
}

export const FormWrapper: React.FC<FormContainerProps> = (props) => {
  const {
    handleSubmit,
    title,
    message,
    submitText,
    disableSubmit,
    fields,
    children,
    hideButton,
    onChange,
    form,
    secondaryButtonText,
    secondaryButtonHandleClick,
    focusOnMount,
    loading
  } = props

  useEffect(() => {
    if (fields) {
      fields.forEach((field) => {
        if (field.data?.value !== undefined) {
          // console.log(field.name, field.data.value)
          form.change(field.name, field.data.value)
        }
      })
    }
  }, [fields])

  const theme = useTheme()

  return (
    <div className={styles.playerForm}>
      <div className={styles.pageSplitter}>
        <div>
          <div className={styles.formContainer}>
            <form onSubmit={handleSubmit}>
              <>
                {(title || message) && (
                  <div className='row-sm'>
                    {!!title && (
                      <>
                        {typeof title === 'string' ? <h4>{title}</h4> : title}
                      </>
                    )}
                    {message && <h5>{message}</h5>}
                  </div>
                )}
              </>
              <>
                {fields.map((field) => {
                  switch (field.type) {
                    case 'text':
                      return (
                        <div key={field.name} className='row-sm'>
                          <Field
                            name={field.name}
                            initialValue={field.initialValue}
                            formatOnBlur={field.formatOnBlur}
                            format={
                              field.format ? field.format : (value) => value
                            }
                            parse={field.parse ? field.parse : (value) => value}
                            render={(props) => {
                              return (
                                <BasicSelect
                                  {...props.input}
                                  selected={props.input.value || ''}
                                  onChange={props.input.onChange}
                                  label={field.label}
                                  required={field.required}
                                  variant='standard'
                                  type={field.textType}
                                  disallowedCharacters={
                                    field.disallowedCharacters
                                  }
                                />
                              )
                            }}
                          />
                        </div>
                      )
                    case 'select':
                      return (
                        <div key={field.name} style={field.style}>
                          <Field
                            key={field.name}
                            name={field.name}
                            options={field.options}
                            initialValue={field.initialValue}
                            render={(props) => (
                              <BasicSelect
                                {...props.input}
                                variant='standard'
                                selected={props.input.value}
                                label={field.label}
                                options={props.options}
                              />
                            )}
                          />
                        </div>
                      )
                    case 'checkbox':
                      return (
                        <div key={field.name}>
                          <Field
                            key={field.name}
                            name={field.name}
                            options={field.options}
                            initialValue={field.initialValue}
                            render={(props) => (
                              <Checkbox {...props} label={field.label} />
                            )}
                          />
                        </div>
                      )
                    case 'toggle':
                      return (
                        <div
                          key={field.name}
                          className='row-sm'
                          style={field.style}
                        >
                          <Field
                            name={field.name}
                            options={field.options}
                            initialValue={field.initialValue}
                            render={(props) => (
                              <Toggle
                                {...props}
                                size={field.size}
                                width={field.width}
                              />
                            )}
                          />
                        </div>
                      )
                    case 'img':
                      return (
                        <div key={field.name} className='row-sm'>
                          <Field
                            name={field.name}
                            initialValue={field.initialValue}
                            render={(props) => (
                              <ImageInput
                                {...props}
                                label={field.label || 'Profile Picture'}
                              />
                            )}
                            onChange={field.onImageChange}
                          />
                        </div>
                      )
                    case 'color':
                      return (
                        <div key={field.name} className='row-sm'>
                          <Field
                            name={field.name}
                            render={(props) => (
                              <ColorPicker
                                {...props}
                                value={props.input.value || ''}
                                label={field.label || 'Team Color'}
                                initialValue={field.initialValue}
                                onChange={props.input.onChange}
                              />
                            )}
                          />
                        </div>
                      )
                    case 'textarea':
                      return (
                        <div key={field.name} className='row-sm'>
                          <div className={styles.labelFieldContainer}>
                            <Field
                              name={field.name}
                              initialValue={field.initialValue}
                              render={({ input }) => (
                                <CustomTextarea
                                  input={input}
                                  label={field.label}
                                  style={{
                                    padding: '5px 5px 50px 5px',
                                    overflow: 'hidden'
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      )
                    case 'file':
                      return (
                        <div key={field.name} className='row-sm'>
                          <Field
                            name={field.name}
                            render={(props) => (
                              <FileField
                                onUpload={props.input.onChange}
                                img={props.input.value}
                              />
                            )}
                          ></Field>
                        </div>
                      )
                    case 'items':
                      return (
                        <div key={field.name} className='row-sm'>
                          <Field
                            name={field.name}
                            initialValue={field.initialValue}
                            render={(props) => (
                              <TeamSelectBar
                                teams={field.options}
                                addTeamCallback={field.addOption}
                                selectedTeamId={props.input.value}
                                disabledTeam={field.disabledValue}
                                disabledMessage={field.disabledMessage}
                                selectTeamCallback={(value) => {
                                  props.input.onChange(value)
                                }}
                                label={field.label}
                              />
                            )}
                          />
                        </div>
                      )
                    case 'searchable':
                      return (
                        <div key={field.name} className='row-sm'>
                          <Field
                            name={field.name}
                            initialValue={field.initialValue}
                            render={(props) => {
                              return (
                                <SearchableDropdown
                                  items={field.options}
                                  onSelect={(values) => {
                                    if (values) {
                                      props.input.onChange(values)
                                    }
                                  }}
                                  value={props.input.value}
                                  label={field.label}
                                  disabledValue={field.disabledValue}
                                  isMultiple={false}
                                  inputVariant={'outlined'}
                                  error={field.error}
                                  errorMessage={field.errorMessage}
                                />
                              )
                            }}
                          />
                        </div>
                      )
                    case 'date':
                      return (
                        <div key={field.name} className='row-sm'>
                          <Field
                            name={field.name}
                            render={(props) => {
                              return (
                                <DatePickerWrapper
                                  sessionDays={field.sessionDays}
                                  onChange={props.input.onChange}
                                  value={props.input.value}
                                  label={field.label}
                                />
                              )
                            }}
                          />
                        </div>
                      )
                    case 'tags':
                      return (
                        <div key={field.name} className='row-sm'>
                          <Field
                            name={field.name}
                            initialValue={field.initialValue}
                            render={(props) => (
                              <TeamSelectBar
                                teams={field.options}
                                addTeamCallback={field.addOption}
                                selectedTeamId={props.input.value}
                                disabledTeam={field.disabledValue}
                                disabledMessage={field.disabledMessage}
                                selectTeamCallback={(value) => {
                                  props.input.onChange(value)
                                }}
                                label={field.label}
                              />
                            )}
                          />
                        </div>
                      )
                    case 'link':
                      return (
                        <div className='row' key={field.name}>
                          <Button
                            handleClick={field.handleClick}
                            className='link-2'
                          >
                            {field.label}
                          </Button>
                        </div>
                      )
                    default:
                      return <noscript />
                  }
                })}
              </>
              <>{children && children}</>
              {!hideButton && (
                <div
                  style={{ marginTop: '30px' }}
                  className={`${styles.buttonsContainer}`}
                >
                  {loading ? (
                    <Loader color={theme.appColor} />
                  ) : (
                    <Button
                      type={'submit'}
                      className={'btn--primary'}
                      disabled={disableSubmit}
                      style={{ backgroundColor: theme.appColor }}
                    >
                      <h5>{submitText ? submitText : 'Save'}</h5>
                    </Button>
                  )}
                  <>
                    {secondaryButtonHandleClick && (
                      <Button
                        className={'btn--plain'}
                        handleClick={secondaryButtonHandleClick}
                      >
                        <h5>
                          {secondaryButtonText ? secondaryButtonText : 'Cancel'}
                        </h5>
                      </Button>
                    )}
                  </>
                </div>
              )}
              {onChange && (
                <FormSpy
                  onChange={(e) => {
                    if (e.dirty) onChange(e)
                  }}
                />
              )}
            </form>
          </div>
        </div>
        {props.FormImage && <props.FormImage />}
      </div>
    </div>
  )
}
