import { UnitsState, UnitSystem } from './types'
import {
  generateMetricTypeClass,
  MetricTypeGroup,
  MetricTypeKeys
} from '../metrics/data_types'
import { Sport } from '../sports/data_types'
import { getSport } from '../sports/functions'
import { CommonColumn, TableHeader } from '../../components/Table/Table.types'
import * as unitSystems from './unit_systems'

// Table functions
export function getMetricHeaders(
  keys: MetricTypeKeys[],
  flightMetrics: MetricTypeGroup,
  unitSystem: UnitSystem,
  width: number,
  maxLength: number
) {
  return keys.map((key) => {
    const metricInfo = generateMetricTypeClass(
      flightMetrics.items[key as MetricTypeKeys]
    )
    let name = 'Unknown'
    if (metricInfo) {
      name = metricInfo.getMetricDisplayWithUnits(unitSystem, maxLength)
    }
    const headers = {
      accessorKey: key,
      header: name,
      name,
      key,
      type: metricInfo?.props.format,
      decimal: metricInfo?.props.decimal,
      width,
      size: width,
      minSize: width
    }
    return headers
  })
}

// Get unit system
export function getUnitSystem(units: UnitsState, sport: Sport) {
  const selectedUnitSystem = units.systemOptions.find(
    (option) => option.value === units.selected
  )
  const sportUnitSystem = units.systemOptions.find(
    (option) => sport && option.value === sport.props.features.defaultUnitSystem
  )
  // Use default unit system if selected is 'Default' and sport has a default unit system that is available
  // Otherwise use selected unit system if available
  // Otherwise use SI (Always available)
  if (units.selected === 'Default' && sportUnitSystem) {
    return unitSystems[sportUnitSystem.value]
  } else if (selectedUnitSystem) {
    return unitSystems[selectedUnitSystem.value]
  } else {
    return unitSystems.SI
  }
}

// used to get unit system from session in various actions files
export function getUnitSystemFromSession(session, units) {
  const sport = getSport(session.sportType)
  const unitSystem = getUnitSystem(units, sport)

  return unitSystem
}
