import { RawTeamData } from '../teams/types'

export interface OrganisationsState {
  fetched: boolean
  hasValidData: boolean
  items: {
    [id: string]: Organisation
  }
  new: {
    name?: string
    logo?: string
  }
  selected: string
  checkedOrganisation: Organisation
}

export interface Organisation {
  id: string
  name: string
  logo: string
  threeWords: string[]
  teams?: RawTeamData[]
}

export const GET_ORGANISATIONS = 'GET_ORGANISATIONS'
export const CREATE_ORGANISATION = 'CREATE_ORGANISATION'
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION'
export const UPDATE_NEW_ORGANISATION = 'UPDATE_NEW_ORGANISATION'
export const CHECK_FOR_ORGANISATION = 'CHECK_FOR_ORGANISATION'
export const JOIN_ORGANISATION = 'JOIN_ORGANISATION'
