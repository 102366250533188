import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup
} from '../../data_types'
import { EventTypeJson, EventTypeProps } from '../data_types'
import { AussieRulesEventCrossSectionTypeGroup } from './cross-section/data_types'

export type AussieRulesEventFeatures = {
  test: boolean
  graph: boolean
}

export const defaultAussieRulesEventFeatures: AussieRulesEventFeatures = {
  test: false,
  graph: false
}

export const aussieRulesEventTypesConfig = {
  unknown: {
    name: 'Unknown',
    key: 'unknown',
    value: 0,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultAussieRulesEventFeatures
    }
  },
  goal: {
    name: 'Goal',
    key: 'goal',
    value: 1,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultAussieRulesEventFeatures
    }
  },
  behind: {
    name: 'Behind',
    key: 'behind',
    value: 2,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultAussieRulesEventFeatures
    }
  },
  nearGoalLine: {
    name: 'Near Goal Line',
    key: 'nearGoalLine',
    value: 3,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultAussieRulesEventFeatures
      }
    }
  },
  crossGoalLine: {
    name: 'Cross Goal Line',
    key: 'crossGoalLine',
    value: 4,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: {
        ...defaultAussieRulesEventFeatures
      }
    }
  },
  outOfBounds: {
    name: 'Out of Bounds',
    key: 'outOfBounds',
    value: 5,
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultAussieRulesEventFeatures
    }
  }
} as const

export type AussieRulesEventTypeProps = EventTypeProps<
  AussieRulesEventCrossSectionTypeGroup,
  AussieRulesEventFeatures
>

export type AussieRulesEventTypes = DataTypes<
  typeof aussieRulesEventTypesConfig
>

export type AussieRulesEventTypeKey = DataTypeKey<AussieRulesEventTypes>

export type AussieRulesEventTypeKeys = DataTypeKeys<AussieRulesEventTypeKey>

export type AussieRulesEventTypeValues = DataTypeValues<AussieRulesEventTypeKey>

export const getAussieRulesEventTypeGroup = (items: {
  [key in AussieRulesEventTypeKeys]?: AussieRulesEventTypes[key]
}) => {
  return getDataTypeGroup<
    AussieRulesEventTypeKeys,
    AussieRulesEventTypeValues,
    AussieRulesEventTypes,
    AussieRulesEventTypeProps
  >(items)
}

export type AussieRulesEventTypeGroup = ReturnType<
  typeof getAussieRulesEventTypeGroup
>

export type AussieRulesEventTypeConfig = DataTypeConfig<
  AussieRulesEventTypeKeys,
  AussieRulesEventTypeValues,
  AussieRulesEventTypeProps
>

export const aussieRulesEventTypes = getAussieRulesEventTypeGroup(
  aussieRulesEventTypesConfig
)

export type AussieRulesEventType = ReturnType<
  (typeof aussieRulesEventTypes)['getTypeByValue']
>

export type AussieRulesEventTypeJson = {
  [key in AussieRulesEventTypeKeys]?: EventTypeJson
}

export type AussieRulesEventJson = EventTypeJson<
  AussieRulesEventFeatures,
  AussieRulesEventTypeJson
>
