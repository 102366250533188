import React from 'react'

export interface DashboardIconProps {
  scale?: number
  color?: string
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  style?: React.CSSProperties
}

export const DashboardIcon = ({
  scale = 0.9,
  color = '#8CB09D',
  handleClick,
  style
}: DashboardIconProps) => {
  return (
    <div onClick={handleClick || undefined} style={style}>
      <svg
        width={`${26 * scale}`}
        height={`${23 * scale}`}
        viewBox='0 0 26 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13.48 9H24.04M1.96 14H12.52M13 2V21M1 1H25V22H1V1Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  )
}
