import { useCallback } from 'react'
import { useTeams } from '../../../metrics_server/teams/hooks'
import { toggleModal } from '../../../ui/modal/actions'
import TeamForm from '../../Teams/TeamForm/TeamForm'
import { editTeam } from '../../../metrics_server/teams/actions'
import { useAppDispatch } from '../../../store/hooks'

export const useEditTeamModal = () => {
  const dispatch = useAppDispatch()
  const { selectedTeam, rawData } = useTeams()

  const openEditTeamModal = useCallback(() => {
    const team = rawData[selectedTeam]
    dispatch(
      toggleModal({
        active: true,
        type: 'form',
        wrapper: true,
        width: 'auto',
        handleProceed: () => {
          dispatch(toggleModal({}))
        },
        ChildComponent: () => (
          <TeamForm
            team={team}
            newTeam={false}
            handleProceed={(values) => {
              dispatch(editTeam(selectedTeam, values))
              dispatch(toggleModal({}))
            }}
            handleSecondaryBtn={() => {
              dispatch(toggleModal({}))
            }}
          />
        ),
        className: 'modalSmall'
      })
    )
  }, [rawData[selectedTeam], selectedTeam])

  return { openEditTeamModal }
}
