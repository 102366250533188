import React, { useState } from 'react'
import { Button } from '../../../components/Button/Button'
import styles from '../styles.module.scss'
import { diagnosticsPath } from '../../Diagnostics/config'

type NetworkProps = {
  setRedirect: (pathname: string) => void
  forceSync: () => void
}

export function Network({ setRedirect, forceSync }: NetworkProps) {
  const [wifiName, setWifiName] = useState<string>('Sportable 5G')
  const [wifiPassword, setWifiPassword] = useState<string>('password')
  const [serverIP, setServerIP] = useState<string>('192.168.1.125')

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'wifiName':
        setWifiName(e.target.value)
        break
      case 'wifiPassword':
        setWifiPassword(e.target.value)
        break
      case 'serverIP':
        setServerIP(e.target.value)
        break
      default:
        return
    }
  }

  return (
    <div className={styles.settingsCard}>
      <div className={styles.row}>
        <h1 className={styles.cardTitle}>Network</h1>
      </div>
      {/* <div className={`${styles.row} space-between`}>
        <div style={{width: '45%'}}>
          <TextInput input={{onChange: handleChange, name: 'wifiName', value: wifiName}} type='text' label='Wifi name' />
        </div>
        <div style={{width: '45%'}}>
          <TextInput input={{onChange: handleChange, name: 'wifiPassword', value: wifiPassword}} type='password' label='Password' />
        </div>
      </div>
      <div className={`${styles.row} flex-start`}>
        <Button className='btn--primary' handleClick={() => {}}>
            Update Wifi
        </Button>
      </div>
      <div className={`${styles.row} flex-start`}>
        <div style={{width: '45%'}}>
          <TextInput input={{onChange: handleChange, name: 'serverIP', value: serverIP}} type='text' label='Server IP' />
        </div>
      </div>
      <div className={`${styles.row} flex-start`}>
        <Button className='btn--primary' handleClick={() => {}}>
            Switch Server
        </Button>
      </div> */}
      <div className={`${styles.row} flex-start`}>
        <Button className='btn--primary' handleClick={forceSync}>
          Force Sync
        </Button>
      </div>
      <div className={styles.row}>
        <h5
          className={styles.delete}
          onClick={() => {
            setRedirect(diagnosticsPath)
          }}
        >
          View Diagnostics
        </h5>
      </div>
    </div>
  )
}
