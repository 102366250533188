import React, { useCallback, useMemo } from 'react'
import { useAppDispatch } from '../../../../store/hooks'
import { Button } from '../../../../components/Button/Button'
import { ConfirmationRequest } from '../../../../components/ConfirmationRequest/ConfirmationRequest'
import {
  createTimeEvent,
  endTimeEvent
} from '../../../../metrics_server/events/actions'
import { useEvents } from '../../../../metrics_server/events/hooks'
import { timeEventTypes } from '../../../../metrics_server/events/time/data_types'
import { useTimeEvents } from '../../../../metrics_server/events/time/hooks'
import { TimeEventData } from '../../../../metrics_server/events/time/types'
import { useSession } from '../../../../metrics_server/sessions/hooks'
import { toggleModal } from '../../../../ui/modal/actions'
import styles from '../Coding.module.scss'
import { useEventsFilters } from '../../../../metrics_server/events/filter'
import { isSportType } from '../../../../metrics_server/sports/data_types'

interface HalfControlsProps {}

export const PeriodControls = ({}: // Period
HalfControlsProps) => {
  // Redux //
  const events = useEvents()
  const dispatch = useAppDispatch()
  // ====== //

  // Session //
  const { session, sport } = useSession()
  const {
    currentPeriod,
    currentPeriodTimeEventType,
    nextPeriodTimeEventType,
    inPlay,
    fullTime,
    playStopped
  } = useTimeEvents()
  // ======= //

  const startHalf = (type) => {
    dispatch(createTimeEvent({ sessionId: session.id, type }))

    // TODO: This can be refactored - ideally we don't want any conditionals like if rugby or if cfl  - be setting this sort of stuff in the sport config
    // This particular one could be called something like `periodStartsStopped`
    if (
      isSportType.canadianFootball(sport) ||
      isSportType.americanFootball(sport)
    ) {
      if (!playStopped) {
        dispatch(
          createTimeEvent({
            sessionId: session.id,
            type: timeEventTypes.items.stoppage.value
          })
        )
      }
    }
  }

  const timeEvents = useEventsFilters({}, ['time'])

  const endStoppageByHalfEnd = useCallback(() => {
    const mostRecentTimeEvent =
      timeEvents.filteredEvents[timeEvents.filteredEvents.length - 1]

    if (
      timeEventTypes.isType('stoppage', mostRecentTimeEvent.type) &&
      !mostRecentTimeEvent.timeEnd
    ) {
      dispatch(
        endTimeEvent(
          mostRecentTimeEvent.eventId,
          { ...mostRecentTimeEvent },
          ''
        )
      )
    }

    return undefined
  }, [timeEvents.filteredEvents])

  const endPeriod = useCallback(
    (type) => {
      dispatch(
        toggleModal({
          active: true,
          type: 'confirm',
          handleProceed: () => {
            // TODO: This can be refactored - ideally we don't want any conditionals like if rugby or if cfl  - be setting this sort of stuff in the sport config
            // This particular one could be called something like `periodStartsStopped`
            if (
              isSportType.canadianFootball(sport) ||
              isSportType.americanFootball(sport)
            ) {
              if (!playStopped) {
                dispatch(
                  createTimeEvent({
                    sessionId: session.id,
                    type: timeEventTypes.items.stoppage.value
                  })
                )
              }
              dispatch(
                endTimeEvent(currentPeriod.eventId, { ...currentPeriod })
              )
            } else {
              dispatch(
                endTimeEvent(currentPeriod.eventId, { ...currentPeriod })
              )

              endStoppageByHalfEnd()
            }
            dispatch(toggleModal({}))
          },
          ChildComponent: ConfirmationRequest,
          message: 'Are you sure?',
          className: 'modalSmall',
          handleClose: () => {
            dispatch(toggleModal({}))
          }
        })
      )
    },
    [currentPeriod]
  )

  return (
    <>
      {/* <h5>Match time:</h5> */}
      {!fullTime ? (
        <div className={styles.halfControls}>
          <div>
            {/* <div className={styles.time}>
          {currentPeriod ? (
            <p>
              {milToFormat(currentPeriod.startTime * 1000, {
                colon: true,
                seconds: true
              })}
            </p>
          ) : (
            <p>-- : -- : --</p>
          )}
        </div> */}
            <Button
              disabled={
                currentPeriod &&
                !!currentPeriod.startTime &&
                !currentPeriod.timeEnd
              }
              className='btn--primary'
              handleClick={() => startHalf(nextPeriodTimeEventType.value)}
            >
              Start{' '}
              {!currentPeriodTimeEventType || !inPlay
                ? nextPeriodTimeEventType.name
                : currentPeriodTimeEventType.name}
            </Button>
          </div>
          <div>
            <Button
              disabled={
                !currentPeriod ||
                (currentPeriod &&
                  !!currentPeriod.startTime &&
                  !!currentPeriod.timeEnd)
              }
              className='btn--primary'
              handleClick={endPeriod}
            >
              End{' '}
              {!currentPeriodTimeEventType || !inPlay
                ? nextPeriodTimeEventType.name
                : currentPeriodTimeEventType.name}
            </Button>
          </div>
          <div>
            {currentPeriod?.timeEnd || !inPlay
              ? nextPeriodTimeEventType.name
              : currentPeriodTimeEventType.name}
          </div>
        </div>
      ) : (
        <div>Full-time</div>
      )}
    </>
  )
}
