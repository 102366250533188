import { SportTypeKey } from '../data_types'
import {
  Coordinate,
  Pitch,
  PitchCoordinates,
  PitchDimensions
} from '../../pitches/types'
import { sportTypeKey } from '..'

export interface DemonstrationPitch extends Pitch {
  type: SportTypeKey['demonstration']
  pitch: DemonstrationPitchDimensions
}

export interface DemonstrationPitchDimensions extends PitchDimensions {
  length: number
  width: number
  coordinates: DemonstrationPitchCoordinates
}

export interface DemonstrationPitchCoordinates extends PitchCoordinates {
  P1: Coordinate
  P2: Coordinate
  P3: Coordinate
  P4: Coordinate
}

const coordinates = {
  P1: {
    x: -1.060635538928798,
    y: 5.803929840453573,
    z: 0.09953638569796995
  },
  P2: {
    x: -1.088226400019195,
    y: -10.1286099207204865,
    z: 0.1001403165802354
  },
  P3: {
    x: 22.672721803883253,
    y: -10.1717574590171695,
    z: -0.10075478391766837
  },
  P4: {
    x: 22.672721803883253,
    y: 5.803929840453573,
    z: 0
  }
}

export const defaultPitch: DemonstrationPitch = {
  id: '0',
  name: 'Default Demonstration Pitch',
  type: sportTypeKey.demonstration,
  anchors: null,
  arcs: null,
  pitch: {
    length: 0,
    width: 0,
    coordinates: coordinates
  }
}
