import {
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS_HISTORY,
  CLEAR_NOTIFICATION_IN_HISTORY,
  TOGGLE_NOTIFICATIONS
} from './types'
import { REMOVE_USER_DATA } from '../types'
import { NotificationsState } from './types'

const initialState: NotificationsState = {
  popUpNotifications: [],
  allNotificationsHistory: [],
  on: false
}

export function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const addNotificationState = {
        ...state,
        popUpNotifications: [...state.popUpNotifications, action.payload],
        allNotificationsHistory: [
          ...state.allNotificationsHistory,
          action.payload
        ]
      }

      // filter out identical download notifications and diagnostic notifications
      const filteredNotifications =
        addNotificationState.allNotificationsHistory.filter((v, i, a) => {
          if (v.arrivalTime) {
            return (
              a.findIndex(
                (t) => t.arrivalTime === v.arrivalTime && t.text === v.text
              ) === i
            )
          } else {
            return (
              a.findIndex(
                (t) => t.title === v.title && t.description === v.description
              ) === i
            )
          }
        })

      const filteredPopUpNotifications =
        addNotificationState.popUpNotifications.filter(
          (item, index) => index < 1
        )

      const filteredState = {
        ...state,
        popUpNotifications: [filteredPopUpNotifications, action.payload],
        allNotificationsHistory: [...filteredNotifications]
      }

      return filteredState
    }

    // clear pop-up notification
    case CLEAR_NOTIFICATIONS:
      return { ...state, popUpNotifications: [] }

    // clear all notifications in notificationsList
    case CLEAR_NOTIFICATIONS_HISTORY:
      return { ...state, allNotificationsHistory: [] }

    // clear single pop-up notification
    case CLEAR_NOTIFICATION: {
      const newState = state.popUpNotifications.filter((notification) => {
        return notification.title !== action.payload.title
      })
      return { ...state, popUpNotifications: [...newState] }
    }

    // clear single notification in notificationList
    case CLEAR_NOTIFICATION_IN_HISTORY: {
      const newHistoricalState = state.allNotificationsHistory.filter(
        (notification) => {
          return notification.title !== action.payload.title
        }
      )
      return { ...state, allNotificationsHistory: [...newHistoricalState] }
    }

    case TOGGLE_NOTIFICATIONS:
      return {
        ...state,
        on: action.payload
      }

    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
