export type NotificationsState = {
  on: boolean
  popUpNotifications: Array<NotificationType>
  allNotificationsHistory: Array<NotificationType>
}

export interface NotificationType {
  text?: string
  type?: string
  deviceType?: string
  timestamp?: number
  title?: string
  description?: string
  arrivalTime: number
}

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'
export const CLEAR_NOTIFICATIONS_HISTORY = 'CLEAR_NOTIFICATIONS_HISTORY'
export const CLEAR_NOTIFICATION_IN_HISTORY = 'CLEAR_NOTIFICATION_IN_HISTORY'
export const TOGGLE_NOTIFICATIONS = 'TOGGLE_NOTIFICATIONS'
