import React, { useState } from 'react'
import { FormContainer } from '../Form/Form'

const validate = (values) => {
  const errors = {} as any
  if (!values.name) {
    errors.name = 'Required'
  }
  return errors
}

export interface NameFormProps {
  handleSubmit: (values) => void
  submitText?: string
}

export const NameForm = ({ handleSubmit, submitText }: NameFormProps) => {
  const fields = [
    {
      name: 'name',
      type: 'text',
      label: 'Name'
    }
  ]

  return (
    <React.Fragment>
      <FormContainer
        fields={fields}
        validate={validate}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
        submitText={submitText ? submitText : 'Create'}
      />
    </React.Fragment>
  )
}
