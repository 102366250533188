import React from 'react'

interface SuccessIconProps {
  // scale?: number
  color?: string
}

export const SuccessIcon: React.FC<SuccessIconProps> = ({
  // scale = 1,
  color = '#4DBD33'
}) => {
  return (
    <div>
      <svg
        className={'success_tick'}
        width='50px'
        height='50px'
        viewBox='0 0 50 50'
      >
        <g
          id='Page-1'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g id='Group-2' fillRule='nonzero'>
            <circle id='Oval' fill={color} cx='25' cy='25' r='25' />
            <path
              d='M7.87943358,27.3260243 L21.044752,27.2797536 C23.0503281,27.2727048 24.6818831,28.8928313 24.6889318,30.8984075 C24.6889617,30.9069162 24.6889617,30.9154248 24.6889318,30.9239335 L24.6889318,30.9239335 C24.6818188,32.9478018 23.0429143,34.5867062 21.019046,34.5938193 L7.85372761,34.6400901 C5.84815142,34.6471389 4.21659651,33.0270123 4.20954772,31.0214361 C4.20951782,31.0129275 4.20951782,31.0044188 4.20954772,30.9959102 L4.20954772,30.9959102 C4.2166608,28.9720418 5.85556524,27.3331374 7.87943358,27.3260243 Z'
              id='Rectangle-Copy'
              fill='#FFFFFF'
              transform='translate(14.449240, 30.959922) rotate(45.000000) translate(-14.449240, -30.959922) '
            />
            <path
              d='M15.5327142,22.785214 L42.0173313,22.8782966 C44.0411996,22.8854097 45.6801041,24.5243142 45.6872171,26.5481825 L45.6872171,26.5481825 C45.6942659,28.5537587 44.0741394,30.1853136 42.0685632,30.1923624 C42.0600545,30.1923923 42.0515459,30.1923923 42.0430372,30.1923624 L15.5584202,30.0992797 C13.5345518,30.0921667 11.8956474,28.4532622 11.8885343,26.4293939 L11.8885343,26.4293939 C11.8814855,24.4238177 13.5016121,22.7922628 15.5071883,22.785214 C15.5156969,22.7851841 15.5242056,22.7851841 15.5327142,22.785214 Z'
              id='Rectangle'
              fill='#FFFFFF'
              transform='translate(28.787876, 26.488788) rotate(-45.000000) translate(-28.787876, -26.488788) '
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
