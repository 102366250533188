import { useMemo } from 'react'
import {
  Filter,
  FilterMultiple,
  SearchStrFilter,
  useFilterReducer
} from '../../hooks/filter'
import { useUserFormattedTeams } from '../teams/hooks'
import { FormattedPlayer } from './types'
import { Group, addItemToGroup } from '../types'
import { getEmptyGroup } from '../sessions/functions'
import { useFormattedSession } from '../sessions/hooks'

export type PlayerFilters = {
  searchStr: SearchStrFilter
  team: FilterMultiple<string | 'All'>
  assigned: Filter<'all' | 'assigned' | 'notAssigned'>
}

export const usePlayerFilter = (
  initialValues: Partial<{
    [key in keyof PlayerFilters]: PlayerFilters[key]['value']
  }>,
  sessionId: string
) => {
  const teams = useUserFormattedTeams()
  const formattedSession = useFormattedSession(sessionId)

  const initialPlayerFilterState: PlayerFilters = {
    searchStr: {
      value: initialValues.searchStr || ''
    },
    team: {
      options: teams.optionsWithAll,
      value: initialValues.team || []
    },
    assigned: {
      options: [
        { name: 'All', value: 'all' },
        { name: 'Assigned', value: 'assigned' },
        { name: 'Not Assigned', value: 'notAssigned' }
      ],
      value: initialValues.assigned || 'all'
    }
  }

  const { filters, updateFilterValue } = useFilterReducer<PlayerFilters>(
    initialPlayerFilterState
  )

  const filteredPlayers = useMemo(() => {
    // Filter by team //
    const filteredTeams = teams.list.filter((team) => {
      return (
        filters.team.value.includes(team.id) || filters.team.value.length === 0
      )
    })

    const filteredPlayers = getEmptyGroup<FormattedPlayer>()

    filteredTeams.forEach((team) => {
      team.players.list
        // Filter by assigned (used for tag assignment) //
        .filter((player) => {
          const playerSession =
            formattedSession.playersSessions.byPlayerId.map[player.id]
          if (filters.assigned.value === 'all') return true
          if (filters.assigned.value === 'assigned') return playerSession
          if (filters.assigned.value === 'notAssigned') return !playerSession
        })
        // Filter by search string
        .filter((player) => {
          if (filters.searchStr.value === '') return true
          const fullName = (
            (player.firstName || '') +
            ' ' +
            (player.lastName || '')
          ).toLowerCase()

          const number = player.number

          if (number === parseInt(filters.searchStr.value)) {
            return true
          }

          return fullName.includes(filters.searchStr.value.toLowerCase())
        })
        // Add item to group
        .forEach((player) => {
          addItemToGroup(
            filteredPlayers,
            player,
            player.id,
            'id',
            player.fullName
          )
        })
    })

    return filteredPlayers
  }, [filters, formattedSession, teams])

  return {
    filteredPlayers,
    filters,
    updateFilterValue
  }
}
