import React from 'react'
import styles from './AnchorIcon.module.scss'
import { sportableColors } from '../../../constants/sportableColors'

export interface AnchorIconProps {
  scale?: number
  color?: string
}

export const AnchorIcon = ({ scale = 1, color }: AnchorIconProps) => {
  return (
    <div>
      <svg
        width={`${29 * scale}`}
        height={`${51 * scale}`}
        viewBox='0 0 29 51'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_165:874)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.3394 24.0592C14.3394 24.8998 13.7188 25.6052 12.9003 25.6949L1.78366 26.9142C0.83125 27.0187 0.000488281 26.2566 0.000488281 25.2785L0.000488281 4.87758C0.000488281 4.44753 0.165546 4.03427 0.460282 3.72695L3.55941 0.493882C3.86249 0.178011 4.27748 -0.000323861 4.71074 -0.000323861L12.7283 -0.000323861C13.6181 -0.000323861 14.3394 0.736379 14.3394 1.64484L14.3394 24.0592Z'
            fill={sportableColors.colors.sportableGreyMid}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.3614 27.1077C14.3614 26.1991 13.64 25.4625 12.7502 25.4625L1.63357 25.4625C0.743779 25.4625 0.0224609 26.1991 0.0224609 27.1077L0.0224609 46.122C0.0224609 46.5522 0.187518 46.9653 0.482257 47.2728L3.58138 50.5057C3.88446 50.8218 4.29945 51 4.73271 51H12.7502C13.64 51 14.3614 50.2634 14.3614 49.3548L14.3614 27.1077Z'
            fill={sportableColors.colors.sportableGreyMid}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.2379 26.8355C14.2965 26.041 14.9043 25.4035 15.6805 25.3224L26.903 24.1502C27.8527 24.051 28.678 24.812 28.678 25.7868L28.678 46.122C28.678 46.5522 28.513 46.9653 28.2183 47.2728L25.1191 50.5057C24.816 50.8218 24.401 51 23.9678 51H14.1932C13.2558 51 12.5163 50.1859 12.5867 49.2313L14.2379 26.8355Z'
            fill={sportableColors.colors.sportableGreyMid}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.227 24.0154C14.2902 24.8737 14.9906 25.5371 15.8335 25.5371L27.0669 25.5371C27.9567 25.5371 28.678 24.8005 28.678 23.8919L28.678 4.87757C28.678 4.44752 28.513 4.03426 28.2183 3.72694L25.1191 0.493871C24.816 0.178 24.401 -0.000335601 23.9678 -0.000335601L14.1932 -0.000335601C13.2558 -0.000335601 12.5163 0.81369 12.5867 1.76821L14.227 24.0154Z'
            fill={sportableColors.colors.sportableGreyMid}
          />
          <path
            d='M12.2446 50.671V51H14.8224V50.671H12.2446Z'
            fill={sportableColors.colors.sportableGreyMid}
          />
          <path
            d='M0.000488281 23.6905L0.000488281 28.626H0.644933L0.644933 23.6905H0.000488281Z'
            fill={sportableColors.colors.sportableGreyMid}
          />
          <path
            d='M28.0337 21.7159V26.6514H28.6781V21.7159H28.0337Z'
            fill={sportableColors.colors.sportableGreyMid}
          />
          <path
            d='M12.2446 0.000137329V0.658203L14.8224 0.658203V0.000137329L12.2446 0.000137329Z'
            fill={sportableColors.colors.sportableGreyMid}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.3387 23.8929C14.3387 24.8015 13.6174 25.5381 12.7276 25.5381L4.18875 25.5381C3.29896 25.5381 2.57764 24.8015 2.57764 23.8929L2.57764 7.31651C2.57764 6.90752 2.72683 6.51301 2.99611 6.21031L5.40597 3.50106C5.71134 3.15755 6.14441 2.96177 6.59862 2.96177L12.7276 2.96177C13.6174 2.96177 14.3387 3.69847 14.3387 4.60693L14.3387 23.8929Z'
            fill='#C6C6C6'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.8788 26.3027C14.8649 25.416 14.1652 24.7002 13.2969 24.6843L4.21815 24.5178C3.31719 24.5013 2.57813 25.2426 2.57813 26.1627L2.57813 43.6844C2.57813 44.0934 2.72731 44.4877 2.9966 44.7905L5.40648 47.5C5.71181 47.8433 6.14491 48.0391 6.59912 48.0391H13.5841C14.484 48.0391 15.2094 47.2863 15.195 46.3674L14.8788 26.3027Z'
            fill='#C6C6C6'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.3389 26.9989C14.3389 26.134 14.9946 25.4168 15.8396 25.3576L24.3784 24.7586C25.3095 24.6932 26.1 25.4469 26.1 26.3999L26.1 43.6844C26.1 44.0934 25.9508 44.4878 25.6815 44.7905L23.2716 47.5C22.9663 47.8433 22.5332 48.0391 22.079 48.0391H15.95C15.0602 48.0391 14.3389 47.3025 14.3389 46.3939L14.3389 26.9989Z'
            fill='#C6C6C6'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.2821 23.953C14.3138 24.8376 15.0253 25.5381 15.8922 25.5381L24.4889 25.5381C25.3787 25.5381 26.1 24.8015 26.1 23.8929L26.1 7.31651C26.1 6.90752 25.9508 6.51301 25.6816 6.21031L23.2717 3.50106C22.9663 3.15755 22.5332 2.96177 22.0791 2.96177H15.2014C14.2886 2.96177 13.5581 3.73565 13.5914 4.66714L14.2821 23.953Z'
            fill='#C6C6C6'
          />
          <path
            d='M19.6559 6.58105H20.6226V3.61976L19.6559 3.61976V6.58105Z'
            fill='#C4C4C4'
          />
          <path
            d='M9.34465 27.3096L19.6558 27.3096V22.7031L9.34465 22.7031L9.34465 27.3096Z'
            fill='#C6C6C6'
          />
          <path
            d='M2.57813 23.6901L2.57812 26.6514H3.22257L3.22257 23.6901H2.57813Z'
            fill='#C4C4C4'
          />
          <path
            d='M16.7559 23.361V26.3223H17.4003V23.361H16.7559Z'
            fill='#C4C4C4'
          />
          <path
            d='M25.4556 26.3223V23.361H26.1V26.3223H25.4556Z'
            fill='#C4C4C4'
          />
          <path
            d='M12.5669 2.96129V4.60645L15.1447 4.60645V2.96129L12.5669 2.96129Z'
            fill='#C4C4C4'
          />
          <path
            d='M13.2114 46.3939V48.0391H15.7892V46.3939H13.2114Z'
            fill='#C4C4C4'
          />
          <path
            d='M13.0165 21.4189H15.5881C16.4779 21.4189 17.1992 20.6824 17.1992 19.7738L17.1992 13.2536C17.1992 12.345 16.4779 11.6084 15.5881 11.6084L13.0165 11.6084C12.1267 11.6084 11.4054 12.345 11.4054 13.2536L11.4054 19.7738C11.4054 20.6824 12.1267 21.4189 13.0165 21.4189Z'
            fill='#D8D8D8'
            stroke={sportableColors.colors.sportableGreyMid}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.59062 43.8381C6.42822 43.8381 6.37602 43.6004 6.38163 43.2736C6.38434 42.8954 6.51919 42.755 6.65111 42.7576V42.2633C6.24411 42.2687 5.97998 42.6901 5.97998 43.3086C5.97998 43.9056 6.21923 44.3081 6.6126 44.3081C7.43807 44.3081 7.00059 42.6685 7.37466 42.6685C7.54537 42.6685 7.59486 42.9466 7.59193 43.3086C7.58661 43.6976 7.45173 43.8597 7.32249 43.857V44.3485C7.72681 44.3566 7.99384 43.9273 7.99384 43.2681C7.99384 42.6307 7.75439 42.2012 7.35832 42.2012C6.55212 42.2012 6.95109 43.8381 6.59062 43.8381Z'
            fill={sportableColors.colors.sportableGreyDark}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.80584 40.522V41.3578H6.37999V40.522C6.37999 40.3608 6.44627 40.2855 6.59572 40.2855C6.74539 40.2855 6.80584 40.3608 6.80584 40.522ZM5.97998 40.4549V41.8174H7.99384V41.3551H7.20842V40.4549C7.20842 40.0921 7.00413 39.8233 6.59562 39.8233C6.25896 39.8233 5.97998 40.0652 5.97998 40.4549Z'
            fill={sportableColors.colors.sportableGreyDark}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.73376 37.4145L6.33952 37.4171V36.4851C6.33952 36.3262 6.39994 36.2521 6.54378 36.2521C6.67051 36.2521 6.73376 36.3236 6.73376 36.4851V37.4145ZM6.97814 36.583V36.3923C6.97814 36.0348 6.79402 35.7966 6.47167 35.7966C6.16385 35.7966 5.93945 36.0084 5.93945 36.3923V37.8672H7.95331V37.4117L7.11892 37.4144V36.9325C7.11892 36.6227 7.28286 36.5327 7.95331 36.1885V35.643C7.29743 35.9501 7.00975 36.1381 6.97814 36.583Z'
            fill={sportableColors.colors.sportableGreyDark}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.93945 33.4184V35.6426H6.35947V34.7642H7.95331V34.2994H6.35947V33.4184H5.93945Z'
            fill={sportableColors.colors.sportableGreyDark}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.19138 32.8078L6.38579 32.383L7.19138 31.9582V32.8078ZM5.97998 32.6407L7.99384 33.7637V33.23L7.61133 33.0283V31.7406L7.99384 31.5388V31.0026L5.97998 32.1256V32.6407Z'
            fill={sportableColors.colors.sportableGreyDark}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.53068 29.4383V30.3493L7.11627 30.3521V29.4383C7.11627 29.2939 7.18255 29.2031 7.32639 29.2031C7.47304 29.2031 7.53068 29.2939 7.53068 29.4383ZM6.35941 29.4969C6.35941 29.3527 6.41115 29.2618 6.55503 29.2618C6.696 29.2618 6.74501 29.3527 6.74501 29.4969V30.3519L6.35941 30.3547V29.4969ZM6.92916 29.3205C6.90902 29.0053 6.76798 28.8022 6.47167 28.8022C6.16385 28.8022 5.93945 29.0159 5.93945 29.4033V30.8115H7.95331V29.3446C7.95331 28.9811 7.74039 28.7407 7.41829 28.7407C7.10464 28.7407 6.93783 28.9705 6.92916 29.3205Z'
            fill={sportableColors.colors.sportableGreyDark}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.97998 27.9399V28.3955H7.99384V26.7082H7.57382V27.9399H5.97998Z'
            fill={sportableColors.colors.sportableGreyDark}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.99384 26.5547V24.714H7.57382V26.0904H7.16808V24.7383H6.77407V26.0904H6.4V24.714H5.97998V26.5547H7.99384Z'
            fill={sportableColors.colors.sportableGreyDark}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.07799 38.821C6.07889 38.822 6.08279 38.8264 6.08382 38.8277C6.11295 38.8626 6.13038 38.9069 6.13038 38.9553C6.13038 39.0037 6.11295 39.0481 6.08382 39.0829C6.08279 39.0842 6.07889 39.0887 6.07799 39.0897C5.94797 39.2364 5.73598 39.332 5.49657 39.332C5.25677 39.332 5.04452 39.2361 4.91457 39.0889C4.91435 39.0887 4.91067 39.0845 4.91045 39.0842C4.88067 39.0491 4.86282 39.0043 4.86282 38.9553C4.86282 38.9064 4.88067 38.8615 4.91045 38.8264C4.91067 38.8262 4.91435 38.8219 4.91457 38.8217C5.04452 38.6745 5.25677 38.5786 5.49657 38.5786C5.73598 38.5786 5.94797 38.6742 6.07799 38.821ZM5.40615 38.1813C5.08699 38.2029 4.79699 38.3383 4.60037 38.561L4.59354 38.5689L4.59232 38.5703L4.59106 38.5717C4.49958 38.6795 4.44922 38.8158 4.44922 38.9555C4.44922 39.0952 4.49958 39.2314 4.59106 39.3393L4.59219 39.3405L4.59335 39.3419L4.59983 39.3493C4.79696 39.5726 5.08699 39.7081 5.40615 39.7298L5.42236 46.8072L5.60854 47.0322L5.58989 39.7295C5.90715 39.7072 6.19541 39.5723 6.39138 39.3511C6.39586 39.346 6.4025 39.3383 6.40472 39.3357C6.49436 39.2284 6.54363 39.0934 6.54363 38.9555C6.54363 38.8175 6.49433 38.6825 6.40482 38.5754C6.40269 38.5728 6.39589 38.565 6.39135 38.5599C6.19544 38.3386 5.90715 38.2038 5.58989 38.1815L5.577 3.92903L5.41589 4.08236L5.40615 38.1813Z'
            fill={sportableColors.colors.sportableGreyDark}
          />
        </g>
        <defs>
          <clipPath id='clip0_165:874'>
            <rect
              width='51'
              height='29'
              fill='white'
              transform='translate(0 51) rotate(-90)'
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
