// Auto Sleep

import { API_ROOT_URL } from '../../const'
import { ErrorState } from '../../ui/error/types'
import { request } from '../../utils/request_handler'

export function getAutoSleep(
  handleError: (error: ErrorState) => void,
  handleSuccess: (response) => void
) {
  const options = {
    url: `config/autosleep`,
    method: 'get',
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  const error = (error, errorType) => {
    return handleError({
      message:
        error.response && error.response.data && error.response.data[errorType]
          ? error.response.data[errorType]
          : `Failed to get auto sleep state. API request failed. Check log for more detail.`
    })
  }
  return request(options, handleSuccess, error)
}

export function updateAutoSleep(
  handleError: (error: ErrorState) => void,
  handleSuccess: (response) => void,
  data
) {
  const options = {
    url: `config/autosleep`,
    data,
    method: 'patch',
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  const error = (error, errorType) => {
    return handleError({
      message:
        error.response && error.response.data && error.response.data[errorType]
          ? error.response.data[errorType]
          : `Failed to update auto sleep state. API request failed. Check log for more detail.`
    })
  }
  return request(options, handleSuccess, error)
}
