import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import Loader from './Loader'

import * as loadingActions from '../../ui/loading/actions'
import { LoadingState } from '../../ui/loading/types'

const actions = _.assign({}, loadingActions)

interface LoadingWrapperProps {
  id: string
  message: string
  loading: LoadingState
  children: React.ReactNode
}

export class LoadingWrapper extends Component<LoadingWrapperProps> {
  render() {
    const { loading, id, message, children } = this.props
    return (
      <React.Fragment>
        {loading.indexOf(id) >= 0 ? <Loader message={message} /> : children}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.loading
  }
}

export default connect(mapStateToProps, actions)(LoadingWrapper)
