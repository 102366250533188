import { PlayerKeyItemType } from './PlayerKeyItem'

export const usePlayerItemLogic = (
  item: PlayerKeyItemType,
  playerIds: Array<string | undefined>,
  onUpdate: (playerIds: string, newState: any, item: PlayerKeyItemType) => void
) => {
  let checked = playerIds.some((playerId) => {
    return playerId === item.id
  })

  const updatePlayerIds = (bool) => {
    let newState
    if (checked) {
      newState = playerIds.filter((playerId) => playerId !== item.id)
    } else {
      newState = [...playerIds, item.id]
    }
    onUpdate('playerIds', newState, item)
  }

  return { checked, updatePlayerIds }
}
