import {
  Anchor,
  Anchors,
  PitchArcs,
  PitchCoordinates,
  PitchTypes,
  RawPitchData
} from '../pitches/types'
import { AppTeam, FormattedTeam, RawTeamData, Team } from '../teams/types'
import { TimeEventData } from '../events/time/types'
import { GameEventData } from '../events/game/types'
import { FormattedPlayer, PlayerData, RawPlayerData } from '../players/types'
import { Options } from '../data_types'
import { FlightEventTypeGroup } from '../events/flight/data_types'
import { MetricTypeGroup } from '../metrics/data_types'
import { SessionType, SessionTypeValues } from './data_types'
import { SubSessionType, SubSessionTypeValues } from './sub_sessions/data_types'
import { SessionModeType, SessionModeTypeValues } from './modes/data_types'
import { Sport, SportTypeValues } from '../sports/data_types'
import { GameEventTypeGroup } from '../events/game/data_types'
import { TimeEventTypeGroup } from '../events/time/data_types'
import { AussieRulesEventTypeGroup } from '../events/aussie_rules/data_types'
import { EventTypeGroup } from '../events/data_types'
import { Group, IDMap } from '../types'
import { FormattedDevice, HardwareProductNames } from '../hardware/types'
import { DeviceTypeValues } from '../hardware/data_types'
import { SessionStateType, SessionStateTypeValues } from './states/data_types'
import { FormattedDate } from '../../utils/helpers'
import { PlayerMetricTypeGroup } from '../metrics/player_data_types'
// Session State

export interface SessionsState {
  // Session Form //
  useCustomName: boolean
  customName: string
  // ============ //
  rawData: IDMap<RawSessionData>
  selectedId: string
  creatingSession: boolean
  selected: SessionData
  filter: {
    items: SessionBasic[]
    options: SessionFilterRequestBody
    fetching: boolean
    hasValidData: boolean
  }
  recent: {
    items: SessionBasic[]
    quantity: number
  }
  graph: {
    items: {
      [id: string]: SessionData
    }
  }
  activeSession: RawSessionData

  activityListScrollHeight: 0

  autoSleepEnabled: boolean
  scoreboard: ScoreboardType
  isKeyboardShortcutEnabled: boolean
}

export type SessionFilterRequestBody = {
  team: string
  startTime: number
  endTime: number
  limitQuantity: number
  type: SessionTypeValues | 'All'
  subType: SubSessionTypeValues | 'All'
  searchStr: string
  state: SessionStateTypeValues | 'All'
  sportType: SportTypeValues | 'All'
}

export interface DropdownTeam {
  name: string
  value: string
}

export interface SessionBasic {
  id: string
  name: string
  startTime: number
  endTime: number
  playersSessions: RawPlayerSessionData[]
  teamsSessions: RawTeamSessionData[]
  type: number
  sportType: SportTypeValues
  state: string
  pitchId: string
  gender: 'male' | 'female'
}

export interface SessionData extends SessionSetup {
  id: string
  startTime: number
  endTime: number
  timeEvents: {
    [id: string]: TimeEventData
  }
  gameEvents: {
    [id: string]: GameEventData
  }
  flights: string[]
  sessionControl: SessionControl
  fetch?: boolean
}

export interface SessionSetup {
  id?: string
  name: string
  pitch?: PitchTypes
  playersSessions: RawPlayerSessionData[]
  teamsSessions: RawTeamSessionData[]
  type: SessionTypeValues
  subType: SubSessionTypeValues
  mode: SessionModeTypeValues
  sportType: SportTypeValues
  state?: 'Finished' | 'NotStarted' | 'Started'
  pitchId?: string
  gender: 'male' | 'female'
  teamA?: Team
  teamB?: Team
  hostname?: string
  locationName?: string
  uploadStatus?
  isCustomName?: boolean
  customName?: string
}

export type SessionControl = {
  possession: Possession
  sides: Side[]
}

export type Possession = Team
export type Side = Team

export interface SessionPlayers {
  [key: string]: RawPlayerSessionData
}

export interface FormattedSessionPlayers {
  map: {
    [id: string]: FormattedSessionPlayer
  }
  options: Options<string>
}

export interface FormattedSessionPlayer {
  playerData: PlayerData
  playerSession: RawPlayerSessionData
}

export interface SessionTeams {
  [key: string]: RawTeamSessionData
}

export interface Scoreboard {
  teamId: string
  scoreSum: number
  teamName: string
}

export interface ScoreboardType {
  [key: string]: Scoreboard
}

export interface SessionConfig {
  team: Team
  teamB: Team
  session: SessionData
  playersSessions: any
  pitch: {
    type: SportTypeValues
    coordinates: PitchCoordinates
  }
  anchorConfig: Anchors
  sessionPlayers: SessionPlayers
  playerOptions: Options<string>
  teamOptions: Options<string>
  sessionTeams: SessionTeams
  sessionTeamsArray: Team[]
  highlightedSide: number
  live: boolean
  sport: Sport
  isTrainingMode: boolean
  isMatchMode: boolean
  getPlayerSession: (playerSessionId: string) => RawPlayerSessionData
  eventTypes: EventTypeGroup
  flightTypes: FlightEventTypeGroup
  flightMetrics: MetricTypeGroup
  gameEventTypes: GameEventTypeGroup
  gameEventMetrics: MetricTypeGroup
  timeEventTypes: TimeEventTypeGroup
  australianRulesEventTypes: AussieRulesEventTypeGroup
  australianRulesEventMetrics: MetricTypeGroup
  playerMap: IDMap<PlayerData>
  teamMap: IDMap<Team>
  newTeamMap: IDMap<AppTeam>
  formattedSessionPlayers: FormattedSessionPlayers
  isKeyboardShortcutEnabled: boolean
}

export interface FormattedPlayerSession {
  id: string
  playerId: string
  teamId: string
  sessionId: string
  player: FormattedPlayer
  hardwareId: number
  number: number
  hardware: {
    id: number
    serial: string
    type: DeviceTypeValues
    productName: HardwareProductNames
  }
  device: FormattedDevice
}

export interface FormattedTeamSession {
  rawData: RawTeamSessionData
  id: string
  teamId: string
  sessionId: string
  team: FormattedTeam
  homeAway: 'HOME' | 'AWAY'
  oppositionTeamId: string
}

export type FormattedPlayersSessions = {
  byHardwareId: Group<FormattedPlayerSession>
  byNumber: {
    [teamId: string]: Group<FormattedPlayerSession>
  }
  byPlayerId: Group<FormattedPlayerSession>
  byId: Group<FormattedPlayerSession>
}

export interface FormattedSession {
  sessionData: RawSessionData

  isSetup: boolean

  // General
  id: string
  name: string
  startTime: FormattedDate
  endTime: FormattedDate
  sport: Sport
  type: SessionType
  subType: SubSessionType
  mode: SessionModeType
  state: SessionStateType
  live: boolean
  isTrainingMode: boolean
  isMatchMode: boolean
  hostname: string
  locationName: string
  uploadStatus
  isSessionUploaded: boolean
  isOfficiatingMode: boolean
  isKeyboardShortcutEnabled: boolean

  // Teams and Players
  teams: Group<FormattedTeam>
  teamsSessions: Group<FormattedTeamSession>
  players: {
    [teamId: string]: Group<FormattedPlayer>
    all: Group<FormattedPlayer>
  }
  playersSessions: FormattedPlayersSessions
  homeTeam: FormattedTeam
  awayTeam: FormattedTeam
  officiationTeam: FormattedTeam

  // Balls
  balls: Group<FormattedPlayerSession>

  // Events types and metrics
  eventTypes: EventTypeGroup
  flightTypes: FlightEventTypeGroup
  flightMetrics: MetricTypeGroup
  gameEventTypes: GameEventTypeGroup
  gameEventMetrics: MetricTypeGroup
  timeEventTypes: TimeEventTypeGroup
  australianRulesEventTypes: AussieRulesEventTypeGroup
  australianRulesEventMetrics: MetricTypeGroup

  playerSummaryMetricTypes: MetricTypeGroup

  // Pitch and anchors
  pitchId: string
  pitch: {
    type: SportTypeValues
    coordinates: PitchCoordinates
    arcs: PitchArcs
  }
  anchorConfig: Anchors
  anchors: Group<Anchor>

  // Features
  homeSideCheck: boolean
}

export type RawSessionData = {
  id: string
  startTime: number
  endTime: number
  name: string
  type: SessionTypeValues
  subType: SubSessionTypeValues
  mode: SessionModeTypeValues
  sportType: SportTypeValues
  state: SessionStateTypeValues
  gender: 'male' | 'female'
  pitchId: string
  pitch: RawPitchData
  womensMode: boolean
  locationName: string
  hostname: string
  teamsSessions: RawTeamSessionData[]
  playersSessions: RawPlayerSessionData[]
  uploadStatus: {
    dump
    daemon
  }
  sessionControl: SessionControl
  officiatingAlgosEnable: boolean
  noDaemon: boolean
}

export type RawTeamSessionData = {
  id: string
  teamId: string
  team: RawTeamData
  sessionId: string
  homeAway: 'HOME' | 'AWAY'
}

export type RawPlayerSessionData = {
  id: string
  playerId: string
  teamId: string
  sessionId: string
  number: number
  player: RawPlayerData
  tag: {
    id: number
    serial: string
    type: DeviceTypeValues
    productName: HardwareProductNames
  }
}

export namespace SessionsActionType {
  export const CHECK_ACTIVE_SESSION = 'CHECK_ACTIVE_SESSION',
    GET_RECENT_SESSIONS = 'GET_RECENT_SESSIONS',
    UPDATE_FILTER_OPTIONS = 'UPDATE_FILTER_OPTIONS',
    FETCH_FILTERED_SESSIONS = 'FETCH_FILTERED_SESSIONS',
    SET_FILTERED_SESSIONS = 'SET_FILTERED_SESSIONS',
    FETCH_FILTERED_SESSIONS_FAILED = 'FETCH_FILTERED_SESSIONS_FAILED',
    SET_SESSION = 'SET_SESSION',
    EXIT_SESSION = 'EXIT_SESSION',
    UPDATE_SELECTED_SESSION = 'UPDATE_SELECTED_SESSION',
    SET_SETUP_SESSION = 'SET_SETUP_SESSION',
    UPDATE_SETUP_SESSION = 'UPDATE_SETUP_SESSION',
    ADD_PLAYER_SESSION = 'ADD_PLAYER_SESSION',
    UPDATE_PLAYER_SESSION = 'UPDATE_PLAYER_SESSION',
    REMOVE_PLAYER_SESSION = 'REMOVE_PLAYER_SESSION',
    REMOVE_BALL_FROM_SESSION = 'REMOVE_BALL_FROM_SESSION',
    CREATE_SESSION = 'CREATE_SESSION',
    CREATING_SESSION = 'CREATING_SESSION',
    STOP_SESSION = 'STOP_SESSION',
    START_SESSION = 'START_SESSION',
    SET_ACTIVITY_LIST_SCROLL_HEIGHT = 'SET_ACTIVITY_LIST_SCROLL_HEIGHT',
    UPDATE_SESSION = 'UPDATE_SESSION',
    SET_ACTIVE_SESSION = 'SET_ACTIVE_SESSION',
    UPDATE_BROADCAST_STATE = 'UPDATE_BROADCAST_STATE',
    GET_SCOREBOARD = 'GET_SCOREBOARD',
    SET_AUTO_SLEEP = 'SET_AUTO_SLEEP',
    SET_SESSION_UPLOAD_STATUS = 'SET_SESSION_UPLOAD_STATUS',
    GET_PLAYER_SESSION = 'GET_PLAYER_SESSION',
    UPDATE_USE_CUSTOM_NAME = 'UPDATE_USE_CUSTOM_NAME',
    SET_CUSTOM_NAME = 'SET_CUSTOM_NAME',
    UPDATE_SET_OFFICIATION = 'UPDATE_SET_OFFICIATION',
    TOGGLE_KEYBOARD_SHORTCUTS = 'TOGGLE_KEYBOARD_SHORTCUTS'
}
