import React, { useState } from 'react'
import { CustomCellDropdown } from '../../Table.types'
import styles from './DropDownWithSendButton.module.scss'

const DropdownWithSendButtonStyles = {
  backgroundColor: '#fff',
  color: '#000000',
  padding: '2px',
  borderRadius: '5px',
  marginRight: '7px',
  cursor: 'pointer',
  border: 'none',
  height: '100%',
  fontFamily: 'MarkPro'
}

const DropdownWithSendButton = ({ item, header }) => {
  const [selectedOption, setSelectedOption] =
    useState<CustomCellDropdown | null>(null)

  // Ensure the options are of type CustomCellDropdown
  const options = item.dropDownOptions?.[header.group] as {
    [key: string]: CustomCellDropdown
  }

  if (!options) return <noscript />

  // Handle change event for dropdown
  const handleChange = (e) => {
    const selectedValue = e.target.value
    const option = Object.values(options).find(
      (opt) => opt.value === selectedValue
    )
    setSelectedOption(option || null)
  }

  // Handle the Send button click event
  const handleSend = () => {
    if (selectedOption && header.callback) {
      header.callback(item, selectedOption.value)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <select
        defaultValue=''
        onChange={handleChange}
        style={DropdownWithSendButtonStyles}
      >
        <option value='' disabled>
          Select an action
        </option>
        {Object.values(options).map((option, index) => (
          <option key={index} value={option.value} disabled={option.disabled}>
            {option.text}
          </option>
        ))}
      </select>
      <button className={styles.tableButton} onClick={handleSend}>
        Send
      </button>
    </div>
  )
}

export default DropdownWithSendButton
