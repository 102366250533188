import React from 'react'
import { sportableColors } from '../../../const'

export interface CodingIconProps {
  scale?: number
  color?: string
}

export const CodingIcon: React.FC<CodingIconProps> = ({
  scale = 1,
  color = sportableColors.colors.sportableGreyDark
}) => {
  return (
    <svg
      width={scale * 32}
      height={scale * 32}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M2 16.5C2 9.6647 2 6.24705 4.1228 4.1228C6.2485 2 9.6647 2 16.5 2C23.3353 2 26.753 2 28.8758 4.1228C31 6.2485 31 9.6647 31 16.5C31 23.3353 31 26.753 28.8758 28.8758C26.7544 31 23.3353 31 16.5 31C9.6647 31 6.24705 31 4.1228 28.8758C2 26.7544 2 23.3353 2 16.5Z'
        stroke={color}
        strokeWidth='2.25'
      />
      <path
        d='M23.75 20.85H16.5M9.25 13.6L9.5893 13.8828C11.4482 15.4314 12.3776 16.2057 12.3776 17.225C12.3776 18.2444 11.4497 19.0187 9.5893 20.5673L9.25 20.85'
        stroke={color}
        strokeWidth='2.25'
        strokeLinecap='round'
      />
    </svg>
  )
}
