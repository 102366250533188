import {
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup,
  generateTypeChecks,
  DataTypeConfig
} from '../data_types'
import {
  MetricTypeGroup,
  getMetricTypeGroup,
  getMetricTypesConfigFromJson,
  metricTypesConfig
} from '../metrics/data_types'
import {
  AussieRulesEventJson,
  AussieRulesEventTypeGroup,
  AussieRulesEventTypeKeys,
  AussieRulesEventTypes,
  defaultAussieRulesEventFeatures,
  getAussieRulesEventTypeGroup
} from './aussie_rules/data_types'
import {
  FlightEventJson,
  FlightEventTypeConfig,
  FlightEventTypeGroup,
  FlightEventTypeKeys,
  defaultFlightEventFeatures,
  flightEventTypesConfig,
  getFlightEventTypeGroup
} from './flight/data_types'
import {
  GameEventJson,
  GameEventTypeGroup,
  GameEventTypeKeys,
  GameEventTypes,
  defaultGameEventFeatures,
  getGameEventTypeGroup
} from './game/data_types'
import {
  TimeEventJson,
  TimeEventTypeGroup,
  TimeEventTypeKeys,
  TimeEventTypes,
  getTimeEventTypeGroup
} from './time/data_types'
import { eventDataTypesMap } from './data_types_map'
import {
  OutcomeTypeGroup,
  getOutcomeTypeGroup,
  getOutcomeTypesConfigFromJson,
  outcomeTypesConfig
} from '../outcomes/data_types'
import {
  GameEventSubTypeKeys,
  GameEventSubTypes,
  gameEventSubTypesConfig,
  getGameEventSubTypeGroup
} from './game/subType/data_types'
import {
  FlightEventSubTypeConfig,
  FlightEventSubTypeKeys,
  flightEventSubTypesConfig,
  getFlightEventSubTypeGroup
} from './flight/subTypes/data_types'
import {
  AussieRulesEventCrossSectionTypeKeys,
  AussieRulesEventCrossSectionTypes,
  aussieRulesEventsCrossSectionTypeConfig,
  getAussieRulesEventCrossSectionTypeGroup
} from './aussie_rules/cross-section/data_types'

export type EventFeatures = {
  exampleFeature: boolean
}

export const eventTypesConfig = {
  flight: {
    key: 'flight',
    value: 0,
    name: 'Flight',
    props: null
  },
  time: {
    key: 'time',
    value: 1,
    name: 'Time',
    props: null
  },
  game: {
    key: 'game',
    value: 6,
    name: 'Game',
    props: null
  },
  aussieRules: {
    key: 'aussieRules',
    value: 7,
    name: 'Aussie Rules',
    props: null
  }
} as const

export type EventTypes = DataTypes<typeof eventTypesConfig>

export type EventTypeProps<SubTypes, Features> = {
  types: SubTypes
  metricTypes: MetricTypeGroup
  outcomeTypes: OutcomeTypeGroup
  features: Features
}

export type EventTypeKey = DataTypeKey<EventTypes>

export type EventTypeKeys = DataTypeKeys<EventTypeKey>

export type EventTypeValues = DataTypeValues<EventTypeKey>

export const getEventTypeGroup = (items: {
  [key in EventTypeKeys]?: EventTypes[key]
}) => {
  return getDataTypeGroup<
    EventTypeKeys,
    EventTypeValues,
    EventTypes,
    EventTypeProps<
      FlightEventTypeGroup &
        GameEventTypeGroup &
        AussieRulesEventTypeGroup &
        TimeEventTypeGroup,
      EventFeatures
    >
  >(items)
}

export type EventTypeGroup = ReturnType<typeof getEventTypeGroup>

export type EventTypeConfig = DataTypeConfig<
  EventTypeKeys,
  EventTypeValues,
  EventTypeProps<
    FlightEventTypeGroup &
      GameEventTypeGroup &
      AussieRulesEventTypeGroup &
      TimeEventTypeGroup,
    EventFeatures
  >
>

export const eventTypes = getEventTypeGroup(eventTypesConfig)

export const isEventType = generateTypeChecks<EventTypeKeys, EventTypeGroup>(
  eventTypes
)

export type EventType = ReturnType<typeof eventTypes.getTypeByValue>

// export type EventTypeJson<EventSubTypesJson = void> = {
//   name?: string
//   types?: EventSubTypesJson
//   metrics?: MetricTypesJson
//   outcomes?: OutcomeTypesJson
// }

export type EventTypeJson<
  EventFeatures = NonNullable<unknown>,
  EventSubTypesJson = NonNullable<unknown>
> = {
  name?: string
  types?: EventSubTypesJson
  metrics?: (
    | string
    | {
        key: string
        name?: string
        abbr?: string
        decimal?: number
      }
  )[]
  outcomes?: (
    | string
    | {
        key: string
        name?: string
        abbr?: string
      }
  )[]
  features?: Partial<EventFeatures>
}

export const emptyEventJson: EventTypeJson = {
  types: {},
  metrics: [],
  outcomes: [],
  features: {}
}

export function getEventTypeGroupFromJson(
  dataTypesJson: {
    events: {
      flight?: FlightEventJson
      game?: GameEventJson
      time?: TimeEventJson
      aussieRules?: AussieRulesEventJson
    }
  },
  sport
): EventTypeGroup {
  // Flights //
  const sportFlightEventTypesConfig = {} as {
    [key: string]: FlightEventTypeConfig
  }

  let flightEvent = dataTypesJson.events.flight

  if (!flightEvent) {
    flightEvent = emptyEventJson
  }

  const flightMetricTypesConfig = getMetricTypesConfigFromJson(
    flightEvent.metrics,
    metricTypesConfig
  )

  const flightOutcomeTypesConfig = getOutcomeTypesConfigFromJson(
    flightEvent.outcomes,
    outcomeTypesConfig
  )

  for (const key in flightEvent.types) {
    const flightTypeKey = key as FlightEventTypeKeys
    const flightType = flightEvent.types[flightTypeKey]

    const sportSubFlightEventTypesConfig = {} as {
      [key: string]: FlightEventSubTypeConfig
    }

    const flightTypeMetricTypesConfig = getMetricTypesConfigFromJson(
      flightType.metrics,
      flightMetricTypesConfig
    )

    const flightTypeOutcomeTypesConfig = getOutcomeTypesConfigFromJson(
      flightType.outcomes,
      flightOutcomeTypesConfig
    )

    for (const subKey in flightType.types) {
      const subFlightTypeKey = subKey as FlightEventSubTypeKeys
      const flightSubType = flightType.types[subFlightTypeKey]

      const flightSubTypeMetricTypesConfig = getMetricTypesConfigFromJson(
        flightSubType.metrics,
        flightTypeMetricTypesConfig
      )

      const flightSubTypeOutcomeTypesConfig = getOutcomeTypesConfigFromJson(
        flightSubType.outcomes,
        flightTypeOutcomeTypesConfig
      )

      sportSubFlightEventTypesConfig[subFlightTypeKey] = {
        ...flightEventSubTypesConfig[subFlightTypeKey],
        props: {
          metricTypes: getMetricTypeGroup(flightSubTypeMetricTypesConfig),
          outcomeTypes: getOutcomeTypeGroup(flightSubTypeOutcomeTypesConfig),
          types: null,
          features: {
            ...defaultFlightEventFeatures,
            ...(flightEvent.features || {}),
            ...(flightType.features || {}),
            ...(flightSubType.features || {})
          }
        }
      }
    }

    sportFlightEventTypesConfig[flightTypeKey] = {
      ...flightEventTypesConfig[flightTypeKey],
      name: flightType?.name || flightEventTypesConfig[flightTypeKey].name,
      props: {
        metricTypes: getMetricTypeGroup(flightTypeMetricTypesConfig),
        outcomeTypes: getOutcomeTypeGroup(flightTypeOutcomeTypesConfig),
        types: getFlightEventSubTypeGroup(sportSubFlightEventTypesConfig),
        features: {
          ...defaultFlightEventFeatures,
          ...(flightEvent.features || {}),
          ...(flightType.features || {})
        }
      }
    }
  }

  const flight = {
    ...eventTypesConfig.flight,
    props: {
      metricTypes: getMetricTypeGroup(flightMetricTypesConfig),
      outcomeTypes: getOutcomeTypeGroup(flightOutcomeTypesConfig),
      types: getFlightEventTypeGroup(sportFlightEventTypesConfig),
      features: {
        ...defaultFlightEventFeatures,
        ...(flightEvent.features || {})
      }
    }
  }

  // Game //

  const sportGameEventTypesConfig = {} as {
    [key in GameEventTypeKeys]?: GameEventTypes[key]
  }

  let gameEvent = dataTypesJson.events.game

  if (!gameEvent) {
    gameEvent = emptyEventJson
  }

  const gameEventMetricTypesConfig = getMetricTypesConfigFromJson(
    gameEvent.metrics,
    metricTypesConfig
  )

  const gameEventOutcomeTypesConfig = getOutcomeTypesConfigFromJson(
    gameEvent.outcomes,
    outcomeTypesConfig
  )

  for (const key in gameEvent.types) {
    const gameEventTypeKey = key as GameEventTypeKeys
    const gameEventType = gameEvent.types[gameEventTypeKey]

    const sportSubGameEventTypesConfig = {} as {
      [key in GameEventSubTypeKeys]?: GameEventSubTypes[key]
    }

    const gameEventTypeMetricTypesConfig = getMetricTypesConfigFromJson(
      gameEventType.metrics,
      gameEventMetricTypesConfig
    )

    const gameEventTypeOutcomeTypesConfig = getOutcomeTypesConfigFromJson(
      gameEventType.outcomes,
      gameEventOutcomeTypesConfig
    )

    for (const subKey in gameEventType.types) {
      const subGameEventTypeKey = subKey
      const gameEventSubType = gameEventType.types[subGameEventTypeKey]

      const gameEventSubTypeMetricTypesConfig = getMetricTypesConfigFromJson(
        gameEventSubType.metrics,
        gameEventTypeMetricTypesConfig
      )

      const gameEventSubTypeOutcomeTypesConfig = getOutcomeTypesConfigFromJson(
        gameEventType.outcomes,
        gameEventTypeOutcomeTypesConfig
      )

      sportSubGameEventTypesConfig[subGameEventTypeKey] = {
        ...gameEventSubTypesConfig[subGameEventTypeKey],
        props: {
          metricTypes: getMetricTypeGroup(gameEventSubTypeMetricTypesConfig),
          outcomeTypes: getOutcomeTypeGroup(gameEventSubTypeOutcomeTypesConfig),
          types: getGameEventSubTypeGroup({}),
          features: {
            ...defaultGameEventFeatures,
            ...(gameEvent.features || {}),
            ...(gameEventType.features || {}),
            ...(gameEventSubType.features || {})
          }
        }
      }
    }

    sportGameEventTypesConfig[gameEventTypeKey] = {
      ...eventDataTypesMap.game.dataTypesConfig[gameEventTypeKey],
      props: {
        metricTypes: getMetricTypeGroup(gameEventTypeMetricTypesConfig),
        outcomeTypes: getOutcomeTypeGroup(gameEventTypeOutcomeTypesConfig),
        types: getGameEventSubTypeGroup(sportSubGameEventTypesConfig),
        features: {
          ...defaultGameEventFeatures,
          ...(gameEvent.features || {}),
          ...(gameEventType.features || {})
        }
      }
    }
  }

  const game = {
    ...eventTypesConfig.game,
    props: {
      metricTypes: getMetricTypeGroup(gameEventMetricTypesConfig),
      outcomeTypes: getOutcomeTypeGroup(gameEventOutcomeTypesConfig),
      types: getGameEventTypeGroup(sportGameEventTypesConfig),
      features: {
        ...defaultGameEventFeatures,
        ...(gameEvent.features || {})
      }
    }
  }

  // Aussie //

  const sportAussieRulesEventTypesConfig = {} as {
    [key in AussieRulesEventTypeKeys]?: AussieRulesEventTypes[key]
  }

  let aussieRulesEvent = dataTypesJson.events.aussieRules

  if (!aussieRulesEvent) {
    aussieRulesEvent = emptyEventJson
  }

  const aussieRulesEventMetricTypesConfig = getMetricTypesConfigFromJson(
    aussieRulesEvent.metrics,
    metricTypesConfig
  )

  const aussieRulesEventOutcomeTypesConfig = getOutcomeTypesConfigFromJson(
    aussieRulesEvent.outcomes,
    outcomeTypesConfig
  )

  for (const key in aussieRulesEvent.types) {
    const aussieRulesEventTypeKey = key as AussieRulesEventTypeKeys
    const aussieRulesEventType = aussieRulesEvent.types[aussieRulesEventTypeKey]

    const sportSubAussieRulesTypesConfig = {} as {
      [key in AussieRulesEventCrossSectionTypeKeys]?: AussieRulesEventCrossSectionTypes[key]
    }

    const aussieRulesEventTypeMetricTypesConfig = getMetricTypesConfigFromJson(
      aussieRulesEventType.metrics,
      aussieRulesEventMetricTypesConfig
    )

    const aussieRulesEventTypeOutcomeTypesConfig =
      getOutcomeTypesConfigFromJson(
        aussieRulesEventType.outcomes,
        aussieRulesEventOutcomeTypesConfig
      )

    for (const subKey in aussieRulesEventType.types) {
      const subAussieRulesEventTypeKey = subKey
      const aussieRulesEventSubType =
        aussieRulesEventType.types[subAussieRulesEventTypeKey]

      const aussieRulesEventSubTypeMetricTypesConfig =
        getMetricTypesConfigFromJson(
          aussieRulesEventSubType.metrics,
          aussieRulesEventTypeMetricTypesConfig
        )

      const aussieRulesEventSubTypeOutcomeTypesConfig =
        getOutcomeTypesConfigFromJson(
          aussieRulesEventType.outcomes,
          aussieRulesEventTypeOutcomeTypesConfig
        )

      sportSubAussieRulesTypesConfig[subAussieRulesEventTypeKey] = {
        ...aussieRulesEventsCrossSectionTypeConfig[subAussieRulesEventTypeKey],
        props: {
          metricTypes: getMetricTypeGroup(
            aussieRulesEventSubTypeMetricTypesConfig
          ),
          outcomeTypes: getOutcomeTypeGroup(
            aussieRulesEventSubTypeOutcomeTypesConfig
          ),
          types: getAussieRulesEventCrossSectionTypeGroup({}),
          features: {
            ...defaultAussieRulesEventFeatures,
            ...(aussieRulesEvent.features || {}),
            ...(aussieRulesEventType.features || {}),
            ...(aussieRulesEventSubType.features || {})
          }
        }
      }
    }

    sportAussieRulesEventTypesConfig[aussieRulesEventTypeKey] = {
      ...eventDataTypesMap.aussieRules.dataTypesConfig[aussieRulesEventTypeKey],
      props: {
        metricTypes: getMetricTypeGroup(aussieRulesEventTypeMetricTypesConfig),
        outcomeTypes: getOutcomeTypeGroup(
          aussieRulesEventTypeOutcomeTypesConfig
        ),
        types: getAussieRulesEventCrossSectionTypeGroup(
          sportSubAussieRulesTypesConfig
        ),
        features: {
          ...defaultAussieRulesEventFeatures,
          ...(aussieRulesEvent.features || {}),
          ...(aussieRulesEventType.features || {})
        }
      }
    }
  }

  const aussieRules = {
    ...eventTypesConfig.aussieRules,
    props: {
      metricTypes: getMetricTypeGroup(aussieRulesEventMetricTypesConfig),
      outcomeTypes: getOutcomeTypeGroup(aussieRulesEventOutcomeTypesConfig),
      types: getAussieRulesEventTypeGroup(sportAussieRulesEventTypesConfig),
      features: {
        ...defaultAussieRulesEventFeatures,
        ...(aussieRulesEvent.features || {})
      }
    }
  }
  // Time //

  const sportTimeEventTypesConfig = {} as {
    [key in TimeEventTypeKeys]?: TimeEventTypes[key]
  }

  for (const key in dataTypesJson.events.time.types) {
    const timeEventTypeKey = key as TimeEventTypeKeys
    const timeEventType = dataTypesJson.events.time.types[timeEventTypeKey]

    sportTimeEventTypesConfig[timeEventTypeKey] = {
      ...eventDataTypesMap.time.dataTypesConfig[timeEventTypeKey],
      name: eventDataTypesMap.time.dataTypesConfig[timeEventTypeKey].props
        .features.period
        ? eventDataTypesMap.time.dataTypesConfig[timeEventTypeKey].name +
          ' ' +
          dataTypesJson.events.time.periodName
        : eventDataTypesMap.time.dataTypesConfig[timeEventTypeKey].name,
      props: {
        ...eventDataTypesMap.time.dataTypesConfig[timeEventTypeKey].props,
        features: {
          ...eventDataTypesMap.time.dataTypesConfig[timeEventTypeKey].props
            .features,
          buttons: timeEventType.buttons,
          startName:
            timeEventType.startName ||
            eventDataTypesMap.time.dataTypesConfig[timeEventTypeKey].props
              .features.startName,
          endName:
            timeEventType.endName ||
            eventDataTypesMap.time.dataTypesConfig[timeEventTypeKey].props
              .features.endName
        }
      }
    }
  }

  const time = {
    ...eventTypesConfig.time,
    props: {
      types: getTimeEventTypeGroup(sportTimeEventTypesConfig),
      periodName: dataTypesJson.events.time.periodName
    }
  }
  const sportEventTypesConfig = {
    flight,
    game,
    time
  } as {
    [key in EventTypeKeys]?: EventTypes[key]
  }

  if (dataTypesJson.events.aussieRules)
    sportEventTypesConfig.aussieRules = aussieRules

  console.log('event type config for: ' + sport, sportEventTypesConfig)

  return getEventTypeGroup(sportEventTypesConfig)
}
