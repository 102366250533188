import { ViewType } from '../../components/Views/Views.types'
import { DiagnosticsContainer } from './Container'
import { MainProps } from '../../components/Views/Main/Main.types'
import { tabConfig, useDiagnositcsTabConfig } from './tab_config'
import { Route } from '../../App/App.types'
import { useDiagnosticsViewStrackProps } from './strack'

export const diagnosticsPath = '/diagnostics'

export const route: Route<MainProps> = {
  path: diagnosticsPath,
  view: {
    type: ViewType.MAIN,
    name: 'Diagnostics',
    tabs: tabConfig,
    Container: DiagnosticsContainer,
    useHeaderProps: () => {
      return {
        title: 'Diagnostics',
        backButton: {}
      }
    },
    useStrackProps: useDiagnosticsViewStrackProps,
    useTabConfig: useDiagnositcsTabConfig
  }
}
