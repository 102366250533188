export const updateUserInLocalStorage = (user) => {
  window.localStorage.setItem('user', JSON.stringify(user))
}

export const updateTokenInLocalStorage = (token) => {
  window.localStorage.setItem('authorization', token || '')
}

export const getToken = () => {
  return localStorage.getItem('authorization')
}
