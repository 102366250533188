import React from 'react'

export const Home = ({ color }) => {
  return (
    <svg width='30px' height='30px' viewBox='0 0 30 30' version='1.1'>
      <title>Path 2</title>
      <desc>Created with Sketch.</desc>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='navbar/home-off' stroke={color} strokeWidth='2'>
          <g id='navbar/home'>
            <polygon id='Path-2' points='6 25 6 13 15 5 24 13 24 25'></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}
