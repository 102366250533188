import React, { Component } from 'react'
import { ToggleModal } from './modalActions'
import { connect } from 'react-redux'
import styles from './Modal.module.scss'
import animations from '../../utils/animations'

interface ModalOverlayProps {
  active: boolean
  toggleModal?: ToggleModal
}

interface ModalOverlayState {
  overlay: boolean
  modalCount: number
}

class ModalOverlay extends Component<ModalOverlayProps, ModalOverlayState> {
  private overlay: React.RefObject<HTMLInputElement>

  constructor(props) {
    super(props)

    this.overlay = React.createRef()

    this.state = {
      overlay: false,
      modalCount: 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { active } = this.props
    if (!prevProps.active && active) {
      this.setState(
        (prevState) => ({
          overlay: true,
          modalCount: prevState.modalCount + 1
        }),
        () => {
          animations.showModalOverlay(this.overlay.current)
        }
      )
    } else if (prevProps.active && !active) {
      animations.hideModalOverlay(this.overlay.current, () => {
        this.setState((prevState) => {
          if (prevState.modalCount > 1) {
            return {
              overlay: prevState.overlay,
              modalCount: prevState.modalCount - 1
            }
          } else {
            return { overlay: false, modalCount: 0 }
          }
        })
      })
    }
  }

  render() {
    const { overlay } = this.state
    return (
      <div>
        {overlay && (
          <div
            ref={this.overlay}
            onClick={() => this.props.toggleModal({})}
            className={styles.overlay}
          />
        )}
      </div>
    )
  }
}

export default ModalOverlay
