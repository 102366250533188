import React, { Component } from 'react'
import downloadIcon from '../../assets/img/download-inverse.svg'
import Loader from '../Loader/Loader'

type HandleClick = (event) => void

interface ButtonProps {
  handleClick?: HandleClick
  disabled?: boolean
  className?: string
  type?: string
  style?: any
  isDownloadButton?: boolean
  id?: string
  children: React.ReactNode
  loading?: boolean
  loaderColor?: string
}

export class Button extends Component<ButtonProps> {
  onPress = (event) => {
    const { disabled, handleClick } = this.props
    if (!disabled && handleClick) {
      handleClick(event)
    }
  }

  render() {
    const {
      disabled,
      children,
      className,
      type,
      style,
      isDownloadButton = false,
      id,
      loading,
      loaderColor
    } = this.props

    return (
      <button
        type={type === 'submit' && !disabled ? type : 'button'}
        className={
          disabled ? `btn--disabled ${className} button` : `${className} button`
        }
        onClick={this.onPress}
        style={style}
        id={id}
        disabled={loading}
      >
        {loading ? (
          <Loader small color={loaderColor} />
        ) : isDownloadButton ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{ backgroundImage: `url(${downloadIcon})` }}
              className={'btn--download button icon'}
            ></div>
            <div>{children}</div>
          </div>
        ) : (
          children
        )}
      </button>
    )
  }
}
