import React from 'react'
import { SideBarView } from '../../Views.types'
import styles from './Navbar.module.scss'

type SideBarProps = {
  cards: SideBarView[]
  switchCard: (viewID: string) => void
  inView: string
  switchTo: string
}

export function SideBar({ cards, switchCard, inView, switchTo }: SideBarProps) {
  /*========== Render ===========*/
  const renderSidebarOption = (settingView, switchTo, inView, index) => {
    let isInView = false
    if (switchTo) {
      isInView = switchTo === settingView.key
    } else {
      isInView = inView === settingView.key
    }
    return (
      <h4
        key={index}
        onClick={() => switchCard(settingView.key)}
        className={
          isInView ? `${styles.active} ${styles.menuButton}` : styles.menuButton
        }
      >
        {settingView.name}
      </h4>
    )
  }

  return (
    <div className={styles.menuContainer}>
      {cards.map((settingView, index) =>
        renderSidebarOption(settingView, switchTo, inView, index)
      )}
    </div>
  )
}
