import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family'
import React from 'react'

import Select from 'react-select'
import { sportableColors } from '../../const'

export function SelectDropdown(props) {
  const {
    isMulti = false,
    options,
    value,
    onChange,
    className,
    style,
    placeholder
  } = props

  const customStyles = {
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      ':hover': {
        backgroundColor: sportableColors.colors.sportableBlue,
        color: 'white'
      }
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'MarkPro',
      fontSize: '14px'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontFamily: 'MarkPro',
      fontSize: '14px'
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      fontFamily: 'MarkPro',
      fontSize: '14px'
    }),
    control: (base) => ({
      ...base
      // maxHeight: 24
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: 'MarkPro',
      fontSize: '12px'
    }),
    multiLabel: (provided, state) => ({
      ...provided,
      fontFamily: 'MarkPro',
      fontSize: '12px'
    })
  }

  const handleChange = (selectedOption: any, f) => {
    onChange(selectedOption.value)
  }

  return (
    <div style={style}>
      <Select
        {...props}
        isMulti={isMulti}
        name='layout'
        options={options}
        value={value}
        className={className}
        // className='basic-multi-select'
        classNamePrefix='select'
        placeholder={placeholder}
        styles={customStyles}
        onChange={handleChange}
      />
    </div>
  )
}
