import { tabConfig, useSessionSetupTabConfig } from './tab_config'
import { ViewType } from '../../components/Views/Views.types'
import { MainProps } from '../../components/Views/Main/Main.types'
import { useSessionSetupHeaderProps } from './header'
import { useSessionSetupNavbarProps } from './navbar'
import { Route } from '../../App/App.types'
import { useSessionSetupViewStrackProps } from './strack'

export const path = '/sessionsetup'

export const route: Route<MainProps> = {
  path,
  view: {
    type: ViewType.MAIN,
    name: 'Session Setup',
    tabs: tabConfig,
    useTabConfig: useSessionSetupTabConfig,
    useHeaderProps: useSessionSetupHeaderProps,
    useNavbarProps: useSessionSetupNavbarProps,
    useStrackProps: useSessionSetupViewStrackProps
  }
}
