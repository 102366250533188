import {
  GET_SUB_SESSIONS,
  CREATE_SUB_SESSION,
  UPDATE_SUB_SESSION,
  DELETE_SUB_SESSION
} from './types'

const initialState = {
  items: []
}

export function subSessionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUB_SESSIONS:
      return {
        ...state,
        sessionId: action.payload.sessionId,
        items: action.payload.data
      }

    case CREATE_SUB_SESSION:
      return {
        ...state,
        items: action.payload
      }

    case UPDATE_SUB_SESSION:
      return {
        ...state,
        items: action.payload
      }

    case DELETE_SUB_SESSION:
      return {
        ...state,
        items: action.payload
      }

    default:
      return state
  }
}
