import {
  // API actions
  GET_SERVER_VERSION,
  GET_APP,
  GET_HEALTH,
  SET_MS_CONNECTION_STATUS,
  GET_DEAMON_VERSION,
  SET_MS_ERRORS
} from './action_types'

import { setError } from '../../ui/error/actions'
import { request } from '../../utils/request_handler'
import { API_ROOT_URL } from '../../const'
import { sleep } from '../../utils/helpers'
import { SERVICE_ROOT_URL } from '../api/config'
import { setUnitSystems } from '../units/actions'

// Get server version

export function getServerVersion() {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: GET_SERVER_VERSION,
        payload: response.data
      })
    }
    const error = async (error) => {
      await sleep(2000)
      dispatch(getServerVersion())
      // dispatch(setError({message: `Failed to get Metrics Server Version. API request failed. Check log for more detail.`}))
    }
    const options = {
      url: `config/version`,
      method: 'get',
      baseURL: API_ROOT_URL
    }
    return request(options, success, error, dispatch)
  }
}

// Get metrics_server system

export function getApp() {
  return (dispatch) => {
    const success = (response) => {
      // Set unit systems //
      dispatch(setUnitSystems(response.data.UnitSystems))

      dispatch({
        type: GET_APP,
        payload: response.data
      })
    }
    const error = (error) => {
      dispatch(
        setError({
          message: `Failed to get Metrics Server system type. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `config/app`,
      method: 'get',
      baseURL: API_ROOT_URL
    }
    return request(options, success, error, dispatch)
  }
}

// Get system health

export function getHealth() {
  return (dispatch) => {
    // If no response in 10 seconds, set connection status to false
    const timeout = setTimeout(() => {
      dispatch({
        type: SET_MS_CONNECTION_STATUS,
        payload: false
      })
    }, 10000)
    const success = (response) => {
      clearTimeout(timeout)
      // Set unit systems //
      dispatch(setUnitSystems(response.data.UnitSystems))
      dispatch({
        type: GET_APP,
        payload: response.data
      })
      dispatch({
        type: GET_HEALTH,
        payload: response.data
      })
      dispatch({
        type: SET_MS_CONNECTION_STATUS,
        payload: true
      })
    }
    const error = (error) => {
      clearTimeout(timeout)
      // Check IOT Auth error
      dispatch({
        type: SET_MS_CONNECTION_STATUS,
        payload: false
      })
    }
    const options = {
      url: `config/app`,
      method: 'get',
      baseURL: API_ROOT_URL
    }
    return request(options, success, error, dispatch)
  }
}

// IOT Auth check

export function getStartupErrors() {
  return (dispatch) => {
    const success = async (response) => {
      dispatch({
        type: SET_MS_ERRORS,
        payload: response.data?.errors
      })
    }
    const error = async (error) => {
      console.log('error', error)
    }
    const options = {
      url: `startup/errors`,
      method: 'get',
      baseURL: SERVICE_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Get Daemon version

export function getDaemonVersion() {
  return (dispatch) => {
    const success = async (response) => {
      if (response.status === 204) {
        await sleep(5000)
        dispatch(getDaemonVersion())
      }
      dispatch({
        type: GET_DEAMON_VERSION,
        payload: response.data
      })
    }
    const error = async (error) => {
      await sleep(5000)
      dispatch(getDaemonVersion())
      // dispatch(setError({message: `Failed to get Daemon Version. API request failed. Check log for more detail.`}))
    }
    const options = {
      url: `config/daemon_version`,
      method: 'get',
      baseURL: API_ROOT_URL
    }
    return request(options, success, error, dispatch)
  }
}
