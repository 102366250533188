import React, { useEffect } from 'react'
import styles from './Drills.module.scss'
import { useDrillsContext } from './DrillsContext'
import { DrillControls } from '../../../metrics_server/drills/components/DrillControls/DrillControls'
import { DrillTimeLine } from '../../../metrics_server/drills/components/DrillTimeLine/DrillTimeLine'
import { DrillsMetrics } from '../../../metrics_server/drills/components/DrillsMetrics/DrillsMetrics'
import { CardList } from '../../../components/CardList/CardList'
import { DrillsList } from '../../../metrics_server/drills/components/DrillsList/DrillsList'
import { Toggle } from '../../../components/Forms/Fields/Toggle/Toggle'

export const Drills = ({ active }) => {
  const {
    live,
    drillsMetricsPitchEventsView,
    resetDrillSelection,
    drills,
    setDrillsMetricsPitchEventsView
  } = useDrillsContext()

  const metricsView = drillsMetricsPitchEventsView === 0

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        resetDrillSelection()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [resetDrillSelection])

  // On component unmount fire resetDrillSelection to clear the selected drill
  useEffect(() => {
    return () => resetDrillSelection()
  }, [resetDrillSelection])

  const sessionWithNoDrills =
    !live &&
    (drills.drills.length === 0 ||
      drills.drills.every((drill) => !drill.startTime))

  // Don't render anything if the dill tab is not active
  if (!active) {
    return null
  }

  if (sessionWithNoDrills) {
    return <div className={styles.noDrillsContainer}>No Drills Recorded</div>
  }

  return (
    <div className={styles.drillsContainer}>
      {/* Drills timeline and controls container */}
      <div className={styles.drillsTimeContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={`${styles.drillsTimeWrapper}`}>
            <DrillControls />
            <DrillTimeLine />
          </div>
        </CardList>
      </div>

      {/* Drills Pitch handled by strack property in session config and toggle canvas visibility */}

      {/* Drills metrics container */}
      {drills.drills.length !== 0 && metricsView && (
        <div className={styles.drillsMetricsContainer}>
          <CardList
            col={12}
            items={[{}]}
            scrollerId={`scroller-${1}`}
            className='maxHeight'
          >
            <DrillsMetrics />
          </CardList>
        </div>
      )}

      {/* Pitch player toggle */}
      <div className={styles.metricPitchToggleContainer}>
        <Toggle
          input={{
            value: drillsMetricsPitchEventsView,
            onChange: (value) => setDrillsMetricsPitchEventsView(value)
          }}
          options={[
            { name: 'Metrics', value: 0 },
            { name: 'Pitch', value: 1 }
            // { name: 'Events', value: 2 }
          ]}
          isSkillTracker={false}
          size={'s'}
        />
      </div>

      {/* Drills list container */}
      <div className={styles.drillsListContainer}>
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <DrillsList />
        </CardList>
      </div>
    </div>
  )
}
