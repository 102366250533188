import { getColor } from '../../utils/helpers'
import { Options } from '../data_types'
import { getSport } from '../sports/functions'
import { Team } from '../teams/types'
import { IDMap } from '../types'
import {
  PlayersState,
  PlayerData,
  RawPlayerData,
  FormattedPlayer
} from './types'

// Add data types to player data
export const formatPlayerData = (playerData: PlayerData, team: Team) => {
  // Get sport from session sportType
  const sport = getSport(team.sportType)

  return {
    playerData,
    sport,
    team
  }
}

// Format player data for display
export function formatPlayer(player: RawPlayerData): FormattedPlayer {
  return {
    ...player,
    rawData: player,
    fullName: `${player.firstName} ${player.lastName}`,
    initials: `${player.firstName[0]}.${player.lastName[0]}`,
    abbreviatedName: `${player.firstName[0]}. ${player.lastName}`,
    nameAndNumber: null
  }
}

// Create colour map for players
export const getPlayerColourMap = (players: IDMap<PlayerData>) => {
  const playerColours = {}
  let playerIndex = 0
  for (const playerId in players) {
    playerColours[playerId] = getColor(playerIndex)
    playerIndex++
  }
  return playerColours
}

export const getPlayersByTeamId = (players: PlayersState, teamId: string) => {
  const playersArray = Object.values(players.items)
  return playersArray.filter((player) => {
    return player.teamId === teamId
  })
}

export const filterPlayerOptionsByTeamId = (
  playerOptions: Options<string>,
  players: { [id: string]: PlayerData },
  teamId: string
) => {
  const options = playerOptions.filter((x) => {
    const player = players[x.value]
    return player && teamId === player.teamId
  })

  return options
}
