// Used in sportscaster tab to test sportscaster endpoints

import React, { useCallback } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { NameForm } from '../../../components/Forms/NameForm/NameForm'
import { createTarget } from '../../../metrics_server/targets/actions'
import { useChallenge, useTargets } from '../../../metrics_server/targets/hooks'
import { toggleModal } from '../../../ui/modal/actions'

export const useNewTargetModal = () => {
  // Redux //
  const targets = useTargets()
  const { challenge } = useChallenge()
  const dispatch = useAppDispatch()
  // ====== //

  const openNewTargetModal = useCallback(() => {
    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        wrapper: true,
        ChildComponent: () => (
          <NameForm
            handleSubmit={(values) => {
              dispatch(
                createTarget({
                  ...values,
                  ...targets.blankTarget,
                  subSessionId: challenge.id
                })
              )
              dispatch(toggleModal({}))
            }}
          />
        ),
        className: 'modalSmall',
        title: 'Create Target',
        handleSecondaryBtn: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }, [])

  return {
    openNewTargetModal
  }
}
