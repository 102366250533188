import React from 'react'
import { useStatisticLogic } from './Statistic.logic'
import styles from './Statistic.module.scss'
import { AdjustedFontSizeType } from '../EventDetailCard/FormattedEventDetailCard'
import { Coordinate } from '../../metrics_server/pitches/types'

export interface StatisticProps {
  title: string
  icon?: string
  stat: number | string | Coordinate
  tag?: number | string | JSX.Element
  inline?: boolean
  statColor?: string
  centerAlign?: boolean
  adjustedFontSize?: AdjustedFontSizeType
  tagOnClick?: (callback) => void
}

export const Statistic: React.FC<StatisticProps> = ({
  title,
  stat,
  icon,
  tag,
  tagOnClick,
  inline = false,
  statColor,
  centerAlign = false,
  adjustedFontSize
}) => {
  const { iconSrc } = useStatisticLogic(icon)

  if (inline) {
    return (
      <div className={styles.statContainerInline}>
        <div className={styles.statTitle}>
          {icon && <img src={iconSrc} alt={icon} />}
          <p style={{ fontSize: adjustedFontSize?.p }}>{title}:</p>
        </div>
        <div className={styles.statValue} style={{ color: statColor }}>
          <p style={{ fontSize: adjustedFontSize?.p }}>
            {stat as string | number}
          </p>
        </div>
      </div>
    )
  }

  if (typeof stat === 'object' && 'x' in stat && 'y' in stat) {
    return (
      <div
        className={styles.statContainer}
        style={{ alignItems: centerAlign ? 'center' : undefined }}
      >
        <div className={styles.statTitle}>
          {icon && <img src={iconSrc} alt={icon} />}
          <p style={{ fontSize: adjustedFontSize?.p }}>{title}</p>
        </div>
        <div className={styles.statValue}>
          <p style={{ fontSize: adjustedFontSize?.p }}>
            x: {stat.x.toFixed(2)}, y: {stat.y.toFixed(2)}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div
      className={styles.statContainer}
      style={{ alignItems: centerAlign ? 'center' : undefined }}
    >
      <div className={styles.statTitle}>
        {icon && <img src={iconSrc} alt={icon} />}
        <p style={{ fontSize: adjustedFontSize?.p }}>{title}</p>
      </div>
      <div className={styles.statValue}>
        <p style={{ fontSize: adjustedFontSize?.p }}>
          {stat as string | number}
          {tag && (
            <span
              className={styles.tag}
              style={{ fontSize: adjustedFontSize?.span, cursor: 'pointer' }}
              onClick={tagOnClick ? tagOnClick : null}
            >
              {tag}
            </span>
          )}
        </p>
      </div>
    </div>
  )
}
