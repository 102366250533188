import { ViewType } from '../../components/Views/Views.types'
import { formMatrix } from './form_config'
import { CreateSessionContainer } from './Container'
import { FormProps } from '../../components/Views/Form/Form.types'

export const path = '/createsession'

export const createSessionRoute = {
  path: path,
  view: {
    type: ViewType.FORM,
    name: 'Form Test',
    side: 0,
    formMatrix,
    Container: CreateSessionContainer
  } as FormProps
}
