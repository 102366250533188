import React, { useEffect, useState } from 'react'
import styles from '../SessionSetup.module.scss'
import { CardList } from '../../../components/CardList/CardList'
import { ListInset } from '../../../components/ListInset/ListInset'
import { AnchorItem } from '../../../components/AnchorItem/AnchorItem'
import Loader from '../../../components/Loader/Loader'
import { usePitches } from '../../../metrics_server/pitches/hooks'
import { useFormattedSession } from '../../../metrics_server/sessions/hooks'
import { useFormattedHardware } from '../../../metrics_server/hardware/hooks'
import PitchSetupForm from '../../../components/Forms/PitchSetupForm'
import { pitchSetupTypes } from '../../../metrics_server/pitches/data_types'

export function PitchSetup({ strack, strackReady, setResetStrack }) {
  const pitches = usePitches()
  const formattedSession = useFormattedSession('setup')
  const { pitch } = formattedSession

  // Redraw when pitch changes
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    if (isMounted) {
      setResetStrack(true)
    } else {
      setIsMounted(true)
    }
  }, [pitch])

  // TODO: Remove when roles are introduced
  const isAdmin = true

  // Strack Functions //
  useEffect(() => {
    if (strackReady) {
      strack.generateSetupCanvas(() => {
        if (pitches.setupType === pitchSetupTypes.items.auto.value) {
          strack.drawSelectedSide(pitches.side)
        } else {
          strack.drawSelectedSide(null)
        }
      })
    }
  }, [strackReady])

  useEffect(() => {
    if (strack) {
      strack.drawSelectedSide(pitches.side)
    }
  }, [pitches.side])

  useEffect(() => {
    if (strack) {
      if (pitches.setupType === pitchSetupTypes.items.auto.value) {
        strack.drawSelectedSide(pitches.side)
      } else {
        strack.drawSelectedSide(null)
      }
    }
  }, [pitches.setupType])
  // ================= //

  // Hardware //
  const formattedHardware = useFormattedHardware()

  return (
    <div className={styles.pitchViewContainer}>
      <div
        style={{ height: isAdmin ? '64%' : '48%' }}
        className={styles.broadcastControlsContainer}
      >
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.analasysButtonContainer}>
            {pitches.autoLocateActive ? (
              <div className={styles.autolocateLoader}>
                <Loader />
                <p>Autolocating Anchors...</p>
              </div>
            ) : (
              <PitchSetupForm />
            )}
          </div>
        </CardList>
      </div>
      <div
        style={{ height: isAdmin ? '36%' : '52%' }}
        className={styles.broadcastAnchorsContainer}
      >
        <CardList
          col={12}
          items={[{}]}
          scrollerId={`scroller-${1}`}
          className='maxHeight'
        >
          <div className={styles.containerInner}>
            <ListInset items={formattedHardware.types.anchor.devices.list}>
              <AnchorItem />
            </ListInset>
          </div>
        </CardList>
      </div>
    </div>
  )
}
