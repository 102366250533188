import React, { Component, JSXElementConstructor } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { ConfirmationRequest } from '../../components/ConfirmationRequest/ConfirmationRequest'
import { PlayerForm } from '../../metrics_server/players/components/PlayerForm/PlayerForm'
import { PlayersState, PlayerData } from '../../metrics_server/players/types'
import { SessionsState, SessionData } from '../../metrics_server/sessions/types'
import { TeamsState, Team } from '../../metrics_server/teams/types'

import { sortArrayOfObjectsByKey } from '../../utils/helpers'

import * as teamActions from '../../metrics_server/teams/actions'
import * as playerActions from '../../metrics_server/players/actions'
import * as organisationActions from '../../metrics_server/organisations/actions'
import { getPlayersByTeamId } from '../../metrics_server/players/functions'
import { Action, ActionCreator } from 'redux'
import { getSport } from '../../metrics_server/sports/functions'
import { AppConfig } from '../../App/App.types'

const actions = _.assign({}, teamActions, playerActions, organisationActions)

export interface TeamsViewProps {
  appConfig: AppConfig
  teams: TeamsState
  toggleModal: any
  createPlayer: (values: any, callback: (player: any) => void) => void
  deletePlayer: (player: string, team: string) => void
  editPlayer: (player: string, values: any) => void
  players: PlayersState
  sessions: SessionsState
  getActiveSession: () => SessionData
  setupNewSession: (session: SessionData) => void
  ChildComponent: JSXElementConstructor<any>

  getTeams: ActionCreator<Action>
  createTeam: ActionCreator<Action>
  joinTeam: ActionCreator<Action>
  editTeam: (team: string, values: any) => void
  getTeamPlayers: any
  deleteTeam: ActionCreator<Action>
  filterTeams: ActionCreator<Action>
  setTeam: (team: Team) => void
}

export interface TeamsViewState {
  selectedPlayers: PlayerData[]
  showModal: boolean
  editMode: boolean
  userDataChecked: boolean
}

export const TeamsContainer: any = (ChildComponent) => {
  class ComposedComponent extends Component<TeamsViewProps, TeamsViewState> {
    loadingTimeouts: any[]
    header: any
    teamContent: any
    playerContent: any
    footer: any
    addPlayersModal: any

    constructor(props) {
      super(props)
      this.addPlayersModal = null

      this.state = {
        showModal: false,
        selectedPlayers: [],
        editMode: false,
        userDataChecked: false
      }

      this.loadingTimeouts = []

      this.header = null
      this.teamContent = null
      this.playerContent = null
      this.footer = null
    }

    componentDidMount() {
      const { selectedTeam } = this.props.teams
      this.props.getTeamPlayers(selectedTeam)
    }

    componentDidUpdate(prevProps) {
      const { players } = this.props
      const { selectedTeam, items } = this.props.teams
      const teamPlayers = getPlayersByTeamId(players, selectedTeam)
      const prevTeamPlayers = getPlayersByTeamId(
        prevProps.players,
        selectedTeam
      )
      if (selectedTeam && teamPlayers) {
        if (prevTeamPlayers.length === 1 && teamPlayers.length === 0) {
          this.setState({ editMode: false })
        }
      }

      if (prevProps.teams.selectedTeam !== selectedTeam) {
        this.props.getTeamPlayers(selectedTeam)
      }
    }

    componentWillUnmount() {
      this.loadingTimeouts.forEach((id) => {
        clearTimeout(id)
      })
    }

    /*========== Toggles =========*/

    playerSelectToggle = (player) => {
      const playerSelected = this.state.selectedPlayers.includes(player)
      if (!playerSelected) {
        this.setState({
          selectedPlayers: [...this.state.selectedPlayers, player]
        })
      } else {
        const newArr = this.state.selectedPlayers.filter(
          (x) => x.id !== player.player_id
        )
        this.setState({ selectedPlayers: newArr })
      }
    }

    modalToggle = () => {
      this.setState({ showModal: !this.state.showModal })
    }

    toggleEditMode = () => {
      this.setState({ editMode: !this.state.editMode })
    }

    /*========== Action handlers =========*/

    switchTeam = (teamId) => {
      this.props.setTeam(teamId)
    }

    getSelectedPlayers = (selectedPlayers) => {
      this.setState({
        selectedPlayers: [...this.state.selectedPlayers, ...selectedPlayers]
      })
    }

    removePlayer = (player) => {
      const { players } = this.props
      const { items, selectedTeam } = this.props.teams

      const teamPlayers = getPlayersByTeamId(players, selectedTeam)

      this.props.deletePlayer(player.id, selectedTeam)

      if (teamPlayers.length === 1) this.setState({ editMode: false })
      this.props.toggleModal({})
    }

    /*========== Modals =========*/

    createNewPlayer = () => {
      const teams = this.props.teams.items
      const selectedTeamId = this.props.teams.selectedTeam
      const team = teams[selectedTeamId]
      const sport = getSport(team.sportType)

      this.props.toggleModal({
        active: true,
        type: 'form',
        wrapper: true,
        width: 'auto',
        handleProceed: () => {
          this.props.toggleModal({})
        },
        ChildComponent: () => (
          <PlayerForm
            handleProceed={(playerData) => {
              this.props.toggleModal({})
              this.playerSelectToggle(playerData)
            }}
            sport={sport}
            teamId={selectedTeamId}
          />
        ),
        className: 'modalSmall'
      })
    }

    handleEditClick = (playerData: PlayerData) => {
      this.props.toggleModal({
        active: true,
        type: 'form',
        wrapper: true,
        width: 'auto',
        ChildComponent: () => (
          <PlayerForm
            handleProceed={() => this.props.toggleModal({})}
            playerData={playerData}
          />
        ),
        className: 'modalSmall'
      })
    }

    handleRemoveClick = (player) => {
      this.props.toggleModal({
        active: true,
        type: 'confirm',
        handleProceed: () => {
          this.removePlayer(player)
        },
        ChildComponent: ConfirmationRequest,
        message: 'Are you sure you want to remove this player?',
        className: 'modalSmall',
        handleClose: () => {
          this.props.toggleModal({ active: false })
        }
      })
    }

    render() {
      const { players, teams, sessions } = this.props
      const { editMode, selectedPlayers } = this.state

      const team = teams.items[teams.selectedTeam]
      const teamsArray = Object.values(teams.items)

      let teamPlayers
      if (team) {
        teamPlayers = getPlayersByTeamId(players, team.id)
        teamPlayers = sortArrayOfObjectsByKey(teamPlayers, 'lastName')
      }

      return (
        <ChildComponent
          {...this.props}
          sessions={sessions}
          team={team}
          teams={teams}
          teamsArray={teamsArray}
          teamPlayers={teamPlayers}
          selectedPlayers={selectedPlayers}
          editMode={editMode}
          toggleEditMode={this.toggleEditMode}
          switchTeam={this.switchTeam}
          handleRemoveClick={this.handleRemoveClick}
          handleEditClick={this.handleEditClick}
          createNewPlayer={this.createNewPlayer}
          playerSelectToggle={this.playerSelectToggle}
        />
      )
    }
  }

  function mapStateToProps(state) {
    return {
      teams: state.teams,
      sessions: state.sessions,
      players: state.players,
      router: state.router
    }
  }

  return connect(mapStateToProps, actions)(ComposedComponent)
}
