export const SET_SUCCESS = 'SET_SUCCESS'

export interface SuccessState {
  message?: string
  proceed?: () => void
  type?: string
}

export interface SetSuccessAction {
  type: typeof SET_SUCCESS
  payload: SuccessState
}

export type SetSuccess = (success: SuccessState) => SetSuccessAction
