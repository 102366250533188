import React from 'react'
import styles from './Modal.module.scss'
import { ModalSettings } from './Modal.types'

import ModalWrapper from './ModalWrapper'

interface ModalContentProps {
  modal: ModalSettings
}

const ModalContent = (props: ModalContentProps) => {
  const {
    ChildComponent,
    handleProceed,
    handleClose,
    className,
    hideCancel,
    message,
    width,
    wrapper,
    height,
    title
  } = props.modal

  return (
    <div
      style={{
        width: width ? width : '35%',
        height: height ? height : 'auto'
      }}
      className={styles[className]}
    >
      {wrapper ? (
        <ModalWrapper title={title} handleClose={handleClose}>
          <ChildComponent
            message={message}
            hideCancel={hideCancel}
            onSubmit={handleProceed}
            handleClose={handleClose}
            handleProceed={handleProceed}
            {...props.modal}
          />
        </ModalWrapper>
      ) : (
        <ChildComponent
          message={message}
          hideCancel={hideCancel}
          onSubmit={handleProceed}
          handleClose={handleClose}
          handleProceed={handleProceed}
          {...props.modal}
        />
      )}
    </div>
  )
}

export default ModalContent
