import React from 'react'
import { Tabs } from './Tabs/Tabs'
import { Tab } from '../Dashboard.types'

interface SideNavbarProps {
  tabsArray: Tab[]
  selectedTabKey: string
  switchTab: (tabKey: string) => void
}

export const SideNavBar = ({
  tabsArray,
  selectedTabKey,
  switchTab
}: SideNavbarProps) => {
  return (
    <>
      <Tabs
        tabs={tabsArray}
        selectedTabKey={selectedTabKey}
        switchTab={switchTab}
      />
    </>
  )
}
