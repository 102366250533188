import React from 'react'
import styles from './TabTitle.module.scss'

interface TabTitleProps {
  title: string | JSX.Element
}

export function TabTitle({ title }: TabTitleProps) {
  return (
    <div className={styles.headingContainer}>
      <div>{title && title !== 'undefined' ? title : undefined}</div>
    </div>
  )
}

export default TabTitle
