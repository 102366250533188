import React, { ReactElement, useState } from 'react'
import styles from './PitchOverlayCard.module.scss'

export interface PitchOverlayCardProps {
  position: string
  width: string
  height: string
  children: ReactElement<any, any>
  dropdown: boolean
}

export const PitchOverlayCard = ({
  position,
  width,
  height,
  children,
  dropdown
}: PitchOverlayCardProps) => {
  const [expanded, setExpanded] = useState(true)

  let style = {
    width,
    height: expanded ? height : '34px',
    top: null,
    bottom: null
  }

  if (position !== 'bottom') {
    style.bottom = 0
  } else {
    style.top = 0
  }

  return (
    <div style={style} className={styles.pitchOverlayContainer}>
      {dropdown && (
        <div
          onClick={() => setExpanded(!expanded)}
          className={`${styles.closeContainer}`}
        >
          <div
            className={`${styles.arrow} ${expanded ? styles.up : styles.down}`}
          ></div>
        </div>
      )}
      {children}
    </div>
  )
}
