import { useState } from 'react'

export const usePlayerShirtTagAssignmentLogic = (teamId: string) => {
  const [playersOrTagsView, setPlayersOrTagsView] = useState(0)
  const [shirtsOrTeamListView, setShirtsOrTeamListView] = useState(0)

  const playersView = playersOrTagsView === 0
  const shirtsView = shirtsOrTeamListView === 0

  return {
    setShirtsOrTeamListView,
    shirtsOrTeamListView,
    playersOrTagsView,
    setPlayersOrTagsView,
    shirtsView,
    playersView
  }
}
