import React, { useState } from 'react'
import { sportableColors } from '../../../constants/sportableColors'

export interface NotificationsBellProps {
  scale?: number
  color?: string
  isVisible: boolean
  style?: React.CSSProperties
}

export const NotificationsBell: React.FC<NotificationsBellProps> = ({
  scale = 1,
  color = 'white',
  isVisible,
  style
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <svg
        id='notificationBell'
        width={`${23 * scale}`}
        height={`${23 * scale}`}
        viewBox='0 0 36 36'
        stroke={
          isVisible || isHovered ? sportableColors.colors.sportableBlue : color
        }
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M32.51 27.83C31.5457 26.9703 30.7015 25.9848 30 24.9C29.2342 23.4025 28.7752 21.7672 28.65 20.09V15.15C28.6566 12.5156 27.701 9.96948 25.9627 7.98997C24.2245 6.01047 21.8232 4.73386 19.21 4.4V3.11C19.21 2.75594 19.0694 2.41638 18.819 2.16601C18.5686 1.91565 18.2291 1.775 17.875 1.775C17.5209 1.775 17.1814 1.91565 16.931 2.16601C16.6807 2.41638 16.54 2.75594 16.54 3.11V4.42C13.9503 4.77793 11.578 6.06226 9.86253 8.03511C8.14709 10.008 7.20473 12.5356 7.21 15.15V20.09C7.08476 21.7672 6.62578 23.4025 5.86 24.9C5.17089 25.9824 4.3402 26.9677 3.39 27.83C3.28333 27.9237 3.19784 28.0391 3.13922 28.1684C3.0806 28.2977 3.05018 28.438 3.05 28.58V29.94C3.05 30.2052 3.15536 30.4596 3.3429 30.6471C3.53043 30.8346 3.78479 30.94 4.05 30.94H31.85C32.1152 30.94 32.3696 30.8346 32.5571 30.6471C32.7446 30.4596 32.85 30.2052 32.85 29.94V28.58C32.8498 28.438 32.8194 28.2977 32.7608 28.1684C32.7022 28.0391 32.6167 27.9237 32.51 27.83ZM5.13 28.94C6.06041 28.0412 6.8796 27.034 7.57 25.94C8.53462 24.1315 9.09743 22.136 9.22 20.09V15.15C9.18034 13.978 9.37693 12.8101 9.79805 11.7157C10.2192 10.6213 10.8562 9.62279 11.6713 8.7797C12.4863 7.93662 13.4626 7.26617 14.5422 6.80828C15.6217 6.35038 16.7824 6.11441 17.955 6.11441C19.1276 6.11441 20.2883 6.35038 21.3678 6.80828C22.4474 7.26617 23.4237 7.93662 24.2388 8.7797C25.0538 9.62279 25.6908 10.6213 26.112 11.7157C26.5331 12.8101 26.7297 13.978 26.69 15.15V20.09C26.8126 22.136 27.3754 24.1315 28.34 25.94C29.0304 27.034 29.8496 28.0412 30.78 28.94H5.13Z'
          fill={
            isVisible || isHovered
              ? sportableColors.colors.sportableBlue
              : color
          }
        />
        <path
          d='M18 34.28C18.6299 34.2655 19.2344 34.0287 19.7066 33.6114C20.1788 33.1942 20.4881 32.6234 20.58 32H15.32C15.4145 32.6403 15.7383 33.2245 16.2313 33.6439C16.7243 34.0633 17.3528 34.2894 18 34.28V34.28Z'
          fill={
            isVisible || isHovered
              ? sportableColors.colors.sportableBlue
              : color
          }
        />
      </svg>
    </div>
  )
}
