import React from 'react'
import { sportableColors } from '../../../constants/sportableColors'

export const QAWarningUnknownIcon = ({ color, scale }) => {
  return (
    <svg
      width='23'
      height='22'
      viewBox='0 0 23 22'
      transform={`scale(${scale})`}
      stroke={color || undefined}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.67436 2.00565C9.85964 1.68551 10.1259 1.41971 10.4463 1.23493C10.7667 1.05015 11.1301 0.952881 11.5 0.952881C11.8699 0.952881 12.2333 1.05015 12.5537 1.23493C12.8742 1.41971 13.1404 1.68551 13.3257 2.00565L22.6044 18.0362C22.79 18.3568 22.8879 18.7207 22.8882 19.0912C22.8885 19.4616 22.7913 19.8257 22.6062 20.1466C22.4211 20.4676 22.1548 20.7341 21.834 20.9195C21.5132 21.1048 21.1492 21.2024 20.7787 21.2023H2.2213C1.85081 21.2024 1.48686 21.1048 1.16605 20.9195C0.845242 20.7341 0.578905 20.4676 0.393838 20.1466C0.208771 19.8257 0.111501 19.4616 0.111817 19.0912C0.112133 18.7207 0.210024 18.3568 0.395639 18.0362L9.67446 2.00576L9.67436 2.00565ZM11.5 1.79682C11.2778 1.79551 11.0593 1.85332 10.8668 1.96432C10.6743 2.07532 10.5148 2.23551 10.4046 2.42848L1.1259 18.4588C1.01456 18.6512 0.955849 18.8695 0.955672 19.0917C0.955496 19.314 1.01386 19.5324 1.1249 19.725C1.23594 19.9175 1.39573 20.0775 1.5882 20.1886C1.78067 20.2998 1.99902 20.3584 2.2213 20.3584H20.7787C21.001 20.3584 21.2194 20.2998 21.4118 20.1886C21.6043 20.0775 21.7641 19.9175 21.8751 19.725C21.9862 19.5324 22.0445 19.314 22.0444 19.0917C22.0442 18.8695 21.9855 18.6512 21.8741 18.4588L12.5954 2.42848C12.4852 2.23551 12.3257 2.07532 12.1333 1.96432C11.9408 1.85332 11.7222 1.79551 11.5 1.79682V1.79682Z'
        fill={sportableColors.colors.sportableGreyDark}
      />
      <path
        d='M13.2451 8.82056C12.7762 8.29032 12.1572 8 11.5008 8C10.8443 8 10.2254 8.29234 9.75649 8.82056C9.26883 9.37097 9 10.1109 9 10.9032V11.0565C9 11.1452 9.05627 11.2177 9.12504 11.2177H9.87527C9.94404 11.2177 10.0003 11.1452 10.0003 11.0565V10.9032C10.0003 10.0141 10.674 9.29032 11.5008 9.29032C12.3276 9.29032 13.0012 10.0141 13.0012 10.9032C13.0012 11.5302 12.6574 12.1048 12.1244 12.369C11.7931 12.5323 11.5117 12.8185 11.3101 13.1935C11.1053 13.5766 10.9991 14.0363 10.9991 14.502V14.9355C10.9991 15.0242 11.0553 15.0968 11.1241 15.0968H11.8743C11.9431 15.0968 11.9994 15.0242 11.9994 14.9355V14.4778C12.0002 14.2821 12.0467 14.0912 12.1328 13.9301C12.219 13.769 12.3408 13.6451 12.4823 13.5746C13.4045 13.1169 14 12.0685 14 10.9032C14.0016 10.1109 13.7327 9.37097 13.2451 8.82056ZM10.8756 17.1935C10.8756 17.4074 10.9415 17.6126 11.0587 17.7638C11.1759 17.915 11.335 18 11.5008 18C11.6666 18 11.8256 17.915 11.9429 17.7638C12.0601 17.6126 12.126 17.4074 12.126 17.1935C12.126 16.9797 12.0601 16.7745 11.9429 16.6233C11.8256 16.4721 11.6666 16.3871 11.5008 16.3871C11.335 16.3871 11.1759 16.4721 11.0587 16.6233C10.9415 16.7745 10.8756 16.9797 10.8756 17.1935Z'
        fill={sportableColors.colors.sportableGreyDark}
      />
    </svg>
  )
}
