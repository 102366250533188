import { TweenMax, Power4 } from 'gsap'

export default {
  slideInHeader(target, duration, cb) {
    if (!target) return
    return TweenMax.from(target, duration, {
      ease: Power4.easeInOut,
      transform: 'translateY(-120%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideOutHeader(target, duration, cb) {
    if (!target) return
    return TweenMax.to(target, duration, {
      ease: Power4.easeInOut,
      transform: 'translateY(-120%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideOutFooter(target, duration, cb) {
    if (!target) return
    return TweenMax.to(target, duration, {
      ease: Power4.easeInOut,
      transform: 'translateY(130%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideInFooter(target, duration, cb) {
    if (!target) return
    return TweenMax.from(target, duration, {
      ease: Power4.easeInOut,
      transform: 'translateY(130%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  showContent(target, duration, cb) {
    if (!target) return
    return TweenMax.to(target, duration, {
      ease: Power4.easeInOut,
      opacity: 1,
      onComplete() {
        if (cb) cb()
      }
    })
  },
  hideContent(target, duration, cb) {
    if (!target) return
    return TweenMax.to(target, duration, {
      ease: Power4.easeInOut,
      opacity: 0,
      onComplete: () => {
        if (cb) cb()
      }
    })
  },
  slideInRight(target, duration, cb) {
    if (!target) return
    return TweenMax.from(target, duration, {
      transform: 'translateX(100%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideOutLeft(target, duration, cb) {
    if (!target) return
    return TweenMax.to(target, duration, {
      transform: 'translateX(-100%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideUpSelect(target, duration, cb) {
    if (!target) return
    return TweenMax.to(target, duration, {
      transform: 'translateY(0)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideDownSelect(target, duration, cb) {
    if (!target) return
    return TweenMax.to(target, duration, {
      transform: 'translateY(140%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideDownTitle(target, duration, cb) {
    if (!target) return
    return TweenMax.to(target, duration, {
      transform: 'translateY(0%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideUpTitle(target, duration, cb) {
    if (!target) return
    return TweenMax.to(target, duration, {
      transform: 'translateY(-150%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideInSettingsContainer(target, duration, cb) {
    if (!target) return
    return TweenMax.to(target, duration, {
      transform: 'translateX(0)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideOutSettingsContainer(target, duration, cb) {
    if (!target) return
    return TweenMax.to(target, duration, {
      transform: 'translateX(100%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },

  // Form view
  slideOutLeftBackground(target, cb) {
    if (!target) return
    return TweenMax.to(target, 1, {
      ease: Power4.easeInOut,
      transform: 'translateX(-100%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideInLeftBackground(target, cb) {
    if (!target) return
    return TweenMax.to(target, 1, {
      ease: Power4.easeInOut,
      transform: 'translateX(100%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideOutRightBackground(target, cb) {
    if (!target) return
    return TweenMax.to(target, 0.5, {
      ease: Power4.easeInOut,
      transform: 'translateX(-50%)',
      onComplete() {
        if (cb) cb()
      }
    })
  },
  slideInRightBackground(target, cb) {
    if (!target) return
    return TweenMax.to(target, 0.5, {
      ease: Power4.easeInOut,
      transform: 'translateX(-50%)',
      onComplete() {
        if (cb) cb()
      }
    })
  }
}
