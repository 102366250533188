import React, { useState } from 'react'
import { containsNumber } from '../../../utils/helpers'
import { AuthenticationForms } from '../form_config'
import { validatePassword } from '../../../metrics_server/user/functions'
import { AuthFormWithPasswordValidation } from '../../../metrics_server/user/components/AuthFormWithPasswordValidation'
import { FormValidationErrors } from '../../../metrics_server/user/types'

type CreatePasswordFormValues = {
  firstName: string
  lastName: string
  password: string
  password_confirmation: string
}

const validate = (values) => {
  const errors: FormValidationErrors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (containsNumber(values.firstName)) {
    errors.firstName = 'Contains a number'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  if (containsNumber(values.lastName)) {
    errors.lastName = 'Contains a number'
  }

  return { ...errors, ...validatePassword(values) }
}

export interface CreatePasswordFormProps {
  switchForm: (formKey: string) => void
  signupUser: (values, loadingId, cb) => void
}

export const CreatePasswordForm = (props: CreatePasswordFormProps) => {
  const { switchForm, signupUser } = props

  const [fields] = useState([
    {
      name: 'firstName',
      type: 'text',
      label: 'First name'
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Last name'
    },
    {
      name: 'password',
      type: 'text',
      textType: 'password',
      label: 'Password'
    },
    {
      name: 'password_confirmation',
      type: 'text',
      textType: 'password',
      label: 'Confirm password'
    }
  ])

  const handleSubmit = (values: CreatePasswordFormValues) => {
    signupUser(values, AuthenticationForms.createPassword, () =>
      switchForm(AuthenticationForms.signin)
    )
  }

  return (
    <AuthFormWithPasswordValidation
      fields={fields}
      validate={validate}
      handleSubmit={handleSubmit}
    />
  )
}
