import React, { useEffect, useState } from 'react'
import { PlayerData } from '../../metrics_server/players/types'
import { AddNewButton } from '../AddNewButton/AddNewButton'
import { Player } from '../Player/Player'
import Scroller from '../Scroller/Scroller'
import styles from './TeamGrid.module.scss'

export interface TeamGridProps {
  addPlayer?: () => void
  editPlayer?: (player: PlayerData) => void
  playerSelectToggle: (player: PlayerData) => void
  editMode?: boolean
  removePlayer?: (player: PlayerData) => void
  players: PlayerData[]
  selectedPlayers: PlayerData[]
  playersInTeam?: PlayerData[]
  id?: string
}

export const TeamGrid = (props: TeamGridProps) => {
  const {
    addPlayer,
    editPlayer,
    playerSelectToggle,
    editMode,
    removePlayer,
    players,
    selectedPlayers,
    playersInTeam,
    id
  } = props

  const [playersArr, setPlayersArr] = useState([])

  useEffect(() => {
    if (!players) return
    const newPlayersArr = players.map((player) => {
      if (playersInTeam && playersInTeam.includes(player)) return
      let isActive = selectedPlayers.some((item) => player.id === item.id)
      return (
        <Player
          key={player.id}
          id='player-card'
          editPlayer={editPlayer}
          removePlayer={removePlayer}
          editMode={editMode}
          className={isActive && !addPlayer ? 'active' : 'inactive'}
          player={player}
          selectPlayer={() => {
            if (!addPlayer) {
              playerSelectToggle(player)
            }
          }}
          addPlayer={addPlayer}
        />
      )
    })
    setPlayersArr(newPlayersArr)
  }, [
    players,
    selectedPlayers,
    playersInTeam,
    addPlayer,
    editPlayer,
    playerSelectToggle,
    editMode,
    removePlayer
  ])

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Scroller scrollerId={id} scrollerClass='form-scroller'>
        <div className={styles.teamGridContainer}>
          <AddNewButton
            addCallBack={props.addPlayer}
            editMode={props.editMode}
            size={45}
            title={'Add Player'}
            horizontal={true}
          />
          {playersArr}
        </div>
      </Scroller>
    </div>
  )
}

export default TeamGrid
