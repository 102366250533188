import { features } from 'process'
import {
  DataTypeConfig,
  DataTypeKey,
  DataTypeKeys,
  DataTypes,
  DataTypeValues,
  getDataTypeGroup
} from '../../../data_types'
import { EventTypeProps } from '../../data_types'
import {
  AussieRulesEventFeatures,
  defaultAussieRulesEventFeatures
} from '../data_types'

export const aussieRulesEventsCrossSectionTypeConfig = {
  goal: {
    key: 'goal',
    value: 1,
    name: 'Goal',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultAussieRulesEventFeatures
    }
  },
  leftBehind: {
    key: 'leftBehind',
    value: 2,
    name: 'Left Behind',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultAussieRulesEventFeatures
    }
  },
  rightBehind: {
    key: 'rightBehind',
    value: 3,
    name: 'Right Behind',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultAussieRulesEventFeatures
    }
  },
  leftOutOfBounds: {
    key: 'leftOutOfBounds',
    value: 4,
    name: 'Left Out of Bounds',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultAussieRulesEventFeatures
    }
  },
  rightOutOfBounds: {
    key: 'rightOutOfBounds',
    value: 5,
    name: 'Right Out of Bounds',
    props: {
      types: null,
      metricTypes: null,
      outcomeTypes: null,
      features: defaultAussieRulesEventFeatures
    }
  }
  // ======= //
} as const

export type AussieRulesEventCrossSectionTypeProps = EventTypeProps<
  null,
  AussieRulesEventFeatures
>

export type AussieRulesEventCrossSectionTypes = DataTypes<
  typeof aussieRulesEventsCrossSectionTypeConfig
>

export type AussieRulesEventCrossSectionTypeKey =
  DataTypeKey<AussieRulesEventCrossSectionTypes>

export type AussieRulesEventCrossSectionTypeKeys =
  DataTypeKeys<AussieRulesEventCrossSectionTypeKey>

export type AussieRulesEventCrossSectionTypeValues =
  DataTypeValues<AussieRulesEventCrossSectionTypeKey>

export const getAussieRulesEventCrossSectionTypeGroup = (items: {
  [key in AussieRulesEventCrossSectionTypeKeys]?: AussieRulesEventCrossSectionTypes[key]
}) => {
  return getDataTypeGroup<
    AussieRulesEventCrossSectionTypeKeys,
    AussieRulesEventCrossSectionTypeValues,
    AussieRulesEventCrossSectionTypes,
    AussieRulesEventCrossSectionTypeProps
  >(items)
}

export type AussieRulesEventCrossSectionTypeGroup = ReturnType<
  typeof getAussieRulesEventCrossSectionTypeGroup
>

export type AussieRulesEventCrossSectionTypeConfig = DataTypeConfig<
  AussieRulesEventCrossSectionTypeKeys,
  AussieRulesEventCrossSectionTypeValues,
  AussieRulesEventCrossSectionTypeProps
>

export const gameEventSubTypes = getAussieRulesEventCrossSectionTypeGroup(
  aussieRulesEventsCrossSectionTypeConfig
)

export type AussieRulesEventCrossSectionType = ReturnType<
  typeof gameEventSubTypes.getTypeByValue
>
