import React, { Component, useState, useCallback } from 'react'
import { useCommentatorWidgetGridLogic } from './CommentatorWidgetGrid.logic'
import { useAppDispatch } from '../../store/hooks'
import {
  useInsights,
  useSelectedLayout
} from '../../metrics_server/insights/hooks'
import {
  editInsightsLayout,
  removeInsightsLayoutItem
} from '../../metrics_server/insights/actions'

import {
  InsightsDashboardLayout,
  ReactGridLayout,
  LayoutType
} from '../../metrics_server/insights/types'

import { CardList } from '../CardList/CardList'
import { InsightsCard } from '../InsightsCard/InsightsCard'
import { Responsive, WidthProvider } from 'react-grid-layout'

import styles from './CommentatorWidgetGrid.module.scss'
import './styles.css'
import { SessionData } from '../../metrics_server/sessions/types'

const ResponsiveGridLayout = WidthProvider(Responsive)

export interface CommentatorWidgetGridProps {
  session: SessionData
}

export const CommentatorWidgetGrid = ({ session }) => {
  const dispatch = useAppDispatch()

  const { selectedLayout } = useSelectedLayout(LayoutType.sessionLayout)

  const handleLayoutChange = useCallback(
    (gridLayout: ReactGridLayout) => {
      const editedLayout: InsightsDashboardLayout = {
        ...selectedLayout,
        layout: {
          ...selectedLayout.layout,
          reactGridLayouts: {
            ...selectedLayout.layout.reactGridLayouts,
            cards: {
              ...selectedLayout.layout.reactGridLayouts.cards,
              layout: gridLayout
            }
          }
        }
      }
      dispatch(editInsightsLayout(editedLayout))
    },
    [selectedLayout]
  )

  const renderInsightsCard = (layoutItem) => {
    const contentId =
      selectedLayout.layout.reactGridLayouts.cards.contentMap[layoutItem.i]

    return (
      <div key={layoutItem.i}>
        <InsightsCard
          layoutItem={layoutItem}
          contentId={contentId}
          session={session}
          removeItem={() => {
            dispatch(
              removeInsightsLayoutItem(selectedLayout.id, layoutItem.i, 'cards')
            )
          }}
        />
      </div>
    )
  }

  if (!selectedLayout) return <noscript />

  return (
    <ResponsiveGridLayout
      className='layout'
      layouts={{
        lg: selectedLayout.layout.reactGridLayouts.cards.layout,
        md: selectedLayout.layout.reactGridLayouts.cards.layout,
        sm: selectedLayout.layout.reactGridLayouts.cards.layout,
        xs: selectedLayout.layout.reactGridLayouts.cards.layout,
        xxs: selectedLayout.layout.reactGridLayouts.cards.layout
      }}
      // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 5, md: 5, sm: 5, xs: 5, xxs: 5 }}
      width={'100%'}
      // preventCollision={true}
      onLayoutChange={handleLayoutChange}
      onDragStop={handleLayoutChange}
      onResizeStop={handleLayoutChange}
      isResizable={false}
      // autoSize={false}
      isBounded={true}
      compactType={'horizontal'}
      measureBeforeMount={true}
      useCSSTransforms={false}
    >
      {selectedLayout.layout.reactGridLayouts.cards.layout.map(
        renderInsightsCard
      )}
    </ResponsiveGridLayout>
  )
}
