import { sportableColors } from '../../../const/index'

export function drawLine(
  x1,
  y1,
  x2,
  y2,
  ctx,
  lineDash,
  width,
  color,
  scaleCoord,
  pixelScale
) {
  const from = scaleCoord(pixelScale, x1, y1),
    to = scaleCoord(pixelScale, x2, y2)
  ctx.beginPath()
  if (lineDash) {
    lineDash = lineDash.map((x) => x * pixelScale)
    ctx.setLineDash(lineDash)
  } else {
    ctx.setLineDash([])
  }
  ctx.moveTo(from.scaleX, from.scaleY)
  ctx.lineTo(to.scaleX, to.scaleY)
  ctx.lineWidth = width || 3
  ctx.strokeStyle = color || 'white'
  ctx.stroke()
}

export const drawCircle = (
  x,
  y,
  ctx,
  radius,
  color = 'black',
  fillColor = 'transparent',
  lineWidth = 2,
  scaleCoord,
  pixelScale,
  callback,
  drawCrosshairs = false, // new parameter
  crosshairLength = radius / 2, // length of the crosshair lines, default to half the radius
  crosshairColor = 'yellow', // color of the crosshairs, default to red
  crosshairWidth = 1 // width of the crosshair lines, default to 1
) => {
  const center = scaleCoord(pixelScale, x, y)
  ctx.beginPath()
  ctx.lineWidth = lineWidth
  ctx.arc(center.scaleX, center.scaleY, radius, 0, Math.PI * 2, false)
  ctx.closePath()
  ctx.fillStyle = fillColor
  ctx.strokeStyle = color
  if (fillColor) {
    ctx.fill()
  }

  ctx.stroke()

  // draw crosshairs if drawCrosshairs is true
  if (drawCrosshairs) {
    drawPlus(
      x,
      y,
      ctx,
      crosshairLength,
      crosshairWidth,
      crosshairColor,
      scaleCoord,
      pixelScale
    )
  }

  if (callback) callback()
}

export const drawX = (
  x,
  y,
  ctx,
  lineLength,
  lineWidth,
  color = 'red',
  scaleCoord,
  pixelScale,
  callback
) => {
  const center = scaleCoord(pixelScale, x, y)
  ctx.beginPath()
  ctx.setLineDash([])
  ctx.lineWidth = lineWidth
  ctx.moveTo(center.scaleX - lineLength, center.scaleY - lineLength)
  ctx.lineTo(center.scaleX + lineLength, center.scaleY + lineLength)
  ctx.strokeStyle = color
  ctx.stroke()

  ctx.moveTo(center.scaleX + lineLength, center.scaleY - lineLength)
  ctx.lineTo(center.scaleX - lineLength, center.scaleY + lineLength)
  ctx.strokeStyle = color
  ctx.stroke()
}

export const drawPlus = (
  x,
  y,
  ctx,
  lineLength,
  lineWidth,
  color = 'red',
  scaleCoord,
  pixelScale,
  callback
) => {
  const center = scaleCoord(pixelScale, x, y)
  ctx.beginPath()
  ctx.setLineDash([])
  ctx.lineWidth = lineWidth
  ctx.moveTo(center.scaleX, center.scaleY - lineLength)
  ctx.lineTo(center.scaleX, center.scaleY + lineLength)
  ctx.strokeStyle = color
  ctx.stroke()

  ctx.moveTo(center.scaleX - lineLength, center.scaleY)
  ctx.lineTo(center.scaleX + lineLength, center.scaleY)
  ctx.strokeStyle = color
  ctx.stroke()
}

export function drawMapCircle(
  ctx,
  x,
  y,
  t,
  n,
  defLine,
  selected,
  color,
  fillColor,
  radius
) {
  if (!t) {
    if (selected) {
      ctx.beginPath()
      ctx.lineWidth = 2
      ctx.arc(x, y, radius ? radius : 9, 0, Math.PI * 2, false)
      ctx.closePath()
      ctx.fillStyle = '#ED975E'
      ctx.strokeStyle = '#4e4e4e'
      ctx.fill()
      ctx.stroke()
    } else {
      ctx.beginPath()
      ctx.lineWidth = 2
      ctx.arc(x, y, 9, 0, Math.PI * 2, false)
      ctx.closePath()
      ctx.fillStyle = '#fdff00'
      ctx.strokeStyle = '#4e4e4e'
      ctx.fill()
      ctx.stroke()
    }
  } else {
    ctx.beginPath()
    ctx.lineWidth = 2
    ctx.arc(x, y, 9, 0, Math.PI * 2, false)
    ctx.closePath()
    if (t === 'B') {
      if (defLine) {
        ctx.fillStyle = 'rgba(120, 161, 255, 0.4)'
        ctx.strokeStyle = '#ED975E'
      } else {
        ctx.fillStyle = 'rgba(0, 0, 0, 0.4)'
        ctx.strokeStyle = color ? color : 'black'
      }
    } else if (t == 'A') {
      if (defLine) {
        ctx.fillStyle = 'rgba(246, 70, 69, 0.6)'
        ctx.strokeStyle = '#E3E666'
      } else {
        ctx.fillStyle = 'rgba(246, 70, 69, 0.6)'
        ctx.strokeStyle = 'rgba(246, 70, 69, 1)'
        ctx.fillStyle = 'rgba(0, 0, 0, 0.4)'
        ctx.strokeStyle = color ? color : 'rgba(246, 70, 69, 1)'
      }
    }
    ctx.fill()
    ctx.stroke()

    // ctx.textAlign = "center";
    // ctx.fillStyle = "#FFFFFF";
    // ctx.strokeStyle = "#FFFFFF";
    // ctx.font = "13px Verdana";
    // ctx.fillText(n, x, y + 6);
  }
}

export function draw2DCircle(
  ctx,
  x,
  y,
  color,
  fillColor,
  radius,
  lineWidth,
  lineDash = []
) {
  ctx.beginPath()
  ctx.lineWidth = lineWidth
  ctx.setLineDash(lineDash)
  ctx.arc(x, y, radius - lineWidth / 2, 0, Math.PI * 2)
  ctx.closePath()
  ctx.fillStyle = fillColor
  ctx.strokeStyle = color
  ctx.fill()
  ctx.stroke()
}

export function getCrossfieldDistances(
  fieldHeight,
  dimensions,
  pitchConfig,
  exitedPitchX,
  exitedPitchY
) {
  const crossfieldLines = pitchConfig.props.pitch.crossfieldLines
  if (!crossfieldLines) return
  const coordinates = dimensions

  let leftDistance = null
  let rightDistance = null
  let leftLineName = ''
  let rightLineName = ''
  let measurePoint
  let leftLineX = null
  let rightLineX = null

  // Set exitedPitchY to 0 if undefined or less than 0
  if (exitedPitchY === undefined || exitedPitchY < 0) {
    exitedPitchY = 0
  }

  // if exitedPitchY is greater than the upper y of the pitch, set it to the upper y
  const halfwayCoord = pitchConfig.props.pitch.crossfieldLines.filter(
    (line) => line.name === 'Halfway line'
  )[0].upper
  const pitchHeight = dimensions[halfwayCoord].y
  if (exitedPitchY > pitchHeight) {
    exitedPitchY = pitchHeight
  }

  crossfieldLines.forEach((line) => {
    const lowerX = coordinates[line.lower].x
    const upperX = coordinates[line.upper].x

    measurePoint =
      Math.abs(fieldHeight - exitedPitchY) < Math.abs(0 - exitedPitchY)
        ? upperX
        : lowerX
    // Check left distance
    if (exitedPitchX > measurePoint) {
      const dist = Math.abs(exitedPitchX - measurePoint)
      if (leftDistance === null || dist < leftDistance) {
        leftDistance = dist
        leftLineName = line.name
        leftLineX = measurePoint
      }
    }

    // Check right distance
    if (exitedPitchX < measurePoint) {
      const dist = Math.abs(measurePoint - exitedPitchX)
      if (rightDistance === null || dist < rightDistance) {
        rightDistance = dist
        rightLineName = line.name
        rightLineX = measurePoint
      }
    }
  })
  return {
    leftDistance,
    rightDistance,
    leftLineName,
    rightLineName,
    leftLineX,
    rightLineX
  }
}

export const drawDistances = (
  dimensions,
  x,
  y,
  ctx,
  leftDistance,
  rightDistance,
  leftLineX,
  rightLineX,
  scaleCoord,
  pixelScale,
  pitchConfig,
  unitSystem,
  pitchFlipped
) => {
  if (leftDistance === null || rightDistance === null) return
  const { scaleX, scaleY } = scaleCoord(pixelScale, x, y || 0)

  const halfwayCoord = pitchConfig.props.pitch.crossfieldLines.filter(
    (line) => line.name === 'Halfway line'
  )[0].upper
  const pitchHeight = dimensions[halfwayCoord].y
  const fixedOffset = 60 // Adjusted value to move the labels further inside.

  // Adjust the offset based on which half of the pitch we are in and if the pitch is flipped.
  const offset = pitchFlipped
    ? y < pitchHeight / 2
      ? fixedOffset
      : -fixedOffset
    : y < pitchHeight / 2
    ? -fixedOffset
    : fixedOffset

  // Rest of the code remains the same...

  // Round the distances to 1 decimal place
  const roundedLeftDistance = leftDistance.toFixed(
    unitSystem ? unitSystem.units.distance.conversion : 1
  )
  const roundedRightDistance = rightDistance.toFixed(
    unitSystem ? unitSystem.units.distance.conversion : 1
  )

  // Set the text vertical position
  const rightTextVerticalPosition = scaleY + offset + 35
  const leftTextVerticalPosition = scaleY + offset

  // Set the font, color and alignment
  ctx.font = 'bold 35px Arial'
  ctx.fillStyle = 'black' // Setting the text color to black
  ctx.textAlign = 'center'

  // Calculate the positions for the distances
  const leftDistanceX =
    leftLineX !== null ? scaleCoord(pixelScale, leftLineX, y).scaleX : scaleX
  const rightDistanceX =
    rightLineX !== null ? scaleCoord(pixelScale, rightLineX, y).scaleX : scaleX

  // Draw left distance
  ctx.fillText(
    unitSystem
      ? roundedLeftDistance + unitSystem.units.distance.abbreviation
      : roundedLeftDistance,
    leftDistanceX,
    leftTextVerticalPosition
  )

  // Draw right distance
  ctx.fillText(
    unitSystem
      ? roundedRightDistance + unitSystem.units.distance.abbreviation
      : roundedRightDistance,
    rightDistanceX,
    rightTextVerticalPosition
  )
  ctx.save()
  ctx.strokeStyle = 'black'
  ctx.lineWidth = 2
  ctx.beginPath()
  ctx.moveTo(leftDistanceX, scaleY)
  ctx.lineTo(leftDistanceX, leftTextVerticalPosition)
  ctx.stroke()

  ctx.beginPath()
  ctx.moveTo(rightDistanceX, scaleY)
  ctx.lineTo(rightDistanceX, rightTextVerticalPosition)
  ctx.stroke()

  ctx.restore()
}
