import Theme from './theme'
import { SET_APP_CONFIG } from './action_types'
import { ConfigState } from './types'

const initialState: ConfigState = {
  application: {
    landing: null,
    isLocal: false,
    routes: {},
    theme: Theme.matchTracker
  }
}

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_CONFIG:
      return {
        ...state,
        application: action.payload
      }
    default:
      return state
  }
}
