export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'

export type LoadingState = string[]

export type StartLoadingAction = {
  type: typeof START_LOADING
  payload: string
}

export type StartLoading = (componentId: string) => StartLoadingAction

export type StopLoadingAction = {
  type: typeof STOP_LOADING
  payload: string
}

export type StopLoading = (componentId: string) => StopLoadingAction
