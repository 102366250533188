import React from 'react'
import { sportableColors } from '../../const/index'
import { useTheme } from '../../ui/config/hook'
import styles from './TeamLogo.module.scss'
interface TeamLogoProps {
  color?: string
  logo: string
  size: number
}

export const TeamLogo = ({ color, logo, size }: TeamLogoProps) => {
  const theme = useTheme()
  const strokeColor =
    color || (theme ? theme.appColor : sportableColors.colors.sportableBlue)

  return (
    <div className={styles.teamImg}>
      {logo ? (
        <img id='team-logo' height={`${size}px`} src={logo} alt='' />
      ) : (
        <svg viewBox='0 0 46 50' height={`${size}px`} version='1.1'>
          <defs />
          <g
            id='Page-1'
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
          >
            <g
              id='Placeholder/team'
              transform='translate(-2.000000, 0.000000)'
              strokeWidth='2'
            >
              <g id='Group' transform='translate(3.000000, 2.000000)'>
                <path
                  stroke={strokeColor}
                  d='M44,11.5 C44,28.3666667 36.6666667,39.8666667 22,46 C7.33333333,39.8666667 1.05167746e-15,28.3666667 0,11.5 L22,0 L44,11.5 Z'
                  id='Path-3'
                  fill='#FFFFFF'
                />
                <path
                  stroke={strokeColor}
                  d='M22,42.0628377 C27.6726224,39.5180015 32.2147397,35.6056758 35.6263519,30.3258606'
                  id='Path-16'
                />
                <path
                  stroke={strokeColor}
                  d='M5.50059699,22.6050326 C4.82812972,20.4344044 4.32793073,17.8993935 4,15 L22,5.77165568 L25.7541204,7.80200704'
                  id='Path-15'
                />
              </g>
            </g>
          </g>
        </svg>
      )}
    </div>
  )
}
