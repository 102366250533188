import { clearDiagnostics } from '../../metrics_server/diags/actions'
import { useAppDispatch } from '../../store/hooks'

export const useHardwareOptions = (view) => {
  const dispatch = useAppDispatch()
  return {
    options: [
      {
        name: 'Clear Diagnostics',
        handle: () => {
          dispatch(clearDiagnostics())
        }
      }
    ]
  }
}
