import React from 'react'
import { CanvasStyle } from '../Strack.types'
import styles from './StrackOverlays.module.scss'

export interface StrackOverlayProps {
  canvasStyle: CanvasStyle
  strackReady: boolean
  children: React.ReactNode
  canvasWidth?: string
  canvasHeight?: string
}

export const StrackOverlay: React.FC<StrackOverlayProps> = ({
  canvasStyle,
  strackReady,
  children,
  canvasWidth,
  canvasHeight
}) => {
  if (!strackReady) return <noscript />
  const renderChildren = (children) => {
    return React.Children.map(children, (child) => {
      if (!child) return null
      return (
        <div
          style={{
            ...canvasStyle,
            width: canvasWidth ? canvasWidth : canvasStyle.width,
            height: canvasHeight ? canvasHeight : canvasStyle.height
          }}
        >
          {React.cloneElement(child, {})}
        </div>
      )
    })
  }

  return (
    <div className={styles.canvasOverlayContainer}>
      {renderChildren(children)}
    </div>
  )
}
