export type DataTypeConfig<Keys, Values, Props> = {
  key: Keys
  value: Values
  name: string
  props: Props
}

export class DataType<k, v, T> {
  name: string
  value: v
  key: k
  props: T
  constructor(props: { name: string; value: v; props }) {
    this.name = props.name
    this.value = props.value

    for (const key in props) {
      this[key] = props[key]
    }
  }

  isUnknown() {
    return this.value === -1
  }
}

export type DataTypes<T> = T

export type DataTypeKey<T extends Record<string, { value: any }>> = {
  [k in keyof T]: T[k]['value']
}

export type DataTypeKeys<TypeKey> = keyof TypeKey

export type DataTypeValues<TypeKey> = TypeKey[keyof TypeKey]

export type DataTypeMap<k extends string> = {
  [key in k]: {
    getGroupType
    dataTypesConfig
  }
}

export interface TypeItem<k, v> {
  name: string
  disableOption?: boolean
  key?: k
  value: v
}

export type OptionsWithAll<ov> = Option<ov | 'All'>[]

export type Options<ov> = Option<ov>[]

export type Option<ov> = {
  name: string
  value: ov
  isSettable?: boolean
  number?: number
}

export class DataTypeGroup<Items extends Record<k, any>, k extends string, v> {
  items: {
    [key in k]: Items[key]
  }

  options: Options<v>
  settableOptions: Options<v>
  optionsWithAll: Options<v | 'All'>
  keyOptions: Options<k>
  keys: k[]

  constructor(items: { [key in k]: Items[key] }) {
    this.items = { ...items } as const
    this.options = this.getOptions(items)
    this.settableOptions = this.getOptions(items, true)
    this.optionsWithAll = this.getOptions(items)
    this.optionsWithAll.unshift({ name: 'All', value: 'All' })
    this.keyOptions = this.getKeyOptions(items)
    this.keys = this.keyOptions.map((option) => option.value)
  }

  getOptions(items: { [key in k]: Items[key] }, settableOptions?: boolean) {
    return Object.keys(items)
      .filter((key: k) => {
        const item: Items[typeof key] = items[key]
        return item.props && !item.props.features?.disableOption
      })
      .map((key: k) => {
        const item: Items[typeof key] = items[key]
        return {
          name: item.name,
          value: item.value,
          isSettable: settableOptions && item.props.features?.isSettable
        }
      })
  }

  getKeyOptions(items: { [key in k]: Items[key] }) {
    return Object.keys(items)
      .filter((key: k) => {
        const item: Items[typeof key] = items[key]
        return item.props && !item.props.features?.disableOption
      })
      .map((key: k) => {
        const item: Items[typeof key] = items[key]
        return {
          name: item.name,
          value: key
        }
      })
  }

  getOptionsByKeys(keys: k[]) {
    const options = this.optionsWithAll
    return options.filter((option) => {
      if (option.value === 'All') return true
      const item = this.getTypeByValue(option.value)
      return keys.includes(item.key)
    })
  }

  getTypesByKey(keys: k[]) {
    const newObject = {} as {
      [key in k]: Items[key]
    }
    keys.forEach((key) => {
      if (this.items[key]) newObject[key] = this.items[key]
    })
    return newObject
  }

  getTypeByValue(value: v) {
    let item: Items[k]
    for (const key in this.items) {
      const i = this.items[key]
      if (i.value === value) {
        item = i
      }
    }

    if (!item) {
      return new DataType({
        name: 'No Type',
        value: -1,
        props: {}
      }) as Items[k]
    }
    return item
  }

  getTypesByValues(values: v[]) {
    const newObject = {} as {
      [key in k]: Items[key]
    }
    values.forEach((value) => {
      const item = this.getTypeByValue(value)
      if (item) newObject[item.key] = item
    })
    return newObject
  }

  getAllValues(): v[] {
    return Object.values<Items[k]>(this.items).map((item) => item.value)
  }

  isType = (typeKey: k | k[], value: v | 'All'): boolean => {
    if (value === 'All') return false
    if (typeKey instanceof Array) {
      return typeKey.some((key) => this.isType(key, value))
    }
    if (!this.items[typeKey] || (!value && value !== 0)) return false
    return this.items[typeKey].value === value
  }

  isEmpty = (): boolean => {
    let count = 0
    for (const key in this.items) {
      count++
    }
    if (count === 0) return true
    return false
  }
}

// =============//

export function getDataTypeGroup<
  TypeKeys extends string,
  TypeValues,
  Items extends Record<
    TypeKeys,
    { key: TypeKeys; value: TypeValues; name: string; props: Props }
  >,
  Props
>(items: {
  [key in TypeKeys]?: Items[key]
}) {
  const classes = {} as {
    [key in TypeKeys]: DataType<key, Items[key]['value'], Props>
  }
  for (const key in items) {
    const item: Items[typeof key] = items[key]
    type I = Items[typeof key]
    classes[item.key] = new DataType<I['key'], I['value'], Props>(item)
  }
  return new DataTypeGroup<
    {
      [key in TypeKeys]: DataType<key, Items[key]['value'], Props>
    },
    TypeKeys,
    TypeValues
  >(classes)
}

export function updateDataTypes(sportDataTypes, dataTypes) {
  const sportMetricTypes = {}
  for (const key in sportDataTypes) {
    sportMetricTypes[key] = {
      ...dataTypes[key],
      ...sportDataTypes[key]
    }
  }
  return sportMetricTypes
}

// Type check generator

export function generateTypeChecks<
  k extends string,
  G extends { items: { [key in k]: { value: string | number } } }
>(types: G) {
  const typeCheck = {} as {
    [key in k]: (item: G['items'][k]) => item is G['items'][key]
  }

  for (const key in types.items) {
    const type = types.items[key]
    typeCheck[key] = (item: G['items'][k]): item is G['items'][typeof key] => {
      if (item === undefined || item === null) return false
      return item.value === type.value
    }
  }

  return typeCheck
}

// Formatting data_types_config.json //

// Get types from json - handles strings and objects - inherit nearest parent type if string given //
export function getTypesConfigFromJson<
  k extends string,
  T extends { [key in k]: { key: k; name: string; props } }
>(typesJson: TypesJson<k, T[k]>, types: T, fullTypes: T): Partial<T> {
  let newTypes: Partial<T>
  if (!typesJson) return {}
  typesJson.forEach((jsonType) => {
    if (typeof jsonType === 'string') {
      const type = types[jsonType] || fullTypes[jsonType]
      newTypes = {
        ...newTypes,
        [jsonType]: {
          ...type
        }
      }
    } else {
      const type = types[jsonType.key] || fullTypes[jsonType.key]
      newTypes = {
        ...newTypes,
        [jsonType.key]: {
          ...type,
          name: jsonType.name || type.name,
          props: {
            ...(type?.props || {}),
            ...jsonType
          }
        }
      }
    }
  })
  return newTypes
}

export type TypesJson<TypeKeys extends string, T> = (TypeKeys | T)[]
