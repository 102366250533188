import React, { useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from '../../store/hooks'
import * as targetActions from '../../metrics_server/targets/actions'
import { MainView } from '../../components/Views/Main/Main'
// Components
import { NameForm } from '../../components/Forms/NameForm/NameForm'

// Actions
import { getSessionChallenges } from '../../metrics_server/targets/actions'
import { getSubSessions } from '../../metrics_server/sub_sessions/actions'
import { getSessionFlights } from '../../metrics_server/events/actions'
import { useSession } from '../../metrics_server/sessions/hooks'
import { useChallenge, useTargets } from '../../metrics_server/targets/hooks'
import { useUnits } from '../../metrics_server/units/hooks'
import { useTargetSetupHeaderProps } from './header'
import { TargetSetupTabs } from './tab_config'
import { useEventsFilters } from '../../metrics_server/events/filter'

const actions = { ...targetActions }

export interface TargetsSetupProps {
  view: any
}

export const TargetsSetup: any = (ChildComponent) => {
  return (props: TargetsSetupProps) => {
    // Redux //
    const targets = useTargets()
    const units = useUnits()
    const dispatch = useAppDispatch()
    // ====== //

    // Session //
    const sessionConfig = useSession()
    const { session, live, sport, playersSessions } = sessionConfig
    // ===== //

    // Targets //
    const { challenge } = useChallenge()
    // ======= //

    // Header //
    const headerProps = useTargetSetupHeaderProps(props)
    // ====== //

    const [options, setOptions] = useState([])

    // Fetch Data //
    useEffect(() => {
      dispatch(getSessionFlights(session.id))
      dispatch(getSessionChallenges(session.id))
      dispatch(getSubSessions(session.id))
    }, [units.selected])
    // ======== //

    const flightEventsFilters = useEventsFilters({}, [
      ...sport.props.features.targetEvents
    ])

    return (
      <ChildComponent
        {...props}
        live={live}
        options={options}
        setOptions={setOptions}
        {...headerProps}
        // Pitch
        pitch={sessionConfig.pitch}
        playersSessions={playersSessions}
        // State
        flightEventsFilters={flightEventsFilters}
      />
    )
  }
}
