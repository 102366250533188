import React from 'react'
import { Shirt } from './Shirt/Shirt'
import styles from './ShirtGrid.module.scss'
import { usePlayerPositions } from '../../metrics_server/sessions/hooks'

export interface ShirtGridProps {
  teamId: string
  sessionId: string
}

export const ShirtGrid = (props: ShirtGridProps) => {
  const { teamId, sessionId } = props

  const playerPositions = usePlayerPositions(sessionId)

  const teamPlayerPositions = playerPositions[teamId]
  return (
    <div className={styles.shirtGridContainer}>
      {teamPlayerPositions.list.map((playerPosition) => (
        <Shirt
          key={playerPosition.number + playerPosition.teamId + 'pp'}
          playerPosition={playerPosition}
        />
      ))}
    </div>
  )
}
