import React, { useCallback, useEffect, useMemo } from 'react'
import { CanvasStyle, CanvasViewTypes, Strack } from '../Strack.types'
import { useAppDispatch } from '../../../store/hooks'
import {
  useChallenge,
  useSelectedTarget,
  useTargets
} from '../../../metrics_server/targets/hooks'
import { useUnitsSystem } from '../../../metrics_server/units/hooks'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { setSelectedTarget } from '../../../metrics_server/targets/actions'
import { useEvents } from '../../../metrics_server/events/hooks'
import { StrackEvents } from '../StrackEvents/StrackEvents'
import { isEventTypeData } from '../../../metrics_server/events/functions'
import { RawFlightEventData } from '../../../metrics_server/events/flight/types'

export interface StrackTargetsProps {
  canvasStyle: CanvasStyle
  strackReady: boolean
  active: boolean
  strack: Strack
  maxNumberOfEvents?: number
  canvasView: CanvasViewTypes
}

export const StrackTargets = ({
  strackReady,
  strack,
  canvasStyle,
  canvasView,
  active
}: StrackTargetsProps) => {
  const dispatch = useAppDispatch()

  const events = useEvents()
  const { sport } = useSelectedFormattedSession()

  const targets = useTargets()
  const { challenge } = useChallenge()
  const { target } = useSelectedTarget()
  const unitSystem = useUnitsSystem(sport)

  // Draw Targets Function //
  const drawTargets = useCallback(() => {
    if (challenge && strackReady) {
      strack.setTargets(
        challenge.Targets || [],
        targets.selected.selectedTarget,
        (targetId) => {
          dispatch(setSelectedTarget(targetId))
        },
        null,
        unitSystem
      )
      strack.drawTargets()
    }
  }, [targets, challenge, unitSystem, strackReady, strack])

  // Initiate targets canvas //
  useEffect(() => {
    if (strackReady) {
      strack.initiateTargetCanvas(() => {
        drawTargets()
      })
    }
  }, [strackReady])

  // Draw targets when selecting a new target //
  useEffect(() => {
    if (targets.selected.id) {
      drawTargets()
    }
  }, [targets.selected, challenge])

  // Draw targets when target is updated //
  useEffect(() => {
    if (challenge) {
      if (target && strackReady) {
        drawTargets()
      }
    }
  }, [target, strackReady])

  // Get flights in selected target: This is where we should handle flights for targets - weird behaviour in target flights for ones that haven't been created yet//
  const flightsInTarget = useMemo(() => {
    const flights: RawFlightEventData[] = []
    if (target?.targetSummary) {
      for (const playerSessionId in target.targetSummary) {
        const flightStats = target.targetSummary[playerSessionId].flightStats
        if (flightStats) {
          flightStats.forEach((flightStat) => {
            const flight = { ...events.rawData[flightStat.id] }
            if (flight && isEventTypeData.flight(flight)) {
              flights.push(flight)
            }
          })
        }
      }
    }
    return flights
  }, [target, events])

  return null

  return (
    <StrackEvents
      events={flightsInTarget}
      active={active}
      canvasView={canvasView}
      strack={strack}
      canvasStyle={canvasStyle}
      strackReady={strackReady}
    />
  )
}
