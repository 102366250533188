import React from 'react'
import { sportableColors } from '../../../constants/sportableColors'

export interface ValidationIconProps {
  scale?: number
  color?: string
}

export const ValidationIcon: React.FC<ValidationIconProps> = ({
  scale = 1,
  color = sportableColors.colors.sportableGreyDark
}) => {
  return (
    <svg
      width={28 * scale}
      height={33 * scale}
      viewBox='0 0 28 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 2.55347L26 7.1249V18.5535C26 23.6963 21.4286 28.8392 14 31.1249C6.57143 28.8392 2 24.2678 2 18.5535V7.1249L14 2.55347Z'
        stroke={color}
        strokeWidth='3.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.85718 16.2677L12.2857 19.6963L19.1429 11.6963'
        stroke={color}
        strokeWidth='3.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
