import { useState, useEffect } from 'react'
import { LayoutTypes, InsightsDashboardLayout } from './types'
import { useAppSelector } from '../../store/hooks'
import { getSelectedLayout } from './functions'

export function useInsights() {
  const insights = useAppSelector((state) => state.insights)
  return insights
}

export function useSelectedLayout(type: LayoutTypes) {
  const { layouts } = useInsights()

  const selectedId = layouts.selectedId[type]

  const [selectedLayout, setSelectedLayout] = useState<{
    selectedLayout: InsightsDashboardLayout
    isEdited: boolean
  }>(getSelectedLayout(layouts, selectedId))

  useEffect(() => {
    setSelectedLayout(getSelectedLayout(layouts, selectedId))
  }, [selectedId, layouts.editedItems[selectedId]])

  return selectedLayout
}

export function useLayoutOptions() {
  const { layouts } = useInsights()
  const layoutOptions = Object.values(layouts.items).map((layout) => {
    return {
      name: layout.name,
      label: layout.name,
      value: layout.id
    }
  })
  return { layoutOptions }
}
