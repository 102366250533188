import React from 'react'

export interface RecentEventsIconProps {
  scale?: number
  color?: string
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  style?: React.CSSProperties
}

export const RecentEventsIcon = ({
  scale = 1,
  color = '#8CB09D',
  handleClick,
  style
}: RecentEventsIconProps) => {
  return (
    <div onClick={handleClick || undefined} style={style}>
      <svg
        width={`${25 * scale}`}
        height={`${25 * scale}`}
        viewBox='0 0 25 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M22.8052 2.52355L21.2754 4.03965C16.6335 -0.64443 9.03829 -0.727762 4.41426 3.85465C-0.209761 8.43706 -0.195087 16.0327 4.44684 20.7167C9.08876 25.4008 16.6839 25.4842 21.3079 20.9017C23.5622 18.6678 24.7469 15.789 24.741 12.7477L22.5851 12.7248C22.5914 15.202 21.6493 17.4918 19.7722 19.352C15.9819 23.1081 9.78162 23.0401 5.9767 19.2006C2.17178 15.3612 2.15981 9.16052 5.95005 5.40439C9.7403 1.64825 15.9406 1.71628 19.7455 5.55575L18.2156 7.07185L24.3499 8.67203L22.8052 2.52355ZM19.393 9.1212L13.6486 10.9743C13.341 10.795 12.9843 10.7216 12.6336 10.7652C12.283 10.8089 11.9581 10.9673 11.7093 11.2159C11.405 11.5175 11.2345 11.9291 11.2353 12.3604C11.2361 12.7916 11.4082 13.207 11.7137 13.5153L11.7621 13.5641L11.7708 18.4978L13.9751 18.522L13.9648 13.5883L14.0129 13.5405C14.1636 13.3912 14.2683 13.2015 14.3476 13.0169L20.1158 11.1878L19.3937 9.12044L19.393 9.1212Z'
          fill={color}
        />
      </svg>
    </div>
  )
}
