import { setError } from '../../ui/error/actions'
import { ErrorState } from '../../ui/error/types'

export const requestAction = (request, successCallback?, data?) => {
  return (dispatch) => {
    const dispatchError = (error: ErrorState) => {
      dispatch(setError(error))
    }
    return request(dispatchError, successCallback, data)
  }
}
