import React from 'react'
import styles from './Documents.module.scss'

import { Terms } from './Terms'
import { Privacy } from './Privacy'

import Scroller from '../Scroller/Scroller'

export interface DocumentsContainerProps {
  docType: string
}

export const DocumentsContainer: React.FC<DocumentsContainerProps> = ({
  docType
}) => {
  return (
    <div className={styles.termsContainer}>
      <Scroller
        padding={'0'}
        scrollerId={'terms-scroller'}
        scrollerClass='form-scroller'
      >
        <div className={styles.termsInner}>
          {docType === 'terms' && <Terms />}
          {docType === 'privacy' && <Privacy />}
        </div>
      </Scroller>
    </div>
  )
}
