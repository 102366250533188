import React from 'react'

function ColorPicker({ label, onChange, initialValue, value }) {
  const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

  const handleColorChange = (event) => {
    const newHex = event.target.value
    if (hexRegex.test(newHex)) {
      onChange(newHex)
    }
  }

  const handleHexInputChange = (event) => {
    const newHex = event.target.value
    onChange(newHex)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label>{label}</label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type='text'
          value={value ? value : initialValue}
          onChange={handleHexInputChange}
          placeholder='Hex Color'
        />
        <input
          onChange={handleColorChange}
          type='color'
          style={{
            backgroundColor: value ? value : initialValue,
            width: '40px',
            height: '35px',
            padding: '20px',
            display: 'inline-block',
            marginLeft: '10px',
            cursor: 'pointer'
          }}
        />
      </div>
    </div>
  )
}

export default ColorPicker
