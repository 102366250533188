import { useRef, useState } from 'react'

const useTimer = (initialState = 0) => {
  const [timer, setTimer] = useState<number>(initialState)
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isPaused, setIsPaused] = useState<boolean>(false)
  const countRef = useRef(null)

  const timerStart = () => {
    setIsActive(true)
    setIsPaused(true)
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  const timerPause = () => {
    clearInterval(countRef.current)
    setIsPaused(false)
  }

  const timerResume = () => {
    setIsPaused(true)
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  const timerReset = () => {
    clearInterval(countRef.current)
    setIsActive(false)
    setIsPaused(false)
    setTimer(0)
  }

  return {
    timer,
    isActive,
    isPaused,
    timerStart,
    timerPause,
    timerResume,
    timerReset
  }
}

export default useTimer
