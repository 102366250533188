import React from 'react'

interface SignalProps {
  color?: string
  scale?: number

  strokeWidth?: number
  strength: number
}

export const Signal: React.FC<SignalProps> = ({
  color,
  scale = 1,
  strokeWidth,
  strength
}) => {
  return (
    <svg
      width={`${30 * scale}px`}
      height={`${30 * scale}px`}
      viewBox='0 0 382 675'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        id='Page-1'
        stroke='none'
        strokeWidth={strokeWidth || 1}
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='1371033'
          transform='translate(191.500000, 338.000000) rotate(90.000000) translate(-191.500000, -338.000000) translate(-146.000000, 147.000000)'
          fill={color || '#F64645'}
        >
          <g
            id='Group'
            transform='translate(337.500000, 191.000000) scale(-1, 1) rotate(-180.000000) translate(-337.500000, -191.000000) translate(0.000000, 0.000000)'
          >
            <path
              style={{ opacity: strength > 2 ? 1 : 0.1 }}
              d='M308.9,380.6 C308.7,380.4 303.8,379.9 298,379.6 C254.1,376.7 199.3,363 153,343.3 C101.1,321.2 50.3,287.9 14.1,252.2 C5.9,244.1 3.9,241.5 2.1,236.5 C-3.9,220.5 3.9,201.8 20.2,192.9 C26.1,189.7 27.1,189.5 36,189.5 C44.6,189.5 46,189.8 50.7,192.4 C53.6,194 59.9,198.9 64.7,203.4 C104.2,239.8 148.3,267.1 198.3,285.9 C213.5,291.6 235.7,298.3 248,301 C285.2,309.1 298,310.4 338,310.5 C368.9,310.5 374.2,310.3 389,308.2 C419.3,303.9 441,298.7 469.5,288.9 C520.7,271.4 570.5,241.7 610.4,204.8 C624.1,192.2 629.2,189.6 640.5,189.5 C647.6,189.5 649.3,189.9 655.2,192.8 C662.3,196.3 669.3,203.1 672.6,209.7 C674.1,212.8 674.5,215.7 674.5,225 C674.5,239.4 673.8,240.9 661.7,252.8 C628.9,285 582.3,316.6 536.5,337.7 C486.1,360.9 431.4,375.3 375.5,380 C364.2,380.9 309.8,381.4 308.9,380.6 Z'
              id='Path'
            ></path>
            <path
              style={{ opacity: strength > 1 ? 1 : 0.1 }}
              d='M311.5,251.9 C232.8,245.3 155.4,210.7 101.3,157.8 C90.2,147 88.5,143.3 88.5,130.5 C88.5,121.6 88.8,120 91.2,115.5 C94.8,108.8 101.9,101.6 108.3,98.3 C112.3,96.3 115.4,95.6 121.5,95.2 C133.6,94.6 138.4,96.8 151.2,108.6 C163.5,120 180.9,133.3 194,141.5 C216.4,155.4 246.6,168.3 270,174 C274.7,175.1 280.1,176.5 282,177 C283.9,177.5 292,178.9 300,180.2 C320.7,183.4 355,183.4 376.2,180.2 C432.3,171.6 482.5,147.7 523.5,110 C536.6,98 541.7,95.5 553,95.5 C560.2,95.5 562.3,95.9 567.1,98.3 C573.4,101.3 581.7,109.5 584.9,115.7 C589.5,124.8 589.2,138.1 584.3,147.2 C579.3,156.4 550.4,181 526.7,196.4 C480,226.6 430.1,244.6 374.5,251 C361.9,252.5 324.6,253 311.5,251.9 Z'
              id='Path'
            ></path>
            <path
              style={{ opacity: strength > 0 ? 1 : 0.1 }}
              d='M315,123.3 C304.7,122.2 282.9,117.3 271.1,113.4 C239.5,102.8 211.5,86 189.2,64.1 C177.4,52.5 175.5,48.7 175.6,37 C175.7,30.1 176.2,27.4 178.2,23 C183.5,11.5 193.7,3.3 205.5,1 C210.6,-5.55111512e-17 212.6,0.1 217.9,1.5 C225.7,3.5 230.6,6.5 239,14.5 C266.1,40.2 300.6,54 337.9,54 C376.6,54 410.1,40.4 439.6,12.8 C449.6,3.5 454.2,1.5 466,1.5 C476.5,1.5 482,3.9 489.9,11.8 C497.6,19.6 500.5,26.3 500.5,37 C500.5,48.8 497.8,54.1 486,65.3 C453,96.8 409.1,117.3 363,123 C355.2,123.9 323.1,124.2 315,123.3 Z'
              id='Path'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
